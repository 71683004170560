import api from 'lib/api';

export const GET_WELCOME_INFORMATION_TEXT = 'INFORMATION_TEXT_GET_WELCOME_TEXT';
export const UPDATE_INFORMATION_TEXT_READ = 'INFORMATION_TEXT_UPDATE_INFORMATION_TEXT_READ';
export const HIDE_WELCOME_MODAL = 'HIDE_WELCOME_MODAL';

export const getWelcomeInformation = () => ({
	type: GET_WELCOME_INFORMATION_TEXT,
	payload: new Promise((resolve) => {
		api.get('informationTexts?messageType=welcome').then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateInformationTextRead = (values) => ({
	type: UPDATE_INFORMATION_TEXT_READ,
	payload: new Promise((resolve) => {
		api.put(`informationTexts/contentread`, values).then((response) => {
			resolve(response.data);
		});
	}),
})

export const hideWelcomeWindow = () => ({
	type:HIDE_WELCOME_MODAL,
})