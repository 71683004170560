import React, { Component } from 'react';
import { Block } from '@haldor/ui';
import WizardTimeLine from './WizardTimeLine';

class Wizard extends Component {

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.currentPage != nextProps.currentPage) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		if (this.props.children == null) {
			return null;
		}

		return (
			<div>
				<WizardTimeLine
					children={this.props.children}
					goToPage={(i) => { return this.props.disableWizardStepNavigation ? null : this.props.goToPage(i) }}
					currentPage={this.props.currentPage}
				/>

				<Block>
					<div>
						{this.props.children[this.props.currentPage]}
					</div>
				</Block>
			</div>
		);
	}

}

export default (Wizard);
