import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
/*import MatrixForm from './MatrixForm/MatrixForm'; **/
import MatrixForm from "./Form/MatrixForm";

/* Compontent for creating Matrixes */

class TestPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matrix: null
		};
	}
	UNSAFE_componentWillMount() {
		if (this.props.match.params != null && this.props.match.params.matrixId != null) {
			let matrix = this.props.matrixes.find(
				t => t.id == this.props.match.params.matrixId
			);
			this.setState({
				matrix: {
					id: matrix.id,
					title: matrix.title,
					matrixBlocks: matrix.matrixBlocks
				}
			});
		}
	}

	render() {
		return (
			<div className="container">
				<Expandable title={this.props.translate("matrix")}>
					<MatrixForm
						matrix={this.state.matrix}
						Type={ASSESSMENT_MATRIX}
						/*onMatrixFormUpdate={this.onMatrixFormUpdate.bind(this)}**/
						editView={this.props.initialValues != null}
					/>
				</Expandable>
				<Link to="/matrixList">Matrix List</Link>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		matrixes: state.Matrix.matrixList
	};
}

export default connect(mapStateToProps, {})(TestPage);
