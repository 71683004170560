import React, { Component } from 'react';
import Block, { BlockWrapper, BlockContent, BlockHeader } from 'UI/Elements/Block';

import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';

class GraphContent extends Component {

	render() {
		return(
			<div className="graph content">
				<BlockWrapper cols={ 1 }>
					<Block>
						{ this.props.title ?
							<BlockHeader>
								{ this.props.title }
							</BlockHeader>
						: null }

						<BlockContent>
							{ this.props.children }
						</BlockContent>
					</Block>
				</BlockWrapper>
			</div>
		);
	}

}

export default GraphContent;
