

var store = require('../');
import { appInsights } from 'lib/appInsights';

var fetchSchool = function (activeSchool, currentUser) {
	var localStorageSchool = localStorage.getItem("hal.school" + currentUser.userId);

	let foundSchool = null;
	if (currentUser.schools != null && localStorageSchool != null) {
		foundSchool = currentUser.schools.find(school => {
			return school.id == localStorageSchool;
		});

		if (foundSchool == null) {
			const properties = {
				'localStorageSchool': localStorageSchool,
				'url': window.location.pathname,
			};

			appInsights.trackTrace({ message: 'Wrong school in localstorage', severityLevel: 2 }, properties);
		}
	}

	if (localStorageSchool == null) {
		localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);

		const newAction = { type: "SET_SCHOOL", payload: currentUser.schools[0].id };
		store.default.dispatch(newAction);
	} else {
		if (foundSchool != null) {
			const newAction = { type: "SET_SCHOOL", payload: localStorageSchool };
			store.default.dispatch(newAction);
		} else {
			if (window.location.pathname == '/') {
				localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);
				const newAction = { type: "SET_SCHOOL", payload: currentUser.schools[0].id };
				store.default.dispatch(newAction);
			}
		}
	}
}

var setLocalStorage = function (currentUser) {
	var localStorageSchool = localStorage.getItem("hal.school" + currentUser.userId);

	if (localStorageSchool == null) {
		localStorage.setItem("hal.school" + currentUser.userId, currentUser.schools[0].id);
	}
}

var setSchoolOnUser = function (currentUser, schoolId) {
	localStorage.setItem("hal.school" + currentUser.userId, schoolId);
}

var getSchoolForUser = function (currentUser) {
	return localStorage.getItem("hal.school" + currentUser.userId);
}

export default {
	fetchSchool,
	setLocalStorage,
	setSchoolOnUser,
	getSchoolForUser,
};
