import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate, Flex, Checkbox, Button, ButtonGroup, Icon } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { sendAdminInvitation, setEmailSentList, getAdminInvitations } from '../../../actions/services';
import { Spinner } from 'UI';
import getEnvironment from 'lib/env';
import '../_onboarding.scss';
import { addError } from 'actions';
import { appInsights } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";

class EmailAdminInvite extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			showEmailInfo: false,
			showConsentInfo: true,
			showEmailSentInfo: false,
			adminEmail: null,
			recpients: [],
			errorMessage: null,
			inviteRetry: 0,
			emailBody: '',
			user: { name: '', email: '' }
		};

		window.onPopupDataReceived = this.getResults.bind(this);
	}

	getResults(response) {
		console.log("CONSENT MAIL", response);

		if (response) {
			this.onAdminInvite({ email: this.state.recpients.join(",") });
		}
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	componentDidMount() {
		appInsights.trackTrace({ message: "Onboarding | Invite Admin was initiated" });

		if (this.inIframe()) {
			microsoftTeams.app.getContext().then((context) => {
				console.log("context", context);

				if (context.user.userPrincipalName) {

					var user = this.state.user;
					user.email = context.user.userPrincipalName;
					user.name = context.user.userPrincipalName.split('@')[0];

					if (user.name.indexOf(".") > -1) {
						var firstName = user.name.split('.')[0];
						var lastName = user.name.split('.')[1];

						firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
						lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
						user.name = firstName + " " + lastName;
					}

					this.setState({ user: user });
					this.prepareEmailBody();
				}
			});
		} else {
			if (this.props.onboardingUser) {
				var user = this.state.user;
				user.email = this.props.onboardingUser.email;
				user.name = this.props.onboardingUser.email.split('@')[0];

				if (user.name.indexOf(".") > -1) {
					var firstName = user.name.split('.')[0];
					var lastName = user.name.split('.')[1];

					firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
					lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
					user.name = firstName + " " + lastName;
				}

				this.setState({ user: user });
				this.prepareEmailBody();
			}
		}
	}



	prepareEmailBody() {
		let product = getEnvironment().id;
		var emailBody = this.props.translate("ONBOARDING_EMAIL_ADMIN");
		if (product === 'haldor_planning') {
			emailBody = this.props.translate("ONBOARDING_PLANNING_EMAIL_ADMIN");
		}
		emailBody = emailBody.replace("{0}", this.state.user.name);
		if (product != 'haldor_planning') {
			emailBody = emailBody.replace("{1}", this.getSelectedServicesInString());
		}
		emailBody = emailBody.replace("{2}", window.location.origin + '/teams/he?admin=true&product=' + product);
		emailBody = emailBody.replace("{3}", "(" + window.location.origin + '/teams/he?admin=true&product=' + product + ")");

		this.setState({ emailBody: emailBody });
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(0)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	renderInput = ({
		input,
		label,
		type,
		placeholder,
		meta: { touched, error, warning }
	}) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: "1px solid red"
							}
							: {}
					}

					onKeyPress={e => {
						if (e.key == 'Enter') {
							e.preventDefault();
						}
					}}
				/>

				{touched &&
					((error && (
						<span style={{ margintop: "1rem", color: "red" }}>
							{error}
						</span>
					)) ||
						(warning && (
							<span style={{ margintop: "1rem", color: "red" }}>
								{warning}
							</span>
						)))}
			</div>
		);
	};

	onAdminInvite(values) {
		let product = getEnvironment().id;
		console.log("Email", values)
		console.log("retry", this.state.inviteRetry)

		this.setState({ errorMessage: null });
		window.scrollTo(0, document.body.scrollHeight);

		if (values == null || values.email == null) {
			emailInvalid = this.props.translate("Email is invalid, please provide correct email");
			this.setState({ errorMessage: emailInvalid });
		} else {
			values.email = values.email.replace(",", ";");
			var emailList = values.email.split(";");

			var emailHandledList = [];
			var emailInvalid = null;

			emailList.forEach(element => {
				element = element.replace(/\s/g, '');

				if (this.validateEmail(element)) {
					emailHandledList.push(element);
				} else {
					emailInvalid = this.props.translate("Email is invalid, please provide correct email");
				}
			});

			this.setState({ errorMessage: emailInvalid });

			if (emailHandledList.length > 0 && emailInvalid == null) {
				var adminInviteInfo = {
					recpients: emailList,
					redirect_url: window.location.origin + '/teams/he?admin=true&product=' + product,
					subscriptionService: product
				}

				this.setState({ recpients: emailList, inviteRetry: this.state.inviteRetry + 1, isLoading: true });

				this.props.setEmailSentList(emailList);

				if (emailHandledList != null && emailHandledList.length > 0 && this.state.inviteRetry < 3) {
					appInsights.trackTrace({ message: "Onboarding | Sending Invitation to Admin" });

					this.props.sendAdminInvitation(adminInviteInfo).then(response => {
						// This will make sure to get data about information of sent invitations
						this.props.getAdminInvitations();

						if (this.props.inviteAdminStatus != 200) {

							if (this.state.inviteRetry == 2) {
								this.setState({ isLoading: false });
							}

							this.getEmailConsent();
						} else {
							this.setState({ isLoading: false });
							this.props.showPage('showEmailSentInfo');
						}
					});
				}
			}
		}
	}


	getEmailConsent() {
		if (this.props.onboardingUser) {

			appInsights.trackTrace({ message: "Onboarding | Opening ms admin outh url" });

			var consentTab = window.open("https://login.microsoftonline.com/" + this.props.onboardingUser.tenantId + "/oauth2/v2.0/authorize?client_id=" + getEnvironment().apiClientId + "&response_type=token&scope=user.read%20mail.send&redirect_uri=" + window.location.origin + "/teams/he/authentication", "_blank", "width=600,height=1200");

			var timer = setInterval(() => {
				if (consentTab.closed) {
					this.setState({ isLoading: false });
					clearInterval(timer);
				}
			}, 1000);

			return;
		}
	}


	showEmailHeader() {
		return (<div>
			<h2 style={{ textAlign: 'left' }}>{this.props.translate('E-mail your IT support or Office 365 administrator')}</h2>
			<p style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '5%' }}>{this.props.translate('Here is an e-mail suggestion we have created for you. Add the e-mail address of the person or people you want to send to. You can also copy and paste the text into an e-mail in your e-mail client.')}</p>
		</div>);
	}


	showEmailBody() {

		return (<div>
			<form className="form form-component"
				onSubmit={this.props.handleSubmit(this.onAdminInvite.bind(this))}>
				<div className="form-row input">

					<div style={{ width: '20rem', height: '20rem' }}>
						<img src="/dist/svg/illustration_steg03.svg" />
					</div>

					<label>{this.props.translate("to")}</label>
					<p>{this.props.translate('To send the e-mail to multiple recipients, enter the addresses and separate them with semicolons or commas.')}</p>

					<Field
						component={this.renderInput}
						type="text"
						name="email"
						placeholder={this.props.translate("to")}
					/>

					<div className="email-container">
						<p><strong>{this.props.translate("A copy of this email is sent to:")}</strong> {this.state.user.email}</p>
						<p><strong>{this.props.translate("Subject")}:</strong> {this.props.translate('Approval of Haldor Education')} </p>
						<p><strong>{this.props.translate("E-mail message:")}</strong></p>
						<p id="email-body" dangerouslySetInnerHTML={{
							__html: this.state.emailBody,
						}}></p>
					</div>

					{this.showEmailButtonGroup()}
				</div>
			</form>
		</div>)
	}

	copyTextToClipboard() {
		var text = document.getElementById("email-body").innerText;
		var elem = document.createElement("textarea");
		document.body.appendChild(elem);
		elem.innerHTML = text;
		elem.select();
		document.execCommand("copy");
		document.body.removeChild(elem);
	}

	showEmailButtonGroup() {
		return (<div>
			{this.state.errorMessage ? <p style={{ color: "red", marginBottom: '0.5rem' }}>{this.state.errorMessage}</p> : null}

			<Button type="save" style={{ marginRight: '0.5rem' }}>
				{this.props.translate('Send')}
			</Button>
			<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={(event) => { event.preventDefault(); this.copyTextToClipboard(); this.props.addError(this.props.translate('The text was copied'), 'info'); }}>
				{this.props.translate('Copy Text')}
			</Button>
			<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('showConsentInfo') }}>
				{this.props.translate('Back')}
			</Button>
		</div>)
	}

	validateEmail(email) {
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(email)) {
			return false;
		}

		return true;
	}

	getSelectedServicesInString() {
		var serviceNames = this.props.selectedServices.map((element, index) => {
			var text = this.props.translate(element.name);
			return (text)
		});

		return serviceNames.join(", ");
	}

	render() {
		return (
			<div>
				{this.state.isLoading ? <Spinner center /> : <div>
					{this.showEmailHeader()}
					{this.showEmailBody()}
				</div>
				}
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		selectedServices: state.Services.selectedServices,
		consentInfo: state.Services.consentInfo,
		inviteAdminStatus: state.Services.inviteAdminStatus,
		onboardingUser: state.user.onboardingUser
	};
}

export default connect(mapStateToProps, {
	sendAdminInvitation,
	setEmailSentList,
	getAdminInvitations,
	addError
})(reduxForm({
	form: 'AdminInvite',
	destroyOnUnmount: true,
	enableReinitialize: true,
	pure: true
})(EmailAdminInvite));
