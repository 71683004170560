import Moment from 'moment';

import schoolHelper from 'helpers/schoolHandling';
const getSchoolForUser = schoolHelper.getSchoolForUser;

import {
	ROLE_TEACHER,
	ROLE_MENTOR,
	ROLE_ADMINISTRATOR,
	ROLE_COURSE_ADMIN,
	ROLE_STUDENT,
	ROLE_STANDARD_CURRICULUM_ADMIN,
	ROLE_SYSADMIN,
	ROLE_SPECIAL_PEDAGOGUE
} from 'constants/roles';

export const havingPermission = (user, permission) => {
	if (user == null)
		return false;

	if (Array.isArray(user.roles) && user.roles.length > 0) {
		const school = getSchoolForUser(user);

		let allAccessRights = user.roles.flatMap(userRole => (userRole.role != null
			&& userRole.referenceType.toUpperCase() == 'SCHOOL'
			&& userRole.referenceId == school) ? userRole.role.accessRights.flatMap(accessRight => accessRight.permission) : []);

		let distinctAccessRights = [...new Set(allAccessRights)];
		return distinctAccessRights.some(accessRight => accessRight.toUpperCase() === permission.toUpperCase());
	}
}

export const isUserPedagogue = (user) => {

	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var pedagogue = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_SPECIAL_PEDAGOGUE &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (pedagogue != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserTeacher = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);
		if (user.roles != null && user.roles.length > 0) {
			var teacher = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_TEACHER &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (teacher != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserAdministrator = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var admin = user.roles.find(role =>
				(role.roleId.toUpperCase() == ROLE_ADMINISTRATOR ||
					role.roleId.toUpperCase() == ROLE_SYSADMIN) &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (admin != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserCourseAdministrator = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var admin = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_COURSE_ADMIN &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (admin != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserCurriculumAdmin = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var admin = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_STANDARD_CURRICULUM_ADMIN &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (admin != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserMentor = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var mentor = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_MENTOR &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (mentor != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const isUserStudent = (user) => {
	if (user != null) {
		const school = getSchoolForUser(user);

		if (user.roles != null && user.roles.length > 0) {
			var student = user.roles.find(role =>
				role.roleId.toUpperCase() == ROLE_STUDENT &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (student != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	return false;
}

export const getUserAssessmentDates = () => {
	const startDateLocalStorage = localStorage.getItem('assessment-start-date');
	const endDateLocalStorage = localStorage.getItem('assessment-end-date');

	let dates = {
		start: Moment().month(7).date(1).format('YYYY-MM-DD'),
		end: Moment().format('YYYY-MM-DD'),
	};

	if (Moment().month() < 7) {
		dates.start = Moment().subtract(1, 'years').month(7).date(1).format('YYYY-MM-DD');
	}

	if (startDateLocalStorage != null) {
		dates.start = Moment(startDateLocalStorage).format('YYYY-MM-DD');
	}

	if (endDateLocalStorage != null) {
		dates.end = Moment(endDateLocalStorage).format('YYYY-MM-DD');
	}

	if (dates.end < dates.start) {
		dates.start = dates.end;
	}

	return dates;
}

export const isUserAuthoritative = (user) => {
	return user.authoritative;
}