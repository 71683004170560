import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getActiveSchool } from 'helpers/localstorage';
import { Checkbox, Button } from '@haldor/ui';
import { Search as SearchInput } from 'UI/Inputs';
import { DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { searchSchoolUsers } from 'actions/search';
import { getUserInfo } from 'actions/user';

class SubjectTeachersForm extends Component {
	constructor(props) {
		super(props);

		let subject = this.props.subject;

		this.state = {
			allTeachers: [],
			teachers: [],
			subject: subject,
			subjectTeachers: [],
		};
	}

	componentDidMount = () => {
		let promises = [];
		let subjectTeachers = [];
		this.props.subject.subjectTeachersUserId.forEach(userId => {
			promises.push(this.props.getUserInfo(userId, "base"));
		});

		Promise.all(promises).then(() => {
			this.props.subject.subjectTeachersUserId.forEach(userId => {
				let teacher = this.props.users.find(u => {
					return u.userId == userId;
				});

				if (teacher != null) {
					subjectTeachers.push(teacher);
				}
			});

			this.setState({ subjectTeachers, teachers: subjectTeachers });
		});
	}

	submit = (values) => {
		this.setState({ loading: true });
		this.props.onSubjectTeachersSubmit(this.state.subject, this.state.subjectTeachers);
		this.setState({ subjectTeachers: [], subject: [], loading: false });
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ marginTop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ marginTop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	onSearchInputChange = (value) => {
		let teachers = [];

		if (value == '') {
			teachers = this.state.allTeachers;
		} else {
			const activeSchool = getActiveSchool(this.props.currentUser.id);
			this.props.searchSchoolUsers(value, 'teacher', '').then(() => {
				let teachers = this.props.teachers;
				let allTeachers = this.props.teachers;
				this.setState({ allTeachers, teachers });

				teachers = this.state.allTeachers.filter(teacher => {
					return teacher.firstName?.toLowerCase().startsWith(value.toLowerCase())
						|| teacher.lastName?.toLowerCase().startsWith(value.toLowerCase())
						|| teacher.email.toLowerCase().startsWith(value.toLowerCase)
				});
			})
		}

		this.setState({ teachers });
	}

	selectTeacher = (teacher) => {
		if (this.state.subjectTeachers.findIndex(ss => ss.userId == teacher.userId) > -1) {
			this.setState({ subjectTeachers: this.state.subjectTeachers.filter(t => t.userId != teacher.userId) });
		} else {
			this.setState({ subjectTeachers: [...this.state.subjectTeachers, teacher] });
		}
	}

	renderTeacher = (teacher, index) => {
		let teacherIndex = this.state.subjectTeachers.findIndex(u => {
			return u.userId == teacher.userId;
		});

		return (
			<RowItem key={teacher.userId}>
				<RowCell shrink title={this.props.translate('name')}>
					<Checkbox
						value={teacherIndex > -1}
						onChange={() => this.selectTeacher(teacher)}
						label={teacher.firstName + " " + teacher.lastName + " (" + teacher.email + ")"}
					/>
				</RowCell>
			</RowItem>
		);
	}

	render() {
		const { handleSubmit, submitting, valid, translate } = this.props;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row" style={{ marginTop: 0 }}>
						<label style={{ marginTop: 0 }}>
							{this.props.translate('search-teacher')}
						</label>

						<SearchInput
							placeholder={this.props.translate('search')}
							onChange={this.onSearchInputChange}
							autofocus
							lengthLimit={3}
						/>
					</div>

					<div className="form-row" style={{ marginTop: '2rem' }}>
						<DataList
							title={translate('teacher')}
							data={this.state.teachers}
							renderRow={this.renderTeacher}
							emptyData={() => (
								<div className="color--meta text--center weight--bold">
									{translate('no-results')}
								</div>
							)}
						/>
					</div>

					<div className="form-row spacing submit" style={{ marginTop: '2em' }}>
						<Button type="save" disabled={submitting || !valid}>
							{this.props.translate('save')}
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		teachers: state.Search.teachers,
		users: state.user.info,
	};
}

export default connect(mapStateToProps, {
	searchSchoolUsers,
	getUserInfo
})(reduxForm({
	form: 'AddTeacherSubject',
	destroyOnUnmount: true,
})(SubjectTeachersForm));