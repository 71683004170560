import {
	GET_CURRENT_MEETINGS,
	GET_PAST_MEETINGS,
	GET_MEETING,
	GET_MEETING_INSTANCE,
	CREATE_MEETING,
	MEETING_INSTANCE_UPDATE_DATE,
	GET_USER_MEETINGS,
	MEETING_INSTANCE_UPDATE,
	CLEAR_MEETING,
	SAVE_WIZARD_MEETING,
	CLEAR_WIZARD_MEETING,
	DELETE_MEETING,
	DELETE_MEETING_INSTANCE,
} from 'actions/meetings';

const INITIAL_STATE = {
	current: [],
	past: [],
	user: [],
	meetingsByCreator: [],
	meeting: null,
	meetingInstance: null,
	createdId: 0,
	wizardMeeting: null,
	deletedMeeting: null,
};

export default function (state = INITIAL_STATE, action) {
	let meeting = { ...state.meeting };

	switch (action.type) {
		case GET_CURRENT_MEETINGS:
			if (action.payload != null) {
				return { ...state, current: action.payload.filter((m) => m.id != state.deletedMeeting) };
			}

			return state;

		case GET_PAST_MEETINGS:
			if (action.payload != null) {
				return { ...state, past: action.payload.filter((m) => m.id != state.deletedMeeting) };
			}

			return state;

		case GET_USER_MEETINGS:
			return { ...state, user: action.payload };

		case GET_MEETING:
			return { ...state, meeting: action.payload };

		case GET_MEETING_INSTANCE:
			return { ...state, meetingInstance: action.payload };

		case CREATE_MEETING:
			return { ...state, meeting: action.payload };

		case CLEAR_MEETING:
			return { ...state, meeting: null };

		case SAVE_WIZARD_MEETING:
			return { ...state, wizardMeeting: action.payload };

		case CLEAR_WIZARD_MEETING:
			return { ...state, wizardMeeting: INITIAL_STATE.wizardMeeting };

		case DELETE_MEETING:
			if (!action.payload) {
				return state;
			}

			return {
				...state,
				current: [...state.current].filter(m =>
					m.id != action.payload
				),
				deletedMeeting: action.payload,
			};

		case DELETE_MEETING_INSTANCE:
			if (!action.payload) {
				return state;
			}

			meeting.instances = meeting.instances.filter(i =>
				i.id != action.payload
			);

			return { ...state, meeting };

		case MEETING_INSTANCE_UPDATE:
			return state;

		case MEETING_INSTANCE_UPDATE_DATE:
			meeting.instances = [...meeting.instances.filter(t => t.id != action.payload.id), action.payload];
			return { ...state, meeting, meetingInstance: action.payload }

		default:
			return state;
	}
};
