import {
	GET_BLOCK_TYPES,
	GET_BLOCKS_BY_REFERENCE,
	SAVE_BLOCKS_ON_REFERENCE,
	CLEAR_BLOCKS_ON_REFERENCE,
	CLEAR_BLOCK_TYPES,
} from 'actions/blocks';

const INITIAL_STATE = {
	types: null,
	reference: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {

		case GET_BLOCKS_BY_REFERENCE:
			return { ...state, reference: action.payload };

		case GET_BLOCK_TYPES:
			return { ...state, types: action.payload };

		case CLEAR_BLOCK_TYPES:
			return { ...state, types: null };

		case CLEAR_BLOCKS_ON_REFERENCE:
			return { ...state, reference: INITIAL_STATE.reference };

		default:
			return state;
	}
}