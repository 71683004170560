import React, { Component } from 'react';

import './Style.scss';

class ColorLabelContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			collapse: false,
		}

		this.container = null;
		this.contentWidth = null;
	}

	setContainer = (element) => {
		this.container = element;
	}

	componentDidMount() {
		this.calculateCollapse();
		this.onResize();

		window.addEventListener('resize', this.onResize);
	}

	componentDidUpdate(prevProps) {
		let prevChildren = [];
		let currentChildren = [];
		if (prevProps != null && prevProps.children != null && typeof prevProps.children == 'object') {
			prevChildren = prevProps.children;
		}
		if (this.props != null && this.props.children != null && typeof this.props.children == 'object') {
			currentChildren = prevProps.children;
		}

		if (prevChildren.length != currentChildren.length) {
			this.calculateCollapse();
			this.onResize();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	onResize = () => {
		if (this.container == null) {
			return false;
		}

		const containerWidth = this.container.clientWidth;

		if (this.contentWidth == null) {
			return false;
		}

		if (containerWidth < this.contentWidth) {
			if (this.state.collapse == false) {
				this.setState({ collapse: true });
			}
		} else {
			if (this.state.collapse == true) {
				this.setState({ collapse: false });
			}
		}
	}

	calculateCollapse = () => {
		// Issue where the container is no longer rendered on screen but the calculateCollapse still runs
		// and refs['container'] == undefined
		if (this.container != null) {
			let contentWidth = 0;

			const dots = [... this.container.querySelectorAll('.dot')];
			dots.forEach(dot => {
				if (dots.length > 1) {
					// Add extra 10 px for margin
					contentWidth = contentWidth + dot.clientWidth + 25;
				} else {
					contentWidth = contentWidth + dot.clientWidth;
				}
			});

			this.contentWidth = contentWidth;
		}
	}

	render() {
		const { children } = this.props;
		const { collapse } = this.state;

		const props = Object.assign({}, this.props);
		delete props.collapse;
		delete props.inline;
		delete props.test;

		return (
			<div
				ref={this.setContainer}
				className={'dots-container'
					+ (this.props.collapse || collapse ? ' collapse' : '')
					+ (!this.props.collapse && !collapse ? ' show-content' : '')
					+ (this.props.inline ? ' inline' : '')
				} {...props}>

				{children}

				{!this.props.inline ?
					<div style={{ clear: 'both' }} />
					: null}
			</div>
		);
	}
}

export default ColorLabelContainer;
