import React from 'react';

const Pen = props => (
  <svg version="1.1" className="icon-pen" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 136.1 136.1">
    <g>
      <path d="M39.7,117.4H23.5c-2,0-3.6-1.6-3.6-3.6V97.6c0-1.8,0.7-3.5,1.9-4.7l69.8-69.8c2.6-2.6,6.8-2.6,9.4,0l13.2,13.2c2.6,2.6,2.6,6.8,0,9.4l-69.8,69.8C43.2,116.7,41.5,117.4,39.7,117.4z M25.5,111.8h14.2c0.3,0,0.5-0.1,0.7-0.3l69.8-69.8c0.4-0.4,0.4-1,0-1.4L97,27.1c-0.4-0.4-1-0.4-1.4,0L25.8,96.9c-0.2,0.2-0.3,0.4-0.3,0.7V111.8z" />
    </g>
    <g>
      <rect x="91.4" y="29.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.8857 79.2333)" width="5.7" height="26.3" />
    </g>
    <g>
      <rect x="26.2" y="68.4" transform="matrix(0.7072 -0.707 0.707 0.7072 -31.0352 67.5614)" width="79.6" height="5.7" />
    </g>
    <g>
      <polygon points="45.2,110.7 39.5,105.1 26.6,92.1 30.6,88.1 43.5,101.1 49.2,106.7" />
    </g>
  </svg>
);

export default Pen;
