import React from 'react';

import { Icon, Haldor__Block, addBlockTranslations } from '@haldor/ui';

import OnedrivePicker from './OnedrivePicker';

import './_FileBlock.scss';

class FileBlock extends Haldor__Block {

	constructor(props) {
		super(props);

		this.name = 'Haldor.Blocks.File';
		this.order = 48;
		this.icon = () => (
			<svg xmlns="http://www.w2.org/2000/svg" viewBox="0 0 50 50"
				dangerouslySetInnerHTML={{
					__html: `
						<defs><style>.icon-block-file-1{fill:#c8c9c8;}</style></defs>
						<path class="icon-block-file-1" d="M30.92,32.9H19.08a2,2,0,1,0,0,4H30.92a2,2,0,1,0,0-4Z"/>
						<path class="icon-block-file-1" d="M19.08,29H25A2,2,0,0,0,25,25H19.08a2,2,0,1,0,0,3.95Z"/>
						<path class="icon-block-file-1" d="M40.28,17.75,29.54,5.91a2,2,0,0,0-1.46-.65H14.26a5,5,0,0,0-5,4.93h0V39.81a5,5,0,0,0,5,4.93H35.74a5,5,0,0,0,5-4.93h0V19.08A2,2,0,0,0,40.28,17.75ZM29,11.18l5.41,5.92H30.41A1.55,1.55,0,0,1,29,15.45V11.18Zm6.79,29.61H14.26a1,1,0,0,1-1.11-1h0V10.19a1,1,0,0,1,1.11-1H25v6.21a5.5,5.5,0,0,0,5.35,5.63h6.5V39.81a1,1,0,0,1-1.11,1Z"/>
					`
				}}
			/>
		)

		addBlockTranslations('sv-se', [
			{
				output: 'Filer',
				id: 'Haldor.Blocks.File',
			}
		]);

		addBlockTranslations('en-us', [
			{
				output: 'Files',
				id: 'Haldor.Blocks.File',
			}
		]);

		addBlockTranslations('nb-no', [
			{
				output: 'Filer',
				id: 'Haldor.Blocks.File',
			}
		]);

		addBlockTranslations('da-dk', [
			{
				output: 'Filer',
				id: 'Haldor.Blocks.File',
			}
		]);

		addBlockTranslations('uk', [
			{
				output: 'Файли',
				id: 'Haldor.Blocks.File',
			}
		]);

		this.registerResourceType('haldor.blocks.file.fileItem', {
			id: 'number',
			name: 'string',
			type: 'string',
			url: 'string',
		});

		this.editorBlockWasAdded = null;
	}

	selectFile = (files) => {
		if (files != null && files.length > 0) {
			const resources = this.getResources();

			files.forEach(file => {
				let foundInResources = resources.findIndex(resource => resource.name == file.name)
				if (foundInResources < 0) {
					this.addResource('haldor.blocks.file.fileItem', file);
				}
			})
		}
	}

	edit = (props) => {
		return <div className="block--fileblock edit">
			{props.resources.length > 0 ?
				props.resources.map((resource) => {
					return <div className="file-resource" key={resource.name}>
						<div className="name">
							{resource.name}
						</div>

						<span className="delete-resource" onClick={(e) => this.removeResource(resource)}>
							<Icon name="Bin" />
						</span>
					</div>
				})
				: null}

			<OnedrivePicker
				onUpdate={this.selectFile}
			/* openPicker={callback => this.editorBlockWasAdded = callback} */
			/>
		</div>
	}

	consume = (props) => {
		let resources = [];
		if (props.resources != null && props.resources.length > 0) {
			resources = [...props.resources].sort((a, b) => a.name.localeCompare(b.name))
		}

		return <div className="block--fileblock consume">
			{resources.map((resource) => {
				return <a className="file-resource" href={resource.url} target="_blank" key={resource.name}>
					<div className="name">
						{resource.name}
					</div>
				</a>
			})}
		</div>
	}

}

export default FileBlock;