import React from 'react';
import { useTranslate } from 'lib/translate';
import { Icon } from '@haldor/ui';
import ColorScale from '_class/ColorScale';

const AssessmentDetailField = (props) => {
	const translate = useTranslate();
	const { resource, row } = props;

	/**
	 * @param {object} cell
	 */
	const onCellClick = (cell) => {
		let value = [];
		if (props.input.value != null && props.input.value !== '') {
			value = props.input.value;
		}

		if (getIsCellSelected(cell)) {
			value = value.filter((value) =>
				value.cellId != cell.id && value.assessmentBlockPartRowId != row.id
			);
		} else {
			const existing = getRowValue(row.id);

			value = value.filter((value) =>
				value.assessmentBlockPartRowId != row.id
			);

			value.push({
				...existing,
				cellId: cell.id,
				assessmentBlockPartRowId: row.id,
			});
		}

		props.input.onChange(value);
	}

	/**
	 * @param {number} rowId
	 */
	const getRowValue = (rowId) => {
		if (props.input.value == null || props.input.value == '') {
			return null;
		}

		return props.input.value.find((value) => value.assessmentBlockPartRowId == rowId);
	}

	/**
	 * @param {object} cell
	 */
	const getIsCellSelected = (cell) => {
		if (props.input.value == null || props.input.value == '') {
			return false;
		}

		let foundInValue = props.input.value.find((value) =>
			value.cellId == cell.id && value.assessmentBlockPartRowId == row.id
		)

		return foundInValue != null;
	}

	const getRowData = () => {
		if (row.referenceType == 'matrixRow') {
			let foundRow = null;
			let addNotAchieved = true;
			resource.value.details.forEach((_detail) => {
				_detail.matrix._ParagraphSet.forEach((_paragraph) => {
					const matchingRow = _paragraph.rows.find((_row) => _row.id == row.referenceId);

					if (matchingRow != null) {
						if (_detail.matrix.disableNotAchieved) {
							addNotAchieved = false;
						}

						foundRow = JSON.parse(JSON.stringify(matchingRow));
					}
				})
			});

			if (foundRow != null && addNotAchieved) {
				if (foundRow?.cells.find(cell => cell.id == 'F') == null) {
					foundRow?.cells.unshift({
						text: translate('Not achieved'),
						id: 'F',
					});
				}
			}

			return foundRow;
		}

		if (row.referenceType == 'assessmentGoalRow') {
			let foundRow = resource?.value?.rows.find((_row) =>
				_row.id == row.referenceId
			);

			if (foundRow != null) {
				foundRow.cells.sort((a, b) => a.index - b.index);
			}

			return foundRow;
		}
	}

	/**
	 * @param {object} rowData
	 */
	const getColors = (rowData) => {
		let showRed = false;

		if (resource.referenceType == 'assessmentGoal') {
			showRed = rowData.cells.find(cell => cell.type == 'NotAchievedTextField') != null;
		}

		if (resource.referenceType == 'matrix') {
			const detail = resource.value.details.find((detail) => {
				return detail.matrix._ParagraphSet.find((paragraph) => {
					return paragraph.rows.find((row) => row.id == rowData.id)
				});
			});

			if (detail != null && !detail.matrix.disableNotAchieved) {
				showRed = true;
			}
		}

		return new ColorScale(rowData.cells.length).get(showRed);
	}

	const getRowParagraph = () => {
		if (row.referenceType == 'matrixRow') {
			let foundParagraph = null;
			resource.value.details.forEach((_detail) => {
				_detail.matrix._ParagraphSet.forEach((_paragraph) => {
					const matchingRow = _paragraph.rows.find((_row) => _row.id == row.referenceId);

					if (matchingRow != null) {
						foundParagraph = _paragraph;
					}
				})
			});

			return foundParagraph;
		}

		return null;
	}

	/**
	 * @param {object} cell
	 * @param {number} index
	 */
	const renderCell = (cell, index) => {
		const selected = getIsCellSelected(cell);

		return <div
			className={selected ? 'cell selected' : 'cell'}
			style={{ '--level-color': colors[index] }}
			key={cell.id}
			onClick={() => onCellClick(cell)}
		>
			{row.referenceType == 'assessmentGoalRow' ?
				cell.value
				: cell.text}

			<Icon name="Check" />
		</div>
	}

	const data = getRowData();
	if (data == null) {
		return null;
	}

	const colors = getColors(data);
	const paragraph = getRowParagraph();

	return (
		<>
			{paragraph != null && paragraph.title != null ? <div className="matrix-paragraph-title">{paragraph.title}</div> : null}
			{paragraph != null && paragraph.subtitle != null ? <div className="matrix-paragraph-subtitle">{paragraph.subtitle}</div> : null}
			<div className="df row">
				{paragraph != null ?
					<div>
						{paragraph.paragraphNr}.{data.sentenceNr}
					</div>
					: null}

				<div className="column-text">
					{data.text != null ?
						data.text
						: data.description}
				</div>

				<div className={data.cells.length > 5 ? "cells df overflow-protection" : "cells df"}>
					{data.cells.map(renderCell)}
				</div>
			</div>
		</>
	);

}

export default AssessmentDetailField;