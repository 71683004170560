import React, { useEffect, useState } from 'react';
import { Flex, Icon, Expandable } from '@haldor/ui';
import { useTranslate } from 'lib/translate';

import GoalMatrixRowForm from './GoalMatrixRowForm';
import { capitalize } from 'helpers/content';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import { ColorLabel, ColorLabelContainer } from 'UI';
import sniffer from 'sniffer';

const GoalMatrixForm = (props) => {
	const translate = useTranslate();
	const [rows, setRows] = useState(props.part?.value.rows ?? []);

	useEffect(() => {
		if (rows.length == 0) {
			let rows = [];
			rows.push({
				index: rows.length,
				id: 0,
				title: '',
				description: '',
				cells: [
					{
						index: 0,
						type: 'NotAchievedTextField',
						value: translate('Not achieved'),
						id: 0,
					},
				],
			});

			setRows(rows);
		}
	}, []);

	// onChange
	useEffect(() => {
		if (props.onChange != null) {
			props.onChange({
				...props.part,
				value: {
					...props.part?.value,
					rows,
				}
			});
		}
	}, [rows]);

	const onRowChange = (row) => {
		if (row == null) {
			return false;
		}

		let changedRows = [...rows];
		changedRows.splice(row.index, 1, row);
		setRows(changedRows);
	}

	const removeMatrix = () => {
		if (props.onRemove != null) {
			props.onRemove();
		}
	}

	/**
	 * @param {string} target
	 * @param {string} referenceType
	 * @description Toggles target permission on part data
	 */
	const togglePermission = (target, referenceType) => {
		let permissions = props.part.permissions;
		const indexOf = props.part.permissions?.findIndex(element =>
			element.target == target && element.referenceType == referenceType
		);

		if (indexOf > -1) {
			permissions.splice(indexOf, 1);
		} else {
			permissions.push({ target: target, referenceType: referenceType, level: 'READ' });
		}

		props.onChange({
			...props.part,
			permissions: permissions
		});
	}

	/**
	 * @param {string} target
	 */
	const renderPermission = (target) => {
		const active = props.part.permissions?.find(element =>
			element.target == target && element.referenceType == 'ASSESSMENTBLOCKPART'
		) != null;

		return <div className="permission active" onClick={(event) => {
			event.stopPropagation(); togglePermission(target, 'ASSESSMENTBLOCKPART');
		}}>
			<Icon name={active ? 'Eye' : 'Eye_Off'} />
			{translate(capitalize(target))}
		</div>
	}

	const renderAssessmentFeedbackPermission = () => {
		const active = props.part.permissions?.find(element =>
			element.target == 'GUARDIAN' && element.referenceType == 'ASSESSMENTFEEDBACK'
		) != null;

		return <div className="permission active" onClick={(event) => {
			event.stopPropagation(); togglePermission('GUARDIAN', 'ASSESSMENTFEEDBACK');
		}}>
			{props.assessmentType == ASSESSMENT_TYPES.TEACHER &&
				<>
					<Icon name={active ? "Eye" : "Eye_Off"} />
					{translate(capitalize('Show feedback for guardians'))}
				</>
			}
		</div>
	}

	const renderRowTitle = () => {
		return <div className="df aic">
			<div className='goalMatrix-Title-Header'><span>{translate('Goal')}</span>
				{props.course.length > 0 ?
					props.course.map(course => {
						const title = course.value.year != null ? `${course.value.title} ${course.value.year} (${course.value.courseCode})` : `${course.value.title} (${course.value.courseCode})`;
						return (
							<ColorLabelContainer>
								<ColorLabel
									key={course.value.id}
									tooltip={title}
									color={course.value.colorCode}
								/>
							</ColorLabelContainer>
						)
					}
					)
					: null}


			</div>

			{props.assessmentType == ASSESSMENT_TYPES.TEACHER &&
				<>
					<div className="row-tools">
						{renderPermission('STUDENT')}
					</div>

					<div className="row-tools">
						{renderPermission('GUARDIAN')}
					</div>
				</>
			}

			{props.disableRows == null || props.disableRows.length == 0 ?
				<div className="controls row-tools">
					<div onClick={removeMatrix}>
						<Icon name="Bin" />
					</div>
				</div>
				: null}
		</div>
	}

	const getRowDisabled = (id) => {
		if (props.disableRows == null || props.disableRows.length == 0) {
			return false;
		}

		const reference = props.part.assessmentBlockPartRows?.find((partRow) => partRow.referenceId == id);
		if (reference == null) {
			return false;
		}

		return props.disableRows.indexOf(reference.id) > -1;
	}

	return (
		<div className="goal-matrix-form">
			<Expandable title={renderRowTitle()} color="#0f78da" open ignorePropsUpdate>
				<div className="guardian-feedback-permission">
					{renderAssessmentFeedbackPermission()}
				</div>

				{props.disabled ?
					<div className="disabled-notice">
						<Icon name="Alert" bw />
						{translate('To be able to edit, you must first delete the assessments made.')}
					</div>
					: null}

				<Flex center={!sniffer.isPhone} column={sniffer.isPhone}>
					{rows.map((row, index) => {
						return <GoalMatrixRowForm
							onChange={onRowChange}
							key={index}
							row={row}
							disabled={getRowDisabled(row.id)}
						/>
					})}
				</Flex>
			</Expandable>
		</div>
	);

}

GoalMatrixForm.defaultProps = {
	assessments: [],
	disabled: false,
};

export default GoalMatrixForm;