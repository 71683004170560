import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import { getStudentAdditionalAdjustmentsForAssignment } from 'actions/assignments';
import { getStudentAdditionalAdjustmentsForPlan } from 'actions/plans';
import { getStudentAdditionalAdjustmentsForGroup, getStudentAdditionalAdjustmentsForMultipleGroups } from 'actions/sections';

import { Spinner, Person } from 'UI';
import { Block } from '@haldor/ui';
import { GetGroupedByStudent } from '../Helpers';

import AdditionalAdjustmentModal from './AdditionalAdjustmentModal';

import './TeacherAdjustments.scss';

class TeacherAdjustments extends PureComponent {

	static defaultProps = {
		hideAll: false,
		disableCommenting: false,
	}

	constructor(props) {
		super(props);

		this.state = {
			activeAdjustment: null,
			loading: false,
			loadedGroups: [],
		}
	}

	/* Lifecycle methods - JLIFE */
	componentDidMount = () => {
		if (this.props.groupId != null) {
			this.setState({ loading: true });

			this.props.getStudentAdditionalAdjustmentsForGroup(this.props.groupId).then(() => {
				this.setState({ loading: false });
			})
		}

		if (this.props.assignmentId != null) {
			this.setState({ loading: true });

			this.props.getStudentAdditionalAdjustmentsForAssignment(this.props.assignmentId)
				.then(() => {
					this.setState({ loading: false });
				})
		}

		if (this.props.planId != null) {
			this.setState({ loading: true });

			this.props.getStudentAdditionalAdjustmentsForPlan(this.props.planId)
				.then(() => {
					this.setState({ loading: false });
				})
		}

		if (this.props.groups != null) {
			this.setState({ loading: true });

			let promises = [];
			this.props.groups.forEach(groupId => {
				promises.push(this.getAdjustmentsFromApi(groupId));
			});

			Promise.all(promises).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.groupId != this.props.groupId) {
			if (nextProps.groupId == null) {
				return false;
			}

			this.setState({ loading: true });

			if (this.shouldLoadAdjustments(nextProps.groupId)) {
				this.props.getStudentAdditionalAdjustmentsForGroup(nextProps.groupId).then(() => {
					this.setState({ loading: false });
				})
			}
		}

		if (this.props.groups != null) {
			if (nextProps.groups !== this.props.groups) {
				this.setState({ loading: true });

				let promises = [];

				nextProps.groups.forEach(groupId => {
					if (this.shouldLoadAdjustments(groupId)) {
						promises.push(this.getAdjustmentsFromApi(groupId));
					}
				});

				Promise.all(promises).then(() => {
					this.setState({ loading: false });
				});
			}
		}
	}

	// Returns true if group has not been loaded
	shouldLoadAdjustments = (groupId) => {
		return this.state.loadedGroups.indexOf(groupId) == -1;
	}

	// Returns promise for api call and add group id to loadedGroups state array
	getAdjustmentsFromApi = (groupId) => {
		this.setState({ loadedGroups: [...this.state.loadedGroups, groupId] })

		return this.props.getStudentAdditionalAdjustmentsForMultipleGroups(groupId);
	}

	// Looks for a group in fetched data and returns it
	getAdjustmentsForGroup = (groupId) => {
		return [...this.props.multipleGroupAdjustments].filter(adj =>
			adj.groupId == groupId
		);
	}

	/* Events */
	openAdjustment = (adjustment) => {
		this.setState({ activeAdjustment: adjustment.id });
	}

	closeAdjustment = () => {
		this.setState({ activeAdjustment: null });
	}

	adjustmentInUse = (additionalAdjustment) => {
		let inUse = false;
		additionalAdjustment.items.forEach(student => {
			student.items.forEach(adjustment => {
				if (adjustment.status == 'ACTIVE') {
					inUse = true;
				}
			});
		});

		return inUse;
	}

	/* Render methods - JREND */
	render() {
		if (this.state.loading) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		let adjustments = [];
		let activeAdjustment = null;
		if (this.props.groups != null) {
			this.props.groups.forEach((groupId) => {
				adjustments = [...adjustments, ...this.getAdjustmentsForGroup(groupId)];
			})
		} else if (this.props.assignmentId) {
			adjustments = this.props.assignment;
		} else if (this.props.planId) {
			adjustments = this.props.plan;
		} else {
			if (this.props.adjustments != null) {
				adjustments = this.props.adjustments;
			} else {
				adjustments = this.props.groupAdjustments;
			}
		}

		const groupedBy = GetGroupedByStudent(adjustments, null, this.props.subjects);

		if (groupedBy.length == 0) {
			return (
				<div className="teacher-adjustments">
					<div className="text--center color--meta" style={{ margin: '1rem 0' }}>
						{this.props.translate('no-results')}
					</div>
				</div>
			)
		}

		if (this.state.activeAdjustment != null) {
			activeAdjustment = groupedBy.find(gb => gb.id == this.state.activeAdjustment)

			if (activeAdjustment != null) {
				let hasActive = activeAdjustment.items.find(student => {
					return student.items.find(adjustment => adjustment.status == "ACTIVE")
				})

				if (hasActive == null) {
					activeAdjustment = null;
				}
			}
		}

		return (
			<div className="teacher-adjustments">
				<AdditionalAdjustmentModal
					onClose={this.closeAdjustment}
					adjustment={activeAdjustment}
					disableCommenting={this.props.disableCommenting}
				/>

				<div className="adjustments">
					{groupedBy.map(additionalAdjustment => {
						let additionalAdjustmentInUse = this.adjustmentInUse(additionalAdjustment);
						if (additionalAdjustmentInUse) {
							return (
								<div className="grid-item" key={additionalAdjustment.id} onClick={() => this.openAdjustment(additionalAdjustment)}>
									<Block>
										<span className="title">
											{additionalAdjustment.title}

											{additionalAdjustment.subTitle != null ?
												' - ' + additionalAdjustment.subTitle
												: null}
										</span>

										<div className="students">
											{additionalAdjustment.items.map(student => {
												let activeItems = student.items.find(adjustment => {
													return adjustment.status == 'ACTIVE';
												});

												if (activeItems == null) {
													return null;
												}

												return <Person person={student} key={student.userId} />
											})}
										</div>
									</Block>
								</div>
							);
						} else {
							return null;
						}
					})}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		groupAdjustments: state.AdditionalAdjustments.groupAdjustments,
		multipleGroupAdjustments: state.AdditionalAdjustments.multipleGroupAdjustments,
		assignment: state.AdditionalAdjustments.assignment,
		plan: state.AdditionalAdjustments.plan,
	};
}

export default connect(mapStateToProps, {
	getStudentAdditionalAdjustmentsForAssignment,
	getStudentAdditionalAdjustmentsForPlan,
	getStudentAdditionalAdjustmentsForGroup,
	getStudentAdditionalAdjustmentsForMultipleGroups,
})(TeacherAdjustments);