import React, { Component } from 'react';
import { Icon } from '@haldor/ui';

import './WizardTimeLine.scss';

class WizardTimeLine extends Component {

	render() {
		return (
			<div className="timeline-container">
				{this.props.children.map((child, i) => {
					if (child.props.name == null)
						return null;

					let classname = 'timeline-item';

					if (this.props.currentPage > i) {
						classname += ' active';
					}

					if (this.props.currentPage == i) {
						classname += ' current';
					}

					return (
						<div className={classname} key={i} onClick={() => { this.props.goToPage(i) }}>
							<div className="timeline-header">
								<div className="timeline-dot">
									<Icon name="Check" />
								</div>

								<span className="timeline-label">
									{child.props.name}
								</span>
							</div>
						</div>
					)
				})}
			</div>
		);
	}

}

export default (WizardTimeLine);
