import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getParameterByName } from 'helpers/url';
import { Spinner } from 'UI';

export const LTIComponent = React.forwardRef((props, ref) => {

	let iframeSrc = document.location.origin + '/lti?start=y';
	let frameProps = { ...props };

	if (props.source != '') {
		iframeSrc += '&url=' + props.source;
	}

	if (props.lti_version != '') {
		iframeSrc += '&lti_version=' + props.lti_version;
	}

	if (props.lti_message_type != '') {
		iframeSrc += '&lti_message_type=' + props.lti_message_type;
	}

	if (props.content_item_return_url != '') {
		iframeSrc += '&content_item_return_url=' + encodeURI(props.content_item_return_url);
	}

	if (props.debug) {
		iframeSrc += '&debug=y';
	}

	delete frameProps.src;
	delete frameProps.source;
	delete frameProps.lti_message_type;
	delete frameProps.content_item_return_url;
	delete frameProps.lti_version;
	delete frameProps.debug;
	delete frameProps.ref;

	return (
		<iframe
			onLoad={(event) => {
				if (event.target.contentWindow.haldor_lti == null) {
					return false;
				}

				const url = event.target.contentWindow.location?.href;

				if (url != null && getParameterByName('start', url) == null) {
					// URL is available and start is not set on url (it has callback)
					let paramString = url.split('?')[1];
					let params_arr = paramString.split('&');
					let result = {};

					for (let i = 0; i < params_arr.length; i++) {
						let pair = params_arr[i].split('=');
						result[pair[0]] = decodeURIComponent(pair[1]);
					}

					props.onCallback(result);
				}
			}}
			{...frameProps}
			src={iframeSrc}
			ref={ref}
		/>
	);

});

LTIComponent.defaultProps = {
	source: '',
	lti_message_type: 'basic-lti-launch-request',

	content_item_return_url: document.location.origin + '/lti',
	lti_version: 'LTI-1p0',
	debug: false,
	onCallback: () => true,
};

LTIComponent.propTypes = {
	source: PropTypes.string,
	lti_message_type: PropTypes.oneOf(['basic-lti-launch-request', 'ContentItemSelectionRequest']),
	content_item_return_url: PropTypes.string,
	lti_version: PropTypes.oneOf(['LTI-1p0', 'LTI-2p0']),
	debug: false,
	onCallback: PropTypes.func,
};

const LTI = () => {
	const formRef = useRef();
	const inputType = getParameterByName('debug') != null ? 'text' : 'hidden';

	useEffect(() => {
		window.haldor_lti = true;

		() => {
			delete window.haldor_lti;
		}
	}, []);

	useEffect(() => {
		if (getParameterByName('debug') == null && formRef.current != null) {
			// Auto submit form
			formRef.current.submit();
		}
	}, [formRef]);

	if (getParameterByName('start') != null) {
		return (
			<form
				ref={formRef}
				method="post"
				action={getParameterByName('url')}
				encType="application/x-www-form-urlencoded"
			>
				<input name="lti_message_type" type={inputType} value={getParameterByName('lti_message_type')} />
				<input name="content_item_return_url" type={inputType} value={getParameterByName('content_item_return_url')} />
				<input name="lti_version" type={inputType} value={getParameterByName('lti_version')} />
				<input name="ext_content_return_types" type={inputType} value={"lti_launch_url"} />
				<input name="launch_presentation_return_url" type={inputType} value={getParameterByName('content_item_return_url')} />

				{getParameterByName('debug') != null ?
					<button type="submit">
						Launch LTI
					</button>
					: null}
			</form>
		);
	}

	return <Spinner center />;
};

export default LTI;
