import React, { useMemo, useState } from 'react';
import { clearPlansFromStore, getPlans, getPlansBySectionId, clearSectionPlansFromStore } from "actions/plans";
import { translate as translateConstructor } from '@haldor/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataGridPro, gridClasses, useGridApiRef } from '@mui/x-data-grid-pro';
import { makeStyles } from '@mui/styles';
import { svSE, enUS, nbNO, daDK } from '@mui/x-data-grid-pro/locales';
import { Avatar, Chip, Stack, Tooltip } from '@mui/material';
import ColorLabelContainer from 'UI/Elements/ColorLabel/ColorLabelContainer';
import ColorLabel from 'UI/Elements/ColorLabel/ColorLabel';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { getRootUrl } from 'helpers/url';
import { useHistory } from 'react-router-dom';
import { TranslateTwoTone } from '@mui/icons-material';
/**
 * this is a map of the locale to the data grid language
 */
const localeMapDataGrid = {
	'EN-US': enUS,
	'SV-SE': svSE,
	'NB-NO': nbNO,
	'DA-DK': daDK,
};


const useStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: '#FFFFFF',
		marginBottom: '3rem',
	},
	firstColumn: {
		boxShadow: '5px 0 10px #0000000d;',
		fontSize: '.688rem',
	},
	rowStyle: {
		cursor: 'pointer',
	},
	"& .MuiDataGrid-root": {
		scrollbarWidth: "none", // Firefox
		"&::-webkit-scrollbar": {
			display: "none", // Chrome, Safari
		},
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: '#f0f0f0', // Custom background color
		color: '#333', // Custom text color
		fontSize: '.688rem', // Font size for headers
		fontWeight: 'normal !important',
		cursor: 'unset !important',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 'normal !important', // Bold text
		whiteSpace: 'normal', // Allow line break
		lineHeight: '1.2', // Adjust line height for better appearance
		overflow: 'visible', // Prevent truncation
	},
});

const MyPlansList = () => {
	const [datagridLanguage, setDatagridLanguage] = useState(enUS); // Default to English
	const [loading, setLoading] = useState(true);
	const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

	const translations = useSelector(state => state.Languages.translations);
	const plans = useSelector(state => state.planning.plans);
	const currrentUser = useSelector(state => state.user.currentUser);

	useEffect(() => {
		if (currrentUser != null && currrentUser.lcid != null && localeMapDataGrid[currrentUser.lcid.toUpperCase()] != null) {
			setDatagridLanguage(localeMapDataGrid[currrentUser.lcid.toUpperCase()]);
		}
	}, [currrentUser]);

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const apiRef = useGridApiRef();
	let translate = translateConstructor(translations);

	/**
	 * This is the columns for the DATAGRIDS and only to be recaulated when the plans are changed
	 */
	const columns = useMemo(() => [
		{
			field: 'title',
			headerName: translate('Title'),
			disableColumnMenu: true,
			minWidth: 200,
			align: 'left',
			flex: 1,
		},
		{
			field: 'groupTitle',
			headerName: translate('Group'),
			disableColumnMenu: true,
			align: 'left',
			minWidth: 200,
			flex: 1,
		},
		{
			field: 'startDate',
			headerName: '[missing translate] Start',
			renderCell: (params) => { return params.value ? new Date(params.value).toLocaleDateString() : '' },
			disableColumnMenu: true,
			minWidth: 100,
			align: 'left',
			flex: 1,
		},
		{
			field: 'endDate',
			headerName: '[missing translate] Slut',
			disableColumnMenu: true,
			renderCell: (params) => { return params.value ? new Date(params.value).toLocaleDateString() : '' },
			align: 'left',
			minWidth: 100,
			flex: 1,
		},
		{
			field: 'subjects',
			headerName: translate('Subjects'),
			disableColumnMenu: true,
			renderCell: (params) => renderCourses(params),
			minWidth: 250,
			align: 'left',
		},
		{
			field: 'informations',
			headerName: translate('Information'),
			disableColumnMenu: true,
			renderCell: (params) => renderInformations(params),
			align: 'left',
			minWidth: 150,
			flex: 1,
		}
	], [plans]);

	/**
	 * This method is used within the DATAGRIDS to render the column of informations
	 * @param {*} params 
	 * @returns 
	 */
	const renderInformations = (params) => {
		let plan = params.row && params.row.plan;
		if (plan == null || !plan.autoAssignStudents) return null;

		let status = plan.status;
		let statusHtml = null;
		let autoAssignHTML = null;

		if (plan.autoAssignStudents) {
			autoAssignHTML = (<Tooltip placement="top" arrow title="[missing translate] Kommer automatiskt att tilldelas elever som tillkommer i gruppen"><div className="card-meta" style={{ alignSelf: 'baseline' }}><PersonAddIcon sx={{ fontSize: '15px' }} />Automatisk tilldelning</div></Tooltip>);
		}

		if (status === "PLANNING_OPEN") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("open")}
				</div>
			);
		} else if (status === "PLANNING_CLOSED") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("Closed")}
				</div>
			);
		} else if (status === "PLANNING_DRAFT") {
			statusHtml = (
				<div className='card-meta' style={{ alignSelf: 'baseline' }}>
					{translate("draft")}
				</div>
			);
		}

		return (<Stack direction="column" spacing={0.5}>{statusHtml} {autoAssignHTML}</Stack>)
	}

	/**
	 * This function is called when the user clicks on a row in the data grid.
	 * @param {*} params 
	 */
	const handleRowClick = (params) => {
		let planId = params?.id;
		var url = `${getRootUrl()}plan/${planId}`;
		history.push(url);
	};

	/**
	 * This method is used within the DATAGRIDS to render the column of courses
	 * @returns 
	 */
	const renderCourses = (params) => {
		let courses = params.row && params.row.courses;
		if (courses == null) return null;

		return (<Stack direction="column" spacing={0.5} style={{ minWidth: '200px' }}>
			{courses.map((course) => {
				return (<ColorLabelContainer key={course.id}>
					<ColorLabel
						key={course.id}
						tooltip={course.title}
						content={course.title}
						color={course.colorCode}
					/>
				</ColorLabelContainer>);
			})}
		</Stack>)

	}


	useEffect(() => {
		dispatch(getPlans());
		return () => { };
	}, []);

	useEffect(() => {
		if (plans != null) {
			var rows = plans.map((plan) => { return { id: plan.id, title: plan.title, groupTitle: plan.section?.title, startDate: plan.timeStart, endDate: plan.timeEnd, courses: plan.courses, plan: plan } });
			apiRef.current.setRows(rows);
			setLoading(false);
		}
	}, [plans]);

	return (
		<div>
			<div className={classes.root}>
				<DataGridPro
					sx={{
						[`& .${gridClasses.cell}`]: {
							py: 2,
						},
						"& .MuiDataGrid-virtualScroller": {
							overflowY: "hidden !important",
						},
					}}
					apiRef={apiRef}
					loading={loading}
					pagination
					paginationMode='client'
					paginationModel={paginationModel}
					onPaginationModelChange={setPaginationModel}
					slotProps={{
						loadingOverlay: {
							variant: 'skeleton',
							noRowsVariant: 'skeleton',
						},
					}}
					checkboxSelection={false}
					localeText={datagridLanguage.components.MuiDataGrid.defaultProps.localeText}
					disableColumnResize
					disableColumnSelector
					disableColumnFilter
					disableRowSelectionOnClick
					onRowClick={(params) => handleRowClick(params)}
					getRowHeight={() => 'auto'}
					getRowClassName={() => classes.rowStyle}
					autosizeOnMount={false}
					disableAutosize={false}
					columns={columns}

				/>
			</div>
		</div>
	);
};

export default MyPlansList;