import * as React from 'react';
import { DataGrid, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { Popover, Button, Checkbox, FormControlLabel, FormGroup, Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { translate as translateConstructor } from '@haldor/ui';

const CustomColumnsButton = ({ id }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [visibleColumns, setVisibleColumns] = React.useState([]);
	const translations = useSelector(state => state.Languages.translations);

	const apiRef = useGridApiContext();

	const translate = React.useMemo(() => translateConstructor(translations), [translations]);
	const allColumns = React.useMemo(() => apiRef.current.getAllColumns(), [apiRef]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	React.useEffect(() => {
		if (id != null) {
			var storedVisibleColumnsObject = localStorage.getItem('visibleColumns-' + id);
			if (storedVisibleColumnsObject != null) {
				var storedVisibleColumns = JSON.parse(storedVisibleColumnsObject);
				allColumns.forEach(column => {
					const matchingColumn = storedVisibleColumns.find(c => c.field === column.field);
					if (!matchingColumn) {
						apiRef.current.setColumnVisibility(column.field, false);
					}
				});
				setVisibleColumns(storedVisibleColumns);
				return;
			}
		}

		var visibleColumns = apiRef.current.getVisibleColumns();
		setVisibleColumns(visibleColumns);
	}, [apiRef]);

	const onChange = (column, checked) => {
		apiRef.current.setColumnVisibility(column.field, checked);

		var visibleColumns = apiRef.current.getVisibleColumns();
		localStorage.setItem('visibleColumns-' + id, JSON.stringify(visibleColumns))

		setVisibleColumns(visibleColumns);
	};

	return (
		<div>
			<Badge badgeContent={visibleColumns.length == allColumns.length ? 0 : visibleColumns.length} color="primary" anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}>
				<Button
					sx={{
						color: 'rgba(0, 0, 0, 0.6)',
						padding: '7.5px 14px',
						border: '1px solid rgba(0, 0, 0, 0.6)',
					}}
					variant="text"
					onClick={handleClick}
				>
					{translate('Manage columns')}
				</Button>
			</Badge>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<FormGroup style={{ padding: 20 }}>
					{allColumns.map((column) => (
						<FormControlLabel
							key={column.field}
							control={
								<Checkbox
									checked={visibleColumns.some(visibleColumn => visibleColumn.field === column.field)}
									onChange={(event) => onChange(column, event.target.checked)}
								/>
							}
							label={column.headerName}
						/>
					))}
				</FormGroup>
			</Popover>
		</div>
	);
}

export default CustomColumnsButton;
