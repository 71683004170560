import React from 'react';

const AssignmentTaskStatusNotAssigned = React.memo((props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 37.4 37"
			xmlSpace="preserve"
		>
			<path
				d="M21.7 19.5 32.6 8.6c.7-.8.6-2-.2-2.7-.7-.7-1.8-.7-2.6 0L19 16.7 8.1 5.8c-.8-.7-2-.6-2.7.2-.7.7-.7 1.8 0 2.6l10.9 10.9L5.4 30.4c-.8.7-.8 2 0 2.7.4.4.8.6 1.4.6.5 0 1-.2 1.4-.6L19 22.2l10.9 10.9c.4.4.9.6 1.4.6s1-.2 1.4-.6c.8-.7.8-2 0-2.7z"
				style={{
					fill: "#df2d2e",
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusNotAssigned;