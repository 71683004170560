import React from 'react';
import ColorScale from '_class/ColorScale';

import DisplayCell from './DisplayCell';

const DisplayGoal = ({ goal, statistic }) => {
	if (goal == null) {
		return null;
	}

	/**
	 * @param {object} row
	 */
	const getColors = (row) => {
		const showRed = row.cells.find(cell =>
			cell.type == 'NotAchievedTextField'
		) != null;

		return new ColorScale(row.cells.length).get(showRed);
	}

	/**
	 * @param {object} row
	 * @param {number} index
	 * @returns {object} assessmentDetail
	 */
	const getCellAssessmentDetails = (row, index) => {
		if (statistic == null) {
			return [];
		}

		let foundEntry = statistic.statistics.find((stat) => stat.index == index);
		if (foundEntry != null) {
			return foundEntry.assessmentDetails.filter((detail) =>
				detail.assessmentBlockPartRow.referenceId == row.id
			);
		}

		return [];
	}

	return (
		<div className="block--assessment-block consume">
			<div className="display-matrix">
				{goal.rows.map((row) => {
					const cells = row.cells?.sort((a, b) => a.index - b.index);
					const colors = getColors(row);

					return <div className="df row" key={row.id}>
						<div className="column-text">
							{row.description}
						</div>

						<div className="cells df">
							{cells.map((cell, index) => {
								const assessmentDetails = getCellAssessmentDetails(row, index);
								return (
									<DisplayCell
										color={colors[index]}
										cell={cell}
										key={cell.id}
										assessmentDetails={assessmentDetails}
									/>
								);
							})}
						</div>
					</div>
				})}
			</div>
		</div>
	);

};

export default DisplayGoal;