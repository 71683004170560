import api from 'lib/api';

export const ADD_MATRIX = 'ADD_MATRIX';
export const GET_MATRIX = 'GET_MATRIX';

export function addMatrix(matrix) {
	return {
		type: ADD_MATRIX,
		payload: matrix,
	};
}

export const getMatrix = (id) => ({
	type: GET_MATRIX,
	payload: new Promise((resolve) => {
		api.get(`matrix/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});