import {
	GET_ASSIGNMENT_EVENTS_PAGINATION,
	SET_CURRENT_ASSIGNMENT_EVENT_PAGE,
	CLEAR_ASSIGNMENT_EVENT_PAGINATION_FROM_STORE,
	SET_ASSIGNMENT_EVENT_PAGE_FILTER,
	SET_ASSIGNMENT_EVENT_PAGE_SORT
} from 'actions/assignments';
import moment from 'moment';

const INITIAL_STATE = {
	events: [],
	history: [],
	currentPage: 0,
	total: 0,
	sortField: null,
	sortOrder: null,
	filterField: null,
	filterValues: null,
	lastFetched: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_ASSIGNMENT_EVENTS_PAGINATION:
			let pageInfo = {
				page: action.payload.page,
				events: action.payload.events,
			};

			if (action.payload.events != null && action.payload.events.length > 0) { state.history.push(pageInfo); }

			return { ...state, currentPage: action.payload.page, events: action.payload.events, history: state.history, total: action.payload.total, lastFetched: moment() };
		case SET_CURRENT_ASSIGNMENT_EVENT_PAGE:
			var existingHistory = state.history.find(t => t.page == action.payload);

			if (existingHistory != null) {
				return { ...state, currentPage: action.payload, events: existingHistory.events };
			}

			return { ...state };
		case CLEAR_ASSIGNMENT_EVENT_PAGINATION_FROM_STORE:
			return {
				...state, events: [], history: [], currentPage: 0, total: 0, sortField: null,
				sortOrder: null,
				filterField: null,
				filterValues: null,
				lastFetched: null
			};

		case SET_ASSIGNMENT_EVENT_PAGE_FILTER:
			return {
				...state,
				events: [],
				history: [],
				currentPage: 0,
				total: 0,
				lastFetched: null,
				filterField: action.payload.filterField,
				filterValues: action.payload.filterValues
			};
		case SET_ASSIGNMENT_EVENT_PAGE_SORT:
			return {
				...state,
				events: [],
				history: [],
				currentPage: 0,
				total: 0,
				lastFetched: null,
				sortField: action.payload.sortField,
				sortOrder: action.payload.sortOrder
			};
		default:
			return state;
	}
};