import React, { useState } from 'react';
import ColorScale from '_class/ColorScale';

import { FlexibleTextArea } from 'UI/Inputs';
import { Checkbox, Flex, Icon } from '@haldor/ui';
import { useTranslate } from 'lib/translate';
import sniffer from 'sniffer';

const GoalMatrixRowForm = (props) => {
	const rebuildIndexes = (cell, index) => ({
		...cell,
		index: index,
	});

	let initialCells = [];

	if (props.row?.cells != null) {
		initialCells = props.row.cells.sort((a, b) =>
			a.index - b.index
		).map(rebuildIndexes);
	}

	const translate = useTranslate();
	const [cells, setCells] = useState(initialCells);
	const [description, setDescription] = useState(props.row?.description ?? '');

	const onChange = (parentCells) => {
		let cellValues = cells;
		if (parentCells != null) {
			cellValues = parentCells;
		}

		if (props.onChange != null) {
			if (props.row != null) {
				props.onChange({
					...props.row,
					description,
					cells: cellValues,
				});
			} else {
				props.onChange({
					description,
					cells: cellValues,
				});
			}
		}
	}

	const onDescriptionChange = (event) => {
		setDescription(event.target.value);
	}

	const onCellChange = (cell, event) => {
		if (cell == null) {
			return false;
		}

		let newCells = [...cells];
		newCells.splice(cell.index, 1, {
			...cell,
			value: event.target.value,
		});

		setCells(newCells);
	}

	const onCellCopy = async (cell, event) => {
		event.preventDefault();

		if (cell == null) {
			return false;
		}

		let newCells = [...cells];
		newCells.splice(cell.index, 0, {
			index: 0,
			value: cell.value,
			type: 'TextField',
			id: 0,
		});

		// Rebuild indexes on cells
		newCells = newCells.map(rebuildIndexes);
		await setCells(newCells);
		onChange(newCells);
	}

	const onCellRemove = async (cell, event) => {
		event.preventDefault();

		if (cell == null) {
			return false;
		}

		let newCells = [...cells];
		newCells.splice(cell.index, 1);

		// Rebuild indexes on cells
		newCells = newCells.map(rebuildIndexes);
		setCells(newCells);
		onChange(newCells);
	}

	const onAddCell = async (event) => {
		event.preventDefault();

		let newCells = [...cells];
		newCells.push({
			index: cells.length,
			type: 'TextField',
			value: '',
			id: 0,
		});

		setCells(newCells);
		onChange(newCells);
	}

	const toggleNotAchieved = (add) => {
		let newCells = [...cells];

		if (add) {
			newCells = [
				{
					index: 0,
					type: 'NotAchievedTextField',
					value: translate('Not achieved'),
					id: 0,
				},
				...cells
			].map(rebuildIndexes);
		} else {
			newCells = [...cells]
				.filter(cell => cell.type != 'NotAchievedTextField')
				.map(rebuildIndexes)
		}

		setCells(newCells);
		onChange(newCells);
	}

	const hasAchieved = cells.find(cell => cell.type == 'NotAchievedTextField') != null;
	const colors = new ColorScale(cells.length).get(hasAchieved);

	return (
		<div className="form form-component scrollable">
			<div className="form-row" style={{ padding: 0 }}>
				<Flex column={sniffer.isPhone} row={!sniffer.isPhone}>
					<div className="cell description">
						{/* TODO: Limit to 255 characters */}
						<FlexibleTextArea
							onChange={onDescriptionChange}
							onBlur={() => onChange()}
							placeholder={translate('Goal description')}
							value={description}
							disabled={props.disabled}
						/>
					</div>

					{cells.map((cell, index) => {
						let color = colors[index];

						return <div className="cell has-color" key={index} style={{ '--level-color': color }}>
							<FlexibleTextArea
								onChange={(event) => onCellChange(cell, event)}
								onBlur={() => onChange()}
								value={cell.value}
								disabled={props.disabled}
							/>

							{!props.disabled ?
								<div className="controls">
									<div className="cell-buttons">
										{cell.type != 'NotAchievedTextField' ?
											<div onClick={(event) => onCellCopy(cell, event)}>
												<Icon name="Copy" />
											</div>
											: null}

										<div onClick={(event) => onCellRemove(cell, event)}>
											<Icon name="Bin" />
										</div>
									</div>
								</div>
								: null}
						</div>
					})}

					{!props.disabled ?
						<div className="controls">
							<div className="add-cell-button" onClick={onAddCell}>
								<Icon name="Plus" /> {translate('Add step')}
							</div>
						</div>
						: null}
				</Flex>

				<div className="controls row-form-settings">
					<Checkbox
						value={hasAchieved}
						onChange={toggleNotAchieved}
						label={translate('Add step for not achieved')}
						disabled={props.disabled}
					/>
				</div>
			</div>

		</div>
	);

}

GoalMatrixRowForm.defaultProps = {
	disabled: false,
};

export default GoalMatrixRowForm;
