import React, { Component } from 'react';
import { connect } from 'react-redux';

import { addError } from 'actions';
import { getNotifications } from 'actions/notifications';
import { setPageTitle, setPageActions } from 'actions/header';

import FromNow from 'components/Presentation/Date/FromNow';
import Alert from 'components/Presentation/Notifications/Alert';

import getEnvironment from 'lib/env';

import { getActiveLanguage, translate, Skeleton } from '@haldor/ui';

class Notifications extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			optionsModalVisible: false
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('notifications'));
	}

	componentDidMount = async () => {
		if (this.props.languages != null) {
			this.setState({ loading: true });
			const lang = getActiveLanguage()

			if (lang == 'sv-se') {
				await this.props.getNotifications("sv-SE");
			} else if (lang == 'da-dk') {
				await this.props.getNotifications("da-DK");
			} else if (lang == 'nb-no') {
				await this.props.getNotifications("nb-no");
			} else if (lang == 'uk') {
				await this.props.getNotifications("uk");
			} else {
				await this.props.getNotifications("en-US");
			}

			this.setState({ loading: false });
		}
	}

	UNSAFE_componentWillReceiveProps = async (nextProps) => {
		if (nextProps.languages != null) {
			if (nextProps.notifications == null && nextProps.notifications.length < 1) {
				this.setState({ loading: true });
				const lang = getActiveLanguage()

				if (lang == 'sv-se') {
					await this.props.getNotifications("sv-SE");
				} else if (lang == 'da-dk') {
					await this.props.getNotifications("da-DK");
				} else if (lang == 'nb-no') {
					await this.props.getNotifications("nb-no");
				} else if (lang == 'uk') {
					await this.props.getNotifications("uk");
				} else {
					await this.props.getNotifications("en-US");
				}

				this.setState({ loading: false });
			}
		}
	}

	onOptionsSubmit = (values) => new Promise((resolve) => {
		resolve(1);
	});

	toggleOptionModal = () => {
		this.setState({ optionsModalVisible: !this.state.optionsModalVisible });
	}

	closeOptionsModal = () => {
		this.setState({ optionsModalVisible: false });
	}

	render() {
		const { notifications } = this.props;
		const { domains } = getEnvironment();

		return (
			<div className="section latest" style={{ margin: 0, padding: 0, border: 0 }}>
				{this.state.loading ?
					<div>
						<Alert type="news">
							<Skeleton />
						</Alert>
					</div>
					: notifications != null && notifications.length == 0 ?
						<div className="color--meta text--center weight--bold">
							{this.props.translate('no-new-notifications')}
						</div>
						: null}

				{notifications != null && notifications.length > 0 ?
					<div>
						{notifications.map((notification, i) => {
							if (notification.referenceUrl !== null) {
								var url = new URL(notification.referenceUrl);

								if (domains.length > 0 && !domains.some((domain) => { return domain === url.hostname })) {
									var correctHost = domains[0];
									url.host = correctHost;
								}

								return (
									<a href={url.href} key={i}>
										<Alert id={i} type="news" key={i}>
											<div className="subtitle">
												<FromNow utc>{notification.created}</FromNow>
											</div>

											{notification.notificationText}
										</Alert>
									</a>
								);
							}

							return (
								<Alert id={i} type="news" key={i}>
									<div className="subtitle">
										<FromNow utc>{notification.created}</FromNow>
									</div>

									{notification.notificationText}
								</Alert>
							);
						})}
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	var notifications = [];

	if (state.Notifications != null) {
		if (state.Notifications.notifications != null) {
			state.Notifications.notifications.forEach((notCol) => {
				notifications = notifications.concat(notCol.notifications);
			});

			notifications = notifications.sort((a, b) => { return b.id - a.id });
		}
	}

	return {
		notifications: notifications,
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	addError,
	getNotifications,
	setPageTitle,
	setPageActions,
})(Notifications);
