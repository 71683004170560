import React, { useEffect, useState, useMemo } from 'react';

import {
	searchCourseByTitle,
	clearCourseSearch as clear,
} from 'actions/search';

import { putData } from 'actions';
import { getCourse } from 'actions/courses';
import { toggleSelectedSection } from 'actions/sections';
import { getSchoolTypes } from 'actions/tenants'

import { useTranslate } from 'lib/translate';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'containers/Modals/Modal';
import { Spinner } from 'UI';
import { Search } from 'UI/Inputs';
import { Tabs, Tab, Button, Checkbox } from '@haldor/ui';

const SelectCourses = (props) => {
	const [searching, setSearching] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [selected, setSelected] = useState([]);
	const [addOnGroup, setAddOnGroup] = useState(true);
	const [schoolType, setSchoolType] = useState('All');
	const [year, setYear] = useState('All');
	const [searchValue, setSearchValue] = useState('');
	const sections = useSelector(state => state.sections.selected);
	const group = useSelector(state => state.assignments.section);
	const searchResultsData = useSelector(state => state.Courses.courseSearchResults);
	const courseDetails = useSelector(state => state.Courses.courses);
	const schoolTypes = useSelector(state => state.Tenant.schoolTypes);
	const YEARS = ["All", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const translate = useTranslate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (schoolTypes == null || schoolTypes.length == 0) {
			dispatch(getSchoolTypes());
		}
	}, []);

	const sectionCourses = useMemo(() => {
		if ((sections == null || sections.length == 0) && group == null) {
			return [];
		} else if ((sections == null || sections.length == 0) && group != null) {
			return group.courses;
		}

		let courses = [];
		sections.forEach((element) => {
			element.courses.forEach(course => {
				if (props.skipCourses != null) {
					if (props.skipCourses.indexOf(course.id) > -1) {
						return null;
					}
				}

				courses.push(JSON.parse(JSON.stringify(course)))
			});
		});

		const removeDuplicate = courses.filter((item, index, self) =>
			self.findIndex((t) => t.id === item.id) === index
		);

		return removeDuplicate;
	}, [sections, props.skipCourses]);

	const searchResults = useMemo(() => {
		if (searchResultsData == null || searchResultsData.length == 0) {
			return [];
		}

		return JSON.parse(JSON.stringify(searchResultsData)).filter((course) => {
			if (props.skipCourses == null) {
				return false;
			}

			return props.skipCourses.indexOf(course.id) == -1;
		}).filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
	}, [searchResultsData]);

	const onSubmit = async (event) => {
		event.preventDefault();

		setSubmitting(true);

		let values = selected;
		const missingDetails = selected.filter(course => course.details.length == 0);

		if (missingDetails.length > 0) {
			await Promise.all(
				missingDetails.map((course) => new Promise(async (resolve) => {
					await dispatch(getCourse(course.id));
					const details = courseDetails.find((c) => c.id == course.id);

					if (details != null) {
						values = values.map((course) =>
							course.id == details.id ? details : course
						);
					}

					resolve();
				}))
			);

			if (addOnGroup) {
				const groups = JSON.parse(JSON.stringify(sections));

				await Promise.all(
					groups.map((group) => new Promise(async (resolve) => {
						dispatch(toggleSelectedSection(group));

						missingDetails.forEach((course) => {
							const courseWithDetails = courseDetails.find((c) => c.id == course.id);
							group.courses.push(courseWithDetails);
						});

						await dispatch(putData('sections', group));
						dispatch(toggleSelectedSection(group));
						resolve();
					}))
				);
			}
		}

		if (props.onSubmit != null) {
			props.onSubmit(values);
		}
	}

	const onSearch = () => {
		setSelected([]);

		if (searchValue == '' && schoolType == 'All' && year == 'All') {
			dispatch(clear());
			return;
		}

		setSearching(true);

		dispatch(searchCourseByTitle(
			searchValue == '' ? null : searchValue,
			schoolType == 'All' ? '' : schoolType,
			year == 'All' ? '' : year,
		)).then(() => {
			setSearching(false);
		});
	}

	useEffect(() => {
		onSearch();
	}, [year, schoolType])

	const renderCourse = (course, index) => {
		const value = selected.find((s) => s.id == course.id) != null;
		const schoolType = translate(course.typeOfSchooling);
		const courseTitle = `${course.title} - ${course.year != null ? course.year : ''} ${course.courseCode} (${schoolType})`;

		return (
			<div className="df aic" style={{ marginBottom: '.55rem' }} key={course.id}>
				<Checkbox
					label={courseTitle}
					onChange={() => toggleCourse(course)}
					value={value}
				/>
			</div>
		);
	}

	const toggleCourse = (course) => {
		const found = selected.find((s) => s.id == course.id);
		if (found != null) {
			setSelected(selected.filter((s) => s.id != course.id));
		} else {
			setSelected([...selected, course]);
		}
	}

	const toggleAll = (checked) => {
		if (checked) {
			let courses = [];
			sections.forEach(element => {
				element.courses.forEach(course =>
					courses.push(course))
			});

			setSelected([...courses]);
			return;
		};

		setSelected([]);
	}

	const selectedSchoolType = schoolTypes?.find((type) =>
		type.typeId == schoolType
	);

	return <Modal isOpen={true} type="small news" onClose={props.onClose} overridePrompt>
		<div className="form" style={{ marginBottom: '2.5rem' }}>
			<div className="form-row">
				<div className="title">
					{translate('Courses')}
				</div>

				<Tabs onChange={() => setSelected([])}>
					<Tab title={translate('Group courses')}>
						{sectionCourses.length > 0 ?
							<>
								<Checkbox
									label={translate('All')}
									onChange={(value) => toggleAll(value)}
								/>

								<div style={{ marginLeft: '2.25rem', marginTop: '.55rem' }}>
									{sectionCourses.map((course, index) => {
										return <div key={index}>
											{renderCourse(course, index)}
										</div>
									})}
								</div>
							</>
							: null}

						<div className="df aic jcb" style={{ marginTop: '1.55rem' }}>
							<Button onClick={onSubmit} disabled={submitting || selected.length == 0}>
								{submitting ?
									<Spinner />
									: null}

								{translate('Add')}
							</Button>
						</div>
					</Tab>

					<Tab title={translate('Search courses')}>
						<Search
							onSubmit={onSearch}
							loading={searching}
							onChange={(value) => setSearchValue(value)}
							allowEmpty
						/>

						<div className="df" style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
							<div style={{ width: '47%', padding: '0px !important', marginRight: '1rem' }}>
								<label style={{ margin: 0 }}>
									{translate('Filter')}
								</label>

								<div className='select' style={{ marginTop: '.5rem' }}>
									<select
										name='schoolType'
										value={schoolType}
										onChange={(e) => setSchoolType(e.target.value)}
									>
										<option value="All">
											{translate('All')}
										</option>

										{schoolTypes?.sort((a, b) =>
											translate(a.typeId).localeCompare(translate(b.typeId))
										).map((schoolType, index) => {
											return (
												<option key={index} value={schoolType.typeId}>
													{translate(schoolType.typeId)}
												</option>
											);
										})}
									</select>
								</div>
							</div>

							{selectedSchoolType != null && selectedSchoolType.maxYear != null && selectedSchoolType.minYear != null ?
								<div style={{ padding: 0, width: 'auto', flex: 1, marginLeft: '2rem' }}>
									<label style={{ margin: 0 }}>
										{translate('course-year')}
									</label>

									<div className='select' style={{ marginTop: '8px' }}>
										<select
											name='year'
											value={year}
											onChange={(e) => setYear(e.target.value)}
										>
											{YEARS
												.filter((year) => {
													if (year == 'All') {
														return true;
													}

													return year >= selectedSchoolType?.minYear && year <= selectedSchoolType?.maxYear;
												})
												.map((year, index) => (
													<option key={index} value={year}>
														{translate(year)}
													</option>
												))}
										</select>
									</div>
								</div>
								: null}
						</div>

						<div style={{ marginTop: '1rem' }}>
							{searchResults.map(renderCourse)}
						</div>

						<div className="df aic jcb" style={{ marginTop: '1.55rem' }}>
							<Button onClick={onSubmit} disabled={submitting || selected.length == 0}>
								{submitting ?
									<Spinner />
									: null}

								{translate('Add')}
							</Button>

							<Checkbox
								value={addOnGroup}
								onChange={setAddOnGroup}
								label={translate('Add course to group')}
							/>
						</div>
					</Tab>
				</Tabs>

			</div>
		</div>
	</Modal >
}

export default SelectCourses;