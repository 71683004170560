import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import { Button, Icon } from '@haldor/ui';

import './GenerateBaseGroupsRandomly.scss';

class GenerateBaseGroupsRandomly extends Component {

	constructor(props) {
		super(props);

		this.state = {
			type: 'number-of-groups',
			amount: 0,
		};
	}

	setType = (event) => this.setState({ type: event.target.value })
	setAmount = (event) => this.setState({ amount: event.target.value })
	subtractAmount = (event) => this.setState({ amount: parseInt(this.state.amount) - 1 })
	addAmount = (event) => this.setState({ amount: parseInt(this.state.amount) + 1 })

	onReset = () => {
		this.setState({ amount: 0, type: 'number-of-groups' });

		if (this.props.onSubmit != null) {
			this.props.onSubmit([]);
		}
	}

	onSubmit = (e) => {
		if (e != null) {
			e.preventDefault();
		}

		let units = [];
		let students = [...this.props.students];

		if (this.state.amount < 1) {
			return false;
		}

		if (this.state.type == 'number-of-groups') {
			let studentsPerUnit = Math.ceil(students.length / this.state.amount);

			while (units.length < this.state.amount) {
				let unitStudents = [];

				while (unitStudents.length < studentsPerUnit && students.length > 0) {
					let index = Math.floor(Math.random() * students.length);
					unitStudents.push({
						userId: students[index].id,
						user: students[index],
					});

					students.splice(index, 1);
				}

				units.push({
					title: this.props.translate('group') + ' ' + (units.length + 1),
					memberships: unitStudents,
				});
			}
		} else if (this.state.type == 'students-per-group') {
			let numberOfUnits = Math.ceil(students.length / this.state.amount);

			while (units.length < numberOfUnits) {
				let unitStudents = [];

				while (unitStudents.length < this.state.amount && students.length > 0) {
					let index = Math.floor(Math.random() * students.length);
					unitStudents.push({
						userId: students[index].id,
						user: students[index],
					});

					students.splice(index, 1);
				}

				units.push({
					title: this.props.translate('group') + ' ' + (units.length + 1),
					memberships: unitStudents,
				});
			}
		}

		if (this.props.onSubmit != null) {
			this.props.onSubmit(units);
		}
	}

	render() {
		const types = [
			{
				value: 'number-of-groups',
				label: this.props.translate('number-of-groups'),
			},
			{
				value: 'students-per-group',
				label: this.props.translate('students-per-group'),
			}
		];

		return (
			<div className="basegroups generate">
				<div className="select">
					<select value={this.state.type} onChange={this.setType}>
						{types.map(type => {
							return <option key={type.value} value={type.value}>
								{type.label}
							</option>
						})}
					</select>
				</div>

				<div className="number-input">
					<span className="calc subtract" onClick={this.subtractAmount}>
						-
					</span>

					<input
						type="number"
						value={this.state.amount}
						onChange={this.setAmount}
						min="0"
						max="99"
					/>

					<span className="calc add" onClick={this.addAmount}>
						<Icon name="Plus" />
					</span>
				</div>

				<div className="button-container">
					<Button type="secondary" onClick={this.onSubmit}>
						{this.props.translate('generate')}
					</Button>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(GenerateBaseGroupsRandomly);
