import {
	GET_DIRECTORY_CONTENTS,
	SET_ACTIVE_DIRECTORY,
	SET_SAVED_DIR_AS_ACTIVE,
	GET_SECTION_DRIVES,
	GET_SECTION_CONTENT,
} from '../actions/onedrive';

const initialState = {
	activeFolders: [],
	activeSections: [],
	activeContent: null,
	fetchedFolders: [],
	sectionDrives: null,
	sectionContent: null,
}

export default function( state, action ) {
	const { type } = action;

	if ( typeof( state ) === 'undefined' ) {
		return initialState;
	}

	switch( type ) {
		case GET_DIRECTORY_CONTENTS:
			let savedFolders = state.fetchedFolders;
			savedFolders.push( action.payload );

			return { ...state, activeContent: action.payload, fetchedFolders: savedFolders };

		case SET_ACTIVE_DIRECTORY:
			if ( typeof( action.folder ) !== 'undefined' && action.folder !== null ) {
				if ( action.folder === 'root' || action.folder === 'sharepointroot' ) {
					return { ...state, activeFolders: [] };
				}

				let newState = state.activeFolders;

				if ( action.root ) {
					newState = [];
				}

				const found = newState.find( function( adir ) {
					return adir.id === action.folder;
				});

				if ( typeof( found ) === 'undefined' ) {
					// Not in list, add it
					// First search for this id in the fetchedFolders state
					let savedFolder = null;
					state.fetchedFolders.map( ( fdir ) => {
						if ( fdir != null ) {
							let tryer = fdir.files.find( function( file ) {
								return file.id === action.folder;
							});

							if ( typeof( tryer ) !== 'undefined' ) {
								savedFolder = tryer;
							}
						}
					});

					if ( savedFolder != null ) {
						// Then use that data for creating an object for displaying the breadcrumb item
						let folder = { id: savedFolder.id, name: savedFolder.name, folder: true };

						newState.push( folder );
					}
				} else {
					// Already in list
					const dirIndex = newState.findIndex( function( adir ) {
						return adir.id === action.folder;
					});

					if ( dirIndex < newState.length ) {
						// The current dir isnt the last in the list
						// Remove all entries behind it
						newState.splice( dirIndex + 1, newState.length );
					} else {
						// The current dir is the last in the list
						newState.splice( dirIndex, 1 );
					}
				}

				return { ...state, activeFolders: newState };
			} else {
				return { ...state, activeFolders: [] };
			}

		case SET_SAVED_DIR_AS_ACTIVE:
			if ( typeof( action.folder ) !== 'undefined' && action.folder !== null ) {
				const foundObject = state.fetchedFolders.find( function( dir ) {
					if ( dir && dir.id ) {
						return dir.id === action.folder;
					}
				});

				if ( typeof( foundObject ) !== 'undefined' ) {
					if ( foundObject.type == 'sectionContent' ) {
						return { ...state, sectionContent: foundObject };
					} else {
						return { ...state, activeContent: foundObject };
					}
				}
			} else {
				// Reset the state of the activeContent property
				return { ...state,
					activeContent: initialState.activeContent,
					sectionContent: initialState.sectionContent
				};
			}

		case GET_SECTION_DRIVES:
			return { ...state, sectionDrives: action.payload };

		case GET_SECTION_CONTENT:
			if ( action.payload != null ) {
				action.payload.type = 'sectionContent';
				let newFolders = state.fetchedFolders;
				newFolders.push( action.payload );

				return { ...state, sectionContent: action.payload, fetchedFolders: newFolders };
			}

		default:
			return state;
	}
}
