import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from 'containers/Modals/Modal';
import { Button, translate } from '@haldor/ui';

import './_SelectLinkedPosition.scss';

class SelectLinkedPosition extends Component {

	constructor(props) {
		super(props);
	}

	onModalClose = () => {
		if (this.props.onClose != null && typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	}

	render() {
		const { translate, lesson } = this.props;

		return (
			<Modal isOpen={true} onClose={this.onModalClose} overridePrompt type="linked-schedule-position">
				<h2>{translate('Select group')}</h2>

				<div className="select-lessons-container">
					{lesson.linkedSchedulePositions.map(lesson => {
						let reported = lesson.attendanceReported != null;

						return <Link to={`/lesson/${lesson.id}`} className="lesson-item" key={lesson.id}>
							<div style={{ flex: 1 }}>
								{lesson.section.title}
							</div>

							<div className="status">
								<span
									className="indicator"
									style={{ backgroundColor: reported ? '#19DB6C' : '#dbdbdb' }}
								/>

								{reported ?
									translate('reported')
									: translate('not-reported-lesson')}
							</div>
						</Link>
					})}
				</div>

				<div style={{ display: 'flex', marginTop: '2rem', justifyContent: 'flex-end' }}>
					<Button type="secondary" onClick={this.onModalClose}>
						{translate('Cancel')}
					</Button>
				</div>
			</Modal>
		)
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(SelectLinkedPosition);