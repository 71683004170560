import { ADD_ERROR } from '../actions/index';

export default function (state = [], action) {
	switch (action.type) {
		case ADD_ERROR:
			return [ ...state, action.payload ];

		default:
			return state;
			break;
	}
}
