export const GetGroupedByStudent = (adjustments, filterStatus = null, subjects = null) => {
	let groupedBy = [];

	if (adjustments === undefined || adjustments == null || adjustments.length <= 0) {
		return groupedBy;
	}

	if (filterStatus != null) {
		adjustments = adjustments.filter(adjustment => {
			return adjustment.status == filterStatus;
		});
	}

	adjustments.forEach((adjustment) => {
		let foundAdjustment = groupedBy.find(t => t.id == adjustment?.additionalAdjustment?.id);
		let student = { ...adjustment.student };

		if (foundAdjustment != null) {
			let foundStudent = foundAdjustment.items.find(t => t.userId == adjustment.studentID);
			if (foundStudent != null) {
				foundStudent.items.push(adjustment);
			} else {
				student.items = [];

				student.items.push(adjustment);
				foundAdjustment.items.push(student);
			}
		} else {
			if (adjustment.additionalAdjustment != null) {
				let obj = {
					id: adjustment.additionalAdjustment.id,
					type: adjustment.additionalAdjustment.type,
					title: adjustment.additionalAdjustment.title,
					description: adjustment.additionalAdjustment.description,
					subTitle: adjustment.additionalAdjustment.subTitle,
					status: adjustment.additionalAdjustment.status,
					deletable: adjustment.additionalAdjustment.deletable,
					items: []
				};

				student.items = [];
				student.items.push(adjustment);

				obj.items.push(student);

				groupedBy.push(obj);
			}
		}
	});

	if (subjects != null) {
		groupedBy = groupedBy.filter(additionalAdjustment => {
			additionalAdjustment.items = additionalAdjustment.items.filter((student) => {
				// Remove item if no adjustments containing courses exists
				if (student.items == null) {
					return false;
				}

				return student.items.find((adjustment) => {
					if (adjustment == null || adjustment.subjects == null) {
						return false;
					}
					return adjustment.subjects.find((subject) => {
						return subjects.indexOf(subject.id) > -1;
					}) != null;
				}) != null;
			});
			return additionalAdjustment.items.length > 0;
		});
	}

	groupedBy = groupedBy.sort((a, b) =>
		(a.title.toUpperCase() > b.title.toUpperCase()) ? 1
			:
			((b.title.toUpperCase() > a.title.toUpperCase()) ? -1 : 0)
	);

	return groupedBy;
}

export const AddUnusedAdditionalAdjustments = (arrayToAddIn, allAdjustments) => {
	allAdjustments.forEach((adjustment) => {
		let found = arrayToAddIn.find(t => t.id == adjustment.id);
		if (found == null) {
			let obj = {
				id: adjustment.id,
				title: adjustment.title,
				type: adjustment.type,
				subTitle: adjustment.subTitle,
				status: adjustment.status,
				description: adjustment.description,
				deletable: adjustment.deletable,
				items: []
			};
			arrayToAddIn.push(obj);
		}
	});

	arrayToAddIn.sort((a, b) =>
		(a.title.toUpperCase() > b.title.toUpperCase()) ? 1
			:
			((b.title.toUpperCase() > a.title.toUpperCase()) ? -1 : 0)
	);

	return arrayToAddIn;
}
