import { clearFormTemplate, getFormTemplate, getFormTemplates, getMyForms } from 'actions/templates';
import PreviewFormTemplate from 'containers/FormTemplate/Display/PreviewFormTemplate';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Checkbox, Button } from '@haldor/ui';
import { Spinner } from 'UI';
import { PRESCHOOL } from "constants/schoolTypes";

class ConnectForm extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		let template = '0';
		let targetedAudience = 'FOLLOWUP';
		if (props.initialValues != null) {
			if (props.initialValues.formTemplateID) {
				template = props.initialValues.formTemplateID;
			}
		}

		this.state = {
			loading: false,
			allChecked: false,
			template,
			targetedAudience,
			targetFollowUp: props.followUp != null ? true : false,
			targetParticipants: false,
			targetGuardians: false,
			targetStudents: false,
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		if (this.props.initialValues != null) {
			const { initialValues } = this.props;

			this.setState({ loading: true });
			this.props.getFormTemplate(initialValues.formTemplateID)
				.then(() => {
					this.setState({ loading: false });
				});
		} else {
			if (this.props.templates == null) {
				this.props.getFormTemplates();
			}
		}
	}

	componentWillUnmount = () => {
		this.props.clearFormTemplate();
	}

	/* Events */
	submit = (values) => {
		return new Promise(resolve => {
			let submits = [];
			values.formTemplateId = this.state.template;

			if (this.state.targetStudents) {
				var obj = JSON.parse(JSON.stringify(values));
				obj.targetedAudience = 'STUDENT';
				submits.push(obj);
			}

			if (this.state.targetGuardians) {
				var obj = JSON.parse(JSON.stringify(values));
				obj.targetedAudience = 'GUARDIAN';
				submits.push(obj);
			}

			if (this.state.targetFollowUp) {
				var obj = JSON.parse(JSON.stringify(values));
				obj.targetedAudience = 'FOLLOWUP';
				submits.push(obj);
			}

			if (this.props.onSubmit != null) {
				this.props.onSubmit(submits).then(() => {
					resolve(1);
				});
			} else {
				resolve(1);
			}
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onClose != null) {
			this.props.onClose();
		}
	}

	onFormChange = async (event) => {
		this.setState({ template: event.target.value });
		if (event.target.value != '0') {
			this.setState({ loading: true });
			await this.props.getFormTemplate(event.target.value)
			this.setState({ loading: false });
			this.props.change('title', this.props.formTemplate.title);
		} else {
			this.props.clearFormTemplate();
			this.props.change('title', '');
		}
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;

		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	/* Render methods */
	render() {
		const { submitting, handleSubmit, translate, valid } = this.props;

		if (submitting) {
			return <Spinner center />
		}

		let isPreschool = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL;

		let audienceValid = true;
		if (!this.state.targetStudents && !this.state.targetGuardians && !this.state.targetFollowUp) {
			audienceValid = false;
		}

		return (
			<div className="form-container">
				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					{this.props.initialValues == null ?
						<div className="form-row">
							<label>{translate('form-template')}*</label>

							<div className="select">
								<select value={this.state.template} onChange={this.onFormChange}>
									<option value="0">{this.props.translate('select-form-template')}</option>

									{this.props.templates != null ?
										this.props.templates.map(template => {
											return <option value={template.id} key={template.id}>
												{template.title}
											</option>
										})
										: null}
								</select>
							</div>
						</div>
						: null}

					<div className="form-row">
						<label>{translate('form-title')}*</label>

						<Field
							component="input"
							type="text"
							validate={this.required}
							name="title"
						/>
					</div>

					{this.props.followUp == null ?
						<div className="form-row textarea">
							<label>{translate('description')}</label>

							<Field
								component="textarea"
								name="text"
							/>
						</div>
						: null}

					{this.props.initialValues == null && this.props.followUp == null ?
						<div className="form-row">
							<label>{this.props.participants ?
								this.props.translate('who-should-answer')
								: this.props.translate('use')}*
							</label>

							{!this.props.participants && !this.props.isfollowUpAdded ?
								<div style={{ marginBottom: '0.75rem' }}>
									<Checkbox
										onChange={() => { this.setState({ targetFollowUp: !this.state.targetFollowUp }) }}
										value={this.state.targetFollowUp}
										label={translate('follow-up-to-the-meeting')}
									/>
								</div> : null}

							{!this.props.participants && !this.props.followUp ?
								<div style={{ marginBottom: '0.75rem' }}>
									<Checkbox
										onChange={() => {
											if (!this.state.targetParticipants) {
												this.setState({ targetParticipants: !this.state.targetParticipants })
											} else {
												this.setState({
													targetParticipants: !this.state.targetParticipants,
													targetStudents: false,
													targetGuardians: false,
												})
											}
										}}
										value={this.state.targetParticipants}
										label={translate('send-to-participants')}
									/>
								</div> : null}

							{this.state.targetParticipants || this.props.participants ?
								<div className="select" style={{ marginLeft: '2rem' }}>

									{!isPreschool ?
										<div style={{ marginBottom: '0.35rem' }}>
											<Checkbox
												onChange={() => { this.setState({ targetStudents: !this.state.targetStudents }) }}
												value={this.state.targetStudents}
												label={translate('Students')}
											/>
										</div> : null}

									<div>
										<Checkbox
											onChange={() => { this.setState({ targetGuardians: !this.state.targetGuardians }) }}
											value={this.state.targetGuardians}
											label={translate('Guardians')}
										/>
									</div>
								</div>
								: null}
						</div>
						: null}

					{this.state.loading ?
						<Spinner center />
						: null}

					{this.state.template != '0' && this.props.formTemplate != null ?
						<div className="form-divider" />
						: null}

					{this.state.template != '0' && this.props.formTemplate != null && !this.state.loading ?
						<div style={{ marginTop: 40 }}>
							<div className="form-row">
								<h3 style={{ marginBottom: '.5rem', fontWeight: 600 }}>{translate('preview')}</h3>
							</div>

							<PreviewFormTemplate fields={this.props.formTemplate.fields} />
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={!valid || !audienceValid || this.state.template == '0'}>
							{this.props.next ? translate('next') : translate('save')}
						</Button>

						{this.props.next ?
							<Button onClick={this.props.goToPage}>Hoppa över</Button>
							: null}

						{this.props.onClose != null ?
							<div className="pull-right">
								<Button type="secondary" onClick={this.onCancel}>
									{this.props.translate('Cancel')}
								</Button>
							</div>
							: null}

						<div className="clearfix" />
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		templates: state.Templates.templates,
		currentUser: state.user.currentUser,
		formTemplate: state.Templates.formTemplate,
	};
}

export default connect(mapStateToProps, {
	getMyForms,
	getFormTemplate,
	clearFormTemplate,
	getFormTemplates,
})(reduxForm({
	form: 'ConnectFormToMeeting',
	destroyOnUnmount: true,
})(ConnectForm));
