import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'lib/translate';

import { getWelcomeInformation, hideWelcomeWindow, updateInformationTextRead } from 'actions/information_texts';

import Modal from 'containers/Modals/Modal';
import { Checkbox } from '@haldor/ui';

const WelcomeMessage = (props) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const message = useSelector(state => state.InformationTexts.welcome);
	const hideWelcomeModal = useSelector(state => state.InformationTexts.hideWelcomeModal);
	const user = useSelector(state => state.user.currentUser);
	const [open, setOpen] = useState(true);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if(hideWelcomeModal){
			setOpen(false);
			return;
		}
		dispatch(getWelcomeInformation());
	}, []);
	
	const onClose = () => {
		setOpen(false);
		if(!disabled){
			dispatch(hideWelcomeWindow());
			return;
		}
		dispatch(updateInformationTextRead({
			"referenceType": 'INFORMATIONTEXT',
			"referenceId": message.id,
			"userType": 'USER',
			"status": disabled ? 'DONTSHOWAGAIN' : null,
		}));
	}

	const onCheckboxChange = (checked) => {
		setDisabled(checked);
	}

	if (message == null) {
		return null;
	}

	if (message.parts == null || message.parts.length == 0) {
		return null;
	}

	return <Modal type="small news" overridePrompt onClose={onClose} isOpen={open}>
		<div className="form" style={{ paddingBottom: '3rem' }}>
			<div className="form-row">
				{message.parts != null ?
					message.parts.map((part, index) => {
						return <div
							key={'part' + index}
							dangerouslySetInnerHTML={{ __html: translate(part, user.firstName) }}
						/>
					})
					: null}

				{message.showType == 'USERSET' ?
					<div style={{ marginTop: '2rem' }}>
						<Checkbox
							onChange={onCheckboxChange}
							value={disabled}
							label={translate('Do not show this message again')}
						/>
					</div>
					: null}
			</div>
		</div>
	</Modal>
}

export default WelcomeMessage;