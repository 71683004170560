import React from 'react';
import { Link } from 'react-router-dom';

import { getRootUrl } from 'helpers/url';

import DisplayAssignment from './DisplayAssignment';
import { Haldor__Block, addBlockTranslations } from '@haldor/ui';

import SelectAssignment from './SelectAssignment';
import CreateAssignment from './CreateAssignment';

import './_AssignmentBlock.scss'

class AssignmentBlock extends Haldor__Block {

	constructor(props) {
		super(props);

		this.name = 'Haldor.Blocks.Assignment';
		this.order = 47;
		this.icon = () => (
			<svg xmlns="http://www.w2.org/2000/svg" viewBox="0 0 50 50"
				dangerouslySetInnerHTML={{
					__html: `
						<defs>
							<style>.icon-block-assignment-1{fill:#c8c9c8;}</style>
						</defs>
						<path class="icon-block-assignment-1" d="M36.85,7.23H13.15a5.92,5.92,0,0,0-5.92,5.92v23.7a5.92,5.92,0,0,0,5.92,5.92h23.7a5.92,5.92,0,0,0,5.92-5.92V13.15A5.92,5.92,0,0,0,36.85,7.23ZM13.19,38.78a2,2,0,0,1-2-2l0-3.1V13.15a2,2,0,0,1,2-2h23.7a2,2,0,0,1,2,2V34.87l0,1.94a2,2,0,0,1-2,2l-22.63,0Z"/>
						<path class="icon-block-assignment-1" d="M22.78,33.31a2,2,0,0,1-1.44-.63L14.8,25.46a2,2,0,0,1,2.88-2.71l5.08,5.68,9.46-11a2,2,0,0,1,2.78-.3,2,2,0,0,1,.3,2.77l-.15.18L24.24,32.66a2,2,0,0,1-1.44.65Z"/>
					`
				}}
			/>
		)

		addBlockTranslations('sv-se', [
			{
				output: 'Uppgifter',
				id: 'Haldor.Blocks.Assignment',
			}
		]);

		addBlockTranslations('en-us', [
			{
				output: 'Assignments',
				id: 'Haldor.Blocks.Assignment',
			}
		]);

		addBlockTranslations('nb-no', [
			{
				output: 'Oppgaver',
				id: 'Haldor.Blocks.Assignment',
			}
		]);

		addBlockTranslations('da-dk', [
			{
				output: 'Opgaver',
				id: 'Haldor.Blocks.Assignment',
			}
		]);

		addBlockTranslations('uk', [
			{
				output: 'Задачі',
				id: 'Haldor.Blocks.Assignment',
			}
		]);

		this.registerResourceType('haldor.assignment.block.assignment', {
			id: 'number',
			title: 'string',
			assignmentType: 'string?',
			description: 'string?',
			dueDate: 'string',
			groupEnabled: 'boolean',
			publishedAssessments: 'number',
			sectionId: 'string',
			startDate: 'string',
			status: 'string',
			submittedTasks: 'number',
			totalTasks: 'number',
		});

		this.registerResourceType('haldor.assignment.block.teamsassignment', {
			id: 'string',
			displayName: 'string',
			dueDateTime: 'string',
			status: 'string',
			classId: 'string',
			allowLateSubmissions: 'boolean',
		});
	}

	onAssignmentSelect = (assignment) => {
		let foundInResources = this.getResources().findIndex(resource => resource.id == assignment.id)

		if (foundInResources < 0) {
			const isHaldorAssignment = assignment.id == parseInt(assignment.id, 10);

			if (isHaldorAssignment) {
				this.addResource('haldor.assignment.block.assignment', {
					...assignment,
					existing: true,
				});
			} else {
				this.addResource('haldor.assignment.block.teamsassignment', {
					...assignment,
					existing: true,
				});
			}
		}
	}

	onAssignmentCreated = (assignments) => {
		if (assignments != null) {
			assignments.forEach(assignment => {
				this.addResource('haldor.assignment.block.assignment', assignment);
			})
		}
	}

	consume = (props) => {
		return <div className="block--assignment-block consume">
			{props.resources != null && props.resources.length > 0 ?
				props.resources.map(resource => {
					const assignmentId = resource.origin != null && resource.origin.toUpperCase() == 'TEAMS' ? resource.externalID : resource.id;
					return <Link className="assignment-resource" to={`${getRootUrl()}assignment/${assignmentId}`} key={assignmentId}>
						<DisplayAssignment assignment={resource} />
					</Link>
				})
				: null}
		</div>
	}

	edit = (props) => {
		const editor = this.api.getEditor();

		let planningAssessments = null;
		if (editor.getProp('planningAssessments') != null) {
			planningAssessments = JSON.parse(JSON.stringify(editor.getProp('planningAssessments')));
		}

		return (
			<div className="block--assignment-block">
				{props.resources != null && props.resources.length > 0 ?
					props.resources.map(resource => {
						return <div className="assignment-resource" key={resource.id}>
							<DisplayAssignment remove={() => this.removeResource(resource)} assignment={resource} />
						</div>
					})
					: null}

				<SelectAssignment onPicked={this.onAssignmentSelect} />
				<CreateAssignment
					planningAssessments={planningAssessments}
					onCreated={this.onAssignmentCreated}
				/>
			</div>
		);
	}
}

export default AssignmentBlock;