import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import User from '_class/User';
import DateTime from '_class/DateTime';
import { getAttendanceColor } from 'helpers/attendance';

import { getScheduleItem } from 'actions/schedule';

import { Spinner } from 'UI';
import { Button, Checkbox, ButtonGroup, Flex } from '@haldor/ui';

class EditAttendanceForm extends Component {

	constructor(props) {
		super(props);

		let status = null;
		let valid = false;
		let lateDuration = '0';
		if (props.attendance != null) {
			status = props.attendance.type;
			valid = props.attendance.type.indexOf('VALID') == 0;
			lateDuration = '' + props.attendance.lateDuration;
		}

		this.state = {
			status,
			valid,
			lateDuration,
			loading: true,
		}
	}

	componentDidMount = () => {
		if (this.props.attendance != null) {
			this.props.getScheduleItem(this.props.attendance.calendarEventId)
				.then(() => {
					this.setState({ loading: false });
				})
		}
	}

	cancel = (event) => {
		event.preventDefault();

		if (this.props.onClose != null) {
			this.props.onClose();
		}
	}

	submit = () => {
		let values = { ...this.props.attendance };

		return new Promise((resolve) => {
			values.type = this.state.status;
			values.lateDuration = this.state.lateDuration;

			if (values.type.indexOf('PRESENT') > -1) {
				values.attendanceType = 'PRESENT';
			}

			if (values.type.indexOf('ABSENCE') > -1) {
				values.attendanceType = 'ABSENT';
			}

			if (values.type.indexOf('LATE') > -1) {
				values.attendanceType = 'LATE';
			}

			if (this.props.onSubmit != null) {
				this.props.onSubmit(values).then(() => {
					resolve(1);

					if (this.props.onClose != null) {
						this.props.onClose();
					}
				});
			} else {
				resolve(1);
			}
		});
	}

	onSelectStatus = (status) => {
		if (status == 'PRESENT') {
			this.setState({ status: 'PRESENT' });

			return;
		}

		if (status == 'ABSENCE') {
			if (this.state.valid) {
				this.setState({ status: 'VALID_ABSENCE' });
			} else {
				this.setState({ status: 'INVALID_ABSENCE' });
			}

			return;
		}

		if (status == 'LATE') {
			if (this.state.valid) {
				this.setState({ status: 'VALID_LATE' });
			} else {
				this.setState({ status: 'INVALID_LATE' });
			}

			return;
		}
	}

	toggleValid = () => {
		const { status } = this.state;
		let newStatus = status;

		if (this.state.valid) {
			if (status.indexOf('ABSENCE') > -1) {
				newStatus = 'INVALID_ABSENCE';
			}

			if (status.indexOf('LATE') > -1) {
				newStatus = 'INVALID_LATE';
			}
		} else {
			if (status.indexOf('ABSENCE') > -1) {
				newStatus = 'VALID_ABSENCE';
			}

			if (status.indexOf('LATE') > -1) {
				newStatus = 'VALID_LATE';
			}
		}

		this.setState({ valid: !this.state.valid, status: newStatus });
	}

	onMinutesChange = (value) => {
		value = value.replace(/[^\d]/, '');

		if (parseInt(value) !== 0) {
			if (value > this.props.scheduleItem.length) {
				value = this.props.scheduleItem.length;
			}
			this.setState({ lateDuration: value });
		}
	}

	getIndicator = (attendance) => {
		const { translate } = this.props;

		if (attendance.type == null) {
			attendance.type = attendance.attendanceType;
		}

		const indicatorColor = getAttendanceColor(attendance);

		return (
			<div className="status">
				<span className="indicator" style={{ backgroundColor: indicatorColor }} />

				<span className="text">
					{translate(attendance.type.toLowerCase())}
				</span>
			</div>
		);
	}

	getReportedBy = (item) => {
		const { translate } = this.props;

		if (item.reportedBy != null && item.reportedBy.firstName != null) {
			const reportedBy = new User(item.reportedBy);
			return reportedBy.getName()
		}

		if (item.reportedBy != null) {
			if (item.reportedBy.userId == 'TrackAttendance') {
				return translate('system');
			}

			if (item.reportedBy != '') {
				return translate('Guardian')
			}
		}

		return null;
	}

	renderStatusSelect = () => {
		const { status } = this.state;

		return (
			<ButtonGroup>
				{status == 'PRESENT' ?
					<Button
						onClick={e => { e.preventDefault(); this.onSelectStatus('PRESENT') }}
						style={{ backgroundColor: '#4baf4d', borderColor: '#4baf4d' }}
					>
						{this.props.translate('present')}
					</Button>
					:
					<Button type="secondary" onClick={e => { e.preventDefault(); this.onSelectStatus('PRESENT') }}>
						{this.props.translate('present')}
					</Button>
				}

				{status == 'INVALID_ABSENCE' || status == 'VALID_ABSENCE' ?
					<Button
						onClick={e => { e.preventDefault(); this.onSelectStatus('ABSENCE') }}
						style={{ backgroundColor: '#df2d2e', borderColor: '#df2d2e' }}
					>
						{this.props.translate('absent-whole')}
					</Button>
					:
					<Button type="secondary" onClick={e => { e.preventDefault(); this.onSelectStatus('ABSENCE') }}>
						{this.props.translate('absent-whole')}
					</Button>
				}

				{status == 'INVALID_LATE' || status == 'VALID_LATE' ?
					<Button
						onClick={e => { e.preventDefault(); this.onSelectStatus('LATE') }}
						style={{ backgroundColor: '#f38234', borderColor: '#f38234' }}
					>
						{this.props.translate('late-partly')}
					</Button>
					:
					<Button type="secondary" onClick={e => { e.preventDefault(); this.onSelectStatus('LATE') }}>
						{this.props.translate('late-partly')}
					</Button>
				}
			</ButtonGroup>
		)
	}

	render() {
		const { handleSubmit, submitting, scheduleItem, translate } = this.props;
		const { status } = this.state;

		let history = [];
		let absences = [];

		if (this.state.loading) {
			return <div className="form-container">
				<Spinner center />
			</div>
		}

		if (this.props.attendance != null) {
			if (this.props.attendance.history != null) {
				history = this.props.attendance.history;
			}

			if (this.props.attendance.absences != null) {
				absences = this.props.attendance.absences;
			}
		}

		return (
			<div className="form-container attendance-history">
				{submitting ?
					<div className="is_sending">
						<p><span className="loading-spinner" /></p>
					</div>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
							{translate('time')}
						</div>

						{scheduleItem != null ?
							new DateTime(scheduleItem.startTime).getLongDateWithTime().capitalize()
							: null}

						<div style={{ marginTop: '0.5rem' }}>
							{scheduleItem?.section != null ?
								<div className="card-meta" style={{ marginBottom: 0 }}>
									<Link style={{ margin: 0, textDecoration: 'none', color: '#363636' }} to={`/groups/${scheduleItem.section.id}`}>
										{scheduleItem.section.title}
									</Link>
								</div>
								: null}

							{scheduleItem?.attendanceReported != null ?
								<div className="card-meta" style={{ marginLeft: '0.55rem', marginBottom: 0 }}>
									{translate('reported')}
								</div>
								: null}

							<div className="clearfix"></div>
						</div>
					</div>

					<div className="form-row" style={{ marginTop: '1rem' }}>
						<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
							{translate('room')}
						</div>

						{scheduleItem?.room}
					</div>

					<div className="form-row" style={{ marginTop: '1rem' }}>
						<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
							{translate('week-day')}
						</div>

						{scheduleItem != null ?
							new DateTime(scheduleItem.startTime).getDay().capitalize()
							: null}
					</div>

					<div className="form-row" style={{ marginTop: '1rem' }}>
						<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
							{translate('length')}
						</div>

						{scheduleItem?.length} {translate('minutes')}
					</div>

					<div className="form-row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
						<Flex space center>
							<div style={{ flexGrow: 0 }}>
								{this.renderStatusSelect()}
							</div>

							{status == 'INVALID_LATE' || status == 'VALID_LATE' ?
								<div style={{ flexGrow: 0 }}>
									<input
										style={{ width: 60, minHeight: 40, padding: 8, marginRight: '0.3rem' }}
										type="number"
										min="0"
										max="500"
										value={this.state.lateDuration}
										onChange={e => this.onMinutesChange(e.target.value)}
									/>

									{translate('minutes')}
								</div>
								: null}

							{status != 'PRESENT' ?
								<div style={{ flexGrow: 0 }}>
									<Checkbox
										value={this.state.valid}
										label={translate('mark-for-valid-absence')}
										onChange={this.toggleValid}
									/>
								</div>
								: null}
						</Flex>
					</div>

					{history.length > 0 ?
						<div className="form-row spacing" style={{ marginTop: '1rem' }}>
							<h3>{translate('Reports on lesson')}</h3>

							<table className="x">
								<thead>
									<tr>
										<th>{translate('Status')}</th>
										<th>{translate('Period')}</th>
										<th>{translate('Valid absence')}</th>
										<th>{translate('Minutes')}</th>
										<th>{translate('Reported by')}</th>
									</tr>
								</thead>

								<tbody>
									{history.map((item) => {
										return <tr key={item.id}>
											<td>{this.getIndicator(item)}</td>
											<td>
												{translate('From')}: {new DateTime(item.startTime).getHourStamp()}

												{item.endTime != null ?
													<div>{translate('To')}: {new DateTime(item.endTime).getHourStamp()}</div>
													:
													<div>{translate('To')}: {new DateTime(item.startTime).add(item.length, 'minutes').getHourStamp()}</div>
												}
											</td>
											<td>
												{item.type.indexOf('VALID') == 0 ?
													translate('Yes')
													: translate('No')}
											</td>
											<td>{item.lateDuration}</td>
											<td>{this.getReportedBy(item)}</td>
										</tr>
									})}
								</tbody>
							</table>
						</div>
						: null}

					{absences.length > 0 ?
						<div className="form-row spacing" style={{ marginTop: '1rem' }}>
							<h3>{translate('Reported absence')}</h3>

							<table className="x">
								<thead>
									<tr>
										<th>{translate('Status')}</th>
										<th>{translate('Absence length')}</th>
										<th>{translate('Valid absence')}</th>
										<th>{translate('Reported by')}</th>
									</tr>
								</thead>

								<tbody>
									{absences.map((absence) => {
										return <tr key={absence.id}>
											<td>{this.getIndicator(absence)}</td>

											<td>
												{translate('From')}: {new DateTime(absence.startTime).getHourStamp()}

												{absence.endTime != null ?
													<div>{translate('To')}: {new DateTime(absence.endTime).getHourStamp()}</div>
													:
													<div>{translate('To')}: {new DateTime(absence.startTime).add(absence.length, 'minutes').getHourStamp()}</div>
												}
											</td>

											<td>
												{absence.type.indexOf('VALID') == 0 ?
													translate('Yes')
													: translate('No')}
											</td>

											<td>
												{this.getReportedBy(absence)}
											</td>
										</tr>
									})}
								</tbody>
							</table>
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button>
							{translate('make-report')}
						</Button>

						<div className="align-right">
							<Button type="secondary" onClick={this.cancel}>
								{translate('Cancel')}
							</Button>
						</div>
					</div>

				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		scheduleItem: state.schedule.activeScheduleItem,
	}
}

export default connect(mapStateToProps, {
	getScheduleItem,
})(reduxForm({
	form: 'EditAttendance',
	destroyOnUnmount: true,
})(EditAttendanceForm));