import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

class Onboarding extends Component {
	constructor(props) {
		super(props);

		this.state = {
			onboarding: false,
		}
	}

	render() {
		const { translate } = this.props;

		return (
			<Joyride
				steps={this.props.steps}
				continuous={true}
				locale={{
					back: translate('back'),
					close: translate('close'),
					last: translate('close'),
					next: translate('next'),
					skip: translate('skip'),
				}}
				run={this.props.onboarding}
				callback={(t) => {
					if (t.action == "close" || t.action == "reset") {
						this.setState({ onboarding: false });
						if (this.props.onExit != null) {
							this.props.onExit();
						}
					}
				}}
				scrollToFirstStep
				showProgress={true}
				scrollOffset={150}
				styles={{
					options: {
						primaryColor: '#005da9',
					},
					buttonBack: {
						appearance: 'none',
						color: '#0f0f0f',
						backgroundColor: '#fff',
						border: '1px solid #c9c9c9',
						boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
						borderRadius: '5px',
						padding: '0.85rem 1.7rem',
						fontWeight: 300,
						fontSize: '0.9rem',
						letterSpacing: '0.14px',
						cursor: 'pointer',
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontFamily: 'Open Sans',
					},
					buttonNext: {
						appearance: 'none',
						color: 'white',
						backgroundColor: '#005da9',
						border: '1px solid #005da9',
						boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
						borderRadius: '5px',
						padding: '0.85rem 1.7rem',
						fontWeight: 300,
						fontSize: '0.9rem',
						letterSpacing: '0.14px',
						cursor: 'pointer',
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						fontFamily: 'Open Sans',
					},
				}}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(Onboarding);
