import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import MatrixAssessmentBlock from "./MatrixAssessment/MatrixAssessmentBlock";
import MatrixDisplayBlock from "./MatrixDisplay/MatrixDisplayBlock";

class ListPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assessment: [],
			descriptionMatrix: []
		};

		this.onMatrixAssessmentUpdate.bind(this);
	}

	onMatrixAssessmentUpdate(block) {
		let assessment = this.state.assessment;
		var findBlock = assessment.find(findBlock => block.id == findBlock.id);
		if (findBlock == null) {
			assessment.push(block);
		} else {
			findBlock.steps = block.steps;
		}
		this.setState({ assessment });
	}

	onMatrixDescriptionAssessmentUpdate(block) {
		let assessment = this.state.descriptionMatrix;
		var findBlock = assessment.find(findBlock => block.id == findBlock.id);
		if (findBlock == null) {
			assessment.push(block);
		} else {
			findBlock.steps = block.steps;
		}
		this.setState({ descriptionMatrix: assessment });
	}

	render() {
		return (
			<div className="container">
				{this.props.matrixes.map(matrix => {
					return (
						<div key={matrix.id}>
							{matrix.title}{" "}
							<Link to={`/editMatrix/${matrix.id}`}>Edit</Link>
						</div>
					);
				})}

				<div>
					<h1>ASSESSMENT FORM</h1>
					{this.props.matrixes[0].matrixBlocks.map(block => {
						return (
							<MatrixAssessmentBlock
								key={block.id}
								block={block}
								onMatrixAssessmentUpdate={this.onMatrixAssessmentUpdate.bind(
									this
								)}
								type="multiple"
							/>
						);
					}, this)}

					<div>
						<pre>
							{JSON.stringify(this.state.assessment, null, 2)}
						</pre>
					</div>
				</div>

				<div>
					<h1>DESCRIPTION ASSESSMENT FORM</h1>
					{this.props.matrixes[1].matrixBlocks.map(block => {
						return (
							<MatrixAssessmentBlock
								key={block.id}
								block={block}
								onMatrixAssessmentUpdate={this.onMatrixDescriptionAssessmentUpdate.bind(
									this
								)}
								type="single"
							/>
						);
					}, this)}

					<div>
						<pre>
							{JSON.stringify(
								this.state.descriptionMatrix,
								null,
								2
							)}
						</pre>
					</div>
				</div>

				<div>
					<h1>MATRIX DISPLAY BLOCK</h1>
					{this.props.matrixes[0].matrixBlocks.map(block => {
						return (
							<MatrixDisplayBlock key={block.id} block={block} />
						);
					}, this)}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		matrixes: state.Matrix.matrixList
	};
}

export default connect(mapStateToProps, {})(ListPage);
