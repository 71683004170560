import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import User from '_class/User';

import { setPageTitle, setPageActions } from 'actions/header';

import {
	getSchoolAdjustments,
	getAdditionalAdjustments,
	updateAdditionalAdjustment,
	getStudentAdjustments,
	updateStudentAdjustment,
	deleteAdditionalAdjustment,
	deleteStudentAdjustment,
} from 'actions/additional_adjustments';

import { clearSearchResults } from 'actions/search';
import { getStudentAdditionalAdjustmentsForGroup } from 'actions/sections';

import { GetGroupedByStudent, AddUnusedAdditionalAdjustments } from '../Helpers';
import AssignAdditionalAdjustments from './AssignAdditionalAdjustments';
import StudentAdjustment from 'containers/AdditionalAdjustments/Display/StudentAdjustment';

import EditStudentAdjustmentForm from '../Form/EditStudentAdjustmentForm';
import CreateAdditionalAdjustment from './CreateAdditionalAdjustment';
import Redirect from 'components/Redirect/Redirect';

import { Block, Icon, TooltipMenu, Flex, Button, Tabs, Tab, translate } from '@haldor/ui';
import Modal from 'containers/Modals/Modal';
import { Spinner, Collapsible, Person } from 'UI';

import '../Display/GroupAdjustments.scss';
import './AdministrateAdjustments.scss';

class AdministrateAdjustments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editAdjustment: false,
			createAdjustmentModal: false,
			assignAdjustmentModal: false,
			loading: true,
			activeStudent: null,
			activeStudentAdjustment: null,
			adjustment: null,
			selectedStatus: null,
			selectedGroup: '0',
			studentAdjustmentsModal: false,
			loadingStudentAdjustments: false,
			studentAdjustmentId: null
		}
	}

	componentDidMount() {
		this.props.setPageTitle(this.props.translate('additional-adjustments'));

		let promises = [this.props.getSchoolAdjustments(), this.props.getAdditionalAdjustments()];
		Promise.all(promises).then(() => {
			this.setState({ loading: false });
		})

		let actions = []
		actions.push({
			type: 'button',
			value: this.props.translate('Create additional adjustment template'),
			onClick: this.toggleCreateAdditionalAdjustment,
			icon: 'plus',
		});

		actions.push({
			type: 'button',
			value: this.props.translate('assign-adjustment'),
			onClick: this.toggleAssignStudentAdditionalAdjustment,
			icon: 'plus',
		});

		this.props.setPageActions(actions);
	}

	/* Events */
	onStudentAdjustmentEdit = (studentAdjustment) => {
		this.setState({ editAdjustment: true, adjustment: studentAdjustment });
	}

	onStudentClick = (student) => {
		this.setState({ activeStudent: student, studentAdjustmentsModal: true, loadingStudentAdjustments: true });
		var fetchStudent = false;

		if (this.props.studentAdjustments != null && this.props.studentAdjustments.length > 0) {
			if (this.props.studentAdjustments[0].studentID != student.userId) {
				fetchStudent = true;
			} else {
				let adjustmentId = student.items[0].adjustmentID;
				this.setState({ loadingStudentAdjustments: false, studentAdjustmentId: adjustmentId });
			}
		} else {
			fetchStudent = true;
		}

		if (fetchStudent) {
			this.props.getStudentAdjustments(student.userId).then(() => {
				let adjustmentId = student.items[0].adjustmentID;
				this.setState({ loadingStudentAdjustments: false, studentAdjustmentId: adjustmentId });
			});
		}
	}

	closeStudent = () => {
		this.setState({ activeStudent: null, studentAdjustmentsModal: false });
	}

	toggleStudentAdjustment = (adjustment) => {
		if (adjustment != null) {
			this.setState({ activeStudentAdjustment: adjustment.id });
		} else {
			this.setState({ activeStudentAdjustment: null });
		}
	}

	updateAdjustmentStatus = (adjustment, status) => {
		let values = { ...adjustment };
		values.status = status;

		this.props.updateAdditionalAdjustment(values).then(() => {
			let promises = [this.props.getSchoolAdjustments(), this.props.getAdditionalAdjustments()];
			Promise.all(promises);
		});
	}

	updateStudentAdjustmentStatus = (studentAdjustment, status) => {
		let values = { ...studentAdjustment };
		values.status = status;

		// Clean up properties that doesnt need to be sent to the api
		delete values.comments;
		delete values.courses;
		delete values.createdBy;
		delete values.editedBy;
		delete values.versions;
		delete values.student;

		this.props.updateStudentAdjustment(values);
	}

	deleteStudentAdjustment = (studentAdjustment) => {
		this.props.deleteStudentAdjustment(studentAdjustment);
	}

	toggleCreateAdditionalAdjustment = () => {
		this.setState({ createAdjustmentModal: !this.state.createAdjustmentModal, adjustment: null });
	}

	toggleAssignStudentAdditionalAdjustment = () => {
		if (this.state.assignAdjustmentModal == false) {
			this.setState({ assignAdjustmentModal: !this.state.assignAdjustmentModal });
		} else {
			this.setState({ loading: true, assignAdjustmentModal: !this.state.assignAdjustmentModal });

			let promises = [
				this.props.getSchoolAdjustments(),
				this.props.getAdditionalAdjustments(),
				this.props.clearSearchResults()
			];

			Promise.all(promises).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	onStudentAdjustmentSubmit = (values) => {
		return new Promise(resolve => {
			// Clean up properties that doesnt need to be sent to the api
			delete values.comments;
			delete values.courses;
			delete values.createdBy;
			delete values.editedBy;
			delete values.versions;
			delete values.student;

			this.props.updateStudentAdjustment(values).then(() => {
				resolve(1);

				this.setState({ editAdjustment: false, adjustment: null });
			})
		})
	}

	onAdjustmentDelete = (adjustment) => {
		this.props.deleteAdditionalAdjustment(adjustment);
	}

	onStatusSelect = (event) => {
		if (event.target.value == '0') {
			this.setState({ selectedStatus: null });
			return true;
		}

		this.setState({ selectedStatus: event.target.value });
	}

	onGroupSelect = (event) => {
		this.setState({ selectedGroup: event.target.value });

		if (event.target.value != '0') {
			this.setState({ loading: true });
			this.props.getStudentAdditionalAdjustmentsForGroup(event.target.value)
				.then(() => {
					this.setState({ loading: false });
				});
		}
	}

	clearFilter = () => {
		this.setState({ selectedGroup: "0", selectedStatus: null, loading: true });
		let promises = [this.props.getSchoolAdjustments(), this.props.getAdditionalAdjustments()];
		Promise.all(promises).then(() => {
			this.setState({ loading: false });
		})
	}

	editAdjustment = (adjustment) => {
		this.setState({ adjustment, createAdjustmentModal: true });
	}

	toggleEditModal = () => {
		this.setState({ editAdjustment: false, adjustment: null });
	}

	/* Render methods */
	renderModals() {
		let adjustments = this.props.schoolAdjustments;

		if (this.state.selectedGroup != '0') {
			adjustments = this.props.groupAdjustments;
		}

		var completed = [];
		this.props.studentAdjustments.forEach(adjustment => {
			if (adjustment.adjustmentID == this.state.studentAdjustmentId && adjustment.status == 'COMPLETED') {
				completed.push(adjustment);
			}
		});

		let studentAdjustmentModalTitle = '';
		let studentAdjustment = null;
		if (this.state.activeStudentAdjustment != null) {
			studentAdjustment = adjustments.find(adj => adj.id == this.state.activeStudentAdjustment);

			if (studentAdjustment != null) {
				studentAdjustmentModalTitle += studentAdjustment.student.firstName + ' ' + studentAdjustment.student.lastName;
				studentAdjustmentModalTitle += ' - ' + studentAdjustment.additionalAdjustment.title;
				if (studentAdjustment.additionalAdjustment.subTitle != null) {
					studentAdjustmentModalTitle += ' - ' + studentAdjustment.additionalAdjustment.subTitle;
				}
			}
		}

		return (
			<div>
				<Modal
					isOpen={studentAdjustment != null}
					onClose={() => this.toggleStudentAdjustment()}
					title={studentAdjustmentModalTitle}
					type="small"
				>
					<div className="studentAdjustmentModal">
						<StudentAdjustment adjustment={studentAdjustment} />
					</div>
				</Modal>

				<Modal isOpen={this.state.editAdjustment} onClose={this.toggleEditModal} title={this.props.translate('Edit adjustment')}>
					<EditStudentAdjustmentForm
						adjustment={this.state.adjustment}
						onClose={this.toggleEditModal}
						onSubmit={this.onStudentAdjustmentSubmit}
					/>
				</Modal>

				<Modal isOpen={this.state.createAdjustmentModal} onClose={this.toggleCreateAdditionalAdjustment} title={this.props.translate('Create additional adjustment template')}>
					<CreateAdditionalAdjustment onClose={this.toggleCreateAdditionalAdjustment} editAdjustment={this.state.adjustment} />
				</Modal>

				<Modal isOpen={this.state.assignAdjustmentModal} onClose={this.toggleAssignStudentAdditionalAdjustment} title={this.props.translate('assign-adjustment')}>
					<AssignAdditionalAdjustments onClose={this.toggleAssignStudentAdditionalAdjustment} />
				</Modal>

				<Modal
					isOpen={this.state.studentAdjustmentsModal}
					onClose={this.closeStudent}
					title={(this.state.activeStudent?.firstName || '') + ' ' + (this.state.activeStudent?.lastName || '')}
				>
					<div className="studentAdjustmentModal">
						{this.state.loadingStudentAdjustments ? <Spinner center />
							: this.props.studentAdjustments.map((adjustment) => {
								if (adjustment.adjustmentID == this.state.studentAdjustmentId && (this.state.selectedStatus == null || adjustment.status == this.state.selectedStatus)) {
									return (
										<StudentAdjustment key={adjustment.id} adjustment={adjustment} />
									);
								}
							})}

						{this.state.selectedStatus == null && this.state.loadingStudentAdjustments == false && completed.length > 0 ?
							<Collapsible trigger={this.props.translate('completed')}>
								{completed.map(adjustment => {
									return (
										<div style={{ padding: '4px' }} key={adjustment.id} >
											<StudentAdjustment adjustment={adjustment} />
										</div>
									);
								})}
							</Collapsible>
							: null}
					</div>
				</Modal>
			</div>
		);
	}

	renderFilters = () => {
		const { translate } = this.props;

		return (
			<div className="form filter-container" style={{ marginBottom: '2rem' }}>
				<Flex>
					<div className="select">
						{translate('section')}

						<select value={this.state.selectedGroup} onChange={this.onGroupSelect}>
							<option value="0">{translate('all-groups')}</option>

							{this.props.groups != null ? this.props.groups.map(group => {
								return <option value={group.id} key={group.id}>
									{group.title}
								</option>
							}) : null}
						</select>
					</div>

					<div className="select">
						{translate('Status on the assigned student adjustment')}

						<select value={this.state.selectedStatus} onChange={this.onStatusSelect}>
							<option value="0">{this.props.translate('All')}</option>
							<option value="ACTIVE">{this.props.translate('active')}</option>
							<option value="COMPLETED">{this.props.translate('adjustment-closed')}</option>
							<option value="DELETED">{this.props.translate('Deleted')}</option>
						</select>
					</div>
				</Flex>

				{this.state.selectedStatus != null || this.state.selectedGroup != '0' ?
					<div style={{ marginTop: '1rem' }}>
						<Button type="secondary" onClick={() => { this.clearFilter() }}>
							{this.props.translate("clear")}
						</Button>
					</div>
					: null}
			</div>
		);
	}

	renderAdjustmentTrigger = (adjustment) => {
		const dropdownTrigger = (<div className="dropdown--trigger">
			<Icon name="Bullets" />
		</div>);

		const user = new User(this.props.user);
		const count = [...adjustment.items].filter(student => {
			let hasActive = student.items.find(adj => adj.status == 'ACTIVE');
			return hasActive != null;
		}).length;

		return (
			<div className="adj--content">
				<div className="adj--title">
					<b>{adjustment.title} {adjustment.subTitle != null ? " - " + adjustment.subTitle : null}</b>
				</div>

				<div className="adj--badge">
					{count}
				</div>

				<TooltipMenu trigger={dropdownTrigger}>
					{adjustment.type == "CUSTOM" ?
						<TooltipMenu.Item onClick={() => { this.editAdjustment(adjustment) }}>
							{this.props.translate('Edit')}
						</TooltipMenu.Item>
						: null}

					{adjustment.status == 'ACTIVE' ?
						<TooltipMenu.Item onClick={() => { this.updateAdjustmentStatus(adjustment, 'ARCHIVED') }}>
							{this.props.translate('Archive')}
						</TooltipMenu.Item>
						: null}

					{adjustment.status == 'ARCHIVED' ?
						<TooltipMenu.Item onClick={() => { this.updateAdjustmentStatus(adjustment, 'ACTIVE') }}>
							{this.props.translate('Activate')}
						</TooltipMenu.Item>
						: null}

					{adjustment.status == 'DELETED' ?
						<TooltipMenu.Item onClick={() => { this.updateAdjustmentStatus(adjustment, 'ARCHIVED') }}>
							{this.props.translate('Reset')}
						</TooltipMenu.Item>
						: null}

					{adjustment.status == 'ARCHIVED' ?
						<TooltipMenu.Item onClick={() => { this.updateAdjustmentStatus(adjustment, 'DELETED') }}>
							{this.props.translate('Delete')}
						</TooltipMenu.Item>
						: null}

					{adjustment.status == 'DELETED' && adjustment.deletable && user.isPedagogue() ?
						<TooltipMenu.Item onClick={() => { this.onAdjustmentDelete(adjustment) }}>
							{this.props.translate('Delete permanently')}
						</TooltipMenu.Item>
						: null}
				</TooltipMenu>
			</div>
		)
	}

	renderStudents = (status) => {
		let students = [];
		let adjustments = this.props.schoolAdjustments;

		if (this.state.selectedGroup != '0') {
			adjustments = this.props.groupAdjustments;
		}

		if (adjustments == null) {
			return null;
		}

		if (status != null) {
			adjustments = adjustments.filter(adjustment =>
				adjustment.status == status
			);
		}

		if (this.state.selectedStatus != null) {
			adjustments = adjustments.filter(adjustment =>
				adjustment.status == this.state.selectedStatus
			);
		}

		adjustments = adjustments.sort((a, b) =>
			a.student.firstName == null || b.student.firstName == null ? 0
				: a.student.firstName.localeCompare(b.student.firstName)
		)

		adjustments.forEach(adjustment => {
			if (students.indexOf(adjustment.studentID) == -1) {
				students.push(adjustment.studentID);
			}
		});

		return (
			<Block>
				{this.state.loading ?
					<Spinner center /> :
					students.map(studentId => {
						let foundAdjustments = [...adjustments].filter(adjustment =>
							adjustment.studentID == studentId
						);

						let student = null;

						if (foundAdjustments.length > 0) {
							student = foundAdjustments[0].student;
						}

						if (student == null || student.firstName == null || student.userId == null) {
							return null;
						}

						const trigger = (
							<div className="adj--content">
								<div className="adj--title">
									<Person person={student} />
								</div>

								<div className="adj--badge">
									{foundAdjustments.length}
								</div>
							</div>
						)

						const dropdownTrigger = (<div className="dropdown--trigger">
							<Icon name="Bullets" />
						</div>);

						return <div className="adjustment" key={studentId}>
							<Collapsible trigger={trigger}>
								<div style={{ padding: '0.65rem 0' }}>
									{foundAdjustments.map(adjustment => {
										return <div style={{ clear: 'both', margin: '0 1.55rem .65rem 5.2rem' }} key={adjustment.id}>
											<div className="additionalAdjustmentTrigger" onClick={() => this.toggleStudentAdjustment(adjustment)}>
												{adjustment.additionalAdjustment.title}
												{adjustment.additionalAdjustment.subTitle != null ?
													' - ' + adjustment.additionalAdjustment.subTitle
													: null}
											</div>

											<div style={{ float: 'right' }}>
												<TooltipMenu trigger={dropdownTrigger}>
													<TooltipMenu.Item onClick={() => { this.onStudentAdjustmentEdit(adjustment) }}>
														{this.props.translate('Edit')}
													</TooltipMenu.Item>

													{adjustment.status == 'ACTIVE' ?
														<TooltipMenu.Item onClick={() => { this.updateStudentAdjustmentStatus(adjustment, 'COMPLETED') }}>
															{this.props.translate('Complete')}
														</TooltipMenu.Item>
														: null}

													{adjustment.status == 'COMPLETED' ?
														<TooltipMenu.Item onClick={() => { this.updateStudentAdjustmentStatus(adjustment, 'ACTIVE') }}>
															{this.props.translate('Activate')}
														</TooltipMenu.Item>
														: null}

													{adjustment.status == 'COMPLETED' ?
														<TooltipMenu.Item onClick={() => { this.updateStudentAdjustmentStatus(adjustment, 'DELETED') }}>
															{this.props.translate('Delete')}
														</TooltipMenu.Item>
														: null}

													{adjustment.status == 'DELETED' ?
														<TooltipMenu.Item onClick={() => { this.updateStudentAdjustmentStatus(adjustment, 'COMPLETED') }}>
															{this.props.translate('Reset')}
														</TooltipMenu.Item>
														: null}

													{adjustment.status == 'DELETED' ?
														<TooltipMenu.Item onClick={() => { this.deleteStudentAdjustment(adjustment) }}>
															{this.props.translate('Remove permanently')}
														</TooltipMenu.Item>
														: null}
												</TooltipMenu>
											</div>

											<div className="clearfix" />
										</div>
									})}
								</div>
							</Collapsible>
						</div>
					})}
			</Block>
		)
	}

	render() {
		if (!this.props.services.additionalAdjustments) {
			return <Redirect to="/" />
		}

		let adjustments = this.props.schoolAdjustments;
		let statusFilter = null;

		// Apply filters
		statusFilter = this.state.selectedStatus;

		if (this.state.selectedGroup != '0') {
			adjustments = this.props.groupAdjustments;
		}

		const { translate } = this.props;
		const user = new User(this.props.user);

		let result = GetGroupedByStudent(adjustments, statusFilter);
		result = AddUnusedAdditionalAdjustments(result, this.props.adjustments);

		return (
			<div className="adjustments spec-pedagog">
				{this.renderModals()}

				<Tabs>
					<Tab title={translate('students')} route="students">
						{this.renderFilters()}

						{this.state.selectedStatus == null ?
							<div>
								<Collapsible open trigger={this.props.translate('active')}>
									<div className="group-adjustment">
										{this.renderStudents('ACTIVE')}
									</div>
								</Collapsible>

								<div style={{ marginTop: '1.55rem' }} />

								<Collapsible trigger={this.props.translate('completed')}>
									<div className="group-adjustment">
										{this.renderStudents('COMPLETED')}
									</div>
								</Collapsible>

								<div style={{ marginTop: '1.55rem' }} />

								{user.isPedagogue() ?
									<Collapsible trigger={this.props.translate('Deleted')}>
										<div className="group-adjustment">
											{this.renderStudents('DELETED')}
										</div>
									</Collapsible>
									: null}
							</div>
							:
							<div>
								<Collapsible open trigger={this.props.translate(this.state.selectedStatus.toLowerCase())}>
									<div className="group-adjustment">
										{this.renderStudents(this.state.selectedStatus)}
									</div>
								</Collapsible>
							</div>
						}
					</Tab>

					<Tab title={translate('Templates')} route="templates">
						{this.renderFilters()}

						<Collapsible open trigger={translate('Active')}>
							<Block>
								<div className="group-adjustment">
									{this.state.loading ?
										<Spinner center /> :
										result.length > 0 ? result.map(adjustment => {
											if (adjustment.status != 'ACTIVE') {
												return null;
											}

											const trigger = this.renderAdjustmentTrigger(adjustment);

											return <div className="adjustment" key={adjustment.id}>
												<Collapsible trigger={trigger}>
													{adjustment.items.map(student => {
														let hasActive = student.items.find(adj => adj.status == 'ACTIVE');
														if (hasActive == null) return null;

														return <div className="student" key={student.userId}>
															<div onClick={() => this.onStudentClick(student)} className="name">
																<Person person={student} />
															</div>
														</div>
													})}
												</Collapsible>
											</div>
										}) : <div>{this.props.translate('no-adjustments-found')}</div>
									}
								</div>
							</Block>
						</Collapsible>

						<div style={{ marginTop: '1.55rem' }} />

						<Collapsible trigger={this.props.translate('archived')}>
							<Block>
								<div className="group-adjustment">
									{this.state.loading ?
										<Spinner center /> :
										result.length > 0 ? result.map(adjustment => {
											if (adjustment.status != 'ARCHIVED') {
												return null;
											}

											const trigger = this.renderAdjustmentTrigger(adjustment);

											return <div className="adjustment" key={adjustment.id}>
												<Collapsible trigger={trigger}>
													{adjustment.items.map(student => {
														let hasActive = student.items.find(adj => adj.status == 'ACTIVE');
														if (hasActive == null) return null;

														return <div className="student" key={student.userId}>
															<div onClick={() => this.onStudentClick(student)} className="name">
																<Person person={student} />
															</div>
														</div>
													})}
												</Collapsible>
											</div>
										})
											:
											<div>{this.props.translate('no-adjustments-archived')}</div>
									}
								</div>
							</Block>
						</Collapsible>

						<div style={{ marginTop: '1.55rem' }} />

						{user.isPedagogue() ?
							<Collapsible trigger={this.props.translate('Deleted')}>
								<Block>
									<div className="group-adjustment">
										{this.state.loading ?
											<Spinner center /> :
											result.length > 0 ? result.map(adjustment => {
												if (adjustment.status != 'DELETED') {
													return null;
												}

												const trigger = this.renderAdjustmentTrigger(adjustment);

												return <div className="adjustment" key={adjustment.id}>
													<Collapsible trigger={trigger}>
														{adjustment.items.map(student => {
															let hasActive = student.items.find(adj => adj.status == 'ACTIVE');
															if (hasActive == null) return null;

															return <div className="student" key={student.userId}>
																<div onClick={() => this.onStudentClick(student)} className="name">
																	<Person person={student} />
																</div>
															</div>
														})}
													</Collapsible>
												</div>
											})
												:
												<div>{this.props.translate('no-adjustments-archived')}</div>
										}
									</div>
								</Block>
							</Collapsible>
							: null}
					</Tab>
				</Tabs>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		schoolAdjustments: state.AdditionalAdjustments.schoolAdjustments,
		adjustments: state.AdditionalAdjustments.adjustments,
		groupAdjustments: state.AdditionalAdjustments.groupAdjustments,
		studentAdjustments: state.AdditionalAdjustments.studentAdjustments,
		groups: state.sections.educationGroups,
		services: state.Services.availableServices,
	}
}

export default connect(mapStateToProps, {
	setPageTitle,
	getSchoolAdjustments,
	setPageActions,
	getAdditionalAdjustments,
	updateAdditionalAdjustment,
	getStudentAdditionalAdjustmentsForGroup,
	clearSearchResults,
	getStudentAdjustments,
	updateStudentAdjustment,
	deleteStudentAdjustment,
	deleteAdditionalAdjustment,
})(AdministrateAdjustments);