import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

import { getFormInstance } from 'actions/templates';
import { FIELD_DESCRIPTION, FIELD_TITLE } from 'constants/fields';

import './Styles.scss';

class DisplayFormResults extends Component {

	/* Render methods */
	renderTitle = (field) => {
		return <div className="input-label">
			{field.value}
		</div>
	}

	renderDescription = (field) => {
		return <div>{field.value}</div>
	}

	renderInput = (field) => {
		const { activeInstance } = this.props;
		const type = field.type.toLowerCase();

		let value = null;
		if (activeInstance != null) {
			let findResult = activeInstance.fieldResults.find(result => {
				return result.fieldName == field.name;
			});

			if (findResult != null) {
				value = findResult.value;
			}
		}

		return <div className={"placeholder-input " + type}
			dangerouslySetInnerHTML={{
				__html: value
			}}
		/>
	}

	renderField = (field, index) => {
		if (field.type == FIELD_TITLE) {
			return <div key={index} className="form-row field">
				{this.renderTitle(field, index)}
			</div>
		}

		if (field.type == FIELD_DESCRIPTION) {
			return <div key={index} className="form-row field">
				{this.renderDescription(field, index)}
			</div>
		}

		return <div key={index} className="form-row">
			{this.renderInput(field, index)}
		</div>
	}

	render() {
		if (this.props.fields == null) {
			return null;
		}

		let fields = [...this.props.fields];
		fields.sort((a, b) => {
			return a.sortOrder > b.sortOrder ? 1 : -1;
		});

		return (
			<div className="dynamic-form">
				<div className="form form-component">
					{this.props.activeInstance?.assigneeViewedCount != null ?
						<div className="form-row field">
							{this.props.translate('opened')}: {this.props.activeInstance.assigneeViewedCount}
						</div>
						: null}

					{fields.map(this.renderField)}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		activeInstance: ownProps.instance,
	};
}

export default connect(mapStateToProps, {
	getFormInstance,
})(DisplayFormResults);
