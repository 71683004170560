import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUser } from 'lib/msal';
import { appInsights } from 'lib/appInsights';
import { Link } from 'react-router-dom';

import { getRootUrl } from 'helpers/url';

import { Button, Block, translate } from '@haldor/ui';

class RequiredDataError extends Component {

	componentDidMount = () => {
		const user = getUser();
		appInsights.trackTrace({ message: "EDU013 - API error" }, {
			error: this.props.error?.data,
		});
	}

	/*
	generateStringFromObject = (target, data) => {
		if (target == null) {
			target = [];
		}

		Object.keys(data).forEach(key => {
			if (typeof data[key] == 'object' && data[key] != null) {
				let arrayData = key + ': (object)\n';
				Object.keys(data[key]).forEach(objectKey => {
					arrayData += '    ' + objectKey + ': ' + data[key][objectKey] + '\n';
				});

				target.push(arrayData);
			} else {
				target.push(key + ': ' + data[key] + '\n');
			}
		});

		return target;
	}

	generateFile = () => {
		if (this.props.error == null) {
			return false;
		}

		const user = getUser();

		console.log('error from props', this.props.error, user)

		let data = [];

		// Add request data to file
		data.push('## Request\n\n');
		data.push('Status: ' + this.props.error.status + '\n');
		data.push('StatusText: ' + this.props.error.statusText + '\n');

		if (this.props.error.config != null) {
			data.push('BaseUrl: ' + this.props.error.config.baseURL + '\n');
			data.push('Url: ' + this.props.error.config.url + '\n');
		}

		// Add request headers
		data.push('\n\n## Request headers\n\n');
		if (this.props.error.config != null && this.props.error.config.headers != null) {
			data = this.generateStringFromObject(data, this.props.error.config.headers);
		}

		// Add error data to file
		data.push('\n\n## Response data\n\n');
		if (this.props.error.data != null) {
			data = this.generateStringFromObject(data, this.props.error.data);
		}

		data.push('\n\n## User data\n\n');
		if (user != null) {
			data = this.generateStringFromObject(data, user);
		} else {
			data.push('User is null!\n');
		}

		let blob = new Blob(data, {
			type: "text/plain;charset=utf-8",
		});

		let textFile = null;

		if (textFile != null) {
			window.URL.revokeObjectURL(textFile);
		}

		textFile = window.URL.createObjectURL(blob);
		this.link.href = textFile;
	}
	*/

	renderInformation = () => {
		return <div>
			<h3>{this.props.translate('something-went-wrong')}</h3>

			<p style={{ marginTop: '1.65rem' }}>
				{this.props.translate('It seems like something went wrong while we were trying to load data. Try reloading.') + ' '}
				{this.props.translate('If this does not work, contact your teacher, administrator or Haldor. Haldor can be reached via our Support.')}
			</p>

			<p style={{ marginTop: '1rem' }}>
				{this.props.translate('Please leave this session ID in the support ticket: ')} <strong>{localStorage.getItem('hsid')}</strong>
			</p>

			<a href="https://haldor.zendesk.com/hc/sv/requests/new" target="_blank" style={{ display: 'inline-block', marginTop: '1rem' }}>
				{this.props.translate('Submit support ticket')}
			</a>

			<div style={{ marginTop: '1.65rem' }}>
				<a href={getRootUrl()}>
					<Button>
						{this.props.translate('Reload page')}
					</Button>
				</a>

				<Link to="/logout" style={{ marginLeft: '.65rem' }}>
					{this.props.translate('log-out')}
				</Link>
			</div>
		</div>
	}

	render() {
		if (window.location.pathname == '/tab-configure' || window.location.pathname == '/course-plan-configure') {
			return this.renderInformation();
		}

		return <div>
			<div style={{ maxWidth: 650, margin: '0 auto', marginTop: '3rem' }}>
				<Block>
					{this.renderInformation()}
				</Block>
			</div>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(RequiredDataError);