import moment from 'moment';

export const clearLocalStorage = function () {
	// Get the name of the schools
	var schools = {};
	for (var i = 0; i < localStorage.length; i++) {
		if (localStorage.key(i).includes("hal.school")) {
			schools[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
		}
	}

	// Get the name of the roles
	var roles = {};
	for (var i = 0; i < localStorage.length; i++) {
		if (localStorage.key(i).includes("hal.role")) {
			roles[localStorage.key(i)] = localStorage.getItem(localStorage.key(i));
		}
	}

	// Get language and schedule type
	var language = null;
	var scheduleType = null;
	for (var i = 0; i < localStorage.length; i++) {
		if (localStorage.key(i) == 'language') {
			language = localStorage.getItem(localStorage.key(i));
		}

		if (localStorage.key(i) == 'haldor-schedule-type') {
			scheduleType = localStorage.getItem(localStorage.key(i));
		}
	}

	// clear the local storage
	localStorage.clear();

	// again add back the schools to local storage
	for (var key in schools) {
		localStorage.setItem(key, schools[key]);
	}

	// again add back the roles to local storage
	for (var key in roles) {
		localStorage.setItem(key, roles[key]);
	}

	// again add back the language to local storage
	if (language != null) {
		localStorage.setItem('language', language);
	}

	// again add back the schedule type to local storage
	if (scheduleType != null) {
		localStorage.setItem('haldor-schedule-type', scheduleType);
	}
}

export const activeDataAvailable = function (userId) {
	var school = localStorage.getItem("hal.school" + userId);

	return school !== null;
}

export const getActiveSchool = function (userId) {
	var school = localStorage.getItem("hal.school" + userId);
	return school;
}

export const setMenuItems = function (items) {
	if (items != null) {
		localStorage.setItem('menu_items', JSON.stringify(items));
	}
}

export const getMenuItems = function () {
	let items = localStorage.getItem('menu_items');

	if (items != null && items != '') {
		let decoded = JSON.parse(items);
		if (decoded.length > 0) {
			return decoded;
		}

		return null;
	}

	return null;
}

export const saveWithExpiration = function (key, value, expiration = null) {
	if (expiration == null) {
		expiration = moment().add(12, 'hours').format();
	} else {
		expiration = moment(expiration).format();
	}

	let item = {
		data: value,
		expiration: expiration,
	};

	localStorage.setItem(key, JSON.stringify(item));
}

export const saveWithExpirationAndLastModified = function (key, value, expiration = null) {
	if (expiration == null) {
		expiration = moment().add(12, 'hours').format();
	} else {
		expiration = moment(expiration).format();
	}

	let item = {
		data: value,
		expiration: expiration,
		lastModifiedUTC: moment().utc()
	};

	localStorage.setItem(key, JSON.stringify(item));
}

export const getWithExpiration = function (key) {
	let item = localStorage.getItem(key);

	if (item == null) {
		return null;
	}

	let obj = null;

	try {
		obj = JSON.parse(item);
	} catch (e) {
		obj = item;
	}

	if (typeof obj == 'string') {
		return obj;
	}

	if (typeof obj == 'object') {
		if (moment().isSameOrAfter(moment(obj.expiration))) {
			// Item has expired
			localStorage.removeItem(key);
			return null;
		}

		return obj.data;
	}

	return null;
}

export const getObjectExpiration = function (key) {
	let item = localStorage.getItem(key);

	if (item == null) {
		return null;
	}

	let obj = null;

	try {
		obj = JSON.parse(item);
	} catch (e) {
		obj = item;
	}

	if (typeof obj == 'string') {
		return obj;
	}

	if (typeof obj == 'object') {
		return obj.expiration;
	}

	return null;
}

export const getBasedOnLastModified = function (key, lastModifiedUTC) {
	let item = localStorage.getItem(key);

	if (item == null) {
		return null;
	}

	let obj = null;

	try {
		obj = JSON.parse(item);
	} catch (e) {
		obj = item;
	}

	if (typeof obj == 'string') {
		return obj;
	}

	if (typeof obj == 'object') {
		if (moment.utc(lastModifiedUTC).isSameOrAfter(moment.utc(obj.lastModifiedUTC)) || moment().utc().isSameOrAfter(moment(obj.expiration))) {
			// object contains older data than on server
			localStorage.removeItem(key);
			return null;
		}

		return obj.data;
	}

	return null;
}

export const isSupported = () => {
	try {
		const key = "__some_random_key_you_are_not_going_to_use__";
		localStorage.setItem(key, key);
		localStorage.removeItem(key);
		return true;
	} catch (e) {
		return false;
	}
}