import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as microsoftTeams from '@microsoft/teams-js';
import msal, { loginRequest } from 'lib/msal';
import sniffer from 'sniffer';

import Modal from 'containers/Modals/Modal';
import { Button, translate } from '@haldor/ui';

class AuthenticationPopup extends Component {

	constructor(props) {
		super(props);

		this.state = {
			error: null,
		}
	};

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	runLogin = () => {
		if (microsoftTeams.app.isInitialized()) {
			localStorage.setItem('haldor-teams-auth-progress', 'login');
			microsoftTeams.authentication.authenticate({
				url: '/Auth',
			})
				.then((response) => {
					localStorage.removeItem('haldor-teams-auth-progress');
					if (msal.getAllAccounts().length > 0 && response != null) {
						this.props.onLogin(response);
					} else {
						this.setState({ error: this.props.translate('Something went wrong while signing you in') });
					}
				})
				.catch((error) => {
					localStorage.removeItem('haldor-teams-auth-progress');
					this.setState({ error });
				});
		} else {
			if (sniffer.infos.isDesktop) {
				msal.loginPopup(loginRequest).then((response) => {
					this.props.onLogin(response);
				}).catch((reason) => {
					this.setState({ error: reason.errorMessage });
				});
			} else {
				msal.loginRedirect(loginRequest);
			}
		}
	}

	render() {
		const { translate } = this.props;

		return (
			<Modal
				isOpen={true}
				type="small news"
				overridePrompt
			>
				<div className="form" style={{ marginBottom: '2.5rem' }}>
					<div className="form-row">
						<div className="title">
							{this.state.error != null ?
								translate('Ops, something went wrong!')
								: translate('Still there?')}
						</div>

						<div style={{ margin: '1rem 0' }}>
							{this.state.error != null ?
								translate('Signing in failed. Please try again.')
								: translate('You have been signed out for security reasons due to inactivity.')}
						</div>

						{this.state.error != null ?
							<div style={{ border: '1px solid var(--color--blue)', padding: '1rem', margin: '1rem 0' }}>
								{this.state.error}
							</div>
							: null}

						<div style={{ marginTop: '2rem' }}>
							<Button onClick={this.runLogin}>
								{translate('log-in')}
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(AuthenticationPopup);