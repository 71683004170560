import { appInsights } from 'lib/appInsights';

export const trackNoGroups = (userId) => {
	var localStorageSchool = localStorage.getItem("hal.school" + userId);
	let message = 'Groups is empty';

	let properties = {
		'user': userId,
		'currentSchool': localStorageSchool,
	};

	appInsights.trackTrace({ message: message, severityLevel: 2 }, properties);
}

export const trackImmersiveReaderError = (error, userId) => {
	var localStorageSchool = localStorage.getItem("hal.school" + userId);
	let message = 'ImmersiveReaderError';

	let properties = {
		'user': userId,
		'currentSchool': localStorageSchool,
		'error': error,
	}

	appInsights.trackTrace({ message: message, severityLevel: 2 }, properties);
}
