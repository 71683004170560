import React, { Component } from 'react';

class StandingBar extends Component {

	/* Class setup */
	static defaultProps = {
		heightUnit: 2,
		minimumHeight: 5,
		showAmount: true,
	};

	/* Render methods */
	renderData = () => {
		const { data } = this.props;
		let total = 0;
		if (data != null && data.length > 0) {
			data.forEach(entry => {
				total += entry.amount;
			});
		}

		return data.map((entry, index) => {
			let height = 0;
			if (entry.amount != null && entry.amount > 0) {
				let percentage = entry.amount / total * 100;
				height = Math.floor(percentage) * this.props.heightUnit;
			}

			if (height <= this.props.minimumHeight) {
				height = this.props.minimumHeight;
			}

			return <div
				key={'graph-standingbar-bar'+index}
				className="bar"
			>
				{this.props.showAmount ?
					<span className="amount">{entry.amount}</span>
				: null}

				<div className="color" style={{backgroundColor: entry.color, height}} />
			</div>
		});
	}

	render() {
		return(
			<div className="graph standing-bar">
				<div className="bar-container">
					{this.renderData()}
				</div>
			</div>
		);
	}

}

export default StandingBar;
