import { getCurrentMeetings, getPastMeetings, createMeeting } from 'actions/meetings';
import CreateMeeting from 'containers/Meeting/Form/CreateMeeting';
import Modal from 'containers/Modals/Modal';
import React, { Component } from 'react';
import moment from 'moment';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { setPageTitle, setPageActions } from 'actions/header';
import { Block, Flex, Tabs, Tab } from '@haldor/ui';
import { Spinner } from 'UI';
import User from '_class/User';
import { withRouter, Link } from 'react-router-dom';

class MyMeetings extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			loadingCurrent: true,
			loadingPast: false,
			currentActiveTab: 'current',
			modal: false,
			user: new User(this.props.user)
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		const user = new User(this.props.user);
		let actions = [];

		if (user.isMentor()) {
			actions.push({
				type: 'button',
				value: this.props.translate('new-meeting'),
				buttonType: 'primary',
				onClick: this.toggleModal,
				icon: 'plus',
			});
		}

		this.setState({ loadingCurrent: true });
		this.props.getCurrentMeetings()
			.then(() => {
				this.setState({ loadingCurrent: false });
			});

		this.props.setPageTitle(this.props.translate('meetings'));
		this.props.setPageActions(actions);
	}

	onTabsChange = () => {
		let reloadData = false;

		if (window.location.hash === "#past" && this.state.currentActiveTab == "current") {
			reloadData = true;
			this.setState({ currentActiveTab: 'past' });
		}

		if (window.location.hash === "#current" && this.state.currentActiveTab == "past") {
			reloadData = true;
			this.setState({ currentActiveTab: 'current' });
		}

		if (reloadData) {
			switch (this.state.currentActiveTab) {
				case 'past':
					this.setState({ loadingCurrent: true });
					this.props.getCurrentMeetings()
						.then(() => {
							this.setState({ loadingCurrent: false });
						});
					break;
				case 'current':
					this.setState({ loadingPast: true });
					this.props.getPastMeetings()
						.then(() => {
							this.setState({ loadingPast: false });
						});
					break;
			}
		}
	}

	/* Events */
	toggleModal = () => {
		this.props.history.push('/meetings/wizard');
	}

	onMeetingSubmit = (meeting) => {
		return new Promise(async resolve => {
			await this.props.createMeeting(meeting);
			await this.props.getCurrentMeetings();

			this.toggleModal();
			resolve(1);
		});
	}

	renderMeetingProgress = (meeting) => {
		var width = 0;
		if (meeting.totalMeetings != 0 && meeting.meetingsCompleted != 0) {
			width = (meeting.meetingsCompleted / meeting.totalMeetings) * 100;
		}

		return (
			meeting.totalMeetings != null && meeting.meetingsCompleted != null ?
				<div className="size-14 color--meta">
					<Flex>
						<div style={{ flex: 1 }}>{this.props.translate('completed-meeting')}</div>
						<div style={{ flex: 1, textAlign: 'right' }}>
							{meeting.meetingsCompleted} / {meeting.totalMeetings}
						</div>
					</Flex>

					<div className="meeting-progress-bar">
						<div className="indicator" style={{ width: `${width}%` }} />
					</div>
				</div>
				: null
		)
	}

	renderMeetingCard = (meeting) => {
		const { translate } = this.props;
		const user = new User(this.props.user);

		return (
			<Link to={`/meeting/${meeting.id}`} key={meeting.id}>
				<Block>
					<span className="title">
						{meeting.title}

						{moment().diff(moment(meeting.created), 'days') <= 2 ?
							<span className="new-badge" style={{ marginLeft: '.5rem' }}>
								{translate('new')}
							</span>
							: null}

						{meeting.status === 'DRAFT' ?
							<span style={{ marginLeft: '.5rem' }} className="new-badge">
								{translate('Draft')}
							</span>
							: null}

						<span style={{ marginLeft: '.5rem' }} className="new-badge">
							{meeting.creator == this.props.user.id ?
								this.props.translate('organizer')
								: this.props.translate('participant')}
						</span>
					</span>

					<div className="clearfix" />

					{meeting.creator == this.props.user.id || user.isMentor() ?
						<div>
							{this.renderMeetingProgress(meeting)}
						</div>
						: null}
				</Block>
			</Link>
		);
	}

	renderStudentMeetingCard = (meeting) => {
		if (meeting == null || meeting.instances == null || meeting.instances.length == 0) {
			return null;
		}

		var instance = meeting.instances[0];

		return (
			<Link key={meeting.id} to={`/meeting/${meeting.id}/instance/${instance.id}`}>
				<Block>
					<span className="title">{meeting.title}</span>
				</Block>
			</Link>
		)
	}

	renderCurrentMeetings = () => {
		const { current } = this.props;
		const { user } = this.state;

		return (
			current.length > 0 ? current.map((meeting) => {
				if (user.isStudent()) {
					return this.renderStudentMeetingCard(meeting);
				}

				return this.renderMeetingCard(meeting);
			}) : <div className="color--meta text--center weight--bold" style={{ textAlign: 'center' }}>
				{this.props.translate('no-meetings')}
			</div>
		);
	}

	renderPastMeetings = () => {
		const { past } = this.props;
		const { user } = this.state;

		return (
			past.length > 0 ? past.map((meeting) => {
				if (user.isStudent()) {
					return this.renderStudentMeetingCard(meeting);
				}

				return this.renderMeetingCard(meeting);
			}) : <div className="color--meta text--center weight--bold" style={{ textAlign: 'center' }}>
				{this.props.translate('no-meetings')}
			</div>
		);
	}

	/* Render methods */
	render() {
		return (
			<div className="meeting">
				<Modal isOpen={this.state.modal} onClose={this.toggleModal} title={this.props.translate('new-meeting')}>
					<CreateMeeting onSubmit={this.onMeetingSubmit} onAbort={this.toggleModal} />
				</Modal>

				<Tabs onChange={this.onTabsChange}>
					<Tab title={this.props.translate('current-meetings')} route="current">
						{this.state.loadingCurrent ?
							<Spinner center />
							: this.renderCurrentMeetings()}

					</Tab>

					<Tab title={this.props.translate('past-meetings')} route="past">
						{this.state.loadingPast ?
							<Spinner center />
							: this.renderPastMeetings()}
					</Tab>
				</Tabs>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		created: state.Meetings.meetingsByCreator,
		current: state.Meetings.current,
		past: state.Meetings.past,
	}
}

export default withRouter(connect(mapStateToProps, {
	setPageTitle,
	setPageActions,
	createMeeting,
	getCurrentMeetings,
	getPastMeetings,
})(MyMeetings));