import {
	FETCH_PRESCHOOL_GOALS,
	SUBMIT_PRESCHOOL_GOAL,
	DELETE_PRESCHOOL_GOAL,
	SEARCH_PRESCHOOL_GOALS,
	GET_PRESCHOOL_GOALS_STATISTICS,
	GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
	RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
	GET_PRESCHOOL_GOALS_USER_STATISTICS,
} from 'actions/preschoolform';

const initialState = {
	preschoolGoals: [],
	searchResults: [],
	statistics: null,
	userStatistics: null,
	linkedData: [],
}

export default function Preschoolgoal(state, action) {
	const { type, value } = action;

	if (typeof (state) === 'undefined') {
		return initialState;
	}

	switch (type) {
		case SUBMIT_PRESCHOOL_GOAL:
			// Cast the values of this goal to strings
			value.title += '';
			value.description += '';

			if (typeof (value.id) !== 'undefined' && value.id !== '') {
				let goal = null;
				const index = state.preschoolGoals.findIndex(pG => value.id === pG.id);

				if (index > -1) {
					goal = value;
					state.preschoolGoals.splice(index, 1);
					state.preschoolGoals.splice(index, 0, goal);
				}

				return { ...state, preschoolGoals: state.preschoolGoals };
			} else {
				// Remove this when we have actual data from the API
				value.id = '1234-test-id-' + (state.preschoolGoals !== null ? state.preschoolGoals.length + 1 : 1);
			}

			state.preschoolGoals.push(value);

			return { ...state, preschoolGoals: state.preschoolGoals };
			break;

		case DELETE_PRESCHOOL_GOAL:
			if (typeof (value.id) !== 'undefined' && value.id !== '') {
				const index = state.preschoolGoals.findIndex(pG => value.id === pG.id);

				if (index > -1) {
					state.splice(index, 1);
				}

				return { ...state, preschoolGoals: state };
			}

			break;

		case SEARCH_PRESCHOOL_GOALS:
			var results = [];

			if (value.length < 3) {
				return { ...state, searchResults: results };
			}

			results = state.preschoolGoals.filter(t => t.title.toLowerCase() === value.search.toLowerCase());

			return { ...state, searchResults: results };
			break;

		case FETCH_PRESCHOOL_GOALS:
			return { ...state, preschoolGoals: action.payload };
			break;

		case GET_PRESCHOOL_GOALS_STATISTICS:
			return { ...state, statistics: action.payload };

		case GET_PRESCHOOL_GOALS_USER_STATISTICS:
			return { ...state, userStatistics: action.payload };

		case GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
			if (action.payload != null) {
				return { ...state, linkedData: [...state.linkedData, action.payload] }
			}

			return state;

		case RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS:
			return { ...state, linkedData: [] };

		default:
			return state;
			break;
	}
}
