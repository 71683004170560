import {
	addCourse,
	getCourse,
	setActiveCourse,
	updateCourse
} from "actions/courses";
import { setPageActions, setPageTitle } from "actions/header";
import { addError } from "actions/index";
import { getSection, getSectionByGraphId } from "actions/sections";
import { ASSESSMENT_MATRIX } from "constants/matrixTypes";
import CustomCoreContent from "containers/CoreContent/Custom";
/*import MatrixForm from 'containers/Matrix/MatrixForm/MatrixForm';**/
import MatrixForm from "containers/Matrix/Form/MatrixForm";
import CourseForm from 'containers/Forms/CourseForm';
import api from "lib/api";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Expandable } from "UI";
import { Editor } from "UI/Inputs";
import * as microsoftTeams from "@microsoft/teams-js";

class CreateCourses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			matrix: null,
			coreContent: null,
			groupId: null,
			loading: false
		};

		this.onMatrixFormUpdate.bind(this);
	}

	quillValidation = value => {
		if (typeof value !== "undefined" && value !== "") {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate("field-needs-input");
	};

	required = value => {
		if (typeof value !== "undefined" && value !== "") {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate("field-max-200-chars");
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate("field-needs-input");
	};

	submit = values => {
		return new Promise(resolve => {
			const { coreContent } = this.state;
			const { currentUser, initialValues } = this.props;

			values.typeOfSchooling = currentUser.schools.getActiveSchool(
				currentUser.userId
			).type;
			values.subjectCode = values.courseCode;

			if (this.state.groupId != null) {
				values.accessibleBy = [
					{
						ReferenceType: "SECTION",
						ReferenceId: this.state.groupId
					}
				];
			}

			var detail = {};

			detail.id = values.details[0].id;
			detail.purpose = values.details[0].purpose;
			detail.matrix = this.state.matrix;
			detail.coreContent = coreContent;

			if (detail.matrix != null && detail.matrix._ParagraphSet != null) {
				detail.matrix._ParagraphSet.forEach(paragraph => {
					paragraph.rows.forEach(row => {
						if (this.props.editView == false) {
							row.id = 0;
						}

						row.cells = row.cells.filter(step => {
							return step.gradeF == null;
						});

						row.cells.forEach(cell => {
							if (this.props.editView == false) {
								cell.id = null;
							}
						});
					});
				});

				var matrix = {
					Title: detail.matrix.Title,
					DisableNotAchieved: detail.matrix.disableNotAchieved,
					Type: detail.matrix.Type,
					ParagraphSet: JSON.stringify(detail.matrix._ParagraphSet)
				};

				detail.matrixId = values.details[0].matrixID;
				if (values.details[0] != null && values.details[0].matrixID != 0 && values.details[0].matrixID != null) {
					matrix.id = values.details[0].matrixID;
				} else {
					matrix.id = 0;
				}

				detail.matrix = matrix;
			}

			values.details = [detail];

			const that = this;
			if (this.props.editView == false || initialValues == null) {
				this.props.addCourse(values, data => {
					const section = this.props.group;
					section.courses.push({
						id: data
					});

					api.put("sections", section).then(() => {
						microsoftTeams.app.getContext().then((context) => {
							this.props.getSectionByGraphId(context.team.groupId);
						});

						if (values.status == "COURSE_PUBLISHED") {
							window.history.go(-1);
						} else {
							this.props.addError(
								this.props.translate("changes-saved"),
								"info"
							);
							this.setState({ loading: true });
							this.props.getCourse(data).then(() => {
								this.props.setPageTitle(
									this.props.translate("update-course")
								);
								this.setState({ loading: false });
							});
						}

						resolve(data);
					});
				});
			} else {
				values.id = initialValues.id;
				this.props.updateCourse(values, data => {
					resolve(data);
					this.props.addError(
						this.props.translate("changes-saved"),
						"info"
					);
				});
			}
		});
	};

	onMatrixFormUpdate(form) {
		let matrix = Object.assign({}, form);
		this.setState({ matrix });
	}

	onCoreContentChange = content => {
		this.setState({ coreContent: content });
	};

	componentDidMount = () => {
		microsoftTeams.app.getContext().then((context) => {
			let foundGroup = this.props.groups.find(group => {
				return group.graphId == context.team.groupId;
			});

			if (foundGroup != null) {
				this.setState({ groupId: foundGroup.id });

				if (this.props.group == null) {
					this.props.getSection(foundGroup.id);
				}
			}
		});

		if (this.props.match.params != null && this.props.match.params.courseId != null) {
			this.props.setPageTitle(this.props.translate("update-course"));
			this.setState({ loading: true });
			this.props.getCourse(this.props.match.params.courseId).then(() => {
				if (this.props.initialValues != null) {
					if (this.props.initialValues.details[0].matrix != null) {
						var matrix = JSON.parse(JSON.stringify(this.props.initialValues.details[0].matrix));
						this.setState({ matrix });
					}

					let coreContent = null;
					if (this.props.initialValues.details != null) {
						if (this.props.initialValues.details.length > 0) {
							coreContent = this.props.initialValues.details[0].coreContent;
							this.setState({ coreContent });
						}
					}
				}

				this.setState({ loading: false });
			});
		} else {
			this.props.setPageTitle(this.props.translate("create-course"));
			this.setState({ loading: false });
		}
	};

	componentWillUnmount = () => {
		this.props.setActiveCourse();
	};

	onCourseCreateFormClose = (reload, skipPrompt) => {
		// Reload tab
		this.props.history.push('/haldor-tab');
	}

	render() {
		const {
			initialValues
		} = this.props;


		if (this.state.loading) {
			return <div></div>
		}

		return (
			<CourseForm
				onClose={this.onCourseCreateFormClose}
				editView={initialValues != null}
				group={this.props.group}
			/>

		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		initialValues: state.Courses.course,
		currentUser: state.user.currentUser,
		groups: state.sections.educationGroups,
		group: state.sections.activeSection
	};
}

export default connect(mapStateToProps, {
	addCourse,
	updateCourse,
	setPageTitle,
	setPageActions,
	setActiveCourse,
	getCourse,
	addError,
	getSectionByGraphId,
	getSection
})(reduxForm({
	form: "CreateCoursesForm",
	destroyOnUnmount: true
})(CreateCourses));