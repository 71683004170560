import React, { Component } from 'react';
import { Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import { getUserGuardian } from 'actions/conversation';
import { clearSearchResults, searchSchoolUsers } from 'actions/search';
import { getSection, getEducationGroups, getMentorGroups } from 'actions/sections';

import User from '_class/User';

import { DataList, Spinner, Expandable } from 'UI';
import { Search } from 'UI/Inputs';
import { Checkbox, Button, Radio } from '@haldor/ui';
import Guardian from 'containers/Guardian/Guardian';

import "./SelectRecipient.scss";
import { getActiveSchool } from 'helpers/localstorage';
import DisplayName from 'components/Presentation/DisplayName';

class SelectRecipientForm extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			group: null,
			searching: false,
			hasMadeQuery: false,
			loadingGuardians: false,
			selectedContact: null,
			selectedGuardians: [],
			selectedPeople: [],
			sections: [],
		};
	}

	/* Lifecycle methods */
	componentWillUnmount = () => {
		this.props.clearSearchResults();
	}

	componentDidMount = () => {
		const user = new User(this.props.currentUser);

		if (user.isMentor() || user.isTeacher()) {
			this.props.getEducationGroups();
			this.props.getMentorGroups();
		}
	}

	/* Events */
	submit = (values) => {
		return new Promise((resolve) => {
			values.memberships = this.getConversationMembers(values);
			values.relationships = this.getConversationRelationships(values);

			if (this.props.onSubmit) {
				this.props.onSubmit(values).then(() => {
					resolve(1);
				})
			} else {
				resolve(1);
			}
		})
	}

	onGroupExpand = (group, toggle) => {
		let foundInState = this.state.sections.findIndex(section => section.id == group.id);

		if (foundInState < 0) {
			this.props.getSection(group.id).then(() => {
				this.setState({
					sections: [...this.state.sections, this.props.group]
				});
			});
		}

		toggle();
	}

	getConversationRelationships = (values) => {
		let relationships = [];

		if (this.state.selectedGuardians.length == 0) {
			return relationships;
		}

		this.state.selectedGuardians.forEach(guardian => {
			this.props.guardians.forEach(studentGuardian => {
				if (studentGuardian.guardians == null) {
					return;
				}

				let foundInGuardians = studentGuardian.guardians.find(gar => {
					return gar.id == guardian.id && studentGuardian.userId == guardian.studentId;
				});

				if (foundInGuardians != null) {
					const foundInRelationships = relationships.find(relationship => {
						return relationship.referenceType == 'STUDENT' && relationship.referenceId == studentGuardian.userId;
					});

					if (foundInRelationships == null) {
						relationships.push({
							referenceId: studentGuardian.userId,
							referenceType: 'STUDENT'
						});
					}
				}
			});
		});

		return relationships;
	}

	getConversationMembers = (values) => {
		let members = [];

		if (this.state.selectedGuardians.length > 0) {
			this.state.selectedGuardians.forEach(guardian => {
				members.push({
					membershipID: guardian.id,
					membershipType: 'GUARDIAN',
					permission: 'W',
					user: guardian,
				});
			});
		}

		if (this.state.selectedPeople.length > 0) {
			this.state.selectedPeople.forEach(student => {
				members.push({
					membershipID: student.userId,
					membershipType: 'USER',
					permission: 'W',
					user: student,
				});
			});
		}

		if (values.senderId != null) {
			if (values.senderId != this.props.currentUser.id) {
				let mentorGroups = this.props.mentorGroups?.filter(ss => { return !ss.archived && ss.userRole == "OWNER" }) ?? [];
				let educationGroups = this.props.educationGroups?.filter(ss => { return !ss.archived && ss.userRole == "OWNER" }) ?? [];

				let section = mentorGroups.find(section => section.id == values.senderId) ?? educationGroups.find(section => section.id == values.senderId);

				if (section != null) {
					members.push({
						membershipID: values.senderId,
						membershipType: 'SECTION_OWNERS',
						permission: 'O',
						section: section,
					});
				}
			} else {
				members.push({
					membershipID: values.senderId,
					membershipType: 'USER',
					permission: 'O',
					user: this.props.currentUser,
				});
			}
		}

		return members;
	}

	onContactSelect = (contact) => {
		const selected = this.state.selectedPeople.find(person => person.userId == contact.userId);

		if (selected == null) {
			let selectedPeople = this.state.selectedPeople;
			selectedPeople.push(contact);

			this.setState({ selectedPeople });
		} else {
			this.setState({
				selectedPeople: [...this.state.selectedPeople].filter(person =>
					person.userId != contact.userId
				)
			});
		}
	}

	onContactToggle = (contact, open) => {
		const user = new User(this.props.currentUser);

		if (open) {
			if ((user.isMentor() || user.isTeacher()) && contact.userType.toLowerCase() == 'student') {
				contact.loadingGuardians = true;
				this.setState({ selectedContact: contact.userId, selectedGuardians: [], selectedPeople: [] });

				this.props.getUserGuardian(contact.userId).then(() => {
					contact.loadingGuardians = false;
					this.setState({});
				});
			} else {
				this.setState({ selectedContact: contact.userId, selectedGuardians: [], selectedPeople: [] });
			}
		}
	}

	onSearch = (query) => {
		this.setState({ searching: true, selectedGuardians: [], selectedPeople: [], selected: null, });

		this.props.searchSchoolUsers(query.query, 'student,teacher,mentor', '').then(() => {
			this.setState({ searching: false, hasMadeQuery: true });
		});
	}

	onGuardianChange = (checked, guardian, studentId) => {

		let { selectedGuardians } = this.state;

		let found = selectedGuardians.findIndex(selected => {
			return selected.id == guardian.id && selected.studentId == studentId;
		});

		if (found == -1) {
			selectedGuardians.push(guardian);
		} else {
			selectedGuardians.splice(found, 1);
		}

		this.setState({ selectedGuardians });
	}

	/* Render methods */
	renderStudentGuardian = (guardian, studentId) => {
		guardian.studentId = studentId;
		let selected = this.state.selectedGuardians.find(selectedGuardian => selectedGuardian.id == guardian.id && selectedGuardian.studentId == studentId);
		selected = selected != null;

		return <div key={guardian.id + '-' + studentId} style={{ marginBottom: '0.35rem', marginLeft: '30px' }}>
			<Checkbox
				label={guardian.firstName + ' ' + (guardian.lastName != null ? guardian.lastName : '')}
				value={selected}
				onChange={c => this.onGuardianChange(c, guardian, studentId)}
			/>
		</div>
	}

	onContactSelected = (value) => {
		this.setState({ selectedContact: value, selectedGuardians: [], selectedPeople: [] });
		return;
	}

	selectAllUserGuardians = (isActive, guardians, studentId) => {
		if (this.state.selectedGuardians.length < guardians.length)
			this.setState({ selectedGuardians: [] })

		guardians.forEach(element => {
			this.onGuardianChange(isActive, element, studentId);
		});
	}

	renderContact = (contact) => {
		let selected = this.state.selectedPeople.find(student => student.userId == contact.userId);
		let userGuardians = null;
		let allSelected = true;
		selected = selected != null;

		if (this.props.guardians != null) {
			userGuardians = this.props.guardians.find(userGuardian => {
				return userGuardian.userId == contact.userId;
			});

			if (userGuardians != null)
				allSelected = this.state.selectedGuardians.length == userGuardians.guardians.length;
		}
		let result = this.props.teachers.concat(this.props.students);
		if (result != null && result.length > 0) {
			const allowedUserTypes = new Set(['teacher', 'student', 'staff', 'mentor']);

			result = Object.values(result.reduce((acc, item) => {
				if (allowedUserTypes.has(item.userType.toLowerCase())) {
					acc[item.userId] = item;
				}
				return acc;
			}, {}));
		}
		let contactName = (contact.firstName || contact.lastName) ?
			<DisplayName
				firstName={contact.firstName}
				lastName={contact.lastName}
				email={contact.email}
				data={result}
			/>
			: contact.email;
		return (
			<Expandable
				key={contact.userId}
				title={(<div>
					<Radio
						input={{
							onChange: this.onContactSelected,
							value: this.state.selectedContact,
						}}
						optionValue={contact.userId}
						label={contactName}
					/>

					<span className="participant-type color--meta size-14" onClick={() => this.onContactSelect(contact)}>
						{contact?.userType.toLowerCase() == 'student' ? this.props.translate('student') : this.props.translate('staff')}
					</span>
				</div>)}
				onChange={(open) => this.onContactToggle(contact, open)}
				open={contact.userId == this.state.selectedContact}
			>
				<div className="participant">
					<div className="name">
						<Checkbox
							value={selected}
							onChange={(e) => this.onContactSelect(contact)}
							label={contactName}
						/>
					</div>

					{contact.userType.toLowerCase() == 'student' ?
						<div>
							{contact.loadingGuardians == true ?
								<Spinner small center />
								: userGuardians != null && userGuardians.guardians != null && userGuardians.guardians.length > 0 ?
									<div>
										<span className="subtitle">
											{this.props.translate('Guardian')}
										</span>

										<div style={{ marginBottom: '.35rem' }}>
											<Checkbox
												onChange={(c) => { this.selectAllUserGuardians(c, userGuardians.guardians, contact.userId); }}
												label={this.props.translate('select-all')}
												value={allSelected}
											/>
										</div>

										{userGuardians.guardians.map(guardian => this.renderStudentGuardian(guardian, contact.userId))}

										<div className="info-description" style={{ marginTop: '0.5rem' }}>
											<Icon name="Alert" bw />
											<div className='text'>
												{this.props.translate('If a guardian\'s name appears more than once, it\'s because the user has multiple accounts. Therefore, check for all duplicates when selecting recipients.')}
											</div>
										</div>
									</div>
									: null}
						</div>
						: null}
				</div>
			</Expandable>
		);
	}

	renderNoResults = () => {
		if (this.state.hasMadeQuery) {
			let noSearchHits = false;
			if (this.props.teachers.length == 0 && this.props.students.length == 0) {
				noSearchHits = true;
			}
			if (noSearchHits) {
				return (
					<div style={{ marginLeft: '2rem' }}>
						{this.props.translate('no-results')}
					</div>
				)
			}
		}
	}

	renderGroupTitle(section, type = 'mentor') {
		return (
			<div className="row">
				<div className="title">{section.title} </div>
				<div className="card-meta">
					{this.props.translate(section.type.toUpperCase())}
				</div>
			</div>
		);
	}

	renderGroup = (section) => {
		let groupData = null;
		if (this.state.sections.length > 0) {
			groupData = this.state.sections.find(s => s.id == section.id);
		}

		return <div key={section.id} className="radio-container">
			<Field
				component={Radio}
				name="senderId"
				optionValue={section.id}
				label={this.renderGroupTitle(section)}
			/>

			<Expandable
				title={null}
				onPress={(toggle) => false}
				onPlus={(toggle) => this.onGroupExpand(section, toggle)}
			>
				<strong>{this.props.translate('Owners')}</strong>

				{groupData != null ?
					<ul>
						{groupData.owners.map((owner) => {
							return <li key={owner.id}>
								{owner.firstName} {owner.lastName}
							</li>
						})}
					</ul>
					: <Spinner small center />}
			</Expandable>
		</div>
	}

	render() {
		const user = new User(this.props.currentUser);
		let disableSubmit = false;
		let senderId = this.props.currentUser.userId;

		let mentorGroups = this.props.mentorGroups?.filter(ss => { return !ss.archived && ss.userRole == "OWNER" }) ?? [];
		let educationGroups = this.props.educationGroups?.filter(ss => { return !ss.archived && ss.userRole == "OWNER" }) ?? [];

		if (this.state.selectedGuardians.length == 0 && this.state.selectedPeople.length == 0) {
			disableSubmit = true;
		}

		let result = this.props.teachers.concat(this.props.students);
		if (result != null && result.length > 0) {
			const allowedUserTypes = new Set(['teacher', 'student', 'staff', 'mentor']);

			result = Object.values(result.reduce((acc, item) => {
				if (allowedUserTypes.has(item.userType.toLowerCase())) {
					acc[item.userId] = item;
				}
				return acc;
			}, {}));
		}

		return (
			<div className="conversation participant-picker form-container">
				<div style={{ padding: '.95rem' }}>
					<Search
						loading={this.state.searching}
						onSubmit={this.onSearch}
						placeholder={this.props.translate('Search student or staff')}
						lengthLimit={3}
					/>
				</div>

				<Form
					onSubmit={this.submit}
					initialValues={{
						'senderId': senderId,
					}}
					render={({ handleSubmit }) => {
						return <form onSubmit={handleSubmit} className="form form-component">
							{result != null && result.length != 0 ?
								<div className="users-list">
									<DataList
										data={result}
										renderRow={this.renderContact}
									/>
								</div>
								: this.renderNoResults()}

							{(user.isMentor() || user.isTeacher()) && !disableSubmit ?
								<div className="form-row sender">
									<label style={{ marginTop: 0 }}>
										{this.props.translate('Sender')}
									</label>


									<div className="radio-container">
										<Field
											component={Radio}
											name="senderId"
											label={this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName}
											optionValue={this.props.currentUser.userId}
										/>
									</div>

									{mentorGroups != null ? mentorGroups.map((section) => {
										return this.renderGroup(section);
									}) : null}

									{educationGroups != null ? educationGroups.map((section) => {
										return this.renderGroup(section);
									}) : null}

									<div className="info-description" style={{ marginTop: '0.5rem' }}>
										<Icon name="Alert" bw />
										<div className='text'>
											{this.props.translate('If you select a group as the sender, all owners of the group will be able to read and write in the conversation.')}
										</div>
									</div>
								</div>
								: null}

							<div style={{ padding: '0 1rem', marginTop: '1.55rem' }}>
								<Button disabled={disableSubmit}>
									{this.props.translate('write-message')}
								</Button>
							</div>
						</form>
					}}
				/>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		contacts: state.Conversation.contacts,
		group: state.sections.activeSection,
		students: state.Search.students,
		teachers: state.Search.teachers,
		guardians: state.Conversation.guardians,
		educationGroups: state.sections.educationGroups,
		mentorGroups: state.sections.mentorGroups
	};
}

export default connect(mapStateToProps, {
	searchSchoolUsers,
	getUserGuardian,
	clearSearchResults,
	getEducationGroups,
	getMentorGroups,
	getSection,
})(SelectRecipientForm);
