import {
	GET_SECTIONS,
	GET_SECTION,
	GET_FILTERED_SECTION,
	GET_SECTION_DETAILS,
	GET_EDUCATION_GROUPS,
	GET_ARCHIVED_EDUCATION_GROUPS,
	GET_MENTOR_GROUPS,
	GET_ARCHIEVED_MENTOR_GROUPS,
	SET_ACTIVE_SECTION,
	CLEAR_SECTIONS_FROM_STORE,
	GET_SCHOOL_BY_GROUP_GRAPH_ID,
	GET_SECTION_BY_GRAPH_ID,
	GET_SECTION_ATTENDANCE_STATS,
	CLEAR_SECTION_ATTENDANCE_STATS,
	GET_GROUP_ABSENCE,
	GET_SECTION_GUARDIANS,
	GET_STUDENT_ATTENDANCE_STATS,
	CLEAR_STUDENT_ATTENDANCE_STATS,
	GET_SECTION_TEAMS_CHANNEL,
	GET_SECTION_ASESSMENT_STATISTICS,
	TOGGLE_SELECTED_SECTION,
	CLEAR_SELECTED_SECTIONS,
	GET_EDUCATION_GROUPS_LEGACY
} from 'actions/sections';

function sortMatrices(section, state) {
	if (section != null && section.courses != null) {
		if (section.courses.length > 0) {
			section.courses.forEach((course) => {
				if (course.details != null) {
					if (course.details.length > 0) {
						course.details.forEach((detail) => {
							if (detail.matrix != null) {
								if (detail.matrix._Rows != null) {
									var rows = detail.matrix._Rows.sort((a, b) => {
										return a.paragraph - b.paragraph || a.sentenceNr - b.sentenceNr;
									});
									detail.matrix._Rows = rows;
								}
							}
						})
					}
				}
			})
		}
	}
}

const INITIAL_STATE = {
	sections: null,
	mentorGroups: [],
	mentorArchievedGroups: [],
	educationGroups: [],
	archivedEducationGroups: [],
	legacyEducationGroups: [],
	activeSection: null,
	activeFilteredSection: null,
	graphGroupSchool: null,
	graphSection: null,
	sectionAttendance: null,
	guardians: [],
	studentAttendanceStats: [],
	teamChannels: [],
	assessmentStatistics: null,
	selected: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SCHOOL_BY_GROUP_GRAPH_ID:
			return { ...state, graphGroupSchool: action.payload };

		case GET_SECTIONS:
			return { ...state, sections: action.payload };

		case GET_SECTION_BY_GRAPH_ID:
			return { ...state, graphSection: action.payload };

		case GET_EDUCATION_GROUPS_LEGACY:
			let groups = action.payload;

			if (Array.isArray(groups) && groups.length > 0) {
				groups.forEach(group => { group.type = group.type + "_LEGACY" });
			}

			return { ...state, legacyEducationGroups: groups };

		case GET_EDUCATION_GROUPS:
			if (action.payload != null) {
				return { ...state, educationGroups: action.payload, loading: false };
			}

			return { ...state, educationGroups: action.payload };

		case GET_SECTION_TEAMS_CHANNEL:
			return { ...state, teamChannels: action.payload };

		case GET_ARCHIVED_EDUCATION_GROUPS:
			if (action.payload != null) {
				return { ...state, archivedEducationGroups: action.payload, loading: false };
			}

			return { ...state, archivedEducationGroups: action.payload };

		case GET_MENTOR_GROUPS:
			return { ...state, mentorGroups: action.payload };

		case GET_ARCHIEVED_MENTOR_GROUPS:
			return { ...state, mentorArchievedGroups: action.payload };

		case GET_SECTION:
			sortMatrices(action.payload, state);
			return { ...state, activeSection: action.payload };

		case GET_FILTERED_SECTION:
			sortMatrices(action.payload, state);
			return { ...state, activeFilteredSection: action.payload };

		case GET_SECTION_DETAILS:
			if (action.payload != null && state.educationGroups.length > 0) {
				const currentIndex = state.educationGroups.findIndex(se => {
					return se.id == action.payload.id;
				});

				if (currentIndex > -1) {
					let newSections = state.educationGroups;
					newSections.splice(currentIndex, 1, action.payload);

					return { ...state, educationGroups: newSections };
				}

				return state;
			}

			return state;

		case SET_ACTIVE_SECTION:
			return { ...state, activeSection: null };

		case CLEAR_SECTIONS_FROM_STORE:
			return {
				...state,
				educationGroups: [],
				sections: INITIAL_STATE.sections,
				mentorGroups: [],
				archivedEducationGroups: INITIAL_STATE.archivedEducationGroups,
			};

		case GET_SECTION_ATTENDANCE_STATS:
			return { ...state, sectionAttendance: action.payload };

		case CLEAR_SECTION_ATTENDANCE_STATS:
			return { ...state, sectionAttendance: INITIAL_STATE.sectionAttendance };

		case GET_GROUP_ABSENCE:
			if (action.payload != null) {
				let activeGroup = state.activeSection;
				let mentorGroups = state.mentorGroups;

				if (state.mentorGroups != null) {
					mentorGroups = mentorGroups.map(group => {
						if (group.id == action.payload.groupId) {
							group.absences = action.payload.value;
						}

						return group;
					});
				}

				if (state.activeSection != null) {
					if (state.activeSection.id == action.payload.groupId) {
						activeGroup.absences = action.payload.value;
					}
				}

				return { ...state, activeSection: activeGroup, mentorGroups: mentorGroups };
			}

			return state;

		case GET_SECTION_GUARDIANS:
			return { ...state, guardians: [...state.guardians.filter(t => t.id != action.payload.id), action.payload] };

		case GET_STUDENT_ATTENDANCE_STATS:
			return { ...state, studentAttendanceStats: [...state.studentAttendanceStats, action.payload] }

		case CLEAR_STUDENT_ATTENDANCE_STATS:
			return { ...state, studentAttendanceStats: INITIAL_STATE.studentAttendanceStats };

		case GET_SECTION_ASESSMENT_STATISTICS:
			return { ...state, assessmentStatistics: action.payload };

		case TOGGLE_SELECTED_SECTION:
			if (action.payload.section != null) {
				let selected = [...state.selected];
				const foundInSelected = selected.findIndex(group => group.id == action.payload.section.id);

				if (action.payload.groupAssignment) {
					selected = [action.payload.section];
				} else if (foundInSelected > -1) {
					selected.splice(foundInSelected, 1);
				} else {
					selected.push(action.payload.section);
				}

				return { ...state, selected };
			}

			return state;

		case CLEAR_SELECTED_SECTIONS:
			return { ...state, selected: INITIAL_STATE.selected };

		default:
			return state;
	}
}
