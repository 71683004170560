/**
 * @typedef {import('types').Bookmark} BookmarkModel
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import {
	getMyBookmarks,
	getBookmarkByReference,
	createBookmark,
	deleteBookmark,
} from 'actions/bookmarks';

import { Button, Icon } from '@haldor/ui';

import './_Bookmark.scss';

/**
 * @prop {number} referenceId
 * @prop {string} referenceType
 */
class Bookmark extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		}
	}

	componentDidMount = () => {
		if (this.props.referenceType == null || this.props.referenceId == null) {
			return false;
		}

		if (this.props.bookmarks == null || this.props.bookmarks.length == 0) {
			this.props.getMyBookmarks()
				.then(() => {
					this.setState({ loading: false });
				})
		} else {
			this.setState({ loading: false });
		}
	}

	onBookmarkClick = () => {
		const { bookmarks, referenceType, referenceId } = this.props;

		/**
		 * @type {BookmarkModel} bookmark
		 */
		const bookmark = bookmarks.find(bookmark =>
			bookmark.referenceType == referenceType && bookmark.referenceId == referenceId
		);

		if (bookmark != null) {
			// delete bookmark
			this.props.deleteBookmark(bookmark.id)
		} else {
			this.props.createBookmark([{
				referenceType: referenceType,
				referenceId: referenceId,
			}]);
		}
	}

	render() {
		if (this.props.referenceType == null || this.props.referenceId == null) {
			return null;
		}

		if (this.state.loading) {
			return null;
		}

		const { bookmarks, referenceId, referenceType } = this.props;

		/**
		 * @type {BookmarkModel} bookmark
		 */
		const bookmark = bookmarks.find(bookmark =>
			bookmark.referenceType == referenceType && bookmark.referenceId == referenceId
		);

		return (
			<div className="bookmarks bookmark--toggle" onClick={this.onBookmarkClick}>
				{bookmark == null ?
					<Icon name="Star" bw />
					:
					<Icon name="Star_Fill" />
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		bookmarks: state.Bookmarks.user,
	}
}

export default connect(mapStateToProps, {
	getMyBookmarks,
	getBookmarkByReference,
	createBookmark,
	deleteBookmark
})(Bookmark);