import React, { Component } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import msal, { loginRequest, getUser, setUser } from 'lib/msal';
import api from 'lib/api';
import localstoragefunc from 'helpers/localstorage';
import getEnvironment from 'lib/env';

import { Spinner } from 'UI';
import { isMicrosoftTeams } from 'helpers/teams';

export default class AuthenticationApi extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logged: false,
		};
	}

	componentDidMount = () => {
		if (window.location.hash.indexOf('access_token') > -1) {
			/** Sometime microsoft is not setting consent on api in time so therefore we waiting 3 seconds to avoid problem when consenting clients */
			setTimeout(function () {
				msal.handleRedirectPromise().then((response) => {
					window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + getEnvironment().clientId + "&response_type=token&scope=openid profile api://" + getEnvironment().apiClientId + "/user_impersonation&redirect_uri=" + window.location.origin + "/teams/he/authentication/client";
				})
					.catch((reason) => {
						microsoftTeams.authentication.notifyFailure(reason);
					});
			}, 2000);
		}

		if (window.location.search.indexOf('error') > -1) {
			if (!isMicrosoftTeams()) {
				window.opener.onPopupDataReceived("AUTHENTICATION:FAILED");
				window.close();
			}
		}
	}

	render() {
		return (
			<div>
				<Spinner center />
			</div>
		);
	}
}
