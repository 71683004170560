import {
	GET_SERVICES,
	SET_SELECTED_SERVICES,
	GET_CONSENT_INFO,
	GET_CONSENT_PRESENT,
	POST_INVITE_ADMIN,
	GET_ADMIN_INVITATIONS,
	SET_EMAIL_SENT_LIST,
	GET_TENANT_PRODUCTS,
} from 'actions/services';
import { GET_SCHOOL_SERVICES } from 'actions/schools';
import { GET_TENANT } from 'actions/user';
import ServiceManager from '_class/ServiceManager';

const INITIAL_STATE = {
	availableProducts: null,
	selectedServices: [],
	tenantProducts: [],
	consentInfo: null,
	inviteAdminStatus: null,
	adminInvitations: [],
	emailSentList: [],
	isAppConsented: {
		data: null,
		error: null,
		requestResponseCode: null
	},
	tenantServices: null,
	schoolServices: null,
	availableServices: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SERVICES:
			return { ...state, availableProducts: action.payload };

		case SET_SELECTED_SERVICES:
			return { ...state, selectedServices: action.payload };

		case GET_CONSENT_INFO:
			return { ...state, consentInfo: action.payload };

		case POST_INVITE_ADMIN:
			return { ...state, inviteAdminStatus: action.payload };

		case GET_ADMIN_INVITATIONS:
			return { ...state, adminInvitations: action.payload };

		case GET_CONSENT_PRESENT:
			var existingObject = state.isAppConsented;
			existingObject.data = action.error ? null : action.payload;
			existingObject.error = action.error ? action.payload?.response : null;
			existingObject.requestResponseCode = action.error ? action.payload?.response?.status : 200;
			return { ...state, isAppConsented: existingObject };
		case SET_EMAIL_SENT_LIST:
			return { ...state, emailSentList: action.payload }

		case GET_TENANT_PRODUCTS:
			return { ...state, tenantProducts: action.payload }

		case GET_TENANT:
			return { ...state, tenantServices: action.payload !== null ? action.payload.tenantServices : null, availableServices: new ServiceManager(action.payload !== null ? action.payload.tenantServices : null, state.schoolServices).getServices() };

		case GET_SCHOOL_SERVICES:
			return { ...state, schoolServices: action.payload, availableServices: new ServiceManager(state.tenantServices, action.payload).getServices() };

		default:
			return state;
	}
};
