import React, { useState, useEffect, useMemo } from 'react';
import { Icon } from '@haldor/ui';
import { useDispatch, useSelector } from 'react-redux';
import { translate as getTranslate } from '@haldor/ui';
import DropDownList from './../DropDownList';
import ListSelectorDropDown from './ListSelectorDropDown';
import {
	changeSelected,
	getSectionSearch,
	removeAllUser,
	removeCustomGroup,
	removeSelectedPeople,
	setAllActive,
	getSections,
	addAll,
	setAllGroups,
	toggleAllUsers,
	changeSelectedOwner,
	removeSelectedOwner,
	removeAll,
	setAllSearched,
} from 'actions/ScheduleFilter';
import { clearActivitesForOther } from 'actions/schedule';

const ListSelector = (props) => {
	const {
		filterLoaded,
		setFilterLoaded,
		scheduleLoading
	} = props;
	const ScheduleFilter = useSelector((state) => state.ScheduleFilter);
	const sections = useSelector((state) => state.sections.educationGroups);
	const translate = getTranslate(useSelector((state) => state.Languages.translations));
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.user.currentUser);
	const [isLoading, setIsloading] = useState({});
	const [isIopen, setIsOpen] = useState({});
	const [displaySearch, setDisplaySearch] = useState(false);
	const [disableSaveFilter, setDisableSaveFilter] = useState(true);

	const fetchData = (checked, id, button, data, ownerArr = [], search) => {
		if (checked) {
			setIsOpen((prev) => ({ ...prev, [id]: true }));
			let findExisting;
			let countObject = {};

			if (search) {
				findExisting = ScheduleFilter.storedSearch.filter((x) => x.id == id);
			} else {
				findExisting = ScheduleFilter.customGroup.filter((x) => x.id == id);
			}

			if (!findExisting[0]?.students || !findExisting[0]?.owners) {
				setIsloading((prev) => ({ ...prev, [id]: true }));
				if (button) {
					dispatch(getSections(id, true, currentUser?.id, search))
						.then(() => {
							setIsloading((prev) => ({ ...prev, [id]: false }));
						})
						.catch((e) => {
							console.error(e);
						});

					return;
				}
			}

			let newData = JSON.parse(JSON.stringify(data));

			if (ownerArr != null) {
				newData.owners = ownerArr;
			}

			dispatch(setAllActive(id, [], newData, search));
			return;
		}

		setIsOpen((prev) => ({ ...prev, [id]: false }));

		if (search) {
			let find = ScheduleFilter.storedSearch.filter((x) => x.id !== id);
			dispatch(removeAllUser(id, data, ownerArr, find, true));
			return;
		}

		const find = ScheduleFilter.userFilter.filter((x) => x.id !== id);
		dispatch(removeAllUser(id, data, ownerArr, find, search));
	};

	const searchGroup = (values) => {
		return new Promise(async (resolve) => {
			if (values != null) {
				resolve(1);

				//check if searched items doesn't already exists in both stored and in section
				if (ScheduleFilter.storedSearch.filter((x) => x.id === values.group.id).length > 0) {
					setDisplaySearch(false);
					return;
				}

				if (sections.filter((x) => x.id === values.group.id).length > 0) {
					setDisplaySearch(false);
					return;
				}

				fetchData(true, values.group.id, true, [], [], true);
				setDisplaySearch(false);
			}
		});
	};

	const removeSearchedItem = (id, object) => {
		dispatch(removeCustomGroup(id, object));
		let getLength = ScheduleFilter.userFilter.filter((x) => x.id !== id);
		if (getLength.length === 0) {
			//clear searchedItems from store if selectedItems are empty
			dispatch(clearActivitesForOther());
		}
	};

	//fetch all members of a section one by one
	const fetchAll = (fetchNew, searched) => {
		let count = 0;
		let arr = [];
		let checked = {};
		let total = 0;
		let owners = 0;

		if (fetchNew) {
			//get all none selected items
			let getNoneItems = sections.filter((x) => {
				return !ScheduleFilter.customGroup.find((t) => {
					return t.id == x.id;
				});
			});

			//make an api call to get every items
			getNoneItems.map(async (items) => {
				checked[items.id] = true;
				if (ScheduleFilter?.customGroup.find((x) => x.id == items.id) === undefined) {
					setIsloading((prev) => ({ ...prev, [items.id]: true }));
					setIsOpen((prev) => ({ ...prev, [items.id]: true }));

					try {
						const response = await addAll(items.id);

						if (response) {
							let object = JSON.parse(JSON.stringify(response?.data));
							if (object.owners == null) {
								object.owners = [];
							}

							if (object.students == null) {
								object.students = [];
							}

							owners += object.owners.filter((x) =>
								x.id !== currentUser?.id
							).length ?? 0;

							total += object.students.length ?? 0;
							checked[items.id] = true;
							arr.push(object);
							count++;
							setIsloading((prev) => ({ ...prev, [items.id]: false }));
						}
					} catch (e) {
						let object = JSON.parse(JSON.stringify(items));
						if (object.owners == null) {
							object.owners = [];
						}

						if (object.students == null) {
							object.students = [];
						}

						checked[items.id] = true;
						arr.push(object);
						count++;
					}

					if (getNoneItems.length === count) {
						let full = [...ScheduleFilter.customGroup, ...arr];
						dispatch(setAllGroups(full, total, sections.length, [], checked));

						return;
					}
				}
			});
		}

		sections.map((items) => {
			checked[items.id] = true;
		});

		if (searched) {
			const checkedSearched = JSON.parse(JSON.stringify(ScheduleFilter.storedSearch)).map((items) => {
				checked[items.id] = true;
				items.owners = [];
				return items;
			});

			dispatch(setAllSearched(checkedSearched, checked));
			return;
		}

		dispatch(setAllGroups(ScheduleFilter.customGroup, null, null, false, checked));
	};

	const getActiveSearch = (groupId, objId, isOwner, search) => {
		let exists = false;
		let existingGroup;
		existingGroup = ScheduleFilter.userFilter.find((x) => x.id === groupId);
		if (isOwner) {
			if (existingGroup?.owners) {
				existingGroup.owners.map((item) => {
					if (item.id == objId) {
						exists = true;
						return;
					}
				});
			}

			if (exists) {
				return true;
			}
		}

		if (existingGroup?.students) {
			existingGroup?.students.map((item) => {
				if (item.id == objId) {
					exists = true;
					return;
				}
			});
		}

		if (exists) {
			return true;
		}

		return false;
	};

	//change object by changing students or owners property
	const changeObj = (userObj, newObj, isOwner = false, search = false) => {
		let getObj = ScheduleFilter?.userFilter.find((x) => x.id === userObj.id);
		let parseObj;
		if (!getObj) {
			parseObj = JSON.parse(JSON.stringify(userObj));
		} else {
			parseObj = JSON.parse(JSON.stringify(getObj));
		}

		if (isOwner) {
			if (!parseObj?.owners) {
				parseObj.owners = [newObj];
			} else {
				parseObj.owners = parseObj.owners.filter((x) => x.id !== newObj.id).concat(newObj);
			}

			return parseObj;
		}

		if (!parseObj?.students) {
			parseObj.students = [newObj];
		} else {
			parseObj.students = parseObj.students.filter((x) => x.id !== newObj.id).concat(newObj);
		}

		return parseObj;
	};

	const handlePeopleSelection = (checked, userObj, obj, owner = false, searchitems = false) => {
		if (checked) {
			//Store searchitems in a seperate array in redux
			if (searchitems) {
				let addPeople = JSON.parse(JSON.stringify(userObj));
				if (owner) {
					//Remove all owners from the stored object before adding new
					addPeople.owners = [];
					addPeople.students = [];
					dispatch(changeSelectedOwner(changeObj(addPeople, obj, true, true)));
					return;
				}
				//Remove all students from the stored object before adding new
				addPeople.students = [];
				addPeople.owners = [];
				dispatch(changeSelected(changeObj(addPeople, obj, false, true)));
				return;
			}

			if (owner) {
				dispatch(changeSelectedOwner(changeObj(userObj, obj, true)));
				return;
			}

			dispatch(changeSelected(changeObj(userObj, obj)));
			return;
		}

		if (searchitems) {
			let findItem = ScheduleFilter.userFilter.find((x) => x.id === userObj.id);
			let searchObj = JSON.parse(JSON.stringify(findItem));

			if (owner) {
				let removedOwners = findItem.owners.filter((x) => x.id !== obj.id);
				//Clear fetchdata if both owner and students are empty

				searchObj.owners = removedOwners;
				if (
					searchObj.students.length === 0 &&
					searchObj.owners.length === 0 &&
					ScheduleFilter.userFilter.length === 1
				) {
					dispatch(clearActivitesForOther());
				}

				searchObj.owner?.filter(x => x.id !== currentUser.id);
				dispatch(removeSelectedOwner(searchObj));
				return;
			}

			let removedStudents = findItem.students.filter((x) => x.id !== obj.id);
			searchObj.students = removedStudents;

			//Clear fetchdata if both owner and students are empty
			if (
				searchObj.students.length === 0 &&
				searchObj.owners.length === 0 &&
				ScheduleFilter.userFilter.length === 1
			) {
				dispatch(clearActivitesForOther());
			}

			searchObj.owner?.filter(x => x.id !== currentUser.id);
			dispatch(removeSelectedPeople(searchObj));
			return;
		}

		let findItem = ScheduleFilter.userFilter.find((x) => x.id === userObj.id);
		let newObj = JSON.parse(JSON.stringify(findItem));
		if (owner) {
			let removedOwners = findItem.owners.filter((x) => x.id !== obj.id && x.id !== currentUser.id);
			newObj.owners = removedOwners;
			dispatch(removeSelectedOwner(newObj));
			return;
		}

		let removedStudents = findItem.students.filter((x) => x.id !== obj.id);
		newObj.students = removedStudents;
		newObj.owner?.filter(x => x.id !== currentUser.id);
		dispatch(removeSelectedPeople(newObj));
	};

	const handlePlus = (open, id, search) => {
		if (open) {
			setIsOpen((prev) => ({ ...prev, [id]: false }));
			return;
		}

		let findExisting;
		if (search) {
			findExisting = findExisting = ScheduleFilter.storedSearch.filter((x) => x.id == id);
		} else {
			findExisting = ScheduleFilter.customGroup.filter((x) => x.id == id);
		}

		setIsOpen((prev) => ({ ...prev, [id]: true }));

		if (!findExisting[0]?.students) {
			setIsloading((prev) => ({ ...prev, [id]: true }));
			if (search) {
				dispatch(getSectionSearch(id, false, currentUser?.id))
					.then(() => {
						setIsloading((prev) => ({ ...prev, [id]: false }));
					})
					.catch((e) => {
						console.error(e);
						setIsloading((prev) => ({ ...prev, [id]: false }));
					});

				return;
			}

			dispatch(getSections(id, false, currentUser?.id))
				.then(() => {
					setIsloading((prev) => ({ ...prev, [id]: false }));
				})
				.catch((e) => {
					console.error(e);
					setIsloading((prev) => ({ ...prev, [id]: false }));
				});

			return;
		}
	};

	const handleAll = (checked) => {
		if (checked) {
			//select all Searched
			if (ScheduleFilter.storedSearch?.length > 0) {
				fetchAll(false, true);
			}

			if (ScheduleFilter.totalGroups >= sections.length) {
				fetchAll(false);
			} else {
				fetchAll(true);
			}

			dispatch(toggleAllUsers());
		}

		if (!checked) {
			setIsOpen({});
			dispatch(removeAll());
		}
	};

	// Load saved filter
	useEffect(() => {
		if (!filterLoaded) {
			// Clear previous data
			dispatch(removeAll());

			// Load saved filter
			let savedFilter = JSON.parse(localStorage.getItem('haldor-schedule-students-filter'));
			if (savedFilter?.length > 0) {
				savedFilter.forEach(item => {
					dispatch(getSections(item.groupId, false, currentUser?.id, item.searched, function (data) {
						setDisableSaveFilter(true);

						let newData = JSON.parse(JSON.stringify(data));
						newData.students = newData.students.filter(student => item?.students?.includes(student.logId));
						newData.owners = newData.owners.filter(owner => item?.owners?.includes(owner.logId));
						dispatch(setAllActive(item.groupId, [], newData, item.searched, { studentsCount: newData?.students?.length, ownersCount: newData?.owners?.length }));

						if (item?.expanded === true) {
							handlePlus(false, item.groupId);
						}

						setDisableSaveFilter(false);
					}));
				});
			} else {
				setDisableSaveFilter(false);
			}

			setFilterLoaded(true);
		}
	}, [sections]);

	// Save filter when userFilter changes or a group in the list is expanded
	useEffect(() => {
		if (disableSaveFilter) {
			return;
		}

		let dataToStore = [];
		ScheduleFilter.userFilter.forEach(item => {

			dataToStore.push({
				groupId: item.id,
				students: item?.students?.map(student => student.logId),
				owners: item?.owners?.map(owner => owner.logId),
				expanded: isIopen[item.id],
				searched: ScheduleFilter.storedSearch.find(x => x.id === item.id) != null
			});
		});
		localStorage.setItem('haldor-schedule-students-filter', JSON.stringify(dataToStore));
	}, [ScheduleFilter.userFilter, isIopen]);

	const groupsSelectedCount = useMemo(() => {
		return Object.keys(ScheduleFilter.selectedGroups).filter((key) =>
			ScheduleFilter.selectedGroups[key]
		).length;
	}, [ScheduleFilter.selectedGroups]);

	return (
		<div>
			<DropDownList
				label={`${translate('Displaying')} ${ScheduleFilter?.selectedPeople} ${translate('students').toLowerCase()}, ${ScheduleFilter.ownersSelected} ${translate('owners').toLowerCase()}`}
				offset={15}
				placement={'bottom'}
				disabled={scheduleLoading}
			>
				<ListSelectorDropDown
					groupsSelectedCount={groupsSelectedCount}
					handleAll={handleAll}
					fetchData={fetchData}
					handlePlus={handlePlus}
					getActiveSearch={getActiveSearch}
					handlePeopleSelection={handlePeopleSelection}
					removeSearchedItem={removeSearchedItem}
					searchGroup={searchGroup}
					isIopen={isIopen}
					isLoading={isLoading}
					disabled={scheduleLoading}
				/>
			</DropDownList>

			{ScheduleFilter.selectedPeople > 0 || ScheduleFilter.ownersSelected > 0 ?
				<div className="clear-filter-container" onClick={() => { if (!scheduleLoading) dispatch(removeAll()); }}>
					<Icon name="Close" /> {translate('clear-filter')}
				</div> : null}

		</div>
	);
};

export default ListSelector;
