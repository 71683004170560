export const getDescription = (description) => {
	try {
		description = description.replace(/amp;/g, '');
		description = description.replace(/amp;wd/g, '');
		description = description.replace(/&lt;/g, '<');
		description = description.replace(/&gt;/g, '>');
		description = description.replace(/\<\/b\>/ig, ' </b>');
		description = description.replace(/\<\/i\>/ig, ' </i>');
		description = description.replace(/\<\/a\>/ig, ' </a>');
		description = description.replace(/\<\/p\>/ig, ' </p>');
	} catch (error) {
		description = description;
	}

	return description;
};

export const debounce = (func, wait, immediate) => {
	var timeout;

	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};

		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const throttle = (func, limit) => {
	let lastFunc
	let lastRan

	return function () {
		const context = this
		const args = arguments
		if (!lastRan) {
			func.apply(context, args)
			lastRan = Date.now()
		} else {
			clearTimeout(lastFunc)
			lastFunc = setTimeout(function () {
				if (Date.now() - lastRan >= limit) {
					func.apply(context, args)
					lastRan = Date.now()
				}
			}, limit - (Date.now() - lastRan))
		}
	}
}

export const getImmersiveReaderDataFromBlocks = (title, blocks) => {
	let data = {
		title: title,
		chunks: [
			{
				content: "<h1>" + title + "</h1>",
				mimeType: "text/html",
			},
		],
	};

	if (blocks != null) {
		blocks.forEach(block => {
			if (block.type == 'Haldor.Blocks.Editor') {
				if (block.title != null && block.title != '') {
					data.chunks.push({
						content: `<h3>${block.title}</h3>`,
						mimeType: "text/html",
					});
				}

				data.chunks.push({
					content: getDescription(block.value),
					mimeType: "text/html",
				});
			}
		})
	}

	return data;
}

/**
 * Capitalize string
 * @param {string} string
 * @returns {string} Capitalized string
 */
export const capitalize = (string) => {
	if (string == null) {
		return '';
	}

	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}