import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import { clearVerdicts, getVerdictScale } from 'actions/verdicts';

import VerdictFeedbackForm from 'containers/Verdict/Form/Partials/VerdictFeedbackForm';
import VerdictLevelForm from 'containers/Verdict/Form/Partials/VerdictLevelForm';

import { Icon, Button, TooltipMenu } from '@haldor/ui';

class MultipleVerdictForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			level: '0',
			feedback: '',
			loading: true,
		};
	}

	componentDidMount = () => {
		if (this.props.scale == null) {
			this.props.getVerdictScale()
				.then(() => {
					this.setState({ loading: false });
				});
		} else {
			this.setState({ loading: false });
		}
	};

	removeStudent = (student) => {
		if (this.props.onRemoveStudent != null) {
			this.props.onRemoveStudent(student);
		}
	}

	abort = (event) => {
		event.preventDefault();
		this.props.onAbort();
	}

	submit = (values) => {
		return new Promise(resolve => {
			let data = [];

			this.props.students.forEach(student => {
				data.push({
					scaleLevelId: this.state.level,
					feedback: this.state.feedback,
					scaleId: this.props.scale.id,
					status: 'VERDICT_DRAFT',
					userId: student.id,
				});
			});

			if (this.props.onSubmit != null) {
				this.props.onSubmit(data).then(() => {
					resolve(1);
				});
			} else {
				resolve(1);
			}
		});
	}

	render() {
		const { submitting, handleSubmit } = this.props;
		const { feedback, level, loading } = this.state;

		if (loading) {
			return <div></div>
		}

		return (
			<div>
				{submitting ?
					<div className="is_sending" style={{ height: '100%', top: 0 }}>
						<p><span className="loading-spinner" /></p>
					</div>
					: null}

				<div className="form-container">
					<form onSubmit={handleSubmit(this.submit)} className="form form-component">
						<div className="form-row">
							<div className="verdict verdict-users-list">
								{this.props.translate('students')}

								{this.props.students.map(student => {
									const dropdownTrigger = (<div className="dropdown--trigger">
										<Icon name="Bullets" />
									</div>);

									return <div className="verdict-user" key={student.id}>
										<div className="name">
											{student.firstName} {student.lastName}
										</div>

										<TooltipMenu trigger={dropdownTrigger}>
											<TooltipMenu.Item onClick={() => this.removeStudent(student)}>
												{this.props.translate('Delete')}
											</TooltipMenu.Item>
										</TooltipMenu>
									</div>
								})}
							</div>
						</div>

						<div className="form-row" style={{ marginTop: '1.55rem' }}>
							{this.props.translate('verdicts')}

							{this.props.scale != null ?
								<VerdictLevelForm
									levels={this.props.scale.levels}
									value={level}
									onChange={level => this.setState({ level })}
									key={this.props.scale.id}
								/>
								: null}
						</div>

						<div className="form-row">
							<VerdictFeedbackForm
								value={this.state.feedback}
								onChange={feedback => this.setState({ feedback })}
							/>
						</div>

						<div className="form-row submit" style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
							<Button disabled={level == 0 || level == null || submitting}>
								{this.props.translate('save-verdicts')}
							</Button>

							<div className="align-right">
								<Button onClick={this.abort} type="secondary">
									{this.props.translate('Cancel')}
								</Button>
							</div>

							<div className="clearfix"></div>
						</div>
					</form>
				</div>
			</div>
		)
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		scale: state.Verdicts.scale,
	};
}

export default connect(mapStateToProps, {
	getVerdictScale,
	clearVerdicts,
}, null, {
	pure: false,
})(reduxForm({
	form: 'MultipleVerdictForm',
	destroyOnUnmount: true,
})(MultipleVerdictForm));