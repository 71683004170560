import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, translate } from '@haldor/ui';
import * as microsoftTeams from "@microsoft/teams-js";

import api from 'lib/api';
import msal from 'lib/msal';

import schoolHandling from 'helpers/schoolHandling';

import { postData } from 'actions';
import { getTenant, getMe, setUser, addUser, getMySections } from 'actions/user';
import { createTenant, isConsentPresent } from 'actions/services';
import { getSectionByGraphId, informAboutTabData, getEducationGroups, getMentorGroups } from 'actions/sections';

import { setOnboardingExpireTokenIn, setOnboardingToken } from 'lib/msal';
import { getSchoolsDataForSections, getSchoolByExternalId } from 'actions/schools';

import { Spinner } from 'UI';
import Input from 'components/Inputs/input';
import User from '_class/User';

class TabConfigure extends Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: false,
			tab: 'NOTABSELECTED',
			title: '',
			user: {
				email: null,
				firstName: null,
				lastName: null,
				userRoles: [],
			},
			isLoading: true,
			showErrorLogin: false,
			loadingGroupData: true,
			disableSchoolSelector: false,
			tabValid: {
				valid: null,
				text: '',
			},
			titleValid: {
				valid: null,
				text: '',
			},
			fieldValidation: {
				valid: null,
				text: '',
				hideForms: false
			},
			hideSchoolInput: false,
			groupId: null,
			schoolId: 0,
			groupType: 'EDUCATION_GROUP',
		};

		this.onTitleChange.bind(this);
		this.onTabSelect.bind(this);
	}

	retrieveUser(context) {
		let user = this.state.user;

		if (context.user.licenseType) {
			user.email = context.user.licenseType;
			user.firstName = context.user.licenseType.split('@')[0];

			if (user.firstName.indexOf(".") > -1) {
				let firstName = user.firstName.split('.')[0];
				let lastName = user.firstName.split('.')[1];

				user.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
				user.lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
			}
		}

		if (context.user.licenseType != null) {
			user.userRoles.push(context.user.licenseType);
		}

		this.setState({ user: user });
	}

	UNSAFE_componentWillMount() {
		document.body.style.backgroundColor = 'white';

		this.initTabConfigure();
	}

	/**
	 * This will init tab Configure. This method will validate if tenant has admin consent and based on the result, it will initiate proper authentication
	 */
	initTabConfigure() {
		let TenantHasAdminConsent = false;
		this.setState({ showErrorLogin: false });

		microsoftTeams.app.getContext().then((context) => {
			this.props.isConsentPresent(context.user.tenant.id).then(() => {
				TenantHasAdminConsent = this.props.isAppConsented;

				if (!TenantHasAdminConsent) {
					localStorage.setItem('onboarding:init', '1');
				} else {
					localStorage.removeItem('onboarding:init');
				}

				microsoftTeams.pages.getConfig().then((settings) => {
					if (settings != null && settings.suggestedDisplayName != null) {
						this.setState({ title: settings.suggestedDisplayName, edit: true });
					}
				});

				microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
					microsoftTeams.app.getContext().then(async (context) => {
						if (this.props.group != null && this.props.group.id == 0) {
							if (this.state.schoolId != 0) {
								let group = {
									title: context.team.displayName,
									graphId: context.team.groupId,
									schoolId: this.state.schoolId,
									type: this.state.groupType,
								};

								await this.props.postData(group, 'sections');

								if (group.schoolId != 0) {
									schoolHandling.setSchoolOnUser(this.props.currentUser, group.schoolId);
								}
							}
						}

						await this.props.informAboutTabData(context.team.groupId, this.state.tab);

						saveEvent.notifySuccess();
					});
				});

				microsoftTeams.authentication.getAuthToken().then((token) => {
					this.props.getTenant().then(() => {
						if (this.props.tenant == null || this.props.tenant == "") {
							this.retrieveUser(context);
							const userPrincipalName = context.user.userPrincipalName;
							let tenantDomain = null;

							if (userPrincipalName != null && userPrincipalName.indexOf("@") > -1) {
								tenantDomain = userPrincipalName.substring(userPrincipalName.indexOf("@") + 1).toLowerCase();
							}

							if (tenantDomain == null && context.user.loginHint != null && context.user.loginHint.indexOf("@") > -1) {
								tenantDomain = context.user.loginHint.substring(context.user.loginHint.indexOf("@") + 1).toLowerCase();
							}

							let tenant = {
								title: "",
								tenantId: context.user.tenant.id,
								tenantDomain: tenantDomain,
								tenantSPOUrl: "https://" + context.sharepoint.teamSiteDomain,
							};

							this.props.createTenant(tenant).then(() => {
								this.props.addUser(this.state.user).then(() => {
									this.props.getTenant().then(() => {
										this.getUserData().then(() => {
											this.getTabConfigInformation(context).then(() => {
												this.setState({ isLoading: false });
											});
										});
									});
								});
							});
						} else {
							this.getUserData().then(() => {
								this.getTabConfigInformation(context).then(() => {
									this.setState({ isLoading: false });
								});
							});
						}
					});

				}).catch(() => {
					this.setState({ showErrorLogin: true });
					console.warn('fail popup error LandingPage', error)
				});
			});
		});
	}

	async getTabConfigInformation(context) {
		const { currentUser } = this.props;

		api.post('log/Apps/HaldorEdu/Context/Configure', context)

		this.setState({ groupId: context.team.groupId });

		await this.props.getSectionByGraphId(context.team.groupId, 'GRAPH_OBJECT;');

		let graphSection = this.props.group;

		if (graphSection == null || currentUser == null) {
			this.setFieldValidation(this.props.translate("Whoops, something went wrong!"), true);
			return false;
		}

		if (graphSection.type == "MENTOR_GROUP") {
			this.setFieldValidation(this.props.translate("You can't add a tab because the group is connected as mentor group"), true);
			return false;
		}

		if (graphSection.externalSchoolId != null) {
			const school = currentUser.schools?.find((school) => {
				return school.externalId == graphSection.externalSchoolId
			});

			if (school != null) {
				this.setState({ schoolId: school.id, disableSchoolSelector: true }, () => {
					schoolHandling.setSchoolOnUser(this.props.currentUser, school.id);
					this.onValidate();
				});
			} else {
				if (graphSection.externalSchoolId != null) {
					await this.props.getSchoolByExternalId(graphSection.externalSchoolId);

					if (this.props.school != null) {
						this.setFieldValidation(this.props.translate("You can't add a tab because you don't have permission to the group {{1}} in the school {{2}}.", graphSection.title, this.props.school.title), true);
						return false;
					} else {
						this.setFieldValidation(this.props.translate("You can't add a tab because you don't have permission to the group {{1}}", graphSection.title), true);
						return false;
					}
				}
			}
		}

		if (graphSection.schoolID != null && graphSection.schoolID > 0) {
			this.setState({
				schoolId: graphSection.schoolID,
				loadingGroupData: false,
				disableSchoolSelector: true
			}, () => {
				schoolHandling.setSchoolOnUser(this.props.currentUser, graphSection.schoolID);
				this.onValidate();
			});

			return false;
		}

		this.setState({ loadingGroupData: false });
	}

	getUserData = () => {
		return new Promise((resolve, reject) => {
			this.props.getMe().then(() => {
				const { currentUser } = this.props;

				if (currentUser != null && currentUser.schools != null) {
					this.props.getSchoolsDataForSections().then(() => {
						if (currentUser.schools.length == 1) {
							this.setState({ schoolId: currentUser.schools[0].id, disableSchoolSelector: true });
							schoolHandling.setSchoolOnUser(this.props.currentUser, currentUser.schools[0].id);
						}
					});
				}

				resolve();
			})
		});
	}

	selectSchool = (event) => {
		this.setState({ schoolId: event.target.value }, () => {
			schoolHandling.setSchoolOnUser(this.props.currentUser, event.target.value);
			this.onValidate();
		});
	}

	setFieldValidation = (message, hideForms = false) => {
		this.state.fieldValidation.valid = false;
		this.state.fieldValidation.text = message;
		this.state.fieldValidation.hideForms = hideForms;

		this.setState({ fieldValidation: this.state.fieldValidation, loadingGroupData: false });
	}

	removeFieldValidation = () => {
		this.state.fieldValidation.valid = true;
		this.state.fieldValidation.hideForms = false;
		this.state.fieldValidation.text = '';

		this.setState({ fieldValidation: this.state.fieldValidation });
	}

	getTabUrl() {
		const tabId = this.state.tab;
		let url = '';

		let selectedTab = this.props.tenant.tabs.find(tab => {
			return tab.id == tabId;
		});

		if (selectedTab != null) {
			url = window.location.origin + selectedTab.contentUrl;
		}

		return url;
	}

	onTitleChange(event) {
		this.setState({ title: event.target.value }, () => {
			this.onValidate();
		});
	}

	onTabSelect(event) {
		this.setState({ tab: event.target.value }, () => {
			this.onValidate();
		});
	}

	onValidate = () => {
		const name = this.state.title;
		const tab = this.getTabUrl();
		const schoolId = this.state.schoolId;
		const user = new User(this.props.currentUser);
		const schoolsSettings = this.props.schoolsData;
		const graphSection = this.props.group;

		this.removeFieldValidation();

		if (this.props.currentUser == null || schoolHandling.getSchoolForUser(this.props.currentUser) == null || schoolsSettings == null) {
			microsoftTeams.pages.config.setValidityState(false);
			return false;
		}

		if (!user.isTeacher()) {
			this.setFieldValidation("You do not have permissions to connect the group to the selected school.");
			microsoftTeams.pages.config.setValidityState(false);
			return;
		}

		const currentActiveSchoolSettings = schoolsSettings.filter((element) => {
			return element.schoolId == schoolId;
		});

		const isAllowedToConnect = (currentActiveSchoolSettings.length == 0 || currentActiveSchoolSettings.filter(element => {
			return element.allowManualEducationGroupConnection == true
		}).length > 0);

		if (name != '' && name != null && tab != '' && schoolId > 0 && (isAllowedToConnect || (graphSection != null && graphSection.schoolID > 0))) {
			this.removeFieldValidation();

			microsoftTeams.pages.config.setConfig({
				contentUrl: tab,
				suggestedDisplayName: name,
				websiteUrl: 'https://app.haldor.se',
			});

			microsoftTeams.pages.config.setValidityState(true);
		} else {
			if (!isAllowedToConnect && (graphSection != null && graphSection.schoolID == 0)) {
				this.setFieldValidation("You are not allowed to connect groups to this school because the administrator has disabled that functionality.");
			}

			microsoftTeams.pages.config.setValidityState(false);
		}
	}

	renderForm() {

		if (this.props.currentUser == null) {
			return;
		}
		const { currentUser } = this.props;

		if (currentUser.schools == null || currentUser.schools.length < 1 || currentUser.roles == null || currentUser.roles.length < 1) {
			return (
				<div style={{ backgroundColor: this.state.background }}>
					<div className="login-content">
						<h1>
							{this.props.translate('something-went-wrong')}
						</h1>

						<p className="login-content__subtitle">
							{this.props.translate('error-no-schools-or-roles')}
						</p>

						<p>&nbsp;</p>
						<p className="login-content__helptext" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(currentUser.userId) }}>
							UserObjectId: {currentUser.userId}
						</p>
					</div>
				</div>
			);
		}

		return (
			<div className="form-container form-create_task">

				<form className="form form-component">
					{!this.state.fieldValidation.hideForms ? <div>
						<div>{this.props.translate('tabconfig-explain')}</div>
						<div className="form-row">
							<label>{this.props.translate('tabconfig-choose-type')}</label>
							<div className="select">
								<select
									name="tab"
									id="tab-select"
									onChange={this.onTabSelect.bind(this)}
									defaultValue="NOTABSELECTED"
								>
									<option value="NOTABSELECTED">
										{this.props.translate('select-an-alternative')}
									</option>

									{this.props.tenant.tabs != null && this.props.tenant.tabs.map((tab) => (
										<option key={tab.id} value={tab.id}>
											{this.props.translate('tab-'.concat(tab.id))}
										</option>
									))}
								</select>

								{this.state.tabValid.valid == false ?
									<div className="form-validation">
										<span className="text-danger">
											{this.state.tabValid.text}
										</span>
									</div>
									: null}
							</div>
						</div>

						<div style={{ clear: 'both' }} />

						<Input
							name="title"
							type="text"
							placeholder={this.props.translate('title')}
							maxLength="199"
							label={this.props.translate('title')}
							value={this.state.title}
							onChange={this.onTitleChange.bind(this)}
							disabled={this.state.edit}
						/>


						<div className="form-row">
							<label>
								{this.props.translate('school')}
							</label>

							<div className="select">
								<select
									disabled={this.state.disableSchoolSelector}
									value={this.state.schoolId}
									onChange={this.selectSchool}
								>
									<option value="0">
										{this.props.translate('pick-a-school')}
									</option>

									{this.props.currentUser?.schools.map(school => {
										return <option key={school.id} value={school.id}>
											{school.title}
										</option>
									})}
								</select>
							</div>
						</div></div> : null}


					{this.state.fieldValidation.valid == false ?
						<div className="form-validation">
							<h4 className="text-danger">
								{this.state.fieldValidation.text}
							</h4>
						</div>
						: null}

					{this.state.titleValid.valid == false ?
						<div className="form-validation">
							<span className="text-danger">
								{this.state.titleValid.text}
							</span>
						</div>
						: null}
				</form>
			</div>
		);
	}

	renderNotACustomer() {
		return (
			<div>
				{this.props.translate('not-a-customer')}
				<a href="https://portal.haldor.se">https://portal.haldor.se</a>
			</div>
		);
	}

	render() {
		return (
			<div style={{ height: '300px' }}>
				{this.state.isLoading || this.state.loadingGroupData ?
					<div>
						{this.state.showErrorLogin ? <div style={{ backgroundColor: '#FFF', boxShadow: '0 5px 15px 0 rgb(0 0 0 / 5%)', borderRadius: '3px', padding: '1.4rem 1.7rem', marginBottom: '3rem', textAlign: 'center' }}>
							{this.props.translate('Remember to allow popups in order to complete the login, you may need to click the Login button twice.')}
							<Button style={{ display: 'block', margin: 'auto', marginTop: '0.5rem' }} type="secondary" onClick={this.initTabConfigure.bind(this)}>
								{this.props.translate('log-in')}
							</Button>
						</div> : <Spinner center />}
					</div>
					:
					this.renderForm()
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		group: state.sections.graphSection,
		currentUser: state.user.currentUser,
		isAppConsented: state.Services.isAppConsented?.data,
		schoolsData: state.School.schoolsDataForSection,
		school: state.School.school,
		groups: state.user.mySections,
	};
}

export default connect(mapStateToProps, {
	getTenant,
	getSectionByGraphId,
	postData,
	informAboutTabData,
	createTenant,
	isConsentPresent,
	addUser,
	getMe,
	setUser,
	getEducationGroups,
	getMySections,
	getMentorGroups,
	getSchoolsDataForSections,
	getSchoolByExternalId
})(TabConfigure);