import { createNote, updateNote } from 'actions/notes';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Editor } from 'UI/Inputs';
import Moment from 'moment';
import DateTime from '_class/DateTime';
import DatePicker from 'react-datepicker';
import { Button, Checkbox } from '@haldor/ui';

class NoteForm extends Component {

	constructor(props) {
		super(props);

		let isPublic = true;
		let relationships = [];
		// let remindDate = null;
		if (props.initialValues != null) {
			if (props.initialValues.public != null) {
				isPublic = props.initialValues.public;
			}

			relationships = props.initialValues.relationships;
			// remindDate = props.initialValues.remindDate;
		}

		this.state = {
			// remindDate,
			private: !isPublic,
			relationships: relationships
		};
	}

	/* Helper methods */
	getRelationship = () => {
		if (this.state.relationships != null && this.state.relationships.length > 0) {
			return this.state.relationships;
		}

		if (this.props.references != null) {
			return this.props.references;
		}

		return {
			'referenceID': this.props.id,
			'referenceType': this.props.reference,
		};
	}

	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	quillValidation = (value) => {
		var strValue = value;

		var text = strValue;
		if (typeof (strValue) == 'string') {
			//text = strValue.replace(/<(?:.|\n)*?>/gm, '');
			text = text.replace('<p>', '');
			text = text.replace('</p>', '');
			text = text.replace('<br>', '');
		}

		if (text == "" || text == null) {
			return this.props.translate('write-a-meeting-note');
		}

		return undefined;
	}

	/* Events */
	submit = (values) => {
		return new Promise(async resolve => {
			values.public = !this.state.private;
			values.relationships = [];
			values.memberships = [];
			// values.remindDate = this.state.remindDate;

			let references = this.getRelationship();

			if (Array.isArray(references) && references.length > 0) {
				values.relationships = references;
			} else {
				values.relationships.push(this.getRelationship());
			}

			if (values.status == null) {
				values.status = 'CREATED';
			}

			if (values.id == null) {
				await this.props.createNote(values);
			} else {
				await this.props.updateNote(values);
			}

			if (this.props.onAbort != null) {
				await this.props.onAbort(true, values);
			}

			resolve(1);
		});
	}

	onCancel = () => {
		if (this.props.onAbort != null) {
			this.props.onAbort(false);
		}
	}

	onDateChange = (remindDate) => {
		this.setState({ remindDate });
	}

	/* Render methods */
	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))
				}
			</div>
		);
	}

	renderShareCheckbox = () => {
		if (this.props.disableShare != null) {
			return null;
		}

		let shareLabel = this.props.translate('private-note');
		if (this.props.shareLabel != null) {
			shareLabel = this.props.shareLabel;
		}

		return (
			<div className="form-row" style={{ marginTop: 30 }}>
				<Checkbox
					value={this.state.private}
					onChange={(checked) => this.setState({ private: checked })}
					label={shareLabel}
				/>
			</div>
		)
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;
		const isEditing = this.props.initialValues != null;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row textarea">
						<label style={{ marginTop: 0 }}>{this.props.translate('text')}*</label>

						<Field
							component={Editor}
							name="text"
							validate={this.quillValidation}
							placeholder={this.props.translate('write-meeting-note')}
							translate={this.props.translate}
						/>
					</div>

					{/* <div className="form-row">
						<div className="form-row-half">
							<label>{this.props.translate('remind-me')}</label>

							<DatePicker
								selected={this.state.remindDate}
								onChange={this.onDateChange}
								minDate={Moment()}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								popperPlacement="bottom-start"
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=""
								nextMonthButtonLabel=""
								isClearable
								placeholderText={this.props.translate('select-date-to-be-reminded-on')}
								{...this.state.startTime}
							/>
						</div>

						<div className="clearfix" />
					</div> */}

					{this.renderShareCheckbox()}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid}>
							{this.props.translate('save')}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		initialValues: state.Notes.active,
	};
}

export default connect(mapStateToProps, {
	createNote,
	updateNote,
})(reduxForm({
	form: 'NoteForm',
	destroyOnUnmount: true,
})(NoteForm));
