import { setPageTitle } from 'actions/header';
import { clearForm, getForm, getFormInstance, getFormTemplate, updateFormInstance } from 'actions/templates';
import Redirect from 'components/Redirect/Redirect';
import SendDynamicForm from 'containers/Forms/SendDynamicForm';
import DisplayFormResults from 'containers/FormTemplate/Display/DisplayFormResults';
import FormInstance from 'containers/FormTemplate/Display/FormInstance';
import PreviewFormTemplate from 'containers/FormTemplate/Display/PreviewFormTemplate';
import Modal from 'containers/Modals/Modal';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataList, Spinner } from 'UI';
import Block, { BlockContent, BlockWrapper } from 'UI/Elements/Block';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import DateTime from '_class/DateTime';

class SingleForm extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			formInstance: null,
			loading: true,
			loadingFormInstance: false
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		this.loadForm();
	}

	loadForm = () => {
		this.props.getForm(this.props.match.params.id).then(() => {
			let promises = [];
			if (this.props.user.id != this.props.form.creator) {
				let instanceId = this.props.form.formInstances[0].id;
				promises = [this.props.getFormInstance(instanceId), this.props.getFormTemplate(this.props.form.formTemplateID)];
			} else {
				promises = [this.props.getFormTemplate(this.props.form.formTemplateID)];
			}

			this.props.setPageTitle(this.props.form.title);

			Promise.all(promises).then(() => {
				this.setState({ loading: false })
			});
		}).catch(() => {
			this.setState({ notFound: true, loading: false });
		})
	}

	componentWillUnmount = () => {
		this.props.clearForm();
	}

	/* Events */
	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	onAbort = (reload) => {
		if (reload) {
			this.loadForm();
		}
	}

	onInstanceClick = (instance) => {
		this.props.getFormInstance(instance.id).then(() => {
			this.setState({ formInstance: instance });
		});
	}

	closeFormInstance = () => {
		this.setState({ formInstance: null });
	}

	/* Render methods */
	renderFormInstance = (instance) => {
		var edited = new DateTime(instance.edited);
		return (
			<RowItem key={instance.id} style={{ cursor: 'pointer' }} onClick={() => { instance.status == 'SUBMITTED' ? this.onInstanceClick(instance) : null }}>
				<RowCell title={this.props.translate('recipient')}>
					{instance.user.firstName} {instance.user.lastName}
				</RowCell>

				{instance.assigneeViewedCount != null ?
					<RowCell title={this.props.translate('opened')}>
						{instance.assigneeViewedCount}
					</RowCell> : null
				}

				<RowCell title={this.props.translate('last-updated')}>
					{instance.status == 'SUBMITTED' ?
						edited.getLongDateWithTime()
						: ''}
				</RowCell>

				<RowCell shrink title={this.props.translate('turned-in')}>
					{instance.status == 'SUBMITTED' ?
						this.props.translate('turned-in')
						: this.props.translate('not-turned-in')}
				</RowCell>
			</RowItem>
		)
	}

	render() {
		if (!this.props.services.forms) {
			return <Redirect to="/" />
		}

		if (this.state.loading) {
			return <Spinner center />
		} else if (this.state.notFound) {
			return <div className="single-task"><h3>{this.props.translate('not-found')}</h3></div>
		}

		const { translate, formTemplate, form, formInstance } = this.props;

		const turnedIn = [...this.props.form.formInstances].filter(x => x.status == 'SUBMITTED').length;

		return (
			<div className="single-task">
				<Modal isOpen={this.state.modal} onClose={() => this.toggleModal()} title={this.props.translate('forms')}>
					<SendDynamicForm />
				</Modal>

				<Modal overridePrompt isOpen={formInstance != null} type="small" onClose={this.closeFormInstance} title={translate('answers')}>
					<DisplayFormResults
						fields={formTemplate.fields}
						instance={this.state.formInstance}
					/>
				</Modal>

				<div className="single-section form left-side" style={{ paddingTop: 0 }}>
					<div>
						<p>{form.text}</p>
					</div>

					{this.props.user.id == form.creator ?
						<div style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
							<DataList
								renderRow={this.renderFormInstance}
								data={form.formInstances}
								title={this.props.translate('recipient')}
							/>
						</div>
						: null}

					<BlockWrapper cols="1">
						{this.props.user.id == form.creator ?
							<Block>
								<BlockContent>
									<h2 style={{ marginBottom: '1rem' }}>{translate('preview')}</h2>

									<PreviewFormTemplate fields={formTemplate.fields} />
								</BlockContent>
							</Block>
							:
							<Block>
								<BlockContent>
									{Moment().isAfter(form.dueDate) ?
										<div>
											<h2>{this.props.translate('your-answers')}</h2>

											<DisplayFormResults
												fields={formTemplate.fields}
											/>
										</div>
										:
										<div>
											<h2>{this.props.translate('answers')}</h2>

											<FormInstance
												form={'cm-' + formTemplate.id}
												fields={this.props.instance.formTemplate.fields}
												instance={this.props.instance}
												onAbort={this.onAbort}
											/>
										</div>
									}
								</BlockContent>
							</Block>
						}
					</BlockWrapper>
				</div>

				<div className="single-actions right-side">
					<div className="action-section">
						<h3>Information</h3>

						{this.props.user.id == form.creator ?
							<div>
								{turnedIn} / {form.formInstances.length} {this.props.translate('turned-in')}
							</div>
							:
							<div>
								{turnedIn > 0 ? this.props.translate('turned-in') : this.props.translate('not-turned-in')}
							</div>
						}
					</div>

					{form.dueDate != null ?
						<div className="action-section">
							<h3>{this.props.translate('last-answer-day')}</h3>
							{new DateTime(form.dueDate).getLongDate()}
						</div>
						: null}

					<div className="action-section">
						{this.props.user.id != form.creator && Moment().isBefore(form.dueDate) && turnedIn < 1 ?
							<span></span>
							: null}
					</div>

					{this.props.user.id == form.creator ?
						<div className="action-section">
							<h3>{translate('tools')}</h3>

							<Link to="#" onClick={() => this.toggleModal('edit')}>
								<div className="button button-target">
									{translate('Edit')}
								</div>
							</Link>
						</div>
						: null}
				</div>

				<div className="clearfix" />
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		formTemplate: state.Templates.formTemplate,
		form: state.Templates.form,
		user: state.user.currentUser,
		instance: state.Templates.instance,
	};
}

export default connect(mapStateToProps, {
	setPageTitle,
	getFormTemplate,
	getForm,
	updateFormInstance,
	getFormInstance,
	clearForm,
})(SingleForm);
