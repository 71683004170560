import React, { Component } from 'react';
import { translate, Button } from '@haldor/ui';
import { connect } from 'react-redux';
import '../_onboarding.scss';
import getEnvironment from 'lib/env';

class EmailAdminSent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sentEmailInfo: []
		};
	}

	componentDidMount() { }

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(0)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	render() {
		let product = getEnvironment().id;
		var productName = 'Haldor Education';
		if (product === 'haldor_planning') {
			productName = "Haldor Planning";
		}
		return (
			<div>
				<div>
					<h2 style={{ textAlign: 'left' }}>{this.props.translate('Your e-mail has been sent!')}</h2>
					<p> {this.props.translate('The e-mail was sent to:')} {this.props.emailSentList.join(", ")} </p>
					<p style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '5%' }}>{this.props.translate('You can start using the services once your Office 365 administrator has approved {0}.').replace('{0}', productName)}</p>

					<div style={{ width: '20rem', marginBottom: '2rem' }}>
						<img src="/dist/svg/illustration_steg06.svg" />
					</div>
				</div>

				<div>
					<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('showEmailInfo') }}>
						{this.props.translate('Send again')}
					</Button>
					<Button type="secondary" onClick={() => { this.props.showPage('navigateToAdminConsent') }}>
						{this.props.translate('I am an Office 365-admin')} </Button>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		emailSentList: state.Services.emailSentList
	};
}

export default connect(mapStateToProps, {
})(EmailAdminSent);
