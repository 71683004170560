import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { Spinner } from 'UI';
import { Checkbox, Flex, Button, Icon, TooltipMenu, Skeleton } from '@haldor/ui';
import { Search as SearchInput } from 'UI/Inputs';
import { DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { searchSubjects } from 'actions/subjects';


class TeacherRoleForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedSubjects: []
		};
	}

	submit = (values) => {
		this.props.onTeacherRoleSubmit(this.state.selectedSubjects)
		this.setState({ selectedSubjects: [] });
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ marginTop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ marginTop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	onSearch = (value) => {
		this.props.searchSubjects(value.query);
		this.setState({ selectedSubjects: [] });
	}

	selectSubject = (subject) => {
		if (this.state.selectedSubjects.findIndex(ss => ss.is == subject.id) > -1) {
			this.setState({ selectedSubjects: this.state.selectedSubjects.filter(t => t.id != subject.id) });
		} else {
			this.setState({ selectedSubjects: [...this.state.selectedSubjects, subject] });
		}
	}

	renderSubject = (subject, index) => {
		let subjectIndex = -1;

		if (this.props.previousTeacherRoles != null && this.props.previousTeacherRoles != '') {
			subjectIndex = this.props.previousTeacherRoles.findIndex(r => {
				return r.subjectCode == subject.subjectCode;
			});
		}

		if (subjectIndex < 0) {
			return (
				<RowItem key={'row-id-' + index}>
					<RowCell title={this.props.translate('name')}>
						<Checkbox
							value={this.state.selectedSubjects.find(t => t.id == subject.id) != null}
							onChange={() => this.selectSubject(subject)}
							label={subject.title}
						//disabled={this.state.selectedCourses.find(t => t.userId == user.userId) != null}
						/>
					</RowCell>
					<RowCell title={this.props.translate('school')}>
						{this.props.translate(subject.typeOfSchooling)}
					</RowCell>
					<RowCell title={this.props.translate('subject-code')}>
						{subject.subjectCode}
					</RowCell>
				</RowItem>
			);
		}
	}


	render() {
		const { handleSubmit, submitting, valid, translate } = this.props;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row" style={{ marginTop: 0 }}>
						<label style={{ marginTop: 0 }}>
							{this.props.translate('search-for-courses')}
						</label>

						<SearchInput
							placeholder={this.props.translate('search')}
							onSubmit={this.onSearch}
							autofocus
							lengthLimit={3}
						/>
					</div>

					<div className="form-row" style={{ marginTop: '2rem' }}>
						<DataList
							title={translate('subjects')}
							data={this.props.subjects}
							renderRow={this.renderSubject}
							emptyData={() => (
								<div className="color--meta text--center weight--bold">
									{translate('no-results')}
								</div>
							)}
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit" style={{ marginTop: '2em' }}>
						<Button
							type="save"
							disabled={submitting || !valid}
						>
							{this.props.translate('save')}
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		subjects: state.Subjects.search
	};
}

export default connect(mapStateToProps, {
	searchSubjects
})(reduxForm({
	form: 'AddTeacherRole',
	destroyOnUnmount: true,
})(TeacherRoleForm));