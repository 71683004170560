import { createConversation, getConversation, resetConversation, submitMessage } from 'actions/conversation';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import MessageForm from '../Form/MessageForm';
import ConversationHeader from './ConversationHeader';
import ConversationMessages from './ConversationMessages';
import { setPageTitle } from 'actions/header';
import { getConversationParticipants } from '../Helpers';
import User from '_class/User';

class ConversationPage extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	/* Lifecycle methods */
	UNSAFE_componentWillMount = () => {
		const { id } = this.props.match.params;

		if (id != 'new') {
			this.props.getConversation(id)
				.then(() => {
					this.setTitle();
					this.setState({ loading: false });
				});
		} else {
			if (this.props.staged == null) {
				this.props.history.replace(`/conversations/`);
			}

			this.setTitle();
		}

		window.addEventListener('resize', this.onResize);
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { id } = nextProps.match.params;

		if (id != this.props.match.params.id && this.state.loading == false) {
			this.setState({ loading: true });

			this.props.getConversation(id)
				.then(() => {
					this.setTitle();
					this.setState({ loading: false });
				});
		}
	}

	setTitle = () => {
		const { user } = this.props;
		const { id } = this.props.match.params;
		let members = null;

		if (id != 'new') {
			members = getConversationParticipants(this.props.conversation, user);
		} else {
			members = getConversationParticipants(this.props.staged, user);
		}

		if (members == null) {
			return null;
		}

		let title = '';
		members.forEach((member, index) => {
			if (member != null) {
				if (index > 0) {
					title += ', ';
				}

				if (member.membershipType == 'SECTION_OWNERS') {
					title += member.title + " (" + this.props.translate('Staff') + ")";
					return true;
				}

				if (member.membershipType == 'GUARDIAN') {
					if (member.userId || (member?.linkGuardianStudents && member.linkGuardianStudents.length > 0 && member.studentId)) {
						if (member.userId) {
							member.lastName = (member.lastName != null ? member.lastName : '') + ' (' + this.props.translate('Guardian') + ')';
						}
						else {
							const linkGuardianStudent = member.linkGuardianStudents.find(link => link.studentId === member.studentId);
							if (linkGuardianStudent) {
								member.lastName = (member.lastName != null ? member.lastName : '') + ' (' + this.props.translate('Guardian') + ')';
							}
							else {
								member.lastName = ' (' + this.props.translate('inactive') + ' ' + this.props.translate('guardian') + ')';
							}
						}
					} else {
						member.lastName = ' (' + this.props.translate('inactive') + ' ' + this.props.translate('guardian') + ')';
					}
				}

				title += member?.firstName === undefined ? member.lastName : member.firstName + ' ' + member.lastName;
			}
		});

		this.props.setPageTitle(title);
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.onResize);
		this.props.resetConversation();
	}

	componentDidMount = () => {
		this.onResize();
	}

	/* Events */
	submitMessage = (values) => {
		if (this.props.conversation != null) {
			let lastMessage = this.props.conversation.messages[this.props.conversation.messages.length - 1];
			if (lastMessage == null) {
				values.parentId = null;
			} else {
				values.parentId = lastMessage.id;
			}
		} else {
			values.parentId = null;
		}

		return new Promise(resolve => {
			let conversation = this.props.conversation;

			if (this.props.match.params.id == 'new') {
				conversation = this.props.staged;
			}

			let user = new User(this.props.user);

			values.creator = user.getUserId();

			if (user.isTeacher() || user.isMentor()) {
				let owner_section = conversation.memberships.find(m => m.membershipType == 'SECTION_OWNERS');

				if (owner_section != null) {
					values.senderId = owner_section.section.id;
					values.senderType = 'SECTION';
				} else {
					values.senderId = values.creator;
					values.senderType = 'USER';
				}
			} else {
				values.senderId = values.creator;
				values.senderType = 'USER';
			}

			if (this.props.match.params.id == 'new') {
				// Send message with a conversation object
				let conversation = this.props.staged;
				conversation.messages = [values];

				delete values.conversationID;

				this.setState({ loading: true });
				this.props.createConversation(conversation)
					.then(() => {
						this.props.history.replace(`/conversations/${this.props.new}`);
						this.props.getConversation(this.props.new).then(() => {
							this.setTitle();
							this.setState({ loading: false });
							resolve(1);
						});
					})
			} else {
				this.props.submitMessage(values).then(() => {
					this.setState({});
					resolve(1);
				});
			}
		});
	}

	onResize = () => {
		const messages = document.querySelector('.conversation--wrapper');

		if (messages != null) {
			let height = 0;

			const body = document.querySelector('body');
			const footer = body.querySelector('.site-footer');
			const header = body.querySelector('.teams-header');
			const desktopHeader = body.querySelector('.desktop-header-spacing');
			const deviceMenu = body.querySelector('.footer-menu-bar-container');
			const deviceHeader = body.querySelector('.device-menu');

			height = window.innerHeight;

			if (footer != null) {
				height = height - footer.scrollHeight
			}

			if (header != null) {
				height = height - header.scrollHeight;
			}

			if (desktopHeader != null) {
				height = height - desktopHeader.scrollHeight;
			}

			if (deviceMenu != null) {
				height = height - deviceMenu.scrollHeight - 15;
			}

			if (deviceHeader != null) {
				height = height - deviceHeader.scrollHeight;
			}

			messages.style.height = height - 25 + 'px';
		}
	}

	/* Render methods */
	render() {
		let conversation = this.props.conversation;
		let disabled = false;

		if (this.props.match.params.id == 'new') {
			conversation = this.props.staged;
		}

		if (conversation != null) {
			disabled = conversation.status == 'ARCHIVED' || conversation.disabled === true ? true : false;
		}

		return (
			<div className="overview" style={{ paddingBottom: '4rem' }}>
				<div className="conversation conversation--wrapper">
					<ConversationHeader conversation={conversation} />

					<ConversationMessages conversation={conversation} />

					<MessageForm
						onSubmit={this.submitMessage}
						conversation={conversation}
						disabled={disabled}
					/>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		conversation: state.Conversation.conversation,
		staged: state.Conversation.staged,
		new: state.Conversation.new,
	};
}

export default withRouter(connect(mapStateToProps, {
	getConversation,
	submitMessage,
	createConversation,
	resetConversation,
	setPageTitle,
})(ConversationPage));
