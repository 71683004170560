import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { Spinner } from 'UI';
import { Flex, Button, Icon, TooltipMenu, Skeleton } from '@haldor/ui';
import swal from 'sweetalert2';
import { eLoggingSeverity } from '@microsoft/applicationinsights-web';

class GuardianDetailsForm extends Component {

	constructor(props) {
		super(props);

		let noStudent = false;
		if (this.props.noStudent != null) {
			noStudent = true;
		}
		let active = false;
		if (this.props.currentGuardian.linkGuardianStudents && this.props.currentGuardian.linkGuardianStudents.length > 0
			&& this.props.currentStudent && this.props.currentStudent.userId != "") {
			const linkGuardianStudent = this.props.currentGuardian.linkGuardianStudents.find(link => link.studentId === this.props.currentStudent.userId);
			if (linkGuardianStudent) {
				active = linkGuardianStudent.active;
			}
		}
		if (this.props.currentGuardian.id != "") {
			this.state = {
				active: active,
				currentGuardian: this.props.currentGuardian,
				loading: false,
				noStudent: noStudent,
				editForm: true
			}
		} else {
			this.state = {
				active: active,
				currentGuardian: this.props.currentGuardian,
				loading: false,
				noStudent: noStudent,
				editForm: false
			}
		}
	}

	submit = (values) => {
		if (values.mail != null) {
			values.mail = values.mail.trim();
		}

		const { currentGuardian } = this.props;

		if (currentGuardian.id != "" && currentGuardian.mail != null && currentGuardian.mail != '') {
			if (currentGuardian.mail.toLowerCase() != values.mail.toLowerCase()) {
				swal.fire({
					title: this.props.translate('are-you-sure'),
					text: this.props.translate('changing-email-will-also-affect-userlogin'),
					showCancelButton: true,
					focusConfirm: false,
					cancelButtonText: this.props.translate('No'),
					confirmButtonText: this.props.translate('Yes'),
				}).then(result => {
					if (result.value == null) {
						values.mail = this.props.currentGuardian.mail;
					}

					this.setState({ loading: true });
					this.props.onModalSave(values);
				});

				return true;
			}
		}

		this.setState({ loading: true });
		this.props.onModalSave(values);
	}

	onCancel = () => {
		let currentGuardian = this.state.currentGuardian;
		currentGuardian.active = this.state.active;
		if (this.props.currentGuardian.linkGuardianStudents && this.props.currentGuardian.linkGuardianStudents.length > 0
			&& this.props.currentStudent && this.props.currentStudent.userId != "") {
			const linkGuardianStudent = this.props.currentGuardian.linkGuardianStudents.find(link => link.studentId === this.props.currentStudent.userId);
			if (linkGuardianStudent) {
				currentGuardian.active = linkGuardianStudent.active;
			}
		}
		this.setState({ currentGuardian: currentGuardian });
		this.props.cancelModal();
	}

	changeGuardianPermissionLevel = (status) => {
		let currentGuardian = this.state.currentGuardian;
		currentGuardian.active = status;
		currentGuardian.linkGuardianStudents[0].active = status;
		this.setState({ currentGuardian: currentGuardian });
	}

	onMailChanged = (email) => {
		this.props.onMailChanged(email);
	}

	mailRequired = (value) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(String(value).toLowerCase())) {
			if (typeof (value) !== 'undefined' && value !== '') {
				// Field passes validation, return a undefined error for this field
				// Rule: Not undefined and not an empty string
				return undefined;
			}
		}
		return this.props.translate('field-needs-input');
	}

	render() {
		const { handleSubmit, submitting, valid, goal, initialValues } = this.props;

		return (
			<div className="userdetails edit-form">
				{!this.state.loading ?
					<Form onSubmit={handleSubmit(this.submit)} className="form form-component">
						{this.state.editForm == false ?
							<div className="form-row">
								<label>{this.props.translate('e-mail')}</label>
								<Field
									name="mail"
									type="text"
									component="input"
									onBlur={(event) => this.onMailChanged(event.target.value)}
								/>

								{this.props.errorMessage != null ? <p style={{ color: 'red', marginTop: '1rem', fontWeight: 'bold' }}>{this.props.errorMessage}</p> : null}
							</div>
							: null}

						{!this.props.searchingGuardian ?
							<div>
								<div className="form-row">
									<div style={{ float: "left", width: "50%", paddingRight: "1.75em" }}>
										<label>{this.props.translate('firstname')}</label>
										<Field
											name="firstName"
											type="text"
											component="input"

										/>
									</div>

									<div style={{ float: "left", width: "50%", paddingRight: "0px" }}>
										<label>{this.props.translate('surname')}</label>
										<Field
											name="lastName"
											type="text"
											component="input"

										/>
									</div>
								</div>

								<div className="form-row">
									<label>{this.props.translate('phone')}</label>
									<Field
										name="phone"
										type="text"
										component="input"

									/>
								</div>
							</div>
							:
							<div>
								<div className="form-row">
									<div style={{ float: "left", width: "50%", paddingRight: "1.75em" }}>
										<label>{this.props.translate('firstname')}</label>
										<div>
											<Skeleton style={{ height: '4em' }} />
										</div>
									</div>

									<div style={{ float: "left", width: "50%", paddingRight: "0px" }}>
										<label>{this.props.translate('surname')}</label>
										<div>
											<Skeleton style={{ height: '4em' }} />
										</div>
									</div>
								</div>

								<div className="form-row">
									<label>{this.props.translate('phone')}</label>
									<div>
										<Skeleton style={{ height: '4em' }} />
									</div>
								</div>
							</div>
						}

						{this.state.editForm == true ?
							<div className="form-row">
								<label>{this.props.translate('e-mail')}</label>
								<Field
									name="mail"
									type="text"
									component="input"
									disabled={this.state.currentGuardian.synced}
								/>
							</div>
							: null}

						{!this.props.searchingGuardian ?
							<div>
								<div className="form-row">
									<label>{this.props.translate('street-address')}</label>
									<Field
										name="street"
										type="text"
										component="input"
									/>
								</div>

								<div className="form-row">
									<div style={{ float: "left", width: "35%", paddingRight: "1.75em" }}>
										<label>{this.props.translate('zip-code')}</label>
										<Field
											name="postal"
											type="text"
											component="input"
										/>
									</div>

									<div style={{ float: "left", width: "65%" }}>
										<label>{this.props.translate('address')}</label>
										<Field
											name="city"
											type="text"
											component="input"
										/>
									</div>

									<div className="clearfix"></div>
								</div>
							</div>
							:
							<div>
								<div className="form-row">
									<label>{this.props.translate('street-address')}</label>
									<div>
										<Skeleton style={{ height: '4em' }} />
									</div>
								</div>

								<div className="form-row">
									<div style={{ float: "left", width: "35%", paddingRight: "1.75em" }}>
										<label>{this.props.translate('zip-code')}</label>
										<div style={{ height: '3em' }}>
											<Skeleton style={{ height: '4em' }} />
										</div>
									</div>

									<div style={{ float: "left", width: "65%" }}>
										<label>{this.props.translate('address')}</label>
										<div>
											<Skeleton style={{ height: '4em' }} />
										</div>
									</div>

									<div className="clearfix"></div>
								</div>
							</div>
						}

						<div className="form-row">
							<div className="select">
								<label>{this.props.translate('select-language')}</label>

								<Field component="select" name="lcid">
									<option value="sv-SE">{this.props.translate('swedish')}</option>
									<option value="en-US">{this.props.translate('english')}</option>
									<option value="nb-NO">{this.props.translate('norwegian')}</option>
									<option value="fi-FI">{this.props.translate('finish')}</option>
									<option value="da-DK">{this.props.translate('danish')}</option>
									<option value="uk">{this.props.translate('ukrainian')}</option>
								</Field>
							</div>
						</div>

						{this.state.editForm == true && !this.state.noStudent ?
							<div className="form-row">
								<label>{this.props.translate('permissions-as-guardian')}</label>

								<Flex>
									<label style={{ marginTop: "0px", width: "80%" }}>
										{this.state.currentGuardian.firstName + " " + (this.state.currentGuardian.lastName != null ? this.state.currentGuardian.lastName : null)}
									</label>

									<TooltipMenu
										trigger={this.state.currentGuardian.linkGuardianStudents[0].active ?
											<div>{this.props.translate('active')}<Icon name="Chevron" /></div>
											: <div>{this.props.translate('inactive')}<Icon name="Chevron" /></div>}
										id="studentPermissionsTooltip"
									>
										<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.changeGuardianPermissionLevel(!initialValues.linkGuardianStudents[0].active) }}>
											{this.state.currentGuardian.linkGuardianStudents[0].active ? this.props.translate('inactive') : this.props.translate('active')}
										</TooltipMenu.Item>
									</TooltipMenu>
								</Flex>
							</div>
							: null}

						<div className="form-row submit" style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
							<Button disabled={submitting || !valid}>
								{this.props.translate('save')}
							</Button>

							<div className="align-right">
								<Button type="secondary" onClick={this.onCancel}>
									{this.props.translate('Cancel')}
								</Button>
							</div>

							<div className="clearfix"></div>
						</div>
					</Form>
					:
					<div>
						<Spinner center />
					</div>
				}
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		initialValues: ownProps.currentGuardian,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(reduxForm({
	form: 'GuardianDetailForm',
	destroyOnUnmount: true,
})(GuardianDetailsForm));
