import {
	GET_POSTS,
	SUBMIT_POST,
	UPDATE_POST,
	SET_ACTIVE_POST,
	SET_ACTIVE_POST_DETAILS,
	GET_POSTS_NEXT_PAGE,
	GET_SECTION_POSTS,
	DELETE_POST_FROM_STORE,
	CLEAR_POSTS_FROM_STORE,
	CLEAR_SECTION_POSTS_FROM_STORE,
	GET_POST_READ_LIST,
	CLEAR_POST_READ_LIST,
	SET_PAGINATION_INFO
} from 'actions/posts';

const initialState = {
	posts: [],
	activePost: null,
	createdPost: {},
	sectionPosts: [],
	readList: null,
	collectionInformation: []
}

import { updateObjectInArray } from 'helpers/array';

export default function Posts(state, action) {
	const { type } = action;

	if (typeof (state) === 'undefined') {
		return initialState;
	}

	let combinedItems = state.posts;

	switch (type) {
		case GET_POSTS:
			return { ...state, posts: action.payload };

		case SUBMIT_POST:
			let addPosts = null
			if (state.posts != null) {
				addPosts = updateObjectInArray(state.posts, action.payload);
			} else {
				addPosts = updateObjectInArray([], action.payload);
			}

			return { ...state, createdPost: action.payload, posts: addPosts };

		case SET_ACTIVE_POST:
			if (action.id != null) {
				let selected = state.posts.find(post => {
					return parseInt(post.id) == parseInt(action.id);
				});

				if (typeof (selected) != 'undefined') {
					return { ...state, activePost: selected };
				} else {
					return { ...state, activePost: null };
				}
			}

			return { ...state, activePost: null };

		case SET_ACTIVE_POST_DETAILS:
			if (action.payload != null && !action.error) {
				let listposts = null;

				if (state.posts != null) {
					listposts = updateObjectInArray(state.posts, action.payload);
				} else {
					listposts = updateObjectInArray([], action.payload);
				}

				return { ...state, activePost: action.payload, posts: listposts };
			}

			return { ...state, activePost: initialState.activePost };

		case UPDATE_POST:
			let updatePosts = updateObjectInArray(state.posts, action.payload);

			return { ...state, activePost: action.payload, posts: updatePosts };

		case GET_POSTS_NEXT_PAGE:
			let combined = state.posts;
			action.payload.map(d => {
				const checkExisting = combined.find(cb => {
					return cb.id == d.id;
				});

				if (typeof (checkExisting) == 'undefined') {
					combined.push(d)
				}
			});

			return { ...state, posts: combined };

		case GET_SECTION_POSTS:
			return { ...state, sectionPosts: action.payload };

		case SET_PAGINATION_INFO:
			return { ...state, collectionInformation: action.payload };

		case CLEAR_SECTION_POSTS_FROM_STORE:
			return { ...state, sectionPosts: [] };

		case DELETE_POST_FROM_STORE:
			combinedItems = combinedItems.filter(t => t.id != action.payload);
			return { ...state, posts: combinedItems };

		case GET_POST_READ_LIST:
			return { ...state, readList: action.payload };

		case CLEAR_POSTS_FROM_STORE:
			return { ...state, posts: [] };

		case CLEAR_POST_READ_LIST:
			return { ...state, readList: null };

		default:
			return state;

			break;
	}
}

function updatePostInList(posts, post) {
	let index = posts.findIndex(t => t.id == post.id);
	posts.splice(index, 1);
	posts.splice(index, 0, post);
	return posts;
}
