import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { userAdminCheck, addUser } from 'actions/user';
import { createTenant, isConsentPresent } from 'actions/services';

import {
	loginRequest,
	requirePopupConsent,
	setOnboardingUser,
	setOnboardingExpireTokenIn,
	isTokenValid,
	setOnboardingToken
} from 'lib/msal';

import getEnvironment from 'lib/env';
import { appInsights } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";

// Import routes
import TabConfigure from 'teams/containers/TabConfigure';
import TeamsOnboardingAuth from 'containers/TeamsOnboarding/Auth/Authentication'
import TeamsOnboardingAuthAPI from 'containers/TeamsOnboarding/Auth/ApiConsent'
import TeamsOnboardingAuthClient from 'containers/TeamsOnboarding/Auth/ClientConsent'
import TeamsOnboardingIndex from 'containers/TeamsOnboarding/IndexWrapper';

import { Spinner } from 'UI';
import Error from 'components/Presentation/Error/Error';
import { Button, translate } from '@haldor/ui';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			onboarding: true,
			error: false,
			redirect: null,
			showErrorLogin: false,
			loading: true,
			user: {
				firstName: '',
				lastName: '',
				email: '',
				userRoles: [],
				userId: '',
				tenantId: ''
			},
		}

		window.onPopupDataReceived = this.getAuthenticationResults.bind(this);
	}

	renderRedirect = () => {
		return <Redirect to={this.state.redirect} />
	}

	getAuthenticationResults = async (response) => {
		if (response == "AUTHENTICATION:SUCCESS") {
			this.setState({ onboarding: false });
		}

		if (response == "AUTHENTICATION:FAILED") {
			console.log("AUTHENTICATION FAILED");
		}
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	retrieveUser(context) {
		return new Promise((resolve, reject) => {
			if (context.user.userPrincipalName) {
				var user = this.state.user;
				user.email = context.user.userPrincipalName;
				user.firstName = context.user.userPrincipalName.split('@')[0];

				if (user.firstName.indexOf(".") > -1) {
					var firstName = user.firstName.split('.')[0];
					var lastName = user.firstName.split('.')[1];

					user.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
					user.lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
				}
			}
			if (context.user) {
				user.userId = context.user.id;
			}

			if (context.user.tenant) {
				user.tenantId = context.user.tenant.id;
			}

			if (context.user && context.user.licenseType) {
				user.userRoles = [];
				user.userRoles.push(context.user.licenseType);
			}

			var tenantDomain = null;

			if (context.user && context.user.userPrincipalName != null && context.user.userPrincipalName.indexOf("@") > -1) {
				tenantDomain = context.user.userPrincipalName.substring(context.user.userPrincipalName.indexOf("@") + 1).toLowerCase();
			}

			if (tenantDomain == null && context.user.userPrincipalName != null && context.user.userPrincipalName.indexOf("@") > -1) {
				tenantDomain = context.user.loginHint.substring(context.user.userPrincipalName.indexOf("@") + 1).toLowerCase();
			}
			console.log('domain' + context.sharePointSite.teamSiteDomain);
			var tenant = {
				title: "",
				tenantId: context.user.tenant.id,
				tenantDomain: tenantDomain,
				tenantSPOUrl: "https://" + context.sharePointSite.teamSiteDomain
			}

			this.props.createTenant(tenant, true).then(() => {
				this.props.addUser(this.state.user, true).then(() => {
					this.setState({ loading: false });
					resolve();
				});
			});

			this.setState({ user: user });
		});
	}

	getConsentOnFrontend(skipTeams = false) {
		return new Promise((resolve, reject) => {
			let request = loginRequest;
			request.scopes = [
				...getEnvironment().scopes,
				...request.scopes
			];

			requirePopupConsent(getEnvironment().clientId, '/teams/he/authentication/client', request.scopes).then((response) => {
				setOnboardingToken(response.accessToken);
				this.setState({ onboarding: false });
				resolve(response);
			}).catch(error => {
				console.log("error", error);
				reject(error);
			})
		})
	}

	Login = async () => {
		return new Promise((resolve, reject) => {
			this.setState({ loading: true });

			if (this.inIframe()) {
				if (!isTokenValid()) {
					microsoftTeams.app.initialize();
					microsoftTeams.authentication.authenticate({
						url: '/teams/he/authentication?haldor_login=true',
					})
						.then((result) => {
							console.log('success popup result', result);
							let data = localStorage.getItem(result);
							localStorage.removeItem(result);
							let tokenResult = JSON.parse(data);
							setOnboardingExpireTokenIn(result.expires_in);
							setOnboardingToken(result.access_token);
							this.setState({ onboarding: false });
							resolve(result);
						})
						.catch((error) => {
							console.log('fail popup error LandingPage', error)
							this.setState({ showErrorLogin: true, loading: false });
							reject(error);
						});
				} else {
					this.setState({ onboarding: false });
				}
			} else {
				requirePopupConsent(getEnvironment().apiClientId, '/teams/he/authentication', ['User.Read']).then((response) => {
					this.getConsentOnFrontend().then(async (response) => {
						await this.checkIfTenantHasAdminConsent();
						resolve(response);
					}).catch(error => {
						if (error.message.indexOf('AADSTS650052') > -1 || error.message.indexOf('AADSTS650051') > -1) {
							console.log("NEED API CONSENT");
							localStorage.clear();
							this.Login();
						}

						if (error.message.indexOf('popup_window_error') > -1) {
							this.setState({ showErrorLogin: true, loading: false });
						}

						reject(error);
					});
				}).catch(error => {
					if (error.message.indexOf('popup_window_error') > -1) {
						this.setState({ showErrorLogin: true, loading: false });
					}

					console.log(error);
				});
			}
		});
	}

	async validateAdminConsentResponse() {
		appInsights.trackTrace({ message: "Onboarding | Consent Check finished" }, {
			isConsentPresent: this.props.isAppConsented
		});

		if (!this.props.isAppConsented?.data) {
			appInsights.trackTrace({ message: "Onboarding | Consent Check failed and onboarding started" }, {
				isConsentPresent: this.props.isAppConsented
			});

			return false;
		} else {
			localStorage.setItem("onboarding.valid", "1");
			localStorage.removeItem('onboarding.token');
			localStorage.removeItem('onboarding.username');
			localStorage.removeItem('onboarding.expireTokenIn');
			localStorage.removeItem('onboarding:init');

			appInsights.trackTrace({ message: "Onboarding | Consent is Valid" }, {
				isConsentPresent: this.props.isAppConsented
			});

			if (this.inIframe()) {
				microsoftTeams.pages.getConfig().then(settings => {
					if (settings.contentUrl != null) {
						var endpoint = settings.contentUrl.replace(window.location.origin, "");

						switch (endpoint) {
							case "/landingpage":
								this.setState({ redirect: "/", onboarding: true });
								break;
							case "/haldor-tab/landingpage":
								this.setState({ redirect: "/haldor-tab", onboarding: true });
								this.setDefaultUrlOnTeamsSettings("/haldor-tab/");
								break;
							case "/attendance-tab/landingpage":
								this.setState({ redirect: "/attendance-tab/", onboarding: true });
								this.setDefaultUrlOnTeamsSettings("/attendance-tab/");
								break;
							case "/assessments-tab/landingpage":
								this.setState({ redirect: "/assessments-tab/", onboarding: true });
								this.setDefaultUrlOnTeamsSettings("/assessments-tab/");
								break;
							case "/planning-tab/landingpage":
								this.setState({ redirect: "/planning-tab/", onboarding: true });
								this.setDefaultUrlOnTeamsSettings("/planning-tab/");
								break;
							case "/assignments-tab/landingpage":
								this.setState({ redirect: "/assignments-tab/", onboarding: true });
								this.setDefaultUrlOnTeamsSettings("/assignments-tab/");
								break;
							default:
								this.setState({ redirect: "/", onboarding: false });
								break;
						}
					} else {
						this.setState({ onboarding: false });
					}

					return true;
				});
				return true;
			} else {
				this.setState({ redirect: "/", onboarding: false });
				return true;
			}
		}
	}

	/**
	 * This method will be used to check whether tenant has admin consent. Should work for teams or webbrowser
	 */
	async checkIfTenantHasAdminConsent() {
		var tenantId = null;

		if (this.inIframe()) {
			const response = await new Promise((resolve) => {
				microsoftTeams.app.getContext().then(async context => {
					tenantId = context.user.tenant.id;
					appInsights.trackTrace({ message: "Onboarding | Teams: Starting to validate if adminconsent exist" }, {
						tenantId: tenantId
					});

					try {
						await this.props.isConsentPresent(tenantId);
					} catch (error) { }

					const response = await this.validateAdminConsentResponse();
					console.log('response checkIfTenantHasAdminConsent', response)
					resolve(response);
				});
			});

			return response;
		}

		await this.props.userAdminCheck();
		tenantId = this.props.onboardingUser.tenantId;

		appInsights.trackTrace({ message: "Onboarding | Browser: Starting to validate if adminconsent exist" }, {
			tenantId: tenantId
		});

		await this.props.isConsentPresent(tenantId);
		const response = await this.validateAdminConsentResponse();
		return response;
	}

	async componentDidMount() {
		if (window.location.href.indexOf('/teams/he/authentication') > -1 || window.location.href.indexOf('/tab-configure') > -1) {
			this.setState({ onboarding: false });
		} else {
			if (this.inIframe()) {
				microsoftTeams.app.getContext().then(async context => {
					setOnboardingUser(context.user.loginHint);

					let isConsented = await this.checkIfTenantHasAdminConsent();

					console.log('### is consented', isConsented)
					appInsights.trackTrace({ message: "Teams checkIf TenantHasAdminConsent result" }, {
						IsConsented: isConsented
					});

					/** If requestResponseCode is not set or not 200 then show error */
					if (this.props.isAppConsented.requestResponseCode != 200)
						this.setState({ error: true });

					if (!isConsented) {
						appInsights.trackTrace({ message: "Teams checkIf TenantHasAdminConsent not consented" }, {
							IsConsented: isConsented
						});

						this.retrieveUser(context);
					} else {
						appInsights.trackTrace({ message: "Teams Consent is present" }, {
							IsConsented: isConsented
						});
					}
				});
			} else {
				this.Login();
			}
		}
	}

	setDefaultUrlOnTeamsSettings(url) {
		if (microsoftTeams != null) {
			microsoftTeams.app.getContext().then((context) => {
				if (context.page.id != "Haldor") {
					microsoftTeams.pages.config.setConfig({
						contentUrl: window.location.origin + url,
					});
					microsoftTeams.pages.config.setValidityState(true);
				}
			});
		}
	}

	render() {
		if (this.state.redirect) {
			return this.renderRedirect();
		}

		if (this.state.error) {
			return (
				<div className="login-content">
					<div style={{ backgroundColor: '#FFF', boxShadow: '0 5px 15px 0 rgb(0 0 0 / 5%)', borderRadius: '3px', padding: '1.4rem 3rem', textAlign: 'center' }}>
						<div style={{ minHeight: '15rem', width: '15rem', marginBottom: '2rem', display: 'inline-block' }}>
							<img src="/dist/svg/illustration_fel-404.svg" />
						</div>

						<h2>{this.props.translate('Ops, something went wrong!')}!</h2>

						<p style={{ marginTop: '1.55rem', marginBottom: '1.55rem' }}>
							{this.props.translate('The page could not be loaded, please try again.')}
						</p>

						<a href={window.location.href}>
							<Button>
								{this.props.translate('Reload page')}
							</Button>
						</a>
					</div>

				</div>
			);
		}

		if (this.state.onboarding) {
			return <div className="login-content">
				<Error />

				{this.state.loading ?
					<Spinner center />
					:
					<div style={{ backgroundColor: '#FFF', boxShadow: '0 5px 15px 0 rgb(0 0 0 / 5%)', borderRadius: '3px', padding: '1.4rem 3rem', marginBottom: '3rem', textAlign: 'left' }}>
						<div>
							<img style={{ minHeight: '8rem', marginTop: '-6rem' }} src="/dist/svg/haldor_logo_rgb.svg" />
						</div>

						<div style={{ marginTop: '-7rem' }}>
							{this.props.translate('In order to start using Haldor you need to login with your Microsoft account. You will be asked to login twice. This gives us the permission to launch Haldor so you can get started.')}
							<Button style={{ display: 'block', marginTop: '1.5rem' }} type="secondary" onClick={this.Login}>
								{this.props.translate('log-in')}
							</Button>
						</div>
					</div>
				}
			</div>
		}

		return (
			<div>
				<Error />

				<Switch>
					<Route path="/teams/he" component={TeamsOnboardingIndex} exact />
					<Route path="/teams/he/tab-configure" component={TabConfigure} exact />
					<Route path="/teams/he/authentication" component={TeamsOnboardingAuth} exact />
					<Route path="/teams/he/authentication/api" component={TeamsOnboardingAuthAPI} exact />
					<Route path="/teams/he/authentication/client" component={TeamsOnboardingAuthClient} exact />
				</Switch>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		onboardingUser: state.user.onboardingUser,
		tenantUser: state.user.tenantUser,
		isAppConsented: state.Services.isAppConsented
	}
}

export default connect(mapStateToProps, {
	userAdminCheck,
	isConsentPresent,
	createTenant,
	addUser,
})(App);