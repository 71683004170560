import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Redirect extends Component {
	componentDidMount() {
		this.props.history.push(this.props.to);
	}

	render() {
		return (
			<div></div>
		);
	}
}

export default withRouter(Redirect);