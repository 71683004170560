import React from 'react';
import AssignmentTaskStatusCanDevelop from "UI/IconsSrc/assignment_task_status_can_develop";
import AssignmentTaskStatusNotComplete from "UI/IconsSrc/assignment_task_status_not_complete";
import AssignmentTaskStatusNotStarted from "UI/IconsSrc/assignment_task_status_not_started";
import AssignmentTaskStatusStarted from "UI/IconsSrc/assignment_task_status_started";
import AssignmentTaskStatusSubmitted from "UI/IconsSrc/assignment_task_status_submitted";


const assignmentTaskStatusIcons = [
	{ id: 'ASSIGNMENT_TASK_NOT_STARTED', icon: <AssignmentTaskStatusNotStarted /> },
	{ id: 'ASSIGNMENT_TASK_STARTED', icon: <AssignmentTaskStatusStarted /> },
	{ id: 'ASSIGNMENT_TASK_SUBMITTED', icon: <AssignmentTaskStatusSubmitted /> },
	{ id: 'ASSIGNMENT_TASK_NOT_COMPLETE', icon: <AssignmentTaskStatusNotComplete /> },
	{ id: 'ASSIGNMENT_TASK_CAN_DEVELOP', icon: <AssignmentTaskStatusCanDevelop /> },
];

export default assignmentTaskStatusIcons;