
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setActivePlan, clearPlanSection } from 'actions/plans';
import { clearAssessments } from 'actions/assessments';

import {
	COMPULSORY_SCHOOL,
	PRESCHOOL,
	UPPER_SECONDARY_EDUCATION,
	ADULT_EDUCATION,
} from 'constants/schoolTypes';

import CompulsoryPlan from './Types/CompulsoryPlan';
import PreschoolPlan from './Types/PreschoolPlan';

class PlanningWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	componentWillUnmount = () => {
		this.props.setActivePlan();
		this.props.clearPlanSection();
		this.props.clearAssessments();
	}

	render() {
		if (this.props.currentUser == null) {
			return;
		} else {
			switch (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type) {
				case PRESCHOOL:
					return <PreschoolPlan params={this.props.match.params} />

				case COMPULSORY_SCHOOL:
					return <CompulsoryPlan params={this.props.match.params} />

				case UPPER_SECONDARY_EDUCATION:
					return <CompulsoryPlan params={this.props.match.params} />

				case ADULT_EDUCATION:
					return <CompulsoryPlan params={this.props.match.params} />

				default:
					return <div></div>
			}
		}
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser
	};
}

export default connect(mapStateToProps, {
	setActivePlan,
	clearPlanSection,
	clearAssessments
})(PlanningWrapper);
