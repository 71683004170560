import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { setActiveSchool, } from 'actions/user';
import { reloadSchoolActions, reloadSchoolProps } from 'components/SchoolSwitch/SchoolSwitch';

import { translate } from '@haldor/ui';
import swal from 'sweetalert2';

class ConditionReceiver extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount = async () => {
		var url = this.getParameterByName('url', this.props.location.search);
		var schoolId = this.getParameterByName('schoolId', this.props.location.search);
		if (url != null && schoolId != null) {
			this.verifySchool(schoolId, url);
			return;
		}

		// fallback
		this.props.history.replace(url != null ? url : "");
	}

	verifySchool = (schoolId, url) => {
		if (schoolId != this.props.activeSchool) {
			swal.fire({
				title: this.props.translate('Switch active school?'),
				text: this.props.translate("This information can't be viewed with the current school. Do you want to switch active school?"),
				showCancelButton: true,
				focusConfirm: false,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then((result) => {
				if (result.value != null) {
					this.switchSchool(schoolId);
					this.props.history.replace(url);
				} else {
					this.props.history.goBack();
				}
			});
		} else {
			this.props.history.replace(url);
		}
	}

	switchSchool = (schoolId) => {
		localStorage.setItem('hal.school' + this.props.currentUser.id, schoolId);
		this.props.setActiveSchool(schoolId);
		reloadSchoolProps(this.props);
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	render() {
		return <div></div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		activeSchool: state.user.activeSchool,
	}
}

export default withRouter(connect(mapStateToProps, {
	setActiveSchool,
	...reloadSchoolActions
})(ConditionReceiver));