import { addError } from 'actions/index';
import { searchSectionByTitle } from 'actions/search';
import { debounce } from 'helpers/content';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import Select from 'react-select/async';
import { QuillEditor } from 'UI/Inputs';
import { Checkbox, Button } from '@haldor/ui';
import api from 'lib/api';

class MentorSectionForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {
				id: 0,
				title: '',
				description: '',
				graphId: null,
				HideClassroomLink: false,
				teamsUrl: null,
				type: 'MENTOR_GROUP'
			},
			sectionInput: null,
			isSending: false,
			disableSectionInput: false,
		};
	}

	UNSAFE_componentWillMount = () => {
		const {
			editView,
			teamsContext
		} = this.props;

		if (editView) {
			this.loadEditView();
		}

		if (teamsContext != null) {
			this.setSectionFieldToTeam();
		}
	}

	setSectionFieldToTeam() {
		const sectionInput = {
			label: this.props.teamsContext.team.displayName,
			value: this.props.teamsContext.team.groupId,
		};

		this.setState({ sectionInput, disableSectionInput: true });
	}

	loadEditView = () => {
		const values = this.state.values;
		values.title = this.props.section.title;
		values.description = this.props.section.description;
		values.HideClassroomLink = this.props.section.hideClassroomLink;
		values.type = this.props.section.type;

		this.setState({ values });
	}

	onInputChange = (input, event) => {
		const values = this.state.values;
		values[input] = event.target.value;
		this.setState({ values });
	}

	onSubmit = (event) => {
		const values = this.state.values;
		if (this.props.editView == false) {
			values.title = this.state.sectionInput.label;
			values.graphId = this.state.sectionInput.value;
		} else {
			values.id = this.props.section.id;
		}

		this.setState({ values }, () => {
			const newError = this.validateForm();

			if (!newError) {
				const that = this;
				this.setState({ isSending: true }, () => {
					if (this.props.editView) {
						api.put('sections', values).then(() => {
							this.props.addError(this.props.translate('changes-saved'), 'info');
							this.props.onAbort(true);
						});
					} else {
						api.post('sections', values).then((response) => {
							this.props.history.push(`/groups/${response.data}`);
						});
					}
				});
			}
		});
	}

	validateForm = () => {
		const values = this.state.values;
		let newError = false;

		if (values.title.length === 0) {
			newError = true;
			this.props.addError(this.props.translate('enter-a-title-error'), 'notice');
		}

		if (this.props.editView == false) {
			if (values.graphId == null) {
				newError = true;
				this.props.addError(this.props.translate('pick-a-section'), 'notice');
			}
		}

		return newError;
	}

	onDescriptionChange = (text) => {
		const values = this.state.values;
		values.description = text;
		this.setState({ values });
	}

	onClickSave = (event) => {
		event.preventDefault();

		if (!this.state.isSending) {
			this.onSubmit();
		}
	}

	onSearchSection = (title, callback) => {
		if (!title) {
			callback(null, []);
		} else {
			var searchValue = title.trim();
			searchValue = searchValue.replace(/[^A-Za-z0-9]/g, "");
			searchValue = encodeURIComponent(searchValue);

			if (searchValue != null && searchValue != "") {
				if (searchValue.length < 3) {
					callback([]);
					return false;
				}

				this.props.searchSectionByTitle(searchValue, true, (data) => {
					let options = [];

					data.forEach((section) => {
						options.push({
							value: section.graphId,
							label: section.title
						});
					});

					callback(options);
				});
			} else {
				callback([]);
			}
		}
	}

	onSectionChange = (option) => {
		this.setState({ sectionInput: option });
	}

	render() {
		return (
			<div className={this.props.teamsContext != null ? 'form-container no-section-found-create-section' : 'form-container form-create-section'}>
				{this.props.teamsContext != null ?
					<div>{this.props.translate('form-no-section-found')}</div>
					: null}

				{this.state.isSending ?
					<div className="is_sending">
						<p>
							<span className="loading-spinner" />
							<br />
							{this.props.editView ? this.props.translate('creation-phrase-1') : this.props.translate('creating-section')}...
						</p>
					</div>
					: null}

				<form className="form form-component">
					{this.props.editView == false ?
						<div className="form-row">
							<label>{this.props.translate('section')}</label>
							<div className="select">
								<Select
									isSearchable
									isDisabled={this.state.disableSectionInput}
									isMulti={false}
									name="section"
									value={this.state.sectionInput}
									loadOptions={debounce(this.onSearchSection, 700)}
									onChange={this.onSectionChange}
									placeholder={this.props.translate('search-for-a-group')}
									className="simple-select"
									ignoreAccents={false}
									ignoreCase
									loadingMessage={() => this.props.translate('loading') + '...'}
									noOptionsMessage={() => this.props.translate('no-results')}
									onKeyDown={(e) => {
										if (e.code == 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</div>
						</div>
						: null}

					<div className="form-row">
						<QuillEditor
							placeholder={this.props.translate('describe-the-group')}
							label={this.props.translate('description')}
							onChange={this.onDescriptionChange}
							text={this.state.values.description}
						/>
					</div>

					<div className="form-row">
						<label></label>
						<Checkbox
							value={this.state.values.HideClassroomLink}
							onChange={(checked) => {
								const values = this.state.values;
								values.HideClassroomLink = checked;
								this.setState({ values });
							}
							}
							id="hideclassroom-checkbox"
							label={this.props.translate('hide-classroom-url')}
						/>
					</div>


					<div className="form-divider" />
					<div className="form-row spacing submit">
						<Button onClick={this.onClickSave} type="save">
							{this.props.translate('publish')}
						</Button>

						<div className="align-right">
							<Button onClick={(event) => { event.preventDefault(); this.props.onAbort(false); }} type="secondary">
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
});

const mapDispatchToProps = {
	searchSectionByTitle,
	addError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MentorSectionForm));