import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';
import {
	createMeetingTimeBlocks, updateMeetingTimeBlock
} from 'actions/timeblocks';
import { Button } from '@haldor/ui';

class CreateTimeBlocks extends Component {

	constructor(props) {
		super(props);

		this.state = {
			dates: null,
			error: null,
		};
	}

	componentDidMount = async () => { }

	onSubmit = async () => {
		const { translate } = this.props;

		return new Promise(async (resolve) => {
			let obj = {
				meetingId: this.props.reference.id,
				startTime: moment.utc(this.state.dates.start).local().format(),
				endTime: moment.utc(this.state.dates.end).local().format()
			}

			if (this.props.initialValues == null) {
				await this.props.createMeetingTimeBlocks(this.props.reference.id, [obj]);
				this.props.onClose();
			} else {
				obj.id = this.props.initialValues.id;

				await this.props.updateMeetingTimeBlock(this.props.reference.id, obj);

				this.props.onClose();
			}
		});
	}

	onDateChange = (dates) => {
		this.setState({ error: null });

		var filteredTimeBlocks = this.props.meetingTimeBlocks.filter((item) => { return this.props.initialValues == null || item.id != this.props.initialValues.id });

		filteredTimeBlocks.map(item => {
			var startDateTime = moment.utc(item.startTime).local();
			var endDateTime = moment.utc(item.endTime).local();
			var currentDateTimeStart = moment.utc(dates.start).local();
			var currentDateTimeEnd = moment.utc(dates.end).local();

			if (currentDateTimeStart.isBetween(startDateTime, endDateTime)
				|| currentDateTimeEnd.isBetween(startDateTime, endDateTime)
				|| startDateTime.isBetween(currentDateTimeStart, currentDateTimeEnd)) {
				this.setState({ error: 'There is already a meeting occasion during this time.' })
			}
		})

		this.setState({ dates });
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.onSubmit)} className="form form-component">
					<div className="form-row">
						<DatePickerFromTo
							timePicker
							type="timeblocks"
							onChange={this.onDateChange}
							values={this.props.initialValues}
							hideEndDate
						/>

						<div className="clearfix"></div>
					</div>


					<div className="form-row" style={{ marginTop: '.55rem', color: 'red' }}>
						{this.props.translate(this.state.error)}
					</div>


					<div className="form-divider" />

					<div className="form-row submit spacing">
						<Button style={{ margin: 0 }} disabled={submitting || !valid || this.state.error} type="save">
							{this.props.initialValues == null ? this.props.translate('Create') : this.props.translate('save')}
						</Button>

						<div className="clearfix" />
					</div>
				</form>

			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		meetingTimeBlocks: state.TimeBlocks.meetingTimeBlocks,
		initialValues: ownProps.timeblock
	}
}

export default connect(mapStateToProps, {
	createMeetingTimeBlocks,
	updateMeetingTimeBlock
})(reduxForm({
	form: 'CreateTimeBlocks',
	destroyOnUnmount: true,
})(CreateTimeBlocks));
