import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { getDescription } from 'helpers/content';
import { getSMS, updateSMS, deleteSMS } from 'actions/sms';
import { setPageTitle } from 'actions/header';

import { Block, Button, Icon, Table, translate } from '@haldor/ui';
import { Spinner } from 'UI';

import Modal from 'containers/Modals/Modal';
import SMSForm from 'containers/Forms/SMS';
import DateTime from '_class/DateTime';
import PostContent from 'components/Presentation/PostContent';

class SingleSMS extends Component {

	constructor(props) {
		super(props);

		this.state = {
			editForm: false,
			loading: true,
			removing: false,
		}
	}

	componentDidMount = async () => {
		this.props.setPageTitle('Sms');
		await this.props.getSMS(this.props.match.params.id);
		this.setState({ loading: false });
	}

	onUpdate = (values) => {
		return new Promise((resolve, reject) => {
			this.props.updateSMS(values).then(() => {
				resolve(1);
				this.setState({ editForm: false });
			}).catch(() => {
				reject(this.props.translate('Something went wrong, try again'));
			});
		});
	}

	onRemove = async () => {
		await this.setState({ removing: true });

		swal.fire({
			title: this.props.translate('Remove'),
			text: this.props.translate('Are you sure you want to delete this sms?'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(result => {
			if (result.value != null) {
				this.props.deleteSMS(this.props.sms.id).then(() => {
					this.props.history.push('/sms');
				});
			} else {
				this.setState({ removing: false });
			}
		});
	}

	render() {
		if (this.props.sms == null || this.state.loading) {
			return <Spinner center />
		}

		const { sms } = this.props;

		let relationships = sms.relationships;
		if (relationships != null) {
			relationships = [...relationships].filter(relation => relation.referenceType != 'SECTION');
		}

		return (
			<div className="single-task sms">
				{sms.status == 'SMS_DRAFT' ?
					<Modal
						isOpen={this.state.editForm}
						title={this.props.translate('Edit sms')}
						onClose={() => this.setState({ editForm: false })}
					>
						<SMSForm
							initialValues={this.props.sms}
							onSubmit={this.onUpdate}
							onCancel={() => this.setState({ editForm: false })}
						/>
					</Modal>
					: null}

				<div className="single-section left-side">
					<Block>
						<span className="title">
							{this.props.translate('Message')}
						</span>

						{sms.title != null ?
							<div style={{ fontWeight: 500 }}>
								{sms.title}
							</div>
							: null}

						<PostContent>
							{getDescription(sms.message.replace(/\n/g, '<br />'))}
						</PostContent>

						<div style={{ marginTop: '1rem' }}>
							<span className="title">
								{this.props.translate('Created by')}
							</span>

							{sms.createdUser.firstName} {sms.createdUser.lastName}
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className="title">
								{this.props.translate('Status')}
							</span>

							{this.props.translate(sms.status)}
						</div>
					</Block>
				</div>

				<div className="single-actions right-side">
					<h3>{this.props.translate("tools")}</h3>

					{sms.status == 'SMS_DRAFT' ?
						<Button type="secondary" onClick={() => this.setState({ editForm: true })} disabled={this.state.removing}>
							<Icon name="Pen_Small" />
							{this.props.translate('Edit')}
						</Button>
						: null}

					{sms.status == 'SMS_DRAFT' ?
						<div style={{ marginTop: 5 }}>
							<Button type="secondary" disabled={this.state.removing} onClick={this.onRemove}>
								<Icon name="Bin" />
								{this.props.translate('Remove')}

								{this.state.removing ?
									<Spinner />
									: null}
							</Button>
						</div>
						: null}
				</div>

				<div className="clearfix"></div>

				<div style={{ marginTop: '2rem' }} />

				<Block>
					<span className="title">
						{this.props.translate('Recipients')}
					</span>

					<div style={{ margin: '0 -1.7rem', marginTop: '1.5rem' }}>
						{relationships != null && relationships.length > 0 ?
							<Table>
								<thead>
									<tr>
										<th>{this.props.translate('Name')}</th>
										<th>{this.props.translate('Phone number')}</th>
										<th>{this.props.translate('Status')}</th>
										<th>{this.props.translate('Delivered')}</th>
									</tr>
								</thead>

								<tbody>
									{relationships.map(relationship => {
										return <tr key={relationship.id}>
											<td>
												{relationship.user.firstName != null ?
													relationship.user.firstName + ' '
													: ''}

												{relationship.user.lastName != null ?
													relationship.user.lastName
													: ''}
											</td>

											<td>
												{relationship.mobilePhone != null ?
													relationship.mobilePhone
													: this.props.translate('Phone number missing')}
											</td>

											<td>
												{this.props.translate(relationship.status)}
											</td>

											<td>
												{relationship.deliveredDate != null ?
													new DateTime(relationship.deliveredDate).getTimeStampHours()
													: null}
											</td>
										</tr>
									})}
								</tbody>
							</Table>
							: null}
					</div>
				</Block>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		sms: state.SMS.single,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	getSMS,
	updateSMS,
	deleteSMS,
	setPageTitle,
})(SingleSMS);