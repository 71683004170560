import React from 'react';
import { Button, Flex, Haldor__Block, Icon } from '@haldor/ui';

import DisplayResource from './DisplayResource';
import GoalMatrixForm from './GoalMatrixForm';
import SelectCoursesForm from './SelectCoursesForm';
import SelectMatricesForm from './SelectMatricesForm';
import OnlyFeedbackForm from './OnlyFeedbackForm';
import DisplayOnlyFeedback from './DisplayOnlyFeedback';

class AssessmentBlock extends Haldor__Block {

	constructor(props) {
		super(props);

		this.PART_RESOURCE_TYPE = 'Haldor.Blocks.AssessmentBlockPart'

		this.registerResourceType(this.PART_RESOURCE_TYPE, {
			status: 'string',
			referenceId: 'string',
			referenceType: 'string',
			value: 'object',
			// permissions: 'object'
		});

		this.registerResourceType('Haldor.Blocks.AssessmentBlock.ShowModal', {
			visible: 'boolean',
		});

		this.registerResourceType('assessmenttype', {});
	}

	addCoursePart = (event) => {
		event.preventDefault();

		this.addResource('Haldor.Blocks.AssessmentBlock.ShowModal', {
			visible: true,
		});
	}

	addGoalMatrix = (event) => {
		event.preventDefault();
		this.removeFeedBackOnlyResource();

		this.addResource(this.PART_RESOURCE_TYPE, {
			status: 'Started',
			referenceId: '',
			referenceType: 'assessmentGoal',
			value: {
				id: 0,
				visibleToStudent: true,
				visibleToGuardian: true,
				title: '',
				rows: [],
			},
			permissions: [
				{
					referenceType: 'ASSESSMENTBLOCKPART',
					target: 'STUDENT',
					level: 'READ',
				},
				{
					referenceType: 'ASSESSMENTBLOCKPART',
					target: 'GUARDIAN',
					level: 'READ',
				},
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'GUARDIAN',
					level: 'READ'
				},
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'STUDENT',
					level: 'READ'
				}
			],
		});
	}

	addFeedBackOnlyResource = () => {
		this.addResource(this.PART_RESOURCE_TYPE, {
			status: 'Started',
			referenceId: '0',
			referenceType: 'assessmentonlyfeedback',
			assessmentBlockPartRows: [],
			value: null,
			permissions: [
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'GUARDIAN',
					level: 'READ'
				},
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'STUDENT',
					level: 'READ'
				}
			]
		});
	}

	removeFeedBackOnlyResource = () => {
		let foundExisting = this.getResources().find((resource) =>
			resource.referenceType == 'assessmentonlyfeedback'
		);

		if (foundExisting != null) {
			this.removeResource(foundExisting);
			return true;
		}
	}

	removeResourceFromBlock = (resource) => {
		this.removeResource(resource);

		let assessmentBlockPart = this.getResources().find((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE
		});

		if (assessmentBlockPart == null && this.name == "Haldor.Blocks.AssessmentBlock") {
			this.addFeedBackOnlyResource();
		}
	}

	addCourses = (values) => {
		this.removeFeedBackOnlyResource();

		values.forEach((course) => {
			this.addResource(this.PART_RESOURCE_TYPE, {
				status: 'Started',
				referenceId: '',
				referenceType: 'matrix',
				assessmentBlockPartRows: [],
				value: course,
				permissions: [
					{
						referenceType: 'ASSESSMENTFEEDBACK',
						target: 'GUARDIAN',
						level: 'READ'
					},
					{
						referenceType: 'ASSESSMENTFEEDBACK',
						target: 'STUDENT',
						level: 'READ'
					}
				]
			});
		});
	}

	goalMatrixChange = (goalMatrix) => {
		if (goalMatrix.index != null) {
			let foundExisting = this.getResources().find((resource) =>
				resource.index == goalMatrix.index
			);

			if (foundExisting != null) {
				const currentIndex = foundExisting.index;
				this.removeResource(foundExisting);
				this.addResource(this.PART_RESOURCE_TYPE, goalMatrix, currentIndex);
				return true;
			}
		}

		this.addResource(this.PART_RESOURCE_TYPE, goalMatrix);
	}

	courseChange = (course) => {
		if (course == null || course.value == null) {
			return false;
		}

		let foundExisting = this.getResources().find((resource) =>
			resource.value?.id == course.value.id
		);

		if (foundExisting != null) {
			const currentIndex = foundExisting.index;
			this.removeResource(foundExisting);
			this.addResource(this.PART_RESOURCE_TYPE, course, currentIndex);
			return true;
		}

		this.addResource(this.PART_RESOURCE_TYPE, course);
	}


	onlyFeedbackChange = (onlyFeedback) => {
		let foundExisting = this.getResources().find((resource) =>
			resource.referenceType == onlyFeedback.referenceType
		);

		if (foundExisting != null) {
			const currentIndex = foundExisting.index;
			this.removeResource(foundExisting);
			this.addResource(this.PART_RESOURCE_TYPE, onlyFeedback, currentIndex);
			return true;
		}
	}


	edit = (props) => {
		const translate = this.api.getEditor().getProp('translate');
		let disabled = false;
		let addGoalsDisabled = false;

		let resources = JSON.parse(JSON.stringify(props.resources));
		if (this.api.getEditor().getProp('assessmentFeedbackParts') != null && this.api.getEditor().getProp('assessmentFeedbackParts').length > 0) {
			addGoalsDisabled = true;
		};

		if (this.api.getEditor().getProp('disableRows') != null) {
			disabled = props.resources.find((resource) => {
				return resource.assessmentBlockPartRows?.findIndex((row) =>
					this.api.getEditor().getProp('disableRows').includes(row.id)
				) > -1;
			}) != null;
		}


		const showModal = props.resources.find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.AssessmentBlock.ShowModal'
		);

		const goalParts = resources.filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentGoal'
		});

		const feedbackParts = resources.filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentonlyfeedback'
		});

		const courses = resources.filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'matrix'
		});

		const clarifications = resources.filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentClarification'
		});

		return <div className="block--assessment-block">
			{showModal != null ?
				<SelectCoursesForm
					onClose={() => this.removeResource(showModal)}
					skipCourses={courses.map(course => course.value.id)}
					onSubmit={(values) => {
						this.removeResource(showModal);
						this.addCourses(values);
					}}
				/>
				: null}

			<div className="goal-matrix">
				{goalParts.map((part, index) => {
					return <GoalMatrixForm
						key={index}
						part={part}
						course={courses}
						onChange={this.goalMatrixChange}
						onRemove={() => this.removeResourceFromBlock(part)}
						assessmentType={this.assessmentType}
						disableRows={this.api.getEditor().getProp('disableRows')}
						disabled={disabled}
					/>
				})}

				{courses.map((part, index) => {
					return <SelectMatricesForm
						key={'matrix' + index}
						part={part}
						course={part.value}
						onChange={this.courseChange}
						onRemove={() => this.removeResourceFromBlock(part)}
						clarifications={clarifications}
						assessmentType={this.assessmentType}
						disableRows={this.api.getEditor().getProp('disableRows')}
						disabled={disabled}
					/>
				})}

				{feedbackParts.map((part, index) => {
					return <OnlyFeedbackForm
						part={part}
						onChange={this.onlyFeedbackChange} />
				})}
			</div>

			<div>
				<Flex center>
					<Button type="secondary" disabled={goalParts.length > 0 || addGoalsDisabled} onClick={this.addGoalMatrix} style={{ marginRight: '.55rem' }}>
						<Icon name="Plus" /> {translate('Add goal')}
					</Button>

					<Button type="secondary" disabled={disabled || addGoalsDisabled} onClick={this.addCoursePart}>
						<Icon name="Plus" /> {translate('Add knowledge requirement')}
					</Button>
				</Flex>
			</div>

		</div>
	}

	consume = (props) => {
		let resources = JSON.parse(JSON.stringify(props.resources));

		const goals = [...resources].filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentGoal'
		});

		const courses = [...resources].filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'matrix'
		});

		const clarifications = [...resources].filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentClarification'
		});

		const feedbackParts = resources.filter((resource) => {
			return resource['@odata.type'] == this.PART_RESOURCE_TYPE && resource.referenceType == 'assessmentonlyfeedback'
		});

		return <div className="block--assessment-block consume">
			<div className="display-matrix">
				{goals.map((resource) =>
					<DisplayResource
						resource={resource}
						block={this}
						key={resource.id}
						course={courses}
					/>
				)}

				{courses.map((resource) =>
					<DisplayResource
						resource={resource}
						block={this}
						key={resource.id}
						clarifications={clarifications}
					/>
				)}

				{feedbackParts.map((resource, index) =>
					<DisplayOnlyFeedback block={this} part={resource} />
				)}
			</div>
		</div>
	}

}

export default AssessmentBlock;