import React, { Component } from 'react';
import { connect } from 'react-redux';


class Status extends Component {
	constructor(props) {
		super(props);
	}

	iterateTypes(status, type, id) {
		let response = null;
		let selectedStatus = null;

		if (status[type] != null) {
			selectedStatus = status[type].find(t_o => t_o.id == id);
		}

		if (selectedStatus != null) {
			response = selectedStatus.value;
		}

		return response;
	}

	render() {
		return (
			<span>
				{this.iterateTypes(this.props.status, this.props.type, this.props.id)}
			</span>
		);
	}
}

function mapStateToProps(state) {
	return {
		status: state.status.status,
	};
}

export default connect(mapStateToProps)(Status);
