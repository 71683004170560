import React, { useEffect, useState } from "react";
import { Prompt } from 'react-router-dom';

export const FormPrompt = ({ hasUnsavedChanges, formPromptMessage, currentPathContext }) => {
	const [currentPath, setCurrentPath] = useState(currentPathContext);

	useEffect(() => {
		const onBeforeUnload = (e) => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				e.returnValue = "";
			}
		};
		window.addEventListener("beforeunload", onBeforeUnload);
		return () => window.removeEventListener("beforeunload", onBeforeUnload);

	}, [hasUnsavedChanges]);

	const onLocationChange = (location) => {
		// If the user is trying to navigate to the same page or if hash changing, we don't need to prompt 
		if (location.pathname === currentPath) {
			return true;
		}

		return formPromptMessage;
	};

	return <Prompt when={hasUnsavedChanges} message={onLocationChange} />;
};