import React from 'react';

const Immersive_Reader = props => (
	<svg viewBox="0 0 32 32" role="presentation">
		<g className="icons-default-fill">
			<path className="icons-unfilled" d="M18.14645 14.14645c.31498-.31499.85355-.0919.85355.35355v9c0 .44545-.53857.66854-.85355.35355L15.29289 21H13.5c-.27614 0-.5-.22386-.5-.5v-3c0-.27614.22386-.5.5-.5h1.7929zm4.2071.5C23.45197 15.74486 24 17.20628 24 19c0 1.79372-.54803 3.25514-1.64645 4.35355-.19526.19527-.51184.19527-.7071 0-.19527-.19526-.19527-.51184 0-.7071C22.54803 21.74486 23 20.53962 23 19c0-1.53962-.45197-2.74486-1.35355-3.64645-.19527-.19526-.19527-.51184 0-.7071.19526-.19527.51184-.19527.7071 0zM18 15.7071l-2.14645 2.14644A.5.5 0 0 1 15.5 18H14v2h1.5a.5.5 0 0 1 .35355.14645L18 22.29289v-6.58578zm2.91373.4487C21.6367 16.91755 22 17.8747 22 19c0 1.13059-.38442 2.09164-1.14647 2.85358-.19528.19525-.51186.19522-.7071-.00005-.19526-.19528-.19523-.51186.00004-.7071C20.71775 20.57522 21 19.8696 21 19c0-.8749-.26932-1.58442-.8116-2.1558-.1901-.2003-.18183-.51677.01847-.70687.2003-.1901.51677-.18182.70686.01847zM13.5 8c.81839 0 1.54493.39323 2.00098 1.00106.41916-.56107 1.07047-.9393 1.81187-.99416L17.5 8h4c.7797 0 1.42045.59489 1.49313 1.35554L23 9.5l.0017 4.38343C22.71726 13.62781 22.35863 13.5 22 13.5v-4c0-.24546-.17688-.4496-.41012-.49194L21.5 9h-4c-.7797 0-1.42045.59489-1.49313 1.35554L16 10.5v4.378l-1 1V10.5c0-.73097-.52285-1.33981-1.21497-1.47295l-.14057-.02018L13.5 9h-4c-.24546 0-.4496.17688-.49194.41012L9 9.5v10c0 .24546.17688.4496.41012.49194L9.5 20H12v.5l.01205.17915c.01994.1131.04947.22066.08753.32197L9.5 21c-.7797 0-1.42045-.59489-1.49313-1.35554L8 19.5v-10c0-.7797.59489-1.42045 1.35554-1.49313L9.5 8h4z">
			</path>

			<path className="icons-filled" d="M18.99304 14.41397L19 14.5v9c0 .41576-.46916.6378-.7878.40947l-.06575-.05592L15.29289 21H13.5c-.24546 0-.4496-.17688-.49194-.41012L13 20.5v-3c0-.24546.17688-.4496.41012-.49194L13.5 17h1.7929l2.85355-2.85355c.29398-.29399.78273-.11926.8466.26752zm3.36051.23248C23.45197 15.74486 24 17.20628 24 19c0 1.79372-.54803 3.25514-1.64645 4.35355-.19526.19527-.51184.19527-.7071 0-.19527-.19526-.19527-.51184 0-.7071C22.54803 21.74486 23 20.53962 23 19c0-1.53962-.45197-2.74486-1.35355-3.64645-.19527-.19526-.19527-.51184 0-.7071.19526-.19527.51184-.19527.7071 0zm-1.43982 1.50935C21.6367 16.91756 22 17.8747 22 19c0 1.13059-.38442 2.09164-1.14647 2.85358-.19528.19525-.51186.19522-.7071-.00005-.19526-.19528-.19523-.51186.00004-.7071C20.71775 20.57522 21 19.8696 21 19c0-.8749-.26932-1.58442-.8116-2.1558-.1901-.2003-.18183-.51677.01847-.70687.2003-.1901.51677-.18182.70686.01847zM13.5 8c.81839 0 1.54493.39323 2.00098 1.00106.41916-.56107 1.07047-.9393 1.81187-.99416L17.5 8h4c.7797 0 1.42045.59489 1.49313 1.35554L23 9.5l.0017 4.38343C22.71726 13.62781 22.35863 13.5 22 13.5v-4c0-.24546-.17688-.4496-.41012-.49194L21.5 9h-4c-.7797 0-1.42045.59489-1.49313 1.35554L16 10.5v4.378l-1 1V10.5c0-.73097-.52285-1.33981-1.21497-1.47295l-.14057-.02018L13.5 9h-4c-.24546 0-.4496.17688-.49194.41012L9 9.5v10c0 .24546.17688.4496.41012.49194L9.5 20H12v.5l.01205.17915c.01994.1131.04947.22066.08753.32197L9.5 21c-.7797 0-1.42045-.59489-1.49313-1.35554L8 19.5v-10c0-.7797.59489-1.42045 1.35554-1.49313L9.5 8h4z"></path>
		</g>
	</svg>
);

export default Immersive_Reader;
