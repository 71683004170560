import { getDescription } from 'helpers/content';
import React, { Component } from 'react';


export default class PostContent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div
				className="post-content"
				dangerouslySetInnerHTML={{
					__html: getDescription(this.props.children)
				}}
			/>
		);
	}
}
