import React from 'react';

const Progress = ({ currentValue, maxValue, percentage, label, labelPostion = 'end' }) => {
	if (currentValue && maxValue) {
		percentage = (currentValue / maxValue) * 100;
	}

	return (
		<div>
			{labelPostion === 'end' && (
				<div className="graph progress" style={{ position: 'relative' }}>
					<div
						style={{
							width: `${percentage}%`,
						}}
						className="progress-bar black"
					>{label}</div>
				</div>
			)}
			{labelPostion === 'start' && (
				<div className="graph progress" style={{ position: 'relative' }}>
					<div
						style={{
							width: `${percentage}%`,
						}}
						className="progress-bar blue"
					/>
					<div
						className="label"
						style={{
							left: percentage < 5 ? '20px' : '0px',
						}}
					>
						{label}
					</div>
				</div>
			)}
		</div>
	);
}

Progress.defaultProps = {
	percentage: 0,
	label: null,
	labelPostion: 'end',
	currentValue: null,
	maxValue: null,
};

export default Progress;
