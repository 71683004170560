import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import DateTime from '_class/DateTime';
import SectionTitle from 'components/Presentation/SectionTitle';
import Modal from 'containers/Modals/Modal';
import NoteForm from 'containers/Forms/Note';
import { getMentorNotesByReference, resetActiveNote, setActiveNote, removeNote } from 'actions/notes';
import swal from 'sweetalert2';

import { Icon, Button, Checkbox, Block } from '@haldor/ui';

class MentorNotes extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			noteForm: false,
			showPrivateNotes: false,
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		this.setState({ loading: true });
		this.props.getMentorNotesByReference(this.props.user, 'STUDENT').then(() => {
			this.setState({ loading: false });
		})
	}

	toggleNoteForm = (reload = false, values) => {
		return new Promise(async resolve => {
			if (reload) {
				const { user } = this.props;


				if (this.props.isUpdating != null) {
					this.props.isUpdating();
				}

				this.props.getMentorNotesByReference(user, "STUDENT").then(() => {
					if (this.props.isUpdated != null) {
						this.props.isUpdated();
					}
				});

				if (this.props.isUpdating == null) {
					this.setState({ noteForm: !this.state.noteForm });
					this.props.resetActiveNote();
					resolve(1);
				}
			} else {
				this.setState({ noteForm: !this.state.noteForm });
				this.props.resetActiveNote();

				resolve(1);
			}
		});
	}

	renderNote = (reference) => {
		const date = new DateTime(reference.created);

		if (!reference.public && !this.state.showPrivateNotes) {
			return null;
		}

		return (
			<div className="note" key={reference.id}>
				<h3>{reference.title}</h3>

				<p dangerouslySetInnerHTML={{ __html: reference.text }} />

				<p className="creator">
					{reference.author != null ?
						reference.author.firstName + " " + reference.author.lastName
						: null}

					<span style={{ fontWeight: 400 }}> {date.getFromNow()}</span>
					<span style={{ fontWeight: 400 }}>
						{' • '} {reference.public ? this.props.translate('shared-note') : this.props.translate('private-note')}
					</span>

					{reference.creator == this.props.currentUser.id || reference.id == this.props.createdNote ?
						<span style={{ fontWeight: 400, cursor: 'pointer' }} onClick={() => this.onNoteClick(reference)}>
							{' • '} <Icon name="Pen_Small" /> {this.props.translate('Edit')}
						</span>
						: null}

					{reference.creator == this.props.currentUser.id || reference.id == this.props.createdNote ?
						<span style={{ fontWeight: 400, cursor: 'pointer' }} onClick={() => this.onNoteRemove(reference)}>
							{' • '} <Icon name="Bin" /> {this.props.translate('Remove')}
						</span>
						: null}
				</p>
			</div>
		);
	}

	onNoteClick = (note) => {
		this.props.setActiveNote(note);
		this.setState({ noteForm: true });
	}

	onNoteRemove = (note) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('note-delete-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		})
			.then(result => {
				if (result.value != null) {
					this.props.removeNote(note.id)
				}
			});
	}

	render() {
		if (this.state.loading)
			return <Spinner center />

		var notes = [];
		if (this.props.notes != null) {
			notes = this.props.notes;
			notes.sort((a, b) => {
				return new Date(b.created) - new Date(a.created);
			});
		}

		const { isStudent } = this.props;

		return (
			<div className="meeting single-instance">
				{!isStudent ?
					<Modal isOpen={this.state.noteForm} type="small" onClose={() => this.toggleNoteForm()} title={this.props.translate('note')}>
						<NoteForm
							onAbort={this.toggleNoteForm}
							reference="STUDENT"
							id={this.props.user}
						/>
					</Modal>
					: null}

				<div className="section single-section notes" style={{ border: 0, margin: 0, padding: 0 }}>
					<SectionTitle>
						<span>
							{this.props.translate('notes')}
						</span>
					</SectionTitle>

					<Block>
						{!isStudent ?
							<div>
								<Checkbox
									value={this.state.showPrivateNotes}
									label={this.props.translate('show-private-notes')}
									onChange={(value) => this.setState({ showPrivateNotes: value })}
								/>

								<div style={{ marginTop: '1.55rem' }} />
							</div>
							: null}

						{notes.map((note) => {
							return this.renderNote(note)
						})}

						{notes.length == 0 ?
							<div className="color--meta text--center">
								{this.props.translate('no-notes')}
							</div>
							: null}

						{!isStudent ?
							<Button onClick={() => this.toggleNoteForm()}>
								<Icon name="Plus" />

								{this.props.translate('write')}
							</Button>
							: null}
					</Block>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		notes: state.Notes.mentorNotes,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getMentorNotesByReference,
	resetActiveNote,
	setActiveNote,
	removeNote,
})(MentorNotes);
