import React, { Component } from 'react';
import { Button, Checkbox, Icon, Radio, Select, translate } from '@haldor/ui';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { getActiveSchool } from 'helpers/localstorage';

import { setPageTitle } from 'actions/header';

import { Block } from '@haldor/ui';

import { Spinner } from 'UI';

import Moment from 'moment';

import {
	getArchivedEducationGroups,
	getEducationGroups,
	getLegacyEducationGroups
} from 'actions/sections';

import {
	getCoursesUsedWithinMySections
} from 'actions/assessments'

import './_SubjectPage.scss';
import DatePickers from 'containers/Progress/Partials/DatePickers';
import DateTime from '_class/DateTime';
import { getUserAssessmentDates } from 'helpers/user';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';

class SubjectPage extends Component {

	constructor(props) {
		super(props);

		var subjectIds = []

		if (props.user.subjects != null) {
			props.user.subjects.forEach(subject => {
				if (getActiveSchool(props.user.id) == subject.schoolId) {
					subjectIds.push(subject.subjectId);
				}
			});
		}

		let currentYear = new Date().getFullYear();
		let yearsList = [];

		for (let i = 0; i < 4; i++) {
			let schoolYear = `${currentYear - i - 1}/${currentYear - i}`;
			yearsList.push({ id: i + 1, text: schoolYear, from: currentYear - i - 1, to: currentYear - i });
		}

		this.state = {
			loading: true,
			subjectIds: subjectIds,
			years: [...yearsList],
			filter: {
				subjectId: 0,
				showArchivedLegacySections: false,
				sectionId: 0,
				dates: getUserAssessmentDates()
			},
		};

		var storedFilter = localStorage.getItem("hal.subjectPageFilter");

		if (storedFilter != null) {
			this.state.filter = JSON.parse(storedFilter);
		}
	}

	componentDidMount = async () => {
		this.props.setPageTitle(this.props.translate('verdicts'));

		if (this.state.filter.showArchivedLegacySections) {
			await this.handleArchivedAndLegacyGroupsVisibilityChange(true);
		}

		await this.reloadData();
	}

	reloadData = async () => {
		this.setState({ loading: true });
		var filter = this.state.filter;

		let from = Moment(this.state.filter.dates.start).format("YYYY-MM-DD");
		let to = Moment(this.state.filter.dates.end).format("YYYY-MM-DD");

		await this.props.getCoursesUsedWithinMySections(from, to);
		this.setState({ loading: false });
	}

	componentWillUnmount = async () => {
		localStorage.setItem("hal.subjectPageFilter", JSON.stringify(this.state.filter));
	}

	filterOnGroup = (event) => {
		const sectionId = parseInt(event.target.value, 10);

		var filter = this.state.filter;
		filter.sectionId = sectionId;

		this.setState({ filter });
	}

	filterOnSubject = (event) => {
		const subjectId = parseInt(event.target.value, 10);

		var filter = this.state.filter;
		filter.subjectId = subjectId;

		this.setState({ filter });
	}

	filterOnYear = async (event) => {
		var yearId = parseInt(event.target.value, 10);

		var filter = this.state.filter;
		filter.yearId = yearId;

		this.setState({ filter, loading: true });

		var yearData = this.state.years.find(data => { return data.id == yearId });
		await this.props.getCoursesUsedWithinMySections(yearData.from, yearData.to);

		this.setState({ loading: false });
	}

	renderGroupRow = (data) => {
		return (
			<Link to={`/groups/${data.group.id}/course/${data.course.id}`} className="group-item" key={data.group.id + '-' + data.course.id}>
				<div className="row course">
					<div className="column group-title">
						{data.group.title}
						{data.group.type === 'EDUCATION_GROUP_LEGACY' ? <div className='card-meta' style={{ marginLeft: '1.2rem' }}>{this.props.translate('Deleted')}</div> : null}
						{data.group.archived ? <div className='card-meta' style={{ marginLeft: '1.2rem' }}>{this.props.translate('Archived')}</div> : null}
					</div>
					<div className="column">
						<div className="name --size-12">
							<span className="dot" style={{ backgroundColor: data.course.colorCode }} />
							{`${data.course.title} ${data.course.courseCode}`}
						</div>
					</div>
				</div>
			</Link>
		);
	}

	clearSubjectFilterSelection = () => {
		var filter = this.state.filter;
		filter.subjectId = 0;

		this.setState({ filter });
	}

	clearGroupFilterSelection = () => {
		var filter = this.state.filter;
		filter.sectionId = 0;

		this.setState({ filter });
	}

	renderSubjectsFilter = () => {
		return (<div className="section-header form">
			<div className="option-container filter-container">
				<div className="form-row select">
					<label>{this.props.translate('Subjects')}</label>
					<select value={this.state.filter.subjectId} onChange={this.filterOnSubject}>
						<option value="0">{this.props.translate('select-an-alternative')}</option>

						{this.props.user.subjects != null && this.props.user.subjects.map(subject => {
							return <option value={subject.subjectId} key={subject.subjectId}>
								{subject.title}
							</option>;
						})}
					</select>

					{this.state.filter.subjectId > 0 ?
						<div className="clear-filter-container" onClick={this.clearSubjectFilterSelection}>
							<Icon name="Close" /> {this.props.translate('clear-filter')}
						</div> : null}
				</div>
			</div>
		</div>)
	}

	renderGroupsFilter = () => {
		var groups = this.props.educationGroups;

		if (this.state.filter.showArchivedLegacySections && Array.isArray(this.props.archivedEducationGroups) && this.props.archivedEducationGroups.length > 0) {
			groups = [...groups, ...this.props.archivedEducationGroups];
		}

		if (this.state.filter.showArchivedLegacySections && Array.isArray(this.props.legacyEducationGroups) && this.props.legacyEducationGroups.length > 0) {
			groups = [...groups, ...this.props.legacyEducationGroups];
		}

		if (groups) {

			const groupIds = new Set();

			groups = groups.filter(group => {
				if (!groupIds.has(group.id)) {
					groupIds.add(group.id);
					return true;
				}

				return false;
			});

			groups.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
		}
		return (<div className="section-header form">
			<div className="option-container filter-container">
				<div className="form-row select">
					<label>{this.props.translate('Group')}</label>
					<select value={this.state.filter.sectionId} onChange={this.filterOnGroup}>
						<option value="0">{this.props.translate('select-an-alternative')}</option>

						{groups != null && groups.map(group => {
							return <option value={group.id} key={group.id}>
								{group.title}
							</option>;
						})}
					</select>

					{this.state.filter.sectionId > 0 ?
						<div className="clear-filter-container" onClick={this.clearGroupFilterSelection}>
							<Icon name="Close" /> {this.props.translate('clear-filter')}
						</div> : null}
				</div>
			</div>
		</div>);
	}

	/**
	 * Renders a checkbox to filter archieved groups or deleted groups from AAD
	 * 
	 * @returns {JSX.Element} A JSX element representing the header filter section.
	 */
	renderGroupsCheckBoxFilter = () => {
		return (<div className='row align-items-left'>
			<div className='row-item size-16'>
				<Checkbox
					value={this.state.filter.showArchivedLegacySections}
					onChange={this.handleArchivedAndLegacyGroupsVisibilityChange}
					label={this.props.translate('View archived and deleted groups')}
				/>
			</div>
		</div>);
	}

	onDateChange = (dates) => {
		var filter = this.state.filter;
		filter.dates = dates;
		this.setState({ filter });
	}

	onTermChange = (value) => {
		if (value != '') {
			let dStartDate = Moment();
			let startDate = Moment().format('YYYY-MM-DD');
			let dDueDate = Moment();
			let dueDate = Moment().format('YYYY-MM-DD');

			switch (value) {
				case 'year':
					if (Moment().month() < 7) {
						dStartDate = Moment().subtract(1, 'year').month(7).date(1);
						startDate = Moment().subtract(1, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(6).date(31);
						dueDate = Moment().month(6).date(31).format('YYYY-MM-DD');
					} else {
						dStartDate = Moment().month(7).date(1);
						startDate = Moment().month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().add(1, 'year').month(6).date(31);
						dueDate = Moment().add(1, 'year').month(6).date(31).format('YYYY-MM-DD');
					}

					break;
				case 'previous-year':
					if (Moment().month() < 7) {
						dStartDate = Moment().subtract(2, 'year').month(7).date(1);
						startDate = Moment().subtract(2, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().subtract(1, 'year').month(6).date(31);
						dueDate = Moment().subtract(1, 'year').month(6).date(31).format('YYYY-MM-DD');
					}
					else {
						dStartDate = Moment().subtract(1, 'year').month(7).date(1);
						startDate = Moment().subtract(1, 'year').month(7).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().subtract(0, 'year').month(6).date(31);
						dueDate = Moment().subtract(0, 'year').month(6).date(31).format('YYYY-MM-DD');
					}
					break;
				case 'semester':
					if (Moment().month() < 7) {
						dStartDate = Moment().month(0).date(1);
						startDate = Moment().month(0).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(6).date(31);
						dueDate = Moment().month(6).date(31).format('YYYY-MM-DD');
					}
					else {
						dStartDate = Moment().month(6).date(1);
						startDate = Moment().month(6).date(1).format('YYYY-MM-DD');

						dDueDate = Moment().month(11).date(31);
						dueDate = Moment().month(11).date(31).format('YYYY-MM-DD');
					}

					break;
			}

			this.onDateChange({
				start: dStartDate,
				end: dDueDate,
			});
		}
	}

	/**
	 * Renders a date filter options
	 * 
	 * @returns {JSX.Element} A JSX element representing the header filter section.
	 */
	renderDatesFilter = () => {
		return (<div className='row'>
			<div className="column section-header form">
				<div className="option-container filter-container">
					<div className="form-row select ">
						<DatePickerFromTo
							values={{
								start: Moment(this.state.filter.dates.start),
								end: Moment(this.state.filter.dates.end),
							}}
							onChange={this.onDateChange}
						/>
					</div>
				</div>
			</div>
			<div className='column-3'>
				<div className='term-change' style={{ marginBottom: '0.5rem' }}>
					<Select
						onChange={this.onTermChange}
						options={[
							{
								label: this.props.translate('current-schoolyear'),
								value: 'year',
							},
							{
								label: this.props.translate('previous-schoolyear'),
								value: 'previous-year',
							},
							{
								label: this.props.translate('current-semester'),
								value: 'semester',
							},
						]}
					>
						<strong style={{ marginBottom: '0.2rem' }}>{this.props.translate('select-period')}</strong>
					</Select>
				</div>
				<Button type='secondary' onClick={() => { this.reloadData() }}>{this.props.translate('Update')}</Button>
			</div>
		</div >);
	}

	/**
	 * Renders a header filter section composed of several filter components.
	 * 
	 * @returns {JSX.Element} A JSX element representing the header filter section.
	 */
	renderHeaderFilter = () => {
		return (
			// Create a section for filters with specified styling
			<div className='section' style={{ margin: 0, padding: 0, border: 0, marginBottom: '1.5rem' }}>
				<div className='row'>
					{/* Render the Year filter in a column*/}
					<div className='column-12'>
						{this.renderDatesFilter()}
					</div>
				</div>

				<div className='row'>
					{/* Render the Subjects filter in a column */}
					<div className='column-6'>
						{this.renderSubjectsFilter()}
					</div>
					{/* Render the Groups filter in a column */}
					<div className='column'>
						{this.renderGroupsFilter()}
					</div>
				</div>

				{/* Render Groups Checkbox filter outside of the columns */}
				{this.renderGroupsCheckBoxFilter()}

				{/* Add a clearfix div to ensure proper wrapping */}
				<div className='clearfix' />
			</div>
		)
	}

	/**
	 * Function to handle the visibility of archived sections.
	 * @param {boolean} isSelected - A boolean to decide if archived sections need to be shown.
	 * If true, archived sections are retrieved if not already loaded and made visible.
	 * If false, and the current selected section is an archived one, the selection is cleared.
	 * @returns {Promise<void>} An async function which returns a promise that resolves to void.
	 */
	handleArchivedAndLegacyGroupsVisibilityChange = async (isSelected) => {
		var filter = { ...this.state.filter, showArchivedLegacySections: isSelected };

		// Destructuring archivedEducationGroups from props with a default value of an empty array
		const { archivedEducationGroups = [], legacyEducationGroups = [] } = this.props;

		// If 'isSelected' is true and there are no archived groups,
		// load archived groups and update the state
		if (isSelected) {
			this.setState({ loading: true });

			if (legacyEducationGroups.length === 0) {
				// Make an async call to get archived groups
				await this.props.getLegacyEducationGroups();
			}

			if (archivedEducationGroups.length === 0) {
				// Make an async call to get archived groups
				await this.props.getArchivedEducationGroups();
			}

			this.setState({ loading: false, filter });
			return;
		}

		// If 'isSelected' is false and the section ID in the filter matches an archived group ID,
		// set the section ID in the filter to 0
		if (!isSelected && filter.sectionId > 0 && (archivedEducationGroups.some(group => group.id === filter.sectionId) || legacyEducationGroups.some(group => group.id === filter.sectionId))) {
			filter.sectionId = 0;
		}

		// Update the state with the new filter object
		this.setState({ filter });
	};

	/**
	 * Processes group data and returns courses associated with the groups.
	 *
	 * @param {Array} groups - The groups to be processed.
	 * @returns {Array} An array of course objects, each with associated group data.
	 */
	getFilteredAndSortedCourseGroup = (groups) => {
		const filter = this.state.filter;

		// If `this.props.assessmentCourses` is null, undefined, or otherwise falsy, assign an empty array to `assessmentCourses`.
		// This ensures that `assessmentCourses` will always be an array and prevents errors.
		const assessmentCourses = this.props.assessmentCourses || [];

		let subjectIds = new Set(this.state.subjectIds.filter(subjectId => {
			return filter.subjectId === 0 || filter.subjectId === subjectId
		}));

		let courses = [];

		for (let group of groups) {
			// If group.courses doesn't exist or isn't an array, default it to an empty array
			const groupCourses = [
				...(group.courses || []),
				...assessmentCourses
					.filter(data => data.sectionId === group.id)
					.map(data => data.course)
			];

			for (let course of groupCourses) {
				if (!subjectIds.has(course.subjectId)) continue;

				if (!courses.some(data => data.group.id === group.id && data.course.id === course.id)) {
					courses.push({ course: course, group: group });
				}
			}
		}

		// Sort the courses array based on the 'title' property in alphabetical order
		courses.sort((a, b) => a.group.title.localeCompare(b.group.title));

		return courses;
	}

	render() {
		// If this.state.subjectIds is not an array or is empty, return a message
		if (this.state.subjectIds.length === 0) {
			return (<div>{this.props.translate('no-subject-teacher-groups') + ' ' + this.props.translate('contact-your-school-administrator')}</div>);
		}

		// Default to an empty array if this.props.educationGroups is not an array
		let groups = [...this.props.educationGroups || []];

		// Check if archivedEducationGroups exists and is an array before trying to access its length property
		if (this.state.filter.showArchivedLegacySections && Array.isArray(this.props.archivedEducationGroups) && this.props.archivedEducationGroups.length > 0) {
			groups = [...groups, ...this.props.archivedEducationGroups];
		}

		// Check if legacyEducationGroups exists and is an array before trying to access its length property
		if (this.state.filter.showArchivedLegacySections && Array.isArray(this.props.legacyEducationGroups) && this.props.legacyEducationGroups.length > 0) {
			groups = [...groups, ...this.props.legacyEducationGroups];
		}

		//Filter groups if any section has been choosen in the filter
		if (this.state.filter.sectionId > 0)
			groups = groups.filter(group => { return group.id === this.state.filter.sectionId });

		let processedCourseGroups = this.getFilteredAndSortedCourseGroup(groups);

		return (
			// Render the UI elements
			<div className="verdict-course-page">
				{this.renderHeaderFilter()}

				<Block>
					<h3 className="title" style={{ marginBottom: '2rem' }}>
						{this.props.translate('verdicts')}
					</h3>

					<div className="row" style={{ marginBottom: '0.5rem' }}>
						<div className="column">
							<strong>{this.props.translate('Group')}</strong>
						</div>
						<div className="column">
							<strong>{this.props.translate('Curriculum')}</strong>
						</div>
					</div>

					{this.state.loading ? <div style={{ marginTop: '3rem', marginBottom: '1rem' }} className='row align-items-center'>
						<Spinner />
					</div> :
						<div className="courses">
							{processedCourseGroups.length === 0 ?
								<div className='row align-items-center' style={{ marginTop: '2rem', marginBottom: '1rem' }}><strong>{this.props.translate('No groups found')}</strong></div>
								: processedCourseGroups.map(this.renderGroupRow)}
						</div>
					}

				</Block>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		educationGroups: state.sections.educationGroups,
		legacyEducationGroups: state.sections.legacyEducationGroups,
		archivedEducationGroups: state.sections.archivedEducationGroups,
		assessmentCourses: state.assessments.courses
	}
}

export default connect(mapStateToProps, {
	setPageTitle,
	getArchivedEducationGroups,
	getEducationGroups,
	getCoursesUsedWithinMySections,
	getLegacyEducationGroups
})(SubjectPage);
