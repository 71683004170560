import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import { createStudentAdjustment, getStudentAdjustments } from 'actions/additional_adjustments';
import User from '_class/User';
import sniffer from 'sniffer';

import { Collapsible, Spinner } from 'UI';
import { Icon, Flex, Button } from '@haldor/ui';

import Modal from 'containers/Modals/Modal';
import StudentAdjustmentForm from 'containers/AdditionalAdjustments/Form/StudentAdjustmentForm';
import StudentAdjustment from 'containers/AdditionalAdjustments/Display/StudentAdjustment';

class AdditionalAdjustments extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			loading: true,
		};
	}

	componentDidMount = async () => {
		const { start, end } = this.props;
		await this.props.getStudentAdjustments(this.props.user, start, end);
		this.setState({ loading: false });
	}

	componentWillReceiveProps = async (nextProps) => {
		const { start, end } = nextProps;

		if (nextProps.start == this.props.start && nextProps.end == this.props.end) {
			return false;
		}

		if (this.props.start != start || this.props.end != end) {
			this.setState({ loading: true });
			await this.props.getStudentAdjustments(this.props.user, start, end);
			this.setState({ loading: false });
		}
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	createStudentAdjustment = (values) => {
		return new Promise(resolve => {
			this.props.createStudentAdjustment(values)
				.then(() => {
					this.setState({ modal: false });
					this.props.getStudentAdjustments(this.props.user);
				});
		});
	}

	render() {
		if (this.props.studentAdjustments == null) {
			return null;
		}

		const { translate } = this.props;

		if (this.state.loading) {
			return (
				<div>
					<Flex center>
						<h2 className="c--title" style={{ flex: 1 }}>
							{translate('additional-adjustments')}
						</h2>
					</Flex>

					<Spinner center />
				</div>
			);
		}

		const active = this.props.studentAdjustments.filter(adjustment => {
			if (adjustment == null) return false;

			return adjustment.status == 'ACTIVE';
		});

		const completed = this.props.studentAdjustments.filter(adjustment => {
			if (adjustment == null) return false;

			return adjustment.status == 'COMPLETED';
		});

		const deleted = this.props.studentAdjustments.filter(adjustment => {
			if (adjustment == null) return false;

			return adjustment.status == 'DELETED';
		});

		const currentUser = new User(this.props.currentUser);

		return (
			<div>
				{!currentUser.isStudent() ?
					<Modal isOpen={this.state.modal} onClose={this.toggleModal} title={translate('new-adjustment')}>
						<StudentAdjustmentForm
							onSubmit={this.createStudentAdjustment}
							onClose={this.toggleModal}
							userId={this.props.user}
						/>
					</Modal>
					: null}

				<Flex center>
					<h2 className="c--title" style={{ flex: 1 }}>
						{translate('additional-adjustments')}
					</h2>

					{!currentUser.isStudent() && (currentUser.isPedagogue() || currentUser.isMentor() || currentUser.isTeacher()) && !sniffer.isDevice ?
						<Button onClick={this.toggleModal}>
							<Icon name="Plus" />
							{translate('assign-adjustment')}
						</Button>
						: null}
				</Flex>

				<div style={{ marginTop: '1rem' }}></div>

				{this.props.studentAdjustments.length == 0 ?
					<div className="color--meta weight--bold text--center">
						{translate('no-additional-adjustments')}
					</div>
					: null}

				{active.map(adjustment => {
					return <StudentAdjustment
						key={adjustment.id}
						adjustment={adjustment}
					/>
				})}

				{!currentUser.isStudent() && sniffer.isDevice ?
					<Button onClick={this.toggleModal}>
						<Icon name="Plus" />
						{translate('assign-adjustment')}
					</Button>
					: null}

				<div style={{ marginTop: '1rem' }}></div>

				{completed.length > 0 ?
					<Collapsible trigger={this.props.translate('completed')}>
						{completed.map(adjustment => {
							return <StudentAdjustment
								key={adjustment.id}
								adjustment={adjustment}
							/>
						})}
					</Collapsible>
					: null}

				<div style={{ marginTop: '1rem' }}></div>

				{currentUser.isPedagogue() && deleted.length > 0 ?
					<Collapsible trigger={this.props.translate('Deleted')}>
						{deleted.map(adjustment => {
							return <StudentAdjustment
								key={adjustment.id}
								adjustment={adjustment}
							/>
						})}
					</Collapsible>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		studentAdjustments: state.AdditionalAdjustments.studentAdjustments,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getStudentAdjustments,
	createStudentAdjustment,
})(AdditionalAdjustments);