import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'lib/translate';

class Expire extends Component {
	static defaultProps = {
		delay: 9000,
	}

	constructor(props) {
		super(props);

		this.state = {
			visible: true,
		};

		this._timer = null;
	}

	componentDidMount = () => {
		this.setTimer();
	}

	setTimer = () => {
		// clear any existing timer
		if (this._timer != null) {
			clearTimeout(this._timer);
		}

		// set visible and create timeout on this._timer
		this.setState({ visible: true }, () => {
			this._timer = setTimeout(() => {
				this.setState({ visible: false });
				this._timer = null;

				if (this.props.onExpire) {
					this.props.onExpire();
				}
			}, this.props.delay);
		});
	}

	componentWillUnmount = () => {
		clearTimeout(this._timer);
	}

	render = () => (
		this.state.visible ?
			<div className={`error type-${this.props.type} is-visible`}>
				{this.props.children}
			</div>
			: null
	)
}

const Error = () => {
	const translate = useTranslate();
	const errors = useSelector(state => state.errors);

	const renderError = (error, i) => {
		if (error.viewed == true) {
			return null;
		}

		return (
			<Expire
				key={'error-' + i}
				onExpire={() => error.viewed = true}
				type={error.type ? error.type : 'warning'}
			>
				<div className="content">
					{error.msg != null ?
						error.msg
						: error.translateMsg != null ?
							translate(error.translateMsg)
							: null}
				</div>
			</Expire>
		);
	}

	return (
		<div className="error-messages-container">
			{errors.map(renderError)}
		</div>
	);
}

export default Error;
