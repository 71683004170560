import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";

import { getAssignmentSubmission, getMicrosoftAssignmentDetails, getTaskDetails } from 'actions/assignments';
import { setPageTitle } from 'actions/header';
import { getSection } from 'actions/sections';
import { isUserTeacher } from 'helpers/user';

import AssessmentForm from 'containers/Forms/AssessmentForm';
import WopiFrame from 'containers/WopiFrame/WopiFrame';
import { getBlocksByReference } from 'actions/blocks';
import { createAssessments, updateAssessment, getAssignmentAssessment } from 'actions/assessments';

import { Skeleton, Spinner } from 'UI';

import { Icon, Tabs, Tab } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';
import { isMicrosoftTeams } from 'helpers/teams';

class SubmissionsView extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loadingSubmission: true,
			loadingAssignment: true,
			loadingDetails: false,
			activeFile: null,
			activeSubmission: null,
			sidebarWide: false,
			sidebar: true,
		};
	}

	componentDidMount = () => {
		const { assignmentId } = this.props.match.params;

		this.props.getMicrosoftAssignmentDetails(assignmentId)
			.then(() => {
				this.setState({ loadingDetails: false });
				this.getAssignment();
			});

		if (window.location.hash == '#assessment') {
			this.setState({ sidebarWide: true });
		}

		window.addEventListener('hashchange', () => {
			this.setState({ sidebarWide: window.location.hash == '#assessment' });
		});
	}

	getAssignment = () => {
		const { assignmentId, submissionId } = this.props.match.params;

		if (this.props.assignment == null) {
			if (isMicrosoftTeams()) {
				microsoftTeams.app.getContext().then((context) => {

					let sectionGuid = null;

					if (context.team) {
						sectionGuid = context.team.groupId;

						let group = this.props.groups.find(group => {
							return group.graphId == context.team.groupId;
						});

						if (this.props.group == null) {
							if (group != null) {
								this.props.getSection(group.id);
							}
						}
					}

					this.props.getTaskDetails(assignmentId, sectionGuid).then(() => {
						this.props.setPageTitle(this.props.assignment.displayName);

						if (!context.team) {
							let group = this.props.groups.find(group => {
								return group.graphId == this.props.assignment.classId;
							});

							if (group != null) {
								this.props.getSection(group.id);
							}
						}

						if (this.props.blocks == null && this.props.details != null) {
							this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
						}
						this.props.getAssignmentAssessment(this.props.details.id);
						this.setState({ loadingAssignment: false });
					});
				});
			} else {
				this.props.getTaskDetails(assignmentId).then(() => {
					this.props.setPageTitle(this.props.assignment.displayName);
					this.setState({ loadingAssignment: false });

					if (this.props.group == null) {
						let group = this.props.groups.find(group => {
							return group.graphId == this.props.assignment.classId;
						});

						if (group != null) {
							this.props.getSection(group.id);
						}
					}

					if (this.props.blocks == null && this.props.details != null) {
						this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
					}
					this.props.getAssignmentAssessment(this.props.details.id);
				});
			}
		} else {
			this.props.setPageTitle(this.props.assignment.displayName);

			if (this.props.blocks == null && this.props.details != null) {
				this.props.getBlocksByReference(this.props.details.id, 'ASSIGNMENT');
				this.props.getAssignmentAssessment(this.props.details.id);
			}

			this.setState({ loadingAssignment: false });
		}

		if (this.state.activeSubmission == null && submissionId != null) {
			const submission = {
				'id': submissionId,
			};

			this.onSubmissionSelect(submission);
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { details } = nextProps;

		if (this.props.match.params.submissionId != nextProps.match.params.submissionId) {
			const submission = {
				'id': nextProps.match.params.submissionId,
			};

			this.onSubmissionSelect(submission);
		}
	}

	toggleSidebar = () => {
		this.setState({ sidebar: !this.state.sidebar });
	}

	filterStudentList = () => {
		const { group, assignment } = this.props;
		let students = [];

		if (assignment == null) {
			return students;
		}

		if (assignment.submissions != null && assignment.submissions.length > 0) {
			if (group != null && isUserTeacher(this.props.currentUser)) {
				group.students.forEach((groupStudent) => {
					let student = assignment.submissions.find(submission => submission.recipient.userId == groupStudent.id);

					if (student != null) {
						students.push(groupStudent);
					}
				});
			}

			return students;
		}

		if (assignment.assignTo == null || assignment.assignTo.recipients == null) {
			return students;
		}

		if (group != null && isUserTeacher(this.props.currentUser)) {
			group.students.forEach((groupStudent) => {
				if (this.props.assignment.assignTo == null || this.props.assignment.assignTo.recipients == null) {
					return;
				}

				let student = this.props.assignment.assignTo.recipients.find(assignmentStudentId => assignmentStudentId == groupStudent.id);

				if (student != null) {
					students.push(groupStudent);
				}
			});
		}

		return students;
	}

	getItems = () => {
		const { activeSubmission } = this.state;
		const { assignment } = this.props;

		const students = this.filterStudentList();
		const items = [];

		if (activeSubmission != null && assignment != null) {
			let task = assignment.submissions.find(sub => {
				return sub.id == activeSubmission;
			});

			let student = students.find(student => {
				return student.id == task.recipient.userId;
			});

			if (student != null) {
				let newItem = {
					students: [student],
					id: student.id,
					selected: true,
					status: task.status,
				};

				items.push(newItem);
			}
		}

		return items;
	}

	toggleIcon(flip) {
		return (
			<i className="cl-container">
				<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" className={flip ? 'i-90' : 'a-90'} style={{ height: '7px', width: '15px' }}>
					<path id="Path_50" data-name="Path 50" className="cls-1" d="M11.361,1.4,6.38,5.9,1.4,1.4" />
				</svg>
			</i>
		);
	}

	onDocumentClick = (file) => {
		this.setState({ activeFile: file });
	};

	renderUserAssignmentAttachedDocument = () => {
		const { submission } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);

		return submission.map(file => {
			let notOfficeFile = file.webUrlViewMode === '';
			let active = this.state.activeFile != null ?
				this.state.activeFile.id == file.id
				: false;

			return <div className={'document' + (active ? ' selected' : '')} key={file.id} onClick={e => this.onDocumentClick(file)}>
				<div className="title" >
					<Icon name="File" />

					{file.name}
				</div>

				<div className="action">
					{(!this.props.IsSubmitted || isTeacher) && !notOfficeFile ?
						<a href={file.webUrlEditMode} target="_blank">
							<span>
								<Icon name="Pen_Small" />
							</span>
							{this.props.translate('Edit')}
						</a>
						: null}

					{isTeacher ?
						null
						:
						!this.props.IsSubmitted ?
							<Link onClick={() => { this.removeDocument(file.name) }}>
								<span>
									<Icon name="Bin" />
								</span>
								{this.props.translate('Remove')}
							</Link>
							: null}
				</div>

				<div className="clearfix"></div>
			</div>
		});
	}

	renderNoDocuments = () => {
		return <div style={{ textAlign: 'center' }}>
			<strong>{this.props.translate('no-files-found')}</strong>
		</div>
	}

	onSubmissionSelect = (submission) => {
		const { assignment, details } = this.props;

		if (submission == null) {
			return;
		}

		let student = null;
		let foundSubmission = null;

		if (assignment != null) {
			let students = this.filterStudentList();
			foundSubmission = assignment.submissions.find((sub) => {
				return sub.id == submission.id;
			});

			student = students.find(student => {
				return student.id == foundSubmission.recipient.userId;
			});
		}

		if (details != null) {
			this.setState({ activeSubmission: submission.id, loadingSubmission: true });

			this.props.getAssignmentSubmission(details.id, submission.id).then(() => {
				this.setState({ loadingSubmission: false });
				const { submission } = this.props;

				if (submission != null && submission.length > 0) {
					// Select the first document
					this.onDocumentClick(submission[0]);

					return;
				}

				this.setState({ activeFile: null });
			});
		}
	}

	getActiveStudent = (student, students) => {
		if (student && students) {
			return (
				<DisplayName
					firstName={student.firstName}
					lastName={student.lastName}
					email={student.email}
					dynamicStringWidth={true}
					showEmail={true}
				/>
			);
		}
		return (
			<Skeleton />
		)
	}

	renderListView = () => {
		const { assignment, details } = this.props;
		const { activeSubmission } = this.state;

		if (assignment == null && assignment.submissions != null || details == null || activeSubmission == null)
			return null;

		const students = this.filterStudentList().sort((a, b) => a.lastName.localeCompare(b.lastName));

		let active = assignment.submissions.find((submission) => {
			return submission.id == activeSubmission;
		});

		let studentId = students.findIndex(student => {
			return student.id == active?.recipient?.userId;
		});
		let nextStudentIndex = studentId + 1;
		let prevStudentIndex = studentId - 1;

		let prevStudent = prevStudentIndex >= 0 ?
			students[prevStudentIndex] : null;
		let nextStudent = nextStudentIndex < students.length ?
			students[nextStudentIndex] : null;

		let next = nextStudent !== null ?
			assignment.submissions.find(x => x.recipient.userId === nextStudent?.id) : null
		let prev = prevStudent !== null ?
			assignment.submissions.find(x => x.recipient.userId === prevStudent?.id) : null;


		return <div style={{ marginBottom: '0.75rem' }}>
			<div style={{ display: 'flex' }}>
				<Link to="#" style={{ cursor: 'pointer' }} onClick={() => this.onSubmissionSelect(prev)}>
					{this.toggleIcon(false)}
				</Link>

				<div style={{ flex: 1, display: 'flex', textAlign: 'center', alignItems: 'center' }}>
					<span style={{ fontWeight: 500, flex: '1' }}>
						{this.getActiveStudent(students[studentId], students)}
					</span>
				</div>

				<Link to="#" style={{ cursor: 'pointer' }} onClick={() => this.onSubmissionSelect(next)}>
					{this.toggleIcon(true)}
				</Link>
			</div>

			<div className="clearfix"></div>
		</div>
	}

	renderFile = (file) => {
		if (file == null)
			return null;

		let notOfficeFile = file.webUrlViewMode === '';
		let mp4File = notOfficeFile && /.mp4$/.test(file.webUrl);
		let imageFile = notOfficeFile && /(.png|.jpg|.gif|.jpeg)$/.test(file.webUrl);
		let m4aFile = notOfficeFile && /.m4a$/.test(file.webUrl);

		if (!notOfficeFile) {
			return <WopiFrame activeFile={file.webUrlViewMode} />
		} else if (mp4File) {
			return <video
				src={file.webUrl}
				controls
				autoPlay
				width="100%"
				height="600">
				Your browser does not support the video tag.
			</video>
		} else if (imageFile) {
			return <div style={{ height: '600px', width: '100%', overflowX: 'scroll' }}>
				<img src={file.webUrl} width="100%" />
			</div>
		} else if (m4aFile) {
			return <audio controls preload="auto" autoPlay style={{ width: '100%' }} >
				<source src={file.webUrl} type="audio/mp4" />
				<p>Your browser does not support the audio tag.</p>
			</audio>
		}

		return null;
	}

	renderView = () => {
		const { activeFile } = this.state;

		return <div>
			{activeFile != null ?
				this.renderFile(activeFile)
				:
				<div></div>
			}
		</div>
	}

	onAssessmentSubmit = (values) => {
		return new Promise((resolve) => {
			const { assignment } = this.props;

			let active = assignment.submissions.find((submission) => {
				return submission.id == this.state.activeSubmission;
			});

			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(async () => {
					await this.props.getAssignmentAssessment(this.props.details.id);
					resolve(1);
				});

				return true;
			}

			let data = {
				...values,
				externalId: this.props.assignment.id,
				studentId: active.recipient.userId,
			};

			this.props.createAssessments([data]).then(async () => {
				await this.props.getAssignmentAssessment(this.props.details.id);
				resolve(1);
			});
		});
	}


	render() {
		const {
			assignmentAttachementLoading,
			loadingSubmission,
			activeSubmission,
			loadingDetails,
			sidebarWide,
		} = this.state;
		const { details, assignment, submission } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);
		const items = this.getItems();

		let assessmentBlocks = [];
		let assessment = [];

		if (assignment == null) {
			return <Spinner center />
		}

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		if (this.props.assessments != null && activeSubmission != null) {
			let active = assignment.submissions.find((submission) => {
				return submission.id == activeSubmission;
			});

			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == active.recipient.userId
			);
		}

		return (
			<div className={sidebarWide ? "attached-user-documents wide" : "attached-user-documents"}>
				<div className="iframe-section left-side">
					{this.renderView()}
				</div>

				{this.state.sidebar ?
					<div className="sidebar">
						<div className="collapse color--meta" onClick={this.toggleSidebar}>
							{this.props.translate('fold-up')} <Icon name="ArrowRight" />
						</div>

						{isTeacher ? this.renderListView() : null}

						<Tabs>
							<Tab title={this.props.translate('details')} route="details">
								{loadingSubmission || loadingDetails ?
									<Spinner center />
									:
									submission.length < 1 ?
										this.renderNoDocuments()
										:
										this.renderUserAssignmentAttachedDocument()
								}
							</Tab>

							<Tab title={this.props.translate('Assessment')} route="assessment">
								{isTeacher && loadingSubmission || loadingDetails ?
									<Spinner center />
									:
									isTeacher && items.length > 0 ?
										<div>
											<AssessmentForm
												onSubmit={this.onAssessmentSubmit}
												items={[this.props.assignmentTask]}
												blocks={assessmentBlocks}
												initialValues={assessment}
												referenceType="TeamsAssignment"
												referenceId={details.id}
												showStudents={false}
											/>
										</div>
										: null}
							</Tab>
						</Tabs>
					</div>
					:
					<div className="expand color--meta" onClick={this.toggleSidebar}>
						{this.props.translate('fold-out')} <Icon name="ArrowLeft" />
					</div>
				}

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		details: state.assignments.teamsAssignmentDetails,
		assignment: state.assignments.active_assignment,
		assessments: state.assessments.assessments,
		submission: state.assignments.submission,
		group: state.sections.activeSection,
		groups: state.sections.educationGroups,
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	getAssignmentSubmission,
	getTaskDetails,
	getMicrosoftAssignmentDetails,
	getAssignmentAssessment,
	getSection,
	setPageTitle,
	getBlocksByReference,
	updateAssessment,
	createAssessments
})(SubmissionsView);
