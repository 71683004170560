import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from 'lib/translate';

import User from '_class/User';
import DateTime from '_class/DateTime';

import { getAttendanceColor } from 'helpers/attendance';

const AttendanceHistory = ({ student }) => {
	const translate = useTranslate();
	const scheduleItem = useSelector(state => state.schedule.activeScheduleItem);
	let absences = [];
	let history = [];

	useMemo(() => {
		if (student == null) {
			return false;
		}

		student.items.forEach((item) => {
			let reducedItem = { ...item };
			// Avoid nested references
			delete reducedItem.history;
			delete reducedItem.absences;

			history = [...history, reducedItem];

			if (item.absences != null) {
				absences = [...absences, ...item.absences];
			}

			if (item.history != null) {
				history = [...history, ...item.history].filter((item) => item.status != 'Deleted');
			}
		});
	}, [student]);

	if (student == null) {
		return null;
	}

	const getIndicator = (attendance) => {
		const indicatorColor = getAttendanceColor(attendance);

		return (
			<div className="status">
				<span className="indicator" style={{ backgroundColor: indicatorColor }} />

				<span className="text">
					{translate(attendance.type.toLowerCase())}
				</span>
			</div>
		);
	}

	const getReportedBy = (item) => {
		if (item.reportedBy != null && item.reportedBy.firstName != null) {
			const reportedBy = new User(item.reportedBy);
			return reportedBy.getName()
		}

		if (item.reportedBy != null) {
			if (item.reportedBy.userId == 'TrackAttendance') {
				return translate('system');
			}

			if (item.reportedBy != '') {
				return translate('Guardian')
			}
		}

		return null;
	}

	return (
		<div className="form-container attendance-history">
			<div className="form form-component">
				<div className="form-row">
					<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
						{translate('time')}
					</div>

					{new DateTime(scheduleItem.startTime).getLongDateWithTime().capitalize()}

					<div style={{ marginTop: '0.5rem' }}>
						{scheduleItem.section != null ?
							<div className="card-meta" style={{ marginBottom: 0 }}>
								<Link style={{ margin: 0, textDecoration: 'none', color: '#363636' }} to={`/groups/${scheduleItem.section.id}`}>
									{scheduleItem.section.title}
								</Link>
							</div>
							: null}

						{scheduleItem.attendanceReported != null ?
							<div className="card-meta" style={{ marginLeft: '0.55rem', marginBottom: 0 }}>
								{translate('reported')}
							</div>
							: null}

						<div className="clearfix"></div>
					</div>
				</div>

				<div className="form-row" style={{ marginTop: '1rem' }}>
					<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
						{translate('room')}
					</div>

					{scheduleItem.room}
				</div>

				<div className="form-row" style={{ marginTop: '1rem' }}>
					<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
						{translate('week-day')}
					</div>

					{new DateTime(scheduleItem.startTime).getDay().capitalize()}
				</div>

				<div className="form-row" style={{ marginTop: '1rem', marginBottom: '2rem' }}>
					<div style={{ fontWeight: 500, marginBottom: '0.5rem' }}>
						{translate('length')}
					</div>

					{scheduleItem.length} {translate('minutes')}
				</div>

				{history.length > 0 ?
					<div className="form-row spacing" style={{ marginTop: '1rem' }}>
						<h3>{translate('Reports on lesson')}</h3>

						<table className="x">
							<thead>
								<tr>
									<th>{translate('Status')}</th>
									<th>{translate('Valid absence')}</th>
									<th>{translate('Minutes')}</th>
									<th>{translate('Reported by')}</th>
								</tr>
							</thead>

							<tbody>
								{history.map((item) => {
									return <tr key={item.id}>
										<td>{getIndicator(item)}</td>

										<td>
											{item.type != 'PRESENT' ?
												item.type.indexOf('VALID') == 0 ?
													translate('Yes')
													: translate('No')
												: null}
										</td>

										<td>{item.lateDuration != null && item.lateDuration > 0 ?
											item.lateDuration
											: null}
										</td>

										<td>{item.status != 'Deleted' ?
											getReportedBy(item)
											: null}</td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
					: null}

				{absences.length > 0 ?
					<div className="form-row spacing" style={{ marginTop: '1rem' }}>
						<h3>{translate('Reported absence')}</h3>

						<table className="x">
							<thead>
								<tr>
									<th>{translate('Status')}</th>
									<th>{translate('Absence length')}</th>
									<th>{translate('Valid absence')}</th>
									<th>{translate('Reported by')}</th>
								</tr>
							</thead>

							<tbody>
								{absences.map((absence) => {
									return <tr key={absence.id}>
										<td>{getIndicator(absence)}</td>

										<td>
											{translate('From')}: {new DateTime(absence.startTime).getHourStamp()}

											{absence.endTime != null ?
												<div>{translate('To')}: {new DateTime(absence.endTime).getHourStamp()}</div>
												:
												<div>{translate('To')}: {new DateTime(absence.startTime).add(absence.length, 'minutes').getHourStamp()}</div>
											}
										</td>

										<td>
											{absence.type.indexOf('VALID') == 0 ?
												translate('Yes')
												: translate('No')}
										</td>

										<td>
											{getReportedBy(absence)}
										</td>
									</tr>
								})}
							</tbody>
						</table>
					</div>
					: null}

			</div>
		</div>
	)
}

export default AttendanceHistory;