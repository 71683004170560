import React from 'react';
import { Field as FormField } from 'react-final-form';

import {
	FIELD_DESCRIPTION,
	FIELD_TEXT,
	FIELD_TEXTAREA,
	FIELD_TITLE,
} from 'constants/fields';

import { Editor } from 'UI/Inputs';
import { Icon } from '@haldor/ui';

import './Field.scss';

const Field = (props) => {

	const getFieldProps = () => {
		switch (props.type) {
			case FIELD_TEXT:
				return {
					component: 'input',
					type: 'text',
					name: props.name,
					placeholder: props.placeholder,
					disabled: props.disabled != null ? props.disabled : false,
					onBlur: props.onBlur != null ? props.onBlur : null
				};

			case FIELD_TEXTAREA:
				return {
					component: Editor,
					name: props.name,
					placeholder: props.placeholder,
					disabled: props.disabled != null ? props.disabled : false,
					onBlur: props.onBlur != null ? props.onBlur : null
				};

			default:
				return {};
		}
	}

	return (
		<div className="form-row field">
			{props.type == FIELD_TITLE ?
				<div onClick={props.onLabelClick} className="input-label">
					{props.onLabelClick != null ?
						<Icon name="Chevron" />
						: null}

					{props.value}
				</div>
				: null}

			{props.type == FIELD_DESCRIPTION ?
				<div>{props.value}</div>
				: null}

			{props.type == FIELD_TEXT || props.type == FIELD_TEXTAREA ?
				<div className="input-field">
					<FormField {...getFieldProps()} />
				</div>
				: null}
		</div>
	);

}

export default Field;
