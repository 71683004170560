import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import { Link } from 'react-router-dom';

import { getCourse, setActiveCourse } from 'actions/courses';
import { getSection, setActiveSection } from 'actions/sections';
import { setPageTitle } from 'actions/header';
import { addError } from 'actions';

import {
	createVerdict,
	getSectionVerdicts,
	getVerdictScale,
	updateVerdict,
	submitBulkVerdicts,
} from 'actions/verdicts';

import DateTime from '_class/DateTime';

import Modal from 'containers/Modals/Modal';
import VerdictWidget from 'containers/Verdict/Display/VerdictWidget';
import MultipleVerdictForm from 'containers/Verdict/Form/MultipleVerdictForm';
import VerdictForm from './Partials/VerdictForm';

import { Collapsible, DataList, Spinner, Person } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Button, Icon, Checkbox, TooltipMenu, Tabs, Tab } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

class CourseProgress extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			activeStudent: null,
			verdictModal: false,
			loading: true,
			multipleVerdicts: false,
			editVerdict: 1,
			disableCreateVerdict: false,
			disableCopyButton: false,
			disablePublishButton: false,
			copyPreviousButtonIsLoading: false,
			selectedStudents: [],
			markableStudentCount: -1,
			open: [],
		};
	}

	/* Lifecycle methods */
	componentDidMount = async () => {
		if (this.props.scale == null) {
			this.props.getVerdictScale();
		}

		await this.props.getSection(this.props.match.params.groupId);
		await this.props.getCourse(this.props.match.params.courseId);

		const { course } = this.props;
		this.props.setPageTitle(course.title);

		await this.props.getSectionVerdicts(this.props.match.params.groupId, this.props.match.params.courseId);
		this.setState({ loading: false });
	}

	componentWillUnmount = () => {
		this.props.setActiveSection();
		this.props.setActiveCourse();
	}

	/* Events */
	onStudentSelect = (student) => {
		let { selectedStudents } = this.state;
		let found = this.state.selectedStudents.findIndex(selectedStudent => {
			return selectedStudent.id == student.id;
		});

		let verdicts = this.props.sectionVerdicts.filter(verdict => {
			return verdict.status == 'VERDICT_DRAFT'
		});

		if (found == -1) {
			selectedStudents.push(student);
		} else {
			selectedStudents.splice(found, 1);
		}

		let studentsWithDrafts = this.state.selectedStudents.filter(student => {
			return verdicts.find(verdict => { return verdict.userId == student.userId }) == null
		});

		let disableCreateVerdict = studentsWithDrafts.length < this.state.selectedStudents.length;
		let disablePublishButton = studentsWithDrafts.length > 0;
		let disableCopyButton = studentsWithDrafts.length === 0;

		this.setState({
			selectedStudents,
			disableCreateVerdict: disableCreateVerdict,
			disablePublishButton: disablePublishButton,
			disableCopyButton: disableCopyButton
		});
	}

	toggleOpen = (student, active) => {
		let { open } = this.state;
		let found = this.state.open.indexOf(student.id);

		if (active && found == -1) {
			open.push(student.id);
		}

		if (!active && found > -1) {
			open.splice(found, 1);
		}

		this.setState({ open });
	}

	toggleOpenAll = () => {
		if (this.state.open.length > 0) {
			this.setState({ open: [] });
		} else {
			const { group } = this.props;
			this.setState({ open: [...group.students].map(student => student.id) })
		}
	}

	toggleAllEntries = (checked) => {
		if (checked) {
			let verdicts = this.props.sectionVerdicts.filter(verdict => {
				return verdict.status == 'VERDICT_DRAFT'
			});

			let studentsWithDrafts = this.props.group.students.filter(student => {
				return verdicts.find(verdict => { return verdict.userId == student.userId }) == null
			});

			let disableCreateVerdict = studentsWithDrafts.length < this.props.group.students.length;

			let disablePublishButton = studentsWithDrafts.length > 0;

			this.setState({
				selectedStudents: [...this.props.group.students],
				markableStudentCount: this.props.group.students.length,
				disableCreateVerdict: disableCreateVerdict,
				disablePublishButton: disablePublishButton
			});
		} else {
			this.setState({ selectedStudents: [] });
		}
	}

	onStudentClick = (student) => {
		this.setState({ activeStudent: student, duplicate: false, verdictModal: true });
	}

	reload = () => {
		const { course } = this.props;

		this.props.getSectionVerdicts(this.props.match.params.groupId, course.id);
	}

	onDuplicateClick = (student) => {
		if (this.props.sectionVerdicts != null) {
			let verdict = this.props.sectionVerdicts.find(verdict => {
				return student.id == verdict.userId;
			});

			if (verdict != null) {
				this.setState({ duplicate: true, activeStudent: student });
			}
		}
	}

	toggleVerdictModal = () => {
		this.setState({ verdictModal: !this.state.verdictModal });
	}

	onMultipleVerdictPublish = (values) => {
		const { course } = this.props;

		return new Promise(resolve => {
			values.forEach(value => {
				value.courses = [{
					"courseCode": course.courseCode,
					"subjectCode": course.subjectCode,
					"courseID": course.id
				}];
			});

			this.props.submitBulkVerdicts(values)
				.then(() => {
					this.setState({ multipleVerdicts: false });
					this.postSubmit(resolve);
				});
		});
	}

	postSubmit = (resolve) => {
		const { course } = this.props;
		let promises = [];

		promises.push(this.props.getSectionVerdicts(this.props.match.params.groupId, course.id));

		Promise.all(promises).then(() => {
			let date = this.props.tenant.currentSchoolYear != null ? this.props.tenant.currentSchoolYear : '2017-01-01';

			let verdicts = this.props.sectionVerdicts.filter(verdict => {
				return verdict.status == 'VERDICT_DRAFT'
			});

			let studentsWithDrafts = this.state.selectedStudents.filter(student => {
				return verdicts.find(verdict => { return verdict.userId == student.userId }) == null
			});

			let disableCreateVerdict = studentsWithDrafts.length < this.state.selectedStudents.length;
			let disablePublishButton = studentsWithDrafts.length > 0;
			let disableCopyButton = studentsWithDrafts.length === 0;

			this.setState({
				loading: false,
				disableCreateVerdict,
				disablePublishButton,
				disableCopyButton,
			});

			if (resolve != null) {
				resolve(1);
			}
		});
	}

	/* Render methods */
	renderStudentRow = (student, index) => {
		const { groupId, courseId } = this.props.match.params;

		let verdict = null;
		if (this.props.sectionVerdicts != null) {
			verdict = this.props.sectionVerdicts.find(verdict => {
				return student.id == verdict.userId;
			});
		}

		let open = false;
		if (this.state.open.indexOf(student.id) > -1) {
			open = true;
		}

		const found = this.state.selectedStudents.find(selectedStudent => {
			return selectedStudent.id == student.id;
		});

		const isDraft = verdict != null && verdict.status == 'VERDICT_DRAFT';
		const isPublished = verdict != null && verdict.status == 'VERDICT_PUBLISHED';

		const triggerButton = (
			<Button type="secondary">
				{"⋯"}
			</Button>
		);

		let collapsibleTrigger = (
			<div style={{ display: 'flex' }}>
				<span style={{ flex: 1, display: 'flex', alignItems: "center", marginRight: "1rem" }}>
					<DisplayName
					firstName={student.firstName}
					lastName={student.lastName}
					email={student.email}
					data={this.props.group.students}
					/>
				</span>

				{verdict != null ?
					<VerdictWidget verdict={verdict} />
					: null}

				{verdict != null ?
					<span style={{ marginLeft: '.45rem' }}>
						{new DateTime(isPublished ? verdict.published : verdict.created).getLongDate()}
					</span>
					: null}
			</div>
		)
		let feedback = '';
		if (verdict != null && verdict.feedback != null) {
			feedback = verdict.feedback.replace(/(\r\n|\r|\n)/g, '<br />');
		}

		return <RowItem key={index}>
			<RowCell title=" " shrink style={{ cursor: 'pointer' }}>
				<Checkbox value={found != null} onChange={() => this.onStudentSelect(student)} />
			</RowCell>

			<RowCell title="  ">
				<Collapsible open={open} onChange={(open) => this.toggleOpen(student, open)} trigger={collapsibleTrigger}>
					{verdict != null ?
						<div className={`verdict-feedback-row${!open ? ' no-print' : ''}`}>
							{verdict.createdBy != null ?
								<Person style={{ display: 'inline-flex' }} person={verdict.createdBy}>
									<span dangerouslySetInnerHTML={{ __html: feedback }} />
								</Person>
								:
								<span dangerouslySetInnerHTML={{ __html: feedback }} />
							}

							<VerdictWidget verdict={verdict} />
						</div>
						: null}
				</Collapsible>
			</RowCell>

			<RowCell shrink>
				{isDraft ?
					<span className="draft-badge" style={{ color: 'red' }}>
						{this.props.translate('Draft')}
					</span>
					: null}
			</RowCell>

			<RowCell shrink>
				<Link to={`/groups/${groupId}/course/${courseId}/student/${student.id}`}>
					<Button type="secondary">
						{this.props.translate('Show/Write verdict')}
					</Button>
				</Link>
			</RowCell>

			<RowCell shrink>
				<TooltipMenu trigger={triggerButton} id={student.id}>
					{isPublished ?
						<TooltipMenu.Item onClick={() => this.onDuplicateClick(student)}>
							{this.props.translate('duplicate-previous-verdict')}
						</TooltipMenu.Item>
						: null}

					<TooltipMenu.Item>
						<Link to={`/groups/${groupId}/course/${courseId}/student/${student.id}`}>
							{this.props.translate('Show/Write verdict')}
						</Link>
					</TooltipMenu.Item>
				</TooltipMenu>
			</RowCell>
		</RowItem>
	}

	renderModals = () => {
		const { activeStudent, duplicate } = this.state;
		const { translate } = this.props;

		if (activeStudent == null)
			return null;

		return (
			<Modal
				isOpen={this.state.verdictModal}
				onClose={this.toggleVerdictModal}
				title={translate('new-verdict') + " " + activeStudent.firstName + " " + activeStudent.lastName}
			>
				<VerdictForm
					activeStudent={activeStudent}
					duplicate={duplicate}
					course={this.props.course}
					group={this.props.group}
					onClose={this.toggleVerdictModal}
					reload={this.reload}
				/>
			</Modal>
		);
	}

	toggleMultipleVerdictForm = () => {
		this.setState({ multipleVerdicts: !this.state.multipleVerdicts });
	}

	copyPreviousVerdict = () => {
		let verdicts = this.props.sectionVerdicts.filter((verdict) => {
			let inSelected = this.state.selectedStudents.find(student => {
				return student.id == verdict.userId;
			});

			if (verdict.status === "VERDICT_PUBLISHED" && inSelected != null)
				return verdict;
		});

		let promises = [];

		let values = [];

		verdicts.forEach((verdict) => {
			var value = { courses: verdict.courses, feedback: verdict.feedback, scaleId: verdict.scaleId, scaleLevelId: verdict.scaleLevelId, status: 'VERDICT_DRAFT', userId: verdict.userId };
			values.push(value);
		});

		let promise = this.onMultipleVerdictPublish(values);

		this.setState({ copyPreviousButtonIsLoading: true });

		promises.push(promise);

		Promise.all(promises).then(() => {
			this.setState({ selectedStudents: [], copyPreviousButtonIsLoading: false });
			this.props.addError(this.props.translate('verdict-copy-succeeded'), 'info');
		});
	}

	publishVerdicts = () => {
		let verdicts = this.props.sectionVerdicts.filter((verdict) => {
			let inSelected = this.state.selectedStudents.find(student => {
				return student.id == verdict.userId;
			});

			if (verdict.status == "VERDICT_DRAFT" && inSelected != null)
				return verdict;
		});

		let promises = [];

		verdicts.forEach((verdict) => {
			verdict.status = 'VERDICT_PUBLISHED';
			let promise = this.props.updateVerdict(verdict)
			promises.push(promise);
		});

		Promise.all(promises).then(() => {
			this.postSubmit();
			this.setState({ selectedStudents: [] });
		});
	}

	renderVerdictButton = () => {
		return (
			<Button type="secondary" disabled={this.state.selectedStudents.length == 0 || this.state.disableCreateVerdict} onClick={this.toggleMultipleVerdictForm}>
				{this.props.translate('new-verdict-for-selected')}
			</Button>
		)
	}

	renderPublishButton = () => {
		if (this.props.sectionVerdicts != null && this.props.sectionVerdicts.find(t => t.status == "VERDICT_DRAFT") != null) {
			return (
				<Button disabled={this.state.selectedStudents.length == 0 || this.state.disablePublishButton} onClick={this.publishVerdicts}>
					{this.props.translate('publish-verdicts')}
				</Button>
			);
		} else {
			return null;
		}
	}

	renderCopyPreviousVerdictButton = () => {
		if (this.props.sectionVerdicts != null && this.props.sectionVerdicts.find(t => t.status != "VERDICT_DRAFT") != null) {
			return (
				<Button type="secondary" disabled={this.state.selectedStudents.length == 0 || this.state.disableCopyButton} onClick={this.copyPreviousVerdict}>
					{this.state.copyPreviousButtonIsLoading ? <Spinner center /> : null}
					{this.props.translate('copy-latest-verdicts')}
				</Button>
			);
		} else {
			return null;
		}
	}

	printAndHideSupportButtonOnPrint = () => {
		const supportLauncher = document.getElementById("support-launcher");
		
		if (supportLauncher){
			window.onbeforeprint = () => supportLauncher.style.display = "none";
			window.onafterprint = () => supportLauncher.style.display = "flex";
		}
		window.print();
	}

	render() {
		if (this.props.group == null || this.props.course == null) {
			return <Spinner center />
		}

		const { group } = this.props;

		let open = this.state.open.length > 0;
		let students = [];
		if (group?.students?.length > 0 && group?.students !== null) {
			students = group?.students?.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));
		}

		return (
			<div className="progress single-task mentor-verdicts">
				{this.renderModals()}

				<Modal isOpen={this.state.multipleVerdicts} title={this.props.translate('new-verdict')} onClose={this.toggleMultipleVerdictForm}>
					<MultipleVerdictForm
						onSubmit={this.onMultipleVerdictPublish}
						students={this.state.selectedStudents}
						onRemoveStudent={this.onStudentSelect}
						onAbort={this.toggleMultipleVerdictForm}
					/>
				</Modal>

				<div className="single-section left-side">
					<div className="single-assignment-meta">
						<Link to={`/groups/${this.props.group.id}`}>
							{this.props.translate('Go to group page for')}	{this.props.group.title}
						</Link>
					</div>
				</div>

				<div className="clearfix" />

				<Tabs>
					<Tab title={this.props.translate('verdicts')} route="verdicts">
						{this.state.loading ? <Spinner center /> :
							<div>
								<div className="no-print" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
									<div onClick={this.toggleOpenAll} className={open ? "toggle-all flip color--meta" : "toggle-all color--meta"}>
										<Icon name="ArrowLeft" />

										{open ?
											this.props.translate('Close all')
											: this.props.translate('Show all')}
									</div>

									<div style={{ marginRight: '0.65rem' }}>
										<div onClick={() => this.printAndHideSupportButtonOnPrint()} style={{ cursor: 'pointer' }} className="color--meta">
											<span style={{ marginRight: '0.25rem', position: 'relative', top: 2 }}>
												<Icon name="Printer" />
											</span>

											{this.props.translate("print")} 
										</div>
									</div>
								</div>

								<DataList
									data={students}
									renderRow={this.renderStudentRow}
									toggleAllEntries={this.toggleAllEntries}
									allEntriesSelected={this.state.selectedStudents.length == this.state.markableStudentCount}
								/>

								<div className="form-container">
									<div className="form">
										<div className="form-row submit" style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
											<div className="align-right">
												{!this.props.group.archived ? this.renderCopyPreviousVerdictButton() : null}
												{!this.props.group.archived ? this.renderVerdictButton() : null}
												{!this.props.group.archived ? this.renderPublishButton() : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						}
					</Tab>
				</Tabs>

				<div className="clearfix" />
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		group: state.sections.activeSection,
		course: state.Courses.course,
		userVerdicts: state.Verdicts.user,
		scale: state.Verdicts.scale,
		sectionVerdicts: state.Verdicts.section,
		tenant: state.user.tenant,
		studentAdjustmentsByCourse: state.AdditionalAdjustments.studentAdjustmentsByCourse,
		studentAdjustmentsBySubject: state.AdditionalAdjustments.studentAdjustmentsBySubject,
	};
}

export default connect(mapStateToProps, {
	getSection,
	setActiveSection,
	getCourse,
	setActiveCourse,
	createVerdict,
	getVerdictScale,
	getSectionVerdicts,
	updateVerdict,
	addError,
	setPageTitle,
	submitBulkVerdicts,
})(CourseProgress);