import {
	VALIDATE_PLAGIARISM_LICENSE
} from 'actions/plagiarism';

const INITIAL_STATE = {
	hasValidPlagiarismLicense: false
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case VALIDATE_PLAGIARISM_LICENSE:
			return { ...state, hasValidPlagiarismLicense: action.payload };

		default:
			return state;
	}
}
