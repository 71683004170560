import React, { Component } from 'react';

import './DataList.scss';

import { Checkbox } from '@haldor/ui';

// Need to work on naming for the component
class DataList extends Component {

	constructor(props) {
		super(props);

		let available = [];
		let active = null;
		const preferred = localStorage.getItem('haldor-datalist-mode');

		if (props.renderRow != null) {
			available.push('table');
		}

		if (props.renderCard != null) {
			available.push('card');
		}

		if (preferred != null && available.indexOf(preferred) > -1) {
			active = preferred;
		} else {
			active = available[0];
		}
		

		this.state = {
			active,
			columns: [],
			available,
		};
	}

	componentDidMount = () => {
		this.updateColumns(this.props);
		
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.updateColumns(nextProps);
	}

	updateColumns = (props) => {
		if (props.data != null && props.data.length > 0) {
			let columns = [];

			if (props.renderRow != null) {
				props.data.forEach((item, index) => {
					const row = props.renderRow(item, index);

					if (row != null && row.props.children != null) {
						let children = [];

						// Make sure we keep the children var array
						// row.props.children can be both object and array
						if (Array.isArray(row.props.children)) {
							children = row.props.children;
						} else {
							children = [row.props.children];
						}

						children.forEach(rowChild => {
							if (rowChild != null && rowChild.props != null && rowChild.props.title != null) {
								if (columns.indexOf(rowChild.props.title) == -1) {
									columns.push(rowChild.props.title);
								}
							}
						});
					}
				});
			}

			this.setState({ columns });
		}
	}

	onSelectType = (type) => {
		localStorage.setItem('haldor-datalist-mode', type);
		this.setState({ active: type });
	}

	renderTable = () => {
		return (
			<div className="table">
				{this.state.columns.length > 0 ?
					<div className="row table-head">
						{this.state.columns.map((column, index) => (
							<div className="cell" key={'column-' + column}>
								{this.props.toggleAllEntries != null && index == 0 ?
									<Checkbox
										onChange={this.props.toggleAllEntries}
										value={this.props.allEntriesSelected}
										label={column}
									/>
									: column}
							</div>
						))}
					</div>
					: null}

				{this.props.data.map((item, index) => {
					return this.props.renderRow(item, index);
				})}
			</div>
		);
	}

	renderCards = () => {
		return (
			<div className="cards-container">
				{this.props.data.map((item, index) => {
					return this.props.renderCard(item, index);
				})}
			</div>
		);
	}

	renderMain = () => {
		if (this.props.data.length == 0 && this.props.emptyData != null) {
			return (
				<div className="list-content">
					{this.props.emptyData()}
				</div>
			);
		}

		return (
			<div className="list-content">
				{ this.state.active == 'table' ?
					this.renderTable()
					: null}

				{ this.state.active == 'card' ?
					this.renderCards()
					: null}
			</div>
		);
	}

	render() {
		if (this.props.data == null) {
			return null;
		}

		return (
			<div className="list-wrapper">
				<div className="list-header">
					{this.props.title ?
						<div className="list-title">
							<h3>{this.props.title}</h3>
						</div>
						: null}

					<div className="list-actions">
						{this.props.actions != null ?
							this.props.actions()
							: null}

						{ /* this.state.available.length > 1 ? this.state.available.map(type => {
							let className = 'button button-action';
							if (type == this.state.active) {
								className += ' active';
							}

							return <div key={type} style={{ marginLeft: '0.55rem' }} className={className} onClick={() => { this.onSelectType(type) }}>
								{type == 'card' ?
									<Card />
									: type == 'table' ?
										<List />
										:
										type
								}
							</div>
						}) : null */ }
					</div>
				</div>

				<div style={{ clear: 'both', width: '100%' }}></div>

				{this.renderMain()}
			</div>
		);
	}

}

export default DataList;
