import { getSection } from 'actions/sections';
import StudentGuardianSelector from 'containers/Forms/Partials/StudentGuardianSelector';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Expandable, List } from 'UI';
import { Editor } from 'UI/Inputs';
import { Checkbox, Button } from '@haldor/ui';
import { Spinner } from 'UI';
import { PRESCHOOL } from "constants/schoolTypes";

class CreateMeeting extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sectionId: 0,
			date: Moment(),
			error: null,
			selectedUsers: []
		};
	}

	submit = (values) => {
		return new Promise(resolve => {
			values.instances = [];
			values.status = 'DRAFT';

			this.state.selectedUsers.forEach(student => {
				let instance = {
					date: null,
					participants: [],
					status: 'DRAFT',
					studentLed: values.studentLed
				};

				if (student.selected) {
					let participant = {
						userID: student.studentId,
						userType: "STUDENT",
						sectionId: student.sectionId,
					};
					instance.participants.push(participant);
				}

				if (student.guardians != null) {
					student.guardians.forEach((guardian) => {
						let participant = {
							userID: guardian.id,
							userType: "GUARDIAN",
							sectionId: student.sectionId,
						};
						instance.participants.push(participant);
					});
				}

				let currentUser = {
					userID: this.props.currentUser.id,
					userType: 'USER',
					sectionId: student.sectionId,
				};

				instance.participants.push(currentUser);
				instance.studentId = student.studentId;
				values.instances.push(instance);
			});

			values.type = "EVALUATION";

			if (this.props.onSubmit) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					});
			}
		});
	}

	onUpdate = (selectedUsers) => {
		this.setState({ selectedUsers: selectedUsers });
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ margintop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ margintop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;

		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	render() {
		const { handleSubmit, submitting, valid } = this.props;

		let isPreschool = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL;

		if (submitting) {
			return <Spinner center />
		}

		return (
			<div className="form-container">
				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<label>{this.props.translate('title')}*</label>
						<Field
							name="title"
							type="text"
							component={this.renderInput}
							placeholder={this.props.translate('title')}
							validate={this.required}
						/>
					</div>

					<div className="form-row input">
						<label>{this.props.translate('Information-to-participants')}</label>

						<Field
							component={Editor}
							name="description"
							placeholder={this.props.translate('describe-meeting')}
							style={{ margin: 0 }}
							translate={this.props.translate}
						/>
					</div>

					{this.props.services.teamsMeeting ?
						<div className="form-row">
							<label>{this.props.translate('create-teamsmeeting')}</label>

							<Field
								component={Checkbox}
								name="onlineMeeting"
								label={this.props.translate('create-teamsmeeting-for-this-meeting')}
							/>
						</div>
						: null}

					{!isPreschool ? <div className="form-row">
						<label>{this.props.translate('studentled')}</label>

						<Field
							component={Checkbox}
							name="studentLed"
							label={this.props.translate('studentled-meeting')}
						/>
					</div> : null}

					<div style={{ marginTop: '2.5em' }}>
						<Expandable
							contentStyles={{ padding: '1em 0rem' }}
							contentStylesClosed={{ padding: '0' }}
							headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
							whiteBackground={true}
							title={this.props.translate('participants') + '*'}
						>
							<StudentGuardianSelector
								mentorGroups={true}
								onUpdate={this.onUpdate}
							/>
						</Expandable>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid || this.state.selectedUsers.length == 0}>
							{this.props.translate('next')}
						</Button>
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		services: state.Services.availableServices,
	}
}

export default connect(mapStateToProps, {
	getSection,
})(reduxForm({
	form: 'CreateMeting',
	destroyOnUnmount: true,
})(CreateMeeting));