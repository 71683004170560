import React from 'react';

class DataClass {

	constructor(props) {
		this.data = props;
	}

	log = () => {
		console.log(this.data);
	}

	debug = () => {
		console.debug(this);
	}

	getHTML = (html) => {
		try {
			html = html.replace(/amp;/g, '');
			html = html.replace(/amp;wd/g, '');
			html = html.replace(/&lt;/g, '<');
			html = html.replace(/&gt;/g, '>');
			html = html.replace(/\<\/b\>/ig, ' </b>');
			html = html.replace(/\<\/i\>/ig, ' </i>');
			html = html.replace(/\<\/a\>/ig, ' </a>');
			html = html.replace(/\<\/p\>/ig, ' </p>');
		} catch (error) {
			html = html;
		}

		return html;
	}

}

export default DataClass;
