import { logActivity } from 'actions/logs';
import { setActiveSection } from 'actions/sections';
import { clearAssignmentEventPaginationFromStore } from 'actions/assignments';
import {
	COMPULSORY_SCHOOL,
	PRESCHOOL,
	UPPER_SECONDARY_EDUCATION,
	ADULT_EDUCATION
} from 'constants/schoolTypes';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import CompulsorySchoolSection from './SectionTypes/CompulsorySchoolSection';
import PreSchoolSection from './SectionTypes/PreSchoolSection';
import UpperSecondarySchoolSection from './SectionTypes/UpperSecondarySchoolSection';

class SectionWrapper extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
		};
	}

	componentWillUnmount() {
		this.props.setActiveSection();
		this.props.clearAssignmentEventPaginationFromStore();
	}

	componentDidMount() {
		//log the activity of loading the page
		const logJson = {
			LogType: "Route Change",
			ReferenceType: "Group Page",
			ReferenceId: this.props.match.params.groupId,
			Comment: "User loaded page",
		};

		this.props.logActivity(logJson);
		this.props.clearAssignmentEventPaginationFromStore();
	}

	render() {
		if (this.props.currentUser == null) {
			return;
		} else {
			switch (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type) {
				case PRESCHOOL:
					return <PreSchoolSection />

				case COMPULSORY_SCHOOL:
					return <CompulsorySchoolSection />

				case UPPER_SECONDARY_EDUCATION:
					return <UpperSecondarySchoolSection />

				case ADULT_EDUCATION:
					return <UpperSecondarySchoolSection />

				default:
					return <div></div>
			}
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser
	};
}

export default connect(mapStateToProps, { setActiveSection, logActivity, clearAssignmentEventPaginationFromStore })(SectionWrapper);
