import {
	GET_SMS,
	GET_SMS_LIST,
	CREATE_SMS,
	UPDATE_SMS,
	GET_REMAINING_SMS,
	GET_SMS_STATISTICS,
	GET_SMS_EDUCATION_SECTIONS,
	GET_SMS_MENTOR_SECTIONS,
} from 'actions/sms';

const INITIAL_STATE = {
	single: null,
	list: null,
	remaining: null,
	limit: null,
	statistics: null,
	educationSections: null,
	mentorSections: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SMS:
			return { ...state, single: action.payload };

		case GET_SMS_LIST:
			return { ...state, list: action.payload };

		case CREATE_SMS:
			if (state.list == null) {
				return { ...state, list: [action.payload] };
			}

			return { ...state, list: [action.payload, ...state.list] };

		case UPDATE_SMS:
			if (state.list != null) {
				// update sms in list as well
				return {
					...state,
					single: action.payload,
					list: [...state.list.filter(t => {
						return t.id != action.payload.id;
					}), action.payload],
				};
			}

			return { ...state, single: action.payload };

		case GET_REMAINING_SMS:
			if (action.payload != null) {
				return {
					...state,
					remaining: action.payload.remainingAmount,
					limit: action.payload.totalAmount * action.payload.limit,
				};
			}

			return state;

		case GET_SMS_STATISTICS:
			return { ...state, statistics: action.payload };

		case GET_SMS_EDUCATION_SECTIONS:
			return { ...state, educationSections: action.payload };

		case GET_SMS_MENTOR_SECTIONS:
			return { ...state, mentorSections: action.payload };

		default:
			return state;
	}

}