import { QrCodeScannerOutlined } from '@mui/icons-material';
import React, { Component } from 'react';

class ColorLabel extends Component {
	render() {
		const { color, tooltip, content } = this.props;

		let dotProps = Object.assign({}, this.props);
		delete dotProps.color;
		delete dotProps.tooltip;
		delete dotProps.content;
		delete dotProps.children;

		return <div className="dot" {...dotProps}>
			<span className="dot-content" style={{ backgroundColor: color }} data-tooltip={tooltip} />

			<span className="text" style={{ marginLeft: "5px" }}>
				{content ? content : tooltip}
			</span>
		</div>
	}
}

export default ColorLabel;
