import AssessmentBlock from '../AssessmentBlock';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';

class PeerReviewBlock extends AssessmentBlock {

	constructor(props) {
		super(props);
		this.name = 'Haldor.Blocks.AssessmentBlock.Peer';
		this.block_type = 'expandable_block';
		this.assessmentType = ASSESSMENT_TYPES.PEER;
	}

	editorBlockWasAdded = () => {
		const editor = this.api.getEditor();
		const translate = editor.getProp('translate');
		var editMode = false;
		const peerAssessments = editor.getBlocks().filter(block => {
			return block.assessmentType == ASSESSMENT_TYPES.PEER
		});

		editor.getBlocks().forEach(block => {
			editMode = block.resources?.length > 0 && block.resources.every((s) => {
				return s.id != null && s.id > 0
			});
		});

		if (editMode) {
			const foundIndex = peerAssessments.findIndex((b) => b.field == this.field);
			this.events.onTitleChange(translate('Peer review') + ' ' + (foundIndex + 1));
		} else {
			this.events.onTitleChange(translate('Peer review') + ' ' + (peerAssessments.length));
		}

		const disableRows = editor.getProp('disableRows');
		if (disableRows != null && disableRows.length > 0 && this.resources != null) {
			const disabled = this.getResources().find((resource) => {
				return resource.assessmentBlockPartRows?.findIndex((row) =>
					disableRows.includes(row.id)
				) > -1;
			}) != null;

			if (disabled) {
				this.api.deregisterAction('Remove');
			}
		}
	}

	addGoalMatrix = (event) => {
		event.preventDefault();

		this.addResource(this.PART_RESOURCE_TYPE, {
			status: 'Started',
			referenceId: '',
			referenceType: 'assessmentGoal',
			value: {
				id: 0,
				visibleToStudent: true,
				visibleToGuardian: true,
				title: '',
				rows: [],
			},
			permissions: []
		});
	}
}

export default PeerReviewBlock;