import { createFormTemplate, updateFormTemplate } from 'actions/templates';
import CreateFormTemplate from 'containers/FormTemplate/Form/CreateFormTemplate';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button } from '@haldor/ui';

class FormTemplate extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		let fields = [];

		// Todo: Change so we can send an entire form object as prop and set fields from that
		if (props.fields != null && props.fields.length > 0) {
			fields = [...props.fields];
		}

		if (props.initialValues != null && props.initialValues.fields != null && props.initialValues.fields.length > 0) {
			fields = [...props.initialValues.fields];
		}

		this.state = {
			fields,
		};
	}

	/* Helper methods */
	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	/* Events */
	onCancel = (event) => {
		// cancel form
		event.preventDefault();

		if (this.props.onAbort != null) {
			this.props.onAbort();
		}
	}

	onSubmit = (values) => {
		return new Promise((resolve) => {
			// Todo: Add support for all other form attributes here
			values.fields = this.state.fields;
			values.status = 'PUBLISHED';

			if (this.props.initialValues != null) {
				this.props.updateFormTemplate(values)
					.then(() => {
						resolve(1);

						if (this.props.onAbort != null) {
							this.props.onAbort(true);
						}
					});
			} else {
				this.props.createFormTemplate(values)
					.then(() => {
						resolve(1);

						if (this.props.onAbort != null) {
							this.props.onAbort(true);
						}
					});
			}
		});
	}

	onFieldsChange = (fields) => {
		this.setState({ fields: fields });
	}

	/* Render methods */
	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))
				}
			</div>
		);
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;
		let isEditing = false;
		let disableSave = false;

		if (this.state.fields.length == 0) {
			disableSave = true;
		}

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.onSubmit)} className="form form-component">
					<div className="form-row">
						<label>{this.props.translate('template-name')}</label>

						<Field
							component={this.renderInput}
							type="text"
							name="title"
							validate={this.required}
						/>
					</div>

					<div className="form-divider" style={{ marginBottom: 40 }} />

					<CreateFormTemplate fields={this.state.fields} onChange={this.onFieldsChange} />

					<div className="form-divider" />

					<div className="form-row spacing submit">
						{isEditing ?
							<Button disabled={submitting || !valid || disableSave}>
								{this.props.translate('save')}
							</Button>
							:
							<Button disabled={submitting || !valid || disableSave}>
								{this.props.translate('publish')}
							</Button>
						}

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		)
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		//initialValues: state.Templates.formTemplate,
	};
}

export default connect(mapStateToProps, {
	createFormTemplate,
	updateFormTemplate,
})(reduxForm({
	form: 'CreateFormTemplate',
	destroyOnUnmount: true,
})(FormTemplate));
