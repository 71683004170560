import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { getAttendanceColor } from 'helpers/attendance';
import { translate as getTranslate, Icon } from '@haldor/ui';
import TimeLineButton from './TimeLineButton';
import moment from 'moment';

const ItemContent = (props) => {
	const { event, lesson, attendance } = props;
	const translate = getTranslate(useSelector((state) => state.Languages.translations));
	const service = useSelector((state) => state.Services.availableServices);
	const getText = (value, singular, plural) => {
		return value == 1 ? translate(singular) : translate(plural);
	}
	let sectionTitle = null;

	if (props.view == 'month') {
		const getAssignmentExams = () => { return (event?.length?.ASSIGNMENT_TYPE_VERBAL_TEST ?? 0) + (event?.length?.ASSIGNMENT_TYPE_WRITTEN_TEST ?? 0); };
		const getLessons = () => { return event.length.lesson - event.length.lessonExaminations; };
		const getAssignemnts = () => { return event.length.Assignment - getAssignmentExams(); };

		return (
			<div className='month-text'>
				{event.length.lessonExaminations > 0 && (
					<div className='month-container'>
						<div className='monthIcon test' />{' '}
						<span>
							{event.length.lessonExaminations} {getText(event.length.lessonExaminations, 'Test (Lesson)', 'Test (Lessons)')}
						</span>
					</div>
				)}

				{getAssignmentExams() > 0 && (
					<div className='month-container'>
						<div className='monthIcon test' />{' '}
						<span>
							{getAssignmentExams()} {getText(getAssignmentExams(), 'Test (Assignment)', 'Test (Assignments)')}
						</span>
					</div>
				)}

				{getLessons() > 0 && (
					<div className='month-container'>
						<div className='monthIcon lesson' />
						<span>
							{getLessons()} {getText(getLessons(), 'Lesson', 'lessons')}
						</span>
					</div>
				)}

				{getAssignemnts() > 0 && (
					<div className='month-container'>
						<div className='monthIcon assignment' />{' '}
						<span>
							{getAssignemnts()} {getText(getAssignemnts(), 'Assignment', 'Assignments')}
						</span>
					</div>
				)}

				{event.length.calendarEvent > 0 && (
					<div className='month-container'>
						<div className='monthIcon calendarEvent' />
						<span>
							{event.length.calendarEvent} {getText(event.length.calendarEvent, 'Calendar event', 'Calendar events')}
						</span>
					</div>
				)}

				{event?.length?.meetings > 0 && (
					<div className='month-container'>
						<div className='monthIcon meeting' />{' '}
						<span>
							{event?.length?.meetings} {getText(event?.length?.meetings, 'Booked meeting', 'Booked meetings')}
						</span>
					</div>
				)}

				{event?.length?.unBooked > 0 && (
					<div className='month-container'>
						<div className='monthIcon meeting' />{' '}
						<span>
							{event?.length?.unBooked} {getText(event?.length?.unBooked, 'Unbooked meeting', 'Unbooked meetings')}
						</span>
					</div>
				)}
			</div>
		);
	}

	if (props?.activities?.type === 'Meeting' && props.view !== 'agenda') {
		let meeting = props?.activities;
		return (
			<div className='event-info'>
				{meeting?.length >= 30 ? (
					<div className='agenda-event-title'>{meeting.title}</div>
				) : null}
				{meeting?.teamsMeeting != null ? (
					<div className='teams-meeting-indicator'>
						<Icon name='Teams' />
					</div>
				) : null}
				{meeting?.length >= 40 ? (
					<div className='size-12 event-meta'>{meeting?.instanceTitle}</div>
				) : null}
				{meeting?.length >= 60 && meeting?.instanceStatus ? (
					<div className='status-meeting'>
						<span
							className='indicator-meeting'
							style={{
								backgroundColor:
									meeting?.instanceStatus === 'ACTIVE' ? '#DBDBDB' : '#19DB6C',
							}}
						/>
						<span className='indicator-title'>
							{meeting?.instanceStatus === 'ACTIVE'
								? translate('meeting-not-completed')
								: translate('meeting-completed')}
						</span>
					</div>
				) : null}
			</div>
		);
	}
	if (props?.activities?.type === 'Assignment' && props.view !== 'agenda') {
		let assignment = props?.activities;
		let endTime = moment
			.utc(assignment.startTime)
			.set({ second: 0, millisecond: 0 })
			.local()
			.add(assignment.length, 'minutes')
			.format('LT');

		return (
			<div className='event-info'>
				<div>{endTime}</div>

				<div className='agenda-event-title'>{assignment.title}</div>
				{assignment?.length >= 40 ? (
					<div className='size-12 event-meta'>{assignment?.section?.title}</div>
				) : null}
			</div>
		);
	}

	if (lesson?.type == 'CALENDAR_EVENT') {
		let sectionListLimit = 4;
		let entireSchool = lesson?.activityMemberships?.find(m => m.referenceType === 'SCHOOL') != null;
		return (
			<div>
				{event.title}
				<div className='size-12 event-meta'>
					{entireSchool ?
						translate('Entire school')
						: (
							lesson?.sections?.slice(0, sectionListLimit).map((section, index) =>
								<span> {(index > 0 ? ", " : "") +
									section.title +
									(index == sectionListLimit - 1 && lesson?.sections?.length > sectionListLimit ? "..." : "")} </span>)
						)}
				</div>
			</div>
		);
	}

	if (lesson?.section != null) {
		sectionTitle = lesson.section.title;
	}

	if (lesson?.scheduleSubject != null && lesson?.scheduleSubject.extraInfo != null) {
		sectionTitle = lesson.scheduleSubject.extraInfo;
	}

	const indicator = () => {
		if (!service.attendance) {
			return null;
		}

		let indicatorColor = '#DBDBDB';
		let text = translate('not-reported-lesson');

		if (lesson.linkedSchedulePositions != null) {
			let reported = [
				...lesson.linkedSchedulePositions.filter((lesson) => {
					return lesson.attendanceReported != null;
				}),
			].length;

			return (
				<div className='status'>
					<span
						className='indicator'
						style={{
							backgroundColor:
								reported == lesson.linkedSchedulePositions.length
									? '#19DB6C'
									: '#DBDBDB',
						}}
					/>
					{reported}({lesson.linkedSchedulePositions.length}) {'reported'}
				</div>
			);
		}

		if (attendance != null) {
			indicatorColor = getAttendanceColor(attendance);
			text = translate(attendance.type.toLowerCase());
		} else {
			if (lesson.attendanceReported != null) {
				indicatorColor = '#19DB6C';
				text = translate('reported');
			}
		}

		return (
			<div className='status' style={{ width: '100%' }}>
				<span className='indicator' style={{ backgroundColor: indicatorColor }} />

				<span className='text'>{text}</span>
			</div>
		);
	};

	if (props.view == 'agenda') {
		if (props?.activities?.type === 'Assignment') {
			let assignment = props?.activities;
			return (
				<div className='event-info'>
					<div className='agenda-event-title'>
						{assignment.title}
					</div>
					<div>{sectionTitle != null ? sectionTitle : null}</div>
				</div>
			)
		} else if (props?.activities?.type === 'Meeting') {
			let meeting = props?.activities;
			return (
				<div className='event-info'>
					<div className='agenda-event-title'>
						{meeting.title}

					</div>
					{meeting?.teamsMeeting != null ? (
						<div className='teams-meeting-indicator'>
							<Icon name='Teams' />
						</div>
					) : null}
					<div>{sectionTitle != null ? sectionTitle : null}</div>
					{/* 		<div className='size-12 event-meta'>{meeting?.instanceTitle}</div> */}
					{meeting?.instanceStatus ? (
						<div className='status'>
							<span
								className='indicator'
								style={{
									backgroundColor:
										meeting?.instanceStatus === 'ACTIVE' ? '#DBDBDB' : '#19DB6C',
								}}
							/>
							<span className='event-meta'>
								{meeting?.instanceStatus === 'ACTIVE'
									? translate('meeting-not-completed')
									: translate('meeting-completed')}
							</span>
						</div>
					) : null}

				</div>
			)
		}

		return (
			<div className='event-info'>
				<div className='agenda-event-title'>
					{event.title}
					{lesson?.teamsMeeting != null ? <Icon name='Teams' /> : null}
				</div>

				<div>{sectionTitle != null ? sectionTitle : null}</div>

				<div>{lesson?.room}</div>

				<div className='event-meta'>
					{lesson?.attendanceReported || attendance != null ? indicator() : ' '}
				</div>
			</div>
		);
	}

	if (lesson?.length >= 60) {
		return (
			<div className='event-info' style={{ width: '100%' }}>
				{event.title}
				<div className='size-12 event-meta'>
					{indicator()}

					<div style={{ marginTop: 4 }}>
						{sectionTitle != null
							? sectionTitle + (lesson.room != null ? ` (${lesson.room})` : '')
							: null}
					</div>
				</div>
				{lesson?.teamsMeeting != null ? (
					<div className='teams-meeting-indicator' style={{ width: 'auto' }}>
						<Icon name='Teams' />
					</div>
				) : null}
			</div>
		);
	}

	if (lesson?.length >= 40) {
		return (
			<div className='event-info' style={{ width: '100%' }}>
				{event.title}

				<div className='size-12 event-meta'>
					{lesson.attendanceReported || attendance != null || sectionTitle == null
						? indicator()
						: lesson.room != null
							? sectionTitle + (lesson.room != null ? ` (${lesson.room})` : '')
							: sectionTitle}
				</div>

				{lesson?.teamsMeeting != null ? (
					<div className='teams-meeting-indicator' style={{ width: 'auto' }}>
						<Icon name='Teams' />
					</div>
				) : null}
			</div>
		);
	}
	if (event.id == 'custom_button') {
		return (
			<TimeLineButton
				time={event.start}
				toggleTimeButton={props.toggleTimeButton}
				open={props.open}
			/>
		);
	}
	return (
		<div>
			{event.title}

			{lesson?.teamsMeeting != null ? (
				<div className='teams-meeting-indicator'>
					<Icon name='Teams' />
				</div>
			) : null}
		</div>
	);
};

export default ItemContent;
