import { getCourses } from 'actions/courses';
import { getSections } from 'actions/sections';
import { CardsContainer } from 'components/Cards/Card';
import Course from 'components/Presentation/Cards/Course';
import CourseForm from 'containers/Forms/CourseForm';
import Modal from 'containers/Modals/Modal';
import React, { Component } from 'react';
import { getRootUrl } from 'helpers/url';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPageTitle, setPageActions } from 'actions/header';
import LoadingCards from 'components/Cards/LoadingCard';
import User from '_class/User';

class Courses extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			isLoading: false,
		};
	}

	UNSAFE_componentWillMount = () => {
		const user = new User(this.props.currentUser);

		if (!(user.isCourseAdministrator() || user.isAdministrator())) {
			this.props.history.push(getRootUrl());
		}

		let actions = [];

		this.props.setPageTitle(this.props.translate('nav_courses'));

		actions.push({
			type: 'button',
			value: this.props.translate('Create'),
			onClick: this.openModal,
			icon: 'plus',
		});

		this.props.setPageActions(actions);
	}

	componentDidMount = () => {
		const user = new User(this.props.currentUser);

		if (user.isCourseAdministrator() || user.isAdministrator()) {
			this.setState({ isLoading: true })
			this.props.getCourses("CUSTOM_COURSE").then(() => {
				this.setState({ isLoading: false })
			});
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = (reload, skipPrompt) => {
		if (skipPrompt) {
			this.setState({ modalIsOpen: false });
		} else {
			this.setState({ modalIsOpen: false });
		}
	}

	render() {

		return (
			<div className="my_groups">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={this.props.translate('create-course')}>
					<CourseForm
						onClose={this.closeModal}
						editView={false}
					/>
				</Modal>

				{this.state.isLoading ?
					<LoadingCards count={3} />
					:
					<CardsContainer key="myCourses-1">
						{this.props.courses.map((course) => {
							return (
								<Course key={course.id} course={course} />
							);
						})}
					</CardsContainer>
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		courses: state.Courses.courses,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
	};
}

export default withRouter(connect(mapStateToProps, {
	getSections,
	getCourses,
	setPageTitle,
	setPageActions,
})(Courses));