import api from 'lib/api';

export const GET_DIRECTORY_CONTENTS = 'GET_DIRECTORY_CONTENTS';
export const SET_ACTIVE_DIRECTORY = 'SET_ACTIVE_DIRECTORY';
export const SET_SAVED_DIR_AS_ACTIVE = 'SET_SAVED_DIR_AS_ACTIVE';
export const GET_SECTION_DRIVES = 'GET_SECTION_DRIVES';
export const GET_SECTION_CONTENT = 'GET_SECTION_CONTENT';

export const getDirectoryContents = (id, callback) => {
	return [
		{
			type: SET_ACTIVE_DIRECTORY,
			folder: id
		},
		{
			type: GET_DIRECTORY_CONTENTS,
			payload: new Promise(resolve => {
				let url = 'users/me/onedrivefiles';
				if (typeof (id) !== 'undefined' && id !== null) {
					url = `users/me/onedrivefiles/folder/${id}`;
				}

				api.get(url).then((response) => {
					resolve(response.data);

					if (callback) {
						callback(response.data);
					}
				})
			})
		}
	]
}

export const getDrivesForSection = (id, callback) => {
	return [
		{
			type: GET_SECTION_DRIVES,
			payload: null,
		},
		{
			type: GET_SECTION_DRIVES,
			payload: new Promise(resolve => {
				api.get(`sections/${id}/drives`).then((response) => {
					resolve(response.data);

					if (callback) {
						callback(response.data);
					}
				});
			}),
		}
	]
}

export const getDriveSectionContent = (section, drive, folder, callback) => {
	return [
		{
			type: GET_SECTION_CONTENT,
			payload: null,
		},
		{
			type: SET_ACTIVE_DIRECTORY,
			folder: folder ? folder : drive ? drive : null,
			root: folder ? false : true,
		},
		{
			type: GET_SECTION_CONTENT,
			payload: new Promise(resolve => {
				let url = `sections/${section}/drives/${drive}`;
				if (typeof (folder) !== 'undefined' && folder !== null) {
					url = `sections/${section}/drives/${drive}/folder/${folder}`;
				}

				api.get(url).then((response) => {
					if (response.data.id == 'root') {
						if (folder) {
							response.data.id = folder;
						} else if (drive) {
							response.data.id = drive;
						} else {
							response.data.id = section;
						}
					}

					resolve(response.data);

					if (callback) {
						callback(response.data);
					}
				});
			}),
		}
	]
}

export const setDirectoryActive = (id, callback) => {
	return [
		{
			type: SET_ACTIVE_DIRECTORY,
			folder: id,
		},
		{
			type: SET_SAVED_DIR_AS_ACTIVE,
			folder: id,
		},
	]
}

export const setSectionContentActive = (drive, folder) => {
	return [
		{
			type: SET_ACTIVE_DIRECTORY,
			folder: folder ? folder : drive ? drive : null,
			root: folder ? false : true,
		},
		{
			type: SET_SAVED_DIR_AS_ACTIVE,
			folder: folder ? folder : drive ? drive : null,
		}
	]
}
