import React from 'react';

const AssignmentTaskStatusNotStarted = React.memo(() => {
	return (
		<svg
			id="Lager_1"
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<defs>
				<style>
					{`
                        .cls-1 {
                            fill: #d3d3d3;
                            stroke: #d3d3d3;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-width: 3.61px;
                        }
                    `}
				</style>
			</defs>
			<circle
				className="cls-1"
				cx="21.32"
				cy="21.43"
				r="17.86"
			/>
		</svg>
	);
});

export default AssignmentTaskStatusNotStarted;
