import React, { useState, useEffect } from 'react';

import { useTranslate } from 'lib/translate';
import Modal from 'containers/Modals/Modal';

import { Checkbox } from '@haldor/ui';
import { useSelector } from 'react-redux';

const InvalidGroupNotice = (props) => {
	const [open, setOpen] = useState(false);
	const [discard, setDiscard] = useState(false);

	const sections = useSelector(state => state.sections.educationGroups);
	const translate = useTranslate();

	useEffect(() => {
		if (localStorage.getItem('discard-invalid-groups-msg') != null && localStorage.getItem('discard-invalid-groups-msg') == 'yes') {
			return () => null;
		}

		if (sections != null) {
			let invalidGroup = sections.find(section =>
				section.archived == false && section.userRole == 'OWNER' && section.type == 'EDUCATION_GROUP' &&
				(section.classroomUrl == null || section.classroomUrl == '')
			);

			if (invalidGroup != null) {
				setOpen(true);
			}
		}
	}, [sections]);

	const onClose = () => {
		setOpen(false);

		if (discard) {
			localStorage.setItem('discard-invalid-groups-msg', 'yes');
		}
	}

	const onCheckboxChange = (checked) => {
		setDiscard(checked);
	}

	return <Modal type="small news" overridePrompt onClose={onClose} isOpen={open}>
		<div className="form">
			<div className="form-row">
				<div className="title">
					{translate('Workspace missing')}
				</div>

				<p style={{ marginTop: '1.55rem' }}>
					{translate('A configuration problem has occurred with one of your groups. This means that the group is missing a workspace in Sharepoint and no assignments or plans can be created.') + ' '}
					{translate('Please wait and try again later. If the problem persists, please contact your school for support.')}
				</p>

				<div style={{ margin: '2rem 0', marginBottom: '3rem' }}>
					<Checkbox
						onChange={onCheckboxChange}
						value={discard}
						label={translate('Do not show this message again')}
					/>
				</div>
			</div>
		</div>
	</Modal>
}

export default InvalidGroupNotice;