import api from 'lib/api';
import Moment from 'moment';

const MESSAGES_PER_PAGE = 50;

export const GET_CONVERSATIONS = 'CONVERSATIONS_GET_CONVERSATIONS';
export const GET_CONVERSATIONS_REFERENCE = 'CONVERSATIONS_GET_CONVERSATIONS_BY_REFERENCE';
export const GET_CONVERSATION = 'CONVERSATIONS_GET_CONVERSATION';
export const GET_ARCHIVED_CONVERSATIONS = 'CONVERSATIONS_GET_ARCHIVED_CONVERSATIONS';
export const RESET_CONVERSATION = 'CONVERSATIONS_RESET_CONVERSATION';
export const GET_CONVERSATION_MESSAGES = 'CONVERSATION_GET_CONVERSATION_MESSAGES';
export const GET_USER_CONTACTS = 'CONVERSATION_GET_USER_CONTACTS';
export const SEARCH_CONVERSATIONS = 'CONVERSATION_SEARCH_CONVERSATIONS';
export const CLEAR_SEARCH_CONVERSATIONS = 'CONVERSATION_CLEAR_SEARCH_CONVERSATIONS';
export const UPDATE_CONVERSATION = 'CONVERSATION_UPDATE_CONVERSATION';
export const ARCHIVE_CONVERSATION = 'CONVERSATION_ARCHIVE_CONVERSATION';
export const GET_USER_GUARDIAN = 'CONVERSATION_GET_USER_GUARDIAN';
export const FLAG_MESSAGE = 'CONVERSATION_FLAG_MESSAGE';
export const UPDATE_SEARCHED_CONVERSATIONS = 'UPDATE_SEARCHED_CONVERSATIONS';
export const STAGE_CONVERSATION = 'CONVERSATION_STAGE_NEW_CONVERSATION';
export const SUBMIT_CONVERSATION = 'CONVERSATION_SUBMIT_NEW_CONVERSATION';
export const SUBMIT_CONVERSATION_MESSAGE = 'CONVERSATION_SUBMIT_CONVERSATION_MESSAGE';

export const getConversations = () => {
	return {
		type: GET_CONVERSATIONS,
		payload: new Promise(resolve => {
			api.get(`conversations`).then((response) => {
				const sortItems = (a, b) => {
					if (a.messages.length > 0 && b.messages.length > 0)
						return Moment(b.messages[0].created).unix() - Moment(a.messages[0].created).unix();
					else
						return 0;
				};
				response.data.sort(sortItems);
				resolve(response.data);
			});
		}),
	};
}

export const getConversationsByReference = (referenceId, referenceType) => {
	return {
		type: GET_CONVERSATIONS_REFERENCE,
		payload: new Promise(resolve => {
			api.get(`conversations?referenceId=${referenceId}&referenceType=${referenceType}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const getArchivedConversations = () => {
	return {
		type: GET_ARCHIVED_CONVERSATIONS,
		payload: new Promise(resolve => {
			api.get(`conversations?archived=true`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const getConversation = (id) => {
	return {
		type: GET_CONVERSATION,
		payload: new Promise(resolve => {
			api.get(`conversations/${id}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const getMessages = (conversation, page) => {
	return {
		type: GET_CONVERSATION_MESSAGES,
		payload: new Promise(resolve => {
			let skip = page * MESSAGES_PER_PAGE;
			let top = skip + MESSAGES_PER_PAGE;

			api.get(`conversations/${conversation}/messages?skip=${skip}&top=${top}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const submitMessage = (message) => {
	return {
		type: SUBMIT_CONVERSATION_MESSAGE,
		payload: new Promise(resolve => {
			api.post('conversations/message', message).then((response) => {
				let data = {
					...message,
					id: response.data,
				};

				resolve(data);
			});
		}),
	}
}

export const createConversation = (conversation) => {
	return {
		type: SUBMIT_CONVERSATION,
		payload: new Promise(resolve => {
			if (conversation.id != null) {
				delete conversation.id;
			}

			api.post(`conversations`, conversation).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export const stageConversation = (conversation) => {
	return {
		type: STAGE_CONVERSATION,
		payload: new Promise(resolve => {
			resolve(conversation);
		}),
	}
}

export const getContacts = () => {
	return {
		type: GET_USER_CONTACTS,
		payload: new Promise(resolve => {
			api.get(`conversations/contacts`).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export const searchConversation = (query) => {
	return {
		type: SEARCH_CONVERSATIONS,
		payload: new Promise(resolve => {
			api.get(`conversations/?search=${query}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const clearSearch = () => {
	return {
		type: CLEAR_SEARCH_CONVERSATIONS,
		payload: true,
	};
}

export const resetConversation = () => {
	return {
		type: RESET_CONVERSATION,
		payload: true,
	};
}

export const archiveConversation = (conversation) => {
	return {
		type: ARCHIVE_CONVERSATION,
		payload: new Promise(resolve => {
			conversation.status = 'ARCHIVED';

			api.put(`conversations/`, conversation).then((response) => {
				resolve(conversation);
			});
		}),
	};
}

export const flagMessage = (id, reason) => {
	return {
		type: FLAG_MESSAGE,
		payload: new Promise(resolve => {
			const values = {
				'messageId': id,
				'reason': reason,
			};

			api.post(`conversations/flagged`, values).then((response) => {
				resolve({
					flagID: response.data,
					...values
				});
			});
		}),
	}
}

export function getUserGuardian(id) {
	return {
		type: GET_USER_GUARDIAN,
		payload: new Promise((resolve, reject) => {
			api.get(`conversations/contacts/${id}/guardians`).then((response) => {
				resolve({ userId: id, guardians: response.data });
			});
		}),
	};
}

export function updateSearchedConversations(results) {
	return {
		type: UPDATE_SEARCHED_CONVERSATIONS,
		payload: results
	};
}