import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Elements/Spinner';
import { Icon } from '@haldor/ui';
import './Search.scss';


class Search extends Component {

	constructor(props) {
		super(props);

		let value = '';
		if (props.input != null && props.input.value != null) {
			value = props.input.value;
		}

		this.state = {
			value,
		};
		
		this.timeout = null;
		this.autoSearchLimit = 200;
	}

	componentDidMount = () => {
		if (this.props.autofocus == true) {
			if (this.refs['input'] != null) {
				this.refs['input'].focus();
			}
		}
	}

	isValid = () => {
		if (this.state.value.length > 0) {
			if (this.state.value.trim().length == 0) {
				return false;
			}
		}

		return true;
	}

	throttle = (func, immediate) => {
		return (...args) => {
			var later = function () {
				this.timeout = null;
				if (!immediate) func.apply(this, args);
			};

			var callNow = immediate && !this.timeout;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(later, this.autoSearchLimit);
			if (callNow) func.apply(this, args);
		};
	}

	onSubmit = () => {
		const { input, onSubmit } = this.props;
		const value = this.getValue();

		if (!this.props.allowEmpty && value.query == '') {
			// Dont submit empty strings
			return false;
		}

		if (this.props.lengthLimit != null) {
			if (value.query.length < this.props.lengthLimit) {
				return false;
			}
		}

		if (input != null && input.onSubmit != null) {
			input.onSubmit(value);
		}

		if (onSubmit != null) {
			onSubmit(value);
		}

		if (this.props.clearOnSubmit) {
			this.setState({ value: '' });
		}
	}

	getValue = () => {
		let type = 'search';
		let query = this.state.value;
		let modifier = null;

		if (this.state.value.charAt(0) == '@') {
			type = 'picker';
			modifier = '@';
			query = this.state.value.substr(1);
		}

		if (this.state.value.charAt(0) == '#') {
			type = 'picker';
			modifier = '#';
			query = this.state.value.substr(1);
		}

		if (this.state.value.charAt(0) == '!') {
			type = 'picker';
			modifier = '!';
			query = this.state.value.substr(1);
		}

		return {
			type,
			modifier,
			query,
		};
	}

	onValueChange = (event) => {
		const { input, onChange } = this.props;

		if (this.props.onChange) {
			this.props.onChange(event.target.value)
		}
		
		this.setState({ value: event.target.value }, () => {
			if (!this.isValid()) {
				return;
			}

			if (this.getValue().type == 'picker') {
				let onSubmit = this.throttle(this.onSubmit);
				onSubmit();

				return;
			}

			if (this.props.autoSearch) {
				let onSubmit = this.throttle(this.onSubmit);
				onSubmit();

				return;
			}
		});

		if (input != null && input.onChange != null) {
			input.onChange(event.target.value);
		}

		if (onChange != null) {
			onChange(event.target.value);
		}
	}

	onKeyDown = (event) => {
		if (event.keyCode == 13) {
			event.preventDefault();
			this.onSubmit(this.state.value);
		}
	}

	onIconClick = () => {
		this.refs['input'].focus();
	}

	render() {
		return (
			<div className="search-container">
				<div className="search-box-container" onClick={this.onIconClick}>
					<div className="search-box-icon">
						{this.props.loading ?
							<Spinner small />
							:
							<Icon name="Magnify" />
						}
					</div>

					<input
						type="search"
						value={this.state.value}
						onChange={this.onValueChange}
						onKeyDown={this.onKeyDown}
						ref="input"
						placeholder={this.props.placeholder}
					/>
				</div>

				<div className="search-children">
					{this.props.children}
				</div>
			</div>
		);
	}

}

Search.defaultProps = {
	autoSearch: false,
	allowEmpty: false,
	clearOnSubmit: false,
	lengthLimit: null,
};

Search.propTypes = {
	autoSearch: PropTypes.bool,
	allowEmpty: PropTypes.bool,
	clearOnSubmit: PropTypes.bool,
	lengthLimit: PropTypes.number,
};

export default Search;
