import React, { Component } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import msal, { loginRequest, getUser, setToken } from 'lib/msal';

import { Spinner } from 'UI';
import { isMicrosoftTeams } from 'helpers/teams';

export default class AuthenticationClient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logged: false,
		};
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	parseHash = () => {
		var hash = window.location.hash.substr(1);

		var result = hash.split('&').reduce(function (res, item) {
			var parts = item.split('=');
			res[parts[0]] = parts[1];
			return res;
		}, {});

		return result;
	}

	componentDidMount = () => {
		console.log("auth CLIENT CONSENT onboarding", window.location);

		if (window.location.hash.indexOf('access_token') > -1) {
			var object = this.parseHash();
			msal.handleRedirectPromise().then((response) => {
				microsoftTeams.app.initialize().then(() => {
					microsoftTeams.app.notifyAppLoaded();
					microsoftTeams.authentication.notifySuccess(object);
				});
			})
				.catch((reason) => {
					microsoftTeams.app.initialize().then(() => {
						microsoftTeams.app.notifyAppLoaded();
						microsoftTeams.authentication.notifyFailure(reason);
					});
				});
		}

		if (window.location.search.indexOf('error') > -1) {
			if (!isMicrosoftTeams()) {
				window.opener.onPopupDataReceived("AUTHENTICATION:FAILED");
				window.close();
			}
		}
	}

	render() {
		return (
			<div>
				<Spinner center />
			</div>
		);
	}
}
