import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import Select from 'react-select';

import StudentAssessmentForm from 'containers/Forms/StudentAssessmentFormV1';
import Modal from 'containers/Modals/Modal';

/**
 * @param {string} props name - Input name
 * @param {string} props id - Input id
 * @param {Array<value, label, disabled, color>} props options
 * @param {function} props onChange
 *
 * @returns {JSX Component}
 */
class StudentAssessorSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.defaultValue ? this.props.defaultValue : null,
			disabled: this.props.disabled != null ? this.props.disabled : false,
			studentAssessmentModalIsOpen: false,
		};
	}

	UNSAFE_componentWillReceiveProps = (newProps) => {
		if (newProps.defaultValue !== this.props.defaultValue) {
			this.setState({ value: newProps.defaultValue });
		}
	}

	_onChange = (option, item) => {
		this.setState({ value: option.value });
		this.props.onChange(option.value, item);
	}

	showStudentAssessmentModal = () => {
		this.setState({ studentAssessmentModalIsOpen: true });
	}

	hideStudentAssessmentModal = () => {
		this.setState({ studentAssessmentModalIsOpen: false });
	}

	onStudentAssessmentSubmit = () => {
		this.setState({ studentAssessmentModalIsOpen: false });
	}

	render() {
		let options = this.props.options.map((assessor) => {
			if (assessor != undefined) {
				if (assessor.id != this.props.item.id) {
					const obj = {
						value: assessor.assignedTo,
						label: (
							<span className="simple-select__label">
								{assessor.groupName}
							</span>
						),
					};
					return obj;
				}
			}
		});

		options = options.filter(option => option != undefined);
		let value = options.find(option => option.value == this.state.value);

		return (
			this.props.item.isStudentAssessed ?
				<div>
					<Modal isOpen={this.state.studentAssessmentModalIsOpen} onClose={this.hideStudentAssessmentModal} title={this.props.translate('buddy-assessment')}>
						<StudentAssessmentForm
							item={this.props.item}
							items={this.props.options}
							knowledgeBlocks={this.props.planningBlocks}
							referenceType="assigments"
							statuses={this.state.statusOptions}
							onStatusChange={this.onAssessmentStatusChange}
							onSubmit={this.onStudentAssessmentSubmit}
							onAbort={this.hideStudentAssessmentModal}
							teacher
							section={this.props.section}
						/>
					</Modal>
					<span className="button button-target" onClick={() => { this.showStudentAssessmentModal(); }}>{this.props.translate('show-assessment')}</span>
				</div>
				:
				<Select
					menuPortalTarget={document.body}
					isDisabled={this.state.disabled}
					isSearchable={false}
					isClearable={false}
					name="studentAssesosr"
					value={value}
					options={options}
					onChange={event => this._onChange(event, this.props.item)}
					className="simple-select"
					placeholder={this.props.placeholder != null ? this.props.placeholder : ''}
				/>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {})(StudentAssessorSelect);

