import React from 'react';

const Close = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 13.01 13.01">
    <path
      style={{ fill: '#010101' }}
      d="M7.26,6.5l4.16-4.16c0.21-0.21,0.21-0.55,0-0.76c-0.21-0.21-0.55-0.21-0.76,0L6.5,5.74L2.34,1.58
			c-0.21-0.21-0.55-0.21-0.76,0c-0.21,0.21-0.21,0.55,0,0.76L5.74,6.5l-4.16,4.16c-0.21,0.21-0.21,0.55,0,0.76
			c0.1,0.1,0.24,0.16,0.38,0.16s0.27-0.05,0.38-0.16L6.5,7.26l4.16,4.16c0.1,0.1,0.24,0.16,0.38,0.16s0.27-0.05,0.38-0.16
			c0.21-0.21,0.21-0.55,0-0.76L7.26,6.5z"
    />
  </svg>
);

export default Close;
