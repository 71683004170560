import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import {
	createAdditionalAdjustment,
	updateAdditionalAdjustment,
	getSchoolAdjustments,
} from 'actions/additional_adjustments';

import { Editor } from 'UI/Inputs';
import { Button } from '@haldor/ui'

class CreateAdditionalAdjustmentForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			title: '',
			subTitle: '',
			description: ''
		};
	}

	onTitleChange = (event) => {
		this.setState({ title: event.target.value });
	}

	onSubTitleChange = (event) => {
		this.setState({ subTitle: event.target.value });
	}

	onDescriptionChange = (event) => {
		this.setState({ description: event.target.value });
	}

	onSubmit = (values) => {
		const { onClose } = this.props;
		return new Promise((resolve) => {
			if (this.props.initialValues == null) {
				this.props.createAdditionalAdjustment(values).then(() => {
					resolve(1);

					if (onClose) {
						onClose();
					}
				});
			} else {
				this.props.updateAdditionalAdjustment(values).then(() => {
					this.props.getSchoolAdjustments().then(() => {
						resolve(1);

						if (onClose) {
							onClose();
						}
					})
				});
			}
		});
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ margintop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ margintop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;

		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	render() {
		const { submitting, handleSubmit, valid } = this.props;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.onSubmit)} className="form form-component">
					<div className="form-row textarea">
						<label>{this.props.translate('title')} *</label>

						<Field
							component={this.renderInput}
							type="text"
							name="title"
							validate={this.required}
							placeholder={this.props.translate('title')}
						/>
					</div>

					<div className="form-row textarea">
						<label>{this.props.translate('description')}</label>

						<Field
							component={Editor}
							name="description"
							placeholder={this.props.translate('description')}
							translate={this.props.translate}
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row submit spacing">
						<Button disabled={submitting || !valid}>
							{this.props.translate('save')}
						</Button>

						<div className="clearfix" />
					</div>
				</form>

			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		initialValues: ownProps.editAdjustment
	}
}

export default connect(mapStateToProps, {
	createAdditionalAdjustment,
	updateAdditionalAdjustment,
	getSchoolAdjustments,
})(reduxForm({
	form: 'CreateAdditionalAdjustmentForm',
	destroyOnUnmount: true,
})(CreateAdditionalAdjustmentForm));