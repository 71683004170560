import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from 'containers/Modals/Modal';
import GroupAssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import AssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';

import { Button, Icon, translate } from '@haldor/ui';

class CreateAssignment extends Component {

	constructor(props) {
		super(props);

		this.state = {
			regular: false,
			group: false,
		}
	}

	toggleRegular = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({ regular: !this.state.regular });
	}

	toggleGroup = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({ group: !this.state.group });
	}

	onAssignmentCreated = (created) => {
		if (created) {
			this.props.onCreated(this.props.createdAssignments);
		}

		this.setState({ regular: !this.state.regular });
	}

	onGroupAssignmentCreate = (assignment) => {
		if (assignment != null) {
			this.props.onCreated([assignment]);
		}

		this.setState({ group: !this.state.group });
	}

	render() {
		if (!this.props.services.haldor) {
			// Shouldnt be able to create assignments if customer doesnt have service for haldor assignments
			return null;
		}

		let section = null;

		if (this.props.selectedSections != null && this.props.selectedSections.length > 0) {
			section = this.props.selectedSections[0];
		} else {
			section = this.props.activeSection;
		}

		if (section == null) {
			return null;
		}

		return (
			<div style={{ display: 'inline-block', marginLeft: '.55rem' }}>
				<Modal
					isOpen={this.state.regular}
					onClose={this.toggleRegular}
					title={this.props.translate('task-create-button')}
				>
					<AssignmentForm
						onAbort={this.onAssignmentCreated}
						editView={false}
						cloneView={false}
						groupid={section.id}
						section={section}
						disablePlanSelector={true}
						planningAssessments={this.props.planningAssessments}
						existingPlanningAssessments={true}
					/>
				</Modal>

				<Modal
					isOpen={this.state.group}
					onClose={this.toggleGroup}
					title={this.props.translate('create-group-assignment')}
				>
					<GroupAssignmentForm
						editView={false}
						cloneView={false}
						groupid={section.id}
						onAbort={() => this.setState({ group: false })}
						onCreated={this.onGroupAssignmentCreate}
						disablePlanSelector={true}
						existingPlanningAssessments={true}
						planningAssessments={this.props.planningAssessments}
					/>
				</Modal>

				<div style={{ display: 'flex' }}>
					<Button onClick={this.toggleRegular}>
						<Icon name="Plus" />
						{this.props.translate('Create assignment')}
					</Button>

					<Button style={{ marginLeft: '.55rem' }} onClick={this.toggleGroup}>
						<Icon name="Plus" />
						{this.props.translate('Create group assignment')}
					</Button>
				</div>
			</div>
		)
	}

}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		createdAssignments: state.assignments.newAssignments,
		createdGroupAssignment: state.assignments.newAssignment,
		activeSection: state.sections.activeSection,
		selectedSections: state.sections.selected,
	}
}

export default connect(mapStateToProps)(CreateAssignment);