import ColorScale from '_class/ColorScale';
import React, { Component } from 'react';
import '../../Verdict.scss';

class VerdictDisplayLevel extends Component {

	render() {
		if (this.props.scale == null) {
			return null;
		}

		const verdictIndexColors = new ColorScale(this.props.scale.levels.length).get();

		return (
			<div className="verdict verdict-level-display">
				<div className="verdict-cells">
					{this.props.scale.levels.map(cell => {
						let styles = {};

						if (this.props.verdict != null) {
							if (cell.id == this.props.verdict.scaleLevelId) {
								styles = {
									backgroundColor: cell.colorCode != null ?
										cell.colorCode
										: verdictIndexColors[cell.level],
									color: 'white',
								};
							}
						}

						return <div key={'verdict-cell-display-' + cell.id} style={styles} className="cell">
							{cell.localizationText != null ? cell.localizationText : cell.level}
						</div>
					})}
				</div>
			</div>
		);
	}

}

export default VerdictDisplayLevel;
