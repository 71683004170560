import React, { Component } from 'react';

/**
 * ProgressBar UI Component
 *
 * @param {string} fillColor - CSS color code for the bar color
 * @param {int} percentage - How much progress has been made? Decides the width of the fill
 * @param {object} style - Custom inline style on the container
 *
 * @class ProgressBar
 * @extends {Component}
 *
 * @return {JSX-Component}
 */
class ProgressBar extends Component {
	render() {
		const { fillColor, percentage, style } = this.props;

		return (
			<div className="progress-bar" style={{ ...style }}>
				<div className="progress-bar-fill" style={
					{ width: percentage ? percentage + '%' : 0,
					backgroundColor: fillColor }
				} />
			</div>
		);
	}
}

export default ProgressBar;
