import {
	CLEAR_NOTIFICATIONS_FROM_STORE,
	GET_NOTIFICATIONS,
	GET_USER_UNREAD_NOTIFICATIONS,
	GET_NOTIFICATION_SETTINGS,
} from 'actions/notifications';

const INITIAL_STATE = {
	notifications: [],
	notificationSettings: [],
	userNotificationSettings: [],
	userUnread: null,
};

export default function (state, action) {
	const { type, payload } = action;

	if (typeof (state) === 'undefined') {
		return INITIAL_STATE;
	}

	switch (type) {
		case GET_NOTIFICATIONS:
			if (typeof (payload) === 'undefined' && payload === null) {
				return state;
			}

			// Order notifications by the id property in a DESC order
			payload.map(referenceType => {
				if (typeof (referenceType.notifications) !== 'undefined' && Array.isArray(referenceType.notifications)) {
					referenceType.notifications.sort(function (a, b) {
						return b.id - a.id;
					});
				}
			});

			return { ...state, notifications: payload };

		case CLEAR_NOTIFICATIONS_FROM_STORE:
			return { ...state, notifications: [] };

		case GET_NOTIFICATION_SETTINGS:
			return { ...state, notificationSettings: action.payload };

		case GET_USER_UNREAD_NOTIFICATIONS:
			return { ...state, userUnread: action.payload };

		default:
			return state;
	}
}