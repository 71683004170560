import React, { Component } from 'react';
import { Person } from 'UI';
import '../../Verdict.scss';

class VerdictDisplayFeedback extends Component {

	render() {
		let feedback = '';
		if (this.props.verdict != null) {
			feedback = this.props.verdict.feedback;
		} else if (this.props.feedback != null) {
			feedback = this.props.feedback;
		} else if (this.props.value != null) {
			feedback = this.props.value;
		}

		if (feedback != null && feedback != '') {
			feedback = feedback.replace(/(\r\n|\r|\n)/g, '<br />');
		}

		return (
			<div className="verdict verdict-feedback-display">
				<div className="text">
					{this.props.user != null ?
						<Person person={this.props.user}>
							<span
								dangerouslySetInnerHTML={{ __html: feedback }}
							/>
						</Person>
						:
						<span
							dangerouslySetInnerHTML={{ __html: feedback }}
						/>
					}
				</div>
			</div>
		);
	}

}

export default VerdictDisplayFeedback;
