import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateTime from '_class/DateTime';


class Date extends Component {
	constructor(props) {
		super(props);
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	formatDate() {
		let dateTime = new DateTime(this.props.children);

		return this.capitalizeFirstLetter(dateTime.getLongDate());
	}

	render() {
		return (
			<time className="date" dateTime={this.props.children}>
				{this.formatDate()}
			</time>
		);
	}
}

function mapStateToProps(state) {
	return {

	};
}

export default connect(mapStateToProps)(Date);
