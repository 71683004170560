import React, { useState, useEffect } from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';
import { Icon } from '@haldor/ui';

import './_phoneInput.scss';

const PhoneInput = ({ placeholder, disabled, input, meta, countryCode }) => {
	let defaultValue = input?.value ?? '';
	if (defaultValue.length > 0) {
		defaultValue = defaultValue.replace('+', '');
	}

	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (input != null && input.onChange != null) {
			input.onChange(value);
		}
	}, [value])

	const onInputFocus = () => {
		if (value == '') {
			if (countryCode != null) {
				setValue(getCountryCallingCode(countryCode));
			} else {
				setValue('46');
			}
		}
	}

	const clearInput = () => {
		setValue('');
	}

	return <div className="input--phone">
		<div className="input-container">
			<span>+</span>
			<input
				type="text"
				placeholder={placeholder}
				style={meta.touched && meta.error ? {
					border: '1px solid red'
				} : {}}
				disabled={disabled}
				{...input}

				value={value}
				onChange={(event) => setValue(event.target.value)}
				onFocus={onInputFocus}
			/>

			{value != '' ?
				<div className="clear-button" onClick={clearInput}>
					<Icon name="Close" />
				</div>
				: null}
		</div>

		{meta.touched && ((meta.error &&
			<span style={{ marginTop: '1rem', color: 'red', }}>{meta.error}</span>) || (meta.warning &&
				<span style={{ marginTop: '1rem', color: 'red', }}>{meta.warning}</span>))
		}
	</div>

}

export default PhoneInput;