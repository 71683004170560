import React from 'react';
import DataObject from './index.js';

class ColorScale extends DataObject {

	colors = [
		'#f55d6e', // Not achieved
		'#1bd308', // Lowest
		'#1ede34',
		'#23de96',
		'#12d3ad',
		'#2bbbc1', // Middle
		'#179bbc',
		'#1e87b5',
		'#1e6fb5',
		'#0b5ca8', // Highest
	];

	get(addRed = true) {
		let size = this.data;
		if (addRed) {
			size = size - 1;
		}

		let result = [];

		if (size == 1) {
			result = [
				this.colors[1],
			];
		}

		if (size == 2) {
			result = [
				this.colors[1],
				this.colors[9],
			];
		}

		if (size == 3) {
			result = [
				this.colors[1],
				this.colors[5],
				this.colors[9],
			];
		}

		if (size == 4) {
			result = [
				this.colors[1],
				this.colors[5],
				this.colors[7],
				this.colors[9],
			];
		}

		if (size == 5) {
			result = [
				this.colors[1],
				this.colors[3],
				this.colors[5],
				this.colors[7],
				this.colors[9],
			];
		}

		if (size == 6) {
			result = [
				this.colors[1],
				this.colors[3],
				this.colors[5],
				this.colors[6],
				this.colors[8],
				this.colors[9],
			];
		}

		if (size == 7) {
			result = [
				this.colors[1],
				this.colors[3],
				this.colors[4],
				this.colors[5],
				this.colors[6],
				this.colors[8],
				this.colors[9],
			];
		}

		if (size == 8) {
			result = [
				this.colors[1],
				this.colors[2],
				this.colors[3],
				this.colors[4],
				this.colors[5],
				this.colors[6],
				this.colors[8],
				this.colors[9],
			];
		}

		if (size == 9) {
			result = [
				this.colors[1],
				this.colors[2],
				this.colors[3],
				this.colors[4],
				this.colors[5],
				this.colors[6],
				this.colors[7],
				this.colors[8],
				this.colors[9],
			];
		}

		if (size > 9) {
			result = [
				this.colors[1],
				this.colors[2],
				this.colors[3],
				this.colors[4],
				this.colors[5],
				this.colors[6],
				this.colors[7],
				this.colors[8],
				this.colors[9],
			];

			let needed = size - 9;
			for (let i = 0; i < needed; i++) {
				result.push(this.colors[9]);
			}
		}

		if (addRed) {
			result.unshift(this.colors[0]);
		}

		return result;
	}

	getAll() {
		return this.colors;
	}

}

export default ColorScale;
