import { getActiveLanguage, setActiveLanguage } from '@haldor/ui';


export const languages = () => {
	const languages = [
		{
			svg_src: '/dist/svg/se.svg',
			label: 'Svenska',
			lcid: 'sv-SE',
		},
		{
			svg_src: '/dist/svg/gb.svg',
			label: 'English',
			lcid: 'en-US',
		},
		{
			svg_src: '/dist/svg/no.svg',
			label: 'Norsk',
			lcid: 'nb-NO',
		},
		{
			svg_src: '/dist/svg/dk.svg',
			label: 'Dansk',
			lcid: 'da-DK',
		},
		{
			svg_src: '/dist/svg/uk.svg',
			label: 'Український',
			lcid: 'uk',
		},
	];

	return languages;
}


export const getLanguage = () => {
	const languagesArray = languages();
	let activeLanguage = languagesArray.find(x => x.lcid.toLowerCase() == getActiveLanguage().toLowerCase());

	return activeLanguage;
};


export const setLanguage = (lcid, user, history) => {
	setActiveLanguage(lcid);
	
	if (user != null) {
		user.lcid = lcid;
	}
	if (history != null){
		history.push('/language');
	}
}