import {
	ADD_MATRIX,
	GET_MATRIX
} from '../actions/matrix';


const INITIAL_STATE = {
	matrix: null,
	matrixList: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADD_MATRIX:
            let matrixList = state.matrixList;
            let object = state.matrixList.find(t => t.id == action.payload.id);
            if (object == null) {
                matrixList.push(action.payload);
            } else {
                matrixList.forEach((item, i) => { if (item.id == action.payload.id) a[i] = action.payload; });
            }
			return { ...state, matrixList };
		case GET_MATRIX:
			return { ...state, matrix: action.payload };
        default:
            return state;
    }
}
