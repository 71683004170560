import { getSection, getSectionByGraphId } from "actions/sections";
import SectionTitle from 'components/Presentation/SectionTitle';
import SectionForm from 'containers/Forms/SectionForm';
import SectionProgress from 'containers/Progress/Section';
import TeacherSectionProgress from 'containers/Progress/TeacherSection';
import { isUserTeacher } from 'helpers/user';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import { setPageTitle, setPageActions } from 'actions/header';
import Modal from 'containers/Modals/Modal';
import * as microsoftTeams from "@microsoft/teams-js";

class MyAssessments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sectionId: null,
			noSectionFound: false,
			teamsContext: null,
			isLoading: false,
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageActions([{
			type: 'icon',
			onClick: this.toggleGroupSettings,
			icon: 'cog_bw',
		}]);

		this.props.setPageTitle(this.props.translate('assessments-header-overview'));
	}

	componentDidMount = () => {
		this.initComponent();
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.sections.length == 0 && nextProps.sections.length > 0) {
			this.setState({ isLoading: true });
			this.loadContext();
		}
	}

	toggleGroupSettings = (shouldReload) => {
		this.setState({ groupForm: !this.state.groupForm });

		if (shouldReload == true) {
			microsoftTeams.app.getContext().then((context) => {
				this.props.getSectionByGraphId(context.team.groupId);
			});
		}
	}

	initComponent = () => {
		const { group, sections } = this.props;

		if (sections != null && sections.length > 0) {
			if (group == null) {
				this.setState({ isLoading: true });
				this.loadContext();
			}
		} else {
			microsoftTeams.app.getContext().then((teamsContext) => {
				this.setState({ isLoading: false, teamsContext });
			});
		}
	}

	loadContext() {
		microsoftTeams.app.getContext().then((context) => {
			const { group } = this.props;

			let section = this.props.sections.find(section => section.graphId == context.team.groupId);

			if (section != null) {
				this.setState({ sectionId: section.id, noSectionFound: false, teamsContext: context });

				if (this.props.group != null) {
					this.setState({ isLoading: false });
				} else {
					this.props.getSection(section.id).then(() => {
						this.setState({ isLoading: false });
					});
				}
			} else {
				this.setState({ noSectionFound: true, teamsContext: context, isLoading: false });
			}
		});
	}

	renderStudentView() {
		return (
			<div id="section-assessment" className="single-section form">
				<SectionProgress
					groupId={this.props.group.id}
					userId={this.props.currentUser.id}
				/>
			</div>
		);
	}

	teamsTabCloseForm = () => {
		this.setState({ noSectionFound: false }, () => {
			window.location = window.location.href;
		})
	}

	renderContent = () => {
		const isTeacher = isUserTeacher(this.props.currentUser);

		return (
			<div>
				{this.props.group != null ?
					<Modal isOpen={this.state.groupForm} onClose={this.toggleGroupSettings} title={this.props.group.title}>
						<SectionForm
							onAbort={this.toggleGroupSettings}
							editView
							section={this.props.group}
						/>
					</Modal>
					: null}

				{this.props.group.students && this.props.group.students.length > 0 && isTeacher ?
					<div id="section-pupils" className="single-section form">
						<TeacherSectionProgress
							groupId={this.props.group.id}
						/>
					</div>
					: null}

				{this.state.isLoading == false && isTeacher == false && this.state.noSectionFound == false ?
					this.renderStudentView()
					: null}
			</div>
		)
	}

	render() {
		if (this.state.isLoading) {
			return <Spinner center />
		}

		const isTeacher = isUserTeacher(this.props.currentUser);

		return (
			<div className="container my_assessments">
				{this.props.group != null ? this.renderContent() : null}

				{this.state.noSectionFound == true && isTeacher ?
					<SectionForm
						editView={false}
						teamsContext={this.state.teamsContext}
						teamsTabCloseForm={this.teamsTabCloseForm}
						tab="assessments"
						onboarding={true}
					/>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		sections: state.sections.educationGroups,
		group: state.sections.activeSection,
		graphSection: state.sections.graphSection,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getSection,
	setPageTitle,
	setPageActions,
	getSectionByGraphId,
})(MyAssessments);
