import {
	GET_SUBJECTS,
	GET_TENANT_SUBJECTS,
	SEARCH_SUBJECTS,
	CREATE_SUBJECT,
	GET_SECTION_SUBJECTS,
	CLEAR_SECTION_SUBJECTS,
} from 'actions/subjects';

const INITIAL_STATE = {
	subjects: null,
	tenant: null,
	search: null,
	created: null,
	section: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_TENANT_SUBJECTS:
			return { ...state, tenant: action.payload };

		case GET_SUBJECTS:
			return { ...state, subjects: action.payload };

		case SEARCH_SUBJECTS:
			return { ...state, search: action.payload };

		case CREATE_SUBJECT:
			return { ...state, created: action.payload };

		case GET_SECTION_SUBJECTS:
			return { ...state, section: action.payload };
		case CLEAR_SECTION_SUBJECTS:
			return { ...state, section: null };
		default:
			return state;
	}
};
