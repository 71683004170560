import React, { useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { useForm } from 'react-final-form';
import { Checkbox, Expandable } from '@haldor/ui';

const BlockCoreContentField = ({ input, ...props }) => {
	let values = [];
	if (input.value != null && input.value != "") {
		values = JSON.parse(JSON.stringify(input.value));
	}

	const forbiddenText = "Undervisningen i kursen ska behandla följande centrala innehåll:";
	const form = useForm();
	const currentRows = useRef([]);
	const { assessmentBlockPartRows, courses } = useMemo(() => {
		const value = form.getFieldState('assessmentBlocks')?.value;
		if (value == null || value.length == 0) {
			return {
				courses: [],
				assessmentBlockPartRows: [],
			};
		}

		let courses = [];
		let blockPartRows = [];
		const blocks = value.filter((b) => b.type === 'Haldor.Blocks.AssessmentBlock');

		blocks.forEach((items) => {
			const resources = items.resources.filter((s) =>
				s["@odata.type"] === 'Haldor.Blocks.AssessmentBlockPart' && s.referenceType === 'matrix'
			);

			if (resources) {
				resources.forEach((resource) => {
					let course = JSON.parse(JSON.stringify(resource));
					let inCourses = courses.find((r) => r.value?.id == course.value?.id);
					if (inCourses == null) {
						let details = course?.value?.details.filter((x) =>
							x.endDate == null || moment(x.endDate).isAfter(moment())
						);
						course.value.details = details;
						courses.push(course.value);
					}

					resource.assessmentBlockPartRows.forEach((blockPartRow) => {
						let relations = [];
						resource.value.details.forEach((detail) => {
							detail.matrixRelationships.forEach((relationship) => {
								if (relationship.matrixRowId == blockPartRow.referenceId && blockPartRow.referenceType == 'matrixRow') {
									relations.push(relationship);
								}
							});
						});

						blockPartRows = [...blockPartRows, {
							...blockPartRow,
							relations,
						}];
					});
				});
			}
		});

		return {
			courses: courses,
			assessmentBlockPartRows: blockPartRows,
		};
	}, [form.getFieldState('assessmentBlocks')?.value]);

	/**
	 * @param {object} row
	 * @param {object} aspect
	 * @param {number} courseDetailsId
	 * @returns {object} coreContent
	 */
	const _generateCoreContent = (row, aspect, courseDetailsId) => {
		return {
			courseDetailsId,
			title: null,
			aspects: [
				{
					title: null,
					id: aspect.id,
					aspectRows: [{
						id: row.id,
						text: null,
					}],
				}
			],
		}
	}

	/**
	 * @param {object} row
	 * @param {object} aspect
	 * @param {number} courseDetailsId
	 * @param {boolean} force
	 */
	const toggleRow = (row, aspect, courseDetailsId, force = false) => {
		if (values.length == 0) {
			values.push({
				referenceType: props.referenceType,
				referenceId: props.referenceId,
				coreContents: [_generateCoreContent(row, aspect, courseDetailsId)],
			});

			input.onChange(values);
			return true;
		}

		const isSelected = isRowSelected(row);

		values.map((value) => {
			let foundCoreContent = value.coreContents?.find((coreContent) =>
				coreContent.courseDetailsId == courseDetailsId
			);

			if (foundCoreContent == null) {
				if (value.coreContents == null) {
					value.coreContents = [];
				}

				value.coreContents.push(_generateCoreContent(row, aspect, courseDetailsId));
				return value;
			}

			foundCoreContent.aspects = foundCoreContent.aspects.map((aspect) => {
				if (isSelected) {
					if (!force) {
						aspect.aspectRows = aspect.aspectRows.filter((_row) => _row.id != row.id);
					}
				} else {
					aspect.aspectRows.push(({
						id: row.id,
						text: null,
					}));
				}

				return aspect;
			});

			return value;
		});

		input.onChange(values);
	};

	/**
	 * @param {object} row
	 * @returns {boolean} selected
	 */
	const isRowSelected = (row) => {
		if (input.value == null || input.value == "") {
			return false;
		}

		const inValues = input.value.find((value) => {
			return value.coreContents?.find((coreContent) => {
				return coreContent.aspects.find((aspect) => {
					return aspect.aspectRows.find((_row) => _row.id == row.id);
				});
			});
		});

		return inValues != null;
	}

	useEffect(() => {
		if (currentRows.current.length !== assessmentBlockPartRows?.length) {
			currentRows.current = assessmentBlockPartRows;

			assessmentBlockPartRows.forEach((blockPartRow) => {
				blockPartRow.relations.forEach((relation) => {
					courses.forEach((course) => {
						const coreContents = course.details[0]?.coreContent;

						if (coreContents == null) {
							return null;
						}

						coreContents.forEach((coreContent) => {
							coreContent.aspects.forEach((aspect) => {
								const aspectRow = aspect.aspectRows.find((aspectRow) =>
									aspectRow.id == relation.aspectRowId
								);

								if (aspectRow != null) {
									toggleRow(aspectRow, aspect, course.details[0].id, true);
								}
							});
						});
					});
				});
			});
		}
	}, [assessmentBlockPartRows]);

	return (
		<div className="core-content">
			{courses.length > 0 ?
				courses.map((course) => {
					const coreContents = course.details[0]?.coreContent;

					if (coreContents == null) {
						return null;
					}

					return <Expandable
						title={course.title + " " + (course.year != null ? course.year : "") + " (" + course.courseCode + ") "}
						key={course.id}
						color={course.colorCode}
					>
						{coreContents.map((coreContent) => (
							<div key={coreContent.id}>
								<div>
									<h2>{coreContent.title}</h2>
								</div>

								{coreContent.aspects.map((aspect) => (
									<div key={'corecontent-aspects-' + aspect.id} className="aspects">
										<h3>{aspect.title != forbiddenText ? aspect.title : ''}</h3>

										{aspect.aspectRows.map((row) => {
											return <div key={'aspect-row-' + row.id} className="aspect-row">
												<Checkbox
													label={row.text}
													value={isRowSelected(row)}
													onChange={() => toggleRow(row, aspect, course.details[0].id)}
												/>
											</div>
										})}
									</div>
								))}
							</div>
						))}
					</Expandable>
				})
				: null}
		</div>
	);
}

BlockCoreContentField.defaultProps = {
	referenceId: 0,
	referenceType: "Planning",
};

export default BlockCoreContentField;