export const ASSIGNMENT_TAB = 'ASSIGNMENT_TAB';
export const PLANNING_TAB = 'PLANNING_TAB';
export const ASSESSMENT_TAB = 'ASSESSMENT_TAB';
export const ATTENDANCE_TAB = 'ATTENDANCE_TAB';
export const COURSE_PLANNING_TAB = 'COURSE_PLANNING_TAB';
export const HALDOR_EDUCATION_TAB = 'HALDOR_EDUCATION_TAB';

export const getTeamsTab = () => {
	if (window.location.pathname.indexOf('assignments-tab') > -1) {
		return ASSIGNMENT_TAB;
	} else if (window.location.pathname.indexOf('planning-tab') > -1) {
		return PLANNING_TAB;
	} else if (window.location.pathname.indexOf('assessments-tab') > -1) {
		return ASSESSMENT_TAB;
	} else if (window.location.pathname.indexOf('attendance-tab') > -1) {
		return ATTENDANCE_TAB;
	} else if (window.location.pathname.indexOf('haldor-tab') > -1) {
		return COURSE_PLANNING_TAB;
	} else {
		return HALDOR_EDUCATION_TAB;
	}
};
