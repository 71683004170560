import React, { Component } from 'react';
import DateTime from '_class/DateTime';

class FromNow extends Component {

	icon() {
		return (
			<svg
				id="Lager_1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				x="0px"
				y="0px"
				xmlSpace="preserve"
				viewBox="0 0 12 12"
			>
				<style type="text/css">
					{'.st0{fill:none;stroke:#1D1D1B;stroke-width:1.2756;stroke-linecap:round;stroke-miterlimit:10;}'}
				</style>

				<g>
					<circle className="st0" cx="6" cy="6.7" r="4.4" />
					<line className="st0" x1="6" y1="3.9" x2="6" y2="6.7" />
					<line className="st0" x1="6" y1="2.1" x2="6" y2="1.1" />
					<line className="st0" x1="5.1" y1="0.8" x2="6.9" y2="0.8" />
					<line className="st0" x1="6" y1="6.7" x2="7.3" y2="5.6" />
					<line className="st0" x1="9.4" y1="3.8" x2="10.1" y2="3.2" />
				</g>
			</svg>
		);
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	formatDate = () => {
		let dateTime = new DateTime(this.props.children);

		if (this.props.utc) {
			return this.capitalizeFirstLetter(dateTime.getFromNow());
		}

		return this.capitalizeFirstLetter(dateTime.getFromNow());
	}

	render() {
		let containerProps = { ...this.props };
		delete containerProps.children;
		delete containerProps.dateTime;

		return (
			<time className="from-now card-meta" dateTime={this.props.children} {...containerProps}>
				{this.icon()}
				{this.formatDate()}
			</time>
		);
	}
}

export default FromNow;
