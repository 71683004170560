import React, { Component } from 'react';
import { translate, Flex, Checkbox, Button } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { getServices, setSelectedServices, createTenantServices, updateTenantServices, updateTenantProducts, saveSelectedProductsAsNotes, getTenantProducts } from '../../../actions/services';
import { getTenant, userAdminCheck } from '../../../actions/user';
import { Spinner } from 'UI';
import { appInsights } from 'lib/appInsights';

class ServiceSelector extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			selectedServices: []
		};
	}

	findIfProductMatchWithTenantServices(product, tenantServices) {
		var test = product.services.every(element => {
			var tenantService = tenantServices.find(service => { return service.serviceId === element.id });

			if (!tenantService) {
				return false;
			}

			return true;
		});

		return test;
	}

	checkIfTenantHasServices() {
		var selectedServices = [];

		if (this.props.products != null && this.props.tenantProducts != null) {
			this.props.products.forEach(product => {
				if (product.default) {
					selectedServices.push(product);
					this.props.change(product.name, true);
				} else {
					var found = this.props.tenantProducts.find(element => { return element.id == product.id });

					if (found) {
						product.default = true;
						selectedServices.push(product);
						this.props.change(product.name, true);
					}
				}
			});
		} else if (this.props.products != null && this.props.tenant.tenantServices != null) {
			this.props.products.forEach(product => {
				if (product.default) {
					selectedServices.push(product);
					this.props.change(product.name, true);
				} else {
					var found = this.findIfProductMatchWithTenantServices(product, this.props.tenant.tenantServices);

					if (found) {
						product.default = true;
						selectedServices.push(product);
						this.props.change(product.name, true);
					}
				}
			});
		} else if (this.props.products != null && this.props.tenant.tenantServices == null) {
			this.props.products.forEach(product => {
				if (product.default) {
					selectedServices.push(product);
					this.props.change(product.name, true);
				}
			});
		}

		this.props.setSelectedServices(selectedServices).then(() => {
			this.setState({ isLoading: false });
		});
	}

	componentDidMount() {
		console.log("Did mount", this.props.tenantProducts);
		appInsights.trackTrace({ message: "Onboarding | Showing Service Selector Page" });

		let promises = [this.props.getTenant()];

		Promise.all(promises).then(() => {
			if (this.props.services != null && this.props.services.length > 0) {
				this.checkIfTenantHasServices();
			} else {
				this.props.getServices().then(() => {
					this.checkIfTenantHasServices();
				});
			}
		});
	}

	submit = (values) => {
		return new Promise(resolve => {
			const keys = Object.keys(values)
			var selectedProducts = [];

			keys.forEach(title => {
				var found = this.props.products.find(element => {
					return element.name === title;
				});

				if (found && values[title]) {
					selectedProducts.push(found);
				}
			});

			this.props.setSelectedServices(selectedProducts);

			appInsights.trackTrace({ message: "Onboarding | Collecting all selected services" }, {
				services: selectedProducts,
				tenant: this.props.tenant
			});

			console.log("tenant", this.props.tenant);

			if (this.props.tenant != null) {
				var tenantServices = [];

				selectedProducts.forEach(selectedService => {
					selectedService.services.forEach(element => {
						if (!tenantServices.find(service => { return service.ServiceId == element.id })) {
							tenantServices.push({ ServiceId: element.id });
						}
					});
				});

				appInsights.trackTrace({ message: "Onboarding | trying update tenantProducts" }, {
					services: tenantServices,
					tenant: this.props.tenant,
					products: selectedProducts
				});

				// We need to store products on tenant level
				this.props.updateTenantProducts(selectedProducts).then(() => {
					this.props.getTenantProducts();

					appInsights.trackTrace({ message: "Onboarding | trying update tenantServices" }, {
						services: tenantServices,
						tenant: this.props.tenant
					});

					// This is importanted since our system is depended on services
					this.props.createTenantServices(tenantServices).then(() => {
						this.props.saveSelectedProductsAsNotes(false);
						this.props.getTenant();
					});
				});
			} else {
				appInsights.trackException({ exception: new Error('Onboarding | No tenant was found to save selected products') });
			}

			if (this.props.onSubmit) {
				this.props.onSubmit(1)
					.then(() => {
						resolve(1);
					});
			}
		});
	}

	render() {
		return (
			<div>
				{this.state.isLoading ? <Spinner center /> : <div>
					<h2 style={{ textAlign: 'left' }}>{this.props.translate('Welcome to Haldor')}!</h2>
					<p style={{ textAlign: 'left', marginTop: '1.55rem', marginBottom: '1.55rem' }}>{this.props.translate('Start by selecting the services you want to use. If you want to use more services in the future, you can easily add them later.')}</p>

					<div style={{ minHeight: '15rem', width: '15rem', marginBottom: '2rem' }}>
						<img src="/dist/svg/illustration_steg01.svg" />
					</div>

					<div className="form-container" style={{ marginTop: '0.75rem' }}>
						<form onSubmit={this.props.handleSubmit(this.submit)} className="form form-component">

							{this.props.products && this.props.products.length > 0 ? <div>
								{this.props.products.map((element, index) => {
									return <div key={index} className="form-row" style={{ marginTop: '0.45rem' }}>
										<Field
											disabled={element.default}
											component={Checkbox}
											name={element.name}
											label={this.props.translate(element.name)}
										/>
									</div>
								})}
							</div> : null}

							<div className="form-row spacing submit">
								<Button type="save">
									{this.props.translate('Continue')}
								</Button>
							</div>
						</form>
					</div>
				</div>}
			</div>
		);
	}
}


function mapStateToProps(state, ownProps) {
	let values = {};

	state.Services.selectedServices.forEach(element => {
		values[element.title] = true;
	})

	return {
		currentUser: state.user.currentUser,
		products: state.Services.availableProducts,
		tenantProducts: state.Services.tenantProducts,
		tenant: state.user.tenant,
		translate: translate(state.Languages.translations),
		initialValues: values
	};
}

export default connect(mapStateToProps, {
	getServices,
	setSelectedServices,
	userAdminCheck,
	updateTenantServices,
	saveSelectedProductsAsNotes,
	updateTenantProducts,
	getTenantProducts,
	getTenant,
	createTenantServices,
	change
})(reduxForm({
	form: 'AddService',
	destroyOnUnmount: true,
})(ServiceSelector));
