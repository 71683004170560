import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTypes } from 'actions/status';
import { setUser, getMe, getTenant } from 'actions/user';
import { getEducationGroups } from 'actions/sections';
import { getUser } from 'lib/msal';

import { getActiveLanguage, translate } from '@haldor/ui';

// Import routes
import PrintAssignment from 'containers/PrintPages/PrintAssignment';
import PrintPlan from 'containers/PrintPages/PrintPlanning';
import { isMicrosoftTeams } from 'helpers/teams';

class PrintApp extends Component {
	componentDidMount() {
		if (this.props.languages != null) {
			const lang = getActiveLanguage();

			if (lang == 'sv-se') {
				this.props.getTypes("sv-SE").then(() => {
				});
			} else if (lang == 'da-dk') {
				this.props.getTypes("da-DK").then(() => {
				});
			} else if (lang == 'nb-no') {
				this.props.getTypes("nb-no");
			} else if (lang == 'uk') {
				this.props.getTypes("uk");
			} else {
				this.props.getTypes("en-US").then(() => {
				});
			}
		}

		this.props.getEducationGroups();

		const user = getUser();

		this.props.setUser(user);
		this.props.getMe();
		this.props.getTenant();

		const isFrame = isMicrosoftTeams();
		if (isFrame) {
			this.setState({ background: '#f5f5f5' });
		}
	}

	render() {
		return (
			<div className="app-comp-content content">
				<Switch>
					<Route path="/print/assignment/:assignmentId" component={PrintAssignment} />
					<Route path="/print/assignments-tab/:assignmentId" component={PrintAssignment} />

					<Route path="/print/plan/:planId" component={PrintPlan} />
					<Route path="/print/planning-tab/:planId" component={PrintPlan} />
				</Switch>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
	languages: state.Languages.languages,
});

const mapDispatchToProps = {
	getTypes,
	setUser,
	getEducationGroups,
	getMe,
	getTenant,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintApp);
