import {
	CREATE_NOTE,
	GET_NOTES_BY_REFERENCE,
	GET_MENTOR_NOTES_BY_REFERENCE,
	RESET_ACTIVE_NOTE,
	SET_ACTIVE_NOTE,
	REMOVE_NOTE_BY_ID,
} from 'actions/notes';

const INITIAL_STATE = {
	notes: [],
	mentorNotes: [],
	active: null,
	new: null,
};

function removeNote(state = [], id) {
	if (id == null) {
		return state;
	}

	let newState = [...state];
	newState = newState.filter(note => {
		return note.id != id;
	});

	return newState;
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_NOTES_BY_REFERENCE:
			return { ...state, notes: action.payload };

		case GET_MENTOR_NOTES_BY_REFERENCE:
			return { ...state, mentorNotes: action.payload };

		case SET_ACTIVE_NOTE:
			return { ...state, active: action.payload };

		case RESET_ACTIVE_NOTE:
			return { ...state, active: INITIAL_STATE.active };

		case CREATE_NOTE:
			return { ...state, new: action.payload };

		case REMOVE_NOTE_BY_ID:
			return {
				...state,
				mentorNotes: removeNote(state.mentorNotes, action.payload),
				notes: removeNote(state.notes, action.payload)
			};

		default:
			return state;
	}
};
