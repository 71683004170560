import api from 'lib/api';

export const GROUP_INFO = 'GROUP_INFO';
export const USER_INFO_BY_ID = 'USER_INFO_BY_ID';

export const getUserInfoById = (userid, callback) => ({
	type: USER_INFO_BY_ID,
	payload: new Promise((resolve) => {
		api.get(`users/${userid}/`).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const getGroupInfoById = (id, callback) => ({
	type: GROUP_INFO,
	payload: new Promise((resolve) => {
		api.get(`groups/${id}/`).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});
