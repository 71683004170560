import React, { Component } from 'react';
import Groups from 'containers/Overview/Sections/Groups';

class GroupsPage extends Component {

	render() {
		return (
			<div className="overview">
				<Groups />
			</div>
		);
	}
}

export default GroupsPage;
