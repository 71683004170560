import moment from 'moment';
import DataObject from './index.js';

class DateTime extends DataObject {

	constructor(props) {
		super(props);

		let locale = localStorage.getItem('language');

		if (locale != null) {
			if (locale == 'sv') {
				locale = 'sv-se';
				localStorage.setItem("language", "sv-se");
			} else if (locale == 'en') {
				locale = 'en-us';
				localStorage.setItem("language", "en-us");
			} else if (locale == 'nb') {
				locale = 'nb-no';
				localStorage.setItem("language", "nb-no");
			} else if (locale == 'nn') {
				locale = 'nn-no';
				localStorage.setItem("language", "nn-no");
			}

			moment.locale(locale);
		}

		if (this.data != null) {
			if (this.data.constructor.name == 'DateTime') {
				this.data = moment.utc(this.data.getTimeStamp());
			} else {
				this.data = moment.utc(this.data);
			}
		} else {
			this.data = moment.utc();
		}
	}

	getMoment = () => {
		return this.data;
	}

	set = (arg1, arg2) => {
		this.data = this.data.set(arg1, arg2);
		return this;
	}

	add = (...args) => {
		this.data = this.data.add(...args);
		return this;
	}

	clone = () => {
		return this.data.clone();
	}

	subtract = (...args) => {
		this.data = this.data.subtract(args);
		return this;
	}

	getUtc = () => {
		this.data = moment.utc(this.data);
		return this;
	}

	getLongDate = () => {
		return this.data.local().format('LL');
	}

	getLongDateWithTime = () => {
		return this.data.local().format('LLL');
	}

	getDateStamp = () => {
		return this.data.local().format('YYYY-MM-DD');
	}

	getTimeStamp = () => {
		return this.data.local().format('YYYY-MM-DD LTS');
	}

	getHourStamp = () => {
		return this.data.local().format('YYYY-MM-DD LT');
	}

	getTimeStampHours = () => {
		return this.data.local().format('YYYY-MM-DD LT');
	}

	getTime = () => {
		return this.data.local().format('LT');
	}

	getShortDate = (includeTime = false) => {
		if (includeTime) {
			return this.data.local().format('D MMM - LT');
		}

		return this.data.local().format('D MMM');
	}

	getDay = () => {
		return this.data.local().format('dddd');
	}

	getDateString = () => {
		return this.data.local().format('dddd D MMMM');
	}

	getFromNow = () => {
		return this.data.local().fromNow();
	}

	format = (format) => {
		return this.data.local().format(format);
	}

	isSame = (date) => {
		return this.data.isSame(date);
	}

	getUTCTimeStamp = () => {
		return this.data.format('YYYY-MM-DDTHH:mm:ss');
	}

	endOf = (period) => {
		this.data = this.data.endOf(period);
		return this;
	}

}

export default DateTime;
