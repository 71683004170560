/**
 * @typedef {import('types').HaldorEnvironment} HaldorEnvironment
 */

const environments = require('../../app-config/_environments.json');

/**
 * @returns HaldorEnvironment
 */
const getEnvironment = () => {
	/** @type {HaldorEnvironment} environment */
	const environment = environments.find(environment =>
		environment.domains.indexOf(window.location.host) > -1
	);

	return environment;
}

export default getEnvironment;