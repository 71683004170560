import { fetch_preschoolgoals, search_preschoolgoals } from 'actions/preschoolform';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Expandable } from 'UI';
import { Checkbox } from '@haldor/ui';

class SearchPreschoolGoal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedGoals: [],
			groups: [],
		}
	}

	componentDidMount = () => {
		if (this.props.preschoolGoals.length < 1) {
			this.props.fetch_preschoolgoals();
		}

		if (this.props.selectedGoals && this.props.selectedGoals.length > 0) {
			this.setState({ selectedGoals: this.props.selectedGoals }, () => {
				if (this.props.onChange != null) {
					this.props.onChange(this.state.selectedGoals);
				}
			});
		}

		this.generateGroups(this.props);
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.generateGroups(nextProps);

		if (nextProps.selectedGoals) {
			if (nextProps.selectedGoals != this.state.selectedGoals) {
				this.setState({ selectedGoals: nextProps.selectedGoals });
			}
		}
	}

	generateGroups = (props) => {
		let { groups } = this.state;

		// Check if we have preschoolgoals and no groups
		if (props.preschoolGoals.length > 0 && groups.length < 1) {
			props.preschoolGoals.map(goal => {
				if (!groups.find(group => { return group.title === goal.group })) {
					groups.push({ title: goal.group, color: goal.colorCode });
				}
			});

			this.setState({ groups });
		}
	}

	onGoalSelect = (goal) => {
		let selectedGoals = this.state.selectedGoals;

		const inArray = selectedGoals.find(function (sG) {
			return sG.goalId == goal.id;
		});

		if (!inArray) {
			selectedGoals.push({ goalId: goal.id });
		} else {
			let index = selectedGoals.findIndex(function (pG) {
				return pG.goalId == goal.id;
			});

			selectedGoals.splice(index, 1);
		}

		this.setState({ selectedGoals }, () => {
			if (this.props.onChange) {
				this.props.onChange(selectedGoals);
			}
		});
	}

	render() {
		const { preschoolGoals, handleSubmit, change, pristine, reset, submitting, valid } = this.props;
		const { selectedGoals, groups } = this.state;

		return (
			<div className="form-container preschoolgoals-container">
				{groups && preschoolGoals ?
					groups.map(group => {
						return (
							<Expandable
								headerStyles={{ backgroundColor: group.color }}
								title={group.title}
								key={'select-' + group.title}
								whiteBackground>
								{preschoolGoals.map(goal => {
									let isChecked = selectedGoals.find(function (sg) {
										return sg.goalId == goal.id;
									});

									if (typeof (isChecked) != 'undefined') {
										isChecked = true;
									} else {
										isChecked = false;
									}

									return (group.title === goal.group ?
										<div key={'select-goal-' + goal.title} className="preschoolgoal-item" data-tooltip={goal.text}>
											<Checkbox
												value={isChecked}
												onChange={e => { this.onGoalSelect(goal) }}
												label={goal.title} />
										</div>
										: null);
								})}
							</Expandable>
						)
					})
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ fetch_preschoolgoals, search_preschoolgoals }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPreschoolGoal);
