import React, { Component } from 'react';
import DateTime from '_class/DateTime';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { searchGroups, clearSearch } from 'actions/search';
import { getGroupAbsenceForToday, getMentorGroups, getArchievedMentorGroups } from 'actions/sections';
import { setPageTitle } from 'actions/header';

import LoadingCards from 'components/Cards/LoadingCard';

import { Expandable } from 'UI';
import { Search as SearchInput } from 'UI/Inputs';
import { Card, Icon, Flex } from '@haldor/ui';
import { getDescription } from 'helpers/content';

class MentorGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expand: false,
			isLoadingSections: false,
		};
	}

	componentDidMount = () => {
		this.props.setPageTitle(this.props.translate('mentor-header-overview'));

		if (this.props.mentorGroups == null || this.props.mentorGroups.length == 0) {
			this.setState({ isLoadingSections: true });
			this.props.getMentorGroups().then(() => {
				if (this.props.mentorGroups != null && this.props.mentorGroups.length > 0) {
					this.props.mentorGroups.forEach((group) => {
						if (group.absences == null && group.currentUserRole == 'OWNER') {
							this.props.getGroupAbsenceForToday(group.id, encodeURIComponent(moment().format()), encodeURIComponent(moment().add(1, 'd').format()));
						}
					});
				}
			});
		}

		if (this.props.mentorArchivedGroups == null || this.props.mentorArchivedGroups.length == 0) {
			this.props.getArchievedMentorGroups().then(() => {
				this.setState({ isLoadingSections: false });
			});
		}
	}

	toggleExpand = () => this.setState({ expand: !this.state.expand });

	renderFoldButton = () => {
		return <Flex end>
			<div className={`all-fold-button color--meta ${this.state.expand ? 'open' : 'closed'}`} onClick={this.toggleExpand}>
				<Icon name="ArrowLeft" />

				{this.state.expand ?
					this.props.translate('Hide description')
					:
					this.props.translate('Show description')
				}
			</div>
		</Flex>
	}

	onSearch = async (values) => {
		if (values.query == null || values.query == '') {
			this.props.clearSearch();
			return true;
		}

		if (values.query.length < 3) {
			return false;
		}

		this.setState({ loading: true });
		const filter = 'LINKED;MENTOR_GROUP';
		await this.props.searchGroups({
			query: values.query,
			filter: filter,
		});

		this.setState({ loading: false });
	}

	renderGroup = (group, index) => {
		let foundInvalid = [];
		let foundValid = [];

		if (group.absences != null && group.absences.length > 0) {
			group.absences.forEach((absence) => {
				let inInvalid = foundInvalid.find(student => {
					return student.userId == absence.user.userId;
				});

				if (absence.type == 'INVALID_ABSENCE') {
					if (inInvalid == null) {
						foundInvalid.push(absence.user);
					}
				}
			})

			group.absences.forEach((absence) => {
				let inInvalid = foundInvalid.find(student => {
					return student.userId == absence.user.userId;
				});

				let inValid = foundValid.find(student => {
					return student.userId == absence.user.userId;
				});

				if (absence.type == 'VALID_ABSENCE') {
					if (inInvalid == null && inValid == null) {
						foundValid.push(absence.user);
					}
				}
			})
		}

		let cardTitle = (
			<div className="group-header">
				<span className="header-title">
					{group.title}
				</span>

				{foundInvalid.length > 0 ?
					<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#f25252' }}>
						{foundInvalid.length + ' ' + this.props.translate('absent').toLowerCase()}
					</div>
					: null}

				{foundValid.length > 0 ?
					<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#ff883e' }}>
						{foundValid.length + ' ' + this.props.translate('valid-absent').toLowerCase()}
					</div>
					: null}
			</div>
		);

		return <Card element={Link} to={`/groups/${group.id}`} style={{ marginBottom: '0.75rem' }} key={'group-' + group.id + '-' + index}>
			<div style={{ flex: 1 }}>
				<Expandable title={cardTitle} open={this.state.expand}>
					<Flex top space>
						<div className="description size-14">
							{this.cutDescription(group.description)}
						</div>

						<div className="size-14">
							{group.events != null ?
								group.events.map((event, index) => {
									return <Flex key={index}>
										<div className="event-date">{new DateTime(event.created).getDateStamp()}</div>

										<div className="event-text">
											{event.createdBy.firstName + ' ' + event.createdBy.lastName + ' ' + this.props.translate(event.event)}
										</div>
									</Flex>
								})
								: null}
						</div>
					</Flex>
				</Expandable>

				<div className="meta-container" style={{ marginTop: '.5rem' }}>
					{group.archived ?
						<div className="card-meta" style={{ marginRight: '.75rem' }}>
							{this.props.translate('Archived')}
						</div>
						: null}

					{group.currentUserRole == 'OWNER' ?
						<div className="card-meta" style={{ marginRight: '.75rem' }}>
							{this.props.translate('Owner')}
						</div>
						:
						<div className="card-meta" style={{ marginRight: '.75rem' }}>
							{this.props.translate('Member')}
						</div>
					}
				</div>
			</div>
		</Card>
	}

	renderFilters = () => {
		return <div className="filter-container">
			<div className="form">
				<div className="form-row">
					<div className="form-row-half">
						<SearchInput
							placeholder={this.props.translate('Search group')}
							onSubmit={this.onSearch}
							allowEmpty
						/>
					</div>

					<div className="clearfix"></div>
				</div>
			</div>
		</div>
	}

	GroupList = () => {
		if (this.props.searchResults != null && this.props.searchResults.length > 0) {
			var anyMentorGroups = this.props.searchResults.filter(function (group) {
				return group.type == 'MENTOR_GROUP';
			});
			if (anyMentorGroups.length > 0) {
				return <div>
					{anyMentorGroups.map(this.renderGroup)}
				</div>
			}
		}

		let groups = JSON.parse(JSON.stringify(this.props.mentorGroups));

		if (groups != null) {
			groups = groups.filter(group =>
				group.currentUserRole == 'OWNER'
			);

			return (
				<div>
					{groups.map(this.renderGroup)}
				</div>
			);
		}
	}

	ArchivedGroupList = () => {
		let groups = JSON.parse(JSON.stringify(this.props.mentorArchivedGroups));

		if (groups != null) {
			groups = groups.filter(group =>
				group.currentUserRole == 'OWNER'
			);

			return (
				<div>
					{groups.map(this.renderGroup)}
				</div>
			);
		}
	}

	noGroupsFound = () => {
		return (
			<div>
				<h3 style={{ textAlign: 'center' }}>
					{this.props.translate('no-sections-found')}
				</h3>
			</div>
		);
	}

	cutDescription = (description) => {
		if (description == null) {
			return '';
		}

		let strippedDescription = getDescription(description).replace(/(<([^>]+)>)/ig, '');
		if (strippedDescription.length > 99) {
			return strippedDescription.slice(0, 100) + '…';
		}

		return strippedDescription;
	}

	render() {
		return (
			<div className="page groups">
				{this.renderFilters()}
				{this.renderFoldButton()}

				{this.GroupList()}

				{this.props.mentorArchivedGroups.length > 0 ?
					this.ArchivedGroupList()
					: null}

				{this.state.isLoadingSections ?
					<LoadingCards count={3} />
					: null}

				{!this.state.isLoadingSections && this.props.mentorGroups != null && this.props.mentorGroups.length == 0 ?
					this.noGroupsFound()
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		mentorGroups: state.sections.mentorGroups,
		mentorArchivedGroups: state.sections.mentorArchievedGroups,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		searchResults: state.Search.groups,
	};
}

export default connect(mapStateToProps, {
	getMentorGroups,
	getArchievedMentorGroups,
	getGroupAbsenceForToday,
	setPageTitle,
	searchGroups,
	clearSearch,
})(MentorGroups);