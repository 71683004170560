import React, { Component } from 'react';

import './Person.scss';

class Person extends Component {

	/* Class setup */
	static defaultProps = {
		person: {
			firstName: '',
			lastName: '',
			email: '',
			id: '',
		},
		showName: true,
		showAvatar: true,
		size: 'sm',
		showEmail: false,
	};

	/* Utils */
	getName = () => {
		var name = '';

		if (this.props.person != null && this.props.person.firstName != null) {
			name = this.props.person.firstName;
		}

		if (this.props.person != null && this.props.person.lastName != null) {
			name = name.length > 0 ? name + ' ' + this.props.person.lastName : this.props.person.lastName;
		}

		return name;
	}

	getInitials = () => {
		var name = this.getName();

		if (name != null && name.length > 0) {
			var nameSplit = name.split(' ');

			if (nameSplit.length > 1)
				return nameSplit[0].charAt(0).toUpperCase() + nameSplit[nameSplit.length - 1].charAt(0).toUpperCase();

			if (nameSplit.length == 1)
				return nameSplit[0].charAt(0).toUpperCase();
		} else {
			return '';
		}
	}

	/* Events */
	// Empty

	/* Render methods */
	render() {
		return (
			<div className="person-container" style={{ display: 'inline-block', ...this.props.style }}>
				<div className={"person size--" + this.props.size}>
					<div className="avatar">
						{this.getInitials()}
					</div>

					<div className="name">
						{this.getName()}
						<div style={{color: "#9F9F9F", fontWeight: "normal", fontSize: "0.75em"}}>
							{this.props.showEmail && this.props.person.email ? this.props.person.email : ""}
						</div>
					</div>
				</div>

				{this.props.children ?
					<div className={"person-children size--" + this.props.size}>
						{this.props.children}
					</div>
					: null}
			</div>
		);
	}

}

export default Person;
