import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Checkbox, Flex, Tabs, Tab, Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';

import { Spinner, Expandable } from 'UI';

import DateTime from '_class/DateTime';
import User from '_class/User';

import { getDescription } from 'helpers/content';

import { getAllUserSections } from 'actions/user';

class SectionList extends Component {
	constructor(props) {
		super(props);

		let filters = ['education', 'archived'];
		let sort = 'TITLE';
		const user = new User(props.currentUser);

		if (localStorage.getItem('staff-group-filters') != null) {
			filters = JSON.parse(localStorage.getItem('staff-group-filters'));
		}

		this.state = {
			expand: false,
			loading: false,
			selectedGroup: null,
			loadingGroups: [],

			page: 1,
			sort,
			filters,
			user
		};
	}
	componentDidMount = () => {
		this.getSections();
	}

	getFilter = () => {
		let filter = '';
		if (this.state.filters.includes('mentor')) {
			filter += 'MENTOR_GROUP;';
		}

		if (this.state.filters.includes('education')) {
			filter += 'EDUCATION_GROUP;';
		}

		if (this.state.filters.includes('groups_owned_by_me')) {
			filter += 'OWNED_BY_ME;';
		}

		if (!this.state.filters.includes('archived')) {
			filter += 'ACTIVE;';
		}

		return filter;
	}

	getSections = () => {
		this.setState({ loading: true });
		let filter = this.getFilter();
		if (this.state.user.isAdministrator()) {
			this.props.getAllUserSections(this.props.userId, {
				pageIndex: this.state.page,
				filter: filter,
				sortBy: this.state.sort,
			})
				.then(() => {
					this.setState({ loading: false });
				});
		}
	}

	getNextPage = async () => {
		await this.setState({ page: this.state.page + 1 });
		this.getSections();
	}

	noGroupsFound = () => {
		return (
			<div>
				<h3 style={{ textAlign: 'center' }}>
					{this.props.translate('no-sections-found')}
				</h3>
			</div>
		);
	}

	cutDescription = (description) => {
		if (description == null) {
			return '';
		}

		let strippedDescription = getDescription(description).replace(/(<([^>]+)>)/ig, '');
		if (strippedDescription.length > 99) {
			return strippedDescription.slice(0, 100) + '…';
		}

		return strippedDescription;
	}

	toggleFilter = async (filter) => {
		let filters = this.state.filters;
		if (filters.includes(filter)) {
			filters = filters.filter(f => f != filter);
		} else {
			filters.push(filter);
		}

		await this.setState({ filters, page: 1 }, () => {
			localStorage.setItem('staff-group-filters', JSON.stringify(this.state.filters));
		});

		this.getSections();
	}

	renderGroupResult = () => {
		let { groups } = this.props;
		let filter = this.getFilter();
		let showResult = filter.includes('MENTOR_GROUP') || filter.includes('EDUCATION_GROUP') || filter.includes('UNLINKED');
		groups = groups?.filter(el => {
			return el.id != 0;
		})

		return (<div>
			{(!this.state.loading && groups != null && groups.length == 0) || !showResult ?
				this.noGroupsFound()
				: null}


			{groups != null && groups.length > 0 && showResult ?
				groups.map(this.renderGroup)
				: null}
		</div>)
	}


	renderGroup = (group, index) => {
		const user = new User(this.props.currentUser);
		const loading = this.state.loadingGroups.indexOf(group.id) > -1;
		let events = group.events;
		let element = Link;
		if (group.id == 0) {
			element = null;
		}

		if (this.state.filters == null || this.state.filters.length == 0) {
			return null;
		}

		if (events != null) {
			events = events.filter(e => e.event == 'Group connected' || e.event == 'Group archived' || e.event == 'Group recovered from archiving');
		}

		let cardTitle = (
			<div className="group-header">
				<span className="header-title">
					{group.title}
				</span>

				{!user.isStudent() ? group.id != 0 ?
					<>
						<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#19db6c' }}>
							{this.props.translate('Connected')}
						</div>
						{group.lastSynced != null ?
							<div className="card-meta" style={{ marginLeft: '.75rem' }}>
								<Icon name="Sync" />
								{`${new DateTime(group.lastSynced).getDateStamp()}, ${new DateTime(group.lastSynced).getTime()}`}
							</div>
							: null}
					</>
					:
					<div className="card-meta outline" style={{ marginLeft: '.75rem', borderColor: '#efc24c' }}>
						{this.props.translate('Not connected')}
					</div>
					: null}

				{group.archived ?
					<div className="card-meta" style={{ marginLeft: '.75rem' }}>
						{this.props.translate('Archived')}
					</div>
					: null}
			</div>
		);

		return <Card element={element} to={`/groups/${group.id}`} style={{ marginBottom: '0.75rem' }} key={'group-' + group.id + '-' + index}>
			<div style={{ flex: 1 }}>

				<Expandable title={cardTitle} open={this.state.expand}>
					<Flex top space>
						<div className="description size-14">
							{this.cutDescription(group.description)}
						</div>

						{!user.isStudent() ?
							<div className="size-14">
								{events != null ?
									events.map((event, index) => {
										return <Flex key={index}>
											<div className="event-date">{new DateTime(event.created).getDateStamp()}</div>

											<div className="event-text">
												{this.props.translate(event.event) + ' ' + event.createdBy.firstName + ' ' + event.createdBy.lastName}
											</div>
										</Flex>
									})
									: null}
							</div>
							: null}
					</Flex>
				</Expandable>

				{!user.isStudent() ?
					<div className="meta-container" style={{ marginTop: '.5rem' }}>
						{group.type != null ?
							<div className="card-meta" style={{ marginRight: '.75rem' }}>
								{this.props.translate(group.type)}
							</div>
							: null}

						{group.userRole == 'OWNER' ?
							<div className="card-meta" style={{ marginRight: '.75rem' }}>
								{this.props.translate('Owner')}
							</div>
							:
							<div className="card-meta" style={{ marginRight: '.75rem' }}>
								{this.props.translate('Member')}
							</div>
						}
					</div>
					: null}
			</div>
		</Card>
	}

	toggleExpand = () => this.setState({ expand: !this.state.expand });

	renderFoldButton = () => {
		return <Flex end>
			<div className={`all-fold-button color--meta ${this.state.expand ? 'open' : 'closed'}`} onClick={this.toggleExpand}>
				<Icon name="ArrowLeft" />

				{this.state.expand ?
					this.props.translate('Hide description')
					:
					this.props.translate('Show description')
				}
			</div>
		</Flex>
	}

	renderFilters = () => {
		const user = new User(this.props.currentUser);

		return <div className="filter-container">
			<div className="form">
				<div className="form-row">
					<div className="form-row-half last">
						<div style={{ marginTop: '1rem', float: 'right' }}>
							<Fragment>
								<Checkbox
									label={this.props.translate('Education groups')}
									value={this.state.filters.indexOf('education') > -1}
									onChange={() => this.toggleFilter('education')}
								/>

								<Checkbox
									label={this.props.translate('Mentor groups')}
									value={this.state.filters.indexOf('mentor') > -1}
									onChange={() => this.toggleFilter('mentor')}
								/>
							</Fragment>

							{this.state.filters.indexOf('mentor') > -1 || this.state.filters.indexOf('education') > -1 ?
								<Checkbox
									label={this.props.translate('Show archived')}
									value={this.state.filters.indexOf('archived') > -1}
									onChange={() => this.toggleFilter('archived')}
								/>
								: null}
						</div>
					</div>

					<div className="clearfix" />
				</div>
			</div>
		</div>
	}

	renderGroupList = () => {
		return (
			<div>
				{this.state.loading ?
					<Spinner center />
					:
					<>
						{this.renderFoldButton()}

						{this.renderGroupResult()}
					</>
				}
			</div>
		);
	}

	render() {
		const user = new User(this.props.currentUser);
		let filter = this.getFilter();

		let showResult = filter.length > 0;

		return (
			<div className="page groups">
				{!user.isStudent() ?
					this.renderFilters()
					: null}

				{this.renderGroupList()}

				{(this.state.searchQuery == null && this.props.groups?.length % 20 < 1 && this.props.groups?.length > 0 && this.state.filters.length > 0 && showResult) ?
					<div className="df jcc" style={{ marginTop: '2rem' }}>
						<Button type="secondary" onClick={this.getNextPage}>
							{this.state.loading ?
								<Spinner />
								: null}

							{this.props.translate('Show more')}
						</Button>
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		groups: state.user.userSections,
	};
}

export default connect(mapStateToProps, { getAllUserSections })(SectionList);