import React, { Component } from 'react';

export default class Alert extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: this.props.hasOwnProperty('type') ? this.props.type : '',
		};
	}

	render() {
		return (
			<div
				key={`notifications_alert_${this.props.id}`}
				className={`card alert alert-${this.props.type}`}
			>
				{this.props.children}
			</div>
		);
	}
}
