import { clearSectionDocumentsFromStore, getDocumentsBySectionId } from 'actions/documents';
import { getFileUrl } from 'actions/files';
import { clearSectionPostsFromStore, getPostsBySectionId } from 'actions/posts';
import { fetch_preschoolgoals } from 'actions/preschoolform';
import LoadingCards from 'components/Cards/LoadingCard';
import Date from 'components/Presentation/Date/Date';
import SectionTitle from 'components/Presentation/SectionTitle';
import { PRESCHOOL } from 'constants/schoolTypes';
import Documentation from 'containers/Forms/Documentation';
import Post from 'containers/Forms/Post';
import Modal from 'containers/Modals/Modal';
import { objectsAreSame } from 'helpers/array';
import { isUserTeacher } from 'helpers/user';
import { getDescription } from 'helpers/content';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Block, { BlockWrapper } from 'UI/Elements/Block';
import { Button, Icon, TooltipMenu } from '@haldor/ui';
import FromNow from 'components/Presentation/Date/FromNow';

class SectionPosts extends Component {
	static defaultProps = {
		limit: 3,
	};

	constructor(props) {
		super(props);

		this.state = {
			documentModal: false,
			eventModal: false,
			loadingDocuments: false,
			loadingPosts: false,
			appending: false,
			limitReached: false,
			currentLength: 0,
		};
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { currentLength } = this.state;

		if (!objectsAreSame(nextProps.combined, this.props.combined)) {
			// Only check if the combined has changed
			const received = nextProps.combined.length - currentLength;

			if (received < 14) {
				this.setState({ limitReached: true, currentLength: nextProps.combined.length });
			} else {
				this.setState({ limitReached: false, currentLength: nextProps.combined.length });
			}
		}
	}

	componentWillUnmount = () => {
		this.props.clearSectionDocumentsFromStore();
		this.props.clearSectionPostsFromStore();
	}

	componentDidMount = () => {
		let isPreschool = false;

		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		if (isPreschool) {
			this.setState({ loadingDocuments: true });

			this.props.clearSectionDocumentsFromStore();
			this.props.getDocumentsBySectionId(this.props.groupid, 1).then(() => {
				this.setState({ loadingDocuments: false });
			});

			if (this.props.preschoolGoals.length < 1) {
				this.props.fetch_preschoolgoals();
			}
		}

		this.setState({ loadingPosts: true });
		this.props.clearSectionPostsFromStore();
		this.props.getPostsBySectionId(this.props.groupid, 1).then(() => {
			this.setState({ loadingPosts: false });
		});

		if (this.props.combined.length > 0) {
			this.setState({ currentLength: this.props.combined.length });
		}
	}

	toggleDocumentModal = () => {
		this.setState({ documentModal: !this.state.documentModal });
	}

	toggleEventModal = () => {
		this.setState({ eventModal: !this.state.eventModal });
	}

	renderSectionContent = () => {
		const { combined, limit } = this.props;

		if (combined.length > 0) {
			return (
				<BlockWrapper cols={limit ? 3 : 1}>
					{combined ? combined.map((document, index) => {
						if (limit) {
							if (index < limit) {
								return this.renderDocument(document)
							} else {
								return null
							}
						}

						return this.renderDocument(document)
					}) : null}
				</BlockWrapper>
			);
		} else {
			return (
				<div className="color--meta weight--bold text--center">
					{this.props.translate('no-items-found')}
				</div>
			);
		}
	}

	headerContent = (document) => {
		return (
			<div>
				<div style={{ float: 'left' }}>
					{document.author != null ?
						typeof document.author == 'string' ?
							document.author
							:
							`${document.author.firstName != null ? document.author.firstName : ''} ${document.author.lastName != null ? document.author.lastName : ''}`
						: ' '}
				</div>

				<div style={{ float: 'right' }}>
					{document.published != null ?
						Moment.utc(document.published).isAfter(Moment()) ?
							<FromNow>{Moment.utc(document.published).local().format()}</FromNow>
							:
							<Date>{Moment.utc(document.published).local()}</Date>
						: null}
				</div>

				{document.status == 'POST_DRAFT' || document.status == 'DOCUMENTATION_DRAFT' ?
					<div style={{ float: 'right', marginRight: 8 }}>
						<span style={{ color: 'red', fontSize: '0.9em' }}>
							{this.props.translate('Draft')}
						</span>
					</div>
					: null}

				{document.important && document.eventDate != null && Moment() <= Moment(document.eventDate) ?
					<div style={{ float: 'right' }}>
						<span style={{ color: 'red', fontSize: '0.9em', marginRight: '8px' }}>
							{this.props.translate('important!')}
						</span>
					</div>
					: null}

				<div className="clearfix" />
			</div>
		);
	}

	content = (document) => {
		if (document.ReferenceType == 'post') {

			let strippedDescription = "";
			if (document.abbreviatedDescription != null) {
				strippedDescription = getDescription(document.abbreviatedDescription).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}
			}
			return (
				<div>
					<h3 style={{ fontSize: '1.25em' }}>{document.title}</h3>

					<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
						dangerouslySetInnerHTML={{ __html: strippedDescription }}
					/>
				</div>
			);
		} else {
			let strippedDescription = '';

			if (document.description != null) {
				strippedDescription = getDescription(document.description).replace(/(<([^>]+)>)/ig, '');
				if (strippedDescription.length > 99) {
					strippedDescription = strippedDescription.slice(0, 100) + '…';
				}
			}

			return (
				<div>
					<h3 style={{ fontSize: '1.25em' }}>{document.title}</h3>

					<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
						dangerouslySetInnerHTML={{ __html: strippedDescription }}
					/>
				</div>
			);
		}
	}

	footerContent = (document) => {
		const { preschoolGoals } = this.props;

		if (document.associatedGoals && document.associatedGoals.length > 0) {
			let groups = [];

			if (preschoolGoals && preschoolGoals.length > 0) {
				preschoolGoals.map(goal => {
					const inDocument = document.associatedGoals.find(function (goalOb) {
						return goalOb.goalId == goal.id;
					});

					if (typeof (inDocument) != 'undefined') {
						let inArray = groups.findIndex(i => i.name == goal.group);

						if (inArray > -1) {
							return groups[inArray].count++;
						} else {
							let group = {
								'name': goal.group,
								'color': goal.colorCode,
								'count': 1,
							};

							return groups.push(group);
						}
					}
				});
			}

			return (
				<div>
					<div>{this.props.translate('goals')}</div>

					{groups.length > 0 ?
						<div className="goal-preview-container">
							{groups.map(group => {
								return (
									<div key={"goal-" + group.name} className="goal-preview"
										style={{ backgroundColor: group.color }}>
										{group.name} ({group.count})
									</div>
								)
							})}
						</div>
						: null}
				</div>
			);
		} else {
			return null;
		}
	}

	renderDocument = (document, important = false) => {
		let link = '';

		if (document.ReferenceType == 'post') {
			link = '/post/' + document.id;
		} else {
			link = '/preschooldocument/' + document.id;
		}

		let expireDate = Moment(document.validUntil).hour(23).minute(59);
		if (document.validUntil != null && expireDate.diff(Moment()) < 0) {
			return null;
		}

		if (!important && document.important && document.ReferenceType == "post" &&
			Moment().isSameOrAfter(Moment(document.published), 'day') &&
			Moment(document.eventDate).isSameOrAfter(Moment(), 'day') &&
			Moment().isSameOrAfter(Moment(document.eventDate).subtract(7, "days"), 'day')) {
			return null;
		}

		let file = null;
		if (document.associatedFiles != null && document.associatedFiles.length > 0) {
			file = document.associatedFiles.find(file => {
				return file.type.indexOf('image') > -1;
			})
		}

		return (
			<Block
				key={document.ReferenceType + '-' + document.id}
				headerContent={this.headerContent(document)}
				image={file}
				content={this.content(document)}
				footerContent={this.footerContent(document)}
				link={link}
			/>
		);
	}

	renderCreateButton = () => {
		if (this.props.disableCreate) {
			return null;
		}

		let isPreschool = false;
		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		const isTeacher = isUserTeacher(this.props.currentUser);

		if (isTeacher) {
			if (isPreschool) {
				return (
					<div className="create-container" style={{ display: 'inline-block' }}>
						<TooltipMenu trigger={(
							<Button type="secondary">
								<Icon name="Plus" /> {this.props.translate('Create')}
							</Button>
						)}>
							<TooltipMenu.Item onClick={this.toggleDocumentModal}>
								{this.props.translate('create-new-documentation')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={this.toggleEventModal}>
								{this.props.translate('create-new-post')}
							</TooltipMenu.Item>
						</TooltipMenu>
					</div>
				);
			}

			return <Button type="secondary" onClick={this.toggleEventModal}>
				<Icon name="Plus" /> {this.props.translate('Create')}
			</Button>
		} else {
			return null;
		}
	}

	render() {
		const {
			loadingDocuments,
			loadingPosts,
			appending,
			limitReached,
		} = this.state;

		const {
			combined,
			documents,
			userRole,
			limit,
			groups,
		} = this.props;
		let isPreschool = false;

		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		const isTeacher = isUserTeacher(this.props.currentUser);

		return (
			<div className="preschooldocuments">
				{isTeacher && isPreschool ?
					<Modal
						isOpen={this.state.documentModal}
						onClose={this.toggleDocumentModal}
						title={this.props.translate('create-new-documentation')}
					>
						<Documentation onModalClose={this.toggleDocumentModal} />
					</Modal>
					: null}

				{isTeacher ?
					<Modal
						isOpen={this.state.eventModal}
						onClose={this.toggleEventModal}
						title={this.props.translate('create-new-post')}>
						<Post section={this.props.group} onModalClose={this.toggleEventModal} />
					</Modal>
					: null}

				<div className="section">
					{limit != false && this.props.combined.find(post =>
						post.important &&
						post.ReferenceType == "post" &&
						Moment().isSameOrAfter(Moment(post.published), 'day') && Moment(post.eventDate).isSameOrAfter(Moment(), 'day') && Moment().isSameOrAfter(Moment(post.eventDate).subtract(7, "days"), 'day')) != null ?
						<div style={{ marginBottom: '1rem' }}>
							<SectionTitle>
								<span>
									{this.props.translate('important-posts')}
								</span>
							</SectionTitle>

							<BlockWrapper cols={limit ? 3 : 1}>
								{this.props.combined.map((post) => {
									let SevenDaysAgo = Moment(post.eventDate).subtract(7, "days");
									if (post.important && post.ReferenceType == "post" && Moment().isSameOrAfter(Moment(post.published), 'day') && Moment(post.eventDate).isSameOrAfter(Moment(), 'day') && Moment().isSameOrAfter(SevenDaysAgo, 'day')) {
										return this.renderDocument(post, true);
									}
								})}
							</BlockWrapper>
						</div>
						: null}

					<SectionTitle>
						<Icon name="Post" />

						<span>
							{this.props.translate('preschooldocument-posts')}
						</span>

						{limit ? this.renderCreateButton() : null}

						{limit ?
							<Link to={`/posts?group=${this.props.groupid}`} className="see-more">
								{this.props.translate('show-all-posts')}
							</Link>
							: null}
					</SectionTitle>
				</div>

				<div className="posts-section-container">
					{!limit ?
						<div className="posts-content">
							{loadingDocuments || loadingPosts ?
								<LoadingCards count={3} />
								:
								this.renderSectionContent()
							}
						</div>
						:
						<div>
							{loadingDocuments || loadingPosts ?
								<LoadingCards count={3} />
								:
								this.renderSectionContent()
							}
						</div>
					}

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user.user,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
		groups: state.sections.educationGroups,
		documents: state.Documents.sectionDocuments,
		posts: state.Posts.sectionPosts,
		combined: state.CombinedEvents.sectionCombined,
		group: state.sections.activeSection,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getDocumentsBySectionId,
		getPostsBySectionId,
		getFileUrl,
		fetch_preschoolgoals,
		clearSectionDocumentsFromStore,
		clearSectionPostsFromStore,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionPosts);