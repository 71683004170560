import React, { Component } from 'react';
import { BlockContent } from 'UI/Elements/Block';
import { Checkbox } from '@haldor/ui';

class GroupUserSelect extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			selected: [],
		}
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		let selected = [];
		if (this.props.input.value != null && this.props.input.value.length > 0) {
			this.props.section.students.forEach(student => {
				if (this.isStudentSelected(student)) {
					selected.push(student);
				}
			});
		} else {
			selected = [...this.props.section.students];
		}

		this.setState({ selected }, () => {
			this.onChange();
		});
	}

	/* Helper methods */
	isStudentSelected = (student) => {
		const { value } = this.props.input;
		const { section } = this.props;
		const { selected } = this.state;

		if (selected.length == 0) {
			return false;
		}

		let found = selected.find(selected => {
			return selected.id == student.id;
		});

		if (found != null) {
			return true;
		}

		if (value != null && value.length > 0) {
			let entireGroup = value.find(ref => this.findSectionRelation(ref, section));

			if (entireGroup != null) {
				return true;
			}

			let userRelation = value.find(ref => this.findUserRelation(ref, student, section));

			if (userRelation != null) {
				return true;
			}
		}

		return false;
	}

	findUserRelation = (ref, student, section) => {
		return ref.referenceType == 'USER' && ref.referenceId == student.id && ref.parentId == section.id;
	}

	findSectionRelation = (ref, section) => {
		return ref.referenceType == 'SECTION' && ref.referenceId == section.id;
	}

	/* Events */
	onChange = () => {
		const { section } = this.props;
		let { selected } = this.state;
		let relationships = [];

		if (selected.length == section.students.length) {
			// All students selected in this section
			let relationship = null;

			if (this.props.input.value != null && this.props.input.value.length > 0) {
				this.props.input.value.find(ref => this.findSectionRelation(ref, section));
			}

			if (relationship == null) {
				relationship = {
					id: -1,
					referenceType: 'SECTION',
					referenceId: section.id,
				};
			}

			relationships.push(relationship);
		} else {
			// All students are not selected
			selected.forEach(student => {
				let relationship = null;

				if (this.props.input.value != null && this.props.input.value.length > 0) {
					relationship = this.props.input.value.find(ref => this.findUserRelation(ref, student, section));
				}

				if (relationship == null) {
					relationship = {
						id: -1,
						referenceType: 'USER',
						referenceId: student.id,
						parentId: section.id,
						parentType: 'SECTION',
					};
				}

				relationships.push(relationship);
			});
		}

		if (this.props.input != null) {
			this.props.input.onChange(relationships);
		}
	}

	onUserCheckboxClick = (student) => {
		let selected = this.state.selected;
		let found = selected.findIndex(selected => {
			return selected.id == student.id;
		});

		if (found > -1) {
			selected.splice(found, 1);
		} else {
			selected.push(student);
		}

		this.setState({ selected }, () => {
			this.onChange();
		});
	}

	/* Render methods */
	render() {
		const { section } = this.props;

		return (
			<div className="user-select single-group">
				<BlockContent style={{ position: 'relative', paddingTop: 0, paddingBottom: 0 }}>
					{section.students ? section.students.map(student => {
						return <div key={student.id}>
							<Checkbox
								onChange={() => this.onUserCheckboxClick(student)}
								value={this.isStudentSelected(student)}
								label={student.firstName + " " + student.lastName}
							/>
						</div>
					}) : null}
				</BlockContent>
			</div>
		);
	}

}

export default GroupUserSelect;
