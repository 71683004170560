import React, { Component } from 'react';
import api from 'lib/api';
import getEnvironment from 'lib/env';
import { getToken, http, loginRequest } from 'lib/msal';

class FileItem extends Component {
	static defaultProps = {
		element: 'img',
	};

	constructor(props) {
		super(props);

		this.image = null;
	}

	_setImage = (element) => (this.image = element);

	componentDidMount = () => {
		if (this.props.file != null) {
			if (this.image == null) {
				return false;
			}

			if (this.props.file.type.indexOf('image') > -1) {
				if (!this.props.file.url) {
					api.getFile(this.props.file.id).then((response) => {
						const urlCreator = window.URL || window.webkitURL;
						const imageSRC = urlCreator.createObjectURL(response.data);
						if (this?.props?.element == 'div') {
							if (this.image) {
								this.image.style.backgroundImage = 'url(' + imageSRC + ')';
							}
							return true;
						}

						this.image.src = imageSRC;
					});
				} else {
					if (this?.props?.element == 'div') {
						if (this.image) {
							this.image.style.backgroundImage = 'url(' + this.props.file.url + ')';
						}

						return true;
					}

					this.image.src = this.props.file.url;
				}
			} else {
				if (!this.props.file.url) {
					let request = loginRequest;
					request.scopes = [...getEnvironment().scopes, ...request.scopes];

					getToken(request).then((response) => {
						this.image.href = `${http.defaults.baseURL}/fileItems/${this.props.file.id}?token=${response.accessToken}`;
					});
				} else {
					this.image.href = this.props.file.url;
				}
			}
		}
	};

	render() {
		let eProps = { ...this.props };
		delete eProps.id;
		delete eProps.element;
		delete eProps.file;

		if (this.props.file == null) {
			return null;
		}

		if (this.props.file.type == null || this.props.file.id == null) {
			return null;
		}

		if (this.props.element == 'div') {
			return <div ref={this._setImage} {...eProps} />;
		}

		if (this.props.file.type.indexOf('image') == -1) {
			return <a href='' target='_blank' ref={this._setImage} {...eProps} />;
		}

		return <img ref={this._setImage} {...eProps} />;
	}
}

export default FileItem;
