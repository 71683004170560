import React, { useEffect } from 'react';
import moment from 'moment';
import store from '../../index';
import DateTime from '_class/DateTime';
import { FormSpy, useForm } from 'react-final-form';
import { throttle } from 'helpers/content';
import { getBasedOnLastModified, saveWithExpirationAndLastModified, getObjectExpiration } from 'helpers/localstorage';

const updateLocalValues = throttle(({ values }, name) => {
	saveWithExpirationAndLastModified(name + '-values', JSON.stringify(values), moment().add(48, 'hours'));
}, 1000);

const PersistentFormListener = ({ name, initialValues, lastModifiedUTC, addTimeStampedToast }) => {
	const form = useForm();
	if (form.getState().submitSucceeded && !form.getState().dirtySinceLastSubmit) {
		localStorage.removeItem(name + '-values');
	}
	useEffect(() => {
		if (lastModifiedUTC != null && getBasedOnLastModified(name + '-values', lastModifiedUTC) != null) {
			const storedValues = JSON.parse(getBasedOnLastModified(name + '-values', lastModifiedUTC));
			if (storedValues.hasOwnProperty('status')) {
				delete storedValues['status'];
			}
			if (initialValues.hasOwnProperty('status')) {
				delete initialValues['status'];
			}
			if (JSON.stringify(initialValues) != JSON.stringify(storedValues)) {
				const values = storedValues;
				setTimeout(() => {
					form.batch(() => {
						Object.keys(values).forEach((field) => {
							const value = values[field];
							if (value == null || value == "") {
								return false;
							}

							form.change(field, values[field]);
						});
					});
				}, 50);
				addTimeStampedToast('There are changes to the form that have not been saved. In order not to lose the changes, you must resave the form at the latest:', moment.utc(getObjectExpiration(name + '-values')).local().format('Do MMMM HH:mm'), 'error');
			}
		}
		return () => {
			if (!form.getState().submitFailed) {
				localStorage.removeItem(name + '-values');
			}
		};
	}, []);

	return (
		<FormSpy
			onChange={(subscription) => {
				if (!form.getState().dirty && !form.getState().dirtySinceLastSubmit) {
					// Dont update values in localStorage if form isnt touched (init)
					return false;
				}
				updateLocalValues(subscription, name)
			}}
			subscription={{ values: true }}
		/>
	);


};

PersistentFormListener.defaultProps = {
	name: 'persisted_form',
};

export default PersistentFormListener;
