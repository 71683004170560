import api from 'lib/api';

export const GET_TYPES = 'GET_TYPES';

export const getTypes = (lcid) => ({
	type: GET_TYPES,
	payload: new Promise((resolve) => {
		api.get(`status/${lcid}`).then((response) => {
			resolve(response.data);
		});
	}),
});
