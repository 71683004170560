import api from 'lib/api';

export const GET_MENU_ITEMS = 'MENU_GET_MENU_ITEMS';

export const getMenuItems = () => ({
	type: GET_MENU_ITEMS,
	payload: new Promise(resolve => {
		api.get(`navigation`).then((response) => {
			resolve(response.data);
		});
	}),
});
