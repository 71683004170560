/**
 * @typedef {import('types').Bookmark} Bookmark
 */

 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import { Link } from 'react-router-dom';
 import { translate } from '@haldor/ui';
 
 import {
	 PRESCHOOL,
 } from 'constants/schoolTypes';
 
 import { setPageTitle } from 'actions/header';
 
 import {
	 getMyBookmarks,
	 deleteBookmark,
 } from 'actions/bookmarks';
 
 import { Block, Icon } from '@haldor/ui';
 import LoadingCards from 'components/Cards/LoadingCard';
 import './_UserBookmarks.scss';

 class UserBookmarks extends Component {
 
	 constructor(props) {
		 super(props);

		 this.state = {
			 loading: false,
		 }
	 }
 
	 UNSAFE_componentWillMount = () => {
		 this.props.setPageTitle(this.props.translate('nav_bookmarks'));
	 }
 
	 componentDidMount = () => {
		 if (this.props.bookmarks == null || this.props.bookmarks.length == 0) {
			 this.setState({loading:true})
			 this.props.getMyBookmarks().then(() => {
				 this.setState({loading:false})
			 }); 
		 }
	 }
 
	 /**
	  * @param {Bookmark} bookmark
	  */
	 onRemoveBookmark = (bookmark) => {
		 this.props.deleteBookmark(bookmark.id);
	 }
 
	 /**
	  * @param {Bookmark} bookmark
	  */
	 getReferenceLink = (bookmark) => {
		 if (bookmark.referenceType == 'ASSIGNMENT') {
			 return '/assignment/' + bookmark.referenceId;
		 }
 
		 if (bookmark.referenceType == 'PLAN') {
			 return '/plan/' + bookmark.referenceId;
		 }
	 }
 
	 /**
	  * @param {Bookmark} bookmark
	  */
	 renderBookmark = (bookmark) => {
		 const link = this.getReferenceLink(bookmark);
 
		 return <div className="bookmark" key={bookmark.id}>
			 <Link to={link}>
				 {bookmark.title}
			 </Link>
 
			 <div className="actions">
				 <span onClick={() => this.onRemoveBookmark(bookmark)}>
					 <Icon name="Bin" />
				 </span>
			 </div>
		 </div>
	 }
 
	 render() {
		 const { currentUser } = this.props;
 
		 /**
		  * @type {Bookmark[]} assignments
		  */
		 const assignments = [...this.props.bookmarks].filter(bookmark =>
			 bookmark.referenceType == 'ASSIGNMENT'
		 );
 
		 /**
		  * @type {Bookmark[]} plans
		  */
		 const plans = [...this.props.bookmarks].filter(bookmark =>
			 bookmark.referenceType == 'PLAN'
		 );
 
		 const schoolType = currentUser.schools.getActiveSchool(currentUser.userId).type
 
		 const total = plans.length + assignments.length;
 
		 return (
			 <div className="bookmarks user">
             {this.state.loading ? 
					 <LoadingCards count={1} />
					 :
				 <Block>
				
					{plans.length > 0 ?
						 <div>
							 <span className="title">
								 {this.props.translate('plans')}
							 </span>
 
							 <div className="bookmarks-list">
								 {plans.map(bookmark => this.renderBookmark(bookmark))}
							 </div>
 
							 <div style={{ marginBottom: '1rem' }} />
						 </div>
						 : null}
 
					 {assignments.length > 0 && schoolType != PRESCHOOL ?
						 <div>
							 <span className="title">
								 {this.props.translate('Assignments')}
							 </span>
 
							 <div className="bookmarks-list">
								 {assignments.map(bookmark => this.renderBookmark(bookmark))}
							 </div>
 
							 <div style={{ marginBottom: '1rem' }} />
						 </div>
						 : null}
 
					 {total == 0 ?
						 <div className="text--center color--meta">
							 {this.props.translate('no-bookmarks')}
						 </div>
						 : null}
						 
				 </Block>
	 }
			 </div>
		 );
	 }
 
 }
 
 function mapStateToProps(state) {
	 return {
		 translate: translate(state.Languages.translations),
		 bookmarks: state.Bookmarks.user,
		 currentUser: state.user.currentUser
	 }
 }
 
 export default connect(mapStateToProps, {
	 getMyBookmarks,
	 setPageTitle,
	 deleteBookmark,
 })(UserBookmarks);