const getObjectKeys = (...objects) => {
	let keys = [];

	objects.forEach((obj) => {
		Object.keys(obj).forEach((key) => {
			if (keys.indexOf(key) == -1) {
				keys.push(key);
			}
		})
	})

	return keys;
}

const isPropertyDifferent = (a, b, property) => {
	return a[property] != b[property];
}

const isObjectDifferent = (a, b) => {
	if (typeof a != 'object' || typeof b != 'object') {
		// One of the provided values are not objects, cannot compare
		return false;
	}

	let result = false;
	const keys = getObjectKeys(a, b);
	keys.forEach((key) => {
		// TODO: Figure out what tyhis does
		// if (typeof a[key] == 'object' && typeof b[key] == 'object' && a[key] != null && b[key] != null) {
		// 	return isObjectDifferent(a[key], b[key]);
		// }

		if (isPropertyDifferent(a, b, key)) {
			result = true;
		}
	});

	return result;
}

export const get_operations = (old, changes) => {
	let a = JSON.parse(JSON.stringify(old));
	let b = JSON.parse(JSON.stringify(changes));

	let operations = [];

	let keys = getObjectKeys(a, b);
	let changedKeys = [];

	keys.forEach((key) => {
		const inOld = Object.keys(a).find((oldKey) => oldKey == key) != null;
		const inNew = Object.keys(b).find((newKey) => newKey == key) != null;

		if (inOld && inNew) {
			switch (typeof a[key]) {
				case 'number':
				case 'string':
					if (isPropertyDifferent(a, b, key)) {
						changedKeys.push(key);
					}

					break;

				case 'object':
					if (a[key] == null || b[key] == null) {
						if (isPropertyDifferent(a, b, key)) {
							changedKeys.push(key);
						}

						break;
					}

					if (a[key].length != null) {
						// Is array, check for new or changed items
						b[key].forEach((item) => {
							if (item.id == null) {
								// New item (no id)
								operations.push({
									op: 'add',
									path: '/' + key,
									value: item,
								});

								return true;
							}

							let foundInA = a[key].find((aItem) => aItem.id == item.id);
							if (foundInA != null) {
								if (isObjectDifferent(item, foundInA)) {
									operations.push({
										op: 'replace',
										path: '/' + key,
										value: item,
									});
								}
							}
						});

						// Check for removed items
						a[key].forEach((item) => {
							let foundInB = b[key].find((bItem) => bItem.id == item.id);

							if (foundInB == null) {
								operations.push({
									op: 'remove',
									path: '/' + key,
									value: item,
								});
							}
						})

						break;
					}

					if (isObjectDifferent(a, b)) {
						operations.push({
							op: 'replace',
							path: '/' + key,
							value: b,
						});
					}

				default:
					break;
			}

			return true;
		}

		changedKeys.push(key);
	})

	if (changedKeys.length > 0) {
		let value = {};
		changedKeys.forEach((key) => {
			value[key] = b[key];
		});

		operations.unshift({
			op: 'replace',
			path: '/',
			value: value,
		});
	}

	return operations;
}
