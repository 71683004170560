import React, { Component } from 'react';
import { connect } from 'react-redux';

import AssignmentSelect from "containers/Forms/Partials/AssignmentSelect";

import Modal from 'containers/Modals/Modal';
import { Button, Icon, translate } from '@haldor/ui';

class SelectAssignment extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modal: false,
		}
	}

	toggleModal = (event) => {
		if (event != null) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.setState({ modal: !this.state.modal });
	}

	selectAssignmentForPlan = (assignment) => {
		if (assignment.id == parseInt(assignment.id)) {
			// Haldor assignment
			delete assignment.associatedMatrices;
			delete assignment.courses;
			delete assignment.assessmentId;
			delete assignment.assignmentDocuments;
			delete assignment.attachedFiles;
			delete assignment.containerId;
			delete assignment.documentTemplates;
			delete assignment.origin;
			delete assignment.removeInstructions;
			delete assignment.selectedCourses;
			delete assignment.section;
			delete assignment.plagiarismCheck;
			delete assignment.teacher;
			delete assignment.sectionUrl;
			delete assignment.plans;
			delete assignment.isDeletable;
			delete assignment.locked;
			delete assignment.matrix;
			delete assignment.tasks;
		} else {
			// MS Teams assignment
		}

		if (this.props.onPicked != null) {
			this.props.onPicked(assignment);
		}

		this.setState({ modal: false });
	};

	render() {
		let filterAssignments = [];
		let section = null;

		if (this.props.selectedSections != null && this.props.selectedSections.length > 0) {
			section = this.props.selectedSections[0];
		} else {
			section = this.props.activeSection;
		}

		if (this.props.plan != null && this.props.plan.associatedAssignments != null) {
			filterAssignments = this.props.plan.associatedAssignments.map((assignment) => assignment.assignmentId);
		}

		return (
			<div style={{ display: 'inline-block' }}>
				<Modal isOpen={this.state.modal} type="small editor-block-modal" onClose={this.toggleModal} title={this.props.translate('Select assignment')}>
					<AssignmentSelect
						section={section}
						onSelect={this.selectAssignmentForPlan}
						filterResults={filterAssignments}
						includeAssignmentsWithPlans={true}
					/>
				</Modal>

				<Button onClick={this.toggleModal}>
					<Icon name="Magnify" bw />
					{this.props.translate('Search assignment')}
				</Button>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		plan: state.planning.active_plan,
		activeSection: state.sections.activeSection,
		selectedSections: state.sections.selected,
	}
}

export default connect(mapStateToProps, {
})(SelectAssignment);