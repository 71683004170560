import { searchCourseByTitle } from 'actions/search';
import React, { Component } from 'react';
import { Tabs, Tab, translate, Button, Checkbox } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner, ColorLabel, ColorLabelContainer } from 'UI';
import { Search } from 'UI/Inputs';
import './SearchCourses.scss';
import { getSchoolTypes } from 'actions/tenants'

class SearchCourses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			courses: null,
			schoolType: 'All',
			year: 'All',
			searchText: null,
			selected: [],
		};

		this.years = ["All", 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	}

	componentDidMount = async () => {
		if (this.props.schoolTypes.length <= 1 || this.props.schoolTypes == null) {
			await this.props.getSchoolTypes();
		}
	}

	onSearch = () => {
		if (this.state.searchText == null || this.state.searchText == '') {
			this.state.searchText = null;
		}

		const { searchText, schoolType, year } = this.state;

		this.setState({ selected: [], loading: true, courses: null });

		this.props.searchCourseByTitle(
			searchText,
			schoolType == 'All' ? '' : schoolType,
			year == 'All' ? '' : this.state.year,
			(data) => {
				this.setState({ loading: false, courses: data });
			}
		);
	}

	getCourseTitle = (course) => {
		const schoolType = this.props.translate(course.typeOfSchooling);
		return `${course.title} - ${course.year != null ? course.year : ''} ${course.courseCode} (${schoolType})`;
	}

	renderCourse = (course) => {
		if (course.title == '')
			return null;

		return (
			<div className="course" key={'searched-course-' + course.id} onClick={e => this.onSelect(course)}>
				<Checkbox
					value={this.state.selected.findIndex((selected) =>
						selected.id == course.id
					) > -1}
					label={(
						<ColorLabelContainer>
							<ColorLabel
								content={this.getCourseTitle(course)}
								color={course.colorCode}
							/>
						</ColorLabelContainer>
					)}
				/>
			</div>
		);
	}

	onSelect = (course) => {
		const foundIndex = this.state.selected.findIndex((selected) => selected.id == course.id);

		if (foundIndex > -1) {
			this.setState({
				selected: this.state.selected.filter((selected) =>
					selected.id != course.id
				),
			});
		} else {
			this.setState({ selected: [...this.state.selected, course] });
		}
	}

	onSubmit = (event) => {
		event.preventDefault();

		if (this.props.onSubmit != null) {
			this.props.onSubmit(this.state.selected);
		}
	}

	onSchoolTypeChange = (event) => {
		this.setState({ schoolType: event.target.value, year: 'All' }, () => {
			this.onSearch();
		});
	}

	onYearChange = (event) => {
		this.setState({ year: event.target.value }, () => {
			this.onSearch();
		});
	}

	render() {
		const { translate } = this.props;

		let courses = [];
		if (this.state.courses != null) {
			courses = this.state.courses.sort((a, b) =>
				this.getCourseTitle(a).localeCompare(this.getCourseTitle(b))
			);
		}

		const selectedSchoolType = this.props.schoolTypes?.find((type) =>
			type.typeId == this.state.schoolType
		);

		return (
			<div className="container course-selector form form-component" style={{ marginTop: 0 }}>
				<div className="form-row" style={{ padding: 0 }}>
					<label>{this.props.translate('Add curriculums to group')}</label>
					<div>{this.props.translate('Search for curriculums')} ({this.props.translate('enter to search')})</div>
					<Search
						onSubmit={this.onSearch}
						loading={this.state.loading}
						onChange={(value) => {
							this.setState({ searchText: value })
						}}
						allowEmpty
					/>
				</div>

				<div className="df" style={{ marginTop: '1.5rem', marginBottom: '1rem' }}>
					<div className="form-row" style={{ width: '47%', padding: 0, marginRight: '1rem' }}>
						<label style={{ margin: 0 }}>
							{this.props.translate('Filter')}
						</label>

						<div className='select' style={{ marginTop: '.5rem' }}>
							<select
								name='schoolType'
								value={this.state.schoolType}
								onChange={this.onSchoolTypeChange}
							>
								<option value="All">
									{this.props.translate('All')}
								</option>

								{this.props.schoolTypes?.sort((a, b) =>
									translate(a.typeId).localeCompare(translate(b.typeId))
								).map((schoolType, index) => {
									return (
										<option key={index} value={schoolType.typeId}>
											{this.props.translate(schoolType.typeId)}
										</option>
									);
								})}
							</select>
						</div>
					</div>

					{selectedSchoolType != null && selectedSchoolType.maxYear != null && selectedSchoolType.minYear != null ?
						<div className="form-row" style={{ padding: 0, width: 'auto', flex: 1, marginLeft: '2rem' }}>
							<label style={{ margin: 0 }}>
								{this.props.translate('course-year')}
							</label>

							<div className='select' style={{ marginTop: '8px' }}>
								<select
									name='year'
									value={this.state.year}
									onChange={this.onYearChange}
								>
									{this.years
										.filter((year) => {
											if (year == 'All') {
												return true;
											}

											return year >= selectedSchoolType?.minYear && year <= selectedSchoolType?.maxYear;
										})
										.map((year, index) => (
											<option key={index} value={year}>
												{this.props.translate(year)}
											</option>
										))}
								</select>
							</div>
						</div>
						: null}
				</div>

				<div style={{ marginTop: '1rem' }} />

				<Tabs>
					<Tab title={this.props.translate('courses')}>
						{courses.map((course) => {
							return this.renderCourse(course);
						})}
					</Tab>
				</Tabs>

				<div style={{ marginTop: '2.5rem' }}>
					<Button disabled={this.state.selected.length == 0} onClick={this.onSubmit}>
						{this.props.translate('Add')}
					</Button>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		schoolTypes: state.Tenant.schoolTypes,
	};
}

export default connect(mapStateToProps, {
	searchCourseByTitle,
	getSchoolTypes
})(SearchCourses);
