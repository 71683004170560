import React, { useState, useEffect } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';

import './_CookiesNotSupported.scss';

const CookiesNotSupportedError = (props) => {
	const [lang, setLang] = useState('en-us');

	useEffect(() => {
		microsoftTeams.app.getContext().then((context) => {
			if (context == null || context.app.locale == null) {
				return false;
			}

			if (context.app.locale == 'sv-se') {
				setLang('sv-se');
			}
		})
	}, []);

	if (lang == 'sv-se') {
		return (
			<div className="cookies-not-supported container">
				<div style={{ minHeight: '15rem', width: '15rem', marginBottom: '3rem' }}>
					<img src="/dist/svg/illustration_fel-404.svg" />
				</div>

				<h1>Oj, något gick fel!</h1>

				<p style={{ marginTop: '1rem' }}>
					Om du vill använda Haldor Education måste du ändra inställningarna i din webbläsare så den tillåter cookies från tredje part. Om du behöver hjälp, ta kontakt med din skolas IT-support.
					<br /><br />
					Om din skola har blockerat tredjepartcookies för hela organisationen måste er IT-support lägga till <strong>[*.]haldor.se</strong> som en tillåten webbplats.
					<br /><br />

					<h3 style={{ marginBottom: '.65rem' }}>Använder du Microsoft Teams i webbläsaren?</h3>
					Om du använder Microsoft Teams och Haldors tjänster i webbläsaren måste du också lägga till webbplatserna för Microsoft Teams som tillåtna webbplatser. Du hittar&nbsp;
					<a href="https://docs.microsoft.com/sv-se/microsoftteams/troubleshoot/teams-sign-in/sign-in-loop" target="_blank">
						alla webbplatser i Microsofts guide.
					</a>
				</p>
			</div>
		);
	}

	return (
		<div className="cookies-not-supported container">
			<div style={{ minHeight: '15rem', width: '15rem', marginBottom: '3rem' }}>
				<img src="/dist/svg/illustration_fel-404.svg" />
			</div>

			<h1>Whoops, something went wrong!</h1>

			<p style={{ marginTop: '1rem' }}>
				You need to change your browser settings to allow third-party cookies to use Haldor Education. If you need help, contact your school's IT-support.
				<br /><br />
				If your school has disabled third-party cookies for the entire organization your IT-support needs to add <strong>[*.]haldor.se</strong> as a trusted site.
				<br /><br />

				<h3 style={{ marginBottom: '.65rem' }}>Using Microsoft Teams in your browser?</h3>
				If you are using Microsoft Teams and Haldor’s services in a desktop browser, you also need to add the URLs for Microsoft Teams as trusted sites. You can find&nbsp;

				<a href="https://docs.microsoft.com/en-us/microsoftteams/troubleshoot/teams-sign-in/sign-in-loop#resolution" target="_blank">
					all the URLs in Microsoft’s guide.
				</a>
			</p>
		</div>
	);
}

export default CookiesNotSupportedError;