import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

import { getTaskDetails } from 'actions/assignments';
import { setPageTitle } from 'actions/header';

import AttachedUserDocuments from 'components/Presentation/Documents/AttachedUserDocuments';

import { Spinner } from 'UI';
import { Link } from 'react-router-dom';
import { getRootUrl } from 'helpers/url';

class UserDocuments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			documentRefresh: 0,
			assignmentLoading: false,
			activeAssignmentTaskId: null,
			assignmentTasks: null,
			assignmentTask: null,
			assignmentTaskIndexes: null
		};

		this.goNext = this.goNext.bind(this);
		this.goPrevious = this.goPrevious.bind(this);
	}



	componentDidMount() {
		this.loadAssignment();
	}

	loadAssignment() {
		this.setState({ assignmentLoading: true });
		const that = this;

		this.props.getTaskDetails(this.props.match.params.assignmentId)
			.then(() => {
				this.props.setPageTitle(this.props.assignment.title);
				if (this.props.assignment.groupEnabled) {
					that.setState({
						assignmentTasks: that.props.assignment.tasks.sort((a, b) =>
							a.groupName.split(' ').pop()
								.localeCompare(b.groupName.split(' ').pop())
						)
					}, () => {
						that.setActiveTaskId(that.props.match.params.taskId);
						that.setTaskIndexes();
						that.setState({ assignmentLoading: false });
					});
				}
				else {
					that.setState({
						assignmentTasks: that.props.assignment.tasks.sort((a, b) =>
							(a.students[0].lastName || "").localeCompare(b.students[0].lastName || ""))
					}, () => {
						that.setActiveTaskId(that.props.match.params.taskId);
						that.setTaskIndexes();
						that.setState({ assignmentLoading: false });
					});
				}
			});
	}

	setActiveTaskId(taskId) {
		this.setState({ activeAssignmentTaskId: taskId });
		this.setActiveTask(taskId);
	}

	setActiveTask(taskId) {
		const { assignmentTasks } = this.state;

		const task = assignmentTasks.filter(t => t.id == taskId)[0];

		this.setState({ assignmentTask: task });
	}

	setTaskIndexes() {
		const { assignmentTasks } = this.state;
		let taskIndexes = {};

		assignmentTasks.forEach((element, index) => {
			taskIndexes[element.id] = index;
		});

		this.setState({ assignmentTaskIndexes: taskIndexes });
	}

	refreshDocuments = () => {
		let documentRefresh = this.state.documentRefresh;
		documentRefresh++;

		this.setState({ documentRefresh });
	}

	goNext() {
		const {
			assignmentTasks,
			assignmentTaskIndexes,
			activeAssignmentTaskId,
		} = this.state;

		let index = assignmentTaskIndexes[activeAssignmentTaskId];

		index++;

		if (index > (assignmentTasks.length - 1)) {
			return;
		}

		let output = Object.entries(assignmentTaskIndexes).map(([key, value]) => ({ key, value }));

		let nextActiveAssignmentTaskId = output.filter(t => t.value === index)[0].key;

		this.setActiveTaskId(nextActiveAssignmentTaskId);
		this.refreshDocuments();
	}

	goPrevious() {
		const {
			assignmentTaskIndexes,
			activeAssignmentTaskId
		} = this.state;

		let index = assignmentTaskIndexes[activeAssignmentTaskId];

		index--;

		if (index < 0) {
			return;
		}

		let output = Object.entries(assignmentTaskIndexes).map(([key, value]) => ({ key, value }));

		let nextActiveAssignmentTaskId = output.filter(t => t.value === index)[0].key;

		this.setActiveTaskId(nextActiveAssignmentTaskId);
		this.refreshDocuments();
	}

	render() {
		const {
			documentRefresh,
			assignmentTask,
			assignmentTasks,
			assignmentTaskIndexes,
			activeAssignmentTaskId,
		} = this.state;

		if (this.state.assignmentLoading || !assignmentTask) {
			return (
				<Spinner center />
			);
		}

		const nextEnable = assignmentTasks.length > 1 &&
			assignmentTaskIndexes[activeAssignmentTaskId] != (assignmentTasks.length - 1);

		const previousEnable = assignmentTasks.length > 1 &&
			assignmentTaskIndexes[activeAssignmentTaskId] != 0;

		return (
			<div>
				{this.props.assignment != null && this.props.assignment.id > 0 ? <div className="single-section">
					<div className="single-assignment-meta">
						<Link to={`${getRootUrl()}assignment/` + this.props.assignment.id}>
							{this.props.translate('Go to assignment')}
						</Link>
					</div>
				</div> : null}

				<div id="documentWidget" className="single-task">
					<AttachedUserDocuments
						assignmentId={this.props.match.params.assignmentId}
						taskId={activeAssignmentTaskId}
						IsSubmitted={assignmentTask.status === 'ASSIGNMENT_TASK_SUBMITTED'}
						documentRefresh={documentRefresh}
						assignmentTask={assignmentTask}
						IsTeacher={true}
						nextEnable={nextEnable}
						previousEnable={previousEnable}
						goNext={this.goNext}
						goPrevious={this.goPrevious}
					/>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		assignment: state.assignments.active_assignment,
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps, {
	getTaskDetails,
	setPageTitle,
})(UserDocuments);
