import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ColorScale from '_class/ColorScale';
import DateTime from '_class/DateTime';
import { useTranslate } from 'lib/translate';
import { capitalize } from 'helpers/content';

import { getUserSummary, getUserStatisticsDetailed } from 'actions/assessments';

import DisplayMatrix from 'components/Presentation/DisplayMatrix';
import DisplayGoal from 'components/Presentation/DisplayGoal';
import DisplayFeedbacks from 'components/Presentation/DisplayFeedbacks';

import { Spinner } from 'UI';
import { Bar } from 'UI/Graphs';
import { Expandable, Icon } from '@haldor/ui';
import User from '_class/User';

const VerdictAssessmentsDisplay = (props) => {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const summary = useSelector(state => state.assessments?.studentOverview);
	const statistics = useSelector(state => state.assessments?.studentStatistics);
	const currentUser = useSelector(state => state.user.currentUser);
	const user = new User(currentUser);
	const translate = useTranslate();

	useEffect(async () => {
		setLoading(true);

		await Promise.all([
			dispatch(getUserSummary(props.studentId, props.start, props.end)),
			dispatch(getUserStatisticsDetailed(props.studentId, props.start, props.end)),
		]);

		setLoading(false);

		return () => null;
	}, [props.studentId, props.start, props.end, props.sectionId])

	const getGoals = () => {
		if (summary == null) {
			return [];
		}

		let goals = [];

		summary.assessmentGoals.forEach((goal) => {
			let parts = summary.assessmentBlockParts?.filter((part) =>
				part.referenceType == 'assessmentGoal' && part.referenceId == goal.id &&
				part.assessmentBlockPartRelationships.find((relation) => relation.courseId == props.courseId) != null
			);

			goals.push({
				parts: parts,
				...goal
			})
		});

		return goals;
	}

	const getMatrices = () => {
		if (summary == null) {
			return [];
		}

		let matrices = [];
		summary.courses.forEach((course) => {
			course.details.forEach((detail) => {
				let parts = summary.assessmentBlockParts?.filter((part) =>
					part.referenceType == 'matrix' && part.referenceId == detail.matrixID &&
					part.assessmentBlockPartRelationships.find((relation) => relation.courseId == props.courseId) != null
				);

				if (parts.length > 0) {
					matrices.push({
						id: detail.matrix.id,
						disableNotAchieved: detail.matrix.disableNotAchieved,
						'_ParagraphSet': detail.matrix['_ParagraphSet'],
						color: course.colorCode,
						parts: parts,
					});
				}
			})
		});

		return matrices;
	}

	/**
	 * @param {array} parts
	 * @returns {array} feedbacks
	 */
	const getFeedbacksForParts = (parts) => {
		if (summary == null) {
			return [];
		}

		return JSON.parse(JSON.stringify(summary.assessmentFeedbacks)).filter((feedback) =>
			parts.find((part) =>
				feedback.assessmentBlockPartId == part.id &&
				part.assessmentBlockPartRelationships.find((relation) => relation.courseId == props.courseId) != null
			) != null
		);
	}

	/**
	 * @returns {object} statistic
	 */
	const getStudentStatistic = () => {
		if (statistics == null || props.studentId == null) {
			return null;
		}

		return statistics.find((statistic) => statistic.studentId == props.studentId);
	}

	/**
	 * @param {number} goalId
	 * @returns {object} statistic
	 */
	const getGoalStatistic = (goalId) => {
		return getStudentStatistic()?.studentStatistics?.find((statistic) =>
			statistic.type == 'ASSESSMENTGOAL' && statistic.referenceId == goalId
		);
	}

	/**
	 * @param {number} matrixId 
	 * @returns {object} statistic
	 */
	const getMatrixStatistic = (matrixId) => {
		return getStudentStatistic()?.studentStatistics?.find((statistic) =>
			statistic.type == 'MATRIX' && statistic.referenceId == matrixId
		);
	}

	const getPartTitle = (type, statistic, reference, index = 0) => {
		let colors = [];
		if (statistic != null) {
			let addRed = false;
			if (statistic.type == "MATRIX") {
				addRed = !reference.disableNotAchieved;
			}

			if (statistic.type == "ASSESSMENTGOAL") {
				addRed = reference.rows.find((row) => {
					return row.cells.find((cell) => cell.type == 'NotAchievedTextField');
				}) != null;
			}

			colors = new ColorScale(statistic.statistics.length).get(addRed);
		}

		return <div className="df aic bar-outer-container">
			<div className="bar-title-container">
				{translate(type)}

				{type == 'assessmentGoal' ?
					' ' + index
					: null}
			</div>

			{statistic != null ?
				<Bar
					data={statistic.statistics.map((stat) => ({
						amount: stat.count,
						label: stat.count,
						color: colors[stat.index],
					}))}
					showAmount
					showAllSteps
				/>
				: null}
		</div>
	}

	/**
	 * @param {string} target
	 * @param {array} permissions
	 */
	const renderPermission = (target, permissions) => {
		const active = permissions.find((perm) => perm.target == target) != null;
		return <div className="permission">
			<Icon name={active ? "Eye" : "Eye_Off"} />
			{translate(capitalize(target))}
		</div>
	}

	/**
	 * @param {object} feedback
	 * @param {number} index
	 */
	const renderFeedback = (feedback, index) => {
		const resource = summary?.assessmentBlockParts?.find((part) =>
			part.id == feedback.assessmentBlockPartId
		);

		let permissions = [];
		if (resource != null && !feedback.isPrivate) {
			permissions = resource.permissions;
		}

		return <div className="feedback" style={{ paddingLeft: 0 }}>
			<div className="df jcb aic size-12" style={{ marginBottom: '.4rem' }}>
				<div>
					{feedback.navigationObject != null ?
						feedback.navigationObject.referenceType == 'PLAN' ?
							translate('Plan')
							: translate('Assignment')
							+ ' • ' + feedback.navigationObject.title
						: null}
				</div>

				<div>
					{feedback.editorUser.firstName} {feedback.editorUser.lastName}
					{' • ' + new DateTime(feedback.created).getLongDate()}
				</div>
			</div>

			<div className="df jcb ais">
				<div
					dangerouslySetInnerHTML={{ __html: feedback.text }}
				/>

				{!user.isStudent() ?
					<div className="permissions">
						{renderPermission('STUDENT', permissions)}
						{renderPermission('GUARDIAN', permissions)}
					</div>
					: null}
			</div>
		</div>
	}

	/**
	 * @param {array} goals 
	 * @param {array} matrices 
	 * @param {boolean} isPrivate
	 */
	const renderCollected = (goals = [], matrices = [], isPrivate = false) => {
		return <div className="course-content">
			{goals.map((goal, index) => {
				const feedbacks = getFeedbacksForParts(goal.parts).filter((feedback) =>
					feedback.isPrivate == isPrivate
				);

				if (feedbacks.length == 0) {
					return null;
				}

				return <Expandable key={goal.id} title={getPartTitle('assessmentGoal', null, goal, index + 1)}>
					<div className="display-feedbacks" style={{ margin: 0, padding: 0, border: 0 }}>
						{feedbacks.map(renderFeedback)}
					</div>
				</Expandable>
			})}

			{matrices.map((matrix) => {
				const feedbacks = getFeedbacksForParts(matrix.parts).filter((feedback) =>
					feedback.isPrivate == isPrivate
				);

				if (feedbacks.length == 0) {
					return null;
				}

				return <Expandable key={matrix.id} title={getPartTitle('matrix', null, matrix)}>
					<div className="display-feedbacks" style={{ margin: 0, padding: 0, border: 0 }}>
						{feedbacks.map(renderFeedback)}
					</div>
				</Expandable>
			})}
		</div>
	}

	if (loading) {
		return (
			<div className="user-asessments assessment-block">
				<Spinner center />
			</div>
		);
	}

	const goals = getGoals();
	const matrices = getMatrices();
	const title = (
		<div className="df aic">
			<span>
				{translate('Assessments')}
			</span>

			<div className="size-14 color--meta" style={{ marginLeft: '.75rem' }}>
				{new DateTime(props.start).getDateStamp()} - {new DateTime(props.end).getDateStamp()}
			</div>
		</div>
	)

	return (
		<div className="user-assessments assessment-block">
			<Expandable title={title}>
				<div className="course-content">
					{props.showGoals ?
						goals.map((goal, index) => {
							const statistic = getGoalStatistic(goal.id);
							const feedbacks = getFeedbacksForParts(goal.parts);

							return <Expandable key={goal.id} title={getPartTitle('assessmentGoal', statistic, goal, index + 1)}>
								<DisplayGoal goal={goal} statistic={statistic} />

								{feedbacks.length > 0 ?
									<DisplayFeedbacks feedbacks={feedbacks} />
									: null}
							</Expandable>
						})
						: null}

					{props.showMatrices ?
						matrices.map((matrix) => {
							const statistic = getMatrixStatistic(matrix.id);
							const feedbacks = getFeedbacksForParts(matrix.parts);

							return <Expandable key={matrix.id} title={getPartTitle('matrix', statistic, matrix)}>
								<DisplayMatrix matrix={matrix} statistic={statistic} />

								{feedbacks.length > 0 ?
									<DisplayFeedbacks feedbacks={feedbacks} />
									: null}
							</Expandable>
						})
						: null}
				</div>
			</Expandable>

			<Expandable title={translate('Collected feedback')}>
				{renderCollected(goals, matrices, false)}
			</Expandable>

			<Expandable title={translate('Personal notes')}>
				{renderCollected(goals, matrices, true)}
			</Expandable>
		</div>
	);

}

VerdictAssessmentsDisplay.defaultProps = {
	showGoals: true,
	showMatrices: true,
};

export default VerdictAssessmentsDisplay;
