import React, { Component } from 'react';

import Plus from 'UI/IconsSrc/plus';
import Minus from 'UI/IconsSrc/minus';

class Expandable extends Component {
	static defaultProps = {
		title: 'Visa mer',
		children: '',
		centerContent: false,
		ignorePropsUpdate: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			open: props.open || false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps && nextProps.open != null) {
			if (this.props.ignorePropsUpdate) {
				return true;
			}

			if (nextProps.open != this.state.open) {
				this.toggleOpen(nextProps.open);
			}
		}
	}

	onPress = (event) => {
		event.preventDefault();

		if (this.props.onClick && typeof this.props.onClick == 'function') {
			this.props.onClick(this.toggleOpen);

			return true;
		}

		this.toggleOpen();
	};

	onPlusPress = (event) => {
		event.preventDefault();

		if (this.props.onPlus && typeof this.props.onPlus == 'function') {
			this.props.onPlus(this.toggleOpen);

			return true;
		} else {
			if (this.props.onClick && typeof this.props.onClick == 'function') {
				this.onPress(event);

				return true;
			}
		}

		this.toggleOpen();
	};

	toggleOpen = (isOpen) => {
		if (typeof isOpen != 'undefined' && isOpen != null) {
			this.setState({ open: isOpen });
		} else {
			this.setState({ open: !this.state.open });
		}

		if (this.props.onChange && typeof this.props.onChange == 'function') {
			this.props.onChange(isOpen ? isOpen : !this.state.open);
		}
	};

	getContentClassNames = () => {
		let className = 'expandable-content';
		const { open } = this.state;
		const { centerContent } = this.props;
		const { whiteBackground } = this.props;

		if (open) {
			className += ' open';
		}

		if (centerContent) {
			className += ' centerContent';
		}

		if (whiteBackground) {
			className += ' expandable-white-bg';
		}

		return className;
	};

	getHeaderClassNames = () => {
		let className = 'expandable-header';
		const { open } = this.state;
		const { color } = this.props;

		if (open) {
			className += ' open';
		}

		if (color) {
			className += ' has-color';
		}

		return className;
	};

	render() {
		const { children, title, color, html, headerStyles, contentStyles, contentStylesClosed } =
			this.props;

		const { open } = this.state;
		const style = typeof color !== 'undefined' ? { borderLeftColor: color } : {};

		return (
			<div className='expandable-container'>
				<div className={this.getHeaderClassNames()} style={{ ...headerStyles, ...style }}>
					{this.props.index ? <span>{this.props.index}&nbsp;</span> : null}

					<span onClick={this.onPress} className='title'>
						{title}
					</span>

					<div className='trigger-container' onClick={this.onPlusPress}>
						<span className='trigger'>{open ? <Minus /> : <Plus />}</span>
					</div>
				</div>

				{html != null ? (
					<div
						className={this.getContentClassNames()}
						style={open ? contentStyles : contentStylesClosed}
						dangerouslySetInnerHTML={{ __html: children }}
					/>
				) : (
					<div
						className={this.getContentClassNames()}
						style={open ? contentStyles : contentStylesClosed}
					>
						{this.props.overrideOpen != null && this.props.overrideOpen && open
							? children
							: null}
						{this.props.overrideOpen == null ? children : null}
					</div>
				)}
			</div>
		);
	}
}

export default Expandable;
