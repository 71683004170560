class PermissionManager {
	constructor() { }

	getPermissionTypes = () => {
		return {
			SEARCH_READ_SCHOOL: 'SEARCH_READ_SCHOOL',
			ASSESSMENTS_READ_SCHOOL: 'ASSESSMENTS_READ_SCHOOL',
			SCHEDULE_READ_SCHOOL: 'SCHEDULE_READ_SCHOOL',
			ATTENDANCE_READ_SCHOOL: 'ATTENDANCE_READ_SCHOOL',
			VERDICT_READ_SCHOOL: 'VERDICT_READ_SCHOOL',
			ADDITIONAL_ADJUSTMENTS_READ: 'ADDITIONAL_ADJUSTMENTS_READ',
			MEETINGS_READ_SCHOOL: 'MEETINGS_READ_SCHOOL',
			FILES_READ_SCHOOL: 'FILES_READ_SCHOOL',
			PLANNING_READ_SCHOOL: 'PLANNING_READ_SCHOOL',
			SECTIONS_READ_SCHOOL: 'SECTIONS_READ_SCHOOL',
			GUARDIANS_READ_SCHOOL: 'GUARDIANS_READ_SCHOOL',
			ASSIGNMENT_READ_SCHOOL: 'ASSIGNMENT_READ_SCHOOL'
		};
	};
}

export default PermissionManager;