import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import FileItem from 'components/FileItem';

class Block extends Component {
	renderContent = () => {
		const { children, headerContent, content, time, footerContent, image } = this.props;

		return (
			<div>
				{headerContent ?
					<div className="block-header">
						{headerContent}
					</div>
					: null}

				{image != null ?
					<FileItem file={image} element="div" className="block-image" />
					: null}

				{time ?
					<div className="block-time">
						{time}
					</div>
					: null}

				{content ?
					<div className="block-content">
						{content}
					</div>
					: null}

				{footerContent ?
					<div className="block-footer">
						{footerContent}
					</div>
					: null}

				{children ?
					children
					: null}
			</div>
		)
	}

	render() {
		const { link } = this.props;

		return (
			<div className="block-container" style={this.props.style}>
				{ link != null ?
					<Link to={link}>
						{this.renderContent()}
					</Link>
					:
					this.renderContent()
				}
			</div>
		);
	}
}

export const BlockWrapper = (props) => {
	let columns = false;

	if (props.columns) {
		columns = props.columns;
	} else if (props.cols) {
		columns = props.cols;
	}

	return (
		<div className={`block-wrapper ${columns ? 'cols-' + columns : null}`} style={props.style}>
			{ props.children ?
				props.children
				: null}
		</div>
	);
}

export const BlockHeader = (props) => {
	return (
		<div className="block-header" style={props.style}>
			{ props.children ?
				<h3>{props.children}</h3>
				: null}
		</div>
	);
}

export const BlockContent = (props) => {
	return (
		<div className="block-content" style={props.style}>
			{ props.children ?
				props.children
				: null}
		</div>
	);
}

export const BlockFooter = (props) => {
	return (
		<div className="block-footer" style={props.style}>
			{ props.children ?
				props.children
				: null}
		</div>
	);
}

export const BlockTime = (props) => {
	return (
		<div className="block-time" style={props.style}>
			{ props.children ?
				props.children
				: null}
		</div>
	);
}

export default Block;
