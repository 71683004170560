import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';

import { PhoneInput } from 'UI/Inputs';
import { Button } from '@haldor/ui';

class UserPhone extends Component {

	submit = (values) => {
		return new Promise(resolve => {
			if (values.mobilePhone != null && values.mobilePhone != '') {
				values.mobilePhone = '+' + values.mobilePhone;
				values.mobilePhone = parsePhoneNumber(values.mobilePhone).format('E.164');
			}

			if (this.props.onSubmit != null) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					})
					.catch((error) => {
						this.setState({ error });
						resolve(1);
					});
			} else {
				resolve(1);
			}
		});
	}

	validatePhone = (value) => {
		if (value == null || value == '') {
			return undefined;
		}

		if (value.length < 5) {
			return this.props.translate('Enter a valid phone number');
		}

		const number = '+' + value;
		if (isValidPhoneNumber(number)) {
			return undefined;
		}

		return this.props.translate('Enter a valid phone number');
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onCancel != null) {
			this.props.onCancel();
		}
	}

	render() {
		const { submitting, handleSubmit, valid, translate } = this.props;

		if (this.props.user == null) {
			return null;
		}

		const country = this.props.tenant.lcid.slice(-2);

		return (
			<div className="form-container sms-form">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form className="form form-component file-upload">
					<div className="form-row" style={{ marginTop: 0 }}>
						<label style={{ marginTop: 0 }}>
							{translate('Name')}
						</label>

						<input
							name="name"
							type="text"
							disabled={true}
							value={this.props.user.firstName + ' ' + this.props.user.lastName}
						/>
					</div>

					<div className="form-row">
						<label>
							{translate('Phone number')}
						</label>

						<Field
							name="mobilePhone"
							component={PhoneInput}
							validate={this.validatePhone}
							placeholder={translate('Phone number')}
							countryCode={country}
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid} onClick={handleSubmit(this.submit)} type="primary">
							{translate('Save')}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>

			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		initialValues: ownProps.user,
	};
}

export default connect(mapStateToProps)(reduxForm({
	form: 'UserPhoneForm',
	destroyOnUnmount: true,
})(UserPhone));