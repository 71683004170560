import React, { Component } from 'react';
import { translate, Flex, Checkbox, Button, Icon } from '@haldor/ui';
import { connect } from 'react-redux';
import { getConsentInfo, updateTenantServiceConsent, isConsentPresent } from '../../../actions/services';
import { Spinner } from 'UI';
import Redirect from 'components/Redirect/Redirect';
import { appInsights } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";
import getEnvironment from 'lib/env';

class ConsentConfirm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: 'Haldor',
			consentInfos: [],
			loading: false
		};
	}

	componentDidMount() {
		console.log("Tab configure", this.props);

		appInsights.trackTrace({ message: "Onboarding | Initiated last step in the proccess" });

		this.props.isConsentPresent(this.props.onboardingUser.tenantId).then(() => {
			this.setState({ loading: false });
		})
	}


	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	renderRedirect = () => {
		return <Redirect to={this.state.redirect} />
	}

	onSave() {
		this.setState({ showErrorMessage: null });

		this.props.isConsentPresent(this.props.onboardingUser.tenantId).then(() => {
			if (this.props.isAppConsented) {
				if (!this.inIframe()) {
					console.log("NOT teams: url", window.location.origin);
					window.location.href = window.location.origin;
				} else {
					microsoftTeams.pages.getConfig().then(settings => {
						console.log("settings", settings);

						if (settings.contentUrl != null) {
							var endpoint = settings.contentUrl.replace(window.location.origin, "");

							switch (endpoint) {
								case "/landingpage":
									this.setState({ redirect: "/" });
									break;
								case "/haldor-tab/landingpage":
									this.setState({ redirect: "/haldor-tab" });
									break;
								case "/attendance-tab/landingpage":
									this.setState({ redirect: "/attendance-tab/" });
									break;
								case "/assessments-tab/landingpage":
									this.setState({ redirect: "/assessments-tab/" });
									break;
								case "/planning-tab/landingpage":
									this.setState({ redirect: "/planning-tab/" });
									break;
								case "/assignments-tab/landingpage":
									this.setState({ redirect: "/assignments-tab/" });
									break;
								default:
									this.setState({ redirect: "/" });
									break;
							}
						}
					});
				}
			} else {
				this.setState({ showErrorMessage: this.props.translate('If you need any help or have any questions about the approval of permissions, please contact our support at support@haldor.se') });
			}
		});
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(2)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	showButtonGroup() {
		return (<div>
			<Button type="primary" style={{ marginRight: '0.5rem' }} onClick={this.onSave.bind(this)}>
				{this.props.translate('Continue')}
			</Button>
		</div >)
	}

	render() {
		if (this.state.redirect) {
			return this.renderRedirect()
		}

		if (this.state.loading) {
			return (<Spinner center />)
		}

		let product = getEnvironment().id;
		var productName = 'Haldor Education';
		if (product === 'haldor_planning') {
			productName = "Haldor Planning";
		}

		return <div style={{ background: '#FFF' }}>
			<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '98%', paddingTop: '3%', paddingBlockStart: '3%' }}>
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 2 }}>
						<h2 style={{ textAlign: 'left' }}>{this.props.translate('{0} is approved').replace('{0}', productName)}</h2>
						<p style={{ textAlign: 'left', marginTop: '1.0rem', marginBottom: '1.0rem' }}>{this.props.translate('The next step is to add {0} to your Microsoft Teams environment. Your colleagues can then easily find {0} in Microsoft Teams.').replace('{0}', productName)}</p>
						<a target="_blank" href={this.props.translate('https://haldor.zendesk.com/hc/en-gb/articles/360019874619-Add-Haldor-for-all-users-in-your-Microsoft-Teams-tenant')} style={{ textAlign: 'left', marginTop: '0.5rem' }}>
							{this.props.translate('Read how to do this in our knowledge bank')}
						</a>
					</div>
				</div>

				<div style={{ color: 'red', marginBottom: '0,5rem', marginTop: '0,5rem' }}>
					{this.state.showErrorMessage ? this.state.showErrorMessage : null}
				</div>

				<div style={{ display: "flex", alignItems: "left", justifyContent: "left", marginTop: '1.5rem' }}>
					{this.showButtonGroup()}
				</div>

				<div style={{ width: '20rem', height: '20rem' }}>
					<img src="/dist/svg/illustration_steg06.svg" />
				</div>
			</div>
		</div>

	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		consentInfo: state.Services.consentInfo,
		isAppConsented: state.Services.isAppConsented?.data,
		onboardingUser: state.user.onboardingUser
	};
}


export default connect(mapStateToProps, {
	isConsentPresent
})(ConsentConfirm);


