import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { addError } from 'actions';

import {
	getUserFiles,
	updateUserFile,
	deleteUserFile,
} from 'actions/user';

import {
	addTagsOnFile,
	removeTagOnFile,
} from 'actions/tags';

import Modal from 'containers/Modals/Modal';
import FileUpload from 'containers/Forms/FileUpload';
import FileForm from 'containers/Forms/File';

import { Block, Flex, Icon, Button, TooltipMenu, translate, getActiveLanguage } from '@haldor/ui';
import { Collapsible, Spinner } from 'UI';

import './_userFileArchive.scss';
import User from '_class/User';

class UserFileArchive extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedTag: null,
			uploadModal: false,
			loading: true,
		};
	}

	componentDidMount = () => {
		this.props.getUserFiles(this.props.user).then(() => {
			this.setState({ loading: false })
		});
	}

	_sortAlphabetically = (a, b) => {
		if (a.toUpperCase() < b.toUpperCase())
			return -1;

		if (b.toUpperCase(0) < a.toUpperCase())
			return 1;

		return 0;
	}

	toggleModal = () => this.setState({ uploadModal: !this.state.uploadModal });
	onFileEdit = (file) => this.setState({ activeFile: file });
	closeEditModal = () => this.setState({ activeFile: null });
	onSelectTag = (tag) => this.setState({ selectedTag: tag });

	onFileDelete = (file) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('This file will be deleted permanently'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
			preConfirm: async () => {
				await this.props.deleteUserFile(this.props.user, file.id).catch(() => {
					const { translate } = this.props;
					this.props.addError(translate('something-went-wrong'), 'error');
				});

				swal.close();
			},
		});
	};

	onFileArchive = (file) => {
		this.onFileUpdate({
			...file,
			archived: !file.archived,
		});
	}

	onFileSubmit = (values) => {
		return new Promise(resolve => {
			const { addTagsOnFile } = this.props;
			const lang = getActiveLanguage();

			let tags = [];
			let promises = [];

			if (values.tags != null) {
				values.tags.forEach(tag => {
					tags.push({
						lcid: lang.code,
						...tag,
					});
				})
			}

			if (tags.length > 0) {
				values.files.forEach(file => {
					promises.push(addTagsOnFile(file.id, tags));
				});
			}

			Promise.all(promises).then(() => {
				this.props.getUserFiles(this.props.user);
				resolve(1);
				this.toggleModal();
			});
		});
	}

	onFileUpdate = (values) => {
		return new Promise(resolve => {
			const file = this.props.files.find(file => {
				return file.id == values.id;
			});

			const lang = getActiveLanguage();

			let promises = [];
			let newTags = [...values.tags.filter(tag => tag.id == null)];
			let oldTags = [...file.tags.filter(tag => {
				return values.tags.find(currentTag => currentTag.name == tag.name) == null;
			})];

			if (newTags.length > 0) {
				let tags = []
				newTags.forEach(tag => {
					tags.push({
						lcid: lang.code,
						tenantId: 0,
						...tag,
					});
				})

				promises.push(this.props.addTagsOnFile(values.id, tags));
			}

			if (oldTags.length > 0) {
				oldTags.forEach(tag => {
					promises.push(this.props.removeTagOnFile(values.id, tag.id));
				});
			}

			promises.push(this.props.updateUserFile(this.props.user, values));

			Promise.all(promises).then(() => {
				this.props.getUserFiles(this.props.user);
				this.closeEditModal();

				resolve(1);
			});
		})
	}

	renderFile = (file, index) => {
		const { translate } = this.props;

		const user = new User(this.props.currentUser);
		let visible = true;

		if (this.state.selectedTag != null) {
			visible = file.tags.find(tag =>
				tag.name == this.state.selectedTag
			) != null;
		}

		if (!visible) {
			return null;
		}

		return (
			<a href={file.url} target="_blank" key={'file-' + index} className="file">
				<div className="df aic">
					<Icon name="File" />

					{file.name}

					{file.tags != null && file.tags.length > 0 ?
						<div className="tags">
							{file.tags.map((tag, index) => {
								return <div key={'filetag-' + index} className="tag">
									{tag.name}
								</div>
							})}
						</div>
						: null}
				</div>

				{user.isMentor() ?
					<div className="df aic">
						<TooltipMenu
							trigger={<Icon name="Bullets" />}
						>
							<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.onFileEdit(file) }}>
								<Icon name="Pen_Small" /> {translate('Edit tags')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.onFileArchive(file) }}>
								<Icon name="Archive" /> {file.archived ?
									translate('Restore')
									: translate('Archive')}
							</TooltipMenu.Item>

							<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.onFileDelete(file); }}>
								<Icon name="Bin" /> {translate('Delete')}
							</TooltipMenu.Item>
						</TooltipMenu>
					</div> : null}
			</a>
		);
	}

	render() {
		const { translate } = this.props;
		const user = new User(this.props.currentUser);

		let tags = [];
		let files = [];
		let archivedFiles = [];
		this.props.files.forEach(file => {
			if (file.tags != null) {
				file.tags.forEach(tag => {
					if (tags.indexOf(tag.name) == -1) {
						tags.push(tag.name);
					}
				})
			}
		})

		files = [...this.props.files].filter(file =>
			!file.archived
		).sort((a, b) => {
			return this._sortAlphabetically(a.name, b.name);
		});

		archivedFiles = [...this.props.files].filter(file =>
			file.archived
		).sort((a, b) => {
			return this._sortAlphabetically(a.name, b.name);
		});

		if (tags.length > 0) {
			tags = tags.sort(this._sortAlphabetically);
		}

		return (
			<div className="user-file-archive">
				<Modal type="small" title={translate('upload-files')} isOpen={this.state.uploadModal} onClose={this.toggleModal}>
					<FileUpload
						userId={this.props.user}
						onSubmit={this.onFileSubmit}
						onCancel={this.toggleModal}
					/>
				</Modal>

				<Modal type="small" title={translate('Edit tags')} isOpen={this.state.activeFile != null} onClose={this.closeEditModal}>
					<FileForm
						onCancel={this.closeEditModal}
						onSubmit={this.onFileUpdate}
						initialValues={this.state.activeFile}
					/>
				</Modal>

				{this.state.loading ?
					<Spinner center />
					: <Block>
						<Flex>
							<div className="sider">
								<Collapsible trigger={translate('Tags')} open={true}>
									{tags.map((tag, index) => {
										return <div key={'tag-' + index}>
											<div className="tag" onClick={() => this.onSelectTag(tag)}>
												{tag}
											</div>
										</div>
									})}

									{this.state.selectedTag != null ?
										<span
											style={{ marginTop: '0.25rem', cursor: 'pointer' }}
											onClick={() => this.setState({ selectedTag: null })}
										>
											{this.props.translate('show-all')}
										</span>
										: null}
								</Collapsible>
							</div>

							<div style={{ flex: 1 }}>
								<span className="title">
									{translate('Files')}

									{this.state.selectedTag != null ?
										<span
											style={{ position: 'relative', top: '-1px', marginLeft: '.55rem', cursor: 'pointer' }}
											className="color--meta size-12"
											onClick={() => this.setState({ selectedTag: null })}
										>
											#{this.state.selectedTag}
										</span>
										: null}
								</span>

								<div className="files">
									{files.map(this.renderFile)}

									{archivedFiles.length > 0 ?
										<div className="archived-files">
											<Collapsible trigger={translate('archived')}>
												{archivedFiles.map(this.renderFile)}
											</Collapsible>
										</div>
										: null}
								</div>

								<div style={{ marginTop: '1.55rem' }} />
								{user.isMentor() ? <Button type="secondary" onClick={this.toggleModal}>
									<Icon name="Plus" />
									{translate('upload-files')}
								</Button> : null}
							</div>
						</Flex>
					</Block>
				}
			</div>
		)
	}

}

function mapStateToProps(state) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		files: state.user.files,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getUserFiles,
	addTagsOnFile,
	updateUserFile,
	removeTagOnFile,
	deleteUserFile,
	addError,
})(UserFileArchive);