import { SAVE_FILTER_VALUE } from '../actions/detailstable';

const INITIAL_STATE = {
	filter: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SAVE_FILTER_VALUE:
			var arr = state.filter;
			arr = arr.filter(item => item.id != action.payload.id);
			arr.push(action.payload);
			return { ...state, filter: arr };
		default:
			return state;
	}
}
