import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Person, Skeleton } from 'UI';

import { User } from 'UI/Icons';
import { Icon } from '@haldor/ui';

class ConversationHeader extends Component {

	renderMeta = () => {
		const { conversation } = this.props;

		return conversation.relationships.map((relationship, i) => {
			if (relationship.reference != null) {
				if (relationship.referenceType == 'ASSIGNMENT') {
					return <div key={i}>
						<Link to={`/assignment/${relationship.referenceID}`}>
							<Icon name="Assignments" /> {relationship.reference.title}
						</Link>
					</div>
				}

				if (relationship.referenceType == 'PLAN') {
					return <div key={i}>
						<Link to={`/plan/${relationship.referenceID}`}>
							<Icon name="Plans" /> {relationship.reference.title}
						</Link>
					</div>
				}

				if (relationship.referenceType == 'SECTION') {
					return <div key={i}>
						<Link to={`/groups/${relationship.referenceID}`}>
							<Icon name="People" /> {relationship.reference.title}
						</Link>
					</div>
				}

				if (relationship.referenceType == 'STUDENT') {
					return <div key={i}>
						<Link to="#">
							<Icon name="People" /> {relationship.reference.firstName} {relationship.reference.lastName}
						</Link>
					</div>
				}

				if (relationship.referenceType == 'MEETING_INSTANCE') {
					return <div key={i}>
						<Link to={`/meeting/${relationship.reference.id}/instance/${relationship.referenceID}`}>
							<Icon name="Agenda" /> {relationship.reference.title}
						</Link>
					</div>
				}
			}

			return null;
		});
	}

	render() {
		const { conversation } = this.props;

		return (
			<div className="conversation conversation-header">
				{conversation != null && conversation.relationships.length > 0 ?
					<div className="meta-container">
						{this.renderMeta()}
					</div>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
	};
}

export default connect(mapStateToProps)(ConversationHeader);
