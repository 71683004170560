import api from 'lib/api';

export const GET_FLAGGED_MESSAGES = 'CONVERSATIONS_ADMIN_GET_FLAGGED_MESSAGES';
export const UPDATE_FLAGGED_MESSAGE = 'CONVERSATIONS_ADMIN_UPDATE_FLAGGED_MESSAGE';
export const DELETE_FLAGGED_MESSAGE = 'CONVERSATIONS_ADMIN_DELETE_FLAGGED_MESSAGE';

export const getFlaggedMessages = () => {
	return {
		type: GET_FLAGGED_MESSAGES,
		payload: new Promise(resolve => {
			api.get(`conversations/flagged`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const updateFlaggedMessage = (flag) => ({
	type: UPDATE_FLAGGED_MESSAGE,
	payload: new Promise(resolve => {
		api.put('conversations/flagged', flag).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteFlaggedMessage = (flagId) => ({
	type: DELETE_FLAGGED_MESSAGE,
	payload: new Promise((resolve) => {
		api.delete(`conversations/flagged/${flagId}`).then((response) => {
			resolve(flagId);
		});
	}),
});
