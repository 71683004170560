import React from 'react';

import { HaldorBlockConsumer } from '@haldor/ui';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import { useTranslate } from 'lib/translate';
import TeacherAssessmentBlock from './Blocks/TeacherAssessmentBlock/TeacherAssessmentBlock';

const BlockConsumer = (props) => {
	const translate = useTranslate();

	const getFields = () => {
		let assessmentFeedback = props.assessment?.assessmentFeedbacks?.length > 0;

		let assessmentBlocks = props.fields.filter(s => assessmentFeedback || s.resources.some(r => r.referenceType == 'assessmentonlyfeedback' && !props.isStudent || (r.assessmentBlockPartRows != null && r.assessmentBlockPartRows?.length > 0)))


		return assessmentBlocks
			.sort((a, b) => a.sortorder - b.sortorder)
			.map((field) => {
				let label = translate('Teacher assessment');

				if (field.assessmentType == ASSESSMENT_TYPES.PEER) {
					label = translate('Peer review');
				}

				if (field.assessmentType == ASSESSMENT_TYPES.SELF) {
					label = translate('Self assessment');
				}

				const ofType = assessmentBlocks.filter((block) =>
					block.assessmentType == field.assessmentType
				);

				const foundIndex = ofType.findIndex((b) => b.id == field.id);
				field.title = label + ' ' + (foundIndex + 1);
				return field;
			});
	}

	return (
		<div className="assessment-block">
			{getFields()?.length > 0 ? <h3 style={{ marginBottom: '1rem', fontWeight: 500 }}>
				{translate('Assessment')}
			</h3> : null}
			<HaldorBlockConsumer
				plugins={[
					TeacherAssessmentBlock
				]}
				translate={translate}
				{...props}
				fields={getFields()}
			/>
		</div>
	);
}

export default BlockConsumer;