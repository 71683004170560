/* Import dependencies */
import api from 'lib/api';

/* Define action type */
export const SUBMIT_PRESCHOOL_GOAL = 'SUBMIT_PRESCHOOL';
export const DELETE_PRESCHOOL_GOAL = 'DELETE_PRESCHOOL';
export const SEARCH_PRESCHOOL_GOALS = 'SEARCH_PRESCHOOL_GOALS';
export const FETCH_PRESCHOOL_GOALS = 'FETCH_PRESCHOOL_GOALS';
export const GET_PRESCHOOL_GOALS_STATISTICS = 'PRESCHOOL_GOALS_GET_GOALS_STATISTICS';
export const GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'PRESCHOOL_GOALS_GET_GOAL_PLANS_AND_DOCUMENTATIONS';
export const RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS = 'RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS';
export const GET_PRESCHOOL_GOALS_USER_STATISTICS = 'PRESCHOOL_GOALS_GET_USER_STATISTICS'

export function submit_preschoolgoal(values) {
	return {
		type: SUBMIT_PRESCHOOL_GOAL,
		value: values,
	}
}

export function fetch_preschoolgoals() {
	return {
		type: FETCH_PRESCHOOL_GOALS,
		payload: new Promise((resolve, reject) => {
			api.get('goals').then((response) => {
				resolve(response.data);
			})
		}),
	}
}

export function delete_preschoolgoal(values) {
	return {
		type: DELETE_PRESCHOOL_GOAL,
		value: values,
	}
}

export function search_preschoolgoals(search) {
	return {
		type: SEARCH_PRESCHOOL_GOALS,
		value: search,
	}
}

export const getGoalsStatisticsForUsers = (sectionId, start, end) => ({
	type: GET_PRESCHOOL_GOALS_STATISTICS,
	payload: new Promise(resolve => {
		api.get(`goals/statistics/sections/${sectionId}?from=${start}&to=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getGoalsStatisticsForUser = (studentId, start, end) => ({
	type: GET_PRESCHOOL_GOALS_USER_STATISTICS,
	payload: new Promise(resolve => {
		api.get(`goals/statistics/users/${studentId}?from=${start}&to=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getLinkedPlansAndDocumententationsForGoal = (goalId, studentId, from, to) => ({
	type: GET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
	payload: new Promise(resolve => {
		api.get(`goals/${goalId}/plansAndDocumentations?from=${from}&to=${to}&studentId=${studentId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const resetLinkedPlansAndDocumentations = () => ({
	type: RESET_PRESCHOOL_GOAL_PLANS_AND_DOCUMENTATIONS,
	payload: true,
});