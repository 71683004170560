import React from 'react';
import CoursesSection from 'containers/Overview/Sections/Courses';

const Courses = () => {

	return (
		<div className="overview">
			<div className="section" style={{ padding: 0, margin: 0, border: 0 }}>
				<CoursesSection />
			</div>
		</div>
	);

};

export default Courses;
