import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'lib/translate';
import { Form, Field } from 'react-final-form';
import User from '_class/User';

import { clearSearch, searchSchoolUsers } from 'actions/search';

import { Spinner } from 'UI';
import { Search } from 'UI/Inputs';
import { Button, Radio } from '@haldor/ui';

import './_SelectUserForm.scss';

const SelectUserForm = (props) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const teachers = useSelector(state => state.Search.teachers);

	const onSearch = (values) => {
		if (values.query == '') {
			dispatch(clearSearch());
			return true;
		}

		if (values.query.length < 3) {
			return false;
		}

		dispatch(searchSchoolUsers(values.query, 'teacher', ''));
	}

	const onSubmit = (values) => new Promise((resolve) => {
		if (props.onSubmit != null) {
			props.onSubmit(values).then(() => {
				resolve(1);
			});

			dispatch(clearSearch());
		}
	})

	return <div className="form select-user-form">
		<Form
			onSubmit={onSubmit}
			mutators={{
				clearUserId: (args, state, utils) => {
					utils.changeValue(state, 'userId', () => '');
				},
			}}
			render={({ handleSubmit, form, submitting, valid }) => {
				const userId = form.getFieldState('userId')?.value;

				return <form onSubmit={handleSubmit} className="form-component">
					{submitting ?
						<div className="is_sending">
							<p>
								<span className="loading-spinner" />
							</p>
						</div>
						: null}

					<div className="form-row">
						<div className="title">
							{translate('Add schedule')}
						</div>
					</div>

					<div className="form-row" style={{ marginTop: '1.35rem' }}>
						<Search
							onSubmit={(values) => {
								form.mutators.clearUserId();
								onSearch(values);
							}}
							placeholder={translate('Search for teacher')}
							allowEmpty
						/>
					</div>

					{teachers.length > 0 ?
						<div className="form-row user-list">
							<div style={{ background: '#f2f3f2', padding: '1rem' }}>
								{teachers.map((teacher) => {
									if (props.forbiddenUsers != null) {
										if (props.forbiddenUsers.indexOf(teacher.userId) > -1) {
											return null;
										}
									}

									const user = new User(teacher);
									return <div className="user">
										<Field
											component={Radio}
											name="userId"
											label={user.getName()}
											optionValue={teacher.userId}
											key={teacher.userId}
										/>
									</div>
								})}
							</div>
						</div>
						: null}

					<div className="form-row" style={{ marginTop: '1.35rem' }}>
						<Button disabled={submitting || userId == null || userId == ''}>
							{submitting ?
								<Spinner />
								: null}

							{translate('Add')}
						</Button>
					</div>
				</form>
			}}
		/>
	</div>
}

export default SelectUserForm;