import { setPageTitle } from 'actions/header';
import { clearFormTemplate, getFormTemplate } from 'actions/templates';
import Redirect from 'components/Redirect/Redirect';
import FormTemplate from 'containers/Forms/FormTemplate';
import PreviewFormTemplate from 'containers/FormTemplate/Display/PreviewFormTemplate';
import Modal from 'containers/Modals/Modal';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';

import { Block, Icon, Button } from '@haldor/ui';

class SingleFormTemplatePage extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			modal: false,
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		this.props.getFormTemplate(this.props.match.params.id)
			.then(() => {
				this.props.setPageTitle(this.props.template.title);
				this.setState({ loading: false });
			});
	}

	componentWillUnmount = () => {
		this.props.clearFormTemplate();
	}

	/* Events */
	toggleModal = (reload) => {
		this.setState({ modal: !this.state.modal });

		if (reload) {
			this.props.setPageTitle(this.props.template.title);
		}
	}

	/* Render methods */
	render() {
		if (!this.props.services.forms) {
			return <Redirect to="/" />
		}

		if (this.state.loading) {
			return <Spinner center />
		}

		const { template, translate } = this.props;

		return (
			<div className="single-task">
				<Modal isOpen={this.state.modal} onClose={() => this.toggleModal(false)} title={translate('form-template')}>
					<FormTemplate initialValues={template} onAbort={this.toggleModal} />
				</Modal>

				<div className="single-section form left-side" style={{ paddingTop: 0 }}>
					<Block>
						<span className="title">{translate('preview')}</span>
						<PreviewFormTemplate fields={template.fields} />
					</Block>
				</div>
				{template.instances == 0 ?
					<div className="single-actions right-side">
						<div className="action-section">
							<h3>{translate('tools')}</h3>

							<Button type="secondary" onClick={this.toggleModal}>
								<Icon name="Pen_Small" />
								{translate('Edit')}
							</Button>

						</div>
					</div>
					: null}
				<div className="clearfix"></div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		template: state.Templates.formTemplate,
	};
}

export default connect(mapStateToProps, {
	getFormTemplate,
	setPageTitle,
	clearFormTemplate,
})(SingleFormTemplatePage);
