import {
	GET_SINGLE_SCHOOL_STUDENTS,
	GET_SINGLE_SCHOOL_DETAILS,
	GET_SCHOOL_ABSENCE,
	GET_SCHOOL_GUARDIANS,
	UPDATE_SCHOOL_TYPE,
	GET_SCHOOL_SUBJECTTEACHERS,
	GET_SCHOOL_SERVICES,
	GET_SCHOOL_DATA_FOR_SECTIONS,
	GET_SCHOOLS_DATA_FOR_SECTIONS,
	GET_SINGLE_SCHOOL_BY_EXTERNALID
} from 'actions/schools';

import {
	DELETE_ABSENCE
} from 'actions/absence';

const INITIAL_STATE = {
	school: null,
	active: null,
	students: null,
	absence: null,
	guardians: [],
	subjectTeacher: [],
	schoolServices: null,
	schoolDataForSection: null,
	schoolsDataForSection: null
};

export default function (state = INITIAL_STATE, action) {

	switch (action.type) {
		case GET_SINGLE_SCHOOL_BY_EXTERNALID:
			return { ...state, school: action.payload };

		case GET_SINGLE_SCHOOL_STUDENTS:
			return { ...state, students: action.payload };

		case GET_SINGLE_SCHOOL_DETAILS:
			return { ...state, active: action.payload };
		case GET_SCHOOLS_DATA_FOR_SECTIONS:
			return { ...state, schoolsDataForSection: action.payload };

		case GET_SCHOOL_DATA_FOR_SECTIONS:
			return { ...state, schoolDataForSection: action.payload };

		case GET_SCHOOL_ABSENCE:
			return { ...state, absence: action.payload };

		case GET_SCHOOL_GUARDIANS:
			return { ...state, guardians: action.payload };

		case UPDATE_SCHOOL_TYPE:
			let active = state.active;
			if (active != null) {
				if (active.id == action.payload.id) {
					return { ...state, active: action.payload };
				}
			}

			return state;

		case GET_SCHOOL_SUBJECTTEACHERS:
			return { ...state, subjectTeacher: action.payload };

		case GET_SCHOOL_SERVICES:
			return { ...state, schoolServices: action.payload };

		default:
			return state;

	}

}
