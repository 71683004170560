import React, { PureComponent, Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import DateTime from '_class/DateTime';
import swal from 'sweetalert2';

import {
	addComment,
	updateStudentAdjustment,
	deleteStudentAdjustment,
} from 'actions/additional_adjustments';

import EditAdditionalAdjustmentSubjects from '../Form/EditAdditionalAdjustmentSubjects';
import EditStudentAdjustmentForm from '../Form/EditStudentAdjustmentForm';

import PostContent from 'components/Presentation/PostContent';
import Modal from 'containers/Modals/Modal';
import Comments from './Partials/Comments';

import { Collapsible } from 'UI';
import { Icon, Flex, Button } from '@haldor/ui'
import DisplayName from 'components/Presentation/DisplayName';

class AdditionalAdjustmentModal extends Component {

	constructor(props) {
		super(props);

		this.state = {
			subjectsModal: null,
			editModal: null,
		}
	}

	toggleCourseModal = (adjustment) => {
		if (adjustment == null) {
			this.setState({ subjectsModal: null });
		} else {
			let clonedAdjustment = JSON.parse(JSON.stringify(adjustment));
			this.setState({ subjectsModal: clonedAdjustment });
		}
	}

	toggleEditModal = (adjustment) => {
		if (adjustment == null) {
			this.setState({ editModal: null });
		} else {
			let clonedAdjustment = JSON.parse(JSON.stringify(adjustment));
			this.setState({ editModal: clonedAdjustment });
		}
	}

	onAdjustmentSubmit = (values) => {
		return new Promise(resolve => {
			// Clean up properties that doesnt need to be sent to the api
			delete values.comments;
			delete values.courses;
			delete values.subjects;
			delete values.createdBy;
			delete values.editedBy;
			delete values.versions;
			delete values.student;

			this.props.updateStudentAdjustment(values).then(() => {
				this.setState({ editModal: null });
				resolve(1);
			})
		})
	}

	deleteAdjustment = (adjustment) => {
		const { translate } = this.props;

		swal.fire({
			title: translate('Are you sure?'),
			text: translate('The adjustment will be removed from the student wherever it is used. Restoration can only be done by a special educational needs teacher.'),
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('Remove'),
		}).then(async (response) => {
			if (response.value != null) {
				this.props.deleteStudentAdjustment(adjustment);
			}
		});
	}

	addComment = (values, adjustmentId) => {
		return new Promise(resolve => {
			this.props.addComment(values, adjustmentId).then(() => {
				resolve(1);
			});
		});
	}

	onClose = () => {
		if (this.props.onClose != null) {
			this.props.onClose();
		}
	}

	renderAdjustment = (adjustment) => {
		const { translate } = this.props;

		const collapseTrigger = (
			<strong className="trigger" style={{ cursor: 'pointer' }}>
				<span>
					{this.props.translate('subjects')} {adjustment.subjects.length}
				</span>

				{!this.props.disableCommenting ?
					<span onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.toggleCourseModal(adjustment)
					}}>
						<Icon name="Pen_Small" />
					</span>
					: null}
			</strong>
		)

		return <div key={adjustment.id} className="adjustment adjustment-item" style={{ marginBottom: '3rem' }}>
			<Flex>
				<div style={{ flex: 1 }}>
					<div>
						<PostContent>
							{adjustment.description}
						</PostContent>

						<div className="createdBy color--meta">
							{adjustment.createdBy.firstName} {adjustment.createdBy.lastName}
							{' • ' + new DateTime(adjustment.created).getTimeStampHours()}

							{adjustment.subjects.length > 0 ?
								' • '
								: null}

							{adjustment.subjects != null ?
								adjustment.subjects.map((subject, index) => {
									let title = subject.title;
									if (index != adjustment.subjects.length - 1) {
										title += ', ';
									}

									return <span className="subject" key={subject.id}>
										{title}
									</span>
								})
								: null}
						</div>
					</div>

					<div style={{ marginTop: '1rem' }}>
						{this.props.disableCommenting == true ?
							<Comments
								form={'comment-' + adjustment.id}
								adjustment={adjustment}
							/>
							:
							<Comments
								form={'comment-' + adjustment.id}
								adjustment={adjustment}
								onSubmit={(values) => this.addComment(values, adjustment.id)}
							/>
						}
					</div>
				</div>

				<div className="adjustment-meta size-14" style={{ flexBasis: '25%' }}>
					{adjustment.status == 'ACTIVE' ?
						<div>
							<strong>{translate('start-time')}</strong>
							<br />

							{new DateTime(adjustment.startDate).getLongDate()}
						</div>
						:
						<div>
							<strong>{translate('time-period')}</strong>
							<br />

							{new DateTime(adjustment.startDate).getLongDate()}
							{' - ' + new DateTime(adjustment.endDate).getLongDate()}
						</div>
					}

					<div style={{ marginTop: '1rem' }}>
						<strong >{this.props.translate('Status')}</strong>
						<br />
						{this.props.translate(adjustment.status.toLowerCase())}
					</div>

					<div className="meta-courses" style={{ marginTop: '1rem' }}>
						<Collapsible trigger={collapseTrigger}>
							{adjustment.subjects != null ?
								adjustment.subjects.map(subject => {
									return <div key={subject.id}>
										{subject.title}
									</div>
								})
								: null}
						</Collapsible>
					</div>

					{!this.props.disableCommenting ?
						<div style={{ marginTop: '1rem' }}>
							<Button type="secondary" onClick={() => this.toggleEditModal({ ...adjustment })}>
								<Icon name="Pen_Small" /> {translate('Edit')}
							</Button>
						</div>
						: null}

					{!this.props.disableCommenting ?
						<div style={{ marginTop: '.5rem' }}>
							<Button type="secondary" onClick={() => this.deleteAdjustment(adjustment)}>
								<Icon name="Bin" /> {translate('Remove')}
							</Button>
						</div>
						: null}
				</div>
			</Flex>
		</div>
	}
	
	render() {
		const { adjustment, translate } = this.props;

		if (adjustment == null) {
			return null;
		}

		let title = adjustment.title;
		if (adjustment.subTitle != null) {
			title += ' - ' + adjustment.subTitle
		}

		return (
			<Modal type="small" onClose={this.onClose} isOpen={true} title={title}>
				<div key={'edit-subjects-modal'}>
					{this.state.subjectsModal != null ?
						<Modal isOpen={true} onClose={() => this.toggleCourseModal()} type="small" title={translate('Subjects')}>
							<EditAdditionalAdjustmentSubjects
								adjustment={this.state.subjectsModal}
								onClose={this.toggleCourseModal}
								/>
						</Modal>
						: null}
				</div>

				{this.state.editModal != null ?
					<Modal isOpen={true} onClose={() => this.toggleEditModal()} title={translate('Edit adjustment')}>
						<EditStudentAdjustmentForm
							adjustment={this.state.editModal}
							onClose={this.toggleEditModal}
							onSubmit={this.onAdjustmentSubmit}
							/>
					</Modal>
					: null}

				<div style={{ padding: '0 1.75em' }}>
					<div className="adjustment teacher-items-adjustments">
						{adjustment.items.map(student => {
							let activeItems = student.items.find(adjustment => {
								return adjustment.status == 'ACTIVE';
							});
							
							if (activeItems == null) {
								return null;
							}
							
							return <div className="student" key={'student-adjustment-' + student.userId}>
								<h2>
									<DisplayName
									firstName={student.firstName}
									lastName={student.lastName}
									email={student.email}
									showEmail={true}								
									/>
								</h2>

								{student.items.map(adjustment => {
									if (adjustment.status != 'ACTIVE') {
										return null;
									}

									return this.renderAdjustment(adjustment);
								})}
							</div>
						})}
					</div>
				</div>
			</Modal>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	addComment,
	updateStudentAdjustment,
	deleteStudentAdjustment,
})(AdditionalAdjustmentModal);
