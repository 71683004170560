import React from 'react';

const Trash = props => (
  <svg version="1.1" className="icon-trash" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px"viewBox="0 0 136.1 136.1">
    <g>
      <path d="M104.2,33h-18l0-6.1c0-4.7-3.8-8.5-8.5-8.5l-19.3,0c0,0,0,0,0,0c-4.7,0-8.5,3.8-8.5,8.5l0,6.1H37.2l-2.7,0v0h-2.7c-1.6,0-2.8,1.3-2.8,2.8s1.3,2.8,2.8,2.8h2.7l0,62.7c0,3.7,1.4,7.1,4,9.7c2.6,2.6,6.1,4,9.7,4l39.5,0c0,0,0,0,0,0c3.7,0,7.1-1.4,9.7-4c2.6-2.6,4-6.1,4-9.7l0-62.7h2.6c1.6,0,2.8-1.3,2.8-2.8S105.8,33,104.2,33z M55.6,33l0-6.1c0-1.6,1.3-2.8,2.8-2.8c0,0,0,0,0,0l19.3,0c1.6,0,2.8,1.3,2.8,2.8l0,6.1H68.7L55.6,33z M95.9,101.4c0,2.2-0.8,4.2-2.4,5.7c-1.5,1.5-3.6,2.4-5.7,2.4c0,0,0,0,0,0l-39.5,0c-2.2,0-4.2-0.8-5.7-2.4s-2.4-3.6-2.4-5.7l0-62.7l55.7,0L95.9,101.4z" />
      <path d="M52.7,98.5C52.7,98.5,52.7,98.5,52.7,98.5c1.6,0,2.8-1.3,2.8-2.8l0-39.9c0-1.6-1.3-2.8-2.8-2.8c0,0,0,0,0,0c-1.6,0-2.8,1.3-2.8,2.8l0,39.9C49.9,97.2,51.1,98.5,52.7,98.5z" />
      <path d="M68,98.5L68,98.5c1.6,0,2.8-1.3,2.8-2.8l0-39.9c0-1.6-1.3-2.8-2.8-2.8h0c-1.6,0-2.8,1.3-2.8,2.8l0,39.9C65.2,97.2,66.5,98.5,68,98.5z" />
      <path d="M83.4,98.5L83.4,98.5c1.6,0,2.8-1.3,2.8-2.8l0-39.9c0-1.6-1.3-2.8-2.8-2.8h0c-1.6,0-2.8,1.3-2.8,2.8l0,39.9C80.6,97.2,81.8,98.5,83.4,98.5z" />
    </g>
  </svg>
);

export default Trash;
