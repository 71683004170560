import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RowItem extends Component {

	render() {
		let props = { ...this.props };
		delete props.children;
		delete props.target;

		if (this.props.target != null) {
			return (
				<Link className="row" to={this.props.target} {...props}>
					{this.props.children}
				</Link>
			);
		}

		return (
			<div className="row" {...this.props}>
				{this.props.children}
			</div>
		);
	}

}

export const RowCell = (props) => {
	let className = 'cell';
	if (props.grow) {
		className += ' grow';
	}

	if (props.shrink) {
		className += ' shrink';
	}

	let elementProps = { ...props };
	delete elementProps.grow;
	delete elementProps.shrink;
	delete elementProps.children;
	delete elementProps.title;

	return (
		<div className={className} title={props.title} {...elementProps}>
			{props.children}
		</div>
	);
};

export default RowItem;
