import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import { useTranslate } from "lib/translate";

import BlockAssessments from "components/BlockAssessments";

import { Button, Expandable } from '@haldor/ui';

const KnowledgeRequirements = (props) => {
	const translate = useTranslate();
	const section = useSelector((state) => state.sections.activeSection);
	const activeAssignment = useSelector((state) => state.assignments.active_assignment);
	const details = useSelector((state) => state.assignments.teamsAssignmentDetails);
	const blocks = useSelector((state) => state.Blocks.reference);

	const initialValues = {
		assessmentBlocks: blocks?.filter((block) => block.type == 'Haldor.Blocks.AssessmentBlock'),
		...activeAssignment,
		...details,
	};

	const submit = (values) => {
		return new Promise(resolve => {
			let assignment = null;
			if (values.id === parseInt(values.id, 10)) {
				assignment = values;
			} else {
				assignment = {
					externalId: values.id,
					origin: "TEAMS",
					title: values.displayName,
					dueDate: values.dueDateTime,
					description: values.instructions.content,
					sectionId: section.id,
					assessmentBlocks: values.assessmentBlocks,
				};
			}

			// Edit values here before posting them to the parent component
			if (props.onSubmit != null) {
				props.onSubmit(assignment).then(() => {
					resolve(assignment);
				});
			}
		});
	};

	return (
		<div className="form-container">
			<Form
				onSubmit={submit}
				initialValues={initialValues}
				render={({ handleSubmit, submitting }) => {
					return <Fragment>
						{submitting ? (
							<div className="is_sending" style={{ height: "100%" }}>
								<p style={{ position: "absolute" }}>
									<span className="loading-spinner" />
								</p>
							</div>
						) : null}

						<form onSubmit={handleSubmit} className="form form-component">
							<Expandable title={translate('Assessments')} open ignorePropsUpdate>
								<Field
									component={BlockAssessments}
									name="assessmentBlocks"
								/>
							</Expandable>

							<div className="form-row spacing submit">
								<Button>
									{translate("save-knowledge-requirements")}
								</Button>

								<div className="clearfix"></div>
							</div>
						</form>
					</Fragment>
				}}
			/>
		</div>
	);
}

export default KnowledgeRequirements;
