import React, { Fragment } from 'react';
import ColorScale from '_class/ColorScale';
import { capitalize } from 'helpers/content';
import { useTranslate } from 'lib/translate';
import { Expandable, Icon } from '@haldor/ui';
import { Collapsible, ColorLabel, ColorLabelContainer } from 'UI';
import DOMPurify from 'dompurify';

import moment from 'moment';
const DisplayResource = (props) => {
	const translate = useTranslate();
	const { resource, block } = props;
	const assessment = block.api.getEditor().getProp('assessment');

	/**
	 * @param {object} row
	 */
	const getRowData = (row) => {
		if (row.referenceType == 'matrixRow') {
			let foundRow = null;
			let addNotAchieved = true;
			resource.value?.details?.forEach((_detail) => {
				_detail?.matrix?._ParagraphSet?.forEach((_paragraph) => {
					const matchingRow = _paragraph.rows.find((_row) => _row.id == row.referenceId);

					if (matchingRow != null) {
						if (_detail.matrix.disableNotAchieved) {
							addNotAchieved = false;
						}

						foundRow = JSON.parse(JSON.stringify(matchingRow));
					}
				})
			});

			if (foundRow != null && addNotAchieved) {
				if (foundRow?.cells.find(cell => cell.id == 'F') == null) {
					foundRow?.cells.unshift({
						text: translate('Not achieved'),
						id: 'F',
					});
				}
			}

			return foundRow;
		}

		if (row.referenceType == 'assessmentGoalRow') {
			let foundRow = resource.value?.rows.find((_row) =>
				_row.id == row.referenceId
			);

			if (foundRow != null) {
				foundRow.cells = foundRow.cells?.sort((a, b) => a.index - b.index);
			}

			return foundRow;
		}
	}

	/**
	 * @param {object} row
	 */
	const getRowParagraph = (row) => {
		if (row.referenceType == 'matrixRow') {
			let foundParagraph = null;
			resource.value?.details?.forEach((_detail) => {
				_detail?.matrix?._ParagraphSet?.forEach((_paragraph) => {
					const matchingRow = _paragraph.rows.find((_row) => _row.id == row.referenceId);

					if (matchingRow != null) {
						foundParagraph = _paragraph;
					}
				})
			});

			return foundParagraph;
		}

		return null;
	}

	/**
	 * @param {object} row
	 */
	const getRowClarifications = (row) => {
		if (props.clarifications == null) {
			return [];
		}

		const found = props.clarifications.find((clarification) => {
			if (clarification.value == null) {
				return false;
			}

			return clarification.value.title == row.id;
		});

		if (found == null) {
			return [];
		}

		return found.value.rows;
	}

	const getCourseDetail = () => {
		if (resource.value == null || resource.value.details == null) {
			return null;
		}

		return resource.value.details.find((detail) =>
			detail.matrixID == resource.referenceId
		);
	}

	const renderExpiredText = () => {
		const courseDetail = getCourseDetail();

		if (courseDetail != null && courseDetail.endDate != null && moment(courseDetail.endDate).isBefore(moment())) {
			return (
				<div>
					<Icon style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }} name="Alert_Red" ></Icon>
					{translate('Expired')}
				</div>
			);
		}

		return null;
	}

	/**
	 * @param {object} resource
	 */
	const getResourceTitle = (resource) => {
		const translate = block.api.getEditor().getProp('translate');
		const isStudent = block.api.getEditor().getProp('isStudent');
		let text = '';
		if (resource.referenceType == 'assessmentGoal') {
			text = translate('Goal');
		}

		if (resource.referenceType == 'matrix') {
			text = resource.value.year != null ? `${resource.value.title} ${resource.value.year} (${resource.value.courseCode})` : `${resource.value.title} (${resource.value.courseCode})`;
		}

		return (
			<div className="df aic jcb">
				{resource.referenceType !== 'assessmentGoal' ?
					<div className='df aic' style={{ flex: 1 }}>{text} {renderExpiredText()}</div>
					:
					<div className='assessmentGoal-dotContainer'>
						<div className='df aic' style={{ flex: 1 }}>{text} {renderExpiredText()}</div>
						{props.course.length > 0 ?
							props.course.map(course => {
								let title = course.value.year != null ? `${course.value.title} ${course.value.year} (${course.value.courseCode})` : `${course.value.title} (${course.value.courseCode})`;
								return (
									<ColorLabelContainer key={course.value.id}>
										<ColorLabel
											key={course.value.id}
											tooltip={title}
											color={course.value.colorCode}
										/>
									</ColorLabelContainer>
								)
							}
							)
							: null}
					</div>
				}

				{!isStudent ?
					<div className="permissions">
						{renderPermission('STUDENT', resource.permissions)}
						{renderPermission('GUARDIAN', resource.permissions)}
					</div>
					: null}
			</div>
		);
	}

	/**
	 * @param {object} resource
	 */
	const getResourceColor = (resource) => {
		if (resource.referenceType == 'assessmentGoal') {
			return '#0f78da';
		}

		if (resource.referenceType == 'matrix') {
			return resource.value.colorCode;
		}

		return '#ccc';
	}

	/**
	 * @param {object} rowData
	 */
	const getColors = (rowData) => {
		let showRed = false;

		if (resource.referenceType == 'assessmentGoal') {
			showRed = rowData.cells.find(cell => cell.type == 'NotAchievedTextField') != null;
		}

		if (resource.referenceType == 'matrix') {
			const detail = resource.value.details.find((detail) => {
				return detail.matrix._ParagraphSet.find((paragraph) => {
					return paragraph.rows.find((row) => row.id == rowData.id)
				});
			});

			if (detail != null && !detail.matrix.disableNotAchieved) {
				showRed = true;
			}
		}

		return new ColorScale(rowData.cells.length).get(showRed);
	}

	/**
	 * @param {object} cell
	 * @param {object} assessmentBlockPartRow
	 */
	const getIsCellSelected = (cell, assessmentBlockPartRow) => {
		if (assessment == null) {
			return false;
		}

		const foundInValue = assessment.assessmentDetails?.find((detail) =>
			detail.cellId == cell.id && detail.assessmentBlockPartRowId == assessmentBlockPartRow.id
		);

		return foundInValue != null;
	}

	const getMatrix = () => {
		return resource.value?.details?.find((detail) =>
			detail.matrixID == resource.referenceId
		)?.matrix;
	}

	/**
	 * @param {string} target
	 * @param {array} permissions
	 */
	const renderPermission = (target, permissions) => {
		const active = permissions.find((perm) =>
			perm.target == target && perm.referenceType == 'ASSESSMENTBLOCKPART'
		) != null;

		return (
			<div className="permission">
				<Icon name={active ? "Eye" : "Eye_Off"} />
				{translate(capitalize(target))}
			</div>
		);
	}

	const renderMatrixOrGoal = (row, paragraph, assessmentBlockPartRow, clarifications, colors) => {
		const isStudent = block.api.getEditor().getProp('isStudent');

		const rowText = row.description != null ?
			row.description
			: row.text;

		return (
			<Fragment key={row.id}>
				<div className="df row">
					{paragraph != null ?
						<div>
							{paragraph.paragraphNr}.{row.sentenceNr}
						</div>
						: null}

					{rowText != null ? <div className="column-text" dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(
							rowText.replace(
								new RegExp("\r\n", "g"),
								"<br />"
							)
						)
					}}>
					</div> : null}

					<div className="cells df">
						{row.cells.map((cell, index) => {
							const selected = getIsCellSelected(cell, assessmentBlockPartRow);

							return <div
								className={selected ? 'cell selected' : 'cell'}
								style={{ '--level-color': colors[index] }}
								key={cell.id}
							>
								{cell.value != null ?
									cell.value
									: cell.text}

								<Icon name="Check" />
							</div>
						})}
					</div>
				</div>

				{clarifications.length > 0 ?
					<div className="clarifications display-resource">
						<Collapsible trigger={translate('Clarifications')}>
							{clarifications.map((clarification) => {
								return <div className="df row" key={clarification.id}>
									<div className="column-text">
										{clarification.description}
									</div>

									<div className="cells df">
										{clarification.cells.map((cell) => {
											return <div className="cell" key={cell.id}>
												{cell.value}
											</div>
										})}
									</div>
								</div>
							})}

							{!isStudent ?
								<div className="color--meta">
									<Icon name="Alert" bw /> {translate('Existing clarifications will not be copied and cannot be edited. Move them to an assessment goal instead.')}
								</div>
								: null}
						</Collapsible>
					</div>
					: null}
			</Fragment >
		)
	}

	const { assessmentBlockPartRows } = resource;
	let feedbacks = [];

	if (assessment != null) {
		feedbacks = assessment.assessmentFeedbacks?.filter((feedback) =>
			feedback.assessmentBlockPartId == resource.id
		);
	}

	if ((resource.assessmentBlockPartRows == null || resource.assessmentBlockPartRows.length == 0) && feedbacks.length == 0) {
		return null;
	}

	let matrix;
	if (resource.referenceType == 'matrix') {
		matrix = getMatrix();
		if (matrix == null || matrix._ParagraphSet == null) {
			return null;
		}
	}

	const isUndefined = (currentValue) => currentValue == undefined;

	return (
		<Expandable
			title={getResourceTitle(resource)}
			color={getResourceColor(resource)}
			open
			ignorePropsUpdate
		>
			{resource.referenceType == 'matrix' &&
				matrix._ParagraphSet.map((paragraph) => {
					var paragraphUsed = paragraph.rows.map((row) => {
						return assessmentBlockPartRows.find((selectedRow) => selectedRow.referenceId == row.id);
					});
					if (paragraphUsed != null && !paragraphUsed.every(isUndefined)) {
						return <div>
							{paragraph.title != null ? <div className="matrix-paragraph-title">{paragraph.title}</div> : null}
							{paragraph.subtitle != null ? <div className="matrix-paragraph-subtitle">{paragraph.subtitle}</div> : null}
							{paragraph.rows.map((row) => {
								var assessmentBlockPartRow = assessmentBlockPartRows.find((selectedRow) => selectedRow.referenceId == row.id);
								if (assessmentBlockPartRow != null) {

									row = getRowData(assessmentBlockPartRow);
									paragraph = getRowParagraph(assessmentBlockPartRow);
									if (row == null) {
										return null;
									}

									const clarifications = getRowClarifications(row);
									const colors = getColors(row);

									return renderMatrixOrGoal(row, paragraph, assessmentBlockPartRow, clarifications, colors);
								}
							})}
						</div>
					}
				})}

			{resource.referenceType == 'assessmentGoal' &&
				assessmentBlockPartRows.map((assessmentBlockPartRow) => {
					const row = getRowData(assessmentBlockPartRow);
					const paragraph = getRowParagraph(assessmentBlockPartRow);

					if (row == null) {
						return null;
					}

					const clarifications = getRowClarifications(row);
					const colors = getColors(row);

					return renderMatrixOrGoal(row, paragraph, assessmentBlockPartRow, clarifications, colors);
				})
			}

			{feedbacks != null && feedbacks.length > 0 ?
				<div className="feedbacks">
					{feedbacks.map((feedback) => {
						return <Collapsible
							key={feedback.id}
							trigger={translate('Feedback')}
							defaultOpen
						>
							<div
								dangerouslySetInnerHTML={{ __html: feedback.text }}
							/>
						</Collapsible>
					})}
				</div>
				: null}
		</Expandable>
	);

}

export default DisplayResource;