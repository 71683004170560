import React, { Component } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

import api from 'lib/api';

import { Icon, translate, Button } from '@haldor/ui';

import Modal from 'containers/Modals/Modal';
import AddLinkForm from './AddLinkForm';

import placeholderImage from './default_img_links.svg';

class Prompt extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeLink: null,
			modal: false,
		}

		if (this.props.openPicker != null) {
			this.props.openPicker(this.toggleModal);
		}
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	toggleEdit = (link) => {
		if (link != null && link.url != null) {
			this.setState({ activeLink: link });
		} else {
			this.setState({ activeLink: null });
		}
	}

	addhttp = (url) => {
		if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
			url = "http://" + url;
		}
		return url;
	}

	addLink = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({ modal: true });
	}

	onFormSubmit = (values) => {
		return new Promise(resolve => {
			api.get(`OpenGraph?Url=${values.url}`).then((response) => {
				const { status, data } = response;

				if (data != null && data.image != null) {
					values.image = data.image;
				}

				if (data != null && data.metadata != null) {
					if (data.metadata['og:description'] != null && data.metadata['og:description'].length > 0) {
						values.description = data.metadata['og:description'][0].value;

						if (values.description.length > 181) {
							values.description = values.description.slice(0, 180) + '…';
						}
					}
				}

				if (values.title == null) {
					if (data != null && data.title != null) {
						values.title = data.title;
					} else {
						values.title = '';
					}
				}

				if (values.description == null) {
					values.description = '';
				}

				if (values.image == null) {
					values.image = '';
				}

				if (this.props.onSubmit != null) {
					this.props.onSubmit(values);
				}

				if (this.state.activeLink != null) {
					this.toggleEdit();
				} else {
					this.toggleModal();
				}

				resolve(1);
			});
		})
	}

	render() {
		return <div>
			<Modal type="small editor-block-modal" isOpen={this.state.modal} onClose={this.toggleModal} title={this.props.translate('Add link')}>
				<AddLinkForm
					onSubmit={this.onFormSubmit}
					onCancel={this.toggleModal}
				/>
			</Modal>

			<Modal type="small editor-block-modal" isOpen={this.state.activeLink != null} onClose={this.toggleEdit} title={this.props.translate('Edit link')}>
				<AddLinkForm
					onSubmit={this.onFormSubmit}
					onCancel={this.toggleEdit}
					initialValues={this.state.activeLink}
				/>
			</Modal>

			{this.props.links != null ?
				<div className="link-resource-list">
					{this.props.links.map((resource, index) => {
						return <div key={index} className="link-resource-container">
							<div className="link-resource">
								<div className="link-content">
									{resource.image != '' ?
										<div
											className="image"
											style={{ backgroundImage: `url(${resource.image})` }}
										/>
										:
										<div
											className="image placeholder"
											style={{ backgroundImage: `url(${placeholderImage})` }}
										/>
									}

									<div className="content">
										<div className="title"
											dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.title) }}
										/>

										<div className="description"
											dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.description) }}
										/>
									</div>
								</div>

								<div className="link-footer">
									<div className="url">
										<a href={this.addhttp(resource.url)} target="_blank">
											{resource.url}

											<Icon name="External" />
										</a>
									</div>

									<span className="delete-resource" onClick={() => this.props.block.removeResource(resource)}>
										<Icon name="Bin" />
									</span>

									<span className="delete-resource" onClick={() => this.toggleEdit(resource)}>
										<Icon name="Pen_Small" />
									</span>
								</div>
							</div>
						</div>
					})}
				</div>
				: null}

			<div style={{ marginTop: '.75rem' }} />

			<Button onClick={this.addLink}>
				{this.props.translate('Add link')}
			</Button>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(Prompt);
