import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import {
	fetch_preschoolgoals,
	getLinkedPlansAndDocumententationsForGoal,
	resetLinkedPlansAndDocumentations,
	getGoalsStatisticsForUser,
} from 'actions/preschoolform';

import { Collapsible, Spinner } from 'UI';
import { Block, Flex } from '@haldor/ui';

import './_mentorGoalOverview.scss';

class MentorGoalOverView extends Component {

	componentDidMount = () => {
		if (this.props.goals == null || this.props.goals.length == 0) {
			this.props.fetch_preschoolgoals();
		}

		if (this.props.linkedData != null && this.props.linkedData.length > 0) {
			this.props.resetLinkedPlansAndDocumentations();
		}

		const { userId, start, end } = this.props;
		this.props.getGoalsStatisticsForUser(userId, start, end);
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.start != this.props.start || nextProps.end != this.props.end) {
			const { userId } = this.props;
			this.props.resetLinkedPlansAndDocumentations();
			this.props.getGoalsStatisticsForUser(userId, nextProps.start, nextProps.end);
		}
	}

	getCategories = () => {
		let categories = [];
		if (this.props.goals != null) {
			this.props.goals.forEach(goal => {
				if (!categories.find(category => category.title === goal.group)) {
					categories.push({ title: goal.group, color: goal.colorCode });
				}
			});
		}

		return categories;
	}

	getGoalsByCategoryName = (category) => {
		let goals = [];
		if (this.props.goals != null) {
			goals = [...this.props.goals].filter(goal => goal.group == category);
		}

		return goals;
	}

	onGoalCategoryChange = (open, goal) => {
		const { userId, start, end } = this.props;

		if (open) {
			const linked = this.getGoalLinkedData(goal.id);

			if (linked == null) {
				this.props.getLinkedPlansAndDocumententationsForGoal(goal.id, userId, start, end);
			}
		}
	}

	getGoalLinkedData = (goalId) => {
		const { linkedData } = this.props;
		let data = [];

		if (linkedData != null) {
			const foundGoalData = linkedData.find(linked => {
				return linked.goalId == goalId;
			});

			if (foundGoalData != null) {
				foundGoalData.plans.forEach(plan => {
					data.push({ ...plan, type: 'planning' });
				});

				foundGoalData.documentations.forEach(documentation => {
					data.push({ ...documentation, type: 'documentation' });
				});
			} else {
				// Nothing loaded
				data = null;
			}
		}

		return data;
	}

	getGoalBadgeContent = (goalId) => {
		let content = null;

		if (this.props.statistics != null && this.props.statistics.length > 0) {
			let foundStatistic = this.props.statistics[0].statistics.find(stat => {
				return stat.goalId == goalId;
			});

			if (foundStatistic != null) {
				content = foundStatistic.total;
			}
		}

		return content;
	}

	getCategoryProgress = (category) => {
		let goals = this.getGoalsByCategoryName(category);
		let categoryCount = 0;

		goals.forEach(goal => {
			let goalStatistics = this.getGoalBadgeContent(goal.id);
			if (goalStatistics != null) {
				categoryCount += 1;
			}
		})

		return categoryCount;
	}

	render() {
		const categories = this.getCategories();
		const { translate } = this.props;

		return (
			<div className="mentor-goal-overview">
				{categories.map((category, index) => {
					const goals = this.getGoalsByCategoryName(category.title);

					return <div className="mentor-goal-category" style={{ borderColor: category.color }} key={index}>
						<Block>
							<Flex center>
								<span className="title" style={{ marginBottom: 0 }}>
									{category.title}
								</span>

								<div style={{ flex: 1, textAlign: 'right' }}>
									{this.getCategoryProgress(category.title)}/{goals.length}
									{' ' + translate('completed-meeting').toLowerCase()}
								</div>
							</Flex>

							<div style={{ marginBottom: '1rem' }} />

							{goals.map(goal => {
								const linked = this.getGoalLinkedData(goal.id);
								const badgeContent = this.getGoalBadgeContent(goal.id);

								const title = (
									<Flex center space>
										<span data-tooltip={goal.text}>
											{goal.title}
										</span>

										<div className="dot-container">
											{badgeContent != null ?
												<span className="number">
													{badgeContent}
												</span>
												:
												<span className="empty-dot" />
											}
										</div>
									</Flex>
								);

								return <Collapsible
									key={goal.id}
									open={linked !== null}
									onChange={e => this.onGoalCategoryChange(e, goal)}
									trigger={title}
								>
									{linked != null ?
										<div>
											{linked.length > 0 ?
												linked.map((item, index) => {
													let link = '/plan/' + item.id;
													if (item.type == 'documentation') {
														link = '/preschooldocument/' + item.id;
													}

													return <Link to={link} key={'item' + index} className="linked-item">
														{item.title}

														<span>
															{this.props.translate(item.type)}
														</span>
													</Link>
												})
												:
												<div className="text--center color--meta" style={{ padding: '1rem 0' }}>
													{this.props.translate('no-results')}
												</div>
											}
										</div>
										:
										<div style={{ paddingBottom: '3rem' }}>
											<Spinner small center />
										</div>
									}
								</Collapsible>
							})}
						</Block>
					</div>
				})}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		goals: state.PreschoolGoals.preschoolGoals,
		linkedData: state.PreschoolGoals.linkedData,
		statistics: state.PreschoolGoals.userStatistics,
	}
}

export default connect(mapStateToProps, {
	fetch_preschoolgoals,
	getLinkedPlansAndDocumententationsForGoal,
	resetLinkedPlansAndDocumentations,
	getGoalsStatisticsForUser,
})(MentorGoalOverView);