import {
	SET_SEARCH_TERM,
	SEARCH_EDUCATION_GROUPS,
	SEARCH_MENTOR_GROUPS,
	SEARCH_GROUPS,
	SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP,
	SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP,
	SEARCH_CLEAR_RESULTS,
	SEARCH_ASSIGNMENTS,
	SEARCH_PLANS,
	SEARCH_SCHOOL_USERS,
	SEARCH_POSTS,
	CLEAR_SEARCH,
	SEARCH_MY_SECTIONS
} from 'actions/search';

const INITIAL_STATE = {
	term: null,
	educationGroups: null,
	mentorGroups: null,
	groups: null,
	mentors: null,
	microsoftAssignments: [],
	haldorAssignments: [],
	assignments: [],
	plans: [],
	students: [],
	guardians: [],
	staff: [],
	courses: [],
	teachers: [],
	userSearch: [],
	posts: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_SEARCH_TERM:
			return { ...state, term: action.payload };

		case SEARCH_EDUCATION_GROUPS:
			return { ...state, educationGroups: action.payload };

		case SEARCH_MENTOR_GROUPS:
			return { ...state, mentorGroups: action.payload };

		case SEARCH_GROUPS:
			return { ...state, groups: action.payload };

		case SEARCH_MY_SECTIONS:
			return { ...state, groups: action.payload };

		case SEARCH_MICROSOFT_ASSIGNMENTS_IN_GROUP:
			return { ...state, microsoftAssignments: action.payload };

		case SEARCH_HALDOR_ASSIGNMENTS_IN_GROUP:
			return { ...state, haldorAssignments: action.payload };

		case SEARCH_CLEAR_RESULTS:
			return INITIAL_STATE;

		case SEARCH_SCHOOL_USERS:
			var staff = [];
			var teachers = [];
			var students = [];
			var guardians = [];
			if (action.payload != null && action.payload.length > 0) {
				if (Array.isArray(action.payload)) {
					for (var userItem of action.payload) {
						switch (userItem.userType) {
							case 'Staff':
								staff.push(userItem);
								break;
							case 'Teacher':
								teachers.push(userItem);
								break;
							case 'Mentor':
								teachers.push(userItem);
								break;
							case 'Student':
								students.push(userItem);
								break;
							case 'GUARDIAN':
								guardians.push(userItem);
								break;
						}
					}
				}
			}
			return { ...state, staff: staff, teachers: teachers, students: students, guardians: guardians };
		case SEARCH_ASSIGNMENTS:
			return { ...state, assignments: action.payload };

		case SEARCH_PLANS:
			return { ...state, plans: action.payload };

		case SEARCH_POSTS:
			return { ...state, posts: action.payload };

		case CLEAR_SEARCH:
			return {
				...state,
				educationGroups: INITIAL_STATE.educationGroups,
				mentorGroups: INITIAL_STATE.mentorGroups,
				groups: INITIAL_STATE.groups,
				mentors: INITIAL_STATE.mentors,
				microsoftAssignments: INITIAL_STATE.microsoftAssignments,
				haldorAssignments: INITIAL_STATE.haldorAssignments,
				students: INITIAL_STATE.students,
				staff: INITIAL_STATE.staff,
				teachers: INITIAL_STATE.teachers,
				userSearch: INITIAL_STATE.userSearch,
				assignments: INITIAL_STATE.assignments,
				plans: INITIAL_STATE.plans,
				guardians: INITIAL_STATE.guardians,
				term: INITIAL_STATE.term,
				posts: INITIAL_STATE.posts,
			};

		default:
			return state;
	}
}
