import React from 'react';
import { useTranslate } from 'lib/translate';
import moment from 'moment';
import { Spinner } from 'UI';
import { getActiveLanguage } from '@haldor/ui';

const SelectionDisplay = (props) => {
	const translate = useTranslate();

	if (props.start == null || props.end == null) {
		return null;
	}

	if (props.view == 'month') {
		return (
			<div className='df aic selection-display'>
				<h3 style={{ marginBottom: '.75rem', marginTop: 0 }}>
					{moment(props.date).locale(getActiveLanguage()).format('MMMM')}
				</h3>

				{props.isLoading ? <Spinner /> : null}
			</div>
		);
	}

	if (moment(props.end).diff(moment(props.start), 'days') > 1) {
		return (
			<div className='df aic selection-display'>
				<h3 style={{ marginBottom: '.75rem', marginTop: 0 }}>
					{translate('week')} {moment(props.date).format('W')}
				</h3>

				{props.isLoading ? <Spinner /> : null}
			</div>
		);
	}

	return (
		<div className='df aic selection-display'>
			<h3 style={{ marginBottom: '.75rem', marginTop: 0 }}>
				{moment(props.date).format('dddd D MMMM').capitalize()}
			</h3>

			{props.isLoading ? <Spinner /> : null}
		</div>
	);
};

export default SelectionDisplay;
