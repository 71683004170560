export const SET_PAGE_ACTIONS = 'HEADER_SET_PAGE_ACTIONS';
export const SET_PAGE_TITLE = 'HEADER_SET_PAGE_TITLE';
export const ON_ROUTE_CHANGE = 'HEADER_ON_ROUTE_CHANGE';
export const ON_ROUTE_BACK = 'HEADER_ON_ROUTE_BACK';

export const setPageActions = (value) => {
	return {
		type: SET_PAGE_ACTIONS,
		payload: new Promise(resolve => {
			resolve(value);
		}),
	};
}

export const setPageTitle = (value) => {
	return {
		type: SET_PAGE_TITLE,
		payload: new Promise(resolve => {
			resolve(value);
		}),
	};
}

export const onRouteBack = () => ({
	type: ON_ROUTE_BACK,
	payload: true,
});
