import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import Moment from 'moment';
import User from "_class/User";
import * as microsoftTeams from "@microsoft/teams-js";

import { getSectionByGraphId } from "actions/sections";
import { setPageTitle, setPageActions } from 'actions/header';

import { getWithExpiration, saveWithExpiration } from 'helpers/localstorage';

import ScheduleView from 'containers/Schedule/ScheduleView';
import Modal from 'containers/Modals/Modal';
import SectionForm from 'containers/Forms/SectionForm';
import CalendarEventForm from 'containers/Forms/CalendarEvent';
import SectionTitle from 'components/Presentation/SectionTitle';

import { Button, Icon } from '@haldor/ui';

class AttendanceTabSection extends Component {

	constructor(props) {
		super(props);

		let date = Moment();
		if (getWithExpiration('haldor-schedule-date') != null) {
			date = Moment(getWithExpiration('haldor-schedule-date'));
		}

		this.state = {
			date,
			createEvent: false,
			groupForm: false,
		};
	}

	componentDidMount = () => {
		const user = new User(this.props.currentUser);

		if (user.isTeacher()) {
			let actions = [];

			actions.push({
				type: 'icon',
				onClick: this.toggleGroupSettings,
				icon: 'cog_bw',
			});

			actions.push({
				type: 'button',
				value: this.props.translate('Create'),
				onClick: this.toggleForm,
				icon: 'plus',
			});

			this.props.setPageActions(actions);
		}

		this.props.setPageTitle(this.props.translate('attendance'));
	}

	toggleGroupSettings = (shouldReload) => {
		this.setState({ groupForm: !this.state.groupForm });

		if (shouldReload == true) {
			if (microsoftTeams.app.isInitialized()) {
				microsoftTeams.app.getContext().then((context) => {
					this.props.getSectionByGraphId(context.team.groupId);
				});
			}
		}
	}

	toggleForm = () => {
		this.setState({ createEvent: !this.state.createEvent });
	}

	onSubmit = (date) => {
		this.setState({ date: Moment(date) });
	}

	toggleIcon = flip => (
		<i className="cl-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				className={flip ? 'i-90' : 'a-90'}
				style={{ height: '12px', width: '15px', margin: 0 }}
			>
				<path
					id="Path_50"
					data-name="Path 50"
					className="cls-1"
					d="M11.361,1.4,6.38,5.9,1.4,1.4"
				/>
			</svg>
		</i>
	)

	goBack = async () => {
		await this.setState({ date: Moment(this.state.date).subtract(1, 'day') });
		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	}

	goForward = async () => {
		await this.setState({ date: Moment(this.state.date).add(1, 'day') });
		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	}

	goToday = async () => {
		await this.setState({ date: Moment() });
		saveWithExpiration('haldor-schedule-date', this.state.date.format());
	}

	render() {
		const user = new User(this.props.currentUser);
		const isToday = Moment().isSame(this.state.date, this.state.type == 'week' ? 'week' : 'day');
		let studentId = null;
		if (user.isStudent()) {
			studentId = this.props.currentUser.id;
		}

		return (
			<div className="overview">
				{this.props.group != null ?
					<Modal isOpen={this.state.groupForm} onClose={this.toggleGroupSettings} title={this.props.group.title}>
						<SectionForm
							onAbort={this.toggleGroupSettings}
							editView
							section={this.props.group}
						/>
					</Modal>
					: null}

				{this.props.group != null && !user.isStudent() ?
					<Modal isOpen={this.state.createEvent} onClose={this.toggleForm} title={this.props.translate('create-lesson')}>
						<CalendarEventForm
							onAbort={this.toggleForm}
							sectionId={this.props.group.id}
							onSubmit={this.onSubmit}
						/>
					</Modal>
					: null}

				<div className="container schedule">
					<SectionTitle>
						<div className="navigation-buttons-container">
							<Button type="secondary" style={{ marginLeft: 0, marginRight: '0.5em' }} onClick={this.goBack}>
								{this.toggleIcon(false)}&nbsp;
							</Button>

							<Button type="secondary" disabled={isToday} style={{ marginRight: '0.5em', marginLeft: 0 }} onClick={this.goToday}>
								{this.props.translate('today')}
							</Button>

							<Button type="secondary" style={{ marginLeft: 0 }} onClick={this.goForward}>
								{this.toggleIcon(true)}&nbsp;
							</Button>
						</div>
					</SectionTitle>

					{this.props.group != null ?
						<div className="section" style={{ padding: 0, margin: 0, border: 0, marginBottom: '2rem' }}>
							<ScheduleView
								view="day"
								date={this.state.date}
								userId={studentId}
								{...this.props}
							/>
						</div>
						: null}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		group: state.sections.graphSection,
	};
}

export default connect(mapStateToProps, {
	setPageTitle,
	setPageActions,
	getSectionByGraphId,
})(AttendanceTabSection);
