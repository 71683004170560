import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import api from 'lib/api';

import DateTime from '_class/DateTime';

import { trackImmersiveReaderError } from 'helpers/insights';
import { getRootUrl } from 'helpers/url';
import { isUserStudent, isUserTeacher } from 'helpers/user';
import swal from 'sweetalert2';
import { getDescription } from 'helpers/content';

import { getAssignmentAssessment, createStudentAssessment, updateAssessment } from "actions/assessments";
import { getBlocksByReference, clearBlocksOnReference } from 'actions/blocks';
import {
	deleteAssignmentFromStore,
	getAssignmentKnowledgeCollections,
	getFolderFiles,
	getTaskDetails,
	setActiveAssignment,
	getAssignmentStatus,
	getAssignmentSection,
	updateAssignment,
	clearAssignmentSection,
	createAssignmentTaskWorkspace,
	setTeacherDone
} from 'actions/assignments';

import { setPageTitle } from 'actions/header';
import { addError } from 'actions/index';

import { getStudentAssessment, getStudentAssessments } from 'actions/studentassessments';

import { getReaderParameters } from 'actions/user';

import Message from '../Partials/Message';
import Bookmark from '../Partials/Bookmark';

import AssignmentForm from "containers/Forms/AssignmentForm/AssignmentForm";
import AssessmentForm from 'containers/Forms/AssessmentForm';
import TeacherAdjustments from "containers/AdditionalAdjustments/Display/TeacherAdjustments";
import Modal from "containers/Modals/Modal";
import CloneButton from "containers/SinglePages/Partials/CloneButton";
import AssessmentsConsumer from 'components/BlockAssessments/Consumer';

import UserAssignment from "components/List/UserAssignment";
import UserGroupAssignment from "components/List/UserGroupAssignment";
import AttachedDocuments from "components/Presentation/Documents/AttachedDocuments";
import AttachedUserDocuments from "components/Presentation/Documents/AttachedUserDocuments";
import UploadDocument from "components/Presentation/Documents/UploadDocument";
import FileList from "components/Presentation/FileList/FileList";
import PostContent from "components/Presentation/PostContent";
import Status from "components/Presentation/Status";
import { ASSESSMENT_TYPES } from "components/BlockAssessments/AssessmentTypes";

import * as plagiarismActions from 'actions/plagiarism';
import { ColorLabel, ColorLabelContainer, Expandable, List, Spinner, Collapsible } from 'UI';

import { Block as ContentBlock, Button, ButtonGroup, Icon } from "@haldor/ui";
import { Immersive_Reader } from "UI/Icons";
import moment from 'moment';
import User from '_class/User';
import { FormControlLabel, Stack, Switch, Tooltip } from '@mui/material';
import assignmentTaskStatusIcons from 'constants/assignmentTaskStatusIcons';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ImmersiveReader = require('@microsoft/immersive-reader-sdk');

class HaldorAssignment extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			modals: [
				{ name: 'edit', open: false },
				{ name: 'studentAssessment', open: false },
				{ name: 'selfAssessment', open: false },
			],
			tab: 'assessments',
			documentRefresh: 0,
			studentConversation: null,
			loadingBlocks: false,
			loadingDocuments: false,
			assessTask: null,
			teacherDone: null
		};

		this.timer = null;
	}

	componentWillUnmount = () => {
		this.props.clearAssignmentSection();
		this.props.setActiveAssignment();
		ImmersiveReader.close();

		if (this.timer != null) {
			clearTimeout(this.timer);
		}
	};

	componentDidMount = () => {
		if (this.props.assignment.id > 0) {
			this.reload();
		}

		// this will update reducer variable (this.props.hasValidPlagiarismLicense)
		this.props.validatePlagiarismLicense(this.props.activeSchool);

		this.setState({ teacherDone: this.props.assignment.teacherDone });
	};

	checkAssignmentTaskStatus = () => {
		const { assignment } = this.props;
		const isStudent = isUserStudent(this.props.currentUser);

		if (assignment != null && isStudent && assignment.tasks != null && assignment.tasks.length > 0) {
			let assignmentTaskLocked = assignment.tasks[0].assignmentTaskAssignees.filter((x) => x.permission === '0').length > 0;
			if (assignment.created != '0001-01-01T00:00:00' && !assignmentTaskLocked) {
				const task = assignment.tasks[0];
				if (task == null) {
					return null;
				}

				if (task.status == 'ASSIGNMENT_TASK_NOT_STARTED') {
					const url = `assignments/Student/Status?assignmentTaskId=${task.id}&status=ASSIGNMENT_TASK_STARTED`;

					api.put(url);
				}
			}
		}
	};

	checkAssignmentTaskWorkspace = () => {
		const { assignment } = this.props;
		const isStudent = isUserStudent(this.props.currentUser);
		if (assignment != null && isStudent) {
			const task = assignment.tasks[0];
			if (task != null && !task.workspace) {
				this.props.createAssignmentTaskWorkspace(assignment.id, task.id).then(() => {
					this.reloadAssignment();
				});
			}
		}
		else if (assignment.created != '0001-01-01T00:00:00' && !assignment.locked && !isStudent) {
			if (assignment.tasks.some((task) => {
				return !task.workspace;
			})) {
				swal.fire({
					title: this.props.translate('The-assignment-needs-to-be-updated'),
					text: this.props.translate('One-or-more-students-do-not-have-access-to-the-tasks-workspace.-Update-the-assignment-to-fix-the-problem.'),
					showCancelButton: false,
					focusConfirm: false,
					confirmButtonText: this.props.translate('Update-assignment'),
				}).then((result) => {
					if (result.value != null) {
						this.props.updateAssignment(assignment).then(() => {
							this.props.getTaskDetails(assignment.id).then(() => {
								this.fetchStatusAfterDelay();
							});
						});
					};
				});
			}
		}
	};

	fetchStatusAfterDelay = () => {
		const getStatus = () => {
			this.props.getAssignmentStatus(this.props.assignment.id).then(() => {
				clearTimeout(this.timer);
				this.timer = null;

				let assignmentEditedDate = moment(this.props.assignment.edited).format();

				/** substract 2 hours from now and if assignmentEditedDate bigger then run status call if assignment is locked  */
				let currentDate = moment(moment().utc().add(-2, 'hours')).format();

				if (
					this.props.assignment.created != '0001-01-01T00:00:00' &&
					!this.props.assignment.locked
				) {
					this.reloadAssignment(false);
				} else {
					if ((this.props.assignment.created == '0001-01-01T00:00:00' || this.props.assignment.locked) && assignmentEditedDate > currentDate) {
						// Run this method again
						this.fetchStatusAfterDelay();
					}
				}
			});
		};

		if (this.timer === null) {
			setTimeout(getStatus, 10000);
		}
	};

	reload = () => {
		const { assignment } = this.props;
		const student = isUserStudent(this.props.currentUser);
		const teacher = isUserTeacher(this.props.currentUser);
		let promises = [];

		if (assignment.assessmentType == 'BUDDY' || assignment.assessmentType == 'SELF') {
			if (teacher) {
				promises.push(this.props.getStudentAssessments(assignment.id));
			} else if (student) {
				promises.push(this.props.getStudentAssessment(assignment.id, this.props.currentUser.id));
			}
		}

		promises.push(this.props.getAssignmentSection(assignment.sectionId));
		promises.push(this.props.getAssignmentAssessment(assignment.id));

		Promise.all(promises).then(() => {
			if (assignment != null) {
				this.props.setPageTitle(assignment.title);
				if (
					this.props.assignment.created == '0001-01-01T00:00:00' ||
					(this.props.assignment.locked)
				) {
					this.fetchStatusAfterDelay();
				}
			}

			this.props.getBlocksByReference(this.props.assignment.id, 'ASSIGNMENT')
				.then(() => {
					this.setState({ loadingBlocks: false });
				})

			this.checkAssignmentTaskStatus();
			this.checkAssignmentTaskWorkspace();
			this.setState({ loading: false });
		});
	};

	reloadAssignment = (skipLoading = false) => {
		if (!skipLoading) {
			this.setState({ loading: true });
		}

		let promises = [];
		promises.push(this.props.getAssignmentAssessment(this.props.assignment.id));
		promises.push(this.props.getTaskDetails(this.props.assignment.id));
		promises.push(this.props.getBlocksByReference(this.props.assignment.id, 'ASSIGNMENT'));

		Promise.all(promises).then(() => {
			if (this.props.assignment.locked) {
				// Is updating
				this.fetchStatusAfterDelay();
			}

			if (this.props.assignment === null)
				[
					//{assignmentId}/tasks/{taskId}/workspacepermission
				];

			this.props.setPageTitle(this.props.assignment.title);

			this.setState({ loading: false });
		});
	};

	runImmersiveReader = async () => {
		const { assignment, currentUser } = this.props;
		const localLanguage = localStorage.getItem('language');

		console.log('run Microsoft Immersive Reader', localLanguage, currentUser.id);

		const data = {
			title: assignment.title,
			chunks: [
				{
					content: '<h1>' + assignment.title + '</h1>',
					mimeType: 'text/html',
				},
				{
					content: getDescription(assignment.description),
					mimeType: 'text/html',
				},
			],
		};

		await this.props.getReaderParameters();
		const { reader } = this.props;

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error('error', error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	};

	pupilStatusIndicator = (status) => {
		var assessed = false;
		if (this.props.assessments != null) {
			if (this.props.assessments.length > 0) {
				assessed = true;
			}
		}

		if (assessed == false) {
			let icon = assignmentTaskStatusIcons.find(item => item.id == status)?.icon;
			const currentStatus = this.props.assignmentTaskStatus.find(task => task.id == status);

			if (currentStatus != null) {
				return (<Stack direction="row" spacing={1}>
					<div style={{ width: '20px' }}>{icon}</div>
					<div>{currentStatus.value}</div>
				</Stack>)
			}
		} else {
			if (status === 'ASSIGNMENT_TASK_NOT_COMPLETE') {
				return (
					<span className='single-dot dot-red'>
						{this.props.translate('assessed')} - {this.props.translate('not-complete')}
					</span>
				);
			} else if (status === 'ASSIGNMENT_TASK_CAN_DEVELOP') {
				return (
					<span className='single-dot dot-orange'>
						{this.props.translate('assessed')} - {this.props.translate('can-develop')}
					</span>
				);
			} else {
				return <span className='single-dot dot-green'>{this.props.translate('assessed')}</span>;
			}
		}
	};

	refreshDocuments = () => {
		let documentRefresh = this.state.documentRefresh;
		documentRefresh++;
		this.setState({ documentRefresh });
	};

	onUploadError = (uploadError) => {
		if (uploadError.data == 'Task already submitted') {
			this.props.addError(this.props.translate('task-already-submitted'), 'error');
		}
	};

	assignmentIndicator = (status) => {
		const { assignment } = this.props;
		if (assignment.published == '0001-01-01T00:00:00') {
			return <span className='single-dot dot-gray'>{this.props.translate('Draft')}</span>;
		}

		if (status === 'ASSIGNMENT_OPEN') {
			return <div className='card-meta'>{this.props.translate('Open')}</div>;
		} else if (status === 'ASSIGNMENT_CLOSED') {
			return <div className='card-meta'>{this.props.translate('Hidden')}</div>;
		} else if (status === 'ASSIGNMENT_DELETED') {
			return <div className='card-meta'>{this.props.translate('Deleted')}</div>;
		} else if (status === 'ASSIGNMENT_LOCKED') {
			return <div className='card-meta'>{this.props.translate('Locked')}</div>;
		}
	};

	toggleForm = () => {
		this.setState({ formOpen: !this.state.formOpen });
	};

	markStudentForConversation = (student) => {
		this.setState({ studentConversation: student });
	};

	onConversationClose = () => {
		this.setState({ studentConversation: null });
	};

	openStudentConversation = () => {
		const { currentUser, assignment } = this.props;
		this.setState({ studentConversation: assignment.teacher });
	};

	renderMembersForStudent = () => {
		if (this.props.assignment.tasks.length > 0) {
			const task = this.props.assignment.tasks.find(
				(task) => task.assignedTo.indexOf(this.props.currentUser.id) > -1
			);

			if (task == null) {
				return null;
			}

			const assignedTo = task.assignedTo.split(';');
			let users = [];

			assignedTo.forEach((user) => {
				task.students.find((student) => {
					if (student.userId == user) {
						users.push(student);
					}
				});
			});

			return users.map((student) => <div>{student.firstName + ' ' + student.lastName}</div>);
		}

		return null;
	};

	statusSwitcher = () => {
		const { assignment } = this.props;
		const task = assignment.tasks[0];
		let assignmentTaskLocked = assignment.tasks[0]?.assignmentTaskAssignees.filter((x) => x.permission === '0').length > 0;

		if (assignment.created != '0001-01-01T00:00:00' && task != null) {
			if (
				task.status === 'ASSIGNMENT_TASK_NOT_STARTED' ||
				task.status === 'ASSIGNMENT_TASK_STARTED' ||
				task.status === 'ASSIGNMENT_TASK_NOT_COMPLETE'
			) {
				return (
					<Button style={{ marginTop: 5 }} onClick={() => this.onSubmitAssignment()} disabled={(assignment.status == 'ASSIGNMENT_LOCKED' && assignmentTaskLocked) || assignmentTaskLocked}>
						{this.props.translate('Turn in')}
					</Button>
				);
			} else if (task.status === 'ASSIGNMENT_TASK_CAN_DEVELOP') {
				return (
					<Button style={{ marginTop: 5 }} onClick={() => this.onSubmitAssignment()}>
						{this.props.translate('submit-complement')}
					</Button>
				);
			}
		} else {
			return <div />;
		}
	};

	renderPrintButton = (id) => {
		let url = `/print/assignment/${id}`;
		if (getRootUrl() != '/') {
			url = `/print/assignments-tab/${id}`;
		}

		return (
			<div style={{ marginTop: 5 }}>
				<Link style={{ display: 'inline' }} to={url}>
					<Button type='secondary'>
						<Icon name='Printer' /> {this.props.translate('printable-view')}
					</Button>
				</Link>
			</div>
		);
	};

	canDeleteAssignment = () => {
		const { assessments, assignment } = this.props;

		// Check if assessments is null or empty
		const assessmentsCondition = assessments === null || assessments.length === 0;

		// Check if all tasks have the status 'ASSIGNMENT_TASK_NOT_STARTED'
		const tasksCondition = assignment.tasks && assignment.tasks.every(t => t.status === 'ASSIGNMENT_TASK_NOT_STARTED');

		// Determine if the assignment is deletable based on conditions
		return assignment.isDeletable || (assessmentsCondition && tasksCondition);
	}


	renderDeleteButton = () => {
		if (this.canDeleteAssignment()) {
			return (
				<Button type='secondary' style={{ marginTop: 5 }} onClick={this.toggleDeletion}>
					<Icon name='Bin' /> {this.props.translate('Delete')}
				</Button>
			);
		}
	};

	setTeacherDone = async (event) => {
		const { target: { checked } } = event;
		await setTeacherDone(this.props.assignment.id, checked);
		this.setState({ teacherDone: checked });
	}

	taskContent = (assignment, section, teacherOwnsAssignment) => {
		const user = new User(this.props.currentUser);
		const isTeacher = isUserTeacher(this.props.currentUser);

		// If the assignment is not loaded, return null
		if (assignment == null) return null;

		let taskWorkSpace = '';
		let rootUrl = '';
		if (user.isStudent()) {
			var sectionUrl = new URL(section.classroomUrl);
			taskWorkSpace = `${sectionUrl.protocol}//${sectionUrl.hostname}${assignment.tasks[0].workspace}`;
			rootUrl = `${sectionUrl.protocol}//${sectionUrl.hostname}`;
		}

		var sectionUrl = new URL(section.classroomUrl);
		const folderUrl = `${sectionUrl.protocol}//${sectionUrl.hostname}${assignment.workspace}`;

		var associatedMatrices = [];
		let subjects = [];
		let assessmentBlocks = [];

		if (assignment.associatedMatrices != null) {
			assignment.associatedMatrices.forEach((matrix) => {
				if (matrix._ParagraphSet != null && matrix._ParagraphSet.length > 0) {
					associatedMatrices.push(matrix);
				}
			});

			associatedMatrices.forEach((matrice) => {
				if (subjects.indexOf(matrice.course.subjectId) == -1) {
					subjects.push(matrice.course.subjectId);
				}
			});
		}
		if (this.props?.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		let expiredCourses = [];

		if (assessmentBlocks?.length > 0) {
			assessmentBlocks?.forEach((content) => {
				content?.resources?.forEach(resources => {
					switch (resources.referenceType) {
						case 'matrix':
							var matrixId = resources.referenceId;
							var detail = resources.value?.details.find(detail => {
								return detail.matrixID == matrixId;
							})

							if (detail != null && detail.endDate != null && moment(detail.endDate).isBefore(moment())) {
								expiredCourses.push(detail.courseID);
							}
							break;
						default:
							break;
					}

					if (resources?.value != null) {
						if (subjects.indexOf(resources.value?.subjectId) == -1) {
							subjects.push(parseInt(resources.value?.subjectId));
						}
					}
				})
			});
		}

		let conversation = null;
		if (this.props.conversations != null && !isTeacher) {
			conversation = this.props.conversations.find((conv) => {
				return conv.memberships.find((member) => {
					return member.membershipID == this.props.currentUser.id;
				});
			});
		}

		return (
			<div>
				<div className='single-section form left-side' style={{ paddingTop: '0' }}>
					<ContentBlock>
						{this.props.services.immersiveReaderAssignments ? (
							<Link to='#' onClick={this.runImmersiveReader}>
								<div className='reader trigger'>
									<Immersive_Reader />
									{this.props.translate('immersive-reader')}
								</div>
							</Link>
						) : null}

						<div style={{ float: 'right' }}>
							<Bookmark referenceType='ASSIGNMENT' referenceId={assignment.id} />
						</div>

						<span className='title'>{this.props.translate('description')}</span>

						<PostContent>{assignment.description}</PostContent>

						<div style={{ marginTop: '1rem' }}>
							{user.isStudent() ?
								<span className='title'>{this.props.translate('Work status')}</span>
								: (<span className='title'>{this.props.translate('Access status')}</span>)}

							{assignment.created == '0001-01-01T00:00:00' ? (
								<div className='badge-primary'>
									{this.props.translate('task-is-creating')}
								</div>
							) : assignment.locked ? (
								<div className='badge-primary'>
									{this.props.translate('task-is-updating')}
								</div>
							) : (
								<div>
									{user.isStudent()
										? this.pupilStatusIndicator(assignment.tasks[0].status)
										: this.assignmentIndicator(assignment.status)}
								</div>
							)}

							<div style={{ clear: 'both' }} />
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className='title'>{this.props.translate('type')}</span>
							<Status type='assignmentTypes' id={assignment.assignmentType} />
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className='title'>{this.props.translate('start')}</span>

							<div>{new DateTime(assignment.startDate).getShortDate(true)}</div>
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className='title'>{this.props.translate('end')}</span>

							<div>{new DateTime(assignment.dueDate).getShortDate(true)}</div>
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className='title'>{this.props.translate('assessment')}</span>
							{assignment.assessmentType == 'BUDDY' ? (
								<div className='card-meta'>
									{this.props.translate('buddy-assessment')}
								</div>
							) : null}

							{assignment.assessmentType == 'SELF' ? (
								<div className='card-meta'>
									{this.props.translate('self-assessment')}
								</div>
							) : null}
						</div>

						{isTeacher == false && assignment.groupEnabled ? (
							<div style={{ marginTop: '1rem' }}>
								<span className='title'>{this.props.translate('members')}</span>
								{this.renderMembersForStudent()}
							</div>
						) : null}

						<div style={{ marginTop: '1rem' }}>
							<span className='title'>{this.props.translate('section')}</span>

							<Stack direction="row" spacing={1}>
								<span>{assignment?.section?.title}</span>
								<span>{assignment?.autoAssignStudents && user.isTeacher() ? <Tooltip placement="top" arrow title="[missing translate] Kommer automatiskt att tilldelas elever som tillkommer i gruppen"><div className="card-meta" style={{ marginLeft: 5, display: 'flex', alignItems: 'center', justifySelf: 'baseline' }}><PersonAddIcon sx={{ fontSize: '15px' }} />[missing translate] Automatisk tilldelning</div></Tooltip> : null}</span>
							</Stack>
						</div>

						{assignment.courses.length > 0 ? (
							<div style={{ marginTop: "1rem" }}>
								<span className="title">
									{this.props.translate("courses")}
								</span>

								<ColorLabelContainer>
									{assignment.courses.map((course) => {
										let text = course.title;
										text = course.year != null ? text + " " + course.year : text;

										if (expiredCourses.indexOf(course.id) > -1) {
											return (
												<div className="dot">
													<Icon name="Alert_Red" /> {text}
												</div>
											)
										} else {
											return (
												<ColorLabel
													key={`single_assignment_course-${course.id}`}
													tooltip={text}
													color={course.colorCode}
												/>
											);
										}
									})}
								</ColorLabelContainer>
							</div>
						) : null}

						{this.props.services.additionalAdjustments &&
							isTeacher &&
							this.props.activeSection &&
							this.props.activeSection.owners.filter(
								(ss) => ss.id == this.props.currentUser.id
							).length > 0 ? (
							<div style={{ marginTop: '1rem' }}>
								<Collapsible trigger={this.props.translate('additional-adjustments')}>
									<TeacherAdjustments
										groupId={assignment.sectionId}
										subjects={subjects}
									/>
								</Collapsible>
							</div>
						) : null}
					</ContentBlock>
				</div>

				<div className='single-actions right-side'>
					<div className='action-section'>
						<h3>{this.props.translate('tools')}</h3>

						{this.props.services.conversations && user.isStudent() ? (
							<Button
								type={conversation == null ? 'secondary' : 'primary'}
								onClick={this.openStudentConversation}
							>
								<Icon name='Message' bw />
								{this.props.translate('conversation')}

								{conversation != null && conversation.unreadMessages > 0 ? (
									<div className='badge'>
										<span>{conversation.unreadMessages}</span>
									</div>
								) : null}
							</Button>
						) : null}

						{!this.props.services.haldor ||
							user.isStudent() ||
							!teacherOwnsAssignment ||
							assignment.created == '0001-01-01T00:00:00' ||
							assignment.locked ? null : (
							<Button type='secondary' onClick={() => this.toggleModal('edit')}>
								<Icon name='Pen_Small' /> {this.props.translate('Edit')}
							</Button>
						)}

						{assignment.created != '0001-01-01T00:00:00' ?
							this.renderPrintButton(assignment.id)
							: null}

						{user.isStudent() &&
							assignment.status != 'ASSIGNMENT_TASK_NOT_STARTED' &&
							assignment.status != 'ASSIGNMENT_CLOSED' &&
							assignment.tasks[0].workspace != null &&
							assignment.tasks[0].assignmentTaskAssignees.find((assignee) =>
								assignee.permission != '0'
							) != null &&
							assignment.created != '0001-01-01T00:00:00' ? (
							<div style={{ marginTop: 5 }}>
								<a target='_blank' style={{ display: 'inline' }} href={taskWorkSpace}>
									<Button type='secondary'>
										{this.props.translate('open-workspace')}
									</Button>
								</a>
							</div>
						) : null}

						{user.isTeacher() &&
							assignment.workspace != '' &&
							assignment.created != '0001-01-01T00:00:00' ? (
							<div style={{ marginTop: 5 }}>
								<a target='_blank' style={{ display: 'inline' }} href={folderUrl}>
									<Button type='secondary'>
										{this.props.translate('open-website')}
									</Button>
								</a>
							</div>
						) : null}

						{isTeacher == false || !teacherOwnsAssignment ? null : this.renderDeleteButton()}

						{isTeacher &&
							assignment.created != '' &&
							!assignment.locked &&
							assignment.created != '0001-01-01T00:00:00'
							? this.renderCloneButton()
							: null}

						{isTeacher || assignment.status == 'ASSIGNMENT_CLOSED'
							? null
							: this.statusSwitcher()}
					</div>

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	};

	renderCloneButton = () => {
		return (
			<div style={{ marginTop: 5 }}>
				<CloneButton
					onClose={this.reloadAssignment}
					object={this.props.assignment}
					type='assignment'
				>
					{this.props.translate('column-copy')}
				</CloneButton>
			</div>
		);
	};

	editStatus = (taskId, value, userId) => {
		return new Promise((resolve) => {
			if (typeof value === 'string' && value.indexOf('ASSESSMENT') > -1) {
				api.put(
					`assessments/${taskId}/type/AssignmentTask/studentId/${userId}/status/${value}`,
					null
				).then(() => {
					this.props.addError(this.props.translate('changes-saved'), 'info');
					resolve();
				});
			} else {
				api.put(
					'assignments/Student/Status?assignmentTaskId=' + taskId + '&status=' + value,
					null
				).then(() => {
					this.props.addError(this.props.translate('changes-saved'), 'info');
					resolve();
				});
			}

			const tasks = this.props.assignment.tasks;
			tasks.forEach((task) => {
				if (task.id === taskId) {
					// Change status of task.
					if (value.indexOf('ASSESSMENT') > -1) {
						task.assignedUsers.forEach((user) => {
							if (user.id == userId) {
								user.assessmentStatus = value;
							}
						});
					} else {
						if (
							task.status == 'ASSIGNMENT_TASK_SUBMITTED' &&
							value != 'ASSIGNMENT_TASK_SUBMITTED'
						) {
							// Old status is Inlämnad. Change users assessment to ASSESMENT STARTED
							task.assignedUsers.forEach((user) => {
								if (user.assessmentStatus != null) {
									user.assessmentStatus = 'ASSESSMENT_STARTED';
								}
							});
						}

						task.status = value;
					}
				}
			});

			this.setState({});
		});
	};

	onSubmitAssignment = () => {
		const { translate } = this.props;

		let that = this;
		swal.fire({
			title: translate('are-you-sure'),
			text: translate('submit-task-text-prompt'),
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('submit'),
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			preConfirm: async () => {
				swal.getTitle().innerText = translate('Good job!');
				swal.getHtmlContainer().innerText = translate('Please wait until the submission of the assignment is completed. The window will then close automatically.');
				return new Promise(function (resolve, reject) {
					setTimeout(async function () {
						const task = that.props.assignment.tasks;
						const taskId = task[0].id;

						await that.editStatus(taskId, 'ASSIGNMENT_TASK_SUBMITTED', null);
						that.reloadAssignment();
						swal.close();
					}, 8000);
				}).catch(err => {
					return false
				})
			}
		})
	};

	toggleDeletion = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('deletion-task-text-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then((result) => {
			if (result.value != null) {
				api.delete('assignments/' + this.props.assignment.id).then((response) => {
					if (response.status != 200) {
						this.props.addError(
							this.props.translate('could-not-delete-assignment'),
							'error'
						);
						return;
					}

					this.props.deleteAssignmentFromStore(this.props.assignment.id);
					this.props.history.push(getRootUrl());
				});
			}
		});
	};

	toggleModal = (modal) => {
		let { modals } = this.state;

		const current = modals.findIndex(function (n) {
			return n.name === modal;
		});

		modals[current].open = !modals[current].open;
		this.setState({ modals });
	};

	getModalState = (modal) => {
		const { modals } = this.state;

		const current = modals.find(function (n) {
			return n.name === modal;
		});

		if (typeof current === 'undefined') {
			console.error(
				'Modals needs to be defined in the component state, the current modal will always be closed! Context:',
				modal
			);
			console.error(
				"Add this to the 'modals' array in component state: { name: '" +
				modal +
				"', open: false }"
			);

			return false;
		}

		return current.open;
	};

	onEditFormDone = (success, skipPrompt, reload) => {
		if (success) {
			if (skipPrompt) {
				this.toggleModal('edit');
				if (reload) {
					this.reloadAssignment();
				}
			} else {
				this.toggleModal('edit');
			}
		} else {
			this.toggleModal('edit');
		}
	};

	closeAssessmentModal = (skipPrompt) => {
		this.toggleModal('studentAssessment');
	};

	closeSelfAssessmentModal = (skipPrompt) => {
		this.toggleModal('selfAssessment');
	};

	openStudentAssessment = (task) => {
		task.firstname = task.groupName
		this.setState({ assessTask: task }, () => {
			this.toggleModal('studentAssessment');
		});
	};

	openSelfAssessment = () => {
		this.toggleModal('selfAssessment');
	};

	toggleTab = (tab) => {
		this.setState({ tab });
	};

	onAssessmentSubmit = (values) => {
		values.studentId = values.assessorType == ASSESSMENT_TYPES.PEER
			? this.state.assessTask?.assignedTo
			: this.props.currentUser?.id;

		return new Promise((resolve) => {
			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing, this.props.assignment.id).then(() => {
					this.props.getAssignmentAssessment(this.props.assignment.id).then((ass) => {
						resolve(1);
						values.assessorType == ASSESSMENT_TYPES.PEER ? this.closeAssessmentModal() : this.closeSelfAssessmentModal();
					})
				});

				return true;
			}

			values.assignmentId = this.props.assignment.id;
			this.props.createStudentAssessment(values).then(() => {
				this.props.getAssignmentAssessment(this.props.assignment.id).then((ass) => {
					resolve(1);
					values.assessorType == ASSESSMENT_TYPES.PEER ? this.closeAssessmentModal() : this.closeSelfAssessmentModal();
				})
			});
		})
	}

	assignmentModals = () => {
		const { assignment } = this.props;

		return (
			<div>
				<Modal
					isOpen={this.getModalState('edit')}
					title={
						<span>
							{this.props.translate('Edit the assignment') + ' '
								+ this.props.assignment.title}
						</span>
					}
					onClose={this.onEditFormDone}
				>
					<AssignmentForm
						groupid={assignment.sectionId}
						onAbort={this.onEditFormDone}
						editView={true}
						cloneView={false}
					/>
				</Modal>
			</div>
		);
	};

	render() {
		const { documentRefresh } = this.state;
		const { assignment, activeSection } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);
		const user = new User(this.props.currentUser);

		console.log("Hej single assignment")
		if (this.state.loading) return <Spinner center />;
		if (assignment != null && activeSection != null) {
			const teacherOwnsAssignment = this.props.sections.find((t) =>
				t.id == this.props.assignment.sectionId && t.userRole == 'OWNER'
			) == null ? false : true;

			let completedTeacherAssessment = null;
			let completedPeerAssessments = [];
			let completedSelfAssessment = null;
			let assessmentBlocks = [];
			let taskWorkSpace = "";
			let rootUrl = "";
			let selfAssessments = [];
			let peerAssessments = [];
			let teacherAssessments = [];
			const sectionUrl = new URL(activeSection.classroomUrl);

			if (user.isStudent()) {
				taskWorkSpace = `${sectionUrl.protocol}//${sectionUrl.hostname}${assignment.tasks[0].workspace}`;
				rootUrl = `${sectionUrl.protocol}//${sectionUrl.hostname}`;
			}
			if (!isTeacher && this.props.assessments.length > 0) {
				completedPeerAssessments = this.props.assessments.filter(assessment => assessment.assessorType == ASSESSMENT_TYPES.PEER);
				completedSelfAssessment = this.props.assessments.find(assessment => assessment.assessorType == ASSESSMENT_TYPES.SELF) ?? null;
				completedTeacherAssessment = this.props.assessments.find(assessment => assessment.assessorType == ASSESSMENT_TYPES.TEACHER || assessment.assessorType == null) ?? null;
			}

			if (this.props.blocks != null) {
				assessmentBlocks = [...this.props.blocks].filter((block) =>
					block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
				);

				selfAssessments = assessmentBlocks.filter((block) =>
					block.assessmentType == ASSESSMENT_TYPES.SELF && block.resources.length > 0
				)?.sort((a, b) => a.title.localeCompare(b.title));

				peerAssessments = assessmentBlocks.filter((block) =>
					block.assessmentType == ASSESSMENT_TYPES.PEER && block.resources.length > 0
				)?.sort((a, b) => a.title.localeCompare(b.title));

				teacherAssessments = assessmentBlocks.filter((block) =>
					(block.assessmentType == ASSESSMENT_TYPES.TEACHER || block.assessmentType == null) && block.resources.length > 0
				)?.sort((a, b) => a.title.localeCompare(b.title));
			}

			return (
				<div className='single-task' key='single-assignment-content-wrapper'>
					{teacherOwnsAssignment ? this.assignmentModals() : null}
					{this.taskContent(assignment, activeSection, teacherOwnsAssignment)}

					{!isTeacher && assignment.studentAssessmentTasks.length > 0 ?
						<Modal
							isOpen={this.getModalState('studentAssessment')}
							onClose={this.closeAssessmentModal}
							title={this.props.translate('Peer review')}
						>
							<AssessmentForm
								onSubmit={this.onAssessmentSubmit}
								onClose={this.closeAssessmentModal}
								initialValues={completedPeerAssessments?.find(s => s.referenceId == this.state.assessTask?.id) ?? null}
								blocks={peerAssessments}
								referenceType="AssignmentTask"
								referenceId={this.state.assessTask?.id}
								items={[this.state.assessTask]}
								assessmentType={ASSESSMENT_TYPES.PEER}
							/>
						</Modal>
						: null}

					{!isUserTeacher(this.props.currentUser) && selfAssessments.length > 0 ?
						<Modal
							isOpen={this.getModalState('selfAssessment')}
							onClose={this.closeSelfAssessmentModal}
							title={this.props.translate('self-assessment')}
						>
							<AssessmentForm
								onSubmit={this.onAssessmentSubmit}
								onClose={this.closeSelfAssessmentModal}
								initialValues={completedSelfAssessment}
								blocks={selfAssessments}
								referenceType="AssignmentTask"
								referenceId={assignment?.tasks[0]?.id}
								assessmentType={ASSESSMENT_TYPES.SELF}
								showStudents={false}
							/>
						</Modal>
						: null}

					<div style={{ clear: 'both' }} />

					<div style={{ marginBottom: '3em' }} className='single-section-divider' />

					{this.props.services.conversations ? (
						<Message
							activeUser={this.state.studentConversation}
							assignment={assignment}
							onClose={this.onConversationClose}
						/>
					) : null}

					{isTeacher == true && teacherOwnsAssignment ? (
						<div className='single-section'>
							<div style={{ marginBottom: '1em' }}>
								<ButtonGroup>
									<Button
										active={this.state.tab == 'assessments' ? true : false}
										onClick={() => this.toggleTab('assessments')}
										type='secondary'
									>
										{this.props.translate('assessment-tab')}
									</Button>

									<Button
										active={this.state.tab == 'assessments' ? false : true}
										disabled={!this.props.hasValidPlagiarismLicense}
										onClick={() => this.toggleTab('plagiarism')}
										type='secondary'
									>
										{this.props.translate('plagiarism-check-tab')}
									</Button>
								</ButtonGroup>
							</div>

							{this.state.tab == 'assessments' ? (
								<div>
									{assignment.groupEnabled ? (
										<List title={this.props.translate('students')}>
											<UserGroupAssignment
												assignment={assignment}
												reload={this.reloadAssignment}
												printable={false}
												onConversation={this.markStudentForConversation}
											/>
										</List>
									) : (
										<List title={this.props.translate('students')}>
											<UserAssignment
												reload={this.reloadAssignment}
												assignmentLocked={assignment.locked}
												printable={false}
												onConversation={this.markStudentForConversation}
											/>
										</List>
									)}
								</div>
							) : (
								<div>
									{this.props.hasValidPlagiarismLicense ?
										<List title={this.props.translate('plagiarism-check-tab')}>
											<FileList
												reloadParent={this.reloadAssignment}
												students={assignment.tasks}
											/>
										</List> :
										<List title={this.props.translate("plagiarism-check-tab")}>
											<div className="color--meta text--center weight--bold">
												Det finns ingen giltig licens för att använda denna tjänst
											</div>
										</List>
									}
								</div>
							)}
						</div>
					) : null}



					{!isTeacher && assignment.studentAssessmentTasks.length && this.props.blocks ? (
						<div className="single-section">
							<h3 style={{ fontWeight: 500 }}>
								{this.props.translate("Peer review")}
							</h3>

							<table className='student-assessment-list'>
								<thead>
									<tr>
										<th>{this.props.translate('buddy')}</th>
										<th>{this.props.translate('workspace')}</th>
										<th>{this.props.translate('tools')}</th>
									</tr>
								</thead>

								<tbody>
									{assignment.studentAssessmentTasks.length > 0 ? (
										assignment.studentAssessmentTasks.map((assessTask) => {
											return (
												<tr key={assessTask.id}>
													<td>{assessTask.groupName}</td>
													<td>
														<a
															target='_blank'
															href={rootUrl + assessTask.workspace}
														>
															{this.props.translate('workspace')}
														</a>
													</td>
													<td>
														<Button
															type='secondary'
															onClick={() => {
																this.openStudentAssessment(assessTask);
															}}
														>
															{this.props.translate('assess')}
														</Button>
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td>{this.props.translate('nothing-to-assess')}</td>
											<td />
											<td />
										</tr>
									)}
								</tbody>
							</table>
						</div>
					) : null}

					{user.isStudent() && selfAssessments.length > 0 ?
						<div>
							<div className='single-section'>
								<h3 style={{ fontWeight: 500 }}>
									{this.props.translate('self-assessment')}
								</h3>

								<div>
									<Button
										disabled={
											assignment.tasks[0].status == 'ASSIGNMENT_TASK_STARTED' ||
											assignment.tasks[0].status == 'ASSIGNMENT_TASK_NOT_STARTED'
										}
										type='secondary'
										onClick={this.openSelfAssessment}
									>
										{this.props.translate('assess')}
									</Button>
								</div>
							</div>
						</div>
						: null}

					{teacherAssessments.length > 0 ?
						<div className="single-section">


							{this.state.loadingBlocks ?
								<Spinner center />
								:
								<AssessmentsConsumer
									fields={teacherAssessments}
									assessment={completedTeacherAssessment}
									isStudent={!isTeacher}
								/>
							}
						</div>
						: null}

					{assignment.assignmentDocuments != null &&
						assignment.assignmentDocuments.length > 0 ? (
						<div className='single-section'>
							<h3 style={{ fontWeight: 500 }}>
								{this.props.translate('attached-instructions')}
							</h3>

							<AttachedDocuments documents={assignment.assignmentDocuments} />
						</div>
					) : null}

					{isTeacher == true ? (
						<div className='single-section'>
							<h3 style={{ fontWeight: 500 }}>
								{this.props.translate('attached-templates')}
							</h3>

							<AttachedDocuments documents={assignment.documentTemplates} />
						</div>
					) : null}

					{user.isStudent() ? (
						<div className='single-section' style={{ marginBottom: 0 }}>
							<div className='flex-container row'>
								<h3
									style={{
										fontWeight: 500,
										float: 'left',
										margin: '0.5rem 0.5rem 0.5rem 0rem',
									}}
								>
									{this.props.translate('my-files')}
								</h3>
							</div>

							<div className='flex-container row'>
								<div>
									{assignment.status != 'ASSIGNMENT_CLOSED' ? (
										<div style={{ marginTop: 5 }}>
											<UploadDocument
												title={this.props.translate('Add work')}
												assignmentId={assignment.id}
												refreshDocuments={this.refreshDocuments}
												IsSubmitted={
													assignment.tasks[0].status === 'ASSIGNMENT_TASK_SUBMITTED'
												}
												IsButtonEnable={
													assignment.tasks[0].status !== 'ASSIGNMENT_TASK_SUBMITTED' &&
													assignment.tasks[0].workspace &&
													assignment.tasks[0].assignmentTaskAssignees.find((assignee) =>
														assignee.permission != '0'
													) != null
												}
												IsTeachlefter={false}
												onUploadError={this.onUploadError}
												schoolId={activeSection?.schoolID}
											/>
										</div>
									) : null}
								</div>

								<div style={{ marginLeft: 5 }}>
									{isTeacher || assignment.status == 'ASSIGNMENT_CLOSED' ? null
										: this.statusSwitcher()}
								</div>
							</div>
						</div>
					) : null}

					{user.isStudent() ? (
						<div className='single-section' style={{ paddingTop: 0 }}>
							<div className='clearfix' />

							<AttachedUserDocuments
								assignmentId={assignment.id}
								taskId={assignment.tasks[0].id}
								IsSubmitted={
									assignment.tasks[0].status === 'ASSIGNMENT_TASK_SUBMITTED' ||
									assignment.status == 'ASSIGNMENT_CLOSED'
								}
								documentRefresh={documentRefresh}
								assignmentTask={assignment.tasks[0]}
							/>
						</div>
					) : null}
				</div>
			);
		}

		// Loading section
		return (
			<div key='single-assignment-spinner'>
				<Spinner center />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		activeSection: state.assignments.section,
		activeSchool: state.user.activeSchool,
		currentUser: state.user.currentUser,
		sections: state.sections.educationGroups,
		assessments: state.assessments.assessments,
		studentAssessment: state.assessments.studentAssessment,
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		conversations: state.Conversation.referenceConversations,
		assignmentTaskStatus: state.status.status.assignmentTaskStatus,
		reader: state.user.reader,
		hasValidPlagiarismLicense: state.Plagiarism.hasValidPlagiarismLicense,
		blocks: state.Blocks.reference,
	};
}

export default withRouter(connect(mapStateToProps, {
	getAssignmentSection,
	getAssignmentKnowledgeCollections,
	getAssignmentAssessment,
	getStudentAssessment,
	updateAssignment,
	addError,
	setActiveAssignment,
	clearAssignmentSection,
	getStudentAssessments,
	deleteAssignmentFromStore,
	getFolderFiles,
	getTaskDetails,
	setPageTitle,
	getReaderParameters,
	getAssignmentStatus,
	getBlocksByReference,
	createStudentAssessment,
	updateAssessment,
	createAssignmentTaskWorkspace,
	setTeacherDone,
	...plagiarismActions,
})(HaldorAssignment));
