import {
	GET_ADDITIONAL_ADJUSTMENTS,
	GET_STUDENT_ADJUSTMENTS,
	ADD_ADJUSTMENT_COMMENT,
	GET_SCHOOL_ADJUSTMENTS,
	CREATE_ADDITIONAL_ADJUSTMENT,
	SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT,
	UPDATE_ADDITIONAL_ADJUSTMENT,
	CREATE_STUDENT_ADJUSTMENT,
	UPDATE_STUDENT_ADJUSTMENT,
	DELETE_ADDITIONAL_ADJUSTMENT,
	DELETE_STUDENT_ADDITIONAL_ADJUSTMENT,
	DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
	UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
	GET_STUDENT_ADJUSTMENTS_BY_COURSE,
	GET_STUDENT_ADJUSTMENTS_BY_SUBJECT,
} from 'actions/additional_adjustments'

import {
	GET_GROUP_STUDENT_ADJUSTMENTS,
	GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE,
} from 'actions/sections'

import { GET_ASSIGNMENT_STUDENT_ADJUSTMENTS } from 'actions/assignments';
import { GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS } from 'actions/plans';

const INITIAL_STATE = {
	adjustments: [],
	studentAdjustments: [],
	studentAdjustmentsByCourse: [],
	studentAdjustmentsBySubject: [],
	schoolAdjustments: [],
	createdAdjustment: null,
	assignment: null,
	plan: null,
	groupAdjustments: [],
	multipleGroupAdjustments: [],
};

export default function (state = INITIAL_STATE, action) {
	let studentAdjustments = [...state.studentAdjustments];
	let studentAdjustmentsByCourse = [...state.studentAdjustmentsByCourse];
	let studentAdjustmentsBySubject = [...state.studentAdjustmentsBySubject];
	let schoolAdjustments = [...state.schoolAdjustments];
	let assignmentAdjustments = JSON.parse(JSON.stringify(state.assignment));
	let planAdjustments = JSON.parse(JSON.stringify(state.plan));
	let groupAdjustments = JSON.parse(JSON.stringify(state.groupAdjustments));
	let multipleGroupAdjustments = JSON.parse(JSON.stringify(state.multipleGroupAdjustments));

	switch (action.type) {
		case GET_ADDITIONAL_ADJUSTMENTS:
			return { ...state, adjustments: action.payload };

		case GET_STUDENT_ADJUSTMENTS:
			if (action.payload != null) {
				return { ...state, studentAdjustments: action.payload };
			}

			return state;

		case GET_STUDENT_ADJUSTMENTS_BY_COURSE:
			return { ...state, studentAdjustmentsByCourse: action.payload };

		case GET_STUDENT_ADJUSTMENTS_BY_SUBJECT:
			return { ...state, studentAdjustmentsBySubject: action.payload };

		case ADD_ADJUSTMENT_COMMENT:
			const foundAdjustment = studentAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundCourseAdjustment = studentAdjustmentsByCourse.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSubjectAdjustmentComment = studentAdjustmentsBySubject.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSchoolAdjustmentComment = schoolAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			})

			const foundGroupAdjustmentComment = groupAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			})

			let foundAssignmentAdjustmentComment = -1;
			if (assignmentAdjustments != null) {
				foundAssignmentAdjustmentComment = assignmentAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				})
			}

			let foundPlanAdjustmentComment = -1;
			if (planAdjustments != null) {
				foundPlanAdjustmentComment = planAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				})
			}

			if (foundAdjustment > -1) {
				let newAdjustment = JSON.parse(JSON.stringify(studentAdjustments[foundAdjustment]));
				newAdjustment.comments.unshift(action.payload);
				studentAdjustments.splice(foundAdjustment, 1, newAdjustment);
			}

			if (foundCourseAdjustment > -1) {
				let newCourseAdjustment = JSON.parse(JSON.stringify(studentAdjustmentsByCourse[foundCourseAdjustment]));
				newCourseAdjustment.comments.unshift(action.payload);
				studentAdjustmentsByCourse.splice(foundCourseAdjustment, 1, newCourseAdjustment);
			}

			if (foundSubjectAdjustmentComment > -1) {
				let newSchoolAdjustment = JSON.parse(JSON.stringify(studentAdjustmentsBySubject[foundSubjectAdjustmentComment]));
				newSchoolAdjustment.comments.unshift(action.payload);
				studentAdjustmentsBySubject.splice(foundSubjectAdjustmentComment, 1, newSchoolAdjustment);
			}

			if (foundSchoolAdjustmentComment > -1) {
				let newSchoolAdjustment = JSON.parse(JSON.stringify(schoolAdjustments[foundSchoolAdjustmentComment]));
				newSchoolAdjustment.comments.unshift(action.payload);
				schoolAdjustments.splice(foundSchoolAdjustmentComment, 1, newSchoolAdjustment);
			}

			if (foundAssignmentAdjustmentComment > -1) {
				let newAssignmentAdjustment = JSON.parse(JSON.stringify(assignmentAdjustments[foundAssignmentAdjustmentComment]));
				newAssignmentAdjustment.comments.unshift(action.payload);
				assignmentAdjustments.splice(foundAssignmentAdjustmentComment, 1, newAssignmentAdjustment);
			}

			if (foundPlanAdjustmentComment > -1) {
				let newAssignmentAdjustment = JSON.parse(JSON.stringify(planAdjustments[foundPlanAdjustmentComment]));
				newAssignmentAdjustment.comments.unshift(action.payload);
				assignmentAdjustments.splice(foundPlanAdjustmentComment, 1, newAssignmentAdjustment);
			}

			if (foundGroupAdjustmentComment > -1) {
				let newGroupAdjustment = JSON.parse(JSON.stringify(groupAdjustments[foundGroupAdjustmentComment]));
				newGroupAdjustment.comments.unshift(action.payload);
				groupAdjustments.splice(foundGroupAdjustmentComment, 1, newGroupAdjustment);
			}

			return {
				...state,
				studentAdjustments: studentAdjustments,
				studentAdjustmentsByCourse: studentAdjustmentsByCourse,
				studentAdjustmentsBySubject: studentAdjustmentsBySubject,
				schoolAdjustments: schoolAdjustments,
				assignment: assignmentAdjustments,
				plan: planAdjustments,
				groupAdjustments: groupAdjustments,
			};

		case CREATE_STUDENT_ADJUSTMENT:
			return { ...state, studentAdjustments: [...state.studentAdjustments, action.payload] ,studentAdjustmentsBySubject:[...state.studentAdjustmentsBySubject,action.payload] }

		case GET_SCHOOL_ADJUSTMENTS:
			return { ...state, schoolAdjustments: action.payload };

		case CREATE_ADDITIONAL_ADJUSTMENT:
			return { ...state, adjustments: [...state.adjustments, action.payload], createdAdjustment: action.payload };

		case SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT:
			return { ...state, adjustments: [...state.adjustments.filter(t => t.id != action.payload.id), action.payload] }

		case UPDATE_ADDITIONAL_ADJUSTMENT:
			return {
				...state,
				adjustments: [...state.adjustments.filter(t => t.id != action.payload.id), action.payload],
				//schoolAdjustments: [...state.schoolAdjustments.filter(t => t.id != action.payload.id), action.payload],
			}

		case UPDATE_STUDENT_ADJUSTMENT:
			if (assignmentAdjustments != null) {
				assignmentAdjustments = [...assignmentAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload]
			}

			if (planAdjustments != null) {
				planAdjustments = [...planAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload]
			}

			return {
				...state,
				studentAdjustments: [...state.studentAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				schoolAdjustments: [...state.schoolAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				studentAdjustmentsByCourse: [...studentAdjustmentsByCourse.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				studentAdjustmentsBySubject: [...studentAdjustmentsBySubject.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				groupAdjustments: [...groupAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				multipleGroupAdjustments: [...multipleGroupAdjustments.filter(t => {
					return t.id != action.payload.oldId;
				}), action.payload],
				assignment: assignmentAdjustments,
				plan: planAdjustments,
			}

		case DELETE_ADDITIONAL_ADJUSTMENT:
			return {
				...state,
				adjustments: [...state.adjustments.filter(t =>
					t.id != action.payload
				)],
				schoolAdjustments: [...state.schoolAdjustments.filter(t => {
					return t.id != action.payload;
				})],
			}

		case DELETE_STUDENT_ADDITIONAL_ADJUSTMENT:
			const foundAdjustmentToBeDeleted = studentAdjustments.findIndex(adj => adj.id == action.payload)
			const foundAdjustmentToBeDeletedFromSchool = schoolAdjustments.findIndex(adj => adj.id == action.payload)
			const foundAdjustmentToBeDeletedFromCourse = studentAdjustmentsByCourse.findIndex(adj => adj.id == action.payload)
			const foundAdjustmentToBeDeletedFromSubject = studentAdjustmentsBySubject.findIndex(adj => adj.id == action.payload)
			let foundAdjustmentToBeDeletedFromAssignment = -1;
			let foundAdjustmentToBeDeletedFromPlan = -1;

			if (assignmentAdjustments != null) {
				foundAdjustmentToBeDeletedFromAssignment = assignmentAdjustments.findIndex(adj => adj.id == action.payload)
			}

			if (planAdjustments != null) {
				foundAdjustmentToBeDeletedFromPlan = planAdjustments.findIndex(adj => adj.id == action.payload)
			}

			if (foundAdjustmentToBeDeleted > -1) {
				if (studentAdjustments[foundAdjustmentToBeDeleted].status == 'DELETED') {
					studentAdjustments.splice(foundAdjustmentToBeDeleted, 1);
				} else {
					let newAdjustment = { ...studentAdjustments[foundAdjustmentToBeDeleted] };
					newAdjustment.status = 'DELETED';
					studentAdjustments.splice(foundAdjustmentToBeDeleted, 1, newAdjustment);
				}
			}

			if (foundAdjustmentToBeDeletedFromSchool > -1) {
				if (schoolAdjustments[foundAdjustmentToBeDeletedFromSchool].status == 'DELETED') {
					schoolAdjustments.splice(foundAdjustmentToBeDeletedFromSchool, 1);
				} else {
					let newAdjustment = { ...studentAdjustments[foundAdjustmentToBeDeletedFromSchool] };
					newAdjustment.status = 'DELETED';
					schoolAdjustments.splice(foundAdjustmentToBeDeletedFromSchool, 1, newAdjustment);
				}
			}

			if (foundAdjustmentToBeDeletedFromCourse > -1) {
				if (studentAdjustmentsByCourse[foundAdjustmentToBeDeletedFromCourse].status == 'DELETED') {
					studentAdjustmentsByCourse.splice(foundAdjustmentToBeDeletedFromCourse, 1);
				} else {
					let newAdjustment = { ...studentAdjustmentsByCourse[foundAdjustmentToBeDeletedFromCourse] };
					newAdjustment.status = 'DELETED';
					studentAdjustmentsByCourse.splice(foundAdjustmentToBeDeletedFromCourse, 1, newAdjustment);
				}
			}

			if (foundAdjustmentToBeDeletedFromSubject > -1) {
				if (studentAdjustmentsBySubject[foundAdjustmentToBeDeletedFromSubject].status == 'DELETED') {
					studentAdjustmentsBySubject.splice(foundAdjustmentToBeDeletedFromSubject, 1);
				} else {
					let newAdjustment = { ...studentAdjustmentsBySubject[foundAdjustmentToBeDeletedFromSubject] };
					newAdjustment.status = 'DELETED';
					studentAdjustmentsBySubject.splice(foundAdjustmentToBeDeletedFromSubject, 1, newAdjustment);
				}
			}

			if (foundAdjustmentToBeDeletedFromAssignment > -1) {
				if (assignmentAdjustments[foundAdjustmentToBeDeletedFromAssignment].status == 'DELETED') {
					assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromAssignment, 1);
				} else {
					let newAdjustment = { ...assignmentAdjustments[foundAdjustmentToBeDeletedFromAssignment] };
					newAdjustment.status = 'DELETED';
					assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromAssignment, 1, newAdjustment);
				}
			}

			if (foundAdjustmentToBeDeletedFromPlan > -1) {
				if (planAdjustments[foundAdjustmentToBeDeletedFromPlan].status == 'DELETED') {
					planAdjustments.splice(foundAdjustmentToBeDeletedFromPlan, 1);
				} else {
					let newAdjustment = { ...planAdjustments[foundAdjustmentToBeDeletedFromPlan] };
					newAdjustment.status = 'DELETED';
					assignmentAdjustments.splice(foundAdjustmentToBeDeletedFromPlan, 1, newAdjustment);
				}
			}

			return {
				...state,
				studentAdjustments: studentAdjustments,
				schoolAdjustments: schoolAdjustments,
				studentAdjustmentsByCourse: studentAdjustmentsByCourse,
				studentAdjustmentsBySubject: studentAdjustmentsBySubject,
				assignment: assignmentAdjustments,
				plan: planAdjustments,
			};

		case DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT:
			const studentAdjustment = studentAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundCourseAdjustmentComment = studentAdjustmentsByCourse.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSubjectAdjustmentCommentDelete = studentAdjustmentsBySubject.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSchoolAdjustmentCommentDelete = schoolAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			let foundAssignmentAdjustmentCommentDelete = -1;
			if (assignmentAdjustments != null) {
				foundAssignmentAdjustmentCommentDelete = assignmentAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				});
			}

			let foundPlanAdjustmentCommentDelete = -1;
			if (planAdjustments != null) {
				foundPlanAdjustmentCommentDelete = planAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				});
			}

			let foundGroupAdjustmentCommentDelete = -1;
			if (groupAdjustments != null) {
				foundGroupAdjustmentCommentDelete = groupAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				});
			}

			if (action.payload.status == 'DELETED') {
				if (studentAdjustment > -1) {
					let newAdjustment = { ...studentAdjustments[studentAdjustment] };
					newAdjustment.comments = newAdjustment.comments.filter(t => t.id != action.payload.id);
					studentAdjustments.splice(studentAdjustment, 1, newAdjustment);
				}

				if (foundCourseAdjustmentComment > -1) {
					let newCourseAdjustment = { ...studentAdjustmentsByCourse[foundCourseAdjustmentComment] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					studentAdjustmentsByCourse.splice(foundCourseAdjustmentComment, 1, newCourseAdjustment);
				}

				if (foundSchoolAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...schoolAdjustments[foundSchoolAdjustmentCommentDelete] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundSubjectAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...studentAdjustmentsBySubject[foundSubjectAdjustmentCommentDelete] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundSchoolAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...schoolAdjustments[foundSchoolAdjustmentCommentDelete] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundAssignmentAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...assignmentAdjustments[foundAssignmentAdjustmentCommentDelete] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					assignmentAdjustments.splice(foundAssignmentAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundPlanAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...planAdjustments[foundPlanAdjustmentCommentDelete] };
					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					planAdjustments.splice(foundPlanAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundGroupAdjustmentCommentDelete > -1) {
					let newGroupAdjustment = { ...groupAdjustments[foundGroupAdjustmentCommentDelete] };
					newGroupAdjustment.comments = newGroupAdjustment.comments.filter(t => t.id != action.payload.id);
					groupAdjustments.splice(foundGroupAdjustmentCommentDelete, 1, newGroupAdjustment);
				}

				return {
					...state,
					studentAdjustments: studentAdjustments,
					studentAdjustmentsByCourse: studentAdjustmentsByCourse,
					studentAdjustmentsBySubject: studentAdjustmentsBySubject,
					schoolAdjustments: schoolAdjustments,
					assignment: assignmentAdjustments,
					plan: planAdjustments,
					groupAdjustments: groupAdjustments,
				};
			} else {
				if (studentAdjustment > -1) {
					let newAdjustment = { ...studentAdjustments[studentAdjustment] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newAdjustment.comments = newAdjustment.comments.filter(t => t.id != action.payload.id);
					newAdjustment.comments.push(newComment);

					studentAdjustments.splice(studentAdjustment, 1, newAdjustment);
				}

				if (foundCourseAdjustmentComment > -1) {
					let newCourseAdjustment = { ...studentAdjustmentsByCourse[foundCourseAdjustmentComment] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					studentAdjustmentsByCourse.splice(foundCourseAdjustmentComment, 1, newCourseAdjustment);
				}

				if (foundSchoolAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...schoolAdjustments[foundSchoolAdjustmentCommentDelete] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					schoolAdjustments.splice(foundSchoolAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundSubjectAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...studentAdjustmentsBySubject[foundSubjectAdjustmentCommentDelete] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundAssignmentAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...assignmentAdjustments[foundAssignmentAdjustmentCommentDelete] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					assignmentAdjustments.splice(foundAssignmentAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundPlanAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...planAdjustments[foundPlanAdjustmentCommentDelete] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					planAdjustments.splice(foundPlanAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				if (foundGroupAdjustmentCommentDelete > -1) {
					let newCourseAdjustment = { ...groupAdjustments[foundGroupAdjustmentCommentDelete] };
					let newComment = { ...action.payload };
					newComment.status = 'DELETED';

					newCourseAdjustment.comments = newCourseAdjustment.comments.filter(t => t.id != action.payload.id);
					newCourseAdjustment.comments.push(newComment);

					groupAdjustments.splice(foundGroupAdjustmentCommentDelete, 1, newCourseAdjustment);
				}

				return {
					...state,
					studentAdjustments: studentAdjustments,
					studentAdjustmentsByCourse: studentAdjustmentsByCourse,
					studentAdjustmentsBySubject: studentAdjustmentsBySubject,
					schoolAdjustments: schoolAdjustments,
					assignment: assignmentAdjustments,
					plan: planAdjustments,
					groupAdjustments: groupAdjustments,
				};
			}

		case UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT:
			const studentAdjustmentObject = studentAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundCourseAdjustmentCommentObject = studentAdjustmentsByCourse.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSubjectAdjustmentCommentUpdate = studentAdjustmentsBySubject.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundSchoolAdjustmentCommentUpdate = schoolAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			const foundGroupAdjustmentCommentUpdate = groupAdjustments.findIndex(adj => {
				return adj.id == action.payload.studentAdjustmentID;
			});

			let foundAssignmentAdjustmentCommentUpdate = -1;
			if (assignmentAdjustments != null) {
				foundAssignmentAdjustmentCommentUpdate = assignmentAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				});
			}

			let foundPlanAdjustmentCommentUpdate = -1;
			if (planAdjustments != null) {
				foundPlanAdjustmentCommentUpdate = planAdjustments.findIndex(adj => {
					return adj.id == action.payload.studentAdjustmentID;
				});
			}

			if (studentAdjustmentObject > -1) {
				let newAdjustmentTwo = { ...studentAdjustments[studentAdjustmentObject] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				studentAdjustments.splice(studentAdjustmentObject, 1, newAdjustmentTwo);
			}

			if (foundCourseAdjustmentCommentObject > -1) {
				let newAdjustmentTwo = { ...studentAdjustmentsByCourse[foundCourseAdjustmentCommentObject] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				studentAdjustmentsByCourse.splice(foundCourseAdjustmentCommentObject, 1, newAdjustmentTwo);
			}

			if (foundSubjectAdjustmentCommentUpdate > -1) {
				let newAdjustmentTwo = { ...studentAdjustmentsBySubject[foundSubjectAdjustmentCommentUpdate] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				studentAdjustmentsBySubject.splice(foundSubjectAdjustmentCommentUpdate, 1, newAdjustmentTwo);
			}

			if (foundSchoolAdjustmentCommentUpdate > -1) {
				let newAdjustmentTwo = { ...schoolAdjustments[foundSchoolAdjustmentCommentUpdate] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				schoolAdjustments.splice(foundSchoolAdjustmentCommentUpdate, 1, newAdjustmentTwo);
			}

			if (foundAssignmentAdjustmentCommentUpdate > -1) {
				let newAdjustmentTwo = { ...assignmentAdjustments[foundAssignmentAdjustmentCommentUpdate] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				assignmentAdjustments.splice(foundAssignmentAdjustmentCommentUpdate, 1, newAdjustmentTwo);
			}

			if (foundPlanAdjustmentCommentUpdate > -1) {
				let newAdjustmentTwo = { ...planAdjustments[foundPlanAdjustmentCommentUpdate] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				planAdjustments.splice(foundPlanAdjustmentCommentUpdate, 1, newAdjustmentTwo);
			}

			if (foundGroupAdjustmentCommentUpdate > -1) {
				let newAdjustmentTwo = { ...groupAdjustments[foundGroupAdjustmentCommentUpdate] };

				newAdjustmentTwo.comments = newAdjustmentTwo.comments.filter(t => t.id != action.payload.id);
				newAdjustmentTwo.comments.push(action.payload);

				groupAdjustments.splice(foundGroupAdjustmentCommentUpdate, 1, newAdjustmentTwo);
			}

			return {
				...state,
				studentAdjustments: studentAdjustments,
				studentAdjustmentsByCourse: studentAdjustmentsByCourse,
				studentAdjustmentsBySubject: studentAdjustmentsBySubject,
				schoolAdjustments: schoolAdjustments,
				assignment: assignmentAdjustments,
				plan: planAdjustments,
				groupAdjustments: groupAdjustments,
			};

		case GET_ASSIGNMENT_STUDENT_ADJUSTMENTS:
			return { ...state, assignment: action.payload };

		case GET_PLAN_STUDENT_ADDITIONAL_ADJUSTMENTS:
			return { ...state, plan: action.payload };

		case GET_GROUP_STUDENT_ADJUSTMENTS:
			return { ...state, groupAdjustments: action.payload };

		case GET_GROUP_STUDENT_ADJUSTMENTS_MULTIPLE:
			if (action.payload != null) {
				let multipleAdjustments = state.multipleGroupAdjustments;

				action.payload.forEach(adjustment => {
					let found = multipleAdjustments.findIndex(adj => adj.id == adjustment.id);

					if (found == -1)
						multipleAdjustments.push(adjustment);
				})

				return { ...state, multipleGroupAdjustments: multipleAdjustments };
			}

			return state;


		default:
			return state;
	}
}
