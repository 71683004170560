import React, { useState, useEffect, useRef } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import ModalObject from "containers/Modals/Modal";
import Bowser from 'bowser';
import "./_reportModal.scss";


const ReportModal = (props) => {	
	const { activeSchool, user, translate, sessionId, pageId } = props;
	const [copyConfirmText, setCopyConfirmText] = useState(translate("Copy Text"));
	const [isEnterPressed, setIsEnterPressed] = useState(false);
	const tooltipRef = useRef(null);
	const reportTitle = translate("Report bugs to support contact");
	const informationBody = translate("Send an email to your school's Haldor support contact. Please describe your problem in as much detail as possible. To facilitate the handling of your case, it is important that you include the information below:");


	useEffect(() => {	
		// changes reportmodals layout to custom style when mounted
		setTimeout(() => {
			const thisModalContainer = document.querySelector('.report-modal-container');

			if (thisModalContainer){
				const closestModalOverlay = thisModalContainer.closest('.modal-overlay');
				if (closestModalOverlay && !closestModalOverlay.classList.contains('.report-modal-container')){
					closestModalOverlay.classList.add("modal-overlay-report");					
				}
			}
		}, 1);
	
		return () => {
			// Optional cleanup logic can be added here
		};
	}, []);
	
	const getCurrentBrowser = () => {
		const browser = Bowser.getParser(window.navigator.userAgent);
		const browserName = browser.getBrowserName() || "unknown";
		const version = browser.getBrowser().version || "unknown";
		const deviceType = browser.getPlatformType() || "unknown";
		
		return (browserName + ", version: " + version + ", device: " + deviceType);
	};

	const getTimeStamp = () => {
		const now = new Date(); 
		const date = now.toISOString().substring(0, 10);
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');

		return `${date}, kl. ${hours}:${minutes}`;
	};

	const getUserRoles = () => {
		let userRoles =  "";
		let activeSchoolInfo = user.schools.filter(s => s.id == activeSchool);
		if (activeSchoolInfo [0].userRoles.length > 0){
			activeSchoolInfo [0].userRoles.forEach(role =>{
				userRoles += role + ", ";
			});
		}
		return userRoles;
	};
		
	const reportInformationToCopy = (
		`${translate("Describe the problem")}: \n\n\n\n\n` +
		`\nUser Email: ` + user.email +
		`\nUser ID: ` + user.id +
		`\nSchool ID: ` + activeSchool +
		`\nRoles in School: ` + getUserRoles() +
		`\nPage ID: ` + pageId +
		`\nTimeStamp: ` + getTimeStamp() +
		`\nSession ID: ` + sessionId +
		`\nBrowser: `+ getCurrentBrowser()
	);

	const handleCopyClick = async () => {		
		try {
			const {current: tooltip} = tooltipRef;
			const windowWidth = window.innerWidth;
			await navigator.clipboard.writeText(reportInformationToCopy);
			// Text copied successfully
			setCopyConfirmText(translate("The text was copied"));
			if(windowWidth < 768){
				tooltip.style.display = "block";
			}

			setTimeout(() => {
				if(windowWidth < 768){
					tooltip.style.display = "none";
				}
				setCopyConfirmText(translate("Copy Text"));
			}, 3000);
		} catch (error) {
			// Something went wrong
			console.error('Unable to copy text: ', error);
		}
	};

	const handleCopyKeyDown = (event) => {
		if (event.key === 'Enter') {
		  setIsEnterPressed(true);
		  handleCopyClick();
		}
		setTimeout(() => {
			setIsEnterPressed(false);
		}, 3000);
	};

	const openMailKlientWithInformation = () => {
		const mailSubject = translate('Problems in Haldor');

		return window.open(`mailto:?subject=${encodeURIComponent(mailSubject)}&body=${encodeURIComponent(reportInformationToCopy)}`)
	};

	return (
		<ModalObject
			parentSelector={() => document.querySelector('#modals')}
			isOpen={props.isOpen}
			onClose={props.onClose}
			onRequestClose={props.onClose}
			title={reportTitle}
			>
			<div className='report-modal-container'>
				<div>
					<div className="modal-content">{informationBody}</div>
					<div id="report-information">
						<div>
							<div className='copy-container'>
								<button 
								tabIndex={0} 
								id="copy-button" 
								onClick={() => handleCopyClick()} 
								onKeyDown={handleCopyKeyDown}
								data-keyboard-enter={isEnterPressed}
								>
									<span className="custom-tooltip-copy" ref={tooltipRef} >{copyConfirmText}</span>
									<img className="copy-icon" src="/dist/svg/copy-outline.svg"  alt="copy"/>
								</button>					
							</div>
							<div>
								<p>User Email: <span>{user.email}</span></p>
								<p>User ID: <span>{user.id}</span></p>
								<p>School ID: <span>{activeSchool}</span></p>
								<p>Roles in School: <span>{getUserRoles()}</span></p>
								<p>Page ID: <span>{pageId}</span></p>
								<p>TimeStamp: <span>{getTimeStamp()}</span></p>
								<p>Session ID: <span>{sessionId}</span></p>
								<p>Browser: <span>{getCurrentBrowser()}</span></p>
							</div>
						</div>
						<button tabIndex={0} id="report-copy-button" 
						onClick={() => openMailKlientWithInformation()}
						>{translate("Send e-mail")}
						</button>					
					</div>
				</div>
				<div className='cancel-container'>
					<button tabIndex={0} id="report-cancel-button" onClick={props.onClose}>{translate("close")}</button>
				</div>
			</div>			
		</ModalObject>
	);
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		activeSchool: state.user.activeSchool,
		sessionId: localStorage.getItem('hsid'),
		pageId : window.location.href,
	}
}
export default connect(mapStateToProps)(ReportModal);
