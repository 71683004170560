/**
 * @typedef {import('types').Bookmark} Bookmark
 */

import api from 'lib/api';

export const GET_MY_BOOKMARKS = 'BOOKMARKS_GET_MY_BOOKMARKS';
export const GET_BOOKMARK_BY_REFERENCE = 'BOOKMARKS_GET_BOOKMARK_BY_REFERENCE';
export const CREATE_BOOKMARK = 'BOOKMARKS_CREATE_BOOKMARK';
export const DELETE_BOOKMARK = 'BOOKMARKS_DELETE_BOOKMARK';

export const getMyBookmarks = () => ({
	type: GET_MY_BOOKMARKS,
	payload: new Promise((resolve) => {
		api.get('users/me/bookmarks').then((response) => {
			resolve(response.data);
		});
	}),
});

/**
 * @param {number} referenceId
 * @param {string} referenceType ASSIGNMENT, PLAN
 */
export const getBookmarkByReference = (referenceId, referenceType) => ({
	type: GET_BOOKMARK_BY_REFERENCE,
	payload: new Promise((resolve) => {
		api.get(`bookmarks?referenceId=${referenceId}&referenceType=${referenceType}`).then((response) => {
			resolve(response.data);
		});
	}),
});

/**
 * @param {Bookmark} values
 */
export const createBookmark = (values) => ({
	type: CREATE_BOOKMARK,
	payload: new Promise(resolve => {
		api.post(`users/me/bookmarks`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

/**
 * @param {number} id
 */
export const deleteBookmark = (id) => ({
	type: DELETE_BOOKMARK,
	payload: new Promise((resolve) => {
		api.delete(`users/me/bookmarks/${id}`).then(() => {
			resolve(id);
		});
	}),
});