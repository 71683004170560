import React from 'react';

const Document = props => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" >
    <g>
      <path
        className="icon-document"
        d="M26.5,7.3l-0.4-0.4c0,0,0,0,0,0l-2.4-2.4l-2.8-2.8c-0.2-0.2-0.4-0.3-0.6-0.3H5.8c-1.7,0-3,1.4-3,3v21.9
					c0,1.7,1.4,3,3,3h18c1.7,0,3-1.4,3-3V7.9C26.8,7.7,26.7,7.4,26.5,7.3z M21,3.6l1.8,1.8l1.8,1.8h-1.8c-1,0-1.8-0.8-1.8-1.8V3.6z
					M25.5,26.4c0,1-0.8,1.8-1.8,1.8h-18c-1,0-1.8-0.8-1.8-1.8V4.5c0-1,0.8-1.8,1.8-1.8h14c0,0,0,0,0,0v2.7c0,1.7,1.4,3,3,3h2.7V26.4z"
      />
      <path
        className="icon-document"
        d="M19.3,19.9H7.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h11.9c0.3,0,0.6-0.3,0.6-0.6
					C19.9,20.2,19.6,19.9,19.3,19.9z"
      />
      <path className="icon-document" d="M19.3,23.4H7.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h11.9c0.3,0,0.6-0.3,0.6-0.6S19.6,23.4,19.3,23.4z" />
      <path className="icon-document" d="M19.3,16.4H7.4c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h11.9c0.3,0,0.6-0.3,0.6-0.6S19.6,16.4,19.3,16.4z" />
      <path
        className="icon-document"
        d="M7.4,14.1h7.7c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.3-0.6-0.6-0.6H7.4c-0.3,0-0.6,0.3-0.6,0.6
					C6.8,13.8,7,14.1,7.4,14.1z"
      />
    </g>
  </svg>
);

export default Document;
