import React from 'react';

const MS_OneDrive = props => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve">
    <g>
      <path className="icon-ms-onedrive" d="M19.188,0A7.507,7.507,0,0,0,16.08.676,7.776,7.776,0,0,0,13.513,2.7c-.946,1.216-.811,1.216-1.351.946a6.477,6.477,0,0,0-3.919-.135A5.988,5.988,0,0,0,4.189,8.919c0,.405,0,.811-.135.811,0,.135-.27.27-.676.27A4.582,4.582,0,0,0,.27,12.567,3.474,3.474,0,0,0,0,14.459c0,1.216,0,1.351.405,2.027a6.029,6.029,0,0,0,3.108,2.7,17.657,17.657,0,0,0,3.784,0,1.347,1.347,0,0,0-.135-.541,8,8,0,0,1-.541-2.7c0-2.567,1.486-4.459,4.189-5.405.811-.27,1.081-.405,1.081-.676a8.988,8.988,0,0,1,1.081-2.432A6.674,6.674,0,0,1,15.675,5a7.31,7.31,0,0,1,8.243,1.081l.676.676.811-.27a6.079,6.079,0,0,1,1.081-.27c.27.27.27-.27,0-.946A7.713,7.713,0,0,0,19.188,0Zm-.135,5.946a5.861,5.861,0,0,0-3.108.676,6.181,6.181,0,0,0-2.7,3.513,5.508,5.508,0,0,0-.27.946c0,.405-.27.676-1.081.811a4.826,4.826,0,0,0-3.513,2.567,2.7,2.7,0,0,0-.27,1.622c0,2.432,1.216,4.189,3.243,4.594.541.135,3.108.135,11.081.135H32.837l.405-.27A3.775,3.775,0,0,0,35,17.567a3.4,3.4,0,0,0-2.432-4.054l-.676-.27-.135-.946c-.27-3.243-3.513-5.135-6.892-4.054l-.676.405-.811-.811A5,5,0,0,0,19.053,5.946Z" />
    </g>
  </svg>
);

export default MS_OneDrive;
