import { logActivity } from 'actions/logs';
import MyPlans from 'containers/Overview/Sections/MyPlans';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { isMicrosoftTeams } from 'helpers/teams';

class Plans extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allowed: true,
		};
	}

	componentDidMount() {
		//log the activity of loading the page
		const logJson = { LogType: "Route Change", ReferenceType: "Plans Page", Comment: "User loaded page" };
		this.props.logActivity(logJson);

		if (isMicrosoftTeams() && window.location.pathname == '/plans') {
			this.setState({ allowed: this.props.services.plans });
		}
	}

	render() {
		if (!this.state.allowed) {
			return (
				<div style={{ width: '80%', maxWidth: '625px', margin: '0 auto', textAlign: 'center' }}>
					{this.props.translate('service-not-active-error')}
				</div>
			);
		}

		return (
			<div className="overview">
				<MyPlans dateRestriction={false} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		sections: state.sections.educationGroups,
	}
}

export default connect(mapStateToProps, {
	logActivity,
})(Plans);
