import api from 'lib/api';

export const GET_FORM_TEMPLATES = 'TEMPLATE_GET_FORM_TEMPLATES';
export const GET_FORM_TEMPLATE = 'TEMPLATE_GET_FORM_SINGLE_TEMPLATE';
export const CREATE_FORM_TEMPLATE = 'TEMPLATE_CREATE_FORM_TEMPLATE';
export const UPDATE_FORM_TEMPLATE = 'TEMPLATE_CREATE_FORM_TEMPLATE';
export const CLEAR_FORM_TEMPLATE = 'TEMPLATE_CLEAR_SINGLE_FORM_TEMPLATE';

export const CREATE_FORM = 'TEMPLATE_ASSIGN_FORM_TEMPLATE';
export const UPDATE_FORM = 'TEMPLATE_UPDATE_FORM';
export const UPDATE_FORM_INSTANCE = 'TEMPLATE_UPDATE_FORM_INSTANCE';
export const CREATE_FORM_INSTANCE = 'TEMPLATE_CREATE_FORM_INSTANCE';
export const GET_FORM = 'TEMPLATE_GET_FORM';
export const CLEAR_FORM = 'TEMPLATE_CLEAR_FORM_DATA';
export const GET_FORMS = 'TEMPLATE_GET_FORMS';
export const GET_MY_FORMS = 'TEMPLATE_GET_MY_FORMS';
export const GET_FORM_INSTANCE = 'TEMPLATE_GET_FORM_INSTANCE';

export const createFormTemplate = (template) => ({
	type: CREATE_FORM_TEMPLATE,
	payload: new Promise(resolve => {
		api.post(`formtemplates`, template).then((data) => {
			resolve(data);
		});
	}),
});

export const updateFormTemplate = (template) => ({
	type: UPDATE_FORM_TEMPLATE,
	payload: new Promise(resolve => {
		api.put(`formtemplates`, template).then((response) => {
			resolve(template);
		});
	}),
});

export const getFormTemplates = () => ({
	type: GET_FORM_TEMPLATES,
	payload: new Promise(resolve => {
		api.get(`formtemplates`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getFormTemplate = (id) => ({
	type: GET_FORM_TEMPLATE,
	payload: new Promise(resolve => {
		api.get(`formtemplates/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearFormTemplate = () => ({
	type: CLEAR_FORM_TEMPLATE,
	payload: true,
});

export const createForm = (form) => ({
	type: CREATE_FORM_INSTANCE,
	payload: new Promise(resolve => {
		api.post(`forms`, form).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateForm = (form) => ({
	type: UPDATE_FORM,
	payload: new Promise(resolve => {
		api.put(`forms`, form).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getForm = (id) => ({
	type: GET_FORM,
	payload: new Promise(resolve => {
		api.get(`forms/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearForm = () => ({
	type: CLEAR_FORM,
	payload: true,
});

export const getForms = (creator = false) => ({
	type: GET_FORMS,
	payload: new Promise(resolve => {
		api.get(`forms?creator=${creator}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getMyForms = () => ({
	type: GET_MY_FORMS,
	payload: new Promise(resolve => {
		api.get(`forms?creator=true`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateFormInstance = (instance) => ({
	type: UPDATE_FORM_INSTANCE,
	payload: new Promise((resolve, reject) => {
		api.put(`forms/instance`, instance).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const getFormInstance = (id) => ({
	type: GET_FORM_INSTANCE,
	payload: new Promise(resolve => {
		api.get(`forms/instance/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});
