import { newGuid } from "helpers/guid";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Minus } from "UI/Icons";
import { FlexibleTextArea } from "UI/Inputs";
import MatrixBlock from "./MatrixBlock";

class MatrixParagraph extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.paragraph.id != null ? props.paragraph.id : newGuid(),
			title: props.paragraph.title != null ? props.paragraph.title : "",
			subtitle:
				props.paragraph.subtitle != null
					? props.paragraph.subtitle
					: "",
			paragraphNr: props.paragraph.paragraphNr,
			rows: props.paragraph.rows != null ? props.paragraph.rows : [],
		};
		this.disableNotAchieved = props.disableNotAchieved;
		this.hasTitle =
			props.paragraph.title != null && props.paragraph.title != "";
		this.hasSubtitle =
			props.paragraph.subtitle != null && props.paragraph.subtitle != "";
	}
	componentDidMount = () => {
		if (!this.disableNotAchieved) {
			let rows = this.state.rows;
			rows.forEach((row) => {
				const searchNotAchieved = row.cells.find((co) => {
					return co.gradeF == true;
				});

				if (searchNotAchieved == null) {
					var id = newGuid();
					var stepId = newGuid();
					var gradeF = {
						id: stepId,
						text: this.props.translate("not-achieved"),
						gradeF: true,
					};

					row.cells.unshift(gradeF);
				}
			});
			this.setState(rows),
				() => {
					this.sendToForm();
				};
		}
	};
	onBlockAdd = (event) => {
		if (event != null) {
			event.preventDefault();
		}
		this.props.onParagraphBlockAdd({
			id: this.props.paragraph.id,
			title: this.state.title,
			subtitle: this.state.subtitle,
			paragraphNr: this.state.paragraphNr,
			rows: this.state.rows,
		});
	};
	onBlockUpdate = (block) => {
		let rows = this.state.rows;
		let matrixBlock = rows.find((t) => t.id == block.id);
		matrixBlock.text = block.text;
		matrixBlock.cells = block.cells;
		this.setState({ rows }, () => {
			this.sendToForm();
		});
	};
	onBlockDelete = (block) => {
		if (event != null) {
			event.preventDefault();
		}
		let rows = this.state.rows;
		rows = rows.filter((t) => t.id != block.id);

		let isEmpty = true;
		let rowIndex = 0;
		rows.forEach((Row) => {
			isEmpty = false;
			rowIndex = rowIndex + 1;
			Row.sentenceNr = rowIndex;
			return Row;
		});
		this.setState({ rows }, () => {
			this.sendToForm();
		});
	};
	onParagraphTitleAdd = (event) => {
		if (event != null) {
			event.preventDefault();
		}
		this.hasTitle = true;
		this.setState({});
	};
	onParagraphSubTitleAdd = (event) => {
		if (event != null) {
			event.preventDefault();
		}
		this.hasSubtitle = true;
		this.setState({});
	};
	onParagraphTitleChange = (text) => {
		let title = text;
		this.setState({ title }, () => {
			this.sendToForm();
		});
	};
	onParagraphSubTitleChange = (text) => {
		let subtitle = text;
		this.setState({ subtitle }, () => {
			this.sendToForm();
		});
	};
	onParagraphDelete = (event) => {
		this.props.onParagraphDelete({
			id: this.props.paragraph.id,
			title: this.state.title,
			subtitle: this.state.subtitle,
			paragraphNr: this.state.paragraphNr,
			rows: this.state.rows,
		});
	};
	sendToForm = () => {
		this.props.onParagraphUpdate({
			id: this.props.paragraph.id,
			title: this.state.title,
			subtitle: this.state.subtitle,
			paragraphNr: this.state.paragraphNr,
			rows: this.state.rows,
		});
	};
	render() {
		return (
			<div
				style={{ marginTop: "3rem" }}
				key={`paragraph-${this.props.paragraph.paragraphNr}`}
			>
				<div
					className="form-row"
					style={{
						padding: "0",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				></div>
				<strong>
					{this.props.translate("core-section")}{" "}
					{this.props.paragraph.paragraphNr}
				</strong>

				<span
					onClick={this.onParagraphDelete}
					style={{
						flexShrink: "0",
						cursor: "pointer",
						margin: "0 1em",
						marginLeft: "3em",
					}}
				>
					<div
						style={{
							width: "15px",
							display: "inline-block",
							position: "right",
							top: "2px",
							marginRight: "0.5em",
						}}
					>
						<Minus />
					</div>
					{this.props.translate("delete-section")}
				</span>
				{this.hasTitle ? (
					<div
						className="form-row"
						style={{
							padding: "0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{ flexGrow: "1" }}>
							<FlexibleTextArea
								value={
									this.state.title != null
										? this.state.title
										: ""
								}
								placeholder={this.props.translate("heading")}
								onChange={(event) =>
									this.onParagraphTitleChange(
										event.target.value
									)
								}
							></FlexibleTextArea>
						</div>
					</div>
				) : (
						<div style={{ marginTop: "1em" }}>
							<div
								className="courses-add-btn"
								onClick={(e) => this.onParagraphTitleAdd(e)}
							>
								+ Lägg till {this.props.translate("heading")}
							</div>
						</div>
					)}
				{this.hasSubtitle ? (
					<div
						className="form-row"
						style={{
							padding: "0",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div style={{ flexGrow: "1" }}>
							<FlexibleTextArea
								value={
									this.state.subtitle != null
										? this.state.subtitle
										: ""
								}
								placeholder={this.props.translate("subtitle")}
								onChange={(event) =>
									this.onParagraphSubTitleChange(
										event.target.value
									)
								}
							></FlexibleTextArea>
						</div>
					</div>
				) : (
						<div style={{ marginTop: "1em" }}>
							<div
								className="courses-add-btn"
								onClick={(e) => this.onParagraphSubTitleAdd(e)}
							>
								+ Lägg till {this.props.translate("subtitle")}
							</div>
						</div>
					)}
				{this.state.rows.map((block, i) => {
					return (
						<div style={{ marginTop: "1rem" }} key={block.id}>
							<span
								style={{
									fontWeight: 500,
									marginBottom: "0.4em",
									fontSize: "0.85em",
									display: "block",
								}}
							>
								Block {i + 1}
							</span>

							<MatrixBlock
								key={block.id}
								block={block}
								onBlockUpdate={this.onBlockUpdate.bind(this)}
								onBlockDelete={this.onBlockDelete.bind(this)}
							/>
						</div>
					);
				})}
				<div className="clearfix" />

				<div style={{ marginTop: "1em" }}>
					<div
						className="courses-add-btn"
						onClick={(e) => this.onBlockAdd(event)}
					>
						+ {this.props.translate("add-block")}
					</div>
				</div>

				<div className="form-divider" />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {})(MatrixParagraph);
