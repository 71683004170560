import React, { Component } from 'react';

import { HaldorBlockConsumer, SkolonBlock } from '@haldor/ui';

import AssignmentBlock from './Blocks/AssignmentBlock';
import FileBlock from './Blocks/FileBlock';
import LinksBlock from './Blocks/LinksBlock';
import NDLABlock from './Blocks/NdlaBlock';

class BlockConsumer extends Component {

	render() {
		return <HaldorBlockConsumer
			plugins={[
				LinksBlock,
				AssignmentBlock,
				FileBlock,
				SkolonBlock,
				NDLABlock,
			]}
			{...this.props}
		/>
	}

}

export default BlockConsumer;