export const getConversationParticipants = (conversation, user) => {
	if (conversation == null) {
		return null;
	}

	let users = [];
	conversation.memberships.forEach(membership => {
		if (user != null && user.id == membership.membershipID) {
			return true;
		}

		if (membership.membershipType.indexOf('SECTION') > -1) {
			users.push({
				id: membership.membershipID,
				membershipType: membership.membershipType,
				...membership.section,
			});
		} else {
			users.push({
				id: membership.membershipID,
				membershipType: membership.membershipType,
				...membership.user,
			});
		}
	});

	return users;
}
