import React, { Component } from 'react';

import img from 'assets/img/spinner.png';

class Spinner extends Component {
	constructor(props) {
		super(props);
	}

	getContainerClassName = () => {
		const { center, right, small } = this.props;
		let classNames = 'spinner-container';

		if (center) {
			classNames += ' center';
		}

		if (right) {
			classNames += ' right';
		}

		if (small) {
			classNames += ' small';
		}

		return classNames;
	};

	render() {
		return (
			<div className={this.getContainerClassName()} key="spinner">
				<div className="loading-spinner" style={{backgroundImage: `url(${img})`}} />
			</div>
		);
	}
}

export default Spinner;
