import React, { Component } from 'react';
import { connect } from 'react-redux';
import msal, { loginRequest, clearInteractionProgress } from 'lib/msal';
import { appInsights, getEnhancedLogLevel } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";

import { Button, translate, setActiveLanguage, getActiveLanguage } from '@haldor/ui';
import Error from 'components/Presentation/Error/Error';
import store from '../../index';
import DateTime from '_class/DateTime';

class LandingPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			lang: null,
		};
	}

	addToast = (message, type = 'warning') => {
		const error = {
			translateMsg: message,
			type: type,
			target: 'API',
			time: new DateTime(),
		};

		store.dispatch({
			type: 'ADD_ERROR',
			payload: error,
		});
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	componentDidMount = () => {
		if (this.props.languages != null) {
			const lang = getActiveLanguage();
			this.setState({ lang });
		}
	}

	Login = async () => {

		appInsights.trackTrace({
			message: 'EDU016a | Login method on LandingPage called',
		}, {
			logLevel: getEnhancedLogLevel(),
		});

		try {
			if (microsoftTeams.app.isInitialized()) {
				microsoftTeams.app.getContext().then((context) => {
					microsoftTeams.authentication.getAuthToken().then((token) => {
						if (this.props.onLogin != null) {
							this.props.onLogin(token);
						}
					});
				});
			} else {
				clearInteractionProgress();
				msal.loginRedirect(loginRequest);
			}
		} catch (error) {
			appInsights.trackTrace({
				message: 'EDU016d | Login failed, unexpected error',
			}, {
				error: JSON.stringify(error),
				logLevel: getEnhancedLogLevel(),
			});

			console.error('Login error:', error);
		}
	}

	switchLanguage = (code) => {
		const lang = { code, active: true };
		this.setState({ lang });

		setActiveLanguage(lang.code);
	}

	resetAndLogout = () => {
		// Clear local storage and cookies
		localStorage.clear();

		// IF not teams then do logout
		if (!this.inIframe()) {
			//Redirect user to the logout page
			window.location.assign('/logout');
		} else {
			this.addToast(this.props.translate('Reset successful'), 'info')
		}
	}

	render() {
		return (
			<div>
				<Error />
				<div className="login-content">
					<h1>{this.props.translate('welcome-phrase-1')}</h1>

					<p className="login-content__subtitle">
						{this.props.translate('welcome-phrase-2')}
					</p>

					<Button type="primary" onClick={this.Login}>
						{this.props.translate('log-in')}
					</Button>

					<div style={{ marginTop: '1rem' }}>
						<strong> {this.props.translate('problems logging in')} <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={this.resetAndLogout}>{this.props.translate('reset')}</a> {this.props.translate('and log in again.')}</strong>
					</div>

					<p className="login-content__helptext">
						{`${this.props.translate('welcome-phrase-3')} `}
						<a target="_blank" href="https://haldor.se/support/">
							{this.props.translate('Contact our support')}
						</a>
					</p>

					{this.state.lang != null ?
						this.state.lang.code == 'sv-se' ?
							<p className="login-content__helptext" onClick={() => { this.switchLanguage('en-us'); }}>
								Press here for English
							</p>
							:
							<p className="login-content__helptext" onClick={() => { this.switchLanguage('sv-se'); }}>
								Klicka här för Svenska
							</p>
						: null}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
	};
}

export default connect(mapStateToProps)(LandingPage);