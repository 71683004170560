import React from 'react';

const AssignmentTaskStatusStarted = React.memo(() => {
	return (
		<svg
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<path
				d="M39.18 21.43a17.86 17.86 0 0 1-35.72 0Z"
				style={{
					fill: "#e2c30b",
					stroke: "#e2c30b",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: "3.61px",
				}}
			/>
			<path
				d="M3.46 21.43a17.86 17.86 0 0 1 35.72 0Z"
				style={{
					stroke: "#e2c30b",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: "3.61px",
					fill: "#fff",
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusStarted;