import api from 'lib/api';

export const GET_BASE_GROUP = 'BASE_GROUPS_GET_BASE_GROUP';
export const GET_BASE_GROUPS = 'BASE_GROUPS_GET_BASE_GROUPS';
export const CREATE_BASE_GROUP = 'BASE_GROUPS_CREATE_BASE_GROUPS';
export const UPDATE_BASE_GROUP = 'BASE_GROUPS_UPDATE_BASE_GROUP';
export const DELETE_BASE_GROUP = 'BASE_GROUPS_DELETE_BASE_GROUP';
export const CLEAR_ACTIVE_BASE_GROUP = 'BASE_GROUPS_CLEAR_ACTIVE';

export const getBaseGroup = (baseGroupId) => ({
	type: GET_BASE_GROUP,
	payload: new Promise(resolve => {
		api.get(`basegroups/${baseGroupId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setActiveBaseGroup = (basegroup) => ({
	type: GET_BASE_GROUP,
	payload: new Promise(resolve => {
		resolve(basegroup);
	}),
});

export const getBaseGroups = (groupId) => ({
	type: GET_BASE_GROUPS,
	payload: new Promise(resolve => {
		api.get(`basegroups/section/${groupId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createBaseGroup = (values) => ({
	type: CREATE_BASE_GROUP,
	payload: new Promise(resolve => {
		api.post('basegroups', values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateBaseGroup = (values) => ({
	type: UPDATE_BASE_GROUP,
	payload: new Promise(resolve => {
		api.put(`basegroups`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteBaseGroup = (baseGroupId) => ({
	type: DELETE_BASE_GROUP,
	payload: new Promise(resolve => {
		api.delete(`basegroups/${baseGroupId}`).then(() => {
			resolve(baseGroupId);
		});
	}),
});

export const clearActiveBaseGroup = () => ({
	type: CLEAR_ACTIVE_BASE_GROUP,
	payload: true,
});
