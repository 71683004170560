import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';

import { getActiveLanguage } from '@haldor/ui';

import './time_picker.scss';

class TimePicker extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());
	}

	onMinutesFocus = () => {
		this.refs['minute'].select();
	}

	onHourFocus = () => {
		this.refs['hour'].select();
	}

	onInputChange = (event) => {
		const timeString = event.target.value;

		const split = timeString.split(':');

		if (split.length === 2 && this.props.time) {
			const newTime = Moment(this.props.time).set('hour', split[0]).set('minute', split[1]);
			this.props.onChange(newTime);
		}
	}

	onHourChange = (event) => {
		let hours = event.target.value;

		if (parseInt(hours) < 0) {
			hours = 0;
		}

		if (parseInt(hours) > 23) {
			hours = 23;
		}

		if (hours.length > 1) {
			this.refs['minute'].focus();
		}

		if (parseInt(hours) < 10) {
			hours = '0' + hours;
		}

		const newTime = Moment(this.props.time).set('hour', hours);
		this.props.onChange(newTime);
	}

	onMinutesChange = (event) => {
		let minutes = event.target.value;

		if (minutes.length == 3) {
			event.target.select();
		}

		if (parseInt(minutes) < 0) {
			minutes = 0;
		}

		if (parseInt(minutes) > 59) {
			minutes = 59;
		}

		if (parseInt(minutes) < 10) {
			minutes = '0' + minutes;
		}

		const newTime = Moment(this.props.time).set('minutes', minutes);
		this.props.onChange(newTime);
	}

	toggleAmPm = () => {
		let newTime = null;

		if (Moment(this.props.time).format('A') == 'AM') {
			newTime = Moment(this.props.time).add(12, 'hours');
		} else {
			newTime = Moment(this.props.time).subtract(12, 'hours');
		}

		this.props.onChange(newTime);
	}

	render() {
		const format = this.props.twelve ? 'hh' : 'HH';
		const hours = this.props.time ? Moment(this.props.time).format(format) : Moment().format(format);
		const minutes = this.props.time ? Moment(this.props.time).format('mm') : Moment().format('mm');

		return (
			<div className="time-picker form">
				<div className="time-picker-container">
					<input
						className="time-part"
						type="text"
						onChange={this.onHourChange}
						onFocus={this.onHourFocus}
						value={hours}
						ref="hour"
					/>

					<span>:</span>

					<input
						className="time-part"
						type="text"
						onChange={this.onMinutesChange}
						onFocus={this.onMinutesFocus}
						value={minutes}
						ref="minute"
					/>

					{this.props.twelve ?
						<span className="time-switcher" onClick={this.toggleAmPm}>
							{Moment(this.props.time).format('A')}
						</span>
						: null}
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		languages: state.Languages.languages
	};
}

export default connect(mapStateToProps, {})(TimePicker);
