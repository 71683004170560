import React, { Component } from 'react';
import msal, {
	setUser,
	errors,
	getUser,
	onMsalTokenError,
	onMsalLoginRequired,
} from 'lib/msal';
import * as microsoftTeams from "@microsoft/teams-js";

import getEnvironment from 'lib/env';
import { appInsights, getEnhancedLogLevel } from 'lib/appInsights';

import InteractionRequired from 'containers/Errors/InteractionRequired';
import AuthenticationPopup from 'containers/Errors/AuthenticationPopup';
import LandingPage from 'components/Login/LandingPage';
import Auth from 'components/Auth';
import GenerateToken from 'components/GenerateToken';
import LTI from 'containers/LTI';

import { Spinner } from 'UI';

class AuthenticationProvider extends Component {

	static defaultProps = {
		loginComponent: null,
	};
	constructor(props) {
		super(props);
		this.state = {
			authenticated: msal.getAllAccounts().length > 0,
			loading: true,
			interactionRequired: false,
			error: null,
			authPopup: false,
		};
		this.onAuthPopupSuccessCallback = null;
	}

	// Check if the user account exists and set the state accordingly
	setAuthenticationState = (username) => {
		if (microsoftTeams.app.isInitialized()) {

			/**
			* This is a temporary fix for the issue where the contentUrl is pointing to app.haldor.se
			*/
			microsoftTeams.app.getContext().then((context) => {
				microsoftTeams.pages.getConfig().then(function (settings) {
					var tenantId = context.user.tenant.id;

					if (settings.contentUrl.indexOf('https://app.haldor.se') > -1) {
						appInsights.trackTrace({ message: 'EDU023 | ContentUrl is pointing to app.haldor.se' }, { 'tenantId': tenantId });
						var triedToChangeContentUrl = localStorage.getItem('triedToChangeContentUrl');

						if (triedToChangeContentUrl == null) {
							localStorage.setItem('triedToChangeContentUrl', 'true');

							microsoftTeams.pages.config.setConfig({
								contentUrl: settings.contentUrl.replace('https://app.haldor.se', 'https://teamsapp.haldor.se')
							}).then(function () {
								appInsights.trackTrace({ message: 'EDU023a | ContentUrl is updated to teamsapp.haldor.se' }, { 'tenantId': tenantId });
								location.reload();
							}).catch(function (error) {
								appInsights.trackTrace({ message: "EDU023b | ContentUrl could not be updated" }, error);
							});
						}
					} else {
						localStorage.removeItem('triedToChangeContentUrl');
					}
				});
			});

			microsoftTeams.authentication.getAuthToken().then((token) => {
				microsoftTeams.app.getContext().then((context) => {
					console.log("User is set in localstorage", context);
					setUser(context.user.userPrincipalName);  // user's email or User Principal Name
					this.setState({ authenticated: true, loading: false });
				});
			}).catch((error) => {
				console.error("Error getting token: ", error);
				this.setState({ authenticated: false, loading: false });
			});
		} else {
			const account = msal.getAllAccounts().find(acc => acc.username === username);

			if (account) {
				setUser(account.username);
				this.setState({ authenticated: true, loading: false });
			} else {
				if (this.state.authenticated) {
					this.setState({ loading: false });
					return;
				}

				this.setState({ authenticated: false, loading: false });
			}
		}
	}
	componentDidMount() {
		const paths = [
			'/teams/he',
			'/landingpage',
			'/tab-configure',
			'/haldor-tab/landingpage'
		];
		const isAuthOrOnboadingPath = window.location.pathname.includes('/teams/he/authentication') || window.location.pathname.includes('/teams/he');
		if (paths.includes(window.location.pathname) || isAuthOrOnboadingPath) {
			this.setState({ authenticated: true, loading: false });
		}

		onMsalTokenError((error) => {
			if (error.errorCode != null && error.errorCode === 'invalid_grant' || error.errorCode === 'interaction_required') {
				this.setState({ loading: false, interactionRequired: true, error: error });
			}
			if (error != null && error === 'login_required') {
				this.setState({ loading: false, authenticated: false });
			}
		});

		onMsalLoginRequired(() => new Promise((resolve) => {
			this.setState({ authPopup: true });
			this.onAuthPopupSuccessCallback = () => resolve(1);
			if (getEnhancedLogLevel() > 0) {
				appInsights.trackTrace({
					message: 'EDU015a | Running login auth flow in AuthenticationProvider',
				}, {
					logLevel: getEnhancedLogLevel(),
				});
			}
		}));

		if (microsoftTeams.app.isInitialized()) {
			microsoftTeams.app.getContext().then((context) => {
				this.setAuthenticationState(context.user.userPrincipalName);
			});
		} else if (!isAuthOrOnboadingPath) {
			const activeLoggedInUser = getUser()?.username;
			this.setAuthenticationState(activeLoggedInUser);
		}

		if (microsoftTeams.app.isInitialized() && !this.state.authenticated) {
			this.setState({ loading: false });
		}

		if (window.location.hash.includes('code') && window.location.pathname !== '/Auth') {
			this.setState({ loading: true });
			msal.handleRedirectPromise()
				.then((response) => {
					if (response) {
						setUser(response.account.username);
						this.setState({ authenticated: msal.getAllAccounts().length > 0, loading: false });
					} else {
						this.setState({ authenticated: false, loading: false });
					}
				})
				.catch(reason => {
					console.error('Error during redirect promise handling', reason);
					this.setState({ authenticated: false, loading: false });
					if (reason instanceof msal.AuthError) {
						msal.logoutRedirect({ postLogoutRedirectUri: window.location.origin + '/' });
					}
				});
		}

		if (localStorage.getItem('haldor-teams-auth-progress')) {
			localStorage.removeItem('haldor-teams-auth-progress');
		}

		const interactionStatus = localStorage.getItem(`msal.${getEnvironment().clientId}.interaction.status`);
		if (interactionStatus === 'interaction_in_progress') {
			localStorage.setItem(`msal.${getEnvironment().clientId}.interaction.status`, '');
		}
	}


	onLandingPageLogin = (response) => {
		if (!response) {
			appInsights.trackTrace({ message: "EDU004 | MSAL - onLandingPageLogin response null" });
			return false;
		}
		const jsonResponse = response.account ? response : JSON.parse(response);
		if (jsonResponse.account?.username) {
			setUser(jsonResponse.account.username);
		} else {
			appInsights.trackTrace({ message: "EDU017c response set user failed - (response does not contain account or username)" });
		}
		this.setState({ authenticated: true, loading: false });
	}

	onAuthenticationPopupLogin = (response) => {
		if (!response) {
			appInsights.trackTrace({ message: "EDU017 | MSAL - authenticationPopupLogin failed (response null)" });
			return false;
		}
		const jsonResponse = response.account ? response : JSON.parse(response);
		if (jsonResponse.account?.username) {
			setUser(jsonResponse.account.username);
		} else {
			appInsights.trackTrace({ message: "EDU017c response set user failed - (response does not contain account or username)" });
		}
		if (this.onAuthPopupSuccessCallback) {
			this.onAuthPopupSuccessCallback();
		}
		this.setState({ authenticated: msal.getAllAccounts().length > 0, authPopup: false });
	}

	render() {
		if (window.location.pathname === '/lti') {
			return <LTI />;
		}
		if (window.location.pathname === '/Auth') {
			return <Auth />;
		}
		if (window.location.pathname === '/GenerateToken') {
			return <GenerateToken />;
		}
		if (this.state.interactionRequired) {
			return <InteractionRequired error={this.state.error} />;
		}
		if (this.state.authenticated) {
			return (
				<>
					{this.state.authPopup && <AuthenticationPopup onLogin={this.onAuthenticationPopupLogin} />}
					{this.props.children}
				</>
			);
		}
		if (this.state.loading) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}
		return <LandingPage onLogin={this.onLandingPageLogin} />;
	}
}

export default AuthenticationProvider;