import { isSupported, setMenuItems, getMenuItems } from 'helpers/localstorage';
import {
	GET_MENU_ITEMS,
} from 'actions/menu';

let rehydrate_items = null;

if (isSupported(() => localStorage)) {
	rehydrate_items = getMenuItems();
}

const INITIAL_STATE = {
	items: rehydrate_items,
};

export default function (state = INITIAL_STATE, action) {

	switch (action.type) {
		case GET_MENU_ITEMS:
			if (action.payload != null) {
				setMenuItems(action.payload);
			}

			return { ...state, items: action.payload };

		default:
			return state;
	}

}
