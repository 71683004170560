import StandardCoursesSection from 'containers/Overview/Sections/StandardCourses';
import React, { Component } from 'react';

class StandardCourses extends Component {

	render() {
		return (
			<div className="overview">
				<div className="section" id="section-courses" style={{ padding: 0, margin: 0, border: 0 }}>
					<StandardCoursesSection />
				</div>
			</div>
		);
	}

}

export default StandardCourses;
