import { addMatrix } from "actions/matrix";
import { ASSESSMENT_MATRIX } from "constants/matrixTypes";
import { allEntriesEqual } from "helpers/array";
import { newGuid } from "helpers/guid";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Checkbox } from '@haldor/ui';
import MatrixParagraph from "./MatrixParagraph";
import { FlexibleTextArea } from "UI/Inputs";
import swal from "sweetalert2";

/* Component for creating Matrixes **/

class MatrixForm extends Component {
	constructor(props) {
		super(props);
		if (props.matrix == null) {
			this.state = {
				id: newGuid(),
				title: "",
				type: props.type,
				disableNotAchieved: false,
				stepSize: 0
			};
		} else {
			this.state = {
				id: props.matrix.id,
				title: props.matrix.title,
				type: props.type,
				disableNotAchieved: props.matrix.disableNotAchieved,
				_ParagraphSet: props.matrix._ParagraphSet,
				stepSize: 0
			};
		}
		if (this.state._ParagraphSet == null) {
			this.state._ParagraphSet = [
				{
					id: newGuid(),
					title: "",
					subtitle: "",
					paragraphNr: 1,
					rows: []
				}
			];
		}
		this.editing = props.editView;
		this.disableNotAchievedToggle = false;
	}

	onLevelChange = event => {
		let stepSize = parseInt(event.target.value, 10);
		let _ParagraphSet = this.state._ParagraphSet;
		if (stepSize > 0) {
			if (stepSize > this.state.stepSize) {
				_ParagraphSet.map((paragraph, i) => {
					let rows = paragraph.rows;
					rows.map((row, i) => {
						let neededSteps =
							parseInt(stepSize, 10) - (row.cells.length - 1);
						for (let i = 0; neededSteps > i; i++) {
							row.cells.push({
								id: newGuid(),
								keyId: newGuid(),
								text: ""
							});
						}
						return row;
					});
					paragraph.rows = rows;
					return paragraph;
				});
				this.setState({ stepSize, _ParagraphSet }, () => {
					this.props.onMatrixFormUpdate(this.state);
				});
			} else {
				let filledCell = null;
				_ParagraphSet.map((paragraph, i) => {
					let rows = paragraph.rows;
					rows.map((block, i) => {
						if (
							block.cells != null &&
							block.cells.length > stepSize &&
							filledCell == null
						) {
							let desiredSteps = stepSize;
							if (block.cells[0].gradeF) {
								desiredSteps++;
							}
							for (
								i = desiredSteps;
								i < block.cells.length;
								i++
							) {
								if (block.cells[i].text != "") {
									filledCell = true;
								}
							}
						}
					});
				});
				// Cells has been edited, show prompt
				if (filledCell != null) {
					swal.fire({
						title: this.props.translate("warning"),
						text: this.props.translate(
							"your-step-will-be-overwritten"
						),
						showCancelButton: true,
						cancelButtonText: this.props.translate("abort"),
						confirmButtonText: this.props.translate("proceed"),
						focusConfirm: false
					}).then(result => {
						if (result.value != null) {
							_ParagraphSet.map((paragraph, i) => {
								let rows = paragraph.rows;
								rows.map((block, i) => {
									if (
										block.cells != null &&
										block.cells.length > stepSize
									) {
										let desiredSteps = stepSize;
										if (block.cells[0].gradeF) {
											desiredSteps++;
										}
										if (block.cells.length < desiredSteps) {
											block.cellscells.push({
												id: newGuid(),
												keyId: newGuid(),
												text: ""
											});
										}
										block.cells.length = desiredSteps;
									}
								});
							});
							this.setState({ stepSize, _ParagraphSet }, () => {
								this.props.onMatrixFormUpdate(this.state);
							});
						}
					});
				} else {
					_ParagraphSet.map((paragraph, i) => {
						let rows = paragraph.rows;
						rows.map((block, i) => {
							if (
								block.cells != null &&
								block.cells.length > stepSize
							) {
								let desiredSteps = stepSize;
								if (block.cells[0].gradeF) {
									desiredSteps++;
								}
								if (block.cells.length < desiredSteps) {
									block.cellscells.push({
										id: newGuid(),
										keyId: newGuid(),
										text: ""
									});
								}
								block.cells.length = desiredSteps;
							}
						});
					});
					this.setState({ stepSize, _ParagraphSet }, () => {
						this.props.onMatrixFormUpdate(this.state);
					});
				}
			}
		}
	};

	onMatrixTitleChange = (text) => {
		let title = text;
		this.setState({ title }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	toggleDisableNotAchieved = checked => {
		let disableNotAchieved = this.state.disableNotAchieved;
		disableNotAchieved = !checked;
		let _ParagraphSet = this.state._ParagraphSet;
		_ParagraphSet.map((paragraph, i) => {
			let rows = paragraph.rows;
			if (!checked) {
				if (rows.length > 0) {
					rows.map(row => {
						let fIndex = row.cells.findIndex(function (cell) {
							return cell.gradeF == true;
						});

						if (fIndex > -1) {
							row.cells.splice(fIndex, 1);
						}

						return row;
					});
				}
			} else {
				if (rows.length > 0) {
					var gradeF = {
						id: newGuid(),
						text: this.props.translate("not-achieved"),
						gradeF: true
					};

					rows.map(row => {
						row.cells.unshift(gradeF);

						return row;
					});
				}
			}
			_ParagraphSet[i].rows = rows;
		});

		this.setState({ disableNotAchieved, _ParagraphSet }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	onParagraphAdd = event => {
		event.preventDefault();
		let _ParagraphSet = this.state._ParagraphSet;
		let paragraphNr = _ParagraphSet.length;
		let row = {
			id: newGuid(),
			sentenceNr: 1,
			cells: []
		};
		if (!this.state.disableNotAchieved) {
			row.cells.push({
				id: newGuid(),
				text: this.props.translate("not-achieved"),
				gradeF: true
			});
		}
		if (this.state.stepSize > 0) {
			for (let i = 0; this.state.stepSize > i; i++) {
				row.cells.push({
					id: newGuid(),
					keyId: newGuid(),
					text: ""
				});
			}
		}
		paragraphNr++;
		_ParagraphSet.push({
			id: newGuid(),
			title: "",
			subtitle: "",
			paragraphNr: paragraphNr,
			rows: [row]
		});
		this.setState({ _ParagraphSet }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	onParagraphUpdate = Paragraph => {
		let _ParagraphSet = this.state._ParagraphSet;
		let paragraph = _ParagraphSet.find(p => p.id == Paragraph.id);
		paragraph.title = Paragraph.title;
		paragraph.subtitle = Paragraph.subtitle;
		paragraph.rows = Paragraph.rows;
		paragraph.paragraphNr = Paragraph.paragraphNr;
		this.setState({ paragraph }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	onParagraphDelete = Paragraph => {
		let _ParagraphSet = this.state._ParagraphSet;
		_ParagraphSet = _ParagraphSet.filter(p => p.id != Paragraph.id);
		let paragraphIndex = 0;
		_ParagraphSet.forEach(paragraph => {
			paragraphIndex = paragraphIndex + 1;
			paragraph.paragraphNr = paragraphIndex;
			return paragraph;
		});
		this.setState({ _ParagraphSet }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	onParagraphBlockAdd = Paragraph => {
		let _ParagraphSet = this.state._ParagraphSet;
		let paragraph = _ParagraphSet.find(p => p.id == Paragraph.id);
		paragraph.title = Paragraph.title;
		paragraph.subtitle = Paragraph.subtitle;
		paragraph.paragraphNr = Paragraph.paragraphNr;
		let rows = paragraph.rows;
		let cells = [];
		let id = newGuid();
		if (!this.state.disableNotAchieved) {
			cells.push({
				id: newGuid(),
				text: this.props.translate("not-achieved"),
				gradeF: true
			});
		}

		for (let i = 0; this.state.stepSize > i; i++) {
			// Add empty step to the cells array
			cells.push({
				id: newGuid(),
				keyId: newGuid(),
				text: ""
			});
		}

		let sentenceNr = 0;
		if (rows.length > 0) {
			sentenceNr = Math.max.apply(
				Math,
				rows.map(function (o) {
					return parseInt(o.sentenceNr, 10);
				})
			);
		}
		sentenceNr++;
		rows.push({ id, text: "", cells, sentenceNr });
		paragraph.rows = rows;

		this.setState({ paragraph }, () => {
			this.props.onMatrixFormUpdate(this.state);
		});
	};

	render() {
		return (
			<div className="form matrix-container" style={{ marginBottom: "1.25em" }}>
				{!this.editing || this.disableNotAchievedToggle ? (
					<div style={{ position: "relative", marginTop: "2em" }}>
						<div
							className="form-row"
							style={{
								float: "left",
								padding: 0,
								paddingRight: "1em",
								width: "200px"
							}}
						>
							<div className="select">
								<select value={this.state.stepSize} onChange={this.onLevelChange}>
									<option value="0">
										{this.props.translate("choose-steps")}
									</option>

									<option value="1">
										1 {this.props.translate("step")}
									</option>

									<option value="2">
										2 {this.props.translate("step")}
									</option>

									<option value="3">
										3 {this.props.translate("step")}
									</option>

									<option value="4">
										4 {this.props.translate("step")}
									</option>
								</select>
							</div>
						</div>

						{!this.disableNotAchievedToggle ? (
							<div style={{ float: "left" }}>
								<Checkbox
									onChange={this.toggleDisableNotAchieved}
									value={!this.state.disableNotAchieved}
									label={this.props.translate("not-achieved")}
								/>
							</div>
						) : null}
						<div className="clearfix" />
					</div>
				) :
					<div style={{ position: "relative" }}>
						<div
							className="form-row"
							style={{
								float: "left",
								padding: 0
							}}
						>
							<label>{this.props.translate("title")}</label>
							<FlexibleTextArea
								value={
									this.state.title != null
										? this.state.title
										: ""
								}
								placeholder={this.props.translate("title")}
								onChange={(event) =>
									this.onMatrixTitleChange(
										event.target.value
									)
								}
							/>
						</div>
						<div className="clearfix" />
					</div>}

				<div className="form-divider" />

				{this.state._ParagraphSet.map((paragraph, i) => {
					return (
						<MatrixParagraph
							key={paragraph.id}
							paragraph={paragraph}
							disableNotAchieved={this.state.disableNotAchieved}
							onParagraphUpdate={this.onParagraphUpdate.bind(
								this
							)}
							onParagraphDelete={this.onParagraphDelete.bind(
								this
							)}
							onParagraphBlockAdd={this.onParagraphBlockAdd.bind(
								this
							)}
						/>
					);
				})}

				<div className="clearfix" />

				<div style={{ marginTop: "2rem" }}>
					<div className="courses-add-btn" onClick={this.onParagraphAdd}>
						+ {this.props.translate("add-core-section")}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations)
	};
}

export default connect(mapStateToProps, { addMatrix })(MatrixForm);
