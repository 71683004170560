import api from 'lib/api';

export const GET_TAGS = 'TAGS_GET_TAGS';
export const ADD_TAGS_ON_FILE = 'TAGS_ADD_TAGS_ON_FILES';
export const REMOVE_TAG_ON_FILE = 'TAGS_REMOVE_TAG_ON_FILES';

export const getTags = () => ({
	type: GET_TAGS,
	payload: new Promise(resolve => {
		api.get(``).then((response) => {
			resolve(response.data);
		});
	}),
});

export const addTagsOnFile = (fileId, tags) => ({
	type: ADD_TAGS_ON_FILE,
	payload: new Promise((resolve, reject) => {
		api.post(`fileItems/${fileId}/tags`, tags).then((response) => {
			resolve(response.data);
		});
	}),
});

export const removeTagOnFile = (fileId, tagId) => ({
	type: REMOVE_TAG_ON_FILE,
	payload: new Promise((resolve) => {
		api.delete(`fileItems/${fileId}/tags/${tagId}`).then((response) => {
			resolve(1);
		});
	}),
});