import React, { useEffect, useState } from 'react';
import { Flex, Icon, Expandable, Block } from '@haldor/ui';
import { useTranslate } from 'lib/translate';

import GoalMatrixRowForm from './GoalMatrixRowForm';
import { capitalize } from 'helpers/content';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import { ColorLabel, ColorLabelContainer } from 'UI';

import './_OnlyFeedbackForm.scss';

const OnlyFeedbackForm = (props) => {
	const translate = useTranslate();
	const [rows, setRows] = useState(props.part?.value?.rows ?? []);

	useEffect(() => {
	}, []);

	const removeMatrix = () => {
		if (props.onRemove != null) {
			props.onRemove();
		}
	}

	/**
	 * @param {string} target
	 * @param {string} referenceType
	 * @description Toggles target permission on part data
	 */
	const togglePermission = (target, referenceType) => {
		let permissions = props.part.permissions;

		const indexOf = props.part.permissions?.findIndex(element =>
			element.target == target && element.referenceType == referenceType
		);

		if (indexOf > -1) {
			permissions.splice(indexOf, 1);
		} else {
			permissions.push({ target: target, referenceType: referenceType, level: 'READ' });
		}

		props.onChange({
			...props.part,
			permissions: permissions
		});
	}

	const renderAssessmentFeedbackPermission = () => {
		const active = props.part.permissions?.find(element =>
			element.target == 'GUARDIAN' && element.referenceType == 'ASSESSMENTFEEDBACK'
		) != null;

		return <div className="permission active" onClick={(event) => {
			event.stopPropagation(); togglePermission('GUARDIAN', 'ASSESSMENTFEEDBACK');
		}}>

			<>
				<Icon name={active ? "Eye" : "Eye_Off"} />
				{translate(capitalize('Show feedback for guardians'))}
			</>

		</div>
	}

	return (
		<div className="only-feedback-container-form">
			<div className="title">
				<Icon name="Alert" bw /> {translate('You can always provide feedback. If you add learning goals or a curriculum, then the feedback will be tied to these.')}
			</div>

			<div className="trigger-container">
				{renderAssessmentFeedbackPermission()}
			</div>
		</div>
	);

}

OnlyFeedbackForm.defaultProps = {
	assessments: [],
	disabled: false,
};

export default OnlyFeedbackForm;