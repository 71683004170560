import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import DateSpan from 'components/Presentation/Date/DateSpan';
import ResizeObserver from 'react-resize-aware';

import { translate, getActiveLanguage } from '@haldor/ui';
import { ColorLabel, ColorLabelContainer } from 'UI';
import { Minus, Plus } from 'UI/Icons';

class ReactGantt extends Component {
	constructor(props) {
		super(props);
		moment.locale(getActiveLanguage());
		this.state = {
			tableId: "react-gantt",
			scaleMarksCount: 99,
			scaleDrawn: false,
			groups: props.groups ? props.groups.sort((a, b) => (a.title > b.title) ? 1 : (a.title === b.title) ? ((a.title > b.title) ? 1 : -1) : -1) : [],
		};
	}

	getCalculatedStyles = (row) => {
		const difference = moment(this.props.options.leftBound).unix();
		const rightBound = moment(this.props.options.rightBound).unix() - difference;

		let startDate = moment(row.startDate).unix() - difference;
		if (startDate < 0) {
			startDate = 0;
		} else if (startDate > rightBound) {
			startDate = rightBound;
		}

		let climaxDate = moment(row.climaxDate).unix() - difference;
		if (climaxDate < 0) {
			climaxDate = 0;
		} else if (climaxDate > rightBound) {
			climaxDate = rightBound;
		}

		let endDate = moment(row.endDate).unix() - difference;
		if (endDate < 0) {
			endDate = 0;
		} else if (endDate > rightBound) {
			endDate = rightBound;
		}

		const leftPadWidth = `${startDate / rightBound * 100}%`;
		let div2Width = (endDate - startDate) / rightBound * 100;
		const rightPadWidth = `${(rightBound - endDate) / rightBound * 100}%`;

		let div2BackgroundColor = 'red';
		if (row.afterClimaxColor) {
			div2BackgroundColor = row.afterClimaxColor;
		} else if (this.props.options.afterClimaxColor) {
			div2BackgroundColor = this.props.options.afterClimaxColor;
		}

		if (div2Width < 10) {
			div2Width = 10;
		}

		let bar1 = {

		};

		let bar2 = {
			marginTop: '2px',
			marginBottom: '2px',
			marginLeft: '0px',
			marginRight: '0px',
			padding: '1.0rem 1.25rem',
			position: 'absolute',
			right: rightPadWidth,
			backgroundColor: div2BackgroundColor,
			width: div2Width,
			float: 'left',
			height: '30px',
			borderLeft: '3px solid #997AE8',
			borderTopRightRadius: '10px',
			borderBottomRightRadius: '10px',
			boxShadow: '2px 2px 4px #000000',
			borderRadius: '4px',
			zIndex: '999',
			cursor: 'pointer',
		};

		if (this.props.options.barStyle.shadow != null) {
			if (this.props.options.barStyle.shadow == false) {
				bar2.boxShadow = 'none';
			} else {
				bar2.boxShadow = this.props.options.barStyle.shadow;
			}
		}

		if (this.props.options.barStyle.border != null) {
			bar2.border = this.props.options.barStyle.border;
		}

		if (this.props.options.barStyle.height != null) {
			bar2.height = this.props.options.barStyle.height;
		}

		if (this.props.options.barStyle.borderRadius != null) {
			bar2.borderTopLeftRadius = this.props.options.barStyle.borderRadius;
			bar2.borderBottomLeftRadius = this.props.options.barStyle.borderRadius;
			bar2.borderBottomRightRadius = this.props.options.barStyle.borderRadius;
			bar2.borderTopRightRadius = this.props.options.barStyle.borderRadius;
		}

		if (row.borderLeft != null) {
			bar2.borderLeft = row.borderLeft;
		}

		bar2.borderLeftColor = '#997AE8';
		bar2.borderLeftWidth = '3px';
		bar2.transition = '0.14s cubic-bezier(0.215, 0.61, 0.355, 1)';
		bar2.boxShadow = '0px 2px 12px rgba(0, 0, 0, 0.15)';

		return { bar1, bar2 };
	};

	renderBar(row) {
		const { bar1, bar2 } = this.getCalculatedStyles(row);

		return (
			<div onClick={row.action} style={{ minHeight: '120px' }}>
				<div style={bar1} />
				<div style={bar2}>
					<div style={bar1} />
					{this.props.options.barStyle.showTitle ? <h3>{row.title}</h3> : null}
				</div>
			</div>
		);
	}

	onTimeLineRowClick = (event, group) => {
		event.preventDefault();
		const { groups } = this.state;

		groups.map((g_o) => {
			if (g_o.id == group.id) {
				g_o.open = !g_o.open;
			}
		});

		this.setState({ groups });
	};

	renderMultipleBars = (rows, group, index) => {
		const timelineStyle = {
			width: '100%',
		};

		timelineStyle.position = 'relative';
		timelineStyle.display = 'block';

		let numberOfRowsInGroup = 0;
		return (
			<td
				style={timelineStyle}
				onClick={(e) => {
					this.onTimeLineRowClick(e, group);
				}}
			>
				<div style={{ position: 'relative', top: '-15px', zIndex: '999' }}>
					{group.showGroupTitle ? group.title : null} {' '}
					{group.showGroupTitle && group.title != null ?
						<div className="timeline-dropdown-button">
							{group.showGroupTitle ?
								group.open ? <Minus /> : <Plus />
								: null}
						</div>
						: null}
				</div>

				{this.props.rows.map((row, index) => {
					if (group.id == row.group) {
						if (
							row.endDate >= this.props.options.leftBound &&
							row.startDate <= this.props.options.rightBound
						) {
							let { bar1, bar2 } = this.getCalculatedStyles(row);

							if (bar2.opacity != 0) {
								<div style={{ display: 'block', height: '0px' }} />;
								const widthValue = bar2.width;
								bar2.width += '%';
								bar1.width += '%';

								let rowIsOverlaying = false;
								let containerStyle = {};

								if (widthValue > 0) {
									if (group.open) {
										containerStyle = { position: 'relative' };
										bar1.top = `${125 * numberOfRowsInGroup}px`;
										bar2.top = `${125 * numberOfRowsInGroup}px`;
									}

									numberOfRowsInGroup += 1;
								} else {
									containerStyle = { position: 'relative' };
								}

								this.props.rows.map((r_o) => {
									if (group.id == r_o.group) {
										if (moment(row.endDate).isBefore(moment(r_o.endDate))) {
											rowIsOverlaying = true;
										}
									}
								});

								if (rowIsOverlaying == true && !group.open) {
									if (numberOfRowsInGroup < 3) {
										bar2.marginTop = 15 * (numberOfRowsInGroup - 1) + 'px';
									}

									bar2.zIndex = 99 - Math.floor(widthValue);

									if (bar2.zIndex <= 0) { //Fail safe, plan should be visible and not disappear
										bar2.zIndex = 1;
									}
								}

								let courses = null;
								if (row.planning.courses != null && row.planning.courses.length > 0) {
									courses = row.planning.courses;
								} else {
									if (row.planning.associatedMatrices != null && row.planning.associatedMatrices.length > 0) {
										courses = [];
										row.planning.associatedMatrices.forEach(matrice => {
											if (matrice._ParagraphSet != null && matrice._ParagraphSet.length > 0) {
												courses.push({
													...matrice.course,
													id: matrice.courseId,
												});
											}
										});
									}
								}

								return (
									<div onClick={row.action} style={containerStyle} key={`gantt_bar-${row.id}`}>
										<div style={bar2}>
											<div style={{ float: 'left', width: '100%' }}>
												{this.props.options.barStyle.showGroupTitle ? (
													<div className="time-line-item-titles">
														<div className="time-line-item-groups">
															{widthValue > 8 && courses != null && courses.length > 0 ?
																<ColorLabelContainer collapse>
																	{courses.map(course => {
																		const int = Math.floor((Math.random() * 10) + 1);
																		let text = course.title;
																		text = course.year != null ? text + ' ' + course.year : text;

																		return <ColorLabel
																			key={'planning-' + row.id + '-' + int + course.id}
																			tooltip={text}
																			color={course.colorCode}
																		/>;
																	})}
																</ColorLabelContainer>
																: null}
														</div>

														<div className="time-line-item-date" style={widthValue > 20 ? {} : { display: 'none' }}>
															<DateSpan
																start={row.startDate}
																end={row.endDate}
															/>
														</div>

														{row.planning.status == 'PLANNING_DRAFT' && widthValue > 20 ?
															<div className="card-meta" style={{ marginLeft: 5, height: 26 }}>
																{this.props.translate('Draft')}
															</div>
															: null}
													</div>
												) : null}

												{this.props.options.barStyle.showTitle ? (
													<h3 className="timeline-planning-title">
														{row.title}
													</h3>
												) : null}
											</div>
										</div>
									</div>
								);
							}
						}
					}
				})}

				<div style={{ clear: 'both' }} />
			</td>
		);
	};

	renderRows() {
		const rows = [];
		let labelWidth = '80px';
		if (this.props.options && this.props.options.labelWidth) {
			labelWidth = this.props.options.labelWidth;
		}

		const titleStyle = {
			textAlign: 'left',
			verticalAlign: 'top',
			paddingRight: '10px',
		};

		const timelineStyle = {
			width: '100%',
		};

		if (this.props.options.showBorders !== false) {
			titleStyle.border = 'solid';
			timelineStyle.border = 'solid';
		}

		const labelStyle = {
			width: labelWidth,
		};

		if (this.props.rows.length > 0) {
			if (this.state.groups != null && this.state.groups.length > 0) {
				// For each group we iterate the rows and if the group id matches on the row compared to the
				// "current" group id we show it, using the this.renderBar method.

				for (var i = 0; i < this.state.groups.length; i++) {
					var groupObject = this.state.groups[i];
					const groupId = groupObject.id;
					var rowStyle = {};

					rowStyle.marginTop = '1em';
					rowStyle.transition = '0.55s cubic-bezier(0.215, 0.61, 0.355, 1)';

					if (groupObject.open) {
						let numberOfRowsInGroup = 0;

						this.props.rows.map((r_o) => {
							const leftBound = moment(this.props.options.leftBound).unix();
							const rightBound = moment(this.props.options.rightBound).unix();

							let startDate = moment(r_o.startDate).unix();
							if (startDate < 0) {
								startDate = 0;
							}

							let endDate = moment(r_o.endDate).unix();
							if (endDate < 0) {
								endDate = 0;
							}

							let inViewport = ((startDate >= leftBound && startDate <= rightBound) || (endDate >= leftBound && endDate <= rightBound));
							let outsideViewport = ((startDate <= leftBound) && (endDate >= rightBound));

							if ((inViewport || outsideViewport) && (r_o.group == groupObject.id)) {
								numberOfRowsInGroup += 1;
							}
						});

						rowStyle.height = `${125 * numberOfRowsInGroup + 50}px`;
					} else {
						rowStyle.height = '175px';
					}

					let visibleObjects = false;
					this.props.rows.map((row, index) => {
						if (groupObject.id == row.group) {
							if (row.endDate >= this.props.options.leftBound && row.startDate <= this.props.options.rightBound) {
								visibleObjects = true;
							}
						}
					});

					if (visibleObjects) {
						var row = (
							<tr
								key={groupId + groupObject.title}
								style={rowStyle}
								className="timeline-item-containers">
								{this.props.options.hideFirstColumn == false ? (
									<td style={titleStyle}>
										<div style={labelStyle}>{groupObject.title}</div>
									</td>
								) : null}

								{this.renderMultipleBars(this.props.rows, groupObject, i)}
							</tr>
						);

						rows.push(row);
					}
				}
			} else {
				// the groups object isnt passed from the parent component

				for (var i = 0; i < this.props.rows.length; i++) {
					var rowStyle = {
						cursor: 'pointer',
					};
					const rowObject = this.props.rows[i];
					var row = (
						<tr
							key={i}
							style={rowStyle}
							onClick={rowObject.action}
						>
							{this.props.options.hideFirstColumn == false ? (
								<td style={titleStyle}>
									<div style={labelStyle}>{rowObject.title}</div>
								</td>
							) : null}
							<td style={timelineStyle}>{this.renderBar(rowObject)}</td>
						</tr>
					);

					rows.push(row);
				}
			}
		} else {
			var row = (
				<tr key={0}>
					{this.props.options.hideFirstColumn == false ? (
						<td style={titleStyle}>
							<div style={labelStyle} />
						</td>
					) : null}
					<td style={timelineStyle}>
						<span>
							{this.props.options.placeholder != null
								? this.props.options.placeholder
								: 'No Data'}
						</span>
					</td>
				</tr>
			);
			rows.push(row);
		}

		return rows;
	}

	drawScale() {
		const leftBound = this.props.options.leftBound;
		const rightBound = this.props.options.rightBound;
		var minutes = 0;
		var hours = 0;
		var days = 0;
		var weeks = 0;
		var months = 0;
		const years = moment(rightBound).diff(moment(leftBound), 'years');

		if (years < 2) {
			var months = moment(rightBound).diff(moment(leftBound), 'months');
			if (months < 6) {
				var days =
					(moment(rightBound).unix() - moment(leftBound).unix()) / 24 / 60 / 60;
				if (days < 2) {
					var hours = moment(rightBound).diff(moment(leftBound), 'hours');
					if (hours < 1) {
						var minutes = moment(rightBound).diff(moment(leftBound), 'minutes');
						this.setState({ scale: this.calculateScale(minutes, 'minutes') });
					} else {
						this.setState({ scale: this.calculateScale(hours, 'hours') });
					}
				} else if (days > 7) {
					var weeks = days / 7;
					this.setState({ scale: this.calculateScale(weeks, 'weeks') }, () => {
						//window.dispatchEvent(new Event('resize'));
					});
				} else {
					this.setState({ scale: this.calculateScale(days, 'days') }, () => {
						//window.dispatchEvent(new Event('resize'));
					});
				}
			} else {
				this.setState({ scale: this.calculateScale(months, 'months') });
			}
		} else {
			this.setState({ scale: this.calculateScale(years, 'years') });
		}
	}

	calculateScale(count, type) {
		const options = this.props.options;
		moment.locale(getActiveLanguage());
		const difference = moment(options.leftBound).unix();
		const widthByTime = moment(options.rightBound).unix() - difference;
		let scale = null;

		if (options.hideFirstColumn == true) {
			// If we dont render the first td for group names we can't query the second child since theres only one!
			// This crude statement fixes that.
			// This is a very specific statement and should only be in place to fix the error if hideFirstcolumn is set to true
			scale = document.querySelector(`#${this.state.tableId} thead td:nth-child(1)`);
		} else {
			scale = document.querySelector(`#${this.state.tableId} thead td:nth-child(2)`);
		}

		let widthByPixels = 0;
		if (scale != null) {
			widthByPixels = scale.offsetWidth;
		}

		var markersCount = Math.round(widthByPixels / 100);
		const unitByPixels = widthByPixels / count;
		let maxIntervalWidth = 100;
		if (options.maxIntervalWidth) {
			maxIntervalWidth = options.maxIntervalWidth;
		}

		let unitsPerInterval = 1;
		if (maxIntervalWidth > unitByPixels) {
			unitsPerInterval = Math.floor(maxIntervalWidth / unitByPixels);
		}

		const intervalByPixels = unitsPerInterval * unitByPixels;
		var markersCount = Math.floor(widthByPixels / intervalByPixels) + 1;
		if (markersCount > 4) {
			// Fail safe, no more than 4 weeks. Add one week to markerscount since we start  from Monday first week and end last sunday after 3 weeks
			markersCount = 4;
		} else if (markersCount < 4) {
			// Fail safe, we need 4 weeks totalt
			markersCount = 4;
		}
		const intervalByPercent = intervalByPixels / widthByPixels * 100;

		const markers = [];
		const style = {
			margin: '0px',
			padding: '0px',
			width: '25%',
			float: 'left',
			borderLeft: 'none',
			borderWidth: '0px',
			paddingLeft: '10px',
			fontWeight: '400',
			textTransform: 'uppercase',
			fontSize: '0.75em',
			lineHeight: '35px',
			color: '#3A3A3A',
		};

		if (options.labelBorderColor != null) {
			style.borderColor = options.labelBorderColor;
		}

		for (let i = 0; i < markersCount; i++) {
			const date = moment(difference * 1000);
			var formattedInterval,
				formattedCurrentDate;
			const currentDate = moment();

			switch (type) {
				case 'years':
					date.add(i * unitsPerInterval, 'years');
					formattedInterval = date.format('YYYY MM DD');
					break;
				case 'months':
					date.add(i * unitsPerInterval, 'months');
					formattedInterval = date.format('YYYY MM DD');
					break;
				case 'weeks':
					date.add(i * unitsPerInterval, 'weeks');
					formattedInterval = date.format('YYYY MM DD');
					break;
				case 'days':
					date.add(i * unitsPerInterval, 'days');
					formattedInterval = date.format('YYYY MM DD');
					break;
				case 'hours':
					date.add(i * unitsPerInterval, 'hours');
					formattedInterval = date.format('H:mm');
				case 'minutes':
					date.add(i * unitsPerInterval, 'minutes');
					formattedInterval = date.format('H:mm:ss');
				default:
			}

			if (options && options.intervalFormat) {
				if (options.intervalFormat == 'W') {
					formattedInterval = date.week();
					formattedCurrentDate = currentDate.week();
				}
				else {
					formattedInterval = date.format(options.intervalFormat);
					formattedCurrentDate = currentDate.format(options.intervalFormat);
				}
			}

			const mark = (
				<div key={i} style={style}>
					<span
						className={
							formattedCurrentDate == formattedInterval
								? 'current-column'
								: null
						}
					>
						{`${this.props.weekTranslation} ${formattedInterval}`}
					</span>
				</div>
			);
			markers.push(mark);
		}

		return <div>{markers}</div>;
	}

	UNSAFE_componentWillMount() {
		this.bootstrapped = false;

		/*
		if (this.props.options.bootstrapped) {
			this.bootstrapped = this.props.options.bootstrapped;
		}
		*/

		if (this.props.options.responsive) {
			this.responsive = true;
		}
	}

	componentDidMount() {
		this.previousProps = this.props;
		this.drawScale();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.rows != nextProps.rows) {
			// prevents infinite loop
			this.previousProps = nextProps.props;
			//this.drawScale();
		}

		this.drawScale();
	}

	render() {
		const tableStyle = {
			width: '100%',
			overflow: 'hidden',
		};

		const scaleStyle = {
			width: '100%',
			paddingBottom: '2em',
		};

		const popoverStyle = {
			position: 'absolute',
			display: 'none',
		};

		return (

			<div id={this.state.tableId} style={{ position: 'relative' }}>
				<table style={tableStyle} className="table table-striped table-bordered table-condensed table-hover">
					<thead>
						<tr>
							{this.props.options.hideFirstColumn == false ? <td /> : null}
							<td style={scaleStyle}>{this.state.scale}</td>
						</tr>
					</thead>
					<tbody>{this.renderRows()}</tbody>
				</table>

				<div className="lines">
					<div className="line" />
					<div className="line" />
					<div className="line" />
					<div className="line" />
				</div>
				<ResizeObserver
					onResize={() => {
						this.drawScale();
					}}
				>
				</ResizeObserver>
			</div >

		);
	}
}

ReactGantt.propTypes = {
	groups: PropTypes.array,
	options: PropTypes.object,
	rows: PropTypes.array,
};

ReactGantt.defaultProps = {
	groups: {},
	options: {},
	rows: {},
};

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps, null, null, {
	pure: false,
})(ReactGantt);