import React from 'react';
import { Icon, Floating } from '@haldor/ui';

const DropDownList = (props) => {

	return (
		<Floating
			trigger={
				<button
					className='c--button dropdown'
					disabled={props.disabled}
				>
					{props.label}
					<Icon name='Chevron_Down' style={{ marginLeft: '2rem' }} />
				</button>
			}
		>
			<div className='dropDownContainer'>
				{props.children}
			</div>
		</Floating>
	);
};

export default DropDownList;
