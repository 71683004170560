import DOMPurify from "dompurify";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import ColorScale from '_class/ColorScale';
import { Collapsible } from 'UI';

/* Compontent for displaying blocks from a Matrix */

class MatrixDisplayBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openClarifications: false,
		};
	}

	getGrade(index) {
		var type = this.props.type;
		if (type == "single") {
			index = index + 1;
		}

		if (index == 0) {
			return "F";
		} else if (index == 1) {
			return "E";
		} else if (index == 2) {
			return "C";
		} else if (index == 3) {
			return "A";
		} else {
			return "A";
		}
	}

	openClarifications = () => {
		let openClarifications = this.state.openClarifications;
		openClarifications = openClarifications ? false : true;
		this.setState({ openClarifications });
	};

	toggleIcon = () => (
		<i className="cl-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				className={this.state.openClarifications ? "a-180" : null}
				style={{ height: "7px", width: "15px" }}
			>
				<path
					id="Path_50"
					data-name="Path 50"
					className="cls-1"
					d="M11.361,1.4,6.38,5.9,1.4,1.4"
				/>
			</svg>
		</i>
	);

	renderClarifications = () => {
		return this.props.associatedBlock.clarifications.map(
			(clarification, i) => {
				return (
					<div className="demand" key={"demand-" + i}>
						<div className="title-container">
							<span
								className="title"
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(
										clarification.text
									),
								}}
							/>
						</div>

						<div className="steps-container">
							{clarification.cells.map((cell, i) => {
								return (
									<div
										className="step"
										key={"step-" + i}
										dangerouslySetInnerHTML={{
											__html: DOMPurify.sanitize(
												cell.text
											),
										}}
									></div>
								);
							})}
						</div>
						<div style={{ clear: "both" }} />
					</div>
				);
			}
		);
	};

	descriptionStepSelected = (step) => {
		if (this.props.assessments != null) {
			var assessment = this.props.assessments.find(
				(t) => t.studentId == this.props.currentUser.userId
			);

			if (assessment != null) {
				var detail = null;
				if (
					assessment.assessmentDetails != null &&
					assessment.assessmentDetails.length > 0
				) {
					detail = assessment.assessmentDetails.find(
						(t) =>
							t.rowId == this.props.block.id &&
							t.cellId == step.id
					);
				}

				if (detail != null) {
					return true;
				}
			}
		}
		return false;
	};

	render() {
		const { associatedBlock } = this.props;

		const colors = new ColorScale(this.props.block.cells.length).get();
		var highestGrade = "";
		if (this.props.assessments != null) {
			var assessment = this.props.assessments.find(
				(t) => t.studentId == this.props.currentUser.userId
			);

			if (assessment != null) {
				var detail = null;
				if (assessment.assessmentDetails != null && assessment.assessmentDetails.length > 0) {
					detail = assessment.assessmentDetails.find(
						(t) => t.rowId == this.props.block.id
					);
				} else if (assessment.studentAssessmentDetails != null && assessment.studentAssessmentDetails.length > 0) {
					detail = assessment.studentAssessmentDetails.find(
						(t) => t.rowId == this.props.block.id
					);
				}

				if (detail != null) {
					highestGrade = detail.cellId;
				}
			}
		}

		var foundGrade = false;

		let clarificatonTrigger = this.props.translate('Clarifications');
		if (associatedBlock.clarifications.length > 0) {
			clarificatonTrigger += ' (' + associatedBlock.clarifications.length + ')';
		}

		return (
			<div
				className={
					this.props.sentenceNr != null
						? this.props.sentenceNr == 1
							? `matrix-block pragraph`
							: `matrix-block`
						: `matrix-block`
				}
				key={this.props.block.id}
			>
				<div className="matrix-block-container">
					<div className="matrix-block-title">
						{this.props.index ? (
							<span>{this.props.index}&nbsp;</span>
						) : null}

						{this.props.block != null && this.props.block.text != null ?
							<span
								dangerouslySetInnerHTML={{
									__html: this.props.block.text.replace(
										new RegExp("\r\n", "g"),
										"<br />"
									),
								}}
							/>
							: <span />}
					</div>

					<div className="matrix-block-steps">
						{this.props.block.cells.map((step, index) => {
							var grade = this.getGrade(index);
							if (this.props.type == "multiple") {
								if (highestGrade != "") {
									if (grade == highestGrade && foundGrade == false) {
										foundGrade = true;
										step.selected = true;
									} else if (highestGrade == step.id && foundGrade == false) {
										foundGrade = true;
										step.selected = true;
									} else if (grade != highestGrade && foundGrade == false && grade != "F") {
										step.selected = true;
									}
								} else {
									step.selected = false;
								}
							} else if (this.props.type == "single") {
								step.selected = this.descriptionStepSelected(step);
							} else {
								step.selected = false;
							}

							return (
								<div
									className={
										step.selected
											? `matrix-block-step selected`
											: `matrix-block-step`
									}
									style={{ "--level-color": colors[index] }}
									key={step.id + '-' + index}
								>
									<div className="matrix-block-step-description">
										{step.text}
									</div>
								</div>
							);
						}, this)}
					</div>
				</div>

				{associatedBlock.clarifications != null && associatedBlock.clarifications.length > 0 ?
					<div className="matrix-block-clarifications">
						<Collapsible trigger={clarificatonTrigger}>
							<div className="content">
								{this.renderClarifications()}
							</div>
						</Collapsible>
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(MatrixDisplayBlock);
