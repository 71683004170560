import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { setActiveSchool } from 'actions/user';

import { Icon, Menu, translate } from '@haldor/ui';
import { languages, getLanguage, setLanguage } from './LanguageManager';
import './index.scss';
import { isMicrosoftTeams } from 'helpers/teams';

class AppMenu extends Component {
	switchSchool = (school, userId) => {
		localStorage.setItem('hal.school' + userId, school);

		this.props.setActiveSchool(school);
		this.props.history.push('/switch');
	};

	getBadgeForSchool = (school) => {
		if (this.props.userUnread == null) {
			return null;
		}

		return this.props.userUnread.reduce((value, item) => {
			if ((item.schoolId == 0 || item.schoolId == school.id) && item.type == 'CONVERSATION') {
				if (value == null)
					value = 0;

				return value += item.unread;
			}

			return value;
		}, null);
	};

	getBadgeForItem = (item) => {
		if (this.props.userUnread == null) {
			return null;
		}

		const { activeSchool } = this.props;

		if (item.url == 'select-school') {
			return this.props.userUnread.reduce((value, item) => {
				if (item.schoolId == activeSchool || item.type != 'CONVERSATION') {
					return value;
				}

				if (value == null)
					value = 0;


				return value += item.unread;
			}, null);
		}

		if (item.url == '/conversations') {
			return this.props.userUnread.reduce((value, item) => {
				if ((item.schoolId == 0 || item.schoolId == activeSchool) && item.type == 'CONVERSATION') {
					if (value == null) {
						value = 0;
					}

					return value += item.unread;
				}

				return value;
			}, null);
		}

		if (item.url == '/notifications') {
			return this.props.userUnread.reduce((value, item) => {
				if ((item.schoolId == 0 || item.schoolId == activeSchool) && item.type == 'NOT') {
					if (value == null) {
						value = 0;
					}

					return value += item.unread;
				}

				return value;
			}, null);
		}

		return null;
	};

	getUserMenu = () => {
		let userMenu = [];
		userMenu.push({
			onClick: () => this.props.history.push("/logout"),
			onKeyDown: (event) => { event.key == "Enter" ? event.target.click() : null },
			label: (<div tabIndex={0} style={{ textDecoration: "none", color: "inherit" }} >{this.props.translate('Sign out')}</div>),
		});

		return userMenu;
	};

	getLanguageMenu = () => {
		let languageMenu = [];

		languages().forEach((language) => {
			languageMenu.push({
				onKeyDown: (event) => { event.key == "Enter" ? event.target.click() : null },
				onClick: () => setLanguage(language.lcid, this.props.user, this.props.history),
				label: this.props.user.lcid.toLowerCase() == language.lcid.toLowerCase() ?
					(<div style={{ fontWeight: "bold", display: "flex", justifyContent: "space-between" }}>
						<span tabIndex={0} style={{ display: "flex", alignItems: "center" }}><img style={{ height: "14px", width: "20px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>
						<Icon name="check" style={{marginLeft: "1rem"}}/>
					</div>)
					: (<span tabIndex={0} style={{ display: "flex", alignItems: "center" }}><img style={{ height: "14px", width: "20px", objectFit: "cover", marginRight: "10px", borderRadius: "2px" }} alt={language.label} src={language.svg_src} /> {language.label}</span>),

			});
		});

		return languageMenu;
	};

	render() {
		const { activeSchool, user, translate } = this.props;

		let userMenu = this.getUserMenu();
		let languageMenu = this.getLanguageMenu();
		let displayActiveLanguage = getLanguage();
		let userSchools = [...this.props.user.schools];
		userSchools = userSchools.sort((a, b) => {
			return a.title.localeCompare(b.title);
		});

		let categories = [];
		let schools = [];
		let schoolName = '';

		userSchools.forEach((school) => {
			const badge = this.getBadgeForSchool(school) != null ? (
				<span
					tabIndex={0}
					className="badge"
					style={{
						display: 'inline-flex',
						justifyContent: 'center',
						transform: 'none',
						borderRadius: '100%',
						position: 'static',
						marginRight: '.4rem'
					}}>
					{this.getBadgeForSchool(school)}
				</span>
			) : null;

			const title = (
				<span tabIndex={0}>
					{school.id != activeSchool ? badge : null}
					{school.id == activeSchool ?
						<Icon name="check" style={{ position: 'relative', top: 2, marginRight: '.4rem' }} />
						: null}
					{school.title}
				</span>
			);

			if (school.id == activeSchool) {
				schoolName = school.title;
			}

			schools.push({
				label: title,
				onClick: () => this.switchSchool(school.id, user.id),
				onKeyDown: (event) => { event.key == "Enter" ? event.target.click() : null }
			});
		});

		if (this.props.items != null) {
			this.props.items.forEach((item) => {
				if (item.parentId != null && item.parentId != '') {
					if (categories.indexOf(item.parentId) == -1) {
						categories.push(item.parentId);
					}
				}
			});
		}

		let items = [];
		if (this.props.items != null) {
			items = [...this.props.items];
			items.sort((a, b) => a.index - b.index);
		}

		return (
			<Menu>
				<div
					className='home_school'
					style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}
				>
					<Menu.Item
						label={schoolName}
						Link={Link}
						to={'/'}
						icon={<Icon name='House' />}
						exact
					/>

					<Menu.Item tabIndex={0} onKeyDown={(event) => event.key == "Enter" ? event.target.click() : null}
						label={translate('Select-school')}
						icon={<img className='chevron-down' src="/dist/svg/chevron-down.svg" alt="chevron down icon" />}
						items={schools}
						badge={this.getBadgeForItem({
							url: 'select-school',
						})}
					/>
				</div>

				{this.props.items != null ?
					this.props.items.map((item, index) => {
						if (item.parentId != null && item.parentId != '') {
							return null;
						}

						if (item.type == 'EXTERNAL') {
							return null;
						}
						if (item.text === 'NAV_HOME') {
							return null;
						}

						return (
							<Menu.Item
								key={'item-' + index}
								label={translate(item.text.toLowerCase())}
								Link={Link}
								to={item.url}
								icon={<Icon name={item.iconId} />}
								badge={this.getBadgeForItem(item)}
								exact
							/>
						);
					}) : null}

				{categories.map((category) => {
					return (
						<Menu.Divider
							key={category}
							label={this.props.translate(category.toLowerCase())}
						>
							{items.map((item, index) => {
								if (item.parentId != null && item.parentId == category) {
									return (
										<Menu.Item
											key={'item-' + index}
											label={translate(item.text.toLowerCase())}
											Link={Link}
											to={item.url}
											badge={this.getBadgeForItem(item)}
											icon={<Icon name={item.iconId} />}
											exact
										/>
									);
								}
							})}
						</Menu.Divider>
					);
				})}

				{this.props.items != null ?
					this.props.items.map((item, index) => {
						if (item.parentId != null && item.parentId != '') {
							return null;
						}

						if (item.type != 'EXTERNAL') {
							return null;
						}

						let label = item.text;
						if (label.indexOf('nav_') > -1) {
							label = translate(item.text.toLowerCase());
						}

						return (
							<Menu.Item
								key={'item-' + index}
								label={label}
								to={item.url}
								icon={<Icon name={item.iconId} />}
								target='_blank'
								exact
							/>
						);
					}) : null}

				{!isMicrosoftTeams() ? (
					<div className='select-userinfo'>
						<Menu.Item tabIndex={0} onKeyDown={(event) => event.key == "Enter" ? event.target.click() : null}
							label={this.props.user.firstName + ' ' + this.props.user.lastName}
							icon={<img className='person' src="/dist/svg/person.svg" alt='person' />}
							items={userMenu}
						/>
					</div>
				) : null}
				{displayActiveLanguage != null ?
					<div className='select-language'>
						<Menu.Item tabIndex={0} onKeyDown={(event) => event.key == "Enter" ? event.target.click() : null}
							label={displayActiveLanguage.label}
							icon={<img className='flag' src={displayActiveLanguage.svg_src} alt={displayActiveLanguage.label} />}
							items={languageMenu}
						/>
					</div>
					: null}
			</Menu>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		user: state.user.currentUser,
		activeSchool: state.user.activeSchool,
		location: document.location.pathname,
		items: state.Menu.items,
		userUnread: state.Notifications.userUnread,
	};
}

export default withRouter(connect(mapStateToProps, {
	setActiveSchool,
})(AppMenu));
