import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getUsersWithoutMentorGroups } from 'actions/user';

import { DataList, Spinner } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { translate } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

class UsersWithoutMentorGroups extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	componentDidMount = async () => {
		await this.props.getUsersWithoutMentorGroups();
		this.setState({ loading: false });
	}

	renderStudent = (student) => {
		return <RowItem key={student.userId} target={`/user/${student.userId}/progress`}>
			<RowCell title={this.props.translate('Name')}>
				<DisplayName
				firstName={student.firstName}
				lastName={student.lastName}
				email={student.email}
				data={this.props.usersWithoutMentorGroups}
				/>
			</RowCell>
		</RowItem>
	}

	render() {
		if (this.state.loading) {
			return <div>
				<Spinner center />
			</div>
		}

		return <DataList
			data={this.props.usersWithoutMentorGroups.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))}
			renderRow={this.renderStudent}
			emptyData={() => (
				<div>
					<h3 style={{ textAlign: 'center' }}>
						{this.props.translate('no-results')}
					</h3>
				</div>
			)}
		/>;
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		usersWithoutMentorGroups: state.user.usersWithoutMentorGroups,
	}
}

export default connect(mapStateToProps, {
	getUsersWithoutMentorGroups,
})(UsersWithoutMentorGroups);