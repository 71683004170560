import { KNOWLEDGE, CLEAR_KNOWLEDGE } from '../actions/knowledge';


const INITIAL_STATE = {
  active_section_knowledge: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case KNOWLEDGE:
      return { ...state, active_section_knowledge: action.payload };
    case CLEAR_KNOWLEDGE:
      return { ...state, active_section_knowledge: null };
    default:
      return state;
  }
}
