import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import { withRouter } from 'react-router-dom';

import { getSection } from 'actions/sections';
import { setPageTitle, setPageActions } from 'actions/header';
import { createBaseGroup, clearActiveBaseGroup } from 'actions/basegroups';

import { Spinner } from 'UI';
import { Block } from '@haldor/ui';

import SectionBaseGroups from 'containers/Section/Functionality/SectionBaseGroups';
import BaseGroupForm from 'containers/BaseGroups/Form/BaseGroupForm';
import Modal from 'containers/Modals/Modal';

class SectionBaseGroupPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			createModal: false,
		};
	}

	componentDidMount = () => {
		const { groupId } = this.props.match.params;

		if (this.props.group == null) {
			if (groupId != null) {
				this.props.getSection(groupId)
					.then(() => {
						this.props.setPageTitle(this.props.group.title);
						this.setState({ loading: false });
					});
			}
		} else {
			this.setState({ loading: false });
			this.props.setPageTitle(this.props.group.title);
		}

		this.props.setPageActions([
			{
				value: this.props.translate('create-basegroup'),
				type: 'button',
				onClick: this.toggleCreateModal,
				icon: 'plus',
			},
		]);
	}

	componentWillUnmount = () => {
		this.props.clearActiveBaseGroup();
	}

	onCreateBaseGroup = async (values) => {
		const { translate } = this.props;

		await this.props.createBaseGroup(values);
		this.setState({ createModal: false });
	}

	toggleCreateModal = () => {
		this.setState({ createModal: !this.state.createModal });
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		const { group, translate } = this.props;

		return (
			<div>
				<Modal isOpen={this.state.createModal} title={translate('create-basegroup')} type="small" onClose={this.toggleCreateModal}>
					<div className="form">
						<div className="form-row">
							<BaseGroupForm onSubmit={this.onCreateBaseGroup} section={group} />
						</div>
					</div>
				</Modal>

				<div>
					<SectionBaseGroups />
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		group: state.sections.activeSection,
		translate: translate(state.Languages.translations),
	}
}

export default withRouter(connect(mapStateToProps, {
	getSection,
	setPageTitle,
	setPageActions,
	createBaseGroup,
	clearActiveBaseGroup,
})(SectionBaseGroupPage));