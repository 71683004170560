import api from 'lib/api';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS';
export const GET_USER_UNREAD_NOTIFICATIONS = 'NOTIFICATIONS_GET_USER_UNREAD_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS_FROM_STORE = 'CLEAR_NOTIFICATIONS_FROM_STORE';
export const MARK_NOTIFICATION_AS_READ = 'NOTIFICATIONS_MARK_NOTIFICATION_AS_READ';

export const getNotifications = (lcid) => ({
	type: GET_NOTIFICATIONS,
	payload: new Promise((resolve) => {
		api.get(`notifications/lcid/${lcid}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getNotificationSettings = () => ({
	type: GET_NOTIFICATION_SETTINGS,
	payload: new Promise((resolve) => {
		api.get(`notifications/notificationSettings`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearNotificationsFromStore = () => ({
	type: CLEAR_NOTIFICATIONS_FROM_STORE,
	payload: []
});

export const markNotificationAsRead = (notificationId) => ({
	type: MARK_NOTIFICATION_AS_READ,
	payload: new Promise(resolve => {
		api.post(`notifications/${notificationId}/read`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserUnreadNotifications = () => ({
	type: GET_USER_UNREAD_NOTIFICATIONS,
	payload: new Promise((resolve) => {
		api.get(`users/me/notifications/unread`).then((response) => {
			resolve(response.data);
		});
	}),
});
