import api from 'lib/api';

export const GET_SCHOOL_TYPES = 'GET_SCHOOL_TYPES';

export const getSchoolTypes = () => ({
	type: GET_SCHOOL_TYPES,
	payload: new Promise((resolve) => {
		api.get(`tenants/schooltypes`).then((response) => {
			resolve(response.data);
		});
	}),
});