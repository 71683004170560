import axios from 'axios'
import moment from 'moment';
import { http } from 'lib/msal';
import { saveWithExpiration, getWithExpiration } from 'helpers/localstorage';

export const REGISTER_LANGUAGES = 'LANGUAGES_REGISTER_LANGUAGES';
export const GET_TRANSLATIONS_FOR_LANGUAGE = 'LANGUAGES_GET_TRANSLATIONS_FOR_LANGUAGE';
export const LOAD_TRANSLATIONS_FILE = 'LANGUAGES_LOAD_TRANSLATIONS_FILE';

export const registerLanguages = () => ({
	type: REGISTER_LANGUAGES,
	payload: new Promise((resolve) => {
		const languages = [
			'sv-se',
			'en-us',
			'da-dk',
			'nb-no'
		];

		resolve(languages);
	})
});

export const getTranslations = (lcid) => ({
	type: GET_TRANSLATIONS_FOR_LANGUAGE,
	payload: new Promise((resolve) => {
		if (getWithExpiration(`localizations-${lcid}`) != null) {
			resolve(JSON.parse(getWithExpiration(`localizations-${lcid}`)));
			return true;
		}

		axios.get(`${http.defaults.baseURL}localization/${lcid}?service=HALDOR_EDUCATION`).then((response) => {
			if (response.status == 200) {
				saveWithExpiration(`localizations-${lcid}`, JSON.stringify(response.data), moment().add('8', 'hours'));

				resolve(response.data);
				return;
			}

			resolve([]);
		})
			.catch(() => {
				resolve([]);
			});
	}),
});