import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import User from '_class/User';
import DateTime from '_class/DateTime';

import { setPageTitle } from 'actions/header';
import { updateForm } from 'actions/templates';
import { addError } from 'actions/index';

import {
	addMeetingInstances,
	getMeeting,
	updateMeeting,
	deleteMeetingRelationship,
	deleteMeeting,
} from 'actions/meetings';

import {
	getMeetingTimeBlocks,
	deleteMeetingTimeBlock,
} from 'actions/timeblocks';

import swal from 'sweetalert2';
import Modal from 'containers/Modals/Modal';
import PostContent from 'components/Presentation/PostContent';
import ConnectForm from 'containers/Meeting/Form/ConnectForm';

import AddMeetingInstances from '../Form/AddMeetingInstances';
import BookMeeting from '../Form/BookMeeting';
import UpdateMeeting from '../Form/UpdateMeeting';
import CreateTimeBlocks from '../../TimeBlocks/CreateTimeBlocks';
import MeetingOccassions from './MeetingOccassions';

import { Spinner, Person } from 'UI';
import { Block, Flex, Icon, Button, Tabs, Tab } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

class Meeting extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			modal: false,
			updateModal: false,
			connectForm: false,
			activeForm: null,
			status: null,
			timeBlocksModal: false,
			lengthValue: 0,
			restLengthValue: 0,
			bookingInstance: null,
			bookingModal: false
		};
	}


	/* Lifecycle methods */
	componentDidMount = () => {
		if (this.props.meeting == null || (this.props.meeting != null && this.props.meeting.id != this.props.match.params.id)) {
			this.props.getMeeting(this.props.match.params.id).then(() => {
				this.props.setPageTitle(this.props.meeting.title);
				this.setState({
					lengthValue: this.props.meeting.length ? this.props.meeting.length : '',
					restLengthValue: this.props.meeting.restLength ? this.props.meeting.restLength : '',
					loading: false
				});
			}).catch((err) => {
				console.log(err);
				this.setState({ notFound: true });
			});

			this.props.getMeetingTimeBlocks(this.props.match.params.id);
		} else {
			this.props.setPageTitle(this.props.meeting.title);
			this.setState({ loading: false });

			this.props.getMeeting(this.props.match.params.id);
		}
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	toggleUpdateModal = () => {
		this.setState({ updateModal: !this.state.updateModal });
	}

	onAddMeetingInstances = (values) => {
		return new Promise(async (resolve) => {
			values.instances.forEach((instance) => { instance.meetingId = this.props.match.params.id });

			await this.props.addMeetingInstances(this.props.match.params.id, values.instances)
			this.setState({ loading: true });
			await this.props.getMeeting(this.props.match.params.id)
			this.setState({ loading: false });
			this.toggleModal();
		});
	}

	onUpdateMeeting = (values) => {
		return new Promise(async (resolve) => {
			values.id = this.props.match.params.id;
			values.instances = this.props.meeting.instances;

			this.setState({ loading: true });

			await this.props.updateMeeting(values)
			await this.props.getMeeting(this.props.match.params.id);

			this.setState({ loading: false });
			this.toggleUpdateModal();
		});
	}

	/* Events */
	onLockMeeting = async () => {
		let values = this.props.meeting;
		values.status = 'COMPLETED';

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('meeting-lock-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({});

				await this.props.updateMeeting(values);
				this.props.getMeeting(this.props.match.params.id);
				this.props.addError(this.props.translate('changes-saved'), 'info');
			}
		});
	}

	onUnlockMeeting = async () => {
		let values = this.props.meeting;
		values.status = 'ACTIVE';

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('meeting-unlock-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({});

				await this.props.updateMeeting(values);
				this.props.getMeeting(this.props.match.params.id);
				this.props.addError(this.props.translate('changes-saved'), 'info');
			}
		});
	}

	onPublishMeeting = async () => {
		let values = this.props.meeting;
		values.status = 'ACTIVE';

		this.setState({});

		await this.props.updateMeeting(values);
		this.props.getMeeting(this.props.match.params.id);
		this.props.addError(this.props.translate('changes-saved'), 'info');
	}

	toggleConnectForm = (forceClose = false) => {
		if (forceClose == true) {
			this.setState({ connectForm: false, activeForm: null });
		} else {
			this.setState({ connectForm: !this.state.connectForm, activeForm: null });
		}
	}

	toggleTimeBlocksModal = (timeblock) => {
		this.setState({ timeBlocksModal: !this.state.timeBlocksModal, editTimeblock: timeblock });
	}

	onConnectSubmit = (submits) => {
		return new Promise(async (resolve) => {
			let tasks = [];
			submits.forEach((form) => {
				if (form.id == null) {
					// Add form to meeting
					let meeting = { ...this.props.meeting };

					if (meeting.meetingForms == null) {
						meeting.meetingForms = [];
					}

					meeting.meetingForms.push(form);

					let task = this.props.updateMeeting(meeting);
					tasks.push(task);
				}
			})

			Promise.all(tasks).then(() => {
				this.toggleConnectForm(true);
				resolve(1);
			});
		});
	}

	onFormClick = (form) => {
		this.setState({ connectForm: true, activeForm: form.reference });
	}

	renderAudience(audience) {
		if (audience == "STUDENT") {
			return <span>{this.props.translate('assigned-to-students')}</span>
		} else if (audience == "GUARDIAN") {
			return <span>{this.props.translate('assigned-to-guardians')}</span>
		} else if (audience == "SELF") {
			return <span>{this.props.translate('assigned-to-me')}</span>
		} else if (audience == "FOLLOWUP") {
			return <span>{this.props.translate('follow-up')}</span>
		} else {
			return <span></span>
		}
	}

	removeTimeBlock = (timeblock) => {
		this.props.deleteMeetingTimeBlock(this.props.meeting.id, timeblock.id);
	}

	getLengthDurations = () => {
		let times = [];
		let increment = 15;
		for (var i = 0; i < 8; i++) {
			if (i == 0) {
				times.push({ text: increment + ' ' + this.props.translate('minutes'), value: i + increment });
			} else {
				times.push({ text: ((i + 1) * increment) + ' ' + this.props.translate('minutes'), value: ((i + 1) * increment) });
			}
		}
		return times;
	}

	getRestLength = () => {
		let times = [];
		let increment = 5;
		for (var i = 0; i < 13; i++) {
			if (i == 0) {
				times.push({ text: i + ' ' + this.props.translate('minutes'), value: i });
			} else {
				times.push({ text: (i * increment) + ' ' + this.props.translate('minutes'), value: (i * increment) });
			}
		}
		return times;
	}

	setLengthDuration = async (event) => {
		let meeting = this.props.meeting;
		meeting.length = event.target.value;
		await this.props.updateMeeting(meeting);

		this.props.addError(this.props.translate('changes-saved'), 'info');
	}

	setRestDuration = async (event) => {
		let meeting = this.props.meeting;
		meeting.restLength = event.target.value;
		await this.props.updateMeeting(meeting);

		this.props.addError(this.props.translate('changes-saved'), 'info');
	}

	toggleBooking = (instance, reload = false) => {
		this.setState({
			bookingModal: !this.state.bookingModal,
			bookingInstance: instance
		});

		if (reload) {
			this.props.getMeeting(this.props.meeting.id).then(() => {
				this.props.setPageTitle(this.props.meeting.title);
				this.setState({
					lengthValue: this.props.meeting.length ? this.props.meeting.length : '',
					restLengthValue: this.props.meeting.restLength ? this.props.meeting.restLength : '',
					loading: false
				});
			}).catch((err) => {
				console.log(err);
				this.setState({ notFound: true });
			});
		}
	}

	renderMeetingProgress = (meeting) => {
		var width = 0;
		if (meeting.totalMeetings != 0 && meeting.meetingsCompleted != 0) {
			width = (meeting.meetingsCompleted / meeting.totalMeetings) * 100;
		}

		return (
			meeting.totalMeetings != null && meeting.meetingsCompleted != null ?
				<div className="size-14 color--meta">
					<Flex>
						<div style={{ flex: 1 }}>{this.props.translate('completed-meeting')}</div>

						<div style={{ flex: 1, textAlign: 'right' }}>
							{meeting.meetingsCompleted} / {meeting.totalMeetings}
						</div>
					</Flex>

					<div className="meeting-progress-bar">
						<div className="indicator" style={{ width: `${width}%` }} />
					</div>
				</div>
				: null
		)
	}

	renderMeetingInstance = (instance) => {
		const { meeting } = this.props;

		return (
			<Link
				className="meeting-participant"
				style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}
				to={`/meeting/${meeting.id}/instance/${instance.id}`}
				key={instance.id}
			>
				<div style={{ display: "flex", flexDirection: "row", alignItems: "center", color: "#0f0f0f", fontWeight: "500", fontSize: ".85rem", width: "100%" }}>
					<DisplayName
						displayAvatar={true}
						firstName={instance.student.firstName}
						lastName={instance.student.lastName}
						email={instance.student.email}
						showEmail={true}
					/>

				</div>

				{instance.date != null ?
					<span className="color--meta size-12" style={{ marginLeft: '.5rem', width: "100%" }}>
						{new DateTime(instance.date).getLongDateWithTime()} - {new DateTime(instance.endDate).format('HH:mm')} 
					</span>
					: null}

				{instance.status == "COMPLETED" ?
					<span className="new-badge" style={{ marginLeft: '.5rem' }}>
						{this.props.translate('complete-meeting')}
					</span>
					: null}
			</Link>
		)
	}

	getForms() {
		let forms = [];

		if (this.props.meeting.relationships != null) {
			this.props.meeting.relationships.forEach(form => {
				if (form.referenceType == 'FORM') {
					this.props.meeting.instances.forEach((instance) => {
						instance.relationships.forEach((relationship) => {
							if (relationship.referenceType == "FORMINSTANCE") {
								if (relationship.reference.formID == form.referenceID) {
									form.answeredBy = form.answeredBy != null ? form.answeredBy++ : 1;
								}
							}
						})
					})
					forms.push(form);
				}
			});
		}

		return forms;
	}

	deleteForm = (form) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true });
				this.props.deleteMeetingRelationship(this.props.meeting.id, form.id).then(() => {
					this.props.getMeeting(this.props.match.params.id).then(() => {
						this.setState({ loading: false });
					});
					this.props.addError(this.props.translate('changes-saved'), 'info');
				});
			}
		});
	}

	onRemoveMeeting = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,

			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true });
				this.props.deleteMeeting(this.props.meeting.id).then(() => {
					this.props.history.push('/meetings');
				});
			}
		});
	}

	render() {
		if (this.state.loading && !this.state.notFound) {
			return <Spinner center />
		} else if (this.state.notFound) {
			return <div className="single-task"><h3>{this.props.translate('not-found')}</h3></div>
		}

		const user = new User(this.props.user);
		const { meeting, translate } = this.props;
		let forms = this.getForms();

		return (
			<div className="single-task meeting">
				<Modal isOpen={this.state.connectForm} onClose={this.toggleConnectForm} title={translate('add-form')}>
					<ConnectForm
						onSubmit={this.onConnectSubmit}
						initialValues={this.state.activeForm}
						meetingInstances={meeting.instances}
						onClose={this.toggleConnectForm}
					/>
				</Modal>

				<Modal isOpen={this.state.modal} onClose={() => this.toggleModal()} title={translate('meetings')}>
					<AddMeetingInstances
						onSubmit={this.onAddMeetingInstances}
						onAbort={this.toggleModal}
						instances={meeting.instances}
					/>
				</Modal>

				<Modal isOpen={this.state.updateModal} onClose={() => this.toggleUpdateModal()} title={translate('meetings')}>
					<UpdateMeeting
						onSubmit={this.onUpdateMeeting}
						onAbort={this.updateModal}
					/>
				</Modal>

				<Modal isOpen={this.state.timeBlocksModal} onClose={() => this.toggleTimeBlocksModal()} title={this.props.translate('meeting-time')}>
					<CreateTimeBlocks
						onClose={this.toggleTimeBlocksModal}
						reference={meeting}
						timeblock={this.state.editTimeblock}
					/>
				</Modal>

				<Modal type="small" isOpen={this.state.bookingModal} onClose={(instance, reload) => this.toggleBooking(instance, reload)} title={this.props.translate('book-meeting-time')}>
					<BookMeeting
						onClose={(instance, reload) => { this.toggleBooking(instance, reload) }}
						meeting={this.props.meeting}
						instance={this.state.bookingInstance}
					/>
				</Modal>

				<div className="single-section form left-side" style={{ paddingTop: 0 }}>
					<Tabs>
						<Tab title={translate('meeting-information')} route="info">
							<Block>
								{meeting != null && meeting.status == "DRAFT" ? <span className="new-badge" style={{ marginLeft: '15px' }}> {translate('Draft')}</span> : null}

								<span className="title">{this.props.translate('Information-to-participants')}</span>

								<div className="size-14">
									<PostContent>{meeting.description}</PostContent>
								</div>
							</Block>

							{!user.isStudent() ?
								<div style={{ marginTop: '2rem' }} />
								: null}

							{!user.isStudent() ?
								<Block>
									<div>
										<span className="title">{this.props.translate('students')}</span>

										<div>
											{this.renderMeetingProgress(meeting)}
										</div>
									</div>

									<div style={{ display: 'flex', flexDirection: 'column' }}>
										{this.props.meeting.instances
										.sort((a, b) => (a.student.lastName || "").localeCompare(b.student.lastName || ""))
										.map((instance) => {
											return this.renderMeetingInstance(instance);
										})}
									</div>

									{user.data.id == meeting.creator && meeting.status != 'COMPLETED' ?
										<div style={{ marginTop: '2rem' }}>
											<div>
												<Button onClick={() => this.toggleModal()} type="secondary" style={{ marginLeft: 0, marginTop: 5 }}>
													<Icon name="Plus" /> {this.props.translate('add-meeting')}
												</Button>
											</div>
										</div>
										: null}
								</Block>
								: null}

							{!user.isStudent() ?
								<Block>
									<div className="forms">
										<span className="title" style={{ flex: 1 }}>{translate('forms')}</span>

										<div style={{ marginTop: '1.25rem' }} />

										{forms != null ?
											forms.map(form => {
												return <div className="form" key={'form-' + form.referenceID}>
													<div className="form-title">
														<div>
															{form.reference.title}
														</div>

														{form.answeredBy == null && user.data.id == meeting.creator ?
															<div onClick={() => { this.deleteForm(form) }}>
																<Icon name="Bin" />
															</div>
															: null}
													</div>

													<div className="size-14">
														{this.renderAudience(form.targetedAudience)}
													</div>
												</div>
											})
											: null}

										{forms != null && forms.length == 0 ?
											<div style={{ marginBottom: '1.25rem' }}>
												{this.props.translate('no-form')}
											</div>
											: null}

										{!user.isStudent() && user.data.id == meeting.creator && meeting.status != 'COMPLETED' ?
											<Button type="secondary" onClick={this.toggleConnectForm}>
												<Icon name="Plus" /> {this.props.translate('add-form')}
											</Button>
											: null}
									</div>
								</Block>
								: null}
						</Tab>

						{!user.isStudent() && user.data.id == meeting.creator ?
							<Tab title={translate('booking-management')} route="occasions">
								<Block>
									<MeetingOccassions
										meeting={this.props.meeting}
										location={this.props.location}
									/>
								</Block>
							</Tab>
							: null}
					</Tabs>
				</div>

				{!user.isStudent() && user.data.id == meeting.creator ?
					<div className="single-actions right-side" style={{ paddingTop: 0 }}>
						<div className="action-section">
							<h3>{this.props.translate('tools')}</h3>

							{meeting.status == 'COMPLETED' && user.isMentor() ?
								<div>
									<Button onClick={this.onUnlockMeeting} type="secondary">
										<Icon name="Lock" /> {this.props.translate('unlock')}
									</Button>
								</div>
								: null}

							{meeting.status != 'COMPLETED' ?
								<div>
									<Button onClick={() => { this.toggleUpdateModal() }} type="secondary">
										<Icon name="Pen" /> {this.props.translate('Edit')}
									</Button>
								</div>
								: null}

							{meeting.status == 'DRAFT' ?
								<div>
									<Button type="secondary" onClick={this.onRemoveMeeting} style={{ marginTop: 5 }}>
										<Icon name="Bin" /> {this.props.translate('Delete')}
									</Button>
								</div>
								: null}

							{meeting.status == 'DRAFT' ?
								<div>
									<Button onClick={this.onPublishMeeting} style={{ marginTop: 5 }} className="c--button primary publishButton">
										{this.props.translate('publish')}
									</Button>
								</div>
								: null}

							{meeting.status == 'ACTIVE' ?
								<div>
									<Button onClick={this.onLockMeeting} style={{ marginLeft: 0, marginTop: 5 }} type="secondary">
										{this.props.translate('lock-and-archive')}
									</Button>
								</div>
								: null}
						</div>
					</div>
					: null}

				<div className="clearfix" />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		meeting: state.Meetings.meeting,
		user: state.user.currentUser,
		meetingTimeBlocks: state.TimeBlocks.meetingTimeBlocks,
		languages: state.Languages.languages,
	};
}

export default withRouter(connect(mapStateToProps, {
	getMeeting,
	setPageTitle,
	updateMeeting,
	addMeetingInstances,
	updateForm,
	addError,
	getMeetingTimeBlocks,
	deleteMeetingTimeBlock,
	deleteMeetingRelationship,
	deleteMeeting,
})(Meeting));