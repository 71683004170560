import React, { Component } from 'react';
import { connect } from 'react-redux';

import User from '_class/User';

import {
	createMeeting,
	updateMeeting,
	clearMeeting,
	deleteMeetingRelationship,
	deleteMeeting,
	getMeeting,
	saveWizardMeeting,
	clearWizardMeeting,
} from 'actions/meetings';

import { getMeetingTimeBlocks } from 'actions/timeblocks';
import { setPageTitle } from 'actions/header';

import { Icon, Button, translate } from '@haldor/ui';
import Modal from 'containers/Modals/Modal';
import swal from 'sweetalert2';

import MeetingOccassions from 'containers/Meeting/Display/MeetingOccassions';
import CreateMeeting from 'containers/Meeting/Form/CreateMeeting';
import UpdateMeeting from 'containers/Meeting/Form/UpdateMeeting';
import ConnectForm from 'containers/Meeting/Form/ConnectForm';

import WizardStep from 'containers/Wizard/WizardStep';
import Wizard from 'containers/Wizard/Wizard';
import WizardQuestion from 'containers/Wizard/WizardQuestion';

class CreateMeetingWizard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 0,
			loading: false,
			connectForm: false,
			occassionsCreated: false,
			submitting: false,
		};
	}

	componentDidMount = () => {
		const user = new User(this.props.user);
		this.props.setPageTitle(this.props.translate('new-meeting'))

		if (!user.isMentor()) {
			window.location = '/meetings';
		}

		if (this.props.meeting != null) {
			this.props.clearMeeting();
		}
	}

	componentWillUnmount = () => {
		this.props.clearMeeting();
		this.deleteMeeting();
	}

	onCreateMeetingSubmit = (meeting) => {
		this.setState({ loading: true });
		return new Promise(async resolve => {
			await this.props.createMeeting(meeting);
			this.props.saveWizardMeeting(this.props.meeting);
			resolve(1);

			this.setState({ loading: false });
			this.goToPage(this.state.currentPage + 1);
		});
	}

	onUpdateMeeting = (values) => {
		this.setState({ loading: true });
		return new Promise(async (resolve) => {
			values.id = this.props.meeting.id;
			values.instances = this.props.meeting.instances;
			await this.props.updateMeeting(values);
			this.props.saveWizardMeeting(this.props.meeting);

			resolve(1);
			this.setState({ loading: false });
			this.goToPage(this.state.currentPage + 1);
		});
	}

	publishMeeting = () => {
		return new Promise(async (resolve) => {
			if (this.props.meeting != null) {
				const values = { ...this.props.meeting };
				values.status = 'ACTIVE';

				await this.props.updateMeeting(values);
				// Clear meeting wizard data here
				resolve(1);
			}

			resolve(0);
		});
	}

	deleteMeeting = () => {
		if (this.props.meeting != null && !this.state.submitting) {
			if (this.props.meeting.status == "DRAFT") {
				this.props.deleteMeeting(this.props.meeting.id);
			}
		}
	}

	onConnectSubmit = (submits) => {
		this.setState({ loading: true });
		return new Promise(async (resolve) => {
			let tasks = [];
			submits.forEach((form) => {
				if (form.id == null) {
					// Add form to meeting
					let meeting = { ...this.props.meeting };

					if (meeting.meetingForms == null) {
						meeting.meetingForms = [];
					}

					meeting.meetingForms.push(form);

					let task = this.props.updateMeeting(meeting);
					tasks.push(task);
				}
			})

			Promise.all(tasks).then(() => {
				resolve(1);

				this.props.saveWizardMeeting(this.props.meeting);

				this.toggleConnectForm(true);
				this.setState({ loading: false });
			});
		});
	}

	goToPage = (page) => {
		if (this.props.meeting != null && this.state.loading == false) {
			this.setState({ currentPage: page });
		}
	}

	nextPage = () => {
		this.goToPage(this.state.currentPage + 1);
	}

	toggleConnectForm = (forceClose = false) => {
		if (forceClose == true) {
			this.setState({ connectForm: false, activeForm: null });
		} else {
			this.setState({ connectForm: !this.state.connectForm, activeForm: null });
		}
	}

	renderAudience(audience) {
		if (audience == "STUDENT") {
			return <span>{this.props.translate('assigned-to-students')}</span>
		} else if (audience == "GUARDIAN") {
			return <span>{this.props.translate('assigned-to-guardians')}</span>
		} else if (audience == "SELF") {
			return <span>{this.props.translate('assigned-to-me')}</span>
		} else if (audience == "FOLLOWUP") {
			return <span>{this.props.translate('follow-up')}</span>
		} else {
			return <span></span>
		}
	}

	getForms() {
		let forms = [];

		if (this.props.meeting?.relationships != null) {
			this.props.meeting.relationships.forEach(form => {
				if (form.referenceType == 'FORM') {
					this.props.meeting.instances.forEach((instance) => {
						instance.relationships.forEach((relationship) => {
							if (relationship.referenceType == "FORMINSTANCE") {
								if (relationship.reference.formID == form.referenceID) {
									form.answeredBy = form.answeredBy != null ? form.answeredBy++ : 1;
								}
							}
						})
					})

					forms.push(form);
				}
			});
		}

		return forms;
	}

	deleteForm = (form) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true });
				this.props.deleteMeetingRelationship(this.props.meeting.id, form.id).then(() => {
					this.props.getMeeting(this.props.meeting.id).then(() => {
						this.setState({ loading: false });
					});
					this.props.addError(this.props.translate('changes-saved'), 'info');
				});
			}
		});
	}

	renderForms = (targetedAudience) => {
		let forms = this.getForms();

		if (targetedAudience != null) {
			forms = forms.filter(t => targetedAudience.find(d => t.targetedAudience == d) != null);
		}

		return (
			<div className="meeting">
				<div className="forms">
					<span className="title" style={{ flex: 1 }}>
						{this.props.translate('forms')}
					</span>

					<div style={{ marginTop: '1.25rem' }} />

					{forms != null ?
						forms.map(form => {
							if (targetedAudience != null) {
								if (targetedAudience.find(t => t == form.targetedAudience) == null) {
									return null;
								}
							}

							return (
								<div className="form" key={'form-' + form.referenceID}>
									<div className="form-title">
										<div>
											{form.reference.title}
										</div>

										{form.answeredBy == null ?
											<div onClick={() => { this.deleteForm(form) }}>
												<Icon name="Bin" />
											</div>
											: null}
									</div>

									<div className="size-14">
										{this.renderAudience(form.targetedAudience)}
									</div>
								</div>
							)
						})
						: null}

					{forms != null && forms.length == 0 ?
						<div style={{ marginBottom: '1.25rem' }}>
							{this.props.translate('no-form')}
						</div>
						: null}
				</div>
			</div>
		)
	}

	renderMeetingOccasions = () => {
		const { translate } = this.props;

		return (
			<MeetingOccassions
				location={this.props.location}
				next={this.nextPage}
			/>
		);
	}

	render() {
		const { translate } = this.props;

		var forms = this.getForms();

		let isFollowUpAdded = false;
		forms.forEach(element => {
			if (element.targetedAudience === "FOLLOWUP")
				isFollowUpAdded = true;
		});

		return (
			<div>
				<Wizard currentPage={this.state.currentPage} goToPage={(i) => { this.goToPage(i) }}>
					<WizardStep name={translate('Meeting information')}>
						<span className="title" style={{ flex: 1 }}>
							{translate('Meeting information')}
						</span>

						<div style={{ marginTop: '1.25rem' }} />

						{this.props.meeting == null ?
							<CreateMeeting onSubmit={this.onCreateMeetingSubmit} />
							:
							<UpdateMeeting creating={true} onSubmit={this.onUpdateMeeting} />
						}
					</WizardStep>

					<WizardStep>
						<span className="title">{translate('Forms')}</span>

						<WizardQuestion
							text={translate('Do you want to add a form to the meeting')}
							buttons={[
								{
									onClick: () => { this.goToPage(this.state.currentPage + 1) },
									value: translate('Yes'),
									type: 'primary',
								},
								{
									onClick: () => { this.goToPage(this.state.currentPage + 2) },
									value: translate('No'),
									type: 'secondary',
								},
							]}
						/>
					</WizardStep>

					<WizardStep name={translate('Forms')}>
						<Modal isOpen={this.state.connectForm} onClose={this.toggleConnectForm} title={translate('add-form')}>
							<ConnectForm
								onSubmit={this.onConnectSubmit}
								goToPage={() => { this.goToPage(this.state.currentPage + 1) }}
								onClose={this.toggleConnectForm}
								isfollowUpAdded={isFollowUpAdded}
							/>
						</Modal>

						{this.renderForms()}

						<Button type="secondary" onClick={this.toggleConnectForm}>
							<Icon name="Plus" /> {this.props.translate('add-form')}
						</Button>

						<div className="float--right">
							<Button onClick={this.nextPage}>{translate('Next')}</Button>
						</div>
					</WizardStep>

					<WizardStep>
						<span className="title">{translate('Time slots')}</span>

						<WizardQuestion
							text={translate('Do you want to set up available time slots that guardians can book? The meeting invitation will be sent out when you publish the meeting.')}
							buttons={[
								{
									onClick: () => { this.goToPage(this.state.currentPage + 1) },
									value: translate('Yes'),
									type: 'primary',
								},
								{
									onClick: () => { this.goToPage(this.state.currentPage + 2) },
									value: translate('No'),
									type: 'secondary',
								},
							]}
						/>
					</WizardStep>

					<WizardStep name={translate('Time slots')}>
						{this.renderMeetingOccasions()}
					</WizardStep>

					<WizardStep name={translate('Review and save')}>
						<span className="title">{translate('Review and save')}</span>

						<WizardQuestion
							text={
								translate('You can now either publish') + ' "' + this.props.meeting?.title + '" ' + translate('directly or save as draft and publish later.') +
								'<br /><br />' +
								translate('As long as the meeting is not published and has no booked meetings, you can edit meeting and rest lengths. When the meeting is published you can only change and remove slot series without booked meetings.')
							}
							buttons={[
								{
									onClick: async () => {
										await this.setState({ submitting: true });
										await this.publishMeeting();
										this.props.clearWizardMeeting();
										window.location = '/meeting/' + this.props.meeting?.id;
									},
									isLoading: this.state.submitting,
									value: translate('Save and publish'),
								},
								{
									onClick: async () => {
										await this.setState({ submitting: true });
										if (this.props.meeting.id) {
											var tasks = [];
											tasks.push(this.props.getMeetingTimeBlocks(this.props.meeting.id));

											Promise.all(tasks).then(() => {
												if (this.props.meetingTimeBlocks && this.props.meetingTimeBlocks.length > 0) {
													this.props.clearWizardMeeting();
													window.location = '/meeting/' + this.props.meeting?.id + "?onboarding=false#info";
												} else {
													this.props.clearWizardMeeting();
													window.location = '/meeting/' + this.props.meeting?.id + "?onboarding=true#occasions";
												}
											});
										} else {
											this.props.clearWizardMeeting();
											window.location = '/meeting/' + this.props.meeting?.id + "?onboarding=true#occasions";
										}
									},
									value: translate('Save draft'),
									isLoading: this.state.submitting,
									type: 'secondary'
								},
							]}
						/>
					</WizardStep>
				</Wizard>
			</div>
		);
	}
}

function mapStateToProps(state) {
	let meeting = state.Meetings.meeting;
	if (meeting == null && state.Meetings.wizardMeeting != null) {
		meeting = state.Meetings.wizardMeeting;
	}

	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		meeting: meeting,
		meetingTimeBlocks: state.TimeBlocks.meetingTimeBlocks,
	}
}

export default connect(mapStateToProps, {
	createMeeting,
	setPageTitle,
	updateMeeting,
	clearMeeting,
	deleteMeetingRelationship,
	deleteMeeting,
	getMeeting,
	saveWizardMeeting,
	getMeetingTimeBlocks,
	clearWizardMeeting,
})(CreateMeetingWizard);
