import {
	clearSectionAttendanceStats,
	getSectionAttendance,
	getArchivedEducationGroups,
	getArchievedMentorGroups,
	getEducationGroups,
	getMentorGroups,
	getSection,
	updateArchived,
} from 'actions/sections';

import UserMentorSection from 'components/List/UserMentorSection';
import MentorSectionForm from 'containers/Forms/MentorSectionForm';
import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';
import SectionProgress from 'containers/Progress/Section';
import TeacherSectionProgress from 'containers/Progress/TeacherSection';
import { getUserAssessmentDates } from 'helpers/user';
import Moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { Spinner } from 'UI';
import DateTime from '_class/DateTime';
import SectionInformation from '../Functionality/SectionInformation';
import SectionPlans from '../Functionality/SectionPlans';
import SectionPosts from '../Functionality/SectionPosts';
import SectionTools from '../Functionality/SectionTools';
import { setPageTitle } from 'actions/header';
import User from '_class/User';

import DatePickers from 'containers/Progress/Partials/DatePickers';
import MyAssignmentsWrapper from 'containers/Assignments/MyAssignmentsWrapper';

class CompulsorySchoolSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			loading: false,
			end: getUserAssessmentDates().end,
			start: getUserAssessmentDates().start,
			currentEnd: getUserAssessmentDates().end,
			currentStart: getUserAssessmentDates().start,
		};
	}

	componentDidMount() {
		this.loadCurrentGroup().then(() => {
			window.addEventListener('hashchange', this.onHashChange, false);
		})
	}

	loadCurrentGroup = () => {
		const { groupId } = this.props.match.params;
		if (groupId) {
			this.setState({ loading: true });
			return this.props.getSection(groupId, true, 'members_expand').then(() => {
				this.setState({ loading: false });

				if (this.props.group) {
					this.props.setPageTitle(this.props.group.title);

					if (window.location.hash === '#attendance' && this.props.group.type === 'MENTOR_GROUP') {
						this.getAttendanceData();
					}
				}
			});
		} else {
			return Promise.resolve();
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener('hashchange', this.onHashChange)
	}

	onHashChange = () => {
		if (window.location.hash == '#attendance') {
			if (this.props.group.type == 'MENTOR_GROUP') {
				this.getAttendanceData();
			}
		}
	}

	getAttendanceData = () => {
		const start = Moment(this.state.start).format("YYYY-MM-DD");
		const end = Moment(this.state.end).format("YYYY-MM-DD");

		this.props.clearSectionAttendanceStats();
		this.props.getSectionAttendance(this.props.group.id, start, end);
	}

	isUserOwner = () => {
		const { currentUser, group } = this.props;

		if (currentUser == null || group == null) {
			return false;
		}

		let foundOwner = group.owners.find(owner => {
			return currentUser.id == owner.id;
		});

		if (foundOwner != null) {
			return true;
		}

		return false;
	}

	EditButton = () => {
		const user = new User(this.props.currentUser)
		let isOwner = this.props.group.owners.find(owner => {
			return owner.userId == this.props.currentUser.id;
		});

		if (!user.isStudent() && isOwner != null) {
			return (
				<span>
					<Button onClick={this.openModal} disabled={this.props.group.archived} type="secondary">
						{this.props.translate('Edit')}
					</Button>
				</span>
			);
		}

		return null;
	}

	CreateLessonButton = () => {
		const user = new User(this.props.currentUser);

		if (!user.isStudent()) {
			return (
				<span>
					<Button type="secondary" onClick={this.openModal} disabled={this.props.group.archived}>
						{this.props.translate('create-lesson')}
					</Button>
				</span>
			);
		}

		return null;
	}

	ArchiveButton = () => {
		const user = new User(this.props.currentUser);

		if (!user.isStudent()) {
			return (
				<span>
					<Button type="secondary" onClick={this.makeArchive}>
						{this.props.translate(this.props.group.archived ? 'Restore' : 'Archive')}
					</Button>
				</span>
			);
		}

		return null;
	}

	makeArchive = () => {
		const { groupId } = this.props.match.params;
		const { translate, group } = this.props;

		swal.fire({
			title: translate('are-you-sure'),
			html: group.archived ?
				translate('Are you sure you want to reset this group?') :
				translate('Both the group and the Microsoft Team will be archived.<br /><br />Do you want to archive this group?'),
			showCancelButton: true,
			cancelButtonText: translate('No'),
			confirmButtonText: translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.props.updateArchived(group).then(() => {
					this.setState({ loading: true });
					this.props.getSection(groupId).then(() => {
						this.setState({ loading: false });
					});

					if (group.type == 'EDUCATION_GROUP') {
						this.props.getEducationGroups();
					}

					if (group.type == 'MENTOR_GROUP') {
						this.props.getMentorGroups();
					}
				});
			}
		});
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = (reRender, skipPrompt) => {
		if (skipPrompt) {
			const { groupId } = this.props.match.params;
			if (reRender) {
				this.setState({ loading: true });
				this.props.getSection(groupId).then(() => {
					this.setState({ loading: false });
				});
			}

			this.setState({ modalIsOpen: false });
		} else {
			this.setState({ modalIsOpen: false });
		}
	}

	renderGroupContent = () => {
		const { group, currentUser } = this.props;
		const user = new User(this.props.currentUser);
		const hasAssignments = this.props.services.haldor || this.props.services.microsoft;
		return (
			<div className="single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<SectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionInformation group={group} />

				<SectionTools
					EditButton={this.EditButton}
					ArchiveButton={this.ArchiveButton}
					group={group}
				/>

				<div style={{ clear: 'both' }} />

				{this.props.services.news ?
					<div style={{ marginTop: '4rem' }}>
						<SectionPosts
							groupid={group.id}
							archived={this.props.group.archived}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{this.props.services.plans ?
					<div id="section-planning" style={{ marginTop: '4rem' }}>
						<SectionPlans
							groupid={group.id}
							archived={this.props.group.archived}
							dateRestriction={false}
							locked={group.locked}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{hasAssignments ?
					<div id="section-groups" style={{ marginTop: '4rem' }}>
						<MyAssignmentsWrapper groupId={group.id} />
					</div>
					: null}

				{group.students && group.students.length > 0 && !user.isStudent() ?
					<div id="section-pupils" className="single-section form" style={{ marginTop: '4rem' }}>
						<TeacherSectionProgress
							groupId={group.id}
						/>
					</div>
					: null}

				{user.isStudent() ?
					<div id="section-assessment" className="single-section form" style={{ marginTop: '4rem' }}>
						<SectionProgress
							groupId={group.id}
							userId={currentUser.userId}
						/>
					</div>
					: null}
			</div>
		);
	}

	onDateChange = ({ start, end }) => {
		localStorage.setItem('assessment-start-date', new DateTime(start).getDateStamp());
		localStorage.setItem('assessment-end-date', new DateTime(end).getDateStamp());

		this.setState({ start, end });
	}

	onUpdateStats = () => {
		const { start, end } = this.state;

		this.props.clearSectionAttendanceStats();
		this.props.getSectionAttendance(this.props.group.id, start, end);

		this.setState({ currentStart: start, currentEnd: end });
	}

	renderMentorGroupActions = () => {
		return (
			<div className="single-section">
				<div className="form">
					<div className="form-row">
						<div className="form-row-half static">
							<DatePickers
								startDate={this.state.start}
								endDate={this.state.end}
								onChange={this.onDateChange}
							/>

							<Button style={{ marginTop: '1rem' }} type="secondary" onClick={this.onUpdateStats}>
								{this.props.translate('update')}
							</Button>
						</div>
					</div>
				</div>

				<div className="clearfix" />

				{this.renderDateFilterText()}

				<div style={{ clear: 'both' }} />
			</div>
		);
	}

	renderDateFilterText = () => {
		if (Moment().isSame(Moment(this.state.currentEnd), 'day')) {
			return null;
		}

		const { translate } = this.props;
		const { currentStart, currentEnd } = this.state;

		return (
			<div style={{ color: 'red', marginTop: '1rem', fontWeight: 'bold' }}>
				{translate('Showing data beween')} {Moment(currentStart).format('YYYY-MM-DD')} {translate('to').toLowerCase()} {Moment(currentEnd).format('YYYY-MM-DD')}
			</div>
		)
	}

	renderMentorGroup = () => {
		const { group } = this.props;
		const user = new User(this.props.currentUser);
		const isOwnerOrAdmin = this.props.group.owners.find(owner => {
			return owner.userId == this.props.currentUser.id;
		}) || user.isAdministrator();

		return (
			<div className="single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<MentorSectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionInformation group={group} />

				<SectionTools
					EditButton={this.EditButton}
					ArchiveButton={this.ArchiveButton}
					group={group}
				/>

				<div style={{ clear: 'both' }} />

				{this.props.services.news ?
					<div className="single-section form" style={{ marginTop: '4rem' }}>
						<SectionPosts
							groupid={group.id}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{group.students && group.students.length > 0 && (user.isMentor() || isOwnerOrAdmin != null) ?
					<UserMentorSection
						section={group}
						attendance={this.props.attendance}
						renderActions={this.renderMentorGroupActions}
						start={this.state.currentStart}
						end={this.state.currentEnd}
					/>
					: null}
			</div>
		);
	}

	render() {
		const { group } = this.props;

		if (!group || this.state.loading) {
			if (!this.state.loading) {
				this.loadCurrentGroup();
			}
			return (
				<div className="single-task">
					<Spinner center />
				</div>
			);
		}

		switch (group.type) {
			case "EDUCATION_GROUP":
				return this.renderGroupContent();

			case "MENTOR_GROUP":
				return this.renderMentorGroup();

			default:
				return this.renderGroupContent();
		}
	}
}

function mapStateToProps(state) {
	return {
		group: state.sections.activeSection,
		currentUser: state.user.currentUser,
		attendance: state.sections.sectionAttendance,
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices
	};
}

export default withRouter(connect(mapStateToProps, {
	getSection,
	updateArchived,
	getEducationGroups,
	getArchivedEducationGroups,
	clearSectionAttendanceStats,
	getSectionAttendance,
	setPageTitle,
	getMentorGroups,
	getArchievedMentorGroups,
})(CompulsorySchoolSection));