import {
	GET_COURSES,
	SET_ACTIVE_COURSE,
	GET_COURSE_DETAILS,
	GET_COURSE_BY_COURSECODE,
	CLEAR_COURSES_FROM_STORE,
	GET_STANDARD_COURSES
} from "actions/courses";

import { SEARCH_COURSE, CLEAR_COURSE_SEARCH } from "actions/search";

import { updateObjectInArray } from "helpers/array";

const INITIAL_STATE = {
	courses: [],
	standardCourses: [],
	course: null,
	courseByCourseCode: null,
	courseSearchResults: []
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_COURSES:
			return { ...state, courses: action.payload };

		case GET_STANDARD_COURSES:
			return { ...state, standardCourses: action.payload };

		case SEARCH_COURSE:
			let searchResult = action.payload;

			searchResult = searchResult.sort((a, b) => {
				const course_one_title = a.title.toLowerCase();
				const course_two_title = b.title.toLowerCase();
				const course_one_typeOfSchooling = a.typeOfSchooling.toLowerCase();
				const course_two_typeOfSchooling = b.typeOfSchooling.toLowerCase();

				if (course_one_title > course_two_title) {
					return 1;
				} else if (course_one_title === course_two_title && course_one_typeOfSchooling > course_two_typeOfSchooling) {
					return 1;
				} else if (course_one_title === course_two_title && course_one_typeOfSchooling === course_two_typeOfSchooling) {
					return 0;
				} else if (course_one_title === course_two_title && course_one_typeOfSchooling < course_two_typeOfSchooling) {
					return -1;
				} else if (course_one_title < course_two_title) {
					return -1;
				}
			});

			return { ...state, courseSearchResults: searchResult };

		case CLEAR_COURSE_SEARCH:
			return { ...state, courseSearchResults: [] };

		case GET_COURSE_BY_COURSECODE:
			if (action.error) {
				return { ...state, courseByCourseCode: null };
			}
			return { ...state, courseByCourseCode: action.payload };

		case SET_ACTIVE_COURSE:
			if (typeof action.id !== "undefined" && action.id !== null) {
				let selected = state.courses.find(course => {
					return parseInt(course.id) == parseInt(course.id);
				});

				if (selected !== undefined) {
					return { ...state, course: selected };
				} else {
					return { ...state, course: INITIAL_STATE.course };
				}
			}

			return { ...state, course: INITIAL_STATE.course };

		case GET_COURSE_DETAILS:
			return {
				...state,
				course: action.payload,
				courses: updateObjectInArray(
					state.courses,
					action.payload
				)
			};

		case CLEAR_COURSES_FROM_STORE:
			return { ...state, courses: [] };

		default:
			return state;
	}
}
