import React, { useState, useEffect, useRef } from 'react';
import ReportModal from 'components/Support/ReportModal';
import "./_supportLauncher.scss";

const SupportLauncher = (props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const supportButtonRef = useRef(null);
	const supportMenuRef = useRef(null);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (
				supportButtonRef.current &&
				!supportButtonRef.current.contains(event.target) 
			) {
				setIsMenuOpen(false);	
			}
		};
		document.body.addEventListener("click", handleOutsideClick);

		return () => {
			document.body.removeEventListener("click", handleOutsideClick);
		};
	}, []);


	//shows zendeskWidget when "help center" button clicked
	const handleHelpButtonClick = () => {
		if (typeof zE !== 'undefined') {
		  zE('webWidget', 'show');
		  zE('webWidget', 'open');
		}

		setIsMenuOpen(false);		
	};

	//hides zendeskWidget on close
	zE('webWidget:on', 'close', () => {
		zE('webWidget', 'hide');
						
		setIsMenuOpen(false);	
	});
	
	const toggleModal = () => {
		setIsModalOpen((prev) => !prev);
		setIsMenuOpen(false);	
	};

	const toggleMenu = () => {
		setIsMenuOpen((prev) => !prev);	
	};

	return (
	<div id="support-launcher">
		<button type="button" id="support-button" tabIndex={0} ref={supportButtonRef} onClick={()=> toggleMenu()}>
		<img className='support-icon' src='/dist/img/help-ico.png' alt="support"/>
		</button>
		{isMenuOpen ?
		<div id="support-menu" ref={supportMenuRef}>
			<div className="arrow"></div>
			<div>
				<button type="button" tabIndex={0} id="support-help-button" onClick={() =>handleHelpButtonClick()}>{props.translate("Help center")}</button>
				<button type="button" tabIndex={0} id="support-report-button" onClick={()=> toggleModal()}>{props.translate("Report problem")}</button>
			</div>
		</div>
		: null}
		{isModalOpen && <ReportModal isOpen={isModalOpen} onClose={()=> toggleModal()}/>}
	</div>
	);
  }

export default SupportLauncher;

