import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import User from '_class/User';
import DateTime from '_class/DateTime';

import { getUserMeetings } from 'actions/meetings';

import { Spinner } from 'UI';
import { Block, translate } from '@haldor/ui';

class UserMeetings extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
		};
	};

	componentDidMount = async () => {
		const { start, end } = this.props;
		await this.props.getUserMeetings(this.props.user, start, end);
		this.setState({ loading: false });
	}

	componentWillReceiveProps = async (nextProps) => {
		const { start, end } = nextProps;

		if (nextProps.start == this.props.start && nextProps.end == this.props.end) {
			return false;
		}

		if (this.props.start != start || this.props.end != end) {
			this.setState({ loading: true });
			await this.props.getUserMeetings(this.props.user, start, end);
			this.setState({ loading: false });
		}
	}

	renderMeetingInfo = (meeting) => {
		if (meeting == null || meeting.instances == null || meeting.instances.length == 0) {
			return null;
		}

		const instance = meeting.instances[0];

		if (instance == null) {
			return null;
		}

		return (
			<div>
				{instance.date != null ?
					<span className="color--meta size-12">
						{new DateTime(instance.date).getLongDateWithTime()}
					</span>
					: null}

				{instance.status == "COMPLETED" ?
					<span className="new-badge" style={{ marginLeft: '.5rem' }}>
						{this.props.translate('complete-meeting')}
					</span>
					: null}

				<div className="clearfix" />
			</div>
		);
	}

	render() {
		const user = new User(this.props.currentUser);

		if (this.state.loading) {
			return (
				<div className="meeting">
					<Spinner center />
				</div>
			);
		}

		if (this.props.meetings == null)
			return null;

		return (
			<div className="meeting">
				{this.props.meetings.map((meeting) => {
					if (meeting.status == "DRAFT") {
						return null;
					}

					return <Link to={`/meeting/${meeting.id}/instance/${meeting.instances[0]?.id}`} key={meeting.id}>
						<Block>
							<span className="title">{meeting.title}</span>

							{this.renderMeetingInfo(meeting)}
						</Block>
					</Link>
				})}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		meetings: state.Meetings.user,
	};
};

export default connect(mapStateToProps, {
	getUserMeetings,
})(UserMeetings);
