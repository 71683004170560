import { getPlansBySectionId, clearSectionPlansFromStore } from 'actions/plans';
import { getEducationGroups } from 'actions/sections';
import { CardsContainer } from 'components/Cards/Card';
import LoadingCards from 'components/Cards/LoadingCard';
import SectionTitle from 'components/Presentation/SectionTitle';
import { PRESCHOOL } from 'constants/schoolTypes';
import PreschoolPlanningForm from 'containers/Forms/PlanningForm/PreschoolPlanningForm';
import SinglePlanForm from 'containers/Forms/PlanningForm/SinglePlanForm';
import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';
import { getRootUrl } from 'helpers/url';
import { isUserTeacher } from 'helpers/user';
import moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button, Icon, getActiveLanguage } from '@haldor/ui';
import ReactGantt from '../../Gantt/ReactGantt';
import * as microsoftTeams from "@microsoft/teams-js";
import { isMicrosoftTeams } from 'helpers/teams';

class SectionPlans extends Component {
	constructor(props) {
		super(props);

		moment.locale(getActiveLanguage());

		const momentDate = moment();
		this.state = {
			modalIsOpen: false,
			rows: null,
			groups: [],
			sectionId: null,
			noSectionFound: false,
			teamsContext: null,
			options: {
				labelWidth: '20%',
				labelBorderColor: '#E4E4EB',
				labelAppendBeforeFormat: this.props.translate('week'),
				intervalFormat: 'W',
				showBorders: false,
				responsive: true,
				bootstrapped: true,
				leftBound: momentDate.startOf('week').toDate(),
				rightBound: momentDate.endOf('week').add('3', 'weeks').toDate(),
				placeholder: '',
				beforeClimaxColor: 'white',
				afterClimaxColor: 'white',
				hideFirstColumn: true,
				barStyle: {
					height: '105px',
					border: '1px solid #E4E4EB',
					showTitle: true,
					shadow: false,
					borderRadius: '4px',
					hoverPopup: false,
					showGroupTitle: true,
				},
			},
		};
	}

	getContext() {
		const isFrame = isMicrosoftTeams();

		if (isFrame) {
			if (window.location.pathname == '/planning-tab') {
				microsoftTeams.app.getContext().then((context) => {
					const section = this.props.sections.find(section => section.graphId == context.team.groupId);

					if (section != null) {
						this.setState({ sectionId: section.id, noSectionFound: false });
						this.parseRows(section.id);
					} else {
						this.setState({ noSectionFound: true, teamsContext: context });
					}
				});
			} else {
				this.parseRows();
			}
		} else {
			this.parseRows();
		}
	}

	componentWillUnmount = () => {
		this.props.clearSectionPlansFromStore();
	}

	componentDidMount = () => {
		this.initComponent();
	}

	initComponent = () => {
		if (this.props.groupid != null) {
			this.setState({ sectionId: this.props.groupid });
		}

		this.props.getPlansBySectionId(this.props.groupid).then(() => {
			this.getContext();
		});
	}

	parseRows = (sectionId) => {
		let { groups, rows } = this.state;
		let groupId = this.props.groupid;

		if (sectionId != null) {
			groupId = sectionId;
		}

		if (this.props.planning != null) {
			if (rows == null && this.props.planning != null) {
				rows = [];
				this.props.planning.map((planning) => {
					// One planning object
					const isInGroups = groups.find(g_o => g_o.id == planning.sectionId);

					// Filter by groupid
					if (groupId && planning.sectionId != parseInt(groupId)) {
						return false;
					}

					let open = false;
					let showGroupTitle = true;
					if (window.location.pathname == '/planning-tab') {
						open = true;
						showGroupTitle = false;
					}

					if (!isInGroups) {
						const newGroup = {
							id: planning.sectionId, title: planning.section.title, open, showGroupTitle,
						};

						groups.push(newGroup);
					}


					let url = `${getRootUrl()}plan/${planning.id}`;

					const newRow = {
						title: planning.title,
						id: planning.id,
						borderLeft: `3px solid #997AE8`,
						startDate: moment(planning.timeStart).toDate(),
						climaxDate: moment(planning.timeStart).add('6', 'hours').toDate(),
						planning: planning,
						groupColor: "grey",
						endDate: moment(planning.timeEnd).toDate(),
						group: planning.sectionId,
						action: () => { this.props.history.push(url); },
					};

					if (this.props.dateRestriction && planning.status != 'PLANNING_CLOSED') {
						rows.push(newRow);
					} else if (this.props.dateRestriction == false) {
						rows.push(newRow);
					}
				});

				this.setState({ groups, rows, sectionId: groupId }, () => true);
			}
		}
	}

	TaskList = (plannings, i) => (
		<CardsContainer key={`card-container-${i}`}>
			{plannings.plannings.map(this.PlanningCard)}
		</CardsContainer>
	)

	toggleIcon = flip => (
		<i className="cl-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				className={flip ? 'i-90' : 'a-90'}
				style={{ height: '12px', width: '15px', margin: 0 }}
			>
				<path
					id="Path_50"
					data-name="Path 50"
					className="cls-1"
					d="M11.361,1.4,6.38,5.9,1.4,1.4"
				/>
			</svg>
		</i>
	)

	CreateButton = () => {
		if (this.props.disableCreate) {
			return null;
		}

		return <Button
			type="secondary"
			onClick={this.openModal}
			disabled={this.props.locked || this.props.archived}
		>
			<Icon name="Plus" /> {this.props.translate("Create")}
		</Button>;
	}

	goBackwards = (event) => {
		event.preventDefault();
		const { options } = this.state;

		const leftBound = moment(options.leftBound).subtract('1', 'weeks').toDate();
		const maxLeftBound = moment().startOf('week').subtract('20', 'weeks').toDate();

		if (leftBound > maxLeftBound || this.props.dateRestriction == false) {
			options.leftBound = leftBound;
			options.rightBound = moment(options.rightBound).subtract('1', 'weeks').toDate();
			this.setState({ options });
		}
	}

	goForwards = (event) => {
		event.preventDefault();
		const { options } = this.state;

		const rightBound = moment(options.rightBound).add('1', 'weeks').toDate();
		const maxRightBound = moment().endOf('week').add('4', 'weeks').add('20', 'weeks')
			.toDate();

		if (rightBound < maxRightBound || this.props.dateRestriction == false) {
			options.leftBound = moment(options.leftBound).add('1', 'weeks').toDate();
			options.rightBound = rightBound;
			this.setState({ options });
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	}

	teamsTabCloseForm = () => {
		this.setState({ noSectionFound: false }, () => {
			window.location = window.location.href;
		})
	}

	render() {
		const { rows, groups, modalIsOpen, options } = this.state;
		const { planning, currentUser } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);

		return (
			<div className="section" id="section-planning">
				<div className="my_planning planning">
					<Modal isOpen={modalIsOpen} onClose={this.closeModal} title={this.props.translate('plan-create-button')}>
						{this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type === PRESCHOOL ?
							<PreschoolPlanningForm
								onAbort={this.closeModal}
								editView={false}
								cloneView={false}
								sectionId={this.props.groupid != null ? this.props.groupid : this.state.sectionId}
							/>
							:
							<SinglePlanForm
								onAbort={this.closeModal}
								editView={false}
								cloneView={false}
								sectionId={this.props.groupid != null ? this.props.groupid : this.state.sectionId}
							/>
						}
					</Modal>

					<SectionTitle>
						<Icon name="Plans" />

						<span>
							{this.props.translate('planning-header-overview')}
						</span>

						{isTeacher && this.state.noSectionFound == false ?
							this.CreateButton()
							: null}

						{this.props.dateRestriction ?
							<Link to="plans" className="see-more">
								{this.props.translate('show-all-plans')}
							</Link>
							: null}

						<div style={{ float: 'right' }}>
							<Button type="secondary" style={{ marginLeft: 0, marginRight: '0.5em' }} onClick={this.goBackwards}>
								{this.toggleIcon(false)}&nbsp;
							</Button>

							<Button type="secondary" style={{ marginLeft: 0 }} onClick={this.goForwards}>
								{this.toggleIcon(true)}&nbsp;
							</Button>
						</div>
					</SectionTitle>

					<div style={{ clear: 'both' }} />

					{planning != null && rows != null && rows.length == 0 ?
						<div style={{ width: '100%', textAlign: 'center' }}>
							{this.props.planning != null ?
								this.props.planning.length > 0 ?
									<h3>
										{this.props.translate('no-plans-created')}
										{isTeacher && this.state.noSectionFound == false ? this.CreateButton() : null}
									</h3>
									: null
								: null}
						</div>
						:
						rows != null ?
							<div className="gantt-container">
								<ReactGantt
									options={options}
									groups={groups}
									sections={this.props.sections}
									rows={rows}
									weekTranslation={this.props.translate('week')}
								/>
							</div>
							: this.state.noSectionFound == false ?
								<LoadingCards count={1} />
								: null
					}

					{this.state.noSectionFound && isTeacher ?
						<SectionForm
							editView={false}
							teamsContext={this.state.teamsContext}
							teamsTabCloseForm={this.teamsTabCloseForm}
							tab="planning"
						/>
						: null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		planning: state.planning.sectionPlans,
		sections: state.sections.educationGroups,
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
		currentUser: state.user.currentUser,
	};
}

export default withRouter(connect(mapStateToProps, {
	getPlansBySectionId,
	getEducationGroups,
	clearSectionPlansFromStore,
})(SectionPlans));
