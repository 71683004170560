import { getLogsPage, logActivity } from 'actions/logs';
import { isUserAdministrator } from 'helpers/user';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { List } from 'UI';

class UserLogs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startingTicks: '0',
			pageSize: 50,
			loading: false,
		};
	}

	componentDidMount() {
		const { startingTicks, pageSize } = this.state;

		// Log the activity of loading the page
		const logJson = {
			LogType: "Route Change",
			ReferenceType: "Logs Page",
			Comment: "User loaded page"
		};

		this.props.logActivity(logJson);

		// Get logs
		this.setState({ loading: true });
		this.props.getLogsPage(startingTicks, pageSize).then(() => {
			this.setState({ loading: false });
		});

	}

	render() {
		const { loading } = this.state;
		const { translate, logs } = this.props;
		const admin = isUserAdministrator(this.props.currentUser);

		if (admin) {
			display = (
				<List title={translate('user-activity')}>
					<table className="status-list">
						<thead>
							<tr>
								<th>{translate('user')}</th>
								<th>{translate('activity')}</th>
								<th>{translate('item')}</th>
								<th>{translate('item-id')}</th>
								<th>{translate('school')}</th>
								<th>{translate('time')}</th>
							</tr>
						</thead>

						<tbody>
							{logs.map(log => {
								return (
									<tr>
										<td>{log.userName}</td>
										<td>{log.logType}</td>
										<td>{log.referenceType}</td>
										<td>{log.referenceId}</td>
										<td>{log.schoolId}</td>
										<td style={{ textAlign: 'left' }}>
											{Moment(log.timestamp).format("lll")}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</List>
			);
		} else {
			display = (
				<div>{translate('unauthorized')}!</div>
			);
		}

		return (
			<div className="container">
				<div className="single-task">
					{display}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser,
		logs: state.Logs.logs,
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps, { getLogsPage, logActivity })(UserLogs);
