import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Document } from 'UI/Icons';
import msal, { loginRequest, getToken } from 'lib/msal';
import getEnvironment from 'lib/env';

class AttachedDocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			documents: [],
			undo: this.props.translate('undo'),
			delete: this.props.translate('Delete'),
		};

		this.token = null;

		let request = loginRequest;
		request.scopes = [
			...getEnvironment().scopes,
			...request.scopes
		];

		getToken(request).then((response) => {
			this.token = response.accessToken;


			this.loadDocuments(this.props);
		});
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.loadDocuments(nextProps);
	}

	loadDocuments = (props) => {
		if (this.token == null) {
			return false;
		}

		if (props.documents != null) {
			if (typeof props.documents === 'string') {
				const documentString = props.documents.split(';');
				const documents = [];
				documentString.forEach((document) => {
					const documentObj = {
						name: document.indexOf("?") > 0 ? document.split("?")[0].substring(document.split("?")[0].lastIndexOf('/') + 1) : document.substring(document.lastIndexOf('/') + 1),
						url: document,
						remove: false,
					};

					documents.push(documentObj);
				});

				this.setState({ documents });
			} else {
				const documents = [];
				props.documents.forEach((document) => {
					var fileUrl = document.value;

					const documentObj = {
						name: document.name,
						url: fileUrl,
						remove: document.markedForDeletion != null ?
							document.markedForDeletion : false,
					};

					documents.push(documentObj);
				});

				this.setState({ documents });
			}
		}
	}

	removeDocument = (document) => {
		const documents = this.state.documents;

		const doc = documents.find(docItr => docItr.url == document.url);
		if (doc.remove) {
			document.remove = false;
		} else {
			document.remove = true;
		}

		this.setState({ documents });

		this.props.removeInstruction(document.url, document.remove);
	}

	renderDocuments = () => {
		if (this.props.documents !== null) {
			const undo = this.state.undo;
			const deleteTitle = this.state.delete;

			return this.state.documents.map((document, i) => {
				if (document.url !== '') {
					return (
						<div className="document" key={`doc-${i}`}>
							<a href={document.url} target="_blank" >
								<div className="icon"><Document /></div>

								<div className="column-1">
									<div className="name">
										<strong>{document.name}</strong>
									</div>
								</div>
							</a>

							{this.props.removeInstruction != null ?
								<div className="button button-secondary" onClick={() => { this.removeDocument(document); }}>
									{document.remove ? undo : deleteTitle}
								</div>
								: null}

							<div style={{ clear: 'both' }} />
						</div>
					);
				}
			});
		}
	}

	render() {
		return (
			<div className="attached-documents">
				{this.renderDocuments()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(AttachedDocuments);