import api from 'lib/api';

export const GET_MEETING_TIME_BLOCKS = 'GET_MEETING_TIME_BLOCKS';
export const CREATE_MEETING_TIME_BLOCKS = 'CREATE_MEETING_TIME_BLOCKS';
export const UPDATE_MEETING_TIME_BLOCKS = 'UPDATE_MEETING_TIME_BLOCKS';
export const UPDATE_MEETING_TIME_BLOCK_SLOT = 'UPDATE_MEETING_TIME_BLOCK_SLOT';
export const DELETE_MEETING_TIME_BLOCKS = 'DELETE_MEETING_TIME_BLOCKS';
export const GET_MEETING_TIME_BLOCK_SLOTS = 'GET_MEETING_TIME_BLOCK_SLOTS';

export const getMeetingTimeBlocks = (meetingId) => ({
	type: GET_MEETING_TIME_BLOCKS,
	payload: new Promise((resolve) => {
		api.get(`timeblocks/meeting/${meetingId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createMeetingTimeBlocks = (meetingId, timeBlocks) => ({
	type: CREATE_MEETING_TIME_BLOCKS,
	payload: new Promise((resolve) => {
		api.post(`timeblocks/meeting/${meetingId}`, timeBlocks).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateMeetingTimeBlock = (meetingId, timeBlock) => ({
	type: UPDATE_MEETING_TIME_BLOCKS,
	payload: new Promise((resolve) => {
		api.put(`timeblocks/meeting/${meetingId}`, timeBlock).then((response) => {
			resolve(timeBlock);
		});
	}),
});

export const updateMeetingTimeBlockSlot = (meetingId, timeBlockSlot) => ({
	type: UPDATE_MEETING_TIME_BLOCK_SLOT,
	payload: new Promise((resolve) => {
		api.put(`timeblocks/meeting/${meetingId}/slots`, timeBlockSlot).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteMeetingTimeBlock = (meetingId, blockId, callback) => ({
	type: DELETE_MEETING_TIME_BLOCKS,
	payload: new Promise((resolve) => {
		api.delete(`timeblocks/meeting/${meetingId}/block/${blockId}`).then((response) => {
			resolve(response.data);
		});
	}),
});
