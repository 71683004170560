import {
	GET_MY_BOOKMARKS,
	GET_BOOKMARK_BY_REFERENCE,
	CREATE_BOOKMARK,
	DELETE_BOOKMARK,
} from 'actions/bookmarks';

const INITIAL_STATE = {
	user: [],
	reference: [],
};

export default function (state = INITIAL_STATE, action) {

	switch (action.type) {
		case GET_MY_BOOKMARKS:
			return { ...state, user: action.payload };

		case GET_BOOKMARK_BY_REFERENCE:
			return { ...state, reference: action.payload };

		case CREATE_BOOKMARK:
			return {
				...state,
				user: [...state.user, ...action.payload],
				reference: [...state.reference, ...action.payload],
			};

		case DELETE_BOOKMARK:
			return {
				...state,
				user: [...state.user.filter(bookmark =>
					bookmark.id != action.payload
				)],
				reference: state.reference != null && state.reference.id == action.payload ?
					INITIAL_STATE.reference : state.reference,
			};

		default:
			return state;
	}

}