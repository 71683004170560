import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	getBaseGroups,
	deleteBaseGroup,
	getBaseGroup,
	updateBaseGroup,
} from 'actions/basegroups';

import { getSection } from 'actions/sections';

import User from '_class/User';
import { Button, Icon, Block, Flex } from '@haldor/ui';
import { Spinner } from 'UI';
import swal from 'sweetalert2';
import Modal from 'containers/Modals/Modal';

import BaseGroupForm from 'containers/BaseGroups/Form/BaseGroupForm';

class SectionBaseGroups extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			editModal: false,
			selectedBaseGroup: 0,
		};
	}

	componentDidMount = () => {
		const { groupId } = this.props.match.params;
		if (this.props.section == null || (this.props.section != null && this.props.section.id != groupId)) {
			this.props.getSection(groupId).then(() => {
				this.props.getBaseGroups(this.props.section.id);
			});
		} else {
			this.props.getBaseGroups(this.props.section.id);
		}
	}

	/* Help methods - JHELP */
	getUnassignedStudents = () => {
		let students = [];

		if (this.props.active == null) {
			return students;
		}

		if (this.props.section == null) {
			return students;
		}

		this.props.section.students.forEach(student => {
			let isSelected = this.props.active?.units.find(unit => {
				return unit.memberships.find(member => student.userId == member.userId);
			});

			if (isSelected != null) {
				return false;
			}

			students.push(student);
		});

		return students;
	}

	/* Event methods - JEVEN */
	toggleEditModal = () => {
		this.setState({ editModal: !this.state.editModal });
	}

	onBaseGroupSubmit = (values) => {
		return new Promise(async (resolve) => {
			await this.props.updateBaseGroup(values);
			this.setState({ editModal: false, selectedBaseGroup: this.props.active?.id });

			resolve(1);
		});
	}

	deleteBaseGroup = (baseGroup) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async result => {
			if (result.value != null) {
				this.setState({ loading: true });
				this.props.deleteBaseGroup(baseGroup.id).then(() => {
					this.setState({ loading: false });
				});
			}
		});
	}

	onBaseGroupSelect = async (event) => {
		if (event.target.value == null || event.target.value == '') {
			this.setState({ selectedBaseGroup: '' });
			return false;
		}

		this.setState({ loading: true, selectedBaseGroup: event.target.value });
		await this.props.getBaseGroup(event.target.value);
		this.setState({ loading: false });
	}

	/* Render methods - JREND */
	renderBaseGroup = (baseGroup) => {
		return (
			<div style={{ marginTop: '1rem' }} key={baseGroup.id}>
				{baseGroup.units.map((unit, index) => {
					return <div className="unit" key={index}>
						<span className="title">
							{unit.title}
						</span>

						{unit.memberships.map((membership) => {
							return <div key={membership.userId}>
								{membership.user?.firstName} {membership.user?.lastName}
							</div>
						})}
					</div>
				})}
			</div>
		);
	}

	render() {
		const user = new User(this.props.currentUser);
		const { translate } = this.props;
		const unassignedStudents = this.getUnassignedStudents();

		return (
			<div className="basegroups display">
				<Modal title={translate('edit-basegroup')} isOpen={this.state.editModal} type="small" onClose={this.toggleEditModal}>
					<div className="form">
						<div className="form-row">
							<BaseGroupForm
								section={this.props.section}
								onSubmit={this.onBaseGroupSubmit}
								baseGroup={this.props.active}
							/>
						</div>
					</div>
				</Modal>

				<Block>
					<h3 style={{ fontWeight: 400, marginBottom: '1rem' }}>
						{this.props.translate('basegroups')}
					</h3>

					<div className="form">
						<Flex center>
							<div style={{ flex: 1 }} className="select">
								<select
									style={{ padding: '.85rem 1rem' }}
									value={this.state.selectedBaseGroup}
									onChange={this.onBaseGroupSelect}
								>
									<option value=''>
										{this.props.translate('select-basegroup')}
									</option>

									{this.props.baseGroups.map((baseGroup) => {
										return (
											<option value={baseGroup.id} key={baseGroup.id}>
												{baseGroup.title}
											</option>
										)
									})}
								</select>
							</div>

							{this.props.active != null ?
								<div style={{ flex: 0, marginLeft: '1.25rem' }}>
									<Button type="secondary" onClick={this.toggleEditModal}>
										<Icon name="Pen_Small" />
										{this.props.translate('Edit')}
									</Button>
								</div>
								: null}
						</Flex>
					</div>

					{this.state.loading ?
						<Spinner center />
						:
						this.props.active != null ?
							this.renderBaseGroup(this.props.active)
							: null
					}

					{unassignedStudents.length > 0 ?
						<div className="unit">
							<span className="title">{this.props.translate('unassigned-students')}</span>

							{unassignedStudents.map((student, index) => {
								return <div key={student.userId}>
									{student.firstName} {student.lastName}
								</div>
							})}
						</div>
						: null}
				</Block>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		section: state.sections.activeSection,
		baseGroups: state.BaseGroups.baseGroups,
		active: state.BaseGroups.baseGroup,
	};
}

export default withRouter(connect(mapStateToProps, {
	getBaseGroups,
	deleteBaseGroup,
	getBaseGroup,
	updateBaseGroup,
	getSection,
})(SectionBaseGroups));