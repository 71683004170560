import CalendarEventForm from 'containers/Forms/CalendarEvent';
import Modal from 'containers/Modals/Modal';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import User from '_class/User';
import swal from "sweetalert2";

import { stageConversation } from 'actions/conversation';
import { getSchedule } from 'actions/schedule';

import { PRESCHOOL } from 'constants/schoolTypes';

import {
	createExportAssessmentForSectionId
} from "actions/assessments";

import SelectRecipient from 'containers/Conversation/Form/SelectRecipient';
import { Button } from '@haldor/ui';

class SectionTools extends Component {

	constructor(props) {
		super(props);

		this.state = {
			createEvent: false,
		};
	}

	isUserOwner = () => {
		const { currentUser, group } = this.props;

		if (currentUser == null || group == null) {
			return false;
		}

		let foundOwner = group.owners.find(owner => {
			return currentUser.id == owner.id;
		});

		if (foundOwner != null) {
			return true;
		}

		return false;
	}

	onConversation = () => {
		this.setState({ selectRecipient: !this.state.selectRecipient });
	}

	createAssessmentForSection = () => {
		swal.fire({
			title: this.props.translate('export-assessment'),
			text: this.props.translate('export-assessment-confirm-text'),
			showCancelButton: true,
			confirmButtonText: 'Ok',
			cancelButtonText: this.props.translate('Cancel')
		}).then(result => {
			if (result.value != null) {
				this.props.createExportAssessmentForSectionId(this.props.group.id);
			}
		});
	}

	toggleForm = (skip) => {
		this.setState({ createEvent: true });
	}

	closeForm = (date) => {
		this.setState({ createEvent: false });

		if (date != null) {
			this.props.getSchedule(
				encodeURIComponent(Moment({ hour: 0 }).format('YYYY-MM-DD HH:mm:ss')),
				encodeURIComponent(Moment({ hour: 23, minute: 59 }).format('YYYY-MM-DD HH:mm:ss')),
			);
		}
	}

	onSelectRecipientSubmit = (conversation) => {
		conversation.relationships.push({
			referenceType: 'SECTION',
			referenceId: this.props.group.id,
			reference: this.props.group,
		});

		conversation.title = '';
		conversation.status = 'ACTIVE';
		conversation.id = 'new';
		conversation.messages = [];

		this.props.stageConversation(conversation)
			.then(() => {
				this.setState({ selectRecipient: false });
				this.props.history.push(`/conversations/new`);
			});
	}

	render() {
		const groupId = this.props.group.id;
		const user = new User(this.props.currentUser);
		const { translate } = this.props;

		let isPreschool = false;

		if (this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL) {
			isPreschool = true;
		}

		return (
			<div className="single-actions right-side">
				{this.props.group.type != 'MENTOR_GROUP' && this.isUserOwner() ?
					<Modal
						isOpen={this.state.createEvent}
						onClose={this.closeForm}
						title={this.props.translate('create-lesson')}>
						<CalendarEventForm
							onSubmit={this.closeForm}
							sectionId={this.props.group.id}
						/>
					</Modal>
					: null}

				<Modal title={translate('send-message')} type="small" isOpen={this.state.selectRecipient} onClose={this.onConversation}>
					<SelectRecipient onSubmit={this.onSelectRecipientSubmit} />
				</Modal>

				<div className="action-section" style={{ marginBottom: '1.55rem' }}>
					<h3>{this.props.translate('tools')}</h3>

					<div style={{ marginTop: 5 }}>
						{!this.props.group.locked && this.isUserOwner() ?
							this.props.ArchiveButton(this.props.group)
							: null}
					</div>

					<div style={{ marginTop: 5 }}>
						{!this.props.group.locked && this.isUserOwner() ?
							this.props.EditButton(this.props.group)
							: null}
					</div>

					{!user.isStudent() && !isPreschool && this.props.group.type != 'MENTOR_GROUP' && !this.props.group.archived && this.props.services.schedule && this.isUserOwner() ?
						<div>
							<Button type="secondary" style={{ marginTop: 5 }} onClick={this.toggleForm}>
								{this.props.translate('create-lesson')}
							</Button>
						</div>
						: null}

					{this.props.group.teamsUrl != '' && this.props.group.teamsUrl != null && this.props.group.locked == false ?
						<div style={{ marginTop: 5 }}>
							<a target="_blank" href={this.props.group.teamsUrl} style={{ display: 'inline', marginBottom: 0 }}>
								<Button type="secondary">
									{this.props.translate('microsoft-teams')}
								</Button>
							</a>
						</div>
						: null}

					{this.props.group.type != 'MENTOR_GROUP' && this.isUserOwner() && !isPreschool ?
						<div style={{ marginTop: 5 }}>
							<Link to={`/groups/${groupId}/basegroups`} style={{ display: 'inline', marginBottom: 0 }}>
								<Button type="secondary">
									{this.props.translate('basegroups')}
								</Button>
							</Link>
						</div>
						: null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		services: state.Services.availableServices
	};
}

export default withRouter(connect(mapStateToProps, {
	stageConversation,
	createExportAssessmentForSectionId,
	getSchedule,
})(SectionTools));