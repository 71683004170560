import React, { Component } from 'react';
import msal, { loginRequest } from 'lib/msal';
import * as microsoftTeams from "@microsoft/teams-js";

import { Spinner } from 'UI';

class GenerateToken extends Component {

	componentDidMount = () => {
		if (window.location.hash.indexOf('code') > -1) {
			msal.handleRedirectPromise().then((response) => {
				if (response != null) {
					microsoftTeams.authentication.notifySuccess(response);
				} else {
					microsoftTeams.authentication.notifyFailure('');
				}
			})
				.catch((reason) => {
					microsoftTeams.authentication.notifyFailure(reason);
				});

			return true;
		}

		let request = JSON.parse(localStorage.getItem('haldor-teams-auth-request'));
		request.redirectUri = window.location.origin + '/GenerateToken';

		msal.acquireTokenRedirect(request);
	}

	render() {
		return (
			<div>
				<Spinner center />
			</div>
		);
	}

}

export default GenerateToken;