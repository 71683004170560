import api from 'lib/api';

export const KNOWLEDGE = 'KNOWLEDGE';
export const CLEAR_KNOWLEDGE = 'CLEAR_KNOWLEDGE';
export const ACTIVE_PLAN_KNOWLEDGECOLLECTIONS = 'ACTIVE_PLAN_KNOWLEDGECOLLECTIONS';
export const ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS = 'ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS';
export const UPDATE_CLARIFICATION = 'UPDATE_CLARIFICATION';
export const DELETE_CLARIFICATION = 'DELETE_CLARIFICATION';


export const getKnowledgeBySection = (sectionId, grouped, callback) => ({
	type: KNOWLEDGE,
	payload: new Promise((resolve) => {
		let endpoint = `knowledge/section/${sectionId}`;

		if (grouped) {
			endpoint = `${endpoint}/grouped/paragraph`;
		} else {
			endpoint = `${endpoint}/collections`;
		}

		api.get(endpoint).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const getGroupedKnowledgeBySection = (sectionId, callback) => ({
	type: KNOWLEDGE,
	payload: new Promise((resolve) => {
		const endpoint = `knowledge/section/${sectionId}/collections`;

		api.get(endpoint).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const getPlanKnowledgeCollections = (sectionId, planId, callback) => ({
	type: ACTIVE_PLAN_KNOWLEDGECOLLECTIONS,
	payload: new Promise((resolve) => {
		api.get(`knowledge/section/${sectionId}/plan/${planId}/collections`).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const getAssignmentKnowledgeCollections = (sectionId, assignmentId, callback) => ({
	type: ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS,
	payload: new Promise((resolve) => {
		api.get(`knowledge/section/${sectionId}/assignment/${assignmentId}/collections`).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const updateClarification = (clarification, callback) => ({
	type: UPDATE_CLARIFICATION,
	payload: new Promise((resolve) => {
		const endpoint = 'knowledge/clarification';

		api.put(endpoint, clarification).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});

export const deleteClarification = (clarification, callback) => ({
	type: DELETE_CLARIFICATION,
	payload: new Promise((resolve) => {
		api.delete(`knowledge/clarification/${clarification.id}`).then((response) => {
			resolve(response.data);
			callback(response.data);
		});
	}),
});


export const clearKnowledge = () => ({
	type: CLEAR_KNOWLEDGE,
	payload: [],
});
