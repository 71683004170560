import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate, Flex, Checkbox, Button, ButtonGroup } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getServices, sendAdminInvitation, getConsentInfo, getAdminInvitations } from '../../actions/services';
import { Spinner } from 'UI';
import getEnvironment from 'lib/env';
import './_onboarding.scss';
import SelectConsentWay from './Step2/SelectConsentWay';
import VerdictFeedbackForm from 'containers/Verdict/Form/Partials/VerdictFeedbackForm';
import EmailAdminInvite from './Step2/EmailAdminInvite';
import EmailAdminSent from './Step2/EmailAdminSent';
import WaitingForResponse from './Step2/WaitingForResponse';
import { appInsights } from 'lib/appInsights';

class ConsentManager extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			showEmailInfo: false,
			showConsentInfo: true,
			showEmailSentInfo: false,
			showWaitingResponse: false,
			waitingResponse: false,
			adminEmail: null,
			recpients: [],
			inviteRetry: 0,
			user: { name: '', email: '' }
		};
	}


	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	componentDidMount() {
		appInsights.trackTrace({ message: "Onboarding | ConsentManager was initiated" });

		this.props.getAdminInvitations().then(() => {
			if (this.props.adminInvitations != null) {
				if (this.props.adminInvitations.sentByMe) {
					this.showPage('waitingForResponse');
				}
			}

			this.setState({ isLoading: false });
		});
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(0)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	showPage(pageName) {
		switch (pageName) {
			case 'showEmailInfo':
				this.setState({ showEmailSentInfo: false, showConsentInfo: false, showEmailInfo: true, showWaitingResponse: false });
				break;
			case 'showConsentInfo':
				if (this.props.adminInvitations != null) {
					if (this.props.adminInvitations.sentByMe) {
						this.showPage('waitingForResponse');
					} else {
						this.setState({ showEmailSentInfo: false, showConsentInfo: true, showEmailInfo: false, showWaitingResponse: false });
					}
				} else {
					this.setState({ showEmailSentInfo: false, showConsentInfo: true, showEmailInfo: false, showWaitingResponse: false });
				}
				break;
			case 'showEmailSentInfo':
				this.setState({ showEmailSentInfo: true, showConsentInfo: false, showEmailInfo: false, showWaitingResponse: false });
				break;
			case 'navigateToAdminConsent':
				if (this.props.onSubmit) {
					this.props.onSubmit(2);
				}
				break;
			case 'waitingForResponse':
				this.setState({ showEmailSentInfo: false, showConsentInfo: false, showEmailInfo: false, showWaitingResponse: true })
			default:
				//this.setState({ showEmailSentInfo: false, showConsentInfo: true, showEmailInfo: false, showWaitingResponse: false });
				break;
		}
	}

	render() {
		return (
			<div>
				{this.state.isLoading ? <Spinner center /> : <div>
					{this.state.showConsentInfo ? <SelectConsentWay showPage={this.showPage.bind(this)} goBack={this.goBack.bind(this)}></SelectConsentWay> : null}
					{this.state.showEmailInfo ? <EmailAdminInvite showPage={this.showPage.bind(this)} ></EmailAdminInvite> : null}
					{this.state.showEmailSentInfo ? <EmailAdminSent showPage={this.showPage.bind(this)} ></EmailAdminSent> : null}
					{this.state.showWaitingResponse ? <WaitingForResponse showPage={this.showPage.bind(this)} goBack={this.goBack.bind(this)}></WaitingForResponse> : null}
				</div>
				}
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		selectedServices: state.Services.selectedServices,
		consentInfo: state.Services.consentInfo,
		inviteAdminStatus: state.Services.inviteAdminStatus,
		onboardingUser: state.user.onboardingUser,
		adminInvitations: state.Services.adminInvitations,
	};
}

export default connect(mapStateToProps, {
	getServices,
	sendAdminInvitation,
	getConsentInfo,
	getAdminInvitations
})(reduxForm({
	form: 'AdminInvite',
	destroyOnUnmount: true,
})(ConsentManager));
