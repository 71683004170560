import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import Moment from 'moment';

import User from '_class/User';

import { submitCalendarEvent, updateCalendarEvent } from 'actions/schedule';
import { clearSelectedSections } from "actions/sections";

import { Form, Field } from 'react-final-form';
import { Expandable, List, Spinner } from 'UI';
import { Editor, TimePicker } from 'UI/Inputs';
import { Checkbox, Radio, Button, Icon } from '@haldor/ui';
import DatePickerFromTo from './Partials/DatePickerFromTo';

import UserSelect from 'components/List/UserSelect';
import { getMentorGroups, getSections } from 'actions/sections';

class ConflictingEventForm extends Component {

	constructor(props) {
		super(props);

		let dateStart = Moment().second(0);
		let dueDate = null;
		if (props.initialValues?.startTime != null) {
			dateStart = Moment.utc(props.initialValues.startTime).local();
			if (props.initialValues?.length) {
				dueDate = Moment(dateStart).add(props.initialValues?.length, 'm');
			}
		}

		this.state = {
			dateStart: props.hasDate ? Moment(props.hasDate) : dateStart,
			dateEnd: props.hasDate ? Moment(props.hasDate) : dueDate ?? dateStart,
			targetTypeSchool: false,
			sections: [],
			user: new User(this.props.currentUser)
		};
	}

	submit = (values) => {
		const editing = this.props.initialValues != null;
		const { dateStart, dateEnd, sections } = this.state;

		return new Promise((resolve) => {
			//Add ActivityMemberships
			values.ActivityMemberships = [];
			if (values.targetType == 'school') {
				values.ActivityMemberships.push({
					ReferenceId: this.state.activeSchool.id,
					ReferenceType: 'SCHOOL'
				});
			}
			else {
				values.relationships.forEach(rel => {
					if (rel.referenceType == 'SECTION') { // only add sections
						values.ActivityMemberships.push({
							ReferenceId: rel.referenceId,
							ReferenceType: rel.referenceType
						});
					}
				});
			}
			delete values.relationships;

			// Add targetAudience
			values.targetAudience = '';
			if (values.targetStudents) { values.targetAudience += 'STUDENTS:'; }
			if (values.targetGuardians) { values.targetAudience += 'GUARDIANS:'; }
			if (values.targetGuardians && values.targetStudents) { values.targetAudience = 'ALL'; }
			if (values.targetAudience[values.targetAudience.length - 1] == ':') {
				values.targetAudience = values.targetAudience.substring(0, values.targetAudience.length - 1);
			}

			//Add date and length
			values.startTimeUTC = Moment.utc(dateStart).format();
			values.length = dateEnd.diff(dateStart, 'minutes');
			values.type = 'CALENDAR_EVENT';

			if (editing) {
				// Submit form
				this.props
					.updateCalendarEvent(values)
					.then(() => {
						resolve(1);
						console.log(values);
						this.props.onSubmit(values);
					})
					.catch(() => {
						// Something went wrong in submission
						resolve(0);
					});
			} else {
				// Submit form
				this.props
					.submitCalendarEvent(values)
					.then(() => {
						resolve(1);

						this.props.onSubmit(dateStart);
						this.props.onAbort(true); // close form
					})
					.catch(() => {
						// Something went wrong in submission
						resolve(0);
					});
			}
		});


	};

	componentWillUnmount = () => {
		this.props.clearSelectedSections();
	}

	componentDidMount = () => {
		let promises = [];
		if ((this.state.user.isMentor() || this.state.user.isSchoolLeader() || this.state.user.isAdministrator()) && this.props.mentorGroups.length == 0) {
			let mentorPromise = this.props.getMentorGroups();
			promises.push(mentorPromise);
		}

		if (this.props.sections == null) {
			let sectionPromise = this.props.getSections();
			promises.push(sectionPromise);
		}

		if (promises.length > 0) {
			this.setState({ loading: true });
		}

		Promise.all(promises).then(() => {
			this.setState({ loading: false });
		});
	}

	onSectionsChange = (groups) => {
		this.setState({ sections: groups });
	};

	onDateChange = (dates) => {
		var start = Moment(dates.start);
		var end = Moment(dates.end);

		this.setState({
			dateStart: start,
			dateEnd: end.set({ date: start.date(), month: start.month(), year: start.year() }) // End date is hidden, only time of day is used
		});
	};

	invalidDates = () => {
		return this.state.dateStart.diff(this.state.dateEnd, 'minutes') == 0 || this.state.dateStart.isAfter(this.state.dateEnd);
	}

	/* Redux form functions */
	required = (value) => {
		if (typeof value !== 'undefined' && value !== '') {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	};

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	validateRelationships = (value) => {
		if (value == null || value == '') {
			return this.props.translate('select-relation-error');
		}

		if (value.length == 0) {
			return this.props.translate('select-relation-error');
		}

		return undefined;
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: '1px solid red',
							}
							: {}
					}
					{...input}
				/>

				{touched &&
					((error && <span style={{ marginTop: '1rem', color: 'red' }}>{error}</span>) ||
						(warning && <span style={{ marginTop: '1rem', color: 'red' }}>{warning}</span>))}
			</div>
		);
	};

	targetTypeChanged = (val) => {
		let targetTypeSchool = val == 'school';
		if (targetTypeSchool != this.state.targetTypeSchool) {
			this.setState({ targetTypeSchool: targetTypeSchool });
		}
	}


	/* Render form */
	render() {
		let initialValues = this.props.initialValues;
		let { targetTypeSchool } = this.state;

		let isEditing = this.props.initialValues != null;
		if (initialValues == null) {
			initialValues = {};
		}

		const showSchoolOption = this.state.user.isAdministrator() || this.state.user.isSchoolLeader() || this.state.user.isOperationManager();
		this.state.activeSchool = this.state.user.getActiveSchool();

		let sections = [];
		if (this.state.user.isMentor() || this.state.user.isSchoolLeader() || this.state.user.isAdministrator()) {
			sections = [...this.props.sections, ...this.props.mentorGroups];
			if (this.state.user.isSchoolLeader() || this.state.user.isAdministrator()) {
				let nonOwnedSections = initialValues.sections?.filter(s => sections.findIndex((x) => x.id === s.id) < 0);
				if (nonOwnedSections?.length > 0) {
					sections = [...this.props.sections, ...nonOwnedSections, ...this.props.mentorGroups];
				}
			}
		} else {
			sections = this.props.sections;
		}

		if (!(this.state.user.isSchoolLeader() || this.state.user.isAdministrator())) {
			// Only show groups user is owner for
			sections = sections.filter(section => {
				return section.userRole === "OWNER";
			});
		}

		if (isEditing) {
			const { targetAudience } = initialValues;
			initialValues.targetStudents = targetAudience == 'ALL' || targetAudience?.indexOf('STUDENTS') > -1;
			initialValues.targetGuardians = targetAudience == 'ALL' || targetAudience?.indexOf('GUARDIANS') > -1;

			if (initialValues.activityMemberships != null) {
				targetTypeSchool = initialValues.activityMemberships?.find(m => m.referenceType === 'SCHOOL') != null;
				initialValues.targetType = targetTypeSchool ? 'school' : 'group';

				if (!targetTypeSchool) {
					initialValues.relationships = [];
					initialValues.activityMemberships.forEach(membership => {
						initialValues.relationships.push({
							referenceType: membership.referenceType.toUpperCase(),
							referenceId: membership.referenceId,
							id: -1
						});
					});
				}
				delete initialValues.activityMemberships;
			}
		} else {
			initialValues.targetStudents = false;
			initialValues.targetGuardians = false;
			initialValues.targetType = this.state.targetTypeSchool ? 'school' : 'group';
		}

		if (this.state.dateStart != null) {
			initialValues.startDate = this.state.dateStart;
		}
		if (this.state.dateEnd != null) {
			initialValues.dueDate = this.state.dateEnd;
		}

		if (this.state.loading) {
			return <Spinner center />
		}

		/* Validate group selection */
		let passingGroup = true;
		return (
			<div className='form-container form-create_task'>
				<Form
					onSubmit={this.submit}
					initialValues={initialValues}
					initialValuesEqual={() => true}

					render={({ submitting, handleSubmit, form, valid }) => {

						return (
							<form onSubmit={handleSubmit} className='form form-component'>

								<Expandable
									contentStyles={{ padding: '1em 0rem' }}
									contentStylesClosed={{ padding: '0' }}
									headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
									whiteBackground={true}

									title={this.props.translate('select-recipient')}
									open={!isEditing}
									ignorePropsUpdate
								>

									{showSchoolOption ? (
										<div style={!targetTypeSchool ? { 'line-height': '2.5em', 'margin-bottom': '1em' } : { 'line-height': '2.5em' }}>
											<div className="form-row">
												<Field
													name="targetType"
													component={Radio}
													optionValue="school"
													label={this.props.translate("Entire school") + ": " + this.state.activeSchool?.title}
													validate={this.targetTypeChanged}
												/>
											</div>
											<div className="form-row">
												<Field
													name="targetType"
													component={Radio}
													optionValue="group"
													label={this.props.translate("Groups")}
													validate={this.targetTypeChanged}
												/>
											</div>
										</div>
									) : null}

									{!targetTypeSchool ? (
										<List style={showSchoolOption ? { border: '0', margin: '0', 'margin-left': '3em', width: '96%', boxShadow: 'none' } : { border: '0', margin: '0', boxShadow: 'none' }}>
											<Field
												component={UserSelect}
												name="relationships"
												sections={sections}
												userSelectDisabled={true}
												selectMultipleSchools={this.props.selectMultipleSchools}
												showUsersWithoutMentorGroups={false}
												validate={this.validateRelationships}
												customSingleSchoolName={this.props.translate("Groups")}
											/>
										</List>) : null}

								</Expandable>

								{submitting ? (
									<div className='is_sending'>
										<p>
											<span className='loading-spinner' />
										</p>
									</div>
								) : null}

								<div className="form-row">
									<label>{this.props.translate('select-recipient-type')}</label>

									<div style={{ display: 'inline-block' }}>
										<Field
											name="targetGuardians"
											component={Checkbox}
											label={this.props.translate('Guardians')}
										/>
									</div>

									<div style={{ marginLeft: '1.25rem', display: 'inline-block' }}>
										<Field
											name="targetStudents"
											component={Checkbox}
											label={this.props.translate('students')}
										/>
									</div>
									<div className="size-12 color--meta recipient-alert" title={this.props.translate('Calendar events are always visible to staff')}>
										<Icon name="Alert" bw /> {this.props.translate('Calendar events are always visible to staff')}
									</div>
								</div>

								<div className='form-row input'>
									<label>{this.props.translate('title')}*</label>

									<Field
										component={this.renderInput}
										type='text'
										name='title'
										placeholder={this.props.translate('title')}
										validate={this.required}
									/>
								</div>

								<div className='form-row input'>
									<label>{this.props.translate('description')}</label>

									<Field
										component={Editor}
										name='description'
										placeholder={this.props.translate('Describe the calendar event')}
										translate={this.props.translate}
									/>
								</div>

								<div className='form-row'>
									<DatePickerFromTo
										timePicker
										type='assignment'
										hideEndDate
										customStartDateLabel='date'
										onChange={this.onDateChange}
										values={
											initialValues ?? { startDate: Moment(this.state.startDate) }
										}
									/>
								</div>

								<div style={{ clear: 'both' }} />
								< div className='form-divider' />

								<div className='form-row spacing submit'>
									<Button
										onClick={(e) => {
											e.preventDefault();
											form.submit();
										}}
										disabled={
											submitting ||
											!valid ||
											!passingGroup ||
											this.invalidDates()
										}
									>
										{this.props.translate('publish')}
									</Button>

									<div className='align-right'>
										<Button
											type='secondary'
											onClick={(e) => {
												e.preventDefault();
												this.props.onAbort(false);
											}}
										>
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						);
					}
					}
				/>


			</div >
		);
	}
}

function mapStateToProps(state) {

	return {
		translate: translate(state.Languages.translations),
		initialValues: state.schedule.activeScheduleItem,
		sections: state.sections.educationGroups,
		mentorGroups: state.sections.mentorGroups,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	clearSelectedSections,
	submitCalendarEvent,
	updateCalendarEvent,
	getSections,
	getMentorGroups,
})(ConflictingEventForm);
