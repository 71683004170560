import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from 'lib/translate';
import { Form, Field } from 'react-final-form';

import { clearSearch, searchGroups } from 'actions/search';

import { Spinner } from 'UI';
import { Search } from 'UI/Inputs';
import { Button, Radio } from '@haldor/ui';

const SearchGroups = (props) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const searchResults = useSelector((state) => state.Search.groups);

	const onSearch = (values) => {
		if (values.query == '') {
			dispatch(clearSearch());
			return true;
		}

		if (values.query.length < 3) {
			return false;
		}

		dispatch(searchGroups({
			filter: 'EDUCATION_GROUP;',
			query: values.query,
		}));
	};

	const onSubmit = (values) => new Promise((resolve) => {
		if (props.onSubmit != null) {
			props.onSubmit(values).then(() => {
				resolve(1);
			});

			dispatch(clearSearch());
		}
	});

	return (
		<div className='form select-user-form'>
			<Form
				onSubmit={onSubmit}
				mutators={{
					clearUserId: (args, state, utils) => {
						utils.changeValue(state, 'group', () => '');
					},
				}}
				render={({ handleSubmit, form, submitting, valid }) => {
					const group = form.getFieldState('group')?.value;

					return (
						<form onSubmit={handleSubmit} className='form-component'>
							{submitting ? (
								<div className='is_sending'>
									<p>
										<span className='loading-spinner' />
									</p>
								</div>
							) : null}

							<div className='form-row' style={{ marginTop: '0.35rem' }}>
								<Search
									onSubmit={(values) => {
										form.mutators.clearUserId();
										onSearch(values);
									}}
									placeholder={translate('Search group')}
									allowEmpty
									clearOnSubmit
								/>
							</div>

							{searchResults?.length > 0 ? (
								<div className='form-row user-list'>
									<div style={{ background: '#f2f3f2', padding: '1rem' }}>
										{searchResults.map((group) => {
											return (
												<div className='user' key={group.id}>
													<Field
														component={Radio}
														name='group'
														label={group.title}
														optionValue={group}
													/>
												</div>
											);
										})}
									</div>
								</div>
							) : null}

							<div className='form-row' style={{ marginTop: '.5rem' }}>
								<Button disabled={props.disabled || submitting || group == null || group == ''}>
									{submitting ? <Spinner /> : null}

									{translate('Add')}
								</Button>
							</div>
						</form>
					);
				}}
			/>
		</div>
	);
};

export default SearchGroups;
