import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import User from '_class/User';

import { addError } from 'actions/index';
import { getSchoolByGroupGraphId, getEducationGroups } from 'actions/sections';
import { getSchoolDataForSections } from 'actions/schools';
import { searchSectionByTitle } from 'actions/search';

import { PRESCHOOL } from 'constants/schoolTypes';
import schoolHandling from 'helpers/schoolHandling';
import api from 'lib/api';

import Select from 'react-select';

import { Spinner } from 'UI';
import Onboarding from 'containers/Onboarding/Onboarding';
import CourseSelectList from 'components/List/CourseSelect';
import { QuillEditor } from 'UI/Inputs';
import { Button, Radio, translate } from '@haldor/ui';
import { getActiveSchool } from 'helpers/localstorage';


class SectionForm extends Component {
	constructor(props) {
		super(props);

		let onboarding = false;
		if (props.onboarding) {
			onboarding = props.onboarding;
		}

		let schoolId = 0;
		let title = '';
		let description = '';
		let graphId = null;

		const user = new User(props.currentUser);
		if (props.connectView) {
			schoolId = user.getActiveSchool().id;
			title = props.section.title;
			graphId = props.section.graphId;
			if (props.section.description != null) {
				description = props.section.description;
			}
		}

		this.state = {
			values: {
				id: 0,
				title: title,
				description: description,
				graphId: graphId,
				course: null,
				schoolId: schoolId,
				extraCourses: [],
				teamsUrl: null,
				type: props.schoolData?.allowManualEducationGroupConnection && (user.isTeacher() || user.isAdministrator()) ? 'EDUCATION_GROUP' : 'MENTOR_GROUP'
			},
			extraCoursesInput: [],
			isSending: false,
			disableSectionInput: false,
			loadingSchedules: false,
			selectedCourse: null,
			onboarding,
			loading: props.schoolData == null,
			steps: [
				{
					target: '#school-input',
					content: props.translate('groupconfig-explain-school'),
					disableBeacon: true,
				},
				{
					target: '#course-input',
					content: props.translate('groupconfig-explain-courses'),
					disableBeacon: true,
				},
				{
					target: '#teams-header-actions',
					content: props.translate('groupconfig-explain-cog'),
					disableBeacon: true,
				},
			],
		};
	}

	UNSAFE_componentWillMount = () => {
		const { editView, connectView, teamsContext } = this.props;

		if (editView || connectView) {
			this.loadEditView();
		}

		if (teamsContext != null) {
			this.setSectionFieldToTeam();
		}
	}

	componentDidMount = () => {
		if (this.props.schoolData == null) {
			const activeSchool = getActiveSchool(this.props.currentUser.id);

			this.props.getSchoolDataForSections(activeSchool).then(() => {
				let values = this.state.values;
				values.type = this.props.schoolData?.allowManualEducationGroupConnection ? 'EDUCATION_GROUP' : 'MENTOR_GROUP';
				this.setState({ values, loading: false });
			});
		}
	}

	setSectionFieldToTeam = () => {
		const sectionInput = {
			label: this.props.teamsContext.team.displayName,
			value: this.props.teamsContext.team.groupId,
		};

		this.setState({ sectionInput, disableSectionInput: true });
		this.props.getSchoolByGroupGraphId(sectionInput.value).then(() => {
			if (this.props.graphGroupSchool != null) {
				let values = this.state.values;
				values.schoolId = this.props.graphGroupSchool.id;
				this.setState({ values });
			}
		});
	}

	loadEditView = () => {
		let selectedCourse = null;
		const values = this.state.values;
		const extraCourses = [];

		values.title = this.props.section.title;
		values.description = this.props.section.description;

		if (!this.props.connectView) {
			values.type = this.props.section.type;
		}

		if (this.props.section.courses.length > 0) {
			this.props.section.courses.forEach(extraCourse => {
				if (this.props.section.courseID != extraCourse.id) {
					var schoolType = this.props.translate(extraCourse.typeOfSchooling);

					extraCourses.push({
						id: extraCourse.id,
						title: `${extraCourse.title} ${extraCourse.year != null ? extraCourse.year : ''} (${schoolType})`,
						colorCode: extraCourse.colorCode,
					});
				} else {
					selectedCourse = extraCourse;
				}
			});
		}

		this.setState({
			values,
			extraCoursesInput: extraCourses,
			selectedCourse
		});
	}

	onInputChange = (input, event) => {
		const values = this.state.values;
		values[input] = event.target.value;
		this.setState({ values });
	}

	onSubmit = (event) => {
		let values = this.state.values;
		values.id = this.props.section.id;

		const extraCourses = [];
		if (this.state.values.type == 'EDUCATION_GROUP') {
			if (this.state.extraCoursesInput != null && this.state.extraCoursesInput.length > 0) {
				this.state.extraCoursesInput.forEach((extraCourse) => {
					const duplicated = extraCourses.find(dupe => dupe.id == extraCourse.id);

					if (duplicated == null) {
						extraCourses.push(extraCourse);
					}
				});
			}
		}

		values.courses = extraCourses;

		this.setState({ values }, async () => {
			const newError = this.validateForm();

			if (!newError) {
				const that = this;
				await this.setState({ isSending: true });

				if (this.props.connectView) {
					const { translate } = this.props;
					const user = new User(this.props.currentUser);
					const schoolTitle = user.getActiveSchool().title;
					const result = await swal.fire({
						title: this.props.translate('are-you-sure'),
						html: `
						<ul>
							<li>${translate('It´s important that you connect the group to the correct school.')}</li>
							<li>${translate('When the group has been linked you will not be able to change group type by yourself.')}</li>
							<li>${translate('Information about who and when the group was linked is saved.')}</li>
						</ul>
						<br />
						${translate('Do you want to connect <strong>{{group_name}}</strong> as a <strong>{{group_type}}</strong> to <strong>{{school_name}}</strong>?', values.title, translate(values.type), schoolTitle)}
						<br />
						`,
						showCancelButton: true,
						focusConfirm: false,
						cancelButtonText: this.props.translate('No'),
						confirmButtonText: this.props.translate('Yes'),
					});

					if (result.dismiss != null) {
						this.setState({ isSending: false });
						return true;
					}
				}

				if (this.props.editView) {
					api.put('sections', values).then(() => {
						this.props.addError(this.props.translate('changes-saved'), 'info');
						this.props.onAbort(true, true);
					});
				} else {
					api.post('sections', values).then((response) => {
						this.props.getEducationGroups();
						if (values.schoolId != 0) {
							schoolHandling.setSchoolOnUser(this.props.currentUser, values.schoolId);
						}

						if (that.props.activeTab == null) {
							this.props.history.push(`/groups/${response.data}`);
						} else {
							that.props.teamsTabCloseForm();
						}
					});
				}
			}
		});
	}

	validateForm = () => {
		const values = this.state.values;
		let newError = false;

		if (values.title.length === 0) {
			newError = true;
			this.props.addError(this.props.translate('enter-a-title-error'), 'notice');
		}

		if (this.props.editView == false) {
			if (values.graphId == null) {
				newError = true;
				this.props.addError(this.props.translate('pick-a-section'), 'notice');
			}
		}

		return newError;
	}

	onDescriptionChange = (text) => {
		const values = this.state.values;
		values.description = text;
		this.setState({ values });
	}

	onClickSave = (event) => {
		event.preventDefault();

		if (!this.state.isSending) {
			this.onSubmit();
		}
	}

	onCoursesChange = (courses) => {
		// courses = array with course objects
		// id, title
		this.setState({ extraCoursesInput: courses });
	}

	onSearchSection = (title, callback) => {
		if (!title) {
			callback([]);
		} else {
			var searchValue = title.trim();
			searchValue = searchValue.replace(/[^A-Za-z0-9åäöÅÄÖ ]/g, "");
			searchValue = encodeURIComponent(searchValue);

			if (searchValue != null && searchValue != "") {
				if (searchValue.length < 3) {
					callback([]);
					return false;
				}

				this.props.searchSectionByTitle(searchValue, true, (data) => {
					let options = [];
					data.forEach((section) => {
						options.push({ value: section.graphId, label: section.title });
					});

					callback(options);
				});
			} else {
				callback([]);
			}
		}
	}

	exitOnboarding = () => this.setState({ onboarding: false });

	selectSchool = (option) => {
		let values = this.state.values;
		values.schoolId = option.values;
		this.setState({ values });
	}

	onTypeChange = (value) => {
		let values = this.state.values;
		values.type = value;
		this.setState({ values });
	}

	render() {
		let preschool = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type == PRESCHOOL;
		let disabled = false;
		let schoolOptions = [];
		const user = new User(this.props.currentUser);
		if (this.props.currentUser != null && this.props.currentUser.schools != null) {
			this.props.currentUser.schools.forEach(school => {
				schoolOptions.push({
					label: school.title,
					value: school.id,
				});
			})
		}

		let schoolValue = schoolOptions.find(school => school.value == this.state.values.schoolId);

		if (this.state.loading) {
			return <div className="form-container form-create-section">
				<Spinner center />
			</div>
		}

		return (
			<div className={this.props.teamsContext != null ? 'form-container no-section-found-create-section' : 'form-container form-create-section'}>
				{this.state.onboarding ?
					<Onboarding
						steps={this.state.steps}
						onExit={this.exitOnboarding}
						onboarding={this.state.onboarding}
					/>
					: null}

				{this.props.teamsContext != null ?
					<div>
						{this.props.translate('form-no-section-found')}
					</div>
					: null}

				{this.state.isSending ?
					<div className="is_sending">
						<p>
							<span className="loading-spinner" />

							<br />
							{this.props.editView ?
								this.props.translate('creation-phrase-1')
								:
								this.props.translate('creating-section')
							}...
						</p>
					</div>
					: null}

				<form className="form form-component">
					{this.props.connectView ?
						<div className="form-row">
							<h2 className="color--blue">
								{this.state.values.title + ' ' + this.props.translate('to').toLowerCase() + ' ' + user.getActiveSchool().title}
							</h2>
						</div>
						: null}

					{this.props.connectView ?
						<div className="form-row">
							<label>{this.props.translate('Select group type')}</label>

							{(user.isTeacher() || user.isAdministrator()) && this.props.schoolData?.allowManualEducationGroupConnection &&
								<div>
									<Radio
										input={{
											onChange: this.onTypeChange,
											value: this.state.values.type,
										}}
										optionValue="EDUCATION_GROUP"
										label={this.props.translate('EDUCATION_GROUP')}
									/>
								</div>
							}

							{(user.isMentor() || user.isAdministrator()) && this.props.schoolData?.allowManualMentorGroupConnection ?
								<div style={{ marginTop: '.35rem' }}>
									<Radio
										input={{
											onChange: this.onTypeChange,
											value: this.state.values.type,
										}}
										optionValue="MENTOR_GROUP"
										label={this.props.translate('MENTOR_GROUP')}
									/>
								</div>
								: null}
						</div>
						: null}

					{this.props.teamsContext != null && !this.props.editView && !this.props.connectView && this.props.currentUser.schools.length > 1 ?
						<div className="form-row">
							<label>
								{this.props.translate('school')}
							</label>

							<div id="school-input">
								<Select
									dataType="schools"
									placeholder={this.props.translate('pick-a-school')}
									disabled={this.props.graphGroupSchool != null}
									value={schoolValue}
									options={schoolOptions}
									onChange={this.selectSchool}
								/>
							</div>
						</div>
						: null}

					<div className="form-row">
						<QuillEditor
							placeholder={this.props.translate('describe-the-group')}
							label={this.props.translate('description')}
							onChange={this.onDescriptionChange}
							text={this.state.values.description}
						/>
					</div>

					{!preschool && this.state.values.type == 'EDUCATION_GROUP' ?
						<div className="form-row" id="course-input">
							<CourseSelectList
								section={this.props.section}
								onChange={this.onCoursesChange}
							/>
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						{this.props.connectView ?
							<Button onClick={this.onClickSave} id="save-input" disabled={disabled}>
								{this.props.translate('Connect to {{school_name}}', user.getActiveSchool().title)}
							</Button>
							:
							<Button onClick={this.onClickSave} id="save-input" disabled={disabled}>
								{this.props.editView ?
									this.props.translate('save')
									:
									this.props.translate('publish')
								}
							</Button>
						}

						<div className="align-right">
							<Button onClick={(event) => {
								event.preventDefault();
								this.props.onAbort(false);
							}} type="secondary">
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
	activeTab: state.user.activeTab,
	graphGroupSchool: state.sections.graphGroupSchool,
	currentUser: state.user.currentUser,
	schoolData: state.School.schoolDataForSection,
});

export default withRouter(connect(mapStateToProps, {
	searchSectionByTitle,
	addError,
	getSchoolByGroupGraphId,
	getEducationGroups,
	getSchoolDataForSections,
})(SectionForm));
