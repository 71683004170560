import React from 'react';
import { Flex, TooltipMenu, Icon } from '@haldor/ui';
import DateTime from '_class/DateTime';

const Message = (props) => {
	let className = 'conversation message';
	let timestampClass = 'timestamp';

	if (props.me) {
		className += ' me';
		timestampClass += ' right';
	}

	if (!props.me) {
		timestampClass += ' left';
	}

	if (props.narrow) {
		className += ' narrow';
	}

	if (props.multipleDown) {
		className += ' no-radius-bottom';
	}

	if (props.multipleUp) {
		className += ' no-radius-top';
	}

	return (<div>
		<Flex end={props.me} center>
			<div className={className} style={{ display: 'flex' }}>
				{props.flagged != null ?
					<div className="flag-badge">
						<Icon name="Flag" />
						{props.flagged}
					</div>
					:
					<span
						dangerouslySetInnerHTML={{ __html: props.children }}
					/>
				}

				{props.new && !props.me ?
					<div className="unread-badge"></div>
					: null}

				{props.actions != null && props.actions.length > 0 ?
					<div className="action-menu">
						<TooltipMenu trigger={"⋯"} id={'tooltip-message-' + props.messageId}>
							{props.actions.map((action, index) => (
								<TooltipMenu.Item onClick={action.onClick} key={'action-' + index}>
									{action.label}
								</TooltipMenu.Item>
							))}
						</TooltipMenu>
					</div>
					: null}
			</div>
		</Flex>

		<div className={timestampClass}>
			{props.sender != null ?
				props.sender + ' • '
				: null}

			{props.user != null ?
				props.user.firstName + ' ' + props.user.lastName + ' • '
				: null}

			{new DateTime(props.time).getTimeStampHours()}
		</div>
	</div>
	);
}

export default Message;
