import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import Moment from "moment";

import User from '_class/User';

import { getActiveSchool } from 'helpers/localstorage';
import { deleteTeacherRole, addTeacherRole, getUserInfo } from 'actions/user';
import { getSchoolSubjectTeachers } from 'actions/schools';

import Modal from 'containers/Modals/Modal';
import SubjectTeachersForm from 'containers/Forms/SubjectTeachersForm';
import TeacherRoleForm from 'containers/Forms/TeacherRole';

import { DataList, Spinner } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Icon, Button, getActiveLanguage } from '@haldor/ui';

class Subjects extends Component {
	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			modal: false,
			modalType: "",
			noPermissionSubjects: [],
			subjects: [],
			subject: {}
		}
	}

	componentDidMount = () => {
		this.getActiveSchoolTeachers();
	}

	userSchoolAdmin = (schoolId) => {
		if (this.props.currentUser.roles != null && this.props.currentUser.roles.length > 0) {
			var schoolAdministrator = this.props.currentUser.roles.find(role => (role.roleId.toUpperCase() == "SCHOOL_ADMINISTRATOR" && role.referenceId == schoolId) || (role.roleId.toUpperCase() == "SYSADMIN"));

			if (schoolAdministrator != null) {
				return true;
			}
		}

		return false;
	}

	getActiveSchoolTeachers = () => {
		this.setState({ loading: true });
		const activeSchool = getActiveSchool(this.props.currentUser.id);

		this.props.getSchoolSubjectTeachers(activeSchool).then(() => {
			this.setState({ loading: false });
			let subjects = [];
			let subjectTeachersUserId = [];
			this.props.subjectTeacher.forEach(s => {
				subjectTeachersUserId.push(s.userId);
				let subject = subjects.find(x => {
					return x.subjectId == s.subjectId;
				})
				if (subject == null) {
					subject = {
						title: s.title,
						subjectId: s.subjectId,
						subjectCode: s.subjectCode,
						typeOfSchooling: s.typeOfSchooling,
						subjectTeachersUserId: [s.userId],
						subjectTeachers: [],
						nrOfSubjectTeachers: 1
					}
					subjects.push(subject);
				}
				else {
					let userId = subject.subjectTeachersUserId.find(uid => {
						return s.userId == uid;
					})
					if (userId == null) {
						subject.nrOfSubjectTeachers = subject.nrOfSubjectTeachers + 1;
						subject.subjectTeachersUserId.push(s.userId);
					}
				}
			})

			subjects = subjects.sort(function (a, b) {
				return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
			});

			this.state.noPermissionSubjects.forEach(s => {
				let subject = subjects.find(x => {
					return x.subjectId == s.subjectId;
				})
				if (subject == null) {
					subjects.push(s);
				}
			})

			this.setState({ loading: false, subjects });
		})
	}

	toggleModal = (modalType) => {
		this.setState({ modal: !this.state.modal, modalType });
	}

	renderSubjectRoleRow = (subject) => {
		const activeSchool = getActiveSchool(this.props.currentUser.id);
		const user = new User(this.props.currentUser);

		return (
			<RowItem key={subject.subjectCode} style={{ cursor: 'pointer' }} onClick={() => { this.userSchoolAdmin(activeSchool) ? this.onEditSubjectTeacher(subject) : null }} >
				<RowCell title={this.props.translate('name')}>
					{subject.title}
				</RowCell>
				<RowCell title={this.props.translate('subject-code')}>
					{subject.subjectCode}
				</RowCell>
				<RowCell title={this.props.translate('school')}>
					{this.props.translate(subject.typeOfSchooling)}
				</RowCell>
				<RowCell title={this.props.translate('teacher')}>
					{subject.nrOfSubjectTeachers}
				</RowCell>
			</RowItem>
		);
	}

	onEditSubjectTeacher = (subject) => {
		this.setState({ subject });
		this.toggleModal('SubjectTeachersForm');
	}

	onSubjectTeachersSubmit = (subject, subjectTeachers) => {
		const activeSchool = getActiveSchool(this.props.currentUser.id);
		let promises = [];
		subject.subjectTeachersUserId.forEach(uid => {
			let teacherIndex = subjectTeachers.findIndex(teacher => {
				return uid == teacher.userId;
			});
			if (teacherIndex == -1) {
				promises.push(this.props.deleteTeacherRole(uid, subject.subjectId, activeSchool));
			}
		});

		subjectTeachers.forEach(teacher => {
			let teacherIndex = subject.subjectTeachersUserId.findIndex(uid => {
				return uid == teacher.userId;
			});
			if (teacherIndex == -1) {
				let subjectTeacher = {
					UserId: teacher.userId,
					SubjectId: subject.subjectId,
					SubjectCode: subject.subjectCode,
					typeOfSchooling: subject.typeOfSchooling,
					Title: subject.title,
					SchoolId: activeSchool
				}
				promises.push(this.props.addTeacherRole(teacher.userId, subjectTeacher));
			}
		});

		let noPermissionSubjectsIndex = this.state.noPermissionSubjects.findIndex(n => {
			return n.subjectId == subject.subjectId;
		})

		if (noPermissionSubjectsIndex > -1) {
			let noPermissionSubjects = this.state.noPermissionSubjects;
			noPermissionSubjects.splice(noPermissionSubjectsIndex, 1);
			this.setState({ noPermissionSubjects });
		}

		Promise.all(promises).then(() => {
			this.getActiveSchoolTeachers();
			this.setState({ modal: false });
		});
	}

	onTeacherRoleSubmit = (subjects) => {
		let noPermissionSubjects = this.state.noPermissionSubjects;
		let stateSubjects = this.state.subjects;

		subjects.forEach(s => {
			let subject = stateSubjects.find(x => {
				return x.subjectId == s.subjectId;
			})
			if (subject == null) {
				subject = {
					title: s.title,
					subjectId: s.id,
					subjectCode: s.subjectCode,
					subjectTeachersUserId: [],
					subjectTeachers: [],
					typeOfSchooling: s.typeOfSchooling,
					nrOfSubjectTeachers: 0
				}
				stateSubjects.push(subject);
			}
			subject = noPermissionSubjects.find(x => {
				return x.subjectId == s.id;
			})
			if (subject == null) {
				subject = {
					title: s.title,
					subjectId: s.id,
					subjectCode: s.subjectCode,
					subjectTeachersUserId: [],
					subjectTeachers: [],
					typeOfSchooling: s.typeOfSchooling,
					nrOfSubjectTeachers: 0
				}
				noPermissionSubjects.push(subject);
			}
		})
		this.setState({ modal: false, stateSubjects, noPermissionSubjects });
	}

	render() {
		const user = new User(this.props.currentUser);
		const activeSchool = getActiveSchool(this.props.currentUser.id);

		if (this.state.loading) {
			return (
				<div className="temp-subject-administration">
					<Spinner center />
				</div>
			)
		}

		return (
			<div className="single-section">
				<Modal onClose={this.toggleModal} overridePrompt title={this.state.modalType == "SubjectTeachersForm" ? this.props.translate('add-subjectteacher') + " " + this.state.subject.title : this.props.translate('add-subject')} isOpen={this.state.modal} type="small">
					{this.state.modalType == "SubjectTeachersForm" ?
						<SubjectTeachersForm
							subject={this.state.subject}
							onSubjectTeachersSubmit={this.onSubjectTeachersSubmit}
						/>
						:
						<TeacherRoleForm
							previousTeacherRoles={this.props.subjectTeacher}
							onTeacherRoleSubmit={this.onTeacherRoleSubmit}
						/>}
				</Modal>

				{this.state.loading ? <Spinner></Spinner> : <DataList
					data={this.state.subjects}
					renderRow={this.renderSubjectRoleRow}
					emptyData={() => (
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					)}
				/>}

				{user.isAdministrator() ?
					<Button style={{ marginTop: '20px', float: 'right' }} type="secondary" onClick={() => this.toggleModal('AddSubjectForm')}>
						<Icon name="Plus" /> {this.props.translate("add-subject")}
					</Button>
					: null}
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		users: state.user.info,
		school: state.School.active,
		subjectTeacher: state.School.subjectTeacher
	};
}

export default connect(mapStateToProps, {
	getSchoolSubjectTeachers,
	deleteTeacherRole,
	addTeacherRole,
	getUserInfo
})(Subjects);