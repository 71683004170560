import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

class Select extends Component {
	constructor(props) {
		super(props);
	}

	Options = () => {
		if (this.props.dataType === 'assignmentTypes') {
			return (
				this.props.options.map((item, i) => (
					<option key={item.id} value={item.id}>{item.value}</option>
				))
			);
		} else if (this.props.dataType == 'assignment') {
			return this.props.status.assignmentStatus.map((item, i) => {
				if (item.selectable) {
					return (
						<option key={item.id} value={item.id}>{item.value}</option>
					);
				}
			});
		} else if (this.props.dataType == 'assignmentStatus') {
			return this.props.options.map((item, i) => {
				if (item.selectable) {
					return (
						<option key={item.id} value={item.id}>{item.value}</option>
					);
				}
			});
		} else if (this.props.dataType == 'plan') {
			return this.props.options.map((item, i) => {
				return (
					<option key={item.id} value={item.id}>{item.value}</option>
				);
			});
		} else if (this.props.dataType == 'assignment-plan') {
			return this.props.options.map((item, i) => {
				return (
					<option key={item.id} value={item.id}>{item.title}</option>
				);
			});
		}
		else if (this.props.dataType == 'groups-selector') {
			return this.props.options.map((item, i) => (
				<option key={item.id} value={item.id}>{item.title}</option>
			));
		}

		return (
			this.props.options.map((item, i) => (
				<option key={item.value} value={item.value}>{item.text}</option>
			))
		);
	}

	placeholderOption = () => {
		if (this.props.placeholder) {
			let selectValue = '0';

			if (this.props.placeholderValue) {
				selectValue = this.props.placeholderValue;
			} else if (this.props.placeholderValue === '') {
				selectValue = this.props.placeholderValue;
			}

			if (this.props.placeholder != null && this.props.placeholder != '' && !this.props.placeholder) {
				return <option value={selectValue}>
					{this.props.placeholder}
				</option>;
			}

			return <option value={selectValue}>
				{this.props.translate('select-an-alternative')}
			</option>;
		}
	}

	onChange = (event) => {
		if (this.props.onChange != null && typeof this.props.onChange == 'function') {
			this.props.onChange(event);
		}

		if (this.props.input != null) {
			if (this.props.input.onChange != null && typeof this.props.input.onChange == 'function') {
				this.props.input.onChange(event.target.value);
			}
		}
	}

	render() {
		const { label, name, id, input, selectedValue, disabled, meta } = this.props;

		return (
			<div className="select">
				{label ? <label htmlFor={name}>{label}</label> : ''}
				<select
					name={name}
					id={id ? id : name}
					disabled={disabled}
					defaultValue={selectedValue} {...input}
					onChange={this.onChange}
				>
					{this.placeholderOption()}
					{this.Options()}
				</select>

				{typeof (meta) !== 'undefined' ?
					<div>
						{meta.touched && ((meta.error &&
							<span style={{ marginTop: '1rem' }}>{meta.error}</span>) || (meta.warning &&
								<span style={{ marginTop: '1rem' }}>{meta.warning}</span>))}
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		status: state.status.status,
	};
}


export default connect(mapStateToProps)(Select);
