import React, { useEffect, useRef } from 'react';

const FlexibleTextArea = (props) => {
	const textField = useRef(null);
	const maxHeight = props.maximumHeight ?? 109;

	const _calculateSize = () => {
		if (textField?.current != null) {
			/**
			 * @type {HTMLTextAreaElement} element
			 */
			const element = textField.current;
			if (props.autoGrow) {
				element.style.height = 'auto';
				element.style.height = element.scrollHeight + 2 + 'px';
				element.style.overflow = parseInt(element.style.height, 10) < maxHeight ? 'hidden' : 'auto';
			} else {
				let rows = element.textContent.split('\n').length - 1;
				if (rows < 0) {
					rows = 0;
				}
				element.style.height = 63 + (23 * rows) + "px";
			}
		}
	}

	const _onBlur = () => {
		if (textField?.current != null) {
			const element = textField.current;
			element.classList.remove('focus');
		}

		_calculateSize();

		// Animation timing
		setTimeout(() => _calculateSize(), 150);
	}

	const _onFocus = () => {
		if (textField?.current != null) {
			const element = textField.current;
			element.classList.add('focus');
		}

		_calculateSize();

		// Animation timing
		setTimeout(() => _calculateSize(), 150);
	}

	useEffect(() => {
		_calculateSize();
	}, [props.children]);

	let elementProps = { ...props };

	delete elementProps.style;
	delete elementProps.children;
	delete elementProps.input;
	delete elementProps.meta;
	delete elementProps.autoGrow;
	delete elementProps.maximumHeight;

	return (
		<textarea
			className="transition--fast"
			ref={textField}
			onKeyUp={_calculateSize}
			onBlur={_onBlur}
			onFocus={_onFocus}
			onChange={props.input?.onChange}
			value={props.input?.value}
			style={{
				resize: 'inherit',
				maxHeight: maxHeight + 'px',
				...props.style
			}}
			{...elementProps}
		>
			{props.children}
		</textarea>
	);
};

export default FlexibleTextArea;
