/**
 * @typedef {import('types').User} UserModel
 */

import api from 'lib/api';
import { http, onMsalTokenError, onMsalLoginRequired } from 'lib/msal';
import { appInsights, setEnhancedLogLevel } from 'lib/appInsights';
import getEnvironment from 'lib/env';

export const SET_USER = 'SET_USER';
export const GET_TENANT = 'GET_TENANT';
export const USER_ROLES = 'USER_ROLES';
export const CURRENT_USER = 'CURRENT_USER';
export const CURRENT_USER_BASE = 'CURRENT_USER_BASE';
export const SET_ROLE = 'SET_ROLE';
export const SET_SCHOOL = 'SET_SCHOOL';
export const SET_SCHOOL_TYPE = 'SET_SCHOOL_TYPE';
export const SEARCH_USER = 'SEARCH_USER';
export const CLEAR_SEARCH_USER = 'CLEAR_SEARCH_USER';
export const GET_USER_INFO = 'GET_USER_INFO';
export const SET_ACTIVE_TEAMS_TAB = 'SET_ACTIVE_TEAMS_TAB';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_TENANT_SCHOOLS = 'GET_TENANT_SCHOOLS';
export const USER_GET_USER_ABSENCE = 'USER_GET_USER_ABSENCE';
export const GET_USER_GUARDIAN = 'GET_USER_GUARDIAN';
export const GET_GUARDIAN_BY_EMAIL = 'GET_GUARDIAN_BY_EMAIL';
export const GET_GUARDIAN_BY_ID = 'GET_GUARDIAN_BY_ID';
export const SET_GUARDIAN_BY_ID = 'SET_GUARDIAN_BY_ID';
export const SET_USER_GUARDIAN = "SET_USER_GUARDIAN";
export const ADD_USER_GUARDIAN = "ADD_USER_GUARDIAN";
export const DELETE_USER_GUARDIAN = "ADD_USER_GUARDIAN";
export const SET_USER_AUTHORITATIVE = "SET_USER_AUTHORITATIVE";
export const GET_READER_PARAMS = 'USER_GET_IMMERSIVE_READER_PARAMETERS';
export const GET_USER_COURSES = 'GET_STUDENT_COURSES';
export const GET_USER_SUBJECTS = 'GET_STUDENT_SUBJECTS';
export const SEARCH_STAFF = 'SEARCH_STAFF';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER_FILES = 'USER_GET_USER_FILES';
export const DELETE_USER_FILE = 'USER_DELETE_USER_FILE';
export const UPDATE_USER_FILE = 'USER_UPDATE_USER_FILES';
export const LINK_STUDENT_GUARDIAN = 'LINK_STUDENT_GUARDIAN';
export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const GET_TEACHER_ROLES = 'GET_TEACHER_ROLES';
export const DELETE_TEACHER_ROLE = 'DELETE_TEACHER_ROLE';
export const ADD_TEACHER_ROLE = "ADD_TEACHER_ROLE";
export const GET_MY_MENTOR_SECTIONS = 'SECTIONS_GET_MY_USER_SECTIONS';
export const GET_USERS_WITHOUT_MENTOR_GROUPS = 'USERS_GET_USERS_WITHOUT_MENTOR_GROUPS';
export const USER_ADMIN_CHECK = 'USER_ADMIN_CHECK';
export const ADD_USER = 'ADD_USER';
export const GET_MY_SECTIONS = 'USERS_GET_MY_SECTIONS';
export const GET_ALL_SECTIONS = 'GET_ALL_SECTIONS';
export const GET_ALL_USER_SECTIONS = 'GET_ALL_USER_SECTIONS';
export const GET_USER_NOTIFICATION_SETTINGS = 'GET_USER_NOTIFICATION_SETTINGS';
export const GET_USER_ACCESSTOKEN = 'GET_USER_ACCESSTOKEN';


export function getOnBehalfOfAccessToken(id, scopes) {
	return {
		type: GET_USER_ACCESSTOKEN,
		payload: new Promise((resolve, reject) => {
			api.get(`users/${id}/access_token?scopes=${scopes}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export function addUserRole(userId, roleId) {
	return {
		type: ADD_USER_ROLE,
		payload: new Promise((resolve, reject) => {
			api.put(`users/${userId}/roles/${roleId}`).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export function deleteUserRole(userId, roleId) {
	return {
		type: DELETE_USER_ROLE,
		payload: new Promise((resolve, reject) => {
			api.delete(`users/${userId}/roles/${roleId}`).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export function setActiveTeamsTab(tab) {
	return {
		type: SET_ACTIVE_TEAMS_TAB,
		payload: tab
	}
}

export function setUser(user) {
	return {
		type: SET_USER,
		payload: user,
	};
}

export function setActiveRole(role) {
	return {
		type: SET_ROLE,
		payload: role
	}
}

export function setActiveSchool(school) {
	return {
		type: SET_SCHOOL,
		payload: school
	}
}

export function setSchoolType(type) {
	return {
		type: SET_SCHOOL_TYPE,
		payload: type
	}
}

export function getTenant() {
	return {
		type: GET_TENANT,
		payload: new Promise((resolve, reject) => {
			api.get('tenants/educationBackendBaseUrl', true).then((response) => {
				let environment = getEnvironment();
				if (environment != null && environment.type == "staging" && response.data != null) {
					http.defaults.baseURL = "https://api-swe-staging.haldor.se/api/";
				} else if (response.data != null) {
					http.defaults.baseURL = response.data;
				}
			}).then(() => {
				api.get('tenants').then((response) => {
					resolve(response.data);
				}, (error) => {
					reject(error.status);
				});
			});
		}),
	};
}

export function userAdminCheck() {
	return {
		type: USER_ADMIN_CHECK,
		payload: new Promise(resolve => {
			api.get('services/me/checkadmin').then((response) => {
				resolve(response.data);
			})
		}),
	};
}

export function getTenantSchools() {
	return {
		type: GET_TENANT_SCHOOLS,
		payload: new Promise(resolve => {
			api.get('tenants/expand').then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export function getUserRoles(userId) {
	return {
		type: USER_ROLES,
		payload: new Promise((resolve) => {
			api.get(`users/${userId}/roles`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export function getBaseMe() {
	return {
		type: CURRENT_USER_BASE,
		payload: new Promise((resolve) => {
			api.get('users/me/base').then((response) => {
				/**
				 * @type {UserModel} response.data
				 */
				resolve(response.data);
			}).catch((error) => {
				if (error.response.data != null) {
					if (error.response.data.errorCode != null) {
						if (error.response.data.errorCode == 'invalid_grant') {
							onMsalTokenError(null, error);
						}
					}
				}
			});
		}),
	};
}

export function getMe() {
	return {
		type: CURRENT_USER,
		payload: new Promise((resolve, reject) => {
			api.get('users/me').then((response) => {
				appInsights.trackTrace({ message: 'EDU022 | UserRoles' }, response.data.roles);

				/**
				 * @type {UserModel} response.data
				 */
				resolve(response.data);
			}).catch((error) => {
				if (error.response == null) {
					return false;
				}

				if (error.response.data != null) {
					if (error.response.data.errorCode != null) {
						if (error.response.data.errorCode == 'invalid_grant') {
							onMsalTokenError(null, error);
						}

						if (error.response.data.errorCode == 'interaction_required') {
							setEnhancedLogLevel(1);
							onMsalTokenError(null, error);

							appInsights.trackTrace({ message: 'EDU014 | Got interaction_required errorCode from graph' });
						}
					}

					reject(error.response);
					return false;
				}

				reject(0);
			});
		}),
	};
}

let infoIds = [];
export function getUserInfo(id, returnType = 'base', update = false) {
	return {
		type: GET_USER_INFO,
		payload: new Promise((resolve, reject) => {
			if ((infoIds.indexOf(id) == -1 && !update) || update) {
				if (infoIds.indexOf(id) == -1)
					infoIds.push(id);

				api.get(`users/${id}?returnType=${returnType}`).then((response) => {
					resolve(response.data);
				})
			} else {
				resolve(null);
			}
		}),
	};
}

export function searchUser(name, schoolId) {
	return {
		type: SEARCH_USER,
		payload: new Promise(resolve => {
			let url = `users?search=${name}&userType=student`;

			if (name == '' || name == null) {
				resolve([]);

				return true;
			}
			api.get(url).then((response) => {
				resolve(response.data);
			})
		})
	};
}

export function clearSearchUser() {
	return {
		type: CLEAR_SEARCH_USER,
		payload: null
	};
}

export function setLanguage(lcid) {
	return {
		type: SET_LANGUAGE,
		payload: new Promise(resolve => {
			api.put(`users/me/lcid/${lcid}`).then((response) => {
				resolve(response.data);
			});
		})
	};
}

export const getUserAbsence = (id, start, end) => ({
	type: USER_GET_USER_ABSENCE,
	payload: new Promise(resolve => {
		let url = `users/${id}/absence/`;
		if (start != null && end != null) {
			url = `users/${id}/absence?start=${start}&end=${end}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		})
	}),
});

export function getUserGuardian(id) {
	return {
		type: GET_USER_GUARDIAN,
		payload: new Promise((resolve, reject) => {
			api.get(`users/${id}/guardians`).then((response) => {
				resolve(response.data);
			})
		}),
	};
}

export function getUserGuardianByEmail(email) {
	return {
		type: GET_GUARDIAN_BY_EMAIL,
		payload: new Promise((resolve, reject) => {
			api.get(`users/guardians?email=${email}`).then((response) => {
				if (response.status > 300) {
					reject('error');
				}

				resolve(response.data);
			})
		}),
	};
}
export function getGuardianById(id) {
	return {
		type: GET_GUARDIAN_BY_ID,
		payload: new Promise((resolve, reject) => {
			api.get(`users/guardians/${id}`).then((response) => {
				resolve(response.data);
			})
		}),
	};
}

export function setUserGuardian(userId, guardian) {
	return {
		type: SET_USER_GUARDIAN,
		payload: new Promise((resolve, reject) => {
			api.put(`users/${userId}/guardians`, guardian).then((response) => {
				if (response.status > 300) {
					reject('error');
				}

				resolve(response.data);
			})
		}),
	}
}

export function setGuardian(id, guardian) {
	return {
		type: SET_GUARDIAN_BY_ID,
		payload: new Promise((resolve, reject) => {
			api.put(`users/guardians/${id}`, guardian).then((response) => {
				resolve(response.data);
			})
		}),
	}
}

export function addUser(userItem, preOnboarding = false) {
	return {
		type: ADD_USER,
		payload: new Promise((resolve, reject) => {
			api.post(`users?preOnboarding=` + preOnboarding, userItem).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export function addUserGuardian(userId, guardian) {
	return {
		type: ADD_USER_GUARDIAN,
		payload: new Promise((resolve, reject) => {
			api.post(`users/${userId}/guardians`, guardian).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export const deleteStudentGuardian = (userId, guardianId, removeAll) => ({
	type: DELETE_USER_GUARDIAN,
	payload: new Promise(resolve => {
		api.delete(`users/${userId}/guardians/${guardianId}?removeAll=${removeAll}`).then((response) => {
			resolve(response.data);
		});
	}),
});


export const linkStudentGuardian = (userId, guardianId) => ({
	type: LINK_STUDENT_GUARDIAN,
	payload: new Promise(resolve => {
		api.put(`users/${userId}/guardians/${guardianId}`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const setUserAuthoritative = (userId, authoritative) => ({
	type: SET_USER_AUTHORITATIVE,
	payload: new Promise((resolve, reject) => {
		api.put(`users/${userId}/authoritative/${authoritative}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export function getReaderParameters() {
	return {
		type: GET_READER_PARAMS,
		payload: new Promise(async (resolve) => {
			const token = await api.get(`cognitiveServices/token`);
			const subdomain = await api.get(`cognitiveServices/subdomain`);

			resolve({
				subdomain: subdomain.data,
				token: token.data,
			});
		}),
	}
}

export const getUserCourses = (userId) => ({
	type: GET_USER_COURSES,
	payload: new Promise(resolve => {
		api.get(`users/${userId}/courses`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserSubjects = (userId) => ({
	type: GET_USER_SUBJECTS,
	payload: new Promise(resolve => {
		api.get(`users/${userId}/subjects`).then((response) => {
			resolve(response.data);
		});
	}),
})

export function updateUser(user) {
	return {
		type: UPDATE_USER,
		payload: new Promise((resolve) => {
			api.put(`users/${user.userId}`, user).then((response) => {
				resolve(response.data);
			})
		}),
	}
}

export const getTeacherRoles = (userId) => ({
	type: GET_TEACHER_ROLES,
	payload: new Promise(resolve => {
		api.get(`users/${userId}/teacherroles`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const deleteTeacherRole = (userId, SubjectCode, schoolId) => ({
	type: DELETE_TEACHER_ROLE,
	payload: new Promise(resolve => {
		api.delete(`users/${userId}/teacherroles/${SubjectCode}?schoolId=${schoolId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export function addTeacherRole(userId, subjectTeacher) {
	return {
		type: ADD_TEACHER_ROLE,
		payload: new Promise((resolve, reject) => {
			api.post(`users/${userId}/teacherroles`, subjectTeacher).then((response) => {
				resolve(response.data);
			});
		}),
	}
}

export const getUserFiles = (userId) => ({
	type: GET_USER_FILES,
	payload: new Promise(resolve => {
		api.get(`users/${userId}/files`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const updateUserFile = (userId, file) => ({
	type: UPDATE_USER_FILE,
	payload: new Promise((resolve) => {
		api.put(`users/${userId}/files/${file.id}`, file).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteUserFile = (userId, fileId) => ({
	type: DELETE_USER_FILE,
	payload: new Promise((resolve, reject) => {
		api.delete(`users/${userId}/files/${fileId}`).then((response) => {
			resolve(fileId);
		}).catch(() => {
			reject(0);
		});
	}),
});

export const getAllUserSections = (userId, parameters) => ({
	type: GET_ALL_USER_SECTIONS,
	payload: new Promise((resolve) => {
		let url = '';
		let urlParams = {
			pageSize: 20,
			pageIndex: 1,
			filter: '',
			sortBy: 'TITLE_DESC',

			...parameters,
		};

		for (const key in urlParams) {
			url += `&${key}=${urlParams[key]}`;
		}

		api.get(`users/${userId}/sections?_=${url}`).then((response) => {
			resolve({
				documents: response.data,
				...urlParams,
			});
		});
	})
});

export const getMyMentorSections = () => ({
	type: GET_MY_MENTOR_SECTIONS,
	payload: new Promise(resolve => {
		api.get(`users/me/sections?type=MENTOR_GROUP`).then((response) => {
			resolve(response.data);
		})
	}),
})

export const getUsersWithoutMentorGroups = () => ({
	type: GET_USERS_WITHOUT_MENTOR_GROUPS,
	payload: new Promise((resolve) => {
		api.get(`users/withoutmentorgroups`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getMySections = (parameters) => ({
	type: GET_MY_SECTIONS,
	payload: new Promise((resolve) => {
		let url = '';
		let urlParams = {
			pageSize: 20,
			pageIndex: 1,
			filter: '',
			sortBy: 'TITLE_DESC',

			...parameters
		};

		for (const key in urlParams) {
			url += `&${key}=${urlParams[key]}`;
		}

		api.get(`users/me/sections?_=${url}`).then((response) => {
			resolve({
				documents: response.data,
				...urlParams
			});
		});
	}),
});

export const getAllSections = (parameters) => ({
	type: GET_ALL_SECTIONS,
	payload: new Promise((resolve) => {
		let url = '';
		let urlParams = {
			pageSize: 20,
			pageIndex: 1,
			filter: '',
			sortBy: 'TITLE_DESC',

			...parameters,
		};

		for (const key in urlParams) {
			url += `&${key}=${urlParams[key]}`;
		}

		api.get(`schools/sections?_=${url}`).then((response) => {
			resolve({
				documents: response.data,
				...urlParams,
			});
		});
	})
});

export const getUserNotificationSettings = () => ({
	type: GET_USER_NOTIFICATION_SETTINGS,
	payload: new Promise((resolve) => {
		api.get(`users/notificationsettings`).then((response) => {
			resolve(response.data);
		});
	}),
});