import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '@haldor/ui';
//    onClick={(event) => toggleList(event)}
const CustomSelector = (props) => {
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const buttonRef = useRef(null);
	const handleOutsideClick = (event) => {
		if (
			ref?.current &&
			!ref.current?.contains(event.target) &&
			event.path != null && event.path[0] !== buttonRef.current
		) {
			setOpen(false);
		}
	};
	useEffect(() => {
		document.addEventListener('click', handleOutsideClick, true);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	const buttonAction = (e) => {
		e.preventDefault();
		if (!open) {
			props.fetchLesson();
		}
		setOpen((prev) => !prev);
	};
	return (
		<div className='selectorForm'>
			<button
				ref={buttonRef}
				className={open ? 'c--button dropdown active' : 'c--button dropdown'}
				onClick={(event) => buttonAction(event)}
			>
				{props.label}
				<Icon name='Chevron_Down' style={{ marginLeft: '2rem' }} />
			</button>

			{open ? (
				<div className='selectorExpendable' ref={ref}>
					{props.children}
				</div>
			) : null}
		</div>
	);
};

export default CustomSelector;
