import SearchCourses from 'containers/Forms/Courses/SearchCourses';
import Modal from 'containers/Modals/Modal';
import { getRootUrl } from 'helpers/url';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ColorLabel, ColorLabelContainer, DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Button, Icon } from '@haldor/ui';
import User from '_class/User';
import './CourseSelect.scss';
import { isMicrosoftTeams } from 'helpers/teams';

class CourseSelectList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			courses: [],
			searchCourses: false,
		};
	}

	onToggleSearch = (e) => {
		e.preventDefault();
		this.setState({ searchCourses: !this.state.searchCourses });
	}

	componentDidMount = () => {
		if (this.props.section != null && this.props.section.courses != null && this.props.section.courses.length > 0) {
			const courses = [];
			this.props.section.courses.forEach(course => {
				if (this.props.section.courseID != course.id) {
					var schoolType = this.props.translate(course.typeOfSchooling);

					courses.push({
						id: course.id,
						title: `${course.title} ${course.year != null ? course.year : ''} (${schoolType})`,
						colorCode: course.colorCode,
						status: course.status,
						type: course.type,
					});
				}
			});

			this.setState({ courses });
		}
	}

	onAddCourses = (newCourses = []) => {
		let { courses } = this.state;

		newCourses.forEach((course) => {
			const schoolType = this.props.translate(course.typeOfSchooling);

			courses.push({
				id: course.id,
				title: `${course.title} ${course.year != null ? course.year : ''} (${schoolType})`,
				colorCode: course.colorCode,
				type: course.type,
			});
		});

		this.setState({ courses, searchCourses: false }, () => {
			this.onChange();
		});
	}

	onRemoveCourse = (course) => {
		const { courses } = this.state;
		let courseIndex = courses.findIndex(co => {
			return co.id == course.id;
		});

		if (courseIndex > -1) {
			courses.splice(courseIndex, 1);

			this.setState({ courses }, () => {
				this.onChange();
			});
		}
	}

	onChange = () => {
		if (this.props.onChange) {
			this.props.onChange(this.state.courses);
		}
	}

	/* Render methods */
	renderCourse = (course) => {
		let target = null;
		if (course.type != 'PREDEFINED_COURSE' && isMicrosoftTeams()) {
			target = `${getRootUrl()}courses/create/${course.id}`;
		} else if (course.type != 'PREDEFINED_COURSE') {
			target = `${getRootUrl()}course/${course.id}`;
		}

		return <RowItem key={'selected-' + course.id} target={target}>
			<RowCell>
				<ColorLabelContainer>
					<ColorLabel
						content={course.title}
						color={course.colorCode}
					/>
				</ColorLabelContainer>
			</RowCell>

			<RowCell shrink>
				{course.status == 'COURSE_DRAFT' ?
					<span className="draft-badge">
						{this.props.translate('Draft')}
					</span>
					: null}
			</RowCell>

			<RowCell shrink>
				<Button type="secondary" onClick={e => { e.preventDefault(); this.onRemoveCourse(course) }}>
					{this.props.translate('Remove')}
				</Button>
			</RowCell>
		</RowItem>
	}

	render() {
		const user = new User(this.props.currentUser);

		return (
			<div className="course-select">
				<Modal
					onClose={() => this.setState({ searchCourses: false })}
					isOpen={this.state.searchCourses}
					title={this.props.translate('Add curriculums')}
					type="small"
				>
					<SearchCourses
						onSubmit={this.onAddCourses}
					/>
				</Modal>

				<div style={{ marginTop: '1.25rem' }} />

				<DataList
					title={this.props.translate('courses')}
					data={this.state.courses}
					renderRow={this.renderCourse}
				/>

				<div style={{ marginTop: '1.25rem' }} />

				<Button type="secondary" onClick={this.onToggleSearch}>
					<Icon name="Plus" /> {this.props.translate('Add curriculums')}
				</Button>

				{isMicrosoftTeams() && getRootUrl() == '/haldor-tab/' && user.isCourseAdministrator() ?
					<Link style={{ marginLeft: '1rem' }} to={getRootUrl() + "courses/create"}>
						<Button type="secondary">
							<Icon name="Plus" /> {this.props.translate('create-course')}
						</Button>
					</Link>
					: null}

			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
})(CourseSelectList);
