import api from 'lib/api';

export const GET_STUDENT_ASSESSOR_ASSESSMENT = 'GET_STUDENT_ASSESSOR_ASSESSMENT';
export const GET_STUDENT_ASSESSOR_ASSESSMENTS = 'GET_STUDENT_ASSESSOR_ASSESSMENTS';
export const CREATE_STUDENT_ASSESSMENTS = 'CREATE_STUDENT_ASSESSMENTS';
export const UPDATE_STUDENT_ASSESSMENT = 'UPDATE_STUDENT_ASSESSMENT';
export const DELETE_STUDENT_ASSESSMENT = 'DELETE_STUDENT_ASSESSMENT';

export const getStudentAssessment = (id, userId, callback) => ({
	type: GET_STUDENT_ASSESSOR_ASSESSMENT,
	payload: new Promise((resolve) => {
		api.get(`assignments/${id}/studentassessments/${userId}`).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const getStudentAssessments = (id, callback) => ({
	type: GET_STUDENT_ASSESSOR_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/${id}/studentassessments`).then((response) => {
			if (response.status == 204) {
				resolve(null);
			} else {
				resolve(response.data);
			}

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const createStudentAssessments = (id, assessment, callback) => ({
	type: CREATE_STUDENT_ASSESSMENTS,
	payload: new Promise((resolve) => {
		const endpoint = `assignments/${id}/studentassessment`;
		api.post(endpoint, assessment).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});


export const updateStudentAssessment = (id, assessment, callback) => ({
	type: UPDATE_STUDENT_ASSESSMENT,
	payload: new Promise((resolve) => {
		const endpoint = `assignments/${id}/studentassessment`;
		api.put(endpoint, assessment).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});


export const deletStudentAssessment = (data, id, callback) => ({
	type: DELETE_STUDENT_ASSESSMENT,
	payload: new Promise((resolve) => {
		api.delete(`assignments/${id}/studentassessment`, data).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});