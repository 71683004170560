import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Arrow_Down } from 'UI/Icons';
import './Dropdown.scss';


class DropdownMenu extends Component {

	/* Setup */
	constructor(props) {
		super(props);

		this.state = {
			open: false,
		};

		this.event = null;
		this.id = 'dropdown-' + Math.floor(Math.random() * 100);
	}

	/* Component lifecycle methods */
	componentDidMount = () => {
		document.querySelector('body').addEventListener('click', this.onBodyClick);
	}

	componentWillUnmount = () => {
		document.querySelector('body').removeEventListener('click', this.onBodyClick);
	}

	onChange = () => {
		// Every time we update open
		if (this.props.onChange) {
			this.props.onChange(this.state.open);
		}
	}

	/* User events */
	onClick = (event) => {
		this.setState({ open: !this.state.open }, () => {
			this.onChange();
		});
	}

	onBodyClick = (event) => {
		const classList = event.target.classList;
		const parentClassList = event.target.parentElement.classList;

		let closest = event.target.closest('.dropdown');
		if (closest != null && closest.id != this.id) {
			this.setState({ open: false }, () => {
				this.onChange();
			});
			return event;
		}

		if (closest != null && closest.id == this.id) {
			return event;
		}

		if (classList.contains('dropdown') || classList.contains('drop-down') || classList.contains('drop-down-icon')) {
			return event;
		}

		if (parentClassList.contains('dropdown') || parentClassList.contains('drop-down') || parentClassList.contains('drop-down-icon')) {
			return event;
		}

		if (this.state.open) {
			this.setState({ open: false }, () => {
				this.onChange();
			});
		}

		return event;
	}

	menuContainerClick = (event) => {
		this.setState({ open: !this.state.open }, () => {
			this.onChange();
		});
	}


	/* Render methods */
	render() {
		return (
			<div className="dropdown dropdown-left" id={this.id}>
				<div
					id="usermenubutton"
					aria-haspopup="true"
					aria-expanded={this.state.open}
					className="drop-down school-display-name user-display-name"
					onClick={this.onClick}
				>
					<div className="drop-down-icon">
						{this.props.icon}

						{this.props.badge ?
							<div className="badge">
								<span>{this.props.badge}</span>
							</div>
							: null}
					</div>

					{this.props.text != null ?
						<span className="name-text">
							{this.props.text}
						</span>
						: null}

					<Arrow_Down />
				</div>

				<div
					aria-labelledby="usermenubutton"
					className="dropdown-menu"
					style={this.state.open ? { left: 'auto', right: 0 } : { display: 'none' }}
					onClick={this.menuContainerClick}
				>
					{this.props.menuTitle != null ?
						<h4>{this.props.menuTitle}</h4>
						: null}

					{this.props.children}
				</div>
			</div>
		);
	}

}

export const DropdownItem = (props) => {
	if (props.divider) {
		return <div className="divider"></div>
	}

	if (props.target != null && props.target != '') {
		return (
			<Link className="dropdown-item" to={props.target}>
				<div className="dropdown-menu-container">
					<div className="dropdown-menu-item" to={props.target}>
						<span>
							{props.children}
						</span>
					</div>
				</div>
			</Link>
		);
	}

	return (
		<div className="dropdown-item" onClick={props.onClick}>
			<div className="dropdown-menu-container">
				<div className="dropdown-menu-item">
					<span>
						{props.children}
					</span>
				</div>
			</div>
		</div>
	);
}

export default DropdownMenu;
