import {
	GET_MEETING_TIME_BLOCKS,
	CREATE_MEETING_TIME_BLOCKS,
	UPDATE_MEETING_TIME_BLOCKS,
	UPDATE_MEETING_TIME_BLOCK_SLOT,
	DELETE_MEETING_TIME_BLOCKS,
} from '../actions/timeblocks';
import Moment from 'moment';

const INITIAL_STATE = {
	meetingTimeBlocks: [],
	meetingTimeBlockSlots: [],
	meetingTimeBlockSlot: {}
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_MEETING_TIME_BLOCKS:
			return { ...state, meetingTimeBlocks: action.payload.sort((a, b) => new Moment(a.startTime).unix() - new Moment(b.startTime).unix()) };
		case CREATE_MEETING_TIME_BLOCKS:
			return { ...state, meetingTimeBlocks: [...state.meetingTimeBlocks, ...action.payload].sort((a, b) => new Moment(a.startTime).unix() - new Moment(b.startTime).unix()) };
		case UPDATE_MEETING_TIME_BLOCKS:
			return { ...state, meetingTimeBlocks: [...state.meetingTimeBlocks.filter(t => t.id != action.payload.id)].sort((a, b) => new Moment(a.startTime).unix() - new Moment(b.startTime).unix()) };
		case UPDATE_MEETING_TIME_BLOCK_SLOT:
			return { ...state, meetingTimeBlockSlot: action.payload };
		case DELETE_MEETING_TIME_BLOCKS:
			return { ...state, meetingTimeBlocks: [...state.meetingTimeBlocks.filter(t => t.id != action.payload)].sort((a, b) => new Moment(a.startTime).unix() - new Moment(b.startTime).unix()) };
		default:
			return state;
	}
}
