import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import User from '_class/User';
import Moment from 'moment';

import { Spinner } from 'UI';
import { PhoneInput } from 'UI/Inputs';
import { Flex, Button, Icon, TooltipMenu } from '@haldor/ui';

import './_studentDetailsForm.scss';

class StudentDetailsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStudent: this.props.currentStudent,
			currentGuadians: [],
			loading: false
		}
	}

	componentDidMount = () => {
		this.setState({ currentGuadians: [...this.props.guardians], currentStudent: this.props.currentStudent });
	}

	submit = (values) => {
		values.guardians = this.state.currentGuadians;
		values.authoritative = this.state.currentStudent.authoritative;

		if (values.mobilePhone != null && values.mobilePhone != '') {
			values.mobilePhone = '+' + values.mobilePhone;
			values.mobilePhone = parsePhoneNumber(values.mobilePhone).format('E.164');
		}

		this.setState({ loading: true });
		this.props.onModalSave(values);
	}

	onCancel = () => {
		this.props.cancelModal();
	}

	changeStudentPermissionLevel = (status) => {
		let currentStudent = this.state.currentStudent;
		currentStudent.authoritative = status;
		this.setState({ currentStudent: currentStudent });
	}

	onGuardianStatusChange = (guardianId, status) => {
		let guardians = this.state.currentGuadians;
		let guardianIndex = guardians.findIndex(g => g.id == guardianId);
		guardians[guardianIndex].active = status;
		guardians[guardianIndex].linkGuardianStudents[0].active = status;
		this.setState({ currentGuadians: guardians });
	}

	validatePhone = (value) => {
		if (value == null || value == '') {
			return undefined;
		}

		if (value.length < 5) {
			return this.props.translate('Enter a valid phone number');
		}

		const number = '+' + value;
		if (isValidPhoneNumber(number)) {
			return undefined;
		}

		return this.props.translate('Enter a valid phone number');
	}

	validatePID = value => {
		if (value != null && value != '') {
			let dateOfBirth = "";
			var length = value.toString().length;
			/** en-us validering **/
			if (length > 6 && (value.toString().charAt(6) == "-" || value.toString().charAt(6) == "A")) {
				dateOfBirth = value.toString().substring(0, 6);
				if (value.toString().charAt(6) == "-") {
					dateOfBirth = dateOfBirth.slice(0, 4) + "19" + dateOfBirth.substring(4);
				} else {
					dateOfBirth = dateOfBirth.slice(0, 4) + "20" + dateOfBirth.substring(4);
				}
				if (Moment(dateOfBirth, "DDMMYYYY", true).isValid()) {
					return null;
				}
			}

			/** sv-se nb-no da-dk validering **/
			if (length > 7) {
				dateOfBirth = value.toString().substring(0, 8);
				/** sv-se validering **/
				if (Moment(dateOfBirth, "YYYYMMDD", true).isValid()) {
					return null;
				}
				/** nb-no da-dk validering **/
				if (Moment(dateOfBirth, "DDMMYYYY", true).isValid()) {
					return null;
				}
			}

			return this.props.translate('pid-field-validation');
		}
	}

	render() {
		const { handleSubmit, submitting, valid, initialValues } = this.props;
		const country = this.props.tenant.lcid.slice(-2);
		const user = new User(this.props.currentUser);

		if (this.state.loading) {
			return <div className="userdetails edit-form">
				<Spinner center />
			</div>
		}

		return (
			<div className="userdetails edit-form">
				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<label>{this.props.translate('phone')}</label>

						<Field
							name="mobilePhone"
							type="text"
							component={PhoneInput}
							countryCode={country}
							validate={this.validatePhone}
						/>
					</div>

					<div className="form-row">
						<label>{this.props.translate('e-mail')}</label>

						<Field
							name="email"
							type="text"
							component="input"
							disabled={true}
						/>
					</div>

					<div className="form-row">
						<label>{this.props.translate('street-address')}</label>

						<Field
							name="street-address"
							type="text"
							component="input"
							disabled={true}
						/>
					</div>

					<div className="form-row">
						<div style={{ float: "left", width: "35%", paddingRight: "1.75em" }}>
							<label>{this.props.translate('zip-code')}</label>

							<Field
								name="zip-code"
								type="text"
								component="input"
								disabled={true}
							/>
						</div>

						<div style={{ float: "left", width: "65%" }}>
							<label>{this.props.translate('address')}</label>

							<Field
								name="address"
								type="text"
								component="input"
								disabled={true}
							/>
						</div>

						<div className="clearfix"></div>
					</div>

					<div className="form-row">
						<label>{this.props.translate('pid')}</label>

						<Field
							name="pid"
							type="text"
							component="input"
							placeholder={this.props.translate('date-format')}
							validate={this.validatePID}
						/>
					</div>

					{user.isAdministrator() ?
						<div className="form-row">
							<label>{this.props.translate('permissions-as-guardian')}</label>
							<Flex>
								<label style={{ marginTop: "0px", width: "80%" }}>
									{this.props.currentStudent.firstName + " " + this.props.currentStudent.lastName + " (" + this.props.translate('student') + ")"}
								</label>

								<div className="studentPermissionsTooltip">
									<TooltipMenu
										trigger={this.state.currentStudent.authoritative ?
											<div>{this.props.translate('active')}<Icon name="Chevron" /></div>
											: <div>{this.props.translate('inactive')}<Icon name="Chevron" /></div>}
										id="studentPermissionsTooltip"
									>
										<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.changeStudentPermissionLevel(!initialValues.authoritative) }}>
											{this.state.currentStudent.authoritative ?
												this.props.translate('inactive')
												: this.props.translate('active')}
										</TooltipMenu.Item>
									</TooltipMenu>

									<span className="tooltiptext">
										{this.state.currentStudent.authoritative ?
											this.props.translate('permissions-authoritative-description')
											: this.props.translate('permissions-non-authoritative-description')}
									</span>
								</div>
							</Flex>
						</div>
						: null}

					{user.isAdministrator() ?
						<div className="form-row">
							{this.state.currentGuadians.map(guardian => {
								return (
									<Flex key={"guardianDiv-" + guardian.id}>
										<label style={{ marginTop: "0px", width: "80%" }}>
											{guardian.firstName + " " + (guardian.lastName != null ? guardian.lastName : null)}
										</label>

										<TooltipMenu
											trigger={guardian.linkGuardianStudents[0].active ? <div>{this.props.translate('active')}<Icon name="Chevron" /></div> : <div>{this.props.translate('inactive')}<Icon name="Chevron" /></div>}
											id={"guardianPermissionsTooltip" + guardian.id}
										>
											<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.onGuardianStatusChange(guardian.id, !guardian.linkGuardianStudents[0].active) }}>
												{guardian.linkGuardianStudents[0].active ? this.props.translate('inactive') : this.props.translate('active')}
											</TooltipMenu.Item>
										</TooltipMenu>
									</Flex>
								)
							})
							}
						</div>
						: null}

					<div className="form-row submit" style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
						<Button disabled={!valid || submitting}>
							{this.props.translate('save')}
						</Button>

						<div className="align-right">
							<Button type="secondary" onClick={this.onCancel}>
								{this.props.translate('Cancel')}
							</Button>
						</div>

						<div className="clearfix"></div>
					</div>
				</form>
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		initialValues: ownProps.currentStudent,
		guardians: state.user.guardians,
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps)(reduxForm({
	form: 'StudentDetailForm',
	destroyOnUnmount: true,
})(StudentDetailsForm));