import api from 'lib/api';

export const CREATE_NOTE = 'NOTES_CREATE_NEW_NOTE';
export const UPDATE_NOTE = 'NOTES_UPDATE_NOTE';
export const SET_ACTIVE_NOTE = 'NOTES_SET_ACTIVE_NOTE';
export const RESET_ACTIVE_NOTE = 'NOTES_RESET_ACTIVE_NOTE';
export const GET_NOTES_BY_REFERENCE = 'NOTES_GET_NOTES_BY_REFERENCE';
export const GET_MENTOR_NOTES_BY_REFERENCE = 'GET_MENTOR_NOTES_BY_REFERENCE';
export const REMOVE_NOTE_BY_ID = 'NOTES_REMOVE_NOTE_BY_ID';

export const createNote = (note) => ({
	type: CREATE_NOTE,
	payload: new Promise(resolve => {
		api.post(`notes`, note).then((response) => {
			let newNote = {
				id: response.data,
				...note,
			}

			resolve(newNote);
		});
	}),
});

export const updateNote = (note) => ({
	type: UPDATE_NOTE,
	payload: new Promise(resolve => {
		api.put(`notes`, note).then((data) => {
			resolve(data);
		});
	}),
});

export const setActiveNote = (note) => ({
	type: SET_ACTIVE_NOTE,
	payload: note,
});

export const resetActiveNote = () => ({
	type: RESET_ACTIVE_NOTE,
	payload: true,
});

export const getNotesByReference = (id, type) => ({
	type: GET_NOTES_BY_REFERENCE,
	payload: new Promise(resolve => {
		api.get(`notes/reference/${id}/type/${type}`).then(response => {
			resolve(response.data);
		});
	}),
});

export const getMentorNotesByReference = (id, type) => ({
	type: GET_MENTOR_NOTES_BY_REFERENCE,
	payload: new Promise(resolve => {
		api.get(`notes/reference/${id}/type/${type}`).then(response => {
			resolve(response.data);
		});
	}),
});

export const removeNote = (id) => ({
	type: REMOVE_NOTE_BY_ID,
	payload: new Promise(resolve => {
		api.delete(`notes/${id}`).then((response) => {
			resolve(id);
		});
	}),
});
