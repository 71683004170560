import AssessmentBlock from '../AssessmentBlock';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';

class TeacherAssessmentBlock extends AssessmentBlock {

	constructor(props) {
		super(props);
		this.name = 'Haldor.Blocks.AssessmentBlock';
		this.block_type = 'expandable_block';
		this.assessmentType = ASSESSMENT_TYPES.TEACHER;
	}

	editorBlockWasAdded = () => {
		const editor = this.api.getEditor();
		const translate = editor.getProp('translate');
		let editMode = false;

		if (this.resources.length == 0) {
			setTimeout(() => {
				this.addResource('assessmenttype', {
					'@odata.type': 'assessmenttype',
					'value': ASSESSMENT_TYPES.TEACHER,
				});

				this.addResource(this.PART_RESOURCE_TYPE, {
					status: 'Started',
					referenceId: '0',
					referenceType: 'assessmentonlyfeedback',
					assessmentBlockPartRows: [],
					value: null,
					permissions: [
						{
							referenceType: 'ASSESSMENTFEEDBACK',
							target: 'GUARDIAN',
							level: 'READ'
						},
						{
							referenceType: 'ASSESSMENTFEEDBACK',
							target: 'STUDENT',
							level: 'READ'
						}
					]
				});
			}, 500);
		}

		const teacherAssessments = editor.getBlocks().filter(block => {
			return block.assessmentType == ASSESSMENT_TYPES.TEACHER
		});

		editor.getBlocks().forEach(block => {
			editMode = block.resources?.length > 0 && block.resources.every((s) => {
				return s.id != null && s.id > 0
			});
		});

		if (editMode) {
			const foundIndex = teacherAssessments.findIndex((b) => b.field == this.field);
			this.events.onTitleChange(translate('Teacher assessment') + ' ' + (foundIndex + 1))
		} else {
			this.events.onTitleChange(translate('Teacher assessment') + ' ' + teacherAssessments.length)
		}

		const disableRows = editor.getProp('disableRows');
		if (disableRows != null && disableRows.length > 0 && this.resources != null) {
			const disabled = this.getResources().find((resource) => {
				return resource.assessmentBlockPartRows?.findIndex((row) =>
					disableRows.includes(row.id)
				) > -1;
			}) != null;

			if (disabled) {
				this.api.deregisterAction('Remove');
			}
		}
	}

	addGoalMatrix = (event) => {
		event.preventDefault();

		this.removeFeedBackOnlyResource();

		this.addResource(this.PART_RESOURCE_TYPE, {
			status: 'Started',
			referenceId: '',
			referenceType: 'assessmentGoal',
			value: {
				id: 0,
				visibleToStudent: true,
				visibleToGuardian: true,
				title: '',
				rows: [],
			},
			permissions: [
				{
					referenceType: 'ASSESSMENTBLOCKPART',
					target: 'STUDENT',
					level: 'READ',
				},
				{
					referenceType: 'ASSESSMENTBLOCKPART',
					target: 'GUARDIAN',
					level: 'READ',
				},
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'GUARDIAN',
					level: 'READ'
				},
				{
					referenceType: 'ASSESSMENTFEEDBACK',
					target: 'STUDENT',
					level: 'READ'
				}
			],
		});
	}
}

export default TeacherAssessmentBlock;