import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { Button, Tags } from '@haldor/ui';

class FileForm extends Component {

	submit = (values) => {
		return new Promise(resolve => {
			if (this.props.onSubmit != null) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					});
			} else {
				resolve(1);
			}
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onCancel != null) {
			this.props.onCancel();
		}
	}

	render() {
		const { submitting, handleSubmit, translate } = this.props;

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component file-upload">
					<div className="form-row">
						<label style={{ marginTop: 0 }}>
							{translate('tags')}
						</label>

						<Field
							component={Tags}
							name="tags"
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting} type="primary">
							{translate('save')}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(reduxForm({
	form: 'FileForm',
	destroyOnUnmount: true,
})(FileForm));