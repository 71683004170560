import React from 'react';

const Image = props => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
		<g>
			<path className="icon-image" d="M4,22.7H19.4a3.241,3.241,0,0,0,3.3-3.3V4A3.241,3.241,0,0,0,19.4.7H4A3.241,3.241,0,0,0,.7,4V19.4A3.309,3.309,0,0,0,4,22.7Z" />
			<path className="icon-image" d="M3.8,21.68H15.13L9.52,10.9Z" />
			<path className="icon-image" d="M21.58,14.18,17.62,6.7,11.9,17.48" />
			<circle className="icon-image-1" cx="1.65" cy="1.65" r="1.65" />
		</g>
	</svg>
);

export default Image;
