import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getFormTemplates } from 'actions/templates';
import { translate } from '@haldor/ui';

import Modal from 'containers/Modals/Modal';
import FormTemplate from 'containers/Forms/FormTemplate';

import { Spinner } from 'UI';
import Card, { CardsContainer, CardTitle } from 'components/Cards/Card';
import { setPageTitle, setPageActions } from 'actions/header';

class AllForms extends Component {

	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			loading: true,
		};
	}

	UNSAFE_componentWillMount = () => {
		let actions = [];

		this.props.setPageTitle(this.props.translate('form-templates'));

		actions.push({
			type: 'button',
			value: this.props.translate('Create'),
			onClick: this.toggleModal,
			icon: 'plus',
		});

		this.props.setPageActions(actions);
	}

	componentDidMount = async () => {
		await this.props.getFormTemplates();
		this.setState({ loading: false });
	}

	toggleModal = (reload) => {
		this.setState({ modal: !this.state.modal });

		if (reload) {
			this.props.getFormTemplates();
		}
	}

	render() {
		const { translate } = this.props;

		return (
			<div className="overview">
				<Modal isOpen={this.state.modal} onClose={() => this.toggleModal(false)} title={translate('create-template')}>
					<FormTemplate onAbort={this.toggleModal} />
				</Modal>

				{this.state.loading ?
					<Spinner center />
					: null}

				{this.props.templates != null && this.props.templates.length > 0 && !this.state.loading ?
					<div className="section" style={{ margin: 0, padding: 0, border: 0 }}>
						<CardsContainer>
							{this.props.templates.map((template) => {
								return (
									<Card type="group" key={template.id} id={template.id} target={`/forms/template/${template.id}`}>
										<CardTitle>{template.title}</CardTitle>
									</Card>
								);
							})}
						</CardsContainer>
					</div>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		templates: state.Templates.templates,
	};
};

export default connect(mapStateToProps, {
	setPageTitle,
	setPageActions,
	getFormTemplates,
})(AllForms);
