import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import ColorScale from '_class/ColorScale';

import '../Verdict.scss';

class VerdictWidget extends Component {

	static defaultProps = {
		displayLabel: true,
		displayLevel: true,
	};

	render() {
		if (this.props.scale == null) {
			return null;
		}

		const scale = this.props.scale;
		const verdictColors = new ColorScale(scale.levels.length).get();

		return (
			<div className="verdict verdict-widget">
				{this.props.displayLabel ?
					<div className="level-text">
						{scale.levels.map(cell => {
							if (this.props.verdict != null) {
								if (cell.id == this.props.verdict.scaleLevelId) {
									return <span key={'verdict-widget-text-' + cell.id}>
										{cell.shortLocalizationText != null ?
											cell.shortLocalizationText
											: cell.level}
									</span>
								}
							}

							return null;
						})}
					</div>
					: null}

				{this.props.displayLevel ?
					<div className="verdict-levels">
						{scale.levels.map(cell => {
							let styles = {};

							if (this.props.verdict != null) {
								if (cell.id == this.props.verdict.scaleLevelId) {
									styles = {
										backgroundColor: cell.colorCode != null ?
											cell.colorCode
											: verdictColors[cell.level],
									};
								}
							}

							return <div
								key={'verdict-widget-cell-' + cell.id}
								style={styles}
								className="level-dot"
							/>
						})}
					</div>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		scale: state.Verdicts.scale,
	};
}

export default connect(mapStateToProps, {
})(VerdictWidget);
