import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import StudentAdjustmentForm from 'containers/AdditionalAdjustments/Form/StudentAdjustmentForm';
import { Search as SearchInput } from 'UI/Inputs';
import { getActiveSchool } from 'helpers/localstorage';
import { searchSchoolUsers } from 'actions/search';
import { DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Checkbox } from '@haldor/ui';

class AssignAdditionalAdjustments extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectStudent: null
		};

		this.selectStudent = this.selectStudent.bind(this);
		this.renderStudent = this.renderStudent.bind(this);
	}

	onSubmit = (values) => {
		const { onClose } = this.props;
		return new Promise((resolve) => {

		});
	}

	runSearch = (term) => {
		if (term != null && term !== '') {
			this.setState({ term });

			Promise.all([
				this.props.searchSchoolUsers(term, 'student', ''),
			]).then(() => {
				this.setState({ loading: false, selectStudent: null });
			});
		}
	}

	onSearch = (value) => {
		this.runSearch(value.query);
	}

	selectStudent = (student) => {
		if (this.state.selectStudent?.userId == student.userId) {
			this.setState({ selectStudent: null });
		} else {
			this.setState({ selectStudent: student });
		}
	}

	renderStudent = (user) => {
		return (
			<RowItem key={user.userId}>
				<RowCell title={this.props.translate('name')}>
					<Checkbox
						value={this.state.selectStudent?.userId == user.userId}
						onChange={() => this.selectStudent(user)}
						label={user.displayName}
						disabled={this.state.selectStudent != null && this.state.selectStudent?.userId != user.userId}
					/>
				</RowCell>
			</RowItem>
		);
	}

	render = () => {
		const { translate } = this.props;

		return (
			<div className="form-container">
				<div className="form-row">
					<SearchInput
						placeholder={this.props.translate('search-student')}
						onSubmit={this.onSearch}
						lengthLimit={3}
						autofocus
					/>
				</div>

				<div className="form-row" style={{ marginTop: '2rem' }}>
					<DataList
						title={translate('students')}
						data={this.props.students}
						renderRow={this.renderStudent}
						emptyData={() => (
							<div className="color--meta text--center weight--bold">
								{translate('no-results')}
							</div>
						)}
					/>
				</div>

				{this.state.selectStudent != null ?
					<StudentAdjustmentForm
						onSubmit={this.onSubmit}
						onClose={this.props.onClose}
						userId={this.state.selectStudent.userId}
					/>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		students: state.Search.students,
	}
}

export default connect(mapStateToProps, {
	searchSchoolUsers
})(reduxForm({
	form: 'AssignAdditionalAdjustments',
	destroyOnUnmount: true,
})(AssignAdditionalAdjustments));
