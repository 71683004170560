import api from 'lib/api';

export const GET_SMS_LIST = 'SMS_GET_SMS_LIST';
export const GET_SMS = 'SMS_GET_SMS';
export const CREATE_SMS = 'SMS_CREATE_SMS';
export const UPDATE_SMS = 'SMS_UPDATE_SMS';
export const DELETE_SMS = 'SMS_DELETE_SMS';
export const GET_REMAINING_SMS = 'SMS_GET_SMS_REMAINING';
export const GET_SMS_STATISTICS = 'SMS_GET_SMS_STATISTICS';
export const GET_SMS_EDUCATION_SECTIONS = 'SMS_GET_SMS_EDUCATION_SECTIONS';
export const GET_SMS_MENTOR_SECTIONS = 'SMS_GET_SMS_MENTOR_SECTIONS';

export const getSMSList = (group, status, start, end) => ({
	type: GET_SMS_LIST,
	payload: new Promise((resolve) => {
		let url = `sms?_=1`;

		if (group != null) {
			url += `&sectionId=${group}`;
		}

		if (status != null) {
			url += `&status=${status}`;
		}

		if (start != null) {
			url += `&startDate=${start}`;
		}

		if (end != null) {
			url += `&endDate=${end}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		})
	}),
});

export const getSMS = (id) => ({
	type: GET_SMS,
	payload: new Promise((resolve) => {
		api.get(`sms/${id}`).then((response) => {
			resolve(response.data);
		})
	}),
});

export const createSMS = (values) => ({
	type: CREATE_SMS,
	payload: new Promise((resolve, reject) => {
		api.post('sms', values).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const updateSMS = (values) => ({
	type: UPDATE_SMS,
	payload: new Promise((resolve, reject) => {
		api.put(`sms/${values.id}`, values).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const deleteSMS = (id) => ({
	type: DELETE_SMS,
	payload: new Promise((resolve) => {
		api.delete(`sms/${id}`).then((response) => {
			resolve(1);
		});
	}),
})

export const getRemainingSMS = () => ({
	type: GET_REMAINING_SMS,
	payload: new Promise((resolve) => {
		api.get('sms/settings').then((response) => {
			resolve(response.data);
		});
	})
});

export const getSMSStatistics = () => ({
	type: GET_SMS_STATISTICS,
	payload: new Promise((resolve) => {
		api.get(`sms/statistics`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSMSEducationSections = () => ({
	type: GET_SMS_EDUCATION_SECTIONS,
	payload: new Promise((resolve) => {
		api.get(`sections?serviceId=HALDOR_SMS&type=EDUCATION_GROUP`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSMSMentorSections = () => ({
	type: GET_SMS_MENTOR_SECTIONS,
	payload: new Promise((resolve) => {
		api.get(`sections?serviceId=HALDOR_SMS&type=MENTOR_GROUP`).then((response) => {
			resolve(response.data);
		});
	}),
});