import {
	SET_ACTIVE_PRESCHOOL_DOCUMENT,
	UPDATE_PRESCHOOL_DOCUMENT,
	SUBMIT_PRESCHOOL_DOCUMENT,
	SET_ACTIVE_PRESCHOOL_DETAILS,
	GET_DOCUMENTS_PAGE,
	GET_SECTION_DOCUMENTS,
	CLEAR_DOCUMENT_READ_LIST,
	GET_PLAN_DOCUMENTS,
	GET_DOCUMENT_READ_LIST,
	DELETE_DOCUMENT_FROM_STORE,
} from 'actions/documents';

import { updateObjectInArray } from 'helpers/array';

const initialState = {
	planDocuments: [],
	documents: [],
	readList: null,
	activeDocument: {},
	createdDocument: {},
};

export default function Documents(state, action) {
	const { type, value } = action;

	if (typeof (state) === 'undefined') {
		return initialState;
	}

	switch (type) {
		case SUBMIT_PRESCHOOL_DOCUMENT:
			return { ...state, createdDocument: value };

		case SET_ACTIVE_PRESCHOOL_DOCUMENT:
			if (typeof (action.id) !== 'undefined' && action.id !== null) {
				let selected = state.documents.find((doc) => { return parseInt(doc.id) == parseInt(action.id) });

				if (selected !== undefined) {
					return { ...state, activeDocument: selected };
				} else {
					return { ...state, activeDocument: initialState.activeDocument };
				}
			}

			return { ...state, activeDocument: initialState.activeDocument };

		case 'get_preschool_document_image':
			if (state.activeDocument != initialState.activeDocument) {
				// If there is something in the activeDocument we will push the images to the state
				let current = state.activeDocument;

				let files = [];
				if (current.files != null) {
					files = current.files;
				}

				files.push(action.payload);
				current.files = files;

				return { ...state, activeDocument: current };
			} else {
				// If not we just return the state once more
				return state;
			}

		case DELETE_DOCUMENT_FROM_STORE:
			let combinedItems = state.documents;
			combinedItems = combinedItems.filter(t => t.id != action.payload);

			return { ...state, documents: combinedItems };
		case GET_DOCUMENT_READ_LIST:
			return { ...state, readList: action.payload };
		case CLEAR_DOCUMENT_READ_LIST:
			return { ...state, readList: initialState.readList };
		case SET_ACTIVE_PRESCHOOL_DETAILS:
			if (action.payload != null) {
				let listdocuments = null;

				if (state.posts != null) {
					listdocuments = updateObjectInArray(state.documents, action.payload);
				} else {
					listdocuments = updateObjectInArray([], action.payload);
				}

				return { ...state, activeDocument: action.payload, documents: listdocuments };
			}

			return { ...state, activePost: initialState.activePost };

		case UPDATE_PRESCHOOL_DOCUMENT:
			action.payload.ReferenceType = 'document';
			let updateDocuments = updateObjectInArray(state.combined, action.payload);

			return { ...state, documents: updateDocuments, activeDocument: action.payload };

		case GET_PLAN_DOCUMENTS:
			return { ...state, planDocuments: action.payload };

		case GET_SECTION_DOCUMENTS:
			return { ...state, sectionDocuments: action.payload };

		default:
			return state;

			break;
	}
};

function updateDocumentationInList(documentations, document) {
	let index = documentations.findIndex(t => t.id == document.id);
	documentations.splice(index, 1);
	documentations.splice(index, 0, document);
	return documentations;
}
