import React, { Component } from 'react';
import { Icon } from '@haldor/ui';

import './Collapsible.scss';

class Collapsible extends Component {

	constructor(props) {
		super(props);

		let open = false;
		if (props.open != null) {
			open = props.open;
		} else {
			if (props.defaultOpen != null) {
				open = props.defaultOpen;
			}
		}
		
		let className = null;
		if (props.className != null) {
			className = props.className;
		}

		this.state = {
			open,
			className,
		};

		this.content = null;
		this.container = null;
		this.animationLength = 270;
	}

	_setContent = (element) => this.content = element;
	_setContainer = (element) => this.container = element;

	/* Lifecycle methods */
	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.open != null) {
			if (nextProps.open != this.state.open) {
				this.toggle();
			}
		}
	}

	componentDidMount = () => {
		if (this.props.open == true || this.props.defaultOpen == true) {
			this.container.style.height = 'auto';
			this.container.style.overflow = 'visible';
		}
	}

	/* Events */
	toggle = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({ open: !this.state.open }, () => {
			if (this.state.open) {
				this.container.style.height = this.content.clientHeight + 'px';

				// Animation timing: Fire after CSS transition is completed
				setTimeout(() => {
					this.container.style.height = 'auto';
					this.container.style.overflow = 'visible';
				}, this.animationLength);
			} else {
				this.container.style.height = this.content.clientHeight + 'px';
				this.container.style.overflow = 'hidden';

				// Set height 0 to animate collapsing
				setTimeout(() => {
					this.container.style.height = '0px';
				}, 35);
			}

			if (this.props.onChange != null) {
				this.props.onChange(this.state.open);
			}
		});
	}

	/* Render methods */
	render() {
		const { open } = this.state;

		return (
			<div className={open ? 'collapsible open' : 'collapsible'}>
				{this.props.trigger != null ?
					<div className="collapsible-trigger" onClick={this.toggle}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ flex: 0 }} className={this.props.className ?? null}>
								<Icon name="Chevron" />
							</div>

							<div style={{ flex: 1 }}>
								{typeof this.props.trigger === 'function' ?
									this.props.trigger(open)
									: this.props.trigger}

								{this.props.badge != null ?
									<span className="badge">{this.props.badge}</span>
									: null}
							</div>
						</div>
					</div>
					: null}

				<div ref={this._setContainer} className="collapsible-content">
					<div ref={this._setContent}>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default Collapsible;
