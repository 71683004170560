import DateTime from '_class/DateTime';
import api from 'lib/api';

export const PUT_DATA = 'PUT_DATA';
export const ADD_ERROR = 'ADD_ERROR';
export const CREATE_TASK = 'CREATE_TASK';
export const DELETE_REQUEST = 'DELETE_REQUEST';

export const postData = (data, endpoint, callback) => ({
	type: CREATE_TASK,
	payload: new Promise((resolve) => {
		api.post(endpoint, data).then((response) => {
			resolve(response.data);
			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const putData = (endpoint, data, callback) => ({
	type: PUT_DATA,
	payload: new Promise((resolve) => {
		api.put(endpoint, data).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export function addError(message, type) {
	const errorType = type || 'warning';

	const error = {
		msg: message,
		type: errorType,
		target: 'API',
		time: new DateTime(),
	};

	console.log('running action addError payload', error);

	return {
		type: ADD_ERROR,
		payload: error,
	};
}

export const deleteRequest = (endpoint, id, callback) => ({
	type: DELETE_REQUEST,
	payload: new Promise((resolve, reject) => {
		api.delete(`${endpoint}/${id}`).then((response) => {
			if (response.status > 300) {
				reject(err);

				if (callback != null) {
					callback(null, err);
				}

				return false;
			}

			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});