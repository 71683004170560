import React from 'react';

const AssignmentTaskStatusCanDevelop = React.memo(() => {
	return (
		<svg
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<path
				d="M23.24 1.91v3.62A16 16 0 1 1 5.4 23.68H1.78A19.63 19.63 0 1 0 23.24 1.91"
				style={{
					fill: "#164cc4",
				}}
			/>
			<path
				d="M19.33 1.94A21 21 0 0 0 1.82 19.77c0 .25-.08.5-.1.75h18.9V1.76c-.43.04-.86.11-1.29.18"
				style={{
					fill: "#e2c30b",
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusCanDevelop;