import React from 'react';

import { HaldorBlockEditor } from '@haldor/ui';
import { useTranslate } from 'lib/translate';

import AddAssessmentBlockButton from './Plugins/AddAssessmentBlockButton';
import AddTeacherAssessmentBlockButton_Plugin from './Plugins/AddTeacherAssessmentBlockButton';

import PushAssessmentBlock from './Plugins/PushAssessmentBlock';
import TeacherAssessmentBlock from './Blocks/TeacherAssessmentBlock/TeacherAssessmentBlock';
import SelfAssessmentBlock from './Blocks/SelfAssessmentBlock/SelfAssessmentBlock';
import PeerReviewBlock from './Blocks/PeerReviewBlock/PeerReviewBlock';

import './_AssessmentBlock.scss';
import { ASSESSMENT_TYPES } from './AssessmentTypes';
import { ASSESSMENT_BLOCK_TYPES } from './AssessmentBlockTypes';

const BlockAssessments = (props) => {
	const translate = useTranslate();

	let plugins = [
		TeacherAssessmentBlock,
		PeerReviewBlock,
		SelfAssessmentBlock,
		PushAssessmentBlock,
	];

	if (props.multipleAssessmentOptions) {
		plugins.push(AddAssessmentBlockButton);
	}

	if (!props.multipleAssessmentOptions) {
		plugins.push(AddTeacherAssessmentBlockButton_Plugin);
	}


	if (props.input?.value != '') {
		props.input?.value?.forEach((value) => {
			if (value.resources != null && value.resources.length > 0) {
				let resource = value.resources.map(s => s.assessmentType)[0];
				switch (resource) {
					case ASSESSMENT_TYPES.PEER:
						value.type = ASSESSMENT_BLOCK_TYPES.PEER_REVIEW_BLOCK
						break;
					case ASSESSMENT_TYPES.TEACHER:
						value.type = ASSESSMENT_BLOCK_TYPES.TEACHER_ASSESSMENT_BLOCK
						break;
					case ASSESSMENT_TYPES.SELF:
						value.type = ASSESSMENT_BLOCK_TYPES.SELF_ASSESSMENT_BLOCK
						break;
					default:
						value.type = ASSESSMENT_BLOCK_TYPES.TEACHER_ASSESSMENT_BLOCK
						break;
				}
			}
		});
	}

	return <div className="assessment-block">
		<HaldorBlockEditor
			plugins={plugins}
			deregisterPlugins={[
				'Core_Haldor_Editor_PlaceholderLine',
				'Core_Haldor_Block_PushPlaceholder',
				'PlaceholderBlock',
				'Haldor.Blocks.Editor',
			]}
			translate={translate}
			{...props}
		/>
	</div>
}

BlockAssessments.defaultProps = {
	multipleAssessmentOptions: false,
};

export default BlockAssessments;