import api from 'lib/api';
import axios from 'axios'
import { http } from 'lib/msal';

export const GET_SERVICES = 'GET_SERVICES';
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES'
export const POST_CREATE_TENANT = 'POST_CREATE_TENANT';
export const POST_CREATE_TENANT_SERVICES = 'POST_CREATE_TENANT_SERVICES';
export const PUT_UPDATE_TENANT_SERVICES = 'PUT_UPDATE_TENANT_SERVICES';
export const PUT_UPDATE_TENANT_SERVICE_CONSENT = 'PUT_UPDATE_TENANT_SERVICE_CONSENT';
export const POST_INVITE_ADMIN = 'POST_INVITE_ADMIN';
export const GET_CONSENT_INFO = 'GET_CONSENT_INFO';
export const GET_ADMIN_INVITATIONS = 'GET_ADMIN_INVITATIONS';
export const GET_CONSENT_PRESENT = 'GET_CONSENT_PRESENT';
export const POST_SELECTED_PRODUCTS_NOTES = 'POST_SELECTED_PRODUCTS_NOTES';
export const SET_EMAIL_SENT_LIST = 'SET_EMAIL_SENT_LIST';
export const PUT_UPDATE_TENANT_PRODUCTS = 'PUT_UPDATE_TENANT_PRODUCTS';
export const GET_TENANT_PRODUCTS = 'GET_TENANT_PRODUCTS';
export const PUT_UPDATE_TENANT = 'PUT_UPDATE_TENANT';

export const getServices = () => ({
	type: GET_SERVICES,
	payload: new Promise((resolve) => {
		api.get(`services/products`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getTenantProducts = () => ({
	type: GET_TENANT_PRODUCTS,
	payload: new Promise((resolve) => {
		api.get(`tenants/products`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getConsentInfo = () => ({
	type: GET_CONSENT_INFO,
	payload: new Promise((resolve) => {
		api.get(`services/consents`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAdminInvitations = () => ({
	type: GET_ADMIN_INVITATIONS,
	payload: new Promise((resolve) => {
		api.get(`services/admin/invitations`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const isConsentPresent = (tenantId) => ({
	type: GET_CONSENT_PRESENT,
	payload: new Promise((resolve, reject) => {
		axios.get(`${http.defaults.baseURL}services/tenant/${tenantId}`).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const saveSelectedProductsAsNotes = (onTenantLevel = true) => ({
	type: POST_SELECTED_PRODUCTS_NOTES,
	payload: new Promise((resolve, reject) => {
		api.post(`services/products/saveAsNote?onTenantLevel=` + onTenantLevel, null).then((response) => {
			resolve(response.status);
		});
	}),
});

export const sendAdminInvitation = (recipients) => ({
	type: POST_INVITE_ADMIN,
	payload: new Promise((resolve, reject) => {
		api.post(`services/admin/invite`, recipients).then((response) => {
			resolve(response.status);
		}).catch(error => {
			if (error.response) {
				resolve(error.response.status);
			}

			resolve(500);
		});
	}),
});

export const setEmailSentList = (recipients) => ({
	type: SET_EMAIL_SENT_LIST,
	payload: recipients
});


export const createTenant = (tenant, preOnboarding = false) => ({
	type: POST_CREATE_TENANT,
	payload: new Promise((resolve) => {
		api.post(`tenants?preOnboarding=` + preOnboarding, tenant).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateTenant = (tenant) => ({
	type: PUT_UPDATE_TENANT,
	payload: new Promise((resolve) => {
		api.put(`tenants`, tenant).then((response) => {
			resolve(response.data);
		});
	}),
});


export const createTenantServices = (tenantServices) => ({
	type: POST_CREATE_TENANT_SERVICES,
	payload: new Promise((resolve) => {
		api.post(`tenants/services`, tenantServices).then((response) => {
			resolve(response.data);
		});
	}),
});


export const updateTenantServices = (tenantServices) => ({
	type: PUT_UPDATE_TENANT_SERVICES,
	payload: new Promise((resolve) => {
		api.put(`tenants/services`, tenantServices).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateTenantProducts = (products) => ({
	type: PUT_UPDATE_TENANT_PRODUCTS,
	payload: new Promise((resolve) => {
		api.put(`tenants/products`, products).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateTenantServiceConsent = (tenantServices) => ({
	type: PUT_UPDATE_TENANT_SERVICE_CONSENT,
	payload: new Promise((resolve) => {
		api.put(`tenants/service/consent`, tenantServices).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setSelectedServices = (selectedServices) => ({
	type: SET_SELECTED_SERVICES,
	payload: new Promise((resolve) => {
		resolve(selectedServices);
	}),
});
