import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { translate } from '@haldor/ui';
import swal from "sweetalert2";
import api from 'lib/api';

import { setPageTitle, setPageActions } from "actions/header";

import { getCourse, setActiveCourse } from "actions/courses";
import { getMatrix } from "actions/matrix";

import { newGuid } from "helpers/guid";

import Modal from "containers/Modals/Modal";
import CourseForm from "containers/Forms/CourseForm";
import MatrixDisplayBlock from "../Matrix/MatrixDisplay/MatrixDisplayBlock";
import PostContent from "components/Presentation/PostContent";
import SelectedCoreContent from "containers/CoreContent/Selected";
import User from '_class/User';

import { Expandable, Spinner } from "UI";
import { Block, Button, Icon } from "@haldor/ui";

class SingleCourse extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			matrixList: [],
			modalIsOpen: false,
			_ParagraphSet: []
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		this.props.getCourse(this.props.match.params.courseId).then(() => {
			const { course } = this.props;
			const { details } = this.props.course;

			let _ParagraphSet = [];
			this.props.setPageTitle(course.title);
			if (details[0] != null) {
				if (details[0].matrix != null) {
					if (details[0].matrix._ParagraphSet != null) {
						_ParagraphSet = details[0].matrix._ParagraphSet;
					}
				}
			}

			this.setState({ _ParagraphSet, loading: false });
		});
	}

	componentWillUnmount = () => {
		this.props.setActiveCourse();
	};

	renderMatrix() {
		if (this.props.course.details[0] != null) {
			if (this.props.course.details[0].matrix != null) {
				const { details } = this.props.course;

				if (!details[0].matrix.disableNotAchieved) {
					if (details[0].matrix._ParagraphSet != null) {
						details[0].matrix._ParagraphSet.forEach(paragraph => {
							paragraph.rows.forEach(row => {
								const searchNotAchieved = row.cells.find(co => {
									return co.gradeF == true;
								});

								if (searchNotAchieved == null) {
									var id = newGuid();
									var stepId = newGuid();
									var gradeF = {
										id: stepId,
										text: this.props.translate(
											"not-achieved"
										),
										gradeF: true
									};

									row.cells.unshift(gradeF);
								}
							});
						});
					}
				}

				return (
					<Expandable
						key={this.props.course.details[0].matrix.id}
						title={this.props.course.details[0].matrix.title}
					>
						{this.props.course.details[0].matrix._ParagraphSet != null ?
							this.props.course.details[0].matrix._ParagraphSet.map(paragraph => {
								return (
									<div style={{ marginTop: "1rem" }} key={`r-paragraph-${paragraph.paragraphNr}`}>
										{paragraph.title != null && paragraph.title != "" ? (
											<div className="single-section matrix-paragraph-title" style={{ paddingTop: "0px", marginBottom: "5px" }}>
												<strong>
													{paragraph.title}
												</strong>
											</div>
										) : null}

										{paragraph.subtitle != null && paragraph.subtitle != "" ? (
											<div className="single-section matrix-paragraph-subtitle" style={{ paddingTop: "0px", marginBottom: "5px" }}>
												{paragraph.subtitle}
											</div>
										) : null}
										{paragraph.rows.map(block => {
											return (
												<MatrixDisplayBlock
													key={block.id}
													block={block}
													associatedBlock={{
														clarifications: []
													}}
												/>
											);
										})}
									</div>
								);
							}
							) : null}
					</Expandable>
				);
			}
		}
	}

	removeCourse = (event) => {
		event.preventDefault();

		swal.fire({
			title: this.props.translate("are-you-sure"),
			text: this.props.translate("remove-course-prompt-text"),
			showCancelButton: true,
			cancelButtonText: this.props.translate("Cancel"),
			confirmButtonText: this.props.translate("Remove"),
		}).then((result) => {
			if (result.value != null) {

				const { courseId } = this.props.match.params;

				api.delete('courses/' + courseId).then((response) => {
					if (response.status != 200) {
						return false;
					}

					this.props.history.push('/courses');
				});
			}
		});
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	closeModal = (reload, skipPrompt) => {
		if (skipPrompt) {
			if (reload) {
				this.setState({ loading: true });
				this.props.getCourse(this.props.match.params.courseId).then(() => {
					const { details } = this.props.course;
					let _ParagraphSet = [];
					if (details[0] != null) {
						if (details[0].matrix != null) {
							if (details[0].matrix._ParagraphSet != null) {
								_ParagraphSet = details[0].matrix._ParagraphSet;
							}
						}
					}

					this.setState({
						_ParagraphSet,
						modalIsOpen: false,
						loading: false
					});
				});
			} else {
				this.setState({ modalIsOpen: false });
			}
		} else {
			if (reload) {
				this.setState({ loading: true });
				this.props.getCourse(this.props.match.params.courseId, data => {
					const { details } = data;
					let _ParagraphSet = [];
					if (details[0] != null) {
						if (details[0].matrix != null) {
							if (details[0].matrix._ParagraphSet != null) {
								_ParagraphSet = details[0].matrix._ParagraphSet;
							}
						}
					}

					this.setState({
						_ParagraphSet,
						loading: false,
						modalIsOpen: false
					});
				});
			} else {
				this.setState({ modalIsOpen: false });
			}
		}
	};

	CreateButton = (enabled) => {
		return (
			<Button disabled={!enabled} type="secondary" onClick={this.openModal}>
				<Icon name="Pen_Small" /> {this.props.translate("edit-course")}
			</Button>
		);
	};

	renderCourse() {
		const { course, translate } = this.props;
		const user = new User(this.props.currentUser);

		if (course != null) {
			const coreContent = course.details[0]?.coreContent;
			let coreContents = [{ courseTitle: null, courseId: course.id, coreContents: course.details[0]?.coreContent }];
			return (
				<div className="single-task">
					<Modal
						isOpen={this.state.modalIsOpen}
						onClose={() => this.closeModal(false, false)}
						title={translate("edit-course")}
					>
						<CourseForm onClose={this.closeModal} editView={true} />
					</Modal>

					<div
						className="single-section form left-side"
						style={{ paddingTop: "0" }}
					>
						<Block>
							<span className="title">
								{translate("description")}
							</span>

							<PostContent>{course.description}</PostContent>

							{course.details[0] != null && course.details[0].purpose != null ?
								<div style={{ marginTop: "1rem" }}>
									<span className="title">
										{this.props.translate("purpose")}
									</span>

									<PostContent>
										{course.details[0].purpose}
									</PostContent>
								</div>
								: null}
						</Block>
					</div>

					<div className="single-actions right-side">
						<div className="action-section">
							<h3>{this.props.translate("tools")}</h3>

							{this.CreateButton(user.isUserCurriculumAdmin() || ((course.tenantId == null || course.tenantId == this.props.tenant.id) && course.type != "PREDEFINED_COURSE"))}

							{<div style={{ marginTop: 5 }}>
								<Button disabled={!(user.isUserCurriculumAdmin() || ((course.tenantId == null || course.tenantId == this.props.tenant.id) && course.type != "PREDEFINED_COURSE" && course.isUsed == false))} type="secondary" onClick={this.removeCourse}>
									<Icon name="Bin" /> {this.props.translate('remove-course')}
								</Button>
							</div>}
						</div>
					</div>

					<div style={{ clear: "both" }} />

					{course.details[0] != null && course.details[0].coreContent != null ?
						<div
							className="single-task-description"
							style={{ marginTop: "2.5em" }}
						>

							<SelectedCoreContent coreContents={coreContents} />
						</div>
						: null}

					{this.state.loading ?
						<Spinner center />
						:
						<div
							className="course-matrix-list"
							style={{ marginTop: "3em" }}
						>
							{this.renderMatrix()}
						</div>
					}
				</div>
			);
		}

		return <div />;
	}

	render() {
		if (this.props.course == null) {
			return <Spinner center />;
		}

		return <div>{this.renderCourse()}</div>;
	}
}

function mapStateToProps(state) {
	return {
		course: state.Courses.course,
		matrix: state.Matrix.matrix,
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations)
	};
}

export default withRouter(connect(mapStateToProps, {
	setActiveCourse,
	getMatrix,
	getCourse,
	setPageTitle,
	setPageActions,
})(SingleCourse));