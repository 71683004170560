import React, { useMemo } from 'react';
import { useTranslate } from 'lib/translate';
import { capitalize } from 'helpers/content';

import { Editor } from "UI/Inputs";
import { Collapsible } from 'UI';
import { Icon } from '@haldor/ui';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';

const AssessmentFeedbackField = (props) => {
	const translate = useTranslate();
	const { resource } = props;

	const initialValue = useMemo(() => {
		if (props.meta != null && props.meta?.initial != null) {
			return JSON.parse(JSON.stringify(props.meta?.initial));
		}

		return null;
	}, []);

	/**
	 * @param {number} index
	 */
	const _getFieldInitialValue = (index) => {
		if (initialValue == null || initialValue.length == 0) {
			return null;
		}

		return initialValue[index];
	}

	/**
	 * @param {number} index
	 * @param {string} text
	 */
	const _onValueTextChange = (index, text) => {
		let value = [...props.input.value];
		value[index].text = text;
		props.input.onChange(value);
	}

	/**
	 * @param {string} permission
	 */
	const renderPermission = (permission, value) => {
		let selected = false;
		if (!value.isPrivate && resource != null) {
			selected = resource.permissions?.find((element) =>
				element.target == permission && element.referenceType == 'ASSESSMENTFEEDBACK'
			) != null;
		}

		return (
			<div className="permission">
				{selected ?
					<Icon name="Eye" />
					: <Icon name="Eye_off" />}

				{translate(capitalize(permission))}
			</div>
		);
	}

	/**
	 * @returns {array}
	 */
	const getValues = () => {
		const { value } = props.input;

		if (resource == null) {
			return [];
		}

		if (value == null || value == '') {
			return [];
		}

		return value;
	}

	/**
	 * @param {object} value
	 * @param {number} index
	 */
	const getValueTrigger = (value, index) => {
		return <div className="df aic">
			<div style={{ flex: 1, marginRight: '1rem' }}>
				{value.isPrivate ?
					translate('Personal note')
					: translate('Feedback')}
			</div>

			{props.assessmentType == ASSESSMENT_TYPES.TEACHER || props.assessmentType == null ?
				<div className="df aic">
					{renderPermission('STUDENT', value)}
					{renderPermission('GUARDIAN', value)}
				</div>
				: null}
		</div>
	}


	const values = getValues();

	if (values.length == 0) {
		return null;
	}

	return <div className="feedback-field">
		{values.map((value, index) => {
			if (resource.id != value.assessmentBlockPartId) {
				return null;
			}

			return <Collapsible defaultOpen key={index} trigger={getValueTrigger(value, index)}>
				<div>
					<Editor
						value={value.text}
						onChange={(text) => _onValueTextChange(index, text)}
					/>
				</div>
			</Collapsible>
		})}
	</div>

}

export default AssessmentFeedbackField;