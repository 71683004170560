import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import User from '_class/User';
import { getRootUrl } from 'helpers/url';
import { PRESCHOOL } from 'constants/schoolTypes';
import { getUserAttendance, clearUserAttendance } from 'actions/absence';
import {
	getScheduleForUser,
	getScheduleForMe,
	getActivitiesForMe,
	getActivitesForGroups,
	getActivitiesById,
	getScheduleForGroups,
	clearActivitesForOther,
	setLastFetched
} from 'actions/schedule';

import SelectLinkedPosition from 'containers/Schedule/SelectLinkedPosition';
import { translate as getTranslate, getActiveLanguage, Icon } from '@haldor/ui';

import SelectionDisplay from './Partials/SelectionDisplay';
import ItemContent from './Partials/ItemContent';
import { Button } from '@haldor/ui';
import './_Schedule.scss';

const MonthHeader = ({ label }) => {
	let test = moment().local().format('dddd');
	return (
		<span
			role='columnheader'
			aria-sort='none'
			className={test == label.toLowerCase() ? 'todaysMonth' : ''}
		>
			{label}
		</span>
	);
};
const _getMonthDates = (date, toISO) => {
	let daysFromPreviousMonthVisible = moment(moment(date).startOf('month')).isoWeekday() - 1;
	let start = moment(date)
		.startOf('month')
		.subtract(daysFromPreviousMonthVisible, 'd')
		.set({ hour: 0, minute: 0, second: 0 });

	let daysFromNextMonthVisible = 7 - moment(moment(date).endOf('month')).isoWeekday();
	let end = moment(date)
		.endOf('month')
		.add(daysFromNextMonthVisible, 'd')
		.set({ hour: 23, minute: 59, second: 59 });

	if (toISO) {
		return { start: start.toISOString(), end: end.toISOString() };
	}
	return { start, end };
}
const _getWeekDates = (date, view) => {
	let start = moment(date).set({ isoWeekday: 1, hour: 0, minute: 0, second: 0 });

	let end = null;
	if (view == 'work_week') {
		end = moment(start).add(5, 'days');
	}
	else {
		end = moment(start).add(1, 'weeks');
	}

	return { start: start.toISOString(), end: end.toISOString() };
}
const filterMonth = (data, date) => {
	let groups = {};
	let { start, end } = _getMonthDates(date, false);

	data.forEach((item) => {
		const timestamp = moment.utc(item.startTime).startOf('day').local();
		const date = timestamp.toISOString();

		if (timestamp.isBetween(start, end)) {
			if (date in groups) {
				groups[date].push(item);
			} else {
				groups[date] = new Array(item);
			}
		}
	});

	let ars = [];

	for (const [key, value] of Object.entries(groups)) {
		ars.push({ [key]: [...value] });
	}

	return ars;
};
const filterSchedule = (scheduleData, ScheduleFilter, isStudent) => {
	if (isStudent) {
		return scheduleData.filter((lesson) => {
			if (lesson.type === 'CALENDAR_EVENT') return ScheduleFilter.activitiesFilter.CalendarEvent;
			return ScheduleFilter.activitiesFilter.Lesson;
		});
	}
	return scheduleData.filter((lesson) => {
		if (lesson.type === 'CALENDAR_EVENT') return ScheduleFilter.activitiesFilter.CalendarEvent;
		if (lesson.examination) return ScheduleFilter.activitiesFilter.LESSON_TYPE_TEST;
		return ScheduleFilter.activitiesFilter.LESSON_TYPE_REGULAR;
	});
}
//Filter array by filtertags for assignment types
const filterArray = (arr, data, tags, isAdvanced, isStudent = false) => {
	let tempArr = [];

	//retrieve booked meetings if user has checked the choice
	if ((isStudent && data?.Meeting) || (!isStudent && data?.booked)) {
		let bookedMeetings = arr.filter((item) => item.type === 'Meeting' && item.instanceId != null);
		tempArr = [...tempArr, ...bookedMeetings];
	}

	//retrieve unbooked meetings if user has checked the choice
	if (((isStudent && data?.Meeting) || (!isStudent && data?.unBooked))) {
		let unbook = [];
		for (let i = 0; i < arr.length; i++) {
			if (arr[i].type === 'Meeting' && arr[i].instanceId === null) {
				unbook.push(arr[i]);
			}
		}
		tempArr = [...tempArr, ...unbook];
	}

	//retrieve assignments if user has checked the choice
	if (isStudent && data?.Assignment) {
		let assignments = arr.filter((item) => { return item.type === 'Assignment'; });
		tempArr = [...tempArr, ...assignments];
	} else if (!isStudent) {
		let assignmentTypes = arr.filter((item) => {
			return tags.find((f) => {
				return f.name === item?.instanceType || (f.name === 'ASSIGNMENT_TYPE_OTHER' && item.type === 'Assignment' && !item.instanceType);
			});
		});
		tempArr = [...tempArr, ...assignmentTypes];
	}
	return tempArr;
};
const countActivities = (data) => {
	const count = {};
	let assignment = 0;
	let meetings = 0;
	let unBooked = 0;
	let lesson = 0;
	let lessonExaminations = 0;
	let calendarEvent = 0;
	data.forEach((element) => {
		if (element.type === 'Meeting' && element.instanceId == null) {
			unBooked++;
		} else if (element.type === 'Meeting' && element.instanceId) {
			meetings++;
		} else if (element.type === 'Assignment') {
			assignment++;
			count[element.instanceType] = (count[element.instanceType] || 0) + 1;
		} else if (element?.type === 'CALENDAR_EVENT') {
			calendarEvent++;
		} else {
			lesson++;
			if (element.examination) {
				lessonExaminations++;
			}
		}
	});

	count.Assignment = assignment;
	count.meetings = meetings;
	count.unBooked = unBooked;
	count.lesson = lesson;
	count.lessonExaminations = lessonExaminations;
	count.calendarEvent = calendarEvent;
	return count;
};
const { v4: uuidv4 } = require('uuid');
let currentScopeUniqueId = uuidv4();
const handleScheduleGroupData = (sections = []) => {
	let peopleArr = [];
	sections.forEach(items => {
		let arr = [...items?.students, ...items?.owners];
		peopleArr = [...peopleArr, ...arr];
	});

	let removeSame = peopleArr?.filter((item, index, self) =>
		self.findIndex((t) => t.id === item.id) === index);
	currentScopeUniqueId = uuidv4();
	return removeSame;
}
const removedDuplicates = (data = [], data2 = [], combined = false) => {
	if (data.length > 0 && data2?.length > 0) {
		if (combined) {
			let removeOwnedItems = data.filter((item) => {
				return !data2.find((f) => {
					return f.id == item?.id;
				});
			});
			return removeOwnedItems;
		}
		let findUnique = data.filter((item) => {
			return !data2?.find((f) => {
				return f.id == item?.id;
			});
		});
		let removeSame = findUnique?.filter((item, index, self) =>
			self.findIndex((t) => t.id === item.id && t.type === item.type) === index);
		return removeSame;
	}
	return data;
}

const setLocalizer = () => {
	moment.locale(getActiveLanguage());
	return momentLocalizer(moment);
}

const ScheduleView = (props) => {
	const dispatch = useDispatch();

	const prevProps = useRef({
		date: props.date,
		view: props.view,
		userId: props.userId
	});

	//Data sections
	const currentUser = useSelector((state) => state.user.currentUser);
	const activities = useSelector((state) => state.schedule.userActivites);
	const schedules = useSelector((state) => state.schedule.usersSchedule);
	const attendance = useSelector((state) => state.Absence.userAttendance);
	const groupActivites = useSelector((state) => state.schedule.groupActivities.data);
	const groupActivitiesId = useSelector((state) => state.schedule.groupActivities.id);
	const groupActivitiesStartDate = useSelector((state) => state.schedule.groupActivities.startDate);
	const groupActivitiesEndDate = useSelector((state) => state.schedule.groupActivities.endDate);
	const groupSchedules = useSelector((state) => state.schedule.groupSchedules.data);
	const groupSchedulesId = useSelector((state) => state.schedule.groupSchedules.id);
	const groupSchedulesStartDate = useSelector((state) => state.schedule.groupSchedules.startDate);
	const groupSchedulesEndDate = useSelector((state) => state.schedule.groupSchedules.endDate);
	const lastFetched = useSelector((state) => state.schedule.lastFetched);

	const loggedInUser = useMemo(() => new User(currentUser), []);
	const school = useSelector((state) => state.user.activeSchool);
	const studentActivites = useSelector((state) => state.schedule.studentActivities);
	const service = useSelector((state) => state.Services.availableServices);
	const ScheduleFilter = useSelector((state) => state.ScheduleFilter);
	const translate = getTranslate(useSelector((state) => state.Languages.translations));
	//states
	const [isLoading, setIsLoading] = useState(false);
	const [linkedPosition, setLinkedPosition] = useState(null);
	const [filterData, setFilterData] = useState(activities);
	const [customButton, setCustomButton] = useState([]);
	const [displayWholeDay, setDisplayWholeDay] = useState(false);
	const [storedFilterData, setStoredFilterData] = useState([]);
	const [storedOthersActivities, setStoredOthersActivities] = useState([]);
	const notOwnedActivities = useMemo(() => {
		if (ScheduleFilter.advancedCalendarActive && !props.noAdvanced) {
			return removedDuplicates(groupActivites, activities)
		}
	}, [groupActivites, activities, ScheduleFilter.advancedCalendarActive, props.noAdvanced]);
	const [myDataTimeout, setMyDataTimeout] = useState(-1);
	const [groupDataTimeout, setGroupDataTimeout] = useState(-1);

	const [buttonCoords, setButtonCords] = useState([]);
	const [timeButtonOpen, setTimeButtonOpen] = useState(false);
	//useRefs
	const containerRef = useRef(null);
	const hasFetchedGroups = useRef({ tasks: false, schedules: false });
	const loadingData = useRef({ my: false, groups: false });

	const [storedOtherSchedules, setStoredOtherSchedules] = useState([]);
	const history = useHistory();
	const localizer = useMemo(() => setLocalizer(), [getActiveLanguage]);
	const userId = props.userId != null ? props.userId : currentUser?.id;
	const notOwnedSchedules = useMemo(() => {
		if (ScheduleFilter.advancedCalendarActive && !props.noAdvanced) {
			return removedDuplicates(groupSchedules, schedules[userId])
		}
	}, [groupSchedules, schedules[userId], ScheduleFilter.advancedCalendarActive, props.noAdvanced]);

	const groupScheduleData = useMemo(() =>
		handleScheduleGroupData(ScheduleFilter?.userFilter),
		[ScheduleFilter?.userFilter]);

	const _eventsFactory = () => {
		if (props.view == 'month') {
			return _getScheduleItemsForUser().map((item) => {
				for (const [key, value] of Object.entries(item)) {
					let count;
					if (Array.isArray(value)) {
						count = countActivities(value);
					}

					return {
						id: key,
						title: key.toString(),
						length: count ?? null,
						start: moment.utc(key).set({ second: 0, millisecond: 0 }).local().toDate(),
						end: moment
							.utc(key)
							.set({ second: 0, millisecond: 0 })
							.local()
							.add(60, 'minutes')
							.toDate(),
					};
				}
			});
		}

		return _getScheduleItemsForUser().map((item) => {
			if (item?.type === 'custom_button') {
				return item;
			}
			return {
				id: item.id,
				title: item.title,
				instance: item?.instanceId,
				type: item.type,
				instanceType: item?.instanceType,
				start: moment.utc(item.startTime).set({ second: 0, millisecond: 0 }).local().toDate(),
				end: moment
					.utc(item.startTime)
					.set({ second: 0, millisecond: 0 })
					.local()
					.add(item.length, 'minutes')
					.toDate(),
			};
		});
	};

	const handleTimePosition = () => {
		let button = document.querySelector('.calendarButton');
		let container = document.querySelector('.add-placeholder-button');
		if (button) {
			const cords = button?.getBoundingClientRect();
			const width = container?.getBoundingClientRect();
			//get width of timelineContainer
			const widthRef = containerRef?.current?.getBoundingClientRect().width;
			let left, right;
			//Get distance from corner to button plus 0.1% padding
			let leftDistance = (cords?.width - width.width) / 2 * 0.9;
			right = window.innerWidth - cords?.right;

			if (props.view === 'day') {
				left = cords?.left;
				setButtonCords({
					left: left,
					right: right,
					top: cords?.top + window.scrollY,
					margin: true,
				});
				return;
			}

			//check if right corner of button plus innerElement plus 30 padding is greater than screenWidth
			if (cords?.right + widthRef + 30 > window.innerWidth) {
				//handle smaller screens
				if (cords?.width - width.width === 0) {
					left = cords?.left - (widthRef + cords?.width);
				} else {
					left = cords?.left - widthRef - (cords?.width - width.width);
				}
			} else {
				//handle smaller screens
				if (cords?.width - width.width === 0) {
					left = cords?.left + cords?.width * 1.1;
				} else {
					left = cords?.left + cords?.width - leftDistance;
				}
			}

			setButtonCords({
				left: left,
				right: right,
				width: cords?.width,
				top: cords?.top + window.scrollY - (width.height / 2),
				margin: false,
			});
		}
	};

	//handle resizing for timelineButton
	useEffect(() => {
		if (customButton) {
			handleTimePosition();
		}
	}, [props.windowWidth]);

	const toggleTimeButton = () => {
		setTimeButtonOpen((prev) => !prev);
	};

	//reset advanced mode data whenever selected sections changes
	useEffect(() => {
		if (props?.advancedCalendar && !props.noAdvanced && !props.agendaModal) {
			//dispatch(clearActivitesForOther());
			setStoredFilterData([]);
			setStoredOthersActivities([]);
			setStoredOtherSchedules([]);
			hasFetchedGroups.current.schedules = false;
			hasFetchedGroups.current.tasks = false;
		}
		setTimeButtonOpen(false);
	}, [
		props.view,
		props.date,
		props.advancedCalendar,
		ScheduleFilter.userFilter,
	]);

	//main useEffect for filtering data
	useEffect(() => {
		const handleActivitesfiltering = () => {
			//remove timeline button
			setCustomButton([]);
			setTimeButtonOpen(false);

			let allowedTags = [];
			let shouldFilter = true;

			for (const [key, value] of Object.entries(ScheduleFilter.activitiesFilter)) {
				if (value) {
					allowedTags.push({ name: key });
				}
			}

			//don't filter if all checkboxes are selected for select-activites
			if (Object.entries(ScheduleFilter.activitiesFilter).length === allowedTags.length) {
				shouldFilter = false;
			}

			if (allowedTags.length === 0) {
				setStoredFilterData([]);
				setStoredOthersActivities([]);
				setStoredOtherSchedules([]);
			}

			// Handle advancedcalendar data
			if (props?.advancedCalendar && allowedTags.length > 0 && !props.noAdvanced) {
				let schedule = [];
				let MyActivites = [];
				let othersActivities = [];

				// Add my lessons
				if (ScheduleFilter.displayMySchedule && schedules[userId]) {
					schedule = shouldFilter ? filterSchedule(schedules[userId], ScheduleFilter) : schedules[userId];
				}

				// Handle nonOwned schedule items
				if (groupScheduleData?.length > 0) {
					let filteredGroupSchedule = shouldFilter ? filterSchedule(groupSchedules, ScheduleFilter) : groupSchedules;
					setStoredOtherSchedules(filteredGroupSchedule);

					let combined = [...schedule ?? [], ...filteredGroupSchedule];
					schedule = combined?.filter((item, index, self) => self.findIndex((t) => t.id === item.id) === index); // distinct combined					
				}

				// Add my own activities 
				if (activities.length > 0 && ScheduleFilter.displayMySchedule) {
					MyActivites = shouldFilter ? filterArray(
						activities,
						ScheduleFilter?.activitiesFilter,
						allowedTags,
						ScheduleFilter.advancedCalendarActive
					) : activities;
				}

				// Handle group activities
				if (ScheduleFilter.userFilter?.length > 0 && groupActivites?.length > 0) {
					othersActivities = shouldFilter ? filterArray(
						groupActivites,
						ScheduleFilter?.activitiesFilter,
						allowedTags,
						ScheduleFilter.advancedCalendarActive
					) : groupActivites;

					let distinctNonMeetings = othersActivities?.filter((item, index, self) =>
						self.findIndex((t) => t.id === item.id && item.type !== 'Meeting') === index);

					let distinctMeetings = othersActivities.filter(x => x.type === 'Meeting' && x.endTime !== '0001-01-01T00:00:00').filter((item, index, self) =>
						self.findIndex((t) => t?.instanceId == item?.instanceId) === index
					);

					setStoredOthersActivities([...distinctNonMeetings, ...distinctMeetings]);
				}

				let combinedActivities = [...othersActivities, ...MyActivites];
				const distinctCombinedMeetings = combinedActivities.filter(t => t.type === 'Meeting').filter((item, index, self) =>
					self.findIndex((t) => t?.instanceId == item?.instanceId) === index || // Distinct booked meetings
					self.findIndex((t) => t.id == item.id && t.startTime == item.startTime && t.endTime == item.endTime) === index // Distinct unbooked meetings
				);
				const distinctCombinedNonMeetings = combinedActivities?.filter((item, index, self) =>
					self.findIndex((t) => t.id === item.id && t.type === item.type && item.type !== 'Meeting') === index
				);

				if (props.view == 'month') {
					setFilterData(filterMonth([...distinctCombinedNonMeetings, ...schedule, ...distinctCombinedMeetings], props.date));
					return;
				}

				setStoredFilterData([...distinctCombinedNonMeetings, ...schedule, ...distinctCombinedMeetings]);
				return;
			}

			//handle substitute teacher
			if (props.userId && !props.isStudentCard) {
				if (schedules[userId]) {
					setFilterData(schedules[userId]);
					if (props.view === 'month') {
						setFilterData(filterMonth(schedules[userId], props.date));
						return;
					}
					return;
				}

				setFilterData([]);
				return;
			}

			//handle normal calendar for logged in user
			if (allowedTags.length > 0) {
				let arr = [];
				if (studentActivites?.length > 0 && shouldFilter) {
					arr = filterArray(studentActivites, ScheduleFilter?.activitiesFilter, allowedTags, false, loggedInUser.isStudent());
				} else if (shouldFilter && !props.isStudentCard) {
					arr = filterArray(activities, ScheduleFilter?.activitiesFilter, allowedTags, false, loggedInUser.isStudent());
				} else {
					if (props.isStudentCard) {
						arr = studentActivites != null ? studentActivites : [];
					} else {
						arr = activities != null ? activities : [];
					}
				}

				if (schedules[userId]?.length > 0) {
					arr = [...arr, ...filterSchedule(schedules[userId], ScheduleFilter, loggedInUser.isStudent())];
				}

				if (props.view === 'month') {
					if (arr.length > 0) {
						setFilterData(filterMonth(arr, props.date));
						return;
					}
				}

				setFilterData(arr);
				return;
			}

			setFilterData([]);
		};
		handleActivitesfiltering();
	}, [
		ScheduleFilter.userFilter,
		activities,
		ScheduleFilter?.activitiesFilter,
		groupActivites,
		studentActivites,
		groupSchedules,
		schedules[userId],
		ScheduleFilter.advancedCalendarActive,
		ScheduleFilter.displayMySchedule
	]);

	const formats = {
		weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
	};

	//group dates under same date
	const handleClickCalendar = (event) => {
		if (props.view === 'month') {
			props.enableAgendaModal(event.start); // Show AgendaModal when clicking on a day in the month view
			return;
		}
		if (timeButtonOpen) {
			setTimeButtonOpen(false);
		}

		let obj = {
			id: 'custom_button',
			startTime: event.start,
			end: moment
				.utc(event.start)
				.set({ second: 0, millisecond: 0 })
				.local()
				.add(60, 'minutes')
				.toDate(),
			typ: 'custom_button',
		};
		setCustomButton([obj]);
	};

	//handle only displayin others schedule for month view
	const handleMonthView = useMemo(() => {
		if (props.advancedCalendar && !ScheduleFilter.displayMySchedule && props.view === 'month') {
			return filterMonth([...storedOtherSchedules, ...storedOthersActivities], props.date);
		}

		return [];
	}, [props.view, props.advancedCalendar, ScheduleFilter.displayMySchedule, groupSchedules, storedOthersActivities])

	//add data to calendar
	const _getScheduleItemsForUser = (userId = null) => {
		let data = [];
		if (props.view == 'month') {
			if (ScheduleFilter.advancedCalendarActive && !ScheduleFilter.displayMySchedule) {
				return handleMonthView;
			}

			return filterData;
		}

		if (ScheduleFilter.displayMySchedule) {
			if (props.advancedCalendar && !props.noAdvanced) {
				data = storedFilterData;
			} else {
				data = filterData;
			}
		} else if (!ScheduleFilter.displayMySchedule && props.advancedCalendar && !props.noAdvanced) {
			data = [...storedOtherSchedules, ...storedOthersActivities];
		} else {
			data = filterData;
		}

		//add custom button to calendar as event
		if (customButton.length > 0) {
			data = [...data, ...customButton];
		}

		// Limit data to the selected date for agendaModal 
		if (props.agendaModal) {
			let start = moment(props.date).set({ hour: 0, minute: 0, second: 0 }).toISOString();
			let end = moment(props.date).set({ hour: 23, minute: 59, second: 59 }).toISOString();
			data = data.filter(item => { return start < item.startTime && item.startTime < end; });
		}

		return data;
	};

	//Handle event style
	const _getEventProps = (event, start, end, isSelected) => {
		let className = '';
		let style = {};
		const attendance = _getAttendanceForItem(event.id);
		const activites = _getActivitesForItem(event.id, event?.instance, event?.type);
		const schedule = _getScheduleItem(event.id, event?.type);
		let hasExamination = false;
		if (event.id === 'custom_button') {
			if (props.view === 'day') {
				className = 'calendarButton day';
			} else {
				className = 'calendarButton';
			}
			style.padding = 0;
			return { className, style };
		}

		if (timeButtonOpen && event.id !== 'custom_button') {
			style.backgroundColor = '#d0d0d0'
		}

		//don't change the size of events whenever the custom button is added
		if (event.id !== 'custom_button') {
			style.maxWidth = '90%';
			style.minWith = 'fit-content';
			style.minHeight = 'fit-content';
			style.borderStyle = 'solid';
		}

		if (schedule?.examination && service.advancedSchedule) {
			hasExamination = true;
		}

		if (props.view == 'month') {
			style.backgroundColor = 'transparent';
			style.color = '#fff';
			return { className, style };
		}

		if (service.attendance && attendance != null && activites === null) {
			className += ' attendance';

			if (attendance.attendanceType == 'PRESENT') {
				//	style.borderLeftColor = '#19db6c';

				if (props.view != 'agenda') {
					style.backgroundColor = '#e8fbf0';
				}
			}

			if (attendance.type === 'Meeting' || attendance.type === 'assignment') {
				style.backgroundColor = '#fffff';
			}

			if (attendance.type.indexOf('VALID') > -1) {
				//style.borderLeftColor = '#efc24c';

				if (props.view != 'agenda') {
					style.backgroundColor = '#fff6df';
				}
			}

			if (attendance.type.indexOf('INVALID') > -1) {
				style.borderLeftColor = '#f25252';

				if (props.view != 'agenda') {
					style.backgroundColor = '#FDE5E5';
				}
			}
		} else if (activites != null) {
			style.borderLeftColor = '#b7b7b';
			if (activites.type === 'Assignment') {
				className += 'Assignment';
			}
			if (activites.notMine ||
				!ScheduleFilter.displayMySchedule &&
				ScheduleFilter.advancedCalendarActive &&
				props.advancedCalendar) {
				className += " notOwner"

				if (
					activites.instanceType == 'ASSIGNMENT_TYPE_WRITTEN_TEST' ||
					activites.instanceType == 'ASSIGNMENT_TYPE_VERBAL_TEST'
				) {
					className += ' hasExamination';
				}
				if (activites.type === 'Meeting') {
					className += ' meeting';
					if (activites.instanceId == null) {
						className += ' no-booked';
					}
				}
			} else if (activites.type === 'Meeting') {
				className += ' meeting';
				if (activites.instanceId == null) {
					className += ' no-booked';
				}
			} else if (
				activites.instanceType == 'ASSIGNMENT_TYPE_WRITTEN_TEST' ||
				activites.instanceType == 'ASSIGNMENT_TYPE_VERBAL_TEST'
			) {
				className += ' hasExamination';
			}
			return { className, style };
			//Handle lessons
		} else {
			const lesson = schedule;
			if (lesson?.type === 'CALENDAR_EVENT') {
				className += 'calendarEvent'
				if (lesson?.notMine || !ScheduleFilter.displayMySchedule && ScheduleFilter.advancedCalendarActive && props.advancedCalendar) {
					className += " notOwner"
				}
				return { className, style };
			}
			if (hasExamination && lesson) {
				className += ' hasExamination';
			}

			className += (service.attendance && lesson != null && lesson.attendanceReported != null) ? ' attendance' : ' lesson';

			if ((props.notOwner && lesson?.attendanceReported === null) ||
				(lesson?.notMine || !ScheduleFilter.displayMySchedule && ScheduleFilter.advancedCalendarActive && props.advancedCalendar)) {
				className += ' notOwner';
			}

			if (timeButtonOpen && event.id !== 'custom_button') {
				style.backgroundColor = '#d0d0d0'
			}
		}

		return { className, style };
	};

	const _getScheduleItem = (id, selectable = false, type, instanceId) => {
		if (id == null && type && !selectable) {
			return null;
		}
		if (selectable) {
			if (type === 'Meeting') {
				return _getScheduleItemsForUser()?.find((item) => item.id == id && item.instanceId == instanceId);
			}
			else {
				return _getScheduleItemsForUser()?.find((item) => item.id == id);
			}
		}
		if (props.advancedCalendar && !props.noAdvanced) {
			let checkOthers = notOwnedSchedules?.find((item) => item.id == id);
			if (typeof checkOthers !== 'undefined') {
				checkOthers.notMine = true;
				return checkOthers;
			}
		}
		return schedules[userId]?.find((item) => item.id == id);
	};

	const _getAttendanceForItem = (id, type) => {
		if (id == null) {
			return null;
		}
		if (attendance == null || attendance.length == 0) {
			return null;
		}
		if (userId === null) {
			return null;
		}
		return attendance.find((item) =>
			item.calendarEventId == id && item.user.userId == userId && item.attendanceType != 'NOT_REPORTED'
		);
	};

	const _getActivitesForItem = (id, instance, type) => {
		if (id == null) {
			return null;
		}

		if (activities.length > 0 || groupActivites.length > 0) {
			if (type === 'Meeting') {
				if (instance) {
					if (props.advancedCalendar && !props.noAdvanced) {
						let notOwned = notOwnedActivities.find(
							(item) => item.id === id && item.instanceId === instance
						);
						let myActivities = activities.find((item) => item.id === id && item.instanceId === instance);

						let owned = groupActivites.find(
							(item) => item.id === id && item.instanceId === instance
						);

						if (notOwned != null && typeof owned === 'undefined' && typeof myActivities === 'undefined') {
							notOwned.notMine = true;
							return notOwned;
						} else if (typeof owned !== 'undefined' && typeof myActivities === 'undefined' && ScheduleFilter?.userFilter?.length > 0) {
							return owned;
						}
						return activities.find((item) => item.id === id && item.instanceId === instance);
					}
					return activities.find((item) => item.id === id && item.instanceId === instance);
				} else {
					if (props.advancedCalendar && !props.noAdvanced) {
						let notOwned = storedOthersActivities.find(
							(item) => item.id === id && item.instanceId === instance
						);
						let owned = groupActivites.find(
							(item) => item.id === id && item.instanceId === instance
						);

						if (notOwned != null) {
							notOwned.notMine = true;
						}
						if (!ScheduleFilter.displayMySchedule) {
							return notOwned;
						}

						if (typeof notOwned !== 'undefined') {
							return notOwned;
						} else if (typeof owned !== 'undefined' && ScheduleFilter?.userFilter?.length > 0) {
							return owned;
						} else {
							return activities.find((item) =>
								item.id === id && item.instanceId === instance
							);
						}
					}

					return activities.find((item) =>
						item.id === id && item.instanceId === instance
					);
				}
			}

			if (props.advancedCalendar && !props.noAdvanced) {
				let notOwned = notOwnedActivities.find((item) => item.id === id);
				let owned = groupActivites.find((item) => item.id === id);
				let myActivities = activities.find((item) => item.id === id);
				if (notOwned != null) {
					notOwned.notMine = true;
					return notOwned;
				}

				if (notOwned != null && typeof owned === 'undefined' && typeof myActivities === 'undefined') {
					notOwned.notMine = true;
					return notOwned;
				} else if (owned && typeof myActivities === 'undefined' && ScheduleFilter?.userFilter?.length > 0) {
					return owned;
				}
				return myActivities;
			}

			if (props.isStudentCard) {
				return studentActivites.find((item) => item.id === id);
			}

			return activities.find((item) => item.id === id);
		}

		return null;
	};

	const _getStartEndDates = () => {
		let start, end = props.date;

		switch (props.view ?? 'week') {
			case 'agenda':
			case 'day':
				start = moment(props.date).set({ hour: 0, minute: 0, second: 0 }).toISOString();
				end = moment(props.date).add(1, 'days').set({ hour: 0, minute: 0, second: 0 }).toISOString();
				break;
			case 'work_week':
				return _getWeekDates(props.date, props.view);
			case 'week':
				return _getWeekDates(props.date, props.view);
			case 'month':
				return _getMonthDates(props.date, true);

			default:
				break;
		}

		return { start, end };
	};

	const _getTimeRange = () => {
		let min = moment().set({ hour: 6, minute: 0 }).toDate();
		let max = moment().set({ hour: 12, minute: 0 }).toDate();
		//Display entire day if button is active

		if (displayWholeDay) {
			min = moment().set({ hour: 6, minute: 0 }).toDate();
			max = moment().set({ hour: 23, minute: 59 }).toDate();
			return { min, max };
		}

		if (props.view !== 'month') {
			let { start, end } = _getStartEndDates();
			const scheduleItems = _getScheduleItemsForUser().filter(x => {
				return moment.utc(x.startTime).local() > moment(start).utc().local() && moment.utc(x.startTime).local() < moment(end).utc().local();
			});
			if (scheduleItems != null && scheduleItems?.length > 0) {
				let items = [...scheduleItems];
				items.sort(function (a, b) {
					const date1 =
						moment.utc(a.startTime).local().minutes() + moment.utc(a.startTime).local().hours() * 60;
					const date2 =
						moment.utc(b.startTime).local().minutes() + moment.utc(b.startTime).local().hours() * 60;
					return date1 > date2 ? 1 : date2 > date1 ? -1 : 0;
				});
				const highest = scheduleItems.reduce(function (prev, current) {
					let now = moment().set({
						minutes: moment
							.utc(current.startTime)
							.add(current.length, 'minutes')
							.local()
							.minutes(),
						hours: moment
							.utc(current.startTime)
							.add(current.length, 'minutes')
							.local()
							.hours(),
					});
					let last = moment().set({
						minutes: moment
							.utc(prev.startTime)
							.add(prev.length, 'minutes')
							.local()
							.minutes(),
						hours: moment.utc(prev.startTime).add(prev.length, 'minutes').local().hours(),
					});

					return last.unix() > now.unix() ? prev : current;
				});

				const start = moment.utc(items[0].startTime).local().set({ minute: 0 })
				const end = moment
					.utc(highest.startTime)
					.local()
					.add(highest.length, 'minutes')
					.set({ date: start.get('date'), month: start.get('month') });
				const diff = end.diff(start, 'hours');
				min = start.toDate();
				max = end.toDate();

				if (min > max) {
					min = moment().set({ hour: 6, minute: 0 }).toDate();
					max = moment().set({ hour: 12, minute: 0 }).toDate();
					return { min, max };
				}

				if (diff < 0) {
					min = moment().set({ hour: 6, minute: 0 }).toDate();
					max = moment().set({ hour: 12, minute: 0 }).toDate();
				}

				if (diff < 3) {
					if (moment(start).subtract(1, 'hour').isSame(start, 'day')) {
						min = start.subtract(1, 'hour').toDate();
					}

					if (moment(end).add(2, 'hours').isSame(end, 'day')) {
						max = end.add(2, 'hours').toDate();
					}
				}
			}
		}

		return { min, max };
	};

	const onSelectEvent = (slot) => {
		const task = _getScheduleItem(slot.id, true, slot?.type, slot?.instance);
		if (slot.id == 'custom_button') {
			return;
		}
		if (task == null) {
			return false;
		}
		if (task?.type) {
			if (task.type === 'Meeting') {
				let url;

				if (task?.instanceId != null) {
					url = `${getRootUrl()}meeting/${task.id}/instance/${task.instanceId}`;
				} else {
					url = `${getRootUrl()}meeting/${task.id}`;
				}
				history.push(url);
				return;
			}
			if (task.type === 'Assignment') {
				let url = `${getRootUrl()}assignment/${task?.externalId ?? task.id}`;
				history.push(url);
				return;
			}
			if (task.type === 'CALENDAR_EVENT') {
				let url = `${getRootUrl()}calendarEvent/${task.id}`;
				history.push(url);
				return;
			}
		}
		if (task.linkedSchedulePositions != null) {
			setLinkedPosition(task);
		} else {
			let url = `${getRootUrl()}lesson/${task.id}`;
			if (props.redirect != null) {
				url += `?redirect=${props.redirect}`;
			}

			history.push(url);
		}
	};

	const renderEventTitle = (event) => {
		const lesson = _getScheduleItem(event.id);
		const attendance = _getAttendanceForItem(event.id);
		const activities = _getActivitesForItem(event.id, event.instance, event.type);

		return (
			<ItemContent
				services={props.services}
				lesson={lesson}
				event={event}
				activities={activities}
				attendance={attendance}
				view={props.view}
				open={timeButtonOpen}
				toggleTimeButton={toggleTimeButton}
			/>
		);
	};

	useEffect(() => {
		if (customButton?.length > 0) {
			let button = document.querySelector('.calendarButton');
			if (button) {
				button.style.left = '0px';
				button.style.right = '0';
				button.style.width = '110%';
				handleTimePosition();
			}
		}
	}, [customButton, displayWholeDay]);

	//handle gray overlay background
	useEffect(() => {
		if (props.view !== 'month') {
			let calendarClass = document.querySelector('.rbc-time-content');
			if (calendarClass && timeButtonOpen && customButton) {
				calendarClass?.classList.toggle('overlay');
			} else {
				calendarClass?.classList.remove('overlay');
			}
		}

	}, [timeButtonOpen]);

	useEffect(() => {
		if (props.agendaModal) {
			return; // don't update for the AgendaModal
		}


		// Check if data was recently fetched within the last 5 minutes
		if (lastFetched != null && moment.utc(lastFetched).local().diff(moment().subtract(5, 'minutes')) > 0) {
			// If the fetched data is recent, and the relevant props (date, view, userId) have not changed
			// compared to the previous props, then do not trigger new loading or data fetching.
			// This helps to avoid unnecessary re-fetching of data when nothing has changed.
			if (
				prevProps.current.date === props.date &&
				prevProps.current.view === props.view &&
				prevProps.current.userId === props.userId
			) {
				// Stop the loading state, as we don't need to fetch data again
				loading(false);
				// Exit the effect to prevent further processing
				return;
			}
		}

		let { start, end } = _getStartEndDates();
		setFilterData([]);

		let timeout = undefined;
		if (props.advancedCalendar && service.advancedSchedule && !props.noAdvanced &&
			school.type != PRESCHOOL && currentUser !== null) {
			timeout = setTimeout(() => {
				loading(true);
				loadingData.current.my = true;
				Promise.all([
					dispatch(getScheduleForMe(currentUser.id, start, end)),
					dispatch(getActivitiesForMe(start, end))
				]).then(() => {
					loadingData.current.my = false;
					if (!loadingData.current.groups) {
						loading(false);
					}
				});
			}, getDelay(myDataTimeout))
		} else if (props.userId != null) {
			timeout = setTimeout(() => {
				loading(true);
				Promise.all([
					dispatch(getUserAttendance(props.userId, start, end)),
					(props.isStudentCard && service.advancedSchedule && school.type != PRESCHOOL) ? dispatch(getActivitiesById(props.userId, start, end)) : undefined,
					dispatch(getScheduleForUser(props.userId, start, end))
				]).then(() => {
					loading(false);
				});
			}, getDelay(myDataTimeout));
		} else if (!props.advancedCalendar && currentUser !== null) {
			const user = new User(props.user);
			let advancedService = service.advancedSchedule && school.type != PRESCHOOL;
			timeout = setTimeout(() => {
				loading(true);
				Promise.all([
					advancedService && user.isStudent() ? dispatch(getUserAttendance(currentUser.id, start, end)) : undefined,
					advancedService ? dispatch(getActivitiesForMe(start, end)) : undefined,
					dispatch(getScheduleForMe(currentUser.id, start, end))
				]).then(() => {
					loading(false);
				});
			}, getDelay(myDataTimeout));
		}

		if (timeout) {
			if (myDataTimeout != null) { clearTimeout(myDataTimeout); } // Prevent unnecessary dispatches when user rapidly clicks next multiple times
			setMyDataTimeout(timeout);
		}

		dispatch(setLastFetched(moment.utc()));

		// Update previous props
		prevProps.current = {
			date: props.date,
			view: props.view,
			userId: props.userId,
		};
	}, [
		currentUser,
		props.date,
		props.view,
		props.userId,
	]);

	//allow refetching of my own data again if disabled
	useEffect(() => {
		setCustomButton([]);
	}, [props.view, props.date, props.advancedCalendar])

	const getStartAndEndDatesOfMonthWithOverlap = (start, end) => {
		const startOfMonth = moment(start).startOf('month');
		const endOfMonth = moment(end).endOf('month');
		const startOfPreviousWeek = startOfMonth.clone().startOf('week');
		const fetchStartDate = startOfPreviousWeek.isSameOrBefore(startOfMonth) ? startOfPreviousWeek.toISOString() : startOfPreviousWeek.subtract(7, 'days').toISOString();
		const endOfNextWeek = endOfMonth.clone().endOf('week');
		const fetchEndDate = endOfNextWeek.isSameOrAfter(endOfMonth) ? endOfNextWeek.toISOString() : endOfNextWeek.add(7, 'days').toISOString();
		return { fetchStartDate, fetchEndDate };
	}


	//handle advanced calendarData data fetching
	useEffect(() => {
		if (props.agendaModal) {
			return; // don't update for the AgendaModal
		}

		let { start, end } = _getStartEndDates();
		start = moment(start).toISOString();
		end = moment(end).toISOString();
		const { fetchStartDate, fetchEndDate } = getStartAndEndDatesOfMonthWithOverlap(start, end);


		if (props.advancedCalendar && service.advancedSchedule && !props.noAdvanced && school.type != PRESCHOOL) {
			if (ScheduleFilter.userFilter.length == 0) {
				return;
			}
			let anyAssignmentTypeSelected = Object.entries(ScheduleFilter.activitiesFilter).filter(([key, value]) => key.startsWith("ASSIGNMENT_TYPE") && value === true).length > 0;
			let getActivities = (anyAssignmentTypeSelected || ScheduleFilter.activitiesFilter?.booked) && ScheduleFilter.userFilter?.length > 0;
			let getSchedule = (ScheduleFilter.activitiesFilter.LESSON_TYPE_REGULAR || ScheduleFilter.activitiesFilter.LESSON_TYPE_TEST) && groupScheduleData.length > 0;
			if (getSchedule && groupSchedulesId != null && groupSchedulesId == currentScopeUniqueId) {
				if (groupSchedulesStartDate != null && groupSchedulesEndDate != null && start > groupSchedulesStartDate && end < groupSchedulesEndDate) {
					getSchedule = false;
				}
			}
			if (getActivities && groupActivitiesId != null && groupActivitiesId == currentScopeUniqueId) {
				if (groupActivitiesStartDate != null && groupActivitiesEndDate != null && start > groupActivitiesStartDate && end < groupActivitiesEndDate) {
					getActivities = false;
				}
			}
			if (getActivities || getSchedule) {
				// Prevent unnecessary dispatches when user rapidly clicks next multiple times
				if (groupDataTimeout != null) { clearTimeout(groupDataTimeout); }

				setGroupDataTimeout(setTimeout(() => {
					loading(true);
					loadingData.current.groups = true;
					Promise.all([
						getActivities ? dispatch(getActivitesForGroups(fetchStartDate, fetchEndDate, ScheduleFilter.userFilter, currentScopeUniqueId)).then(() => {
							hasFetchedGroups.current.tasks = true;
						}) : undefined,
						getSchedule ? dispatch(getScheduleForGroups(fetchStartDate, fetchEndDate, groupScheduleData, currentScopeUniqueId)).then(() => {
							hasFetchedGroups.current.schedules = true;
						}) : undefined
					]).then(() => {
						loadingData.current.groups = false;
						if (!loadingData.current.my) {
							loading(false);
						}
					})
				}, getDelay(groupDataTimeout)));
			}
		}
	}, [ScheduleFilter.userFilter, props.date, props.view, props.advancedCalendar,
	ScheduleFilter.activitiesFilter])

	const loading = (isLoading) => {
		props.setScheduleLoading(isLoading);
		setIsLoading(isLoading);
		if (!isLoading) {
			setMyDataTimeout(undefined);
			setGroupDataTimeout(undefined);
		}
	};

	const getDelay = (timeoutId) => {
		if (timeoutId < 0) return 0;
		return timeoutId ? 1000 : 500;
	}

	const { start, end } = _getStartEndDates();
	const { min, max } = _getTimeRange();
	let containerClassName = 'c--schedule';
	if (props.userId != null && currentUser != null) {
		if (props.userId != currentUser.id) {
			containerClassName += ' variant-striped';
		}
	}

	return (
		<div>
			<div className={containerClassName}>
				{linkedPosition != null && linkedPosition?.section ? (
					<SelectLinkedPosition
						lesson={linkedPosition}
						onClose={() => setLinkedPosition(null)}
					/>
				) : null}

				<SelectionDisplay
					date={props.date}
					start={start}
					end={end}
					isLoading={isLoading}
					view={props.view}
				/>

				<Calendar
					localizer={localizer}
					events={_eventsFactory()}
					eventPropGetter={_getEventProps}
					startAccessor='start'
					endAccessor='end'
					date={props.date != null ? props.date.toDate() : moment().toDate()}
					view={props.view ?? 'work_week'}
					views={['work_week', 'week', 'day', 'month', 'agenda']}
					toolbar={false}
					min={min}
					max={max}
					formats={
						props.view == 'month'
							? formats
							: {
								dayFormat: (date, culture, localizer) =>
									localizer.format(date, 'dddd D/M', culture).capitalize(),
							}
					}
					messages={{
						noEventsInRange: (
							<span
								style={{ display: 'block' }}
								className='color--meta text--center weight--bold'
							>
								{props.view != 'agenda' ? translate('no-schedule-for-today') : translate('There are no activities for the selected day.')}
							</span>
						),
					}}
					titleAccessor={renderEventTitle}
					onSelectEvent={onSelectEvent}
					onView={() => true}
					onNavigate={() => true}
					selectable={props.view == 'month' ? true : !loggedInUser.isStudent() && props.userId === null && service.advancedSchedule ? true : false}
					components={{
						month: {
							header: MonthHeader,
						},
					}}
					onSelectSlot={handleClickCalendar}
					dayLayoutAlgorithm="no-overlap"
					onDrillDown={(date) => { if (props.goToDate) props.goToDate(date); }}
				/>

				{props.view !== 'month' && props.view !== 'agenda' && !loggedInUser.isStudent() && !props.userId ? (
					<div className='DayDisplayer'>
						<Button
							type='no_background'
							style={{
								marginLeft: 0,
								marginRight: '0.5em',
							}}
							onClick={() => setDisplayWholeDay((prev) => !prev)}
						>
							{!displayWholeDay ? (
								<div>{translate('Display-whole-day')}</div>
							) : (
								<div>{translate('Display-less')}</div>
							)}
						</Button>
					</div>
				) : null}
			</div>

			{props.view !== 'month' &&
				<div
					className={timeButtonOpen ? 'timeLineOpen' : 'timeLineOpen notVisible'}
					ref={containerRef}
					style={{
						position: 'absolute',
						left: buttonCoords?.left,
						top: buttonCoords?.top,
						right: buttonCoords?.right,
						margin: buttonCoords?.margin ? '3em auto' : 'auto'
					}}
				>
					<button
						onClick={() => props?.scheduleButtons('create-lesson', customButton[0]?.startTime)}
					>
						<div>
							<Icon name='Calendar_bw' />
						</div>
						<span className='timeline_title'>
							{translate('Lesson')}
						</span>
					</button>
					<button
						onClick={() =>
							props?.scheduleButtons('Create assignment', customButton[0]?.startTime)
						}
					>
						<div>
							<Icon name='assignment' />
						</div>
						<span className='timeline_title'>
							{translate('Assignment')}
						</span>
					</button>
					<button
						onClick={() =>
							props?.scheduleButtons('Create group assignment', customButton[0]?.startTime)
						}
					>
						<div className='Assignment_Group_icon'>
							<Icon name='Assignment_Group' />
						</div>
						<span className='timeline_title'>
							{translate('group-assignment')}
						</span>
					</button>
					<button
						onClick={() =>
							props?.scheduleButtons('Create conflicting event', customButton[0]?.startTime)
						}
					>
						<div>
							<Icon name='Calendar_Event' />
						</div>
						<span className='timeline_title'>
							{translate('Calendar event')}
						</span>
					</button>
				</div>
			}
		</div>
	);
};

export default ScheduleView;
