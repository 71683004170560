import { getDirectoryContents, getDriveSectionContent, getDrivesForSection, setDirectoryActive, setSectionContentActive } from 'actions/onedrive';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { FileList, Spinner } from 'UI';
import { Button } from '@haldor/ui';

class OneDrivePicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFiles: [],
			isLoading: false,
			sharepoint: false,
			sharepointDrive: null,
		}
	}

	componentDidMount() {
		this.goRoot();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.section && nextProps.section !== this.props.section) {
			this.props.getDrivesForSection(nextProps.section.graphId);
		}
	}

	onChangeDirectory = (id, force) => {
		const { sharepoint, sharepointDrive } = this.state;
		const { section } = this.props;
		const { activeContent, fetchedFolders } = this.props.onedrive;
		const foundObject = fetchedFolders.find(function (dir) {
			if (id != null) {
				return dir.id === id;
			} else {
				return dir.id === sharepointDrive.id;
			}
		});

		if (typeof (foundObject) === 'undefined' || force) {
			// We need to get this directory from the API
			this.setState({ isLoading: true });

			if (sharepoint) {
				this.props.getDriveSectionContent(section.graphId, sharepointDrive.id, id, (data) => {
					this.setState({ isLoading: false });
				});
			} else {
				this.props.getDirectoryContents(id, () => {
					this.setState({ isLoading: false });
				});
			}
		} else {
			if (sharepoint) {
				if (foundObject.id == sharepointDrive.id) {
					this.props.setSectionContentActive(sharepointDrive.id, null);
				} else {
					this.props.setSectionContentActive(sharepointDrive.id, foundObject.id);
				}
			} else {
				this.props.setDirectoryActive(foundObject.id);
			}
		}
	}

	goRoot = () => {
		this.onChangeDirectory('root');
	}

	onSharePointFolder = (file) => {
		if (file.folder) {
			this.onChangeDirectory(file.id);
		}
	}

	onSelectFile = (file) => {
		if (file.folder) {
			this.onChangeDirectory(file.id);
		} else {
			let { selectedFiles } = this.state;

			const foundObject = selectedFiles.findIndex(function (selF) {
				return selF.id == file.id;
			});

			if (foundObject !== -1) {
				// File got unselected
				selectedFiles.splice(foundObject, 1);
			} else {
				// File got selected
				selectedFiles.push(file);
			}

			this.setState({ selectedFiles }, () => {
				if (this.props.onChange) {
					this.props.onChange(this.state.selectedFiles);
				}
			});
		}
	}

	onDriveClick = (drive) => {
		this.setState({ sharepoint: true, sharepointDrive: drive }, () => {
			this.onChangeDirectory();
		});
	}

	updateCurrentFolder = () => {
		const { activeContent } = this.props.onedrive;

		this.onChangeDirectory(activeContent.id, true);
	}

	setSharepointMode = () => {
		this.setState({ sharepoint: !this.state.sharepoint });
	}

	setOneDriveMode = () => {
		this.setState({ sharepoint: false }, () => {
			this.goRoot();
		});
	}

	renderFileList = () => {
		const { activeContent, activeFolders, sectionContent } = this.props.onedrive;
		const { sharepoint, isLoading } = this.state;

		if (sharepoint) {
			return (
				<div>
					{isLoading ?
						<div>
							<Spinner medium center />
						</div> :
						sectionContent !== null ?
							sectionContent.files.length > 0 ?
								<FileList
									files={sectionContent.files}
									selectedFiles={this.state.selectedFiles}
									onFolderClick={this.onSharePointFolder}
									onFileClick={this.onSelectFile} />
								:
								<div>
									<span>{this.props.translate('no-files')}</span>
								</div>
							: null}
				</div>
			);
		} else {
			return (
				<div>
					{isLoading ?
						<div>
							<Spinner medium center />
						</div> :
						activeContent != null ?
							activeContent.files.length > 0 ?
								<FileList
									files={activeContent.files}
									selectedFiles={this.state.selectedFiles}
									onFolderClick={this.onSelectFile}
									onFileClick={this.onSelectFile} />
								:
								<div>
									<span>{this.props.translate('no-files')}</span>
								</div>
							: null}
				</div>
			);
		}
	}

	render() {
		// Define Onedrive constants from the state
		const { activeContent, activeFolders, sectionDrives } = this.props.onedrive;
		const { isLoading, sharepoint, sharepointDrive } = this.state;
		const { section } = this.props;

		return (
			<div className="onedrive-picker">
				<div className="sidebar">
					<div className="directory-breadcrumb">
						<div className="items-container">
							<strong>OneDrive</strong>
							<div className="sidebar-item" onClick={this.setOneDriveMode}>
								Filer
							</div>

							<div style={{ clear: 'both' }} />
						</div>

						{section ?
							sectionDrives == null ?
								<div>
									<Spinner small center />
								</div>
								:
								<div>
									{sectionDrives.length > 0 ?
										<div className="items-container">
											<strong>{section.title}</strong>

											{sectionDrives.map(drive => {
												return <div onClick={
													e => {
														this.onDriveClick(drive)
													}
												}>
													<div className="sidebar-item">
														{drive.name}
													</div>

													<div style={{ clear: 'both' }} />
												</div>;
											})}

										</div>
										:
										<div>
											Det finns inga drives i denna grupp
										</div>
									}
								</div>
							: null}
					</div>
				</div>

				<div className="content">
					<div className="directory-breadcrumb">
						<div className="pull-left">
							{sharepoint ?
								<div style={{ display: 'inline-block' }}>
									<strong>{section.title}</strong>

									{sharepointDrive ?
										<div style={{ display: 'inline-block' }}>
											&nbsp;>&nbsp;
											<span onClick={e => { this.onDriveClick(sharepointDrive) }}>
												{sharepointDrive.name}
											</span>
										</div>
										: null}
								</div>
								:
								<div style={{ display: 'inline-block' }}>
									<strong>OneDrive</strong>&nbsp;>&nbsp;
									<span onClick={e => { this.onSelectFile({ folder: true, id: 'root' }) }}>
										{this.props.translate('files')}
									</span>
								</div>
							}

							{activeFolders.length > 0 ?
								activeFolders.map(activeFolder => {
									return <div style={{ display: 'inline-block' }}>
										&nbsp;>&nbsp;
								<span key={`one-head-${activeFolder.id}`}
											onClick={e => { this.onSelectFile(activeFolder) }}>
											{activeFolder.name}</span>
									</div>;
								}) : null}
						</div>

						<Button
							style={{ float: 'right' }} onClick={this.updateCurrentFolder}
							className={"c--button"} type="secondary">
							{this.props.translate('refresh')}
						</Button>

						<div style={{ clear: 'both' }} />
					</div>

					<div>
						{this.renderFileList()}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		onedrive: state.onedrive,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	getDirectoryContents,
	setDirectoryActive,
	getDrivesForSection,
	getDriveSectionContent,
	setSectionContentActive,
})(OneDrivePicker);
