import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, isValid } from 'redux-form';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { getSections } from 'actions/sections';
import { getRemainingSMS, getSMSEducationSections, getSMSMentorSections } from 'actions/sms';

import UserSelect from 'components/List/UserSelect';
import { List, Expandable, Spinner } from 'UI';
import { Flex, Button, Icon } from '@haldor/ui';

import './_smsForm.scss';

class SMSForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			error: null,
			loading: false,
			loadingEducationSections: true,
			loadingMentorSections: true,
		};
	}

	componentDidMount = () => {
		this.props.getRemainingSMS();

		if (this.props.educationSections == null || this.props.educationSections.length == 0) {
			this.props.getSMSEducationSections().then(() => {
				this.setState({ loadingEducationSections: false });
			});
		} else {
			this.setState({ loadingEducationSections: false });
		}

		if (this.props.mentorSections == null || this.props.mentorSections.length == 0) {
			this.props.getSMSMentorSections().then(() => {
				this.setState({ loadingMentorSections: false });
			});
		} else {
			this.setState({ loadingMentorSections: false });
		}
	}

	userSelectLoading = (loading) => {
		this.setState({ loading });
	}

	UNSAFE_componentWillMount = () => {
		if (this.props.initialValues != null) {
			this.props.initialize({
				sender: 'Haldor',
				...this.props.initialValues,
			});

			return false;
		}

		this.props.initialize({
			sender: 'Haldor',
		});
	}

	submit = (values) => {
		return new Promise(resolve => {
			values.targetAudience = 'STUDENTS';

			if (values.status == null) {
				values.status = 'SMS_PUBLISHED';
			}

			// Remove internal form properties from data
			delete values.targetGuardians;
			delete values.targetStudents;

			if (this.props.onSubmit != null) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					})
					.catch((error) => {
						this.setState({ error });
						resolve(1);
					});
			} else {
				resolve(1);
			}
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onCancel != null) {
			this.props.onCancel();
		}
	}

	/* Redux form functions */
	required = (value) => {
		if (value != null && value !== '') {
			return undefined;
		}

		return this.props.translate('field-needs-input');
	}

	validateRelationships = (relationships) => {
		if (relationships == null || relationships.length == 0) {
			return this.props.translate('select-relation-error');
		}

		return undefined;
	}

	render() {
		const { submitting, handleSubmit, translate, valid } = this.props;

		let numberOfCharacters = 0;
		let numberOfRecipients = 0;

		numberOfCharacters += this.props.titleValue?.length ?? 0;
		numberOfCharacters += this.props.contentValue?.length ?? 0;

		let numberOfMessages = Math.ceil(numberOfCharacters / 160);
		if (numberOfMessages == 0) {
			numberOfMessages = 1;
		}

		if (this.props.relationshipsValue != null) {
			let students = [];
			this.props.relationshipsValue.forEach((relationship) => {
				if (relationship.referenceType != 'SECTION' && relationship.mobilePhone != null && relationship.mobilePhone != '') {
					if (relationship.mobilePhone.length > 4 && isValidPhoneNumber(relationship.mobilePhone)) {
						if (students.indexOf(relationship.referenceId) == -1) {
							students.push(relationship.referenceId);
						}
					}
				}
			});

			numberOfRecipients = students.length;
		}

		const amount = numberOfMessages * numberOfRecipients;
		let sections = [];
		if (this.props.educationSections != null) {
			sections = sections.concat(this.props.educationSections);
		}

		if (this.props.mentorSections != null) {
			sections = sections.concat(this.props.mentorSections);
		}

		return (
			<div className="form-container sms-form">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component file-upload">
					{this.state.error != null ?
						<div className="form-row">
							<div style={{ marginBottom: '1rem', color: 'red' }}>
								{this.state.error}
							</div>
						</div>
						: null}

					<Expandable
						contentStyles={{ padding: '1em 0rem' }}
						contentStylesClosed={{ padding: '0' }}
						whiteBackground={true}
						title={this.props.translate('students') + '*'}
						open ignorePropsUpdate
					>
						{!this.state.loadingEducationSections && !this.state.loadingMentorSections ?
							<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
								<Field
									component={UserSelect}
									name="relationships"
									userSelectDisabled={false}
									showUsersWithoutMentorGroups
									showPhonenumbers
									alwaysFetchStudents
									sections={sections}
									validate={this.validateRelationships}
									setIsLoading={this.userSelectLoading}
								/>
							</List>
							:
							<Spinner center />
						}
					</Expandable>

					<div className="form-row">
						<label>
							{translate('Sender')}
						</label>

						<Field
							name="sender"
							type="text"
							component="input"
							disabled={true}
						/>
					</div>

					<div className="form-row">
						<label>
							{translate('Content')}
						</label>

						<div className="fake-input">
							<Flex center>
								<Field
									name="title"
									type="text"
									component="input"
									placeholder={this.props.translate('Title (not required)')}
								/>
							</Flex>

							<Field
								name="message"
								type="textarea"
								component="textarea"
								validate={this.required}
								placeholder={this.props.translate("Message")}
							/>
						</div>

						<div style={{ marginTop: '1rem' }}>
							{this.props.translate('Amount of messages')}:
							<div className="card-meta" style={{ marginRight: '1.65rem' }}>
								{numberOfMessages}
							</div>

							{this.props.translate('Amount of characters')}:
							<div className="card-meta">
								{(160 * numberOfMessages) - numberOfCharacters}
							</div>
						</div>
					</div>

					<div className="form-row">
						<label>{this.props.translate('Sms-debiting')}</label>

						{this.props.remaining != null && this.props.remaining <= this.props.limit ?
							<div className="sms-limit-notice">
								<Icon name="Alert_Red" />
								{this.props.translate('Your school have {{remaining}} sms remaining and need to top up the account.', this.props.remaining)}
							</div>
							: null}

						<table className="debit-table" cellSpacing="0">
							<tbody>
								<tr>
									<td>{this.props.translate('Sms-balance for your school:')}</td>
									<td>{this.props.remaining}</td>
								</tr>

								<tr>
									<td>{this.props.translate('Number of messages:')}</td>
									<td>{numberOfMessages}</td>
								</tr>

								<tr>
									<td style={{ paddingBottom: '.65rem' }}>
										{this.props.translate('Number of recipients:')}
									</td>
									<td style={{ paddingBottom: '.65rem' }}>
										{numberOfRecipients}
									</td>
								</tr>

								<tr>
									<td>{this.props.translate('Number of messages that will be sent:')}</td>
									<td>{amount}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button
							onClick={handleSubmit(values =>
								this.submit({ ...values, status: "SMS_PUBLISHED" })
							)}
							disabled={submitting || !valid || this.state.loading || (this.props.remaining - amount) < -2000}
							type="primary"
						>
							{translate('Send')}

							{this.state.loading ?
								<Spinner />
								: null}
						</Button>

						<Button
							onClick={handleSubmit(values =>
								this.submit({ ...values, status: "SMS_DRAFT" })
							)}
							type="secondary"
							disabled={submitting || !valid || this.state.loading}
						>
							{this.props.translate("save-draft")}

							{this.state.loading ?
								<Spinner />
								: null}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{translate('Cancel')}
							</Button>
						</div>
					</div>
				</form>

			</div>
		);
	}
}

const FORM_NAME = 'SMSForm';
const getFormValue = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
	const contentValue = getFormValue(state, 'message');
	const titleValue = getFormValue(state, 'title');
	const relationshipsValue = getFormValue(state, 'relationships');

	return {
		translate: translate(state.Languages.translations),
		remaining: state.SMS.remaining,
		limit: state.SMS.limit,
		educationSections: state.SMS.educationSections,
		mentorSections: state.SMS.mentorSections,
		contentValue: contentValue,
		titleValue: titleValue,
		relationshipsValue: relationshipsValue,
	}
}

export default connect(mapStateToProps, {
	getSections,
	getRemainingSMS,
	getSMSEducationSections,
	getSMSMentorSections,
})(reduxForm({
	form: FORM_NAME,
	destroyOnUnmount: true,
})(SMSForm));