import { addError } from 'actions';
import { submit_post, update_post, deletePostFile } from 'actions/posts';
import { getMentorGroups, getSection, getSections } from 'actions/sections';
import moment from 'moment';

import User from '_class/User';

import GroupUserSelect from 'components/List/GroupUserSelect';
import UserSelect from 'components/List/UserSelect';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Expandable, List, Spinner } from 'UI';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox, Radio, Icon, translate } from '@haldor/ui';
import { Close, Image } from 'UI/Icons';
import { Editor } from 'UI/Inputs';
import DatePickerEvent from './Partials/DatePickerEvent';
import swal from 'sweetalert2';
import FileItem from 'components/FileItem';

import getEnvironment from 'lib/env';
import { getToken, loginRequest, http } from 'lib/msal';

class Post extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};

	static defaultProps = {
		selectMultipleSchools: false,
	};

	constructor(props) {
		super(props);

		let uploadedFiles = [];
		if (props.activePost != null && props.activePost.associatedFiles != null) {
			uploadedFiles = props.activePost.associatedFiles;
		}

		this.state = {
			Files: [],
			uploadedFiles: uploadedFiles,
			removedFiles: [],
			important: props.activePost != null ? props.activePost.important : false,
			dateStart: null,
			dateEnd: null,
			published: null,
			error: null,
			uploadProgress: null,
		}

		this.dropzone = null;

		/* Configure dropzone component */
		this.componentConfig = {
			iconFiletypes: ['.jpg', '.png', '.gif'],
			showFiletypeIcon: false,
			postUrl: http.defaults.baseURL + 'fileItems',
			dropzoneSelector: '.dropzone-trigger',
		};

		this.djsConfig = {
			autoProcessQueue: false,
			parallelUploads: 1,
			timeout: 99900000,
			headers: {},
		};

		let request = loginRequest;
		request.scopes = [
			...getEnvironment().scopes,
			...request.scopes
		];

		getToken(request).then((response) => {
			this.djsConfig.headers['Authorization'] = 'Bearer ' + response.accessToken;
		});

		this.dropzoneEvents = {
			init: this.dropzoneInit,
			addedfile: this.onAddFile,
			success: () => {
				this.dropzone.processQueue();
			},
		};
	}

	/* Redux form functions */
	required = (value) => {
		if (value != null && value !== '') {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	quillValidation = (value) => {
		var strValue = value;

		var text = '';
		if (typeof (strValue) == 'string') {
			text = strValue.replace(/<(?:.|\n)*?>/gm, '');
		}

		if (text == "" || text == null) {
			return this.props.translate('needs-description');
		}

		return undefined;
	}

	validateRelationships = (value) => {
		if (value == null || value == '') {
			return this.props.translate('select-relation-error');
		}

		if (value.length == 0) {
			return this.props.translate('select-relation-error');
		}

		return undefined;
	}

	componentDidMount = () => {
		let promises = [];
		const user = new User(this.props.currentUser);
		if ((user.isMentor() || user.isSchoolLeader() || user.isAdministrator()) && this.props.mentorGroups.length == 0) {
			let mentorPromise = this.props.getMentorGroups();
			promises.push(mentorPromise);
		}

		if (this.props.sections == null) {
			let sectionPromise = this.props.getSections();
			promises.push(sectionPromise);
		}

		if (promises.length > 0) {
			this.setState({ loading: true });
		}

		Promise.all(promises).then(() => {
			this.setState({ loading: false });
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
	}

	submit = (values) => new Promise((resolve, reject) => {
		if (values.relationships == null) {
			this.setState({ error: this.props.translate('select-relation-error') + '!' });

			reject(0);
			return false;
		}

		const { activePost } = this.props;
		const isEditing = (activePost != null && Object.keys(activePost).length > 0);
		const user = new User(this.props.currentUser);

		if (this.dropzone && this.dropzone.getQueuedFiles().length > 0) {
			this.dropzone.on('totaluploadprogress', (progress) => {
				this.setState({ uploadProgress: progress });
			});

			const that = this;
			this.readyForSubmit(values).then((data) => {
				let url = `${http.defaults.baseURL}posts/${data}/files?schoolId=${user.getActiveSchool().id}`;
				if (isEditing) {
					url = `${http.defaults.baseURL}posts/${activePost.id}/files?schoolId=${user.getActiveSchool().id}`;
				}

				this.componentConfig.postUrl = url;
				this.dropzone.options.url = url;
				this.dropzone.processQueue();

				this.dropzone.on("queuecomplete", () => {
					if (isEditing) {
						if (that.props.onModalClose) {
							that.props.onModalClose(true, true);
						}
					} else {
						this.props.history.push(`/post/${data}`)
					}

					resolve(data);
				});
			});
		} else {
			const that = this;
			this.readyForSubmit(values).then((data) => {
				if (isEditing) {
					if (that.props.onModalClose) {
						that.props.onModalClose(true, true);
					}
				} else {
					this.props.history.push(`/post/${data}`);
				}

				resolve(data);
			});
		}
	});

	readyForSubmit = (values) => {
		return new Promise((resolve, reject) => {
			const {
				removedFiles,
				dateStart,
				dateEnd,
				published,
				important,
			} = this.state;

			const { submit_post, update_post, activePost } = this.props;
			const isEditing = (activePost != null && Object.keys(activePost).length > 1);

			/* Add custom inputs to the values object that redux form creates */
			values.eventDate = dateStart;
			values.validUntil = dateEnd;
			values.published = published;
			values.important = important;
			values.targetAudience = '';

			if (values.targetStudents) {
				values.targetAudience += 'STUDENTS:';
			}

			if (values.targetGuardians) {
				values.targetAudience += 'GUARDIANS:';
			}

			if (values.targetGuardians && values.targetStudents) {
				values.targetAudience = 'ALL';
			}

			if (values.targetAudience[values.targetAudience.length - 1] == ':') {
				values.targetAudience.substring(0, values.targetAudience.length - 1);
			}

			if (values.status == null) {
				values.status = 'POST_PUBLISHED';
			}

			// Remove internal form properties from data
			delete values.targetGuardians;
			delete values.targetStudents;

			if (values.sender != null) {
				if (values.sender == 'me') {
					values.author = null;
				}

				delete values.sender;
			}

			// Convert dates to UTC
			values.published = moment(values.published).utc().format();
			if (values.eventDate != null) {
				values.eventDate = moment(values.eventDate).utc().format();
			}

			if (values.validUntil != null) {
				values.validUntil = moment(values.validUntil).utc().format();
			}

			if (isEditing) {
				if (removedFiles.length > 0) {
					removedFiles.map((file) => {
						this.props.deletePostFile(values.id, file.id);
					});
				}

				// Clear out the "files" object with fetched files to save data
				values.files = null; // Do NOT send the file src in the PUT request

				update_post(values, (data) => {
					resolve(data);
				});
			} else {
				submit_post(values).then(() => {
					resolve(this.props.createdPost.id);
				});
			}
		});
	}

	onDateChange = (dates) => {

		dates.start = dates.start != null ? moment(dates.start) : null;
		dates.end = dates.end != null ? moment(dates.end) : null;
		dates.published = dates.published != null ? moment(dates.published) : null;

		this.setState({ dateStart: dates.start, dateEnd: dates.end, published: dates.published });
	}

	dropzoneInit = (dropzone) => {
		this.dropzone = dropzone;
	}

	onAddFile = (file) => {
		let { Files } = this.state;

		if (file.size > 101000024) {
			this.dropzone.removeFile(file);

			// File over 100MB
			swal.fire({
				title: this.props.translate('file-too-big'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('okey'),
			});

			return false;
		}

		Files.push(file);

		setTimeout(() => {
			this.setState({ Files });
		}, 40);
	}

	onRemoveFile = (file) => {
		let { Files } = this.state;

		const fileIndex = Files.findIndex(function (f) {
			return f.upload.filename === file.upload.filename;
		});

		Files.splice(fileIndex, 1);
		this.setState({ Files }, () => {
			if (this.dropzone) {
				this.dropzone.removeFile(file);
			}
		});
	}

	removeFileFromDocument = (file) => {
		let { uploadedFiles, removedFiles } = this.state;

		if (file != null) {
			const filesIndex = uploadedFiles.findIndex(function (f) {
				return f.id === file.id;
			});

			const fileObject = uploadedFiles[filesIndex];

			uploadedFiles.splice(filesIndex, 1);
			removedFiles.push(fileObject);

			this.setState({ uploadedFiles, removedFiles });
		}
	}

	importantToggle = (checked) => {
		this.setState({ important: checked });
	}

	renderInput = ({ input, label, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span className="error">{error}</span>) || (warning &&
						<span className="error">{warning}</span>))
				}
			</div>
		);
	}

	renderTextarea = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<textarea placeholder={placeholder || label} type={type} {...input}>
					{input.value}
				</textarea>

				{touched && ((error &&
					<span style={{ marginTop: '1rem' }}>{error}</span>) || (warning &&
						<span style={{ marginTop: '1rem' }}>{warning}</span>))}
			</div>
		);
	}

	triggerDropzone = () => {
		if (this.dropzone != null) {
			this.dropzone.hiddenFileInput.click();
		}
	}

	render() {
		let sections = [];

		let initialValues = JSON.parse(JSON.stringify(this.props.activePost))
		const isEditing = (initialValues != null && initialValues.id != null);

		if (initialValues == null) {
			initialValues = {};
		}

		if (isEditing) {
			const { targetAudience, author } = initialValues;
			const { currentUser } = this.props;

			initialValues.targetStudents = targetAudience == 'ALL' || targetAudience?.indexOf('STUDENTS') > -1;
			initialValues.targetGuardians = targetAudience == 'ALL' || targetAudience?.indexOf('GUARDIANS') > -1;
			initialValues.sender = author == currentUser.firstName + ' ' + currentUser.lastName ? 'me' : 'custom';
		} else {
			initialValues.targetStudents = true;
			initialValues.targetGuardians = true;
			initialValues.sender = 'me';
		}

		const user = new User(this.props.currentUser);

		if (user.isMentor() || user.isSchoolLeader() || user.isAdministrator()) {
			sections = this.props.sections.concat(this.props.mentorGroups);
		} else {
			sections = this.props.sections;
		}

		if (!(user.isSchoolLeader() || user.isAdministrator())) {
			// Only show groups user is owner for
			sections = sections.filter(section => {
				return section.userRole === "OWNER";
			});
		}

		if (this.state.loading) {
			return <Spinner center />
		}

		/* Validate group selection */
		let passingGroup = true;
		return (
			<div className="form-container">
				<Form
					onSubmit={this.submit}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'POST_DRAFT');
						},
						setStatusPublished: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'POST_PUBLISHED');
						},
					}}
					initialValues={initialValues}
					initialValuesEqual={() => true}
					render={({ handleSubmit, form, valid, submitting, initialValues }) => {
						let { Files, uploadedFiles, important } = this.state;

						return (
							<form onSubmit={handleSubmit} className="form form-component">
								{submitting ?
									<div className="is_sending">
										<p>
											<span className="loading-spinner" />
											<br />

											{this.state.uploadProgress != null ?
												<div className="upload-progress">
													<div style={{ width: Math.round(this.state.uploadProgress) + '%' }} className="upload-progress-bar" />
												</div>
												: null}
										</p>
									</div>
									: null}

								<Expandable
									contentStyles={{ padding: '1em 0rem' }}
									contentStylesClosed={{ padding: '0' }}
									headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
									whiteBackground={true}
									title={user.getActiveSchool()?.type == 'PRESCHOOL' ?
										this.props.translate('groups-and-children') + '*'
										: this.props.translate('students') + '*'}
									open={!isEditing}
									ignorePropsUpdate
								>
									{this.props.section != null ?
										<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
											<Field
												component={GroupUserSelect}
												name="relationships"
												section={this.props.section}
												validate={this.validateRelationships}
											/>
										</List>
										:
										<List style={{ border: '0', margin: '0', boxShadow: 'none' }}>
											<Field
												component={UserSelect}
												name="relationships"
												sections={sections}
												userSelectDisabled={false}
												selectMultipleSchools={this.props.selectMultipleSchools}
												showUsersWithoutMentorGroups
												validate={this.validateRelationships}
											/>
										</List>
									}
								</Expandable>

								<div className="form-row">
									<label>{this.props.translate('select-recipient')}</label>

									<div style={{ display: 'inline-block' }}>
										<Field
											name="targetGuardians"
											component={Checkbox}
											label={this.props.translate('Guardians')}
										/>
									</div>

									<div style={{ marginLeft: '1.25rem', display: 'inline-block' }}>
										<Field
											name="targetStudents"
											component={Checkbox}
											label={this.props.translate('students')}
										/>
									</div>

									<div className="size-12 color--meta recipient-alert">
										<Icon name="Alert" bw /> {this.props.translate('Posts will always be displayed for staff belonging to the groups you have selected.')}
									</div>
								</div>

								<div className="form-row">
									<label>{this.props.translate('Sender')}</label>

									<div style={{ display: 'inline-block' }}>
										<Field
											component={Radio}
											name="sender"
											label={this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName}
											optionValue="me"
										/>
									</div>

									<div style={{ display: 'inline-block', marginLeft: '.85rem' }}>
										<Field
											component={Radio}
											name="sender"
											optionValue="custom"
											label={this.props.translate('Free text') + (this.props.senderValue == 'custom' ? '*' : '')}
										/>
									</div>
								</div>

								{form.getFieldState('sender')?.value == 'custom' ?
									<div className="form-row" style={{ marginTop: '.35rem' }}>
										<Field
											name="author"
											type="text"
											component={this.renderInput}
											validate={this.required}
										/>
									</div>
									: null}

								{this.state.error != null ?
									<div className="form-row error">
										{this.state.error}
									</div>
									: null}

								<div className="form-row">
									<label>{this.props.translate('title')}*</label>
									<Field
										name="title"
										type="text"
										component={this.renderInput}
										placeholder={this.props.translate('title')}
										validate={this.required}
									/>
								</div>

								<div className="form-row">
									<label>{this.props.translate('description')}*</label>

									<Field
										component={Editor}
										name="body"
										validate={this.quillValidation}
										placeholder={this.props.translate('description')}
										translate={this.props.translate}
									/>
								</div>

								<div className="form-row">
									<label>{this.props.translate('add-images-and-files')}</label>

									<div className="dropzone-trigger icon-container" style={{ height: 'auto', width: '100%' }}>
										<div onClick={this.triggerDropzone} className="icon">
											<Image />
										</div>

										<div className="text" onClick={this.triggerDropzone}>
											{this.props.translate('drop-files-or-click-here')}
										</div>

										<DropzoneComponent
											djsConfig={this.djsConfig}
											eventHandlers={this.dropzoneEvents}
											config={this.componentConfig}
										/>
									</div>

									<div style={{ clear: 'both' }}></div>
								</div>

								<div className="form-row">
									{Files && Files.length > 0 ?
										<div>
											<div>
												<label>{this.props.translate('images-needs-uploading')}</label>
											</div>

											<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', display: 'flex' }}>
												{Files.map(file => {
													return (
														<div
															key={file.lastModified}
															style={file.type.indexOf('image') > -1 ? {
																background: `url(${file.dataURL})`
															} : {}}
															className={`dropzone-file-preview ${!file.accepted ? " not-accepted" : ""} ${file.upload.progress > 0 ? ' upload-started' : ' not-started'} ${file.type.indexOf('image') > -1 ? ' image' : ''}`}
														>
															<div className="file-name">
																<div style={{ flex: 1, maxWidth: 'calc(100% - 1rem)', wordWrap: 'break-word' }}>
																	{file.name + ' '}

																	({file.size > 1048576 ?
																		Math.ceil(file.size / 1024 / 1024) + 'MB'
																		:
																		Math.max(Math.ceil(file.size / 1024), 1) + 'KB'
																	})
																</div>

																<div onClick={e => { this.onRemoveFile(file) }} className="file-delete-btn">
																	<Close />
																</div>
															</div>

															{file.accepted && file.size > 7500000 ?
																<div className="file-warning">
																	<Icon name="Info_Blue" />

																	<div style={{ flex: 1 }}>
																		{this.props.translate('size-warning')}
																	</div>
																</div>
																: null}

															{!file.accepted ?
																<div className="file-warning">
																	<Icon name="Alert" />

																	<div style={{ flex: 1 }}>
																		{this.props.translate('file-too-big')}
																	</div>
																</div>
																: null}

															{file.type.indexOf('image') > -1 ?
																<div className="overlay"></div>
																: null}
														</div>
													);
												})}
											</div>
										</div>
										: null}
								</div>

								<div className="form-row">
									<label>{this.props.translate('important')}</label>
									<Checkbox
										value={this.state.important}
										checked={this.state.important}
										onChange={this.importantToggle}
										label={this.props.translate('is-this-event-important')}
									/>
								</div>

								<div className="form-row">
									<DatePickerEvent
										onChange={this.onDateChange}
										values={initialValues}
										showEnd={important}
									/>
								</div>

								{isEditing && initialValues.associatedFiles != null ?
									<div className="form-row" style={{ marginTop: '1rem' }}>
										{uploadedFiles != null && uploadedFiles.length > 0 && initialValues.associatedFiles.length > 0 ?
											<div>
												<div>
													<label>{this.props.translate('images-attached')}</label>
												</div>

												<div style={{ marginLeft: '-0.5rem', marginRight: '-0.5em', display: 'flex' }}>
													{uploadedFiles.map((file, index) => {
														return <FileItem element="div" file={file} key={file.id} className={`dropzone-file-preview ${file.type.indexOf('image') > -1 ? ' image' : ''}`}>
															<div className="file-name">
																<div style={{ flex: 1 }}>
																	{file.name}
																</div>

																<div onClick={(e) => { this.removeFileFromDocument(file) }} className="file-delete-btn">
																	<Close />
																</div>
															</div>

															{file.type.indexOf('image') > -1 ?
																<div className="overlay"></div>
																: null}
														</FileItem>
													})}
												</div>
											</div>
											: null}
									</div>
									: null}

								<div className="clearfix"></div>

								<div className="form-divider" />

								{!passingGroup ?
									<div className="form-row spacing submit">
										{this.props.translate('preschooldocument-form-studentgrouperror')}
									</div>
									: null}

								<div className="form-row spacing submit">
									{isEditing ?
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.mutators.setStatusPublished();
												form.submit();
											}}
											disabled={submitting || !valid || !passingGroup || (this.state.important == true && this.state.dateStart == null)}
										>
											{this.props.translate('publish')}
										</Button>
										:
										<Button disabled={submitting || !valid || !passingGroup || (this.state.important == true && this.state.dateStart == null)}>
											{this.props.translate('publish')}
										</Button>
									}

									{isEditing && initialValues.status != "POST_DRAFT" ? null : (
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.mutators.setStatusDraft();
												form.submit();
											}}
											type="secondary"
											disabled={submitting || !valid || (this.state.important == true && this.state.dateStart == null)}
										>
											{this.props.translate("save-draft")}
										</Button>
									)}

									<div className="align-right">
										<Button onClick={this.onCancel} disabled={submitting} type="secondary">
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						)
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		sections: state.sections.educationGroups,
		mentorGroups: state.sections.mentorGroups,
		currentUser: state.user.currentUser,
		createdPost: state.Posts.createdPost,
		activePost: state.Posts.activePost,
	};
}

export default withRouter(connect(mapStateToProps, {
	submit_post,
	update_post,
	getSection,
	getSections,
	deletePostFile,
	addError,
	getMentorGroups,
})(Post));