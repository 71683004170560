import React, { useEffect } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { ON_ROUTE_CHANGE } from 'actions/header';

import UserDocuments from 'components/List/UserDocuments';
import Logout from 'components/Logout/Logout';
import App from 'containers/App';
import MyAssessments from 'containers/AssessmentsTab/MyAssessments';
import AttendanceTabSections from 'containers/AttendanceTab/AttendanceTabSections';
import SingleLesson from 'containers/Lesson/Single';
import NotFound from 'containers/NotFound';
import Plans from 'containers/Plans/Plans';
import SectionProgress from 'containers/Progress/Section';
import PrintApp from 'containers/PrintApp';
import SubmissionsView from 'containers/SinglePages/Assignment/SubmissionsView';
import PlanningWrapper from 'containers/SinglePages/Plan/PlanningWrapper';
import SingleAssignmentV1 from 'containers/SinglePages/SingleAssignmentV1';
import SingleCourse from 'containers/SinglePages/SingleCourse';
import TeamsApp from 'teams/App';
import CreateCourses from 'teams/containers/Courses/Create';
import HaldorTab from 'teams/containers/HaldorTab';
import CoursePlanConfigure from 'teams/containers/TabConfigure/CoursePlanConfigure';

import Auth from 'components/Auth';

import TeamsOnboardingApp from 'containers/TeamsOnboarding/App';

import { appInsights } from 'lib/appInsights';
import MyAssignmentsWrapper from 'containers/Assignments/MyAssignmentsWrapper';

const Routes = (props) => {
	let currentPath = window.location.pathname;

	useEffect(() => {
		props.history.listen((location) => {
			const forbiddenRoutes = ["language", "switch", "conversations/new", "Auth"]
			if (window.location.pathname == currentPath) {
				return false;
			}

			if (window.location.hostname != 'localhost') {
				appInsights.trackPageView({
					name: location.pathname,
					isLoggedIn: appInsights.context.user.authenticatedId != null,
				});
			}

			var route = forbiddenRoutes.find(t => location.pathname.indexOf(t) > -1);
			if (route != null) {
				return false;
			}
			const body = document.body;
			if (body != null) {
				if (body.style.overflow == 'hidden') {
					body.style.overflow = 'initial';
				}
				let menu = body.querySelector('.c--sider');
				if (menu != null && menu.style.display == 'none') {
					menu.style.display = 'block';
				}
				let header = body.querySelector('.desktop-header');
				if (header != null && header.style.display == 'none') {
					header.style.display = 'flex';
				}
			}
			currentPath = window.location.pathname;

			props.store.dispatch({
				type: ON_ROUTE_CHANGE,
			});

			window.scrollTo(0, 0);

			const event = new Event('route_change');
			document.dispatchEvent(event);
		})
	}, []);

	return (
		<Switch>
			<Route path="/teams/he" component={TeamsOnboardingApp} />

			{ /* Auth Redirect */}
			<Route path="/Auth" component={Auth} />
			<Route path="/logout" component={Logout} />

			{ /* Tab Applications */}
			<Route path="/assignments-tab" render={() => {
				return <TeamsApp>
					<Switch>
						<Route path="/assignments-tab/" exact component={MyAssignmentsWrapper} />
						<Route path="/assignments-tab/assignments" component={MyAssignmentsWrapper} />
						<Route path="/assignments-tab/assignment/:assignmentId/task/:taskId/documents" component={UserDocuments} />
						<Route path="/assignments-tab/assignment/:assignmentId/submission/:submissionId/documents" component={SubmissionsView} />
						<Route path="/assignments-tab/assignment/:assignmentId" component={SingleAssignmentV1} exact />
						<Redirect path="/assignments-tab/landingpage" to="/teams/he" />
					</Switch>
				</TeamsApp>
			}} />

			<Route path="/planning-tab" render={() => {
				return <TeamsApp>
					<Switch>
						<Route path="/planning-tab" exact component={Plans} />
						<Route path="/planning-tab/plan/:planId" component={PlanningWrapper} />
						<Route path="/planning-tab/plans" component={Plans} />
						<Route path="/planning-tab/assignment/:assignmentId/task/:taskId/documents" component={UserDocuments} />
						<Route path="/planning-tab/assignment/:assignmentId/submission/:submissionId/documents" component={SubmissionsView} />
						<Route path="/planning-tab/assignment/:assignmentId" component={SingleAssignmentV1} exact />
						<Redirect path="/planning-tab/landingpage" to="/teams/he" />
					</Switch>
				</TeamsApp>
			}} />

			<Route path="/assessments-tab" render={() => {
				return <TeamsApp>
					<Switch>
						<Route path="/assessments-tab" exact component={MyAssessments} />
						<Route path="/assessments-tab/groups/:groupId/progress/:userId" component={SectionProgress} />
						<Route path="/assessments-tab/assignment/:assignmentId/task/:taskId/documents" component={UserDocuments} />
						<Route path="/assessments-tab/assignment/:assignmentId/submission/:submissionId/documents" component={SubmissionsView} />
						<Route path="/assessments-tab/assignment/:assignmentId" component={SingleAssignmentV1} exact />
						<Route path="/assessments-tab/plan/:planId" component={PlanningWrapper} />
						<Redirect path="/assessments-tab/landingpage" to="/teams/he" />
					</Switch>
				</TeamsApp>
			}} />

			<Route path="/haldor-tab" render={() => {
				return <TeamsApp>
					<Switch>
						<Route path="/haldor-tab" exact component={HaldorTab} />

						<Route path="/haldor-tab/assignment/:assignmentId/submission/:submissionId/documents" component={SubmissionsView} />
						<Route path="/haldor-tab/assignment/:assignmentId/task/:taskId/documents" component={UserDocuments} />
						<Route path="/haldor-tab/assignment/:assignmentId" component={SingleAssignmentV1} exact />
						<Route path="/haldor-tab/assignments" component={MyAssignmentsWrapper} />

						<Route path="/haldor-tab/plan/:planId" component={PlanningWrapper} />

						<Route path="/haldor-tab/courses/create/:courseId" component={CreateCourses} />
						<Route path="/haldor-tab/courses/create" component={CreateCourses} />
						<Route path="/haldor-tab/course/:courseId" component={SingleCourse} />

						<Route path="/haldor-tab/groups/:groupId/progress/:userId" component={SectionProgress} />
						<Redirect path="/haldor-tab/landingpage" to="/teams/he" />
					</Switch>
				</TeamsApp>
			}} />

			<Route path="/attendance-tab" render={() => {
				return <TeamsApp>
					<Switch>
						<Route path="/attendance-tab" exact component={AttendanceTabSections} />
						<Route path="/attendance-tab/lesson/:id" component={SingleLesson} />
						<Redirect path="/attendance-tab/landingpage" to="/teams/he" />
					</Switch>
				</TeamsApp>
			}} />

			<Redirect path="/tab-configure" to="/teams/he/tab-configure" />
			<Redirect path="/landingpage" to="/teams/he/" />

			{ /* Configure pages */}
			<Route path="/course-plan-configure" component={CoursePlanConfigure} />

			{ /* Print Pages */}
			<Route path="/print" component={PrintApp} />

			{ /* Education Dashboard Application */}
			<Route path="/" component={App} />

			{ /* 404 Not Found Routes */}
			<Route path="*" component={NotFound} />
		</Switch>
	);
};

export default withRouter(Routes);