import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import swal from 'sweetalert2';

import { Flex, Button, Select, Checkbox, Icon, TooltipMenu } from '@haldor/ui';

import GenerateBaseGroupsRandomly from './GenerateBaseGroupsRandomly';

import './BaseGroupForm.scss';
import DisplayName from 'components/Presentation/DisplayName';

class BaseGroupForm extends Component {

	static defaultProps = {
		hideTitle: false,
	}

	constructor(props) {
		super(props);

		let units = [];
		let title = '';

		if (props.baseGroup != null) {
			units = props.baseGroup.units;
			title = props.baseGroup.title;
		}

		this.state = {
			title,
			units,
			activeStudents: [],
			submitting: false,
		};
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.baseGroup != null && nextProps.baseGroup != this.props.baseGroup) {
			this.setState({ units: nextProps.baseGroup.units, title: nextProps.baseGroup.title });
		}

		if (nextProps.baseGroup == null && nextProps.baseGroup != this.props.baseGroup) {
			this.setState({ units: [], title: '' });
		}
	}

	componentDidMount = () => {
		if (this.state.units.length > 0) {
			// Trigger onUpdate event when this form mounts and the value isnt empty
			this.onUpdate();
		}
	}

	/* Help methods - JHELP */
	getUnassignedStudents = () => {
		let students = [];
		this.props.section.students.forEach(student => {
			let isSelected = this.state.units.find(unit => {
				return unit.memberships.find(member => student.userId == member.userId);
			});

			if (isSelected != null) {
				return false;
			}

			students.push(student);
			students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
		});

		return students;
	}

	/* Event methods - JEVEN */
	setTitle = (event) => this.setState({ title: event.target.value });

	onUpdate = () => {
		if (this.props.onUpdate != null) {
			this.props.onUpdate(this.state.units);
		}
	}

	randomUnitsSubmit = (units) => {
		this.setState({ units }, () => {
			this.onUpdate();
		});
	}

	onStudentActiveToggle = (student) => {
		let { activeStudents } = this.state;
		let studentIndex = this.state.activeStudents.findIndex(activeStudent => {
			return activeStudent.userId == student.userId;
		});

		if (studentIndex > -1) {
			activeStudents.splice(studentIndex, 1);
		} else {
			activeStudents.push(student);
		}
		activeStudents.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
		this.setState({ activeStudents });
	}

	onStudentSelect = (student, unitTitle) => {
		let { units } = this.state;

		let unit = units.find(unit => {
			return unit.title == unitTitle;
		});

		if (unit == null) {
			unit = this.addUnit();
		}

		let isSelected = this.state.units.find(unit => {
			return unit.memberships.find(member => student.userId == member.userId);
		});

		if (isSelected != null) {
			let inMemberships = isSelected.memberships.findIndex(member => student.userId == member.userId);
			if (inMemberships > -1) {
				isSelected.memberships.splice(inMemberships, 1);
			}

			if (unit != null && unit.title != isSelected.title) {
				unit.memberships.push({
					userId: student.userId,
					user: student,
				});
			}
		} else {
			unit.memberships.push({
				userId: student.userId,
				user: student,
			});
		}

		this.setState({ units }, () => {
			this.onUpdate();
		});
	}

	assignStudentToNewGroup = (student) => {
		let unit = this.addUnit();

		this.onStudentSelect(student, unit.title);
	}

	setGroupOnActiveStudents = (unitTitle) => {
		this.state.activeStudents.forEach(student => {
			this.onStudentSelect(student, unitTitle);
		});

		this.setState({ activeStudents: [] });
	}

	createGroupWithActiveStudents = () => {
		let unit = this.addUnit();

		this.setGroupOnActiveStudents(unit.title);
	}

	addUnit = (e) => {
		if (e != null) {
			e.preventDefault();
		}

		let { units } = this.state;
		let unit = {
			title: this.props.translate('group') + ' ' + (this.state.units.length + 1),
			memberships: [],
		};

		units.push(unit);

		this.setState({ units }, () => {
			this.onUpdate();
		});

		return unit;
	}

	removeUnit = (unitTitle) => {
		let { units } = this.state;
		let unitIndex = units.findIndex(unit => {
			return unit.title == unitTitle;
		});

		if (unitIndex > -1) {
			units.splice(unitIndex, 1);

			units.forEach((unit, index) => {
				unit.title = this.props.translate('group') + ' ' + (index + 1);

				return unit;
			});

			this.setState({ units }, () => {
				this.onUpdate();
			});
		}
	}

	editUnitTitle = (unit) => {
		const { translate } = this.props;
		let { units } = this.state;

		swal.fire({
			title: translate('edit-name'),
			text: translate('name'),
			input: 'text',
			inputValue: unit.title,
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('save'),
			preConfirm: (value) => {
				if (value.length > 50) {
					return swal.showValidationMessage(translate('Group name can not be longer than 50 characters'));
				}

				return undefined;
			},
		}).then((response) => {
			if (response.value != null) {
				unit.title = response.value;

				this.setState({ units }, () => {
					this.onUpdate();
				});
			}
		});
	}

	onSubmit = (e) => {
		if (e != null) {
			e.preventDefault();
		}

		if (this.props.onSubmit != null) {
			if (this.props.baseGroup != null) {
				let values = { ...this.props.baseGroup };
				values.title = this.state.title;
				values.units = this.state.units;

				this.setState({ submitting: true });
				this.props.onSubmit(values).then(() => {
					this.setState({ submitting: false });
				});
			} else {
				let values = {
					title: this.state.title,
					sectionId: this.props.section.id,
					units: this.state.units,
				};

				this.setState({ submitting: true });
				this.props.onSubmit(values).then(() => {
					this.setState({ submitting: false });
				});
			}
		}
	}

	/* Render methods - JREND */
	renderStudent = (student, index) => {
		let options = [];
		let isActive = this.state.activeStudents.find(activeStudent => {
			return activeStudent.userId == student.userId;
		});

		const unit = this.state.units.find(unit => {
			return unit.memberships.find(member => member.userId == student.userId);
		});

		this.state.units.forEach(unit => {
			options.push({
				value: unit.title,
				label: unit.title,
			});
		});

		options.push({
			value: 'new',
			label: this.props.translate('new-group'),
		});

		return <div style={{ marginBottom: '.35rem' }} key={student.userId}>
			<Checkbox
				value={isActive != null}
				label={
					<DisplayName
						firstName={student.firstName}
						lastName={student.lastName}
						email={student.email}
						data={this.props.section.students}
					/>
				}
				onChange={() => this.onStudentActiveToggle(student)}
			/>

			<div className="float--right">
				<Select
					onChange={(value) => this.onStudentSelect(student, value)}
					options={options}
				>
					{unit != null ?
						unit.title
						: this.props.translate('select-group')}
				</Select>
			</div>

			<div className="clearfix"></div>
		</div>
	}

	render() {
		const { section } = this.props;
		const { units, title } = this.state;
		const unassignedStudents = this.getUnassignedStudents();

		return (
			<div className="basegroups create">
				{this.state.submitting ?
					<div className="is_sending">
						<p><span className="loading-spinner" /></p>
					</div>
					: null}

				{!this.props.hideTitle ?
					<div>
						<div style={{ marginTop: '1.25rem' }} />

						<span style={{ display: 'block', marginBottom: 'var(--size-10)' }}>
							{this.props.translate('title')}
						</span>

						<div className="form-row" style={{ padding: 0 }}>
							<input type="text" value={this.state.title} onChange={this.setTitle} />
						</div>
					</div>
					: null}

				<div style={{ marginTop: '1.25rem' }} />

				{this.props.baseGroup == null ?
					<div>
						<span style={{ display: 'block', marginBottom: 'var(--size-10)' }}>
							{this.props.translate('automatically-divide')}
						</span>

						<GenerateBaseGroupsRandomly
							onSubmit={this.randomUnitsSubmit}
							students={this.props.section.students}
						/>
					</div>
					: null}

				<div style={{ marginTop: '1.85rem' }} />

				<span style={{ fontWeight: 400 }}>
					{this.props.translate('Groups')}
				</span>

				<div>
					<Button type="secondary" onClick={this.addUnit}>
						<Icon name="Plus" /> {this.props.translate('add-group')}
					</Button>
				</div>

				{this.state.units.map((unit, index) => {
					return <div className="unit" key={index}>
						<div className="title">
							<span style={{ flex: 1 }}>
								{unit.title}
							</span>

							<TooltipMenu id={'unit-menu-' + index} trigger={<Icon name="Bullets" />}>
								<TooltipMenu.Item
									onClick={(e) => { e.preventDefault(); this.editUnitTitle(unit) }}
								>
									{this.props.translate('edit-name')}
								</TooltipMenu.Item>

								<TooltipMenu.Item
									onClick={(e) => { e.preventDefault(); this.removeUnit(unit.title) }}
								>
									{this.props.translate('Remove')}
								</TooltipMenu.Item>
							</TooltipMenu>
						</div>

						{unit.memberships.sort((a, b) => (a.user.lastName || "").localeCompare(b.user.lastName || ""))
						.map((membership, index) => {
							return this.renderStudent(membership.user, index);
						})}
					</div>
				})}

				{unassignedStudents.length > 0 ?
					<div className="unit">
						<span className="title">{this.props.translate('unassigned-students')}</span>

						{unassignedStudents.map((student, index) => {
							return this.renderStudent(student, index);
						})}
					</div>
					: null}

				<div style={{ marginTop: '1.25rem' }} />

				<div className="action-buttons">
					<div className="larger">
						<div style={{ display: 'inline-block' }}>
							<TooltipMenu
								trigger={(
									<Button type="secondary" disabled={this.state.activeStudents.length == 0}>
										{this.props.translate('select-group-for-selected-students')} <Icon name="Chevron" />
									</Button>
								)}
								id="createBaseGroupSelectedStudentsTooltip"
							>
								{this.state.units.map(unit => {
									return <TooltipMenu.Item key={unit.title} onClick={(e) => { e.preventDefault(); this.setGroupOnActiveStudents(unit.title) }}>
										{unit.title}
									</TooltipMenu.Item>
								})}

								<TooltipMenu.Item onClick={(e) => { e.preventDefault(); this.createGroupWithActiveStudents() }}>
									{this.props.translate('new-group')}
								</TooltipMenu.Item>
							</TooltipMenu>
						</div>
					</div>

					{this.props.actions != null ?
						<div style={{ flex: 0 }}>
							{this.props.actions.map((action, index) => {
								return <Button key={index} onClick={action.onClick} type={action.type}>
									{action.value}
								</Button>
							})}
						</div>
						: null}
				</div>

				{this.props.onSubmit != null ?
					<div className="button-row">
						<Button disabled={units.length == 0 || title.length == 0} onClick={this.onSubmit}>
							{this.props.translate('save')}
						</Button>

						<div className="clearfix"></div>
					</div>
					: null}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(BaseGroupForm);
