import React from 'react';
import { useTranslate } from 'lib/translate';
import Moment from 'moment';

import Modal from 'containers/Modals/Modal';
import ScheduleView from './ScheduleView';

import { Floating } from '@haldor/ui';
import { Button, Icon } from '@haldor/ui';

const AgendaModal = (props) => {
	const translate = useTranslate();
	const {
		date,
		advancedCalendar,
		showAgendaModal,
		closeAgendaModal,
		setAgendaModalDate,
		toggleIcon,
		goToDate
	} = props;

	const goBack = () => {
		setAgendaModalDate(date.subtract(1, 'day'));
	};
	const goToday = () => {
		setAgendaModalDate(Moment());
	};
	const goForward = () => {
		setAgendaModalDate(date.add(1, 'day'));
	};

	const isToday = Moment().isSame(date, 'day');

	return (
		<Modal
			isOpen={showAgendaModal}
			onClose={closeAgendaModal}
			title={translate('agenda')}
			overridePrompt={true}
		>
			<div className="agenda-modal-content">
				<div className="calendar-helper">
					<Floating trigger={(<Icon name="Help" />)}>
						<div className="helper--content">
							<strong>{translate('Sign explanation')}</strong>

							<div className="helper--content--item df aic">
								<div className="checkbox-imitator" style={{
									backgroundColor: '#fff'
								}} />

								{translate('My activities')}
							</div>

							<div className="helper--content--item df aic">
								<div className="checkbox-imitator" style={{
									backgroundColor: '#ededed'
								}} />

								{translate('Other peoples activities')}
							</div>

							<div className="helper--content--item df aic">
								<div className="checkbox-imitator" style={{
									backgroundColor: '#fff',
									borderLeftWidth: 3,
									borderLeftColor: '#027adc'
								}} />

								{translate('Lesson')}
							</div>

							<div className="helper--content--item df aic">
								<div className="checkbox-imitator" style={{
									backgroundColor: '#fff',
									borderLeftWidth: 3,
									borderLeftColor: '#ea4b9a'
								}} />

								{translate('Test')}
							</div>
						</div>
					</Floating>
				</div>

				<div className='navigation-buttons-container'>
					<Button
						type='secondary'
						style={{ marginLeft: 0, marginRight: '0.5em' }}
						onClick={goBack}
					>
						{toggleIcon(false)}
						&nbsp;
					</Button>

					<Button
						type='secondary'
						disabled={isToday}
						style={{ marginRight: '0.5em', marginLeft: 0 }}
						onClick={goToday}
					>
						{translate('today')}
					</Button>

					<Button
						type='secondary'
						style={{ marginLeft: 0 }}
						onClick={goForward}
					>
						{toggleIcon(true)}
						&nbsp;
					</Button>

				</div>

				<ScheduleView
					view={'agenda'}
					date={date}
					advancedCalendar={advancedCalendar}
					agendaModal={true}
				/>

				<div className="footer-buttons">
					<Button type="secondary" onClick={() => goToDate(date)} style={{ marginRight: '1em' }}>
						{translate('Go to day view')}
					</Button>

					<Button type="secondary" onClick={closeAgendaModal}>
						{translate('close')}
					</Button>
				</div>
			</div>
		</Modal >
	);
};

export default AgendaModal;