import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

class GroupFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.selectedGroupId,
		};
	}

	reset = () => {
		this.setState({ value: '0' }, () => {
			if (this.props.onChange) {
				this.props.onChange('0');
			}
		});
	}

	onChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}

		this.setState({ value: event.target.value });
	}

	render() {
		return (
			<div className="form">
				<div className="form-row" style={{ padding: 0 }}>
					<div className="select">
						<label style={{ marginTop: 0 }}>
							{this.props.translate('section')}
						</label>

						<select value={this.state.value} onChange={this.onChange}>
							<option value="0">{this.props.translate('select-an-alternative')}</option>

							{this.props.sections != null ?
								this.props.sections.map(group => {
									return <option key={`gfilter-section-${group.id}`} value={group.id}>
										{group.title}
									</option>;
								})
								: null}
						</select>

						{this.state.value != '0' ?
							<div style={{ paddingTop: '0.25em', cursor: 'pointer' }} onClick={this.reset}>
								{this.props.translate('restore-filter')}
							</div>
							: null}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations)
	};
}

export default connect(mapStateToProps)(GroupFilter);
