import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getRootUrl } from 'helpers/url';
import { Search as SearchInput } from 'UI/Inputs';
import { Icon, Button, TooltipMenu, translate } from '@haldor/ui';

import './_web.scss';

class DesktopHeader extends Component {

	onSearch = (search) => {
		this.props.history.push('/search?q=' + search.query);
	};

	renderAction = (action, index) => {
		switch (action.type) {
			case 'button':
				return (
					<Button
						key={'action-' + index}
						type={action.buttonType}
						disabled={action.disabled}
						onClick={action.onClick}
					>
						{action.icon != null ? <Icon name={action.icon} /> : null}

						{action.value}
					</Button>
				);

			case 'dropDown':
				return (
					<TooltipMenu
						key={'action-' + index}
						trigger={
							<Button type={'secondary'} disabled={action.disabled}>
								{action.icon != null ? <Icon name={action.icon} /> : null}

								{action.value}
							</Button>
						}
					>
						{action.subButtons ?
							action.subButtons.map((subAction, index) => (
								<TooltipMenu.Item
									onClick={() => subAction.onClick(true)}
									key={'subAction-' + index}
								>
									{subAction.value}
								</TooltipMenu.Item>
							))
							: null}
					</TooltipMenu>
				);

			case 'icon':
				return (
					<Link
						to="#"
						key={'action-' + index}
						className='header-action'
						data-tooltip={action.tooltip}
						onClick={action.onClick}
					>
						{action.icon != null ? <Icon name={action.icon} /> : null}
					</Link>
				);

			default:
				return null;
		}
	};

	render() {
		const { translate } = this.props;

		return (
			<div className='desktop-header'>
				<div className='content'>
					<Link to={getRootUrl()}>
						<img src='/dist/img/logo_type_small.png' className='logotype' />
					</Link>
				</div>

				<div className='content right'>
					<div className='actions'>
						<SearchInput
							placeholder={translate('search') + '...'}
							onSubmit={this.onSearch}
							clearOnSubmit
							lengthLimit={3}
						/>

						{this.props.actions != null ? this.props.actions.map(this.renderAction) : null}
					</div>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		actions: state.Header.actions,
	};
}

export default withRouter(connect(mapStateToProps)(DesktopHeader));
