import api from 'lib/api';

export const SUBMIT_POST = 'SUBMIT_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST';
export const SET_ACTIVE_POST_DETAILS = 'SET_ACTIVE_POST_DETAILS';
export const GET_POSTS = 'GET_POSTS';
export const GET_POSTS_NEXT_PAGE = 'GET_POSTS_NEXT_PAGE';
export const GET_SECTION_POSTS = 'GET_SECTION_POSTS';
export const DELETE_POST_FROM_STORE = 'DELETE_POST_FROM_STORE';
export const CLEAR_POSTS_FROM_STORE = 'CLEAR_POSTS_FROM_STORE';
export const CLEAR_SECTION_POSTS_FROM_STORE = 'CLEAR_SECTION_POSTS_FROM_STORE';
export const GET_POST_READ_LIST = 'POSTS_GET_READ_LIST'
export const DELETE_POST = 'POST_DELETE_POST';
export const CLEAR_POST_READ_LIST = 'CLEAR_POST_READ_LIST';
export const SET_PAGINATION_INFO = 'SET_PAGINATION_INFO';
export const DELETE_POST_FILE = 'POST_DELETE_POST_FILE';

export function submit_post(values, callback) {
	return {
		type: SUBMIT_POST,
		payload: new Promise(resolve => {
			api.post('posts', values).then((response) => {
				resolve({ ...values, id: response.data });

				if (callback != null) {
					callback(response.data);
				}
			});
		}),
	}
}

export function update_post(values, callback) {
	return {
		type: UPDATE_POST,
		payload: new Promise(resolve => {
			api.put('posts', values).then((response) => {
				if (callback != null) {
					callback(response.data);
				}

				resolve(values);
			});
		}),
	}
}

export function setActivePost(id) {
	return [
		{
			type: SET_ACTIVE_POST,
			id: id,
		}
	]
}

export function getPostDetails(id) {
	return {
		type: SET_ACTIVE_POST_DETAILS,
		payload: new Promise((resolve, reject) => {
			if (typeof (id) !== 'undefined' && id !== null) {
				api.get(`posts/${id}`).then((response) => {
					if (response.status > 201) {
						reject(response);
						return true;
					}

					resolve(response.data);
				});
			}
		})
	};
}

export function getPosts(callback) {
	return {
		type: GET_POSTS,
		payload: new Promise((resolve) => {
			api.get(`posts`).then((response) => {
				resolve(response.data);

				if (callback) {
					callback(response.data);
				}
			});
		}),
	}
}

export function setPostCollectionInfo(info) {
	return {
		type: SET_PAGINATION_INFO,
		payload: new Promise(resolve => {
			resolve(info);
		}),
	}
};

export function getPostsBySectionId(id, page, status = null, startDate = null, endDate = null, pageSize = 15) {
	return {
		type: GET_SECTION_POSTS,
		payload: new Promise((resolve) => {
			let url = `posts?sectionId=${id}`;

			if (page != null) {
				url += `&pageIndex=${page}&pageSize=${pageSize}`;
			}

			if (status != null) {
				url += `&filter=${status}`;
			}

			if (startDate != null) {
				url += `&startDate=${startDate}`;
			}

			if (endDate != null) {
				url += `&endDate=${endDate}`;
			}

			api.get(url).then((response) => {
				if (response.data.length > 0) {
					resolve(response.data);
				} else {
					resolve([]);
				}
			});
		}),
	}
};

export function getPostsPage(page, status = null, startDate = null, endDate = null, pageSize = 15) {
	return {
		type: GET_POSTS_NEXT_PAGE,
		payload: new Promise((resolve) => {
			let url = `posts?pageIndex=${page}&pageSize=${pageSize}`;
			if (status != null) {
				url += `&filter=${status}`;
			}

			if (startDate != null) {
				url += `&startDate=${startDate}`;
			}

			if (endDate != null) {
				url += `&endDate=${endDate}`;
			}

			api.get(url).then((response) => {
				if (response.data.length > 0) {
					resolve(response.data);
				} else {
					resolve([]);
				}
			});
		}),
	}
};

export function deletePostFromStore(id) {
	return {
		type: DELETE_POST_FROM_STORE,
		payload: id
	}
}

export const deletePost = (id) => ({
	type: DELETE_POST,
	payload: new Promise(resolve => {
		api.delete(`posts/${id}`).then((response) => {
			resolve(id);
		});
	}),
});

export const getPostReadList = (post) => ({
	type: GET_POST_READ_LIST,
	payload: new Promise(resolve => {
		api.get(`posts/${post}/reads`).then(response => {
			resolve(response.data);
		})
	}),
});

export function clearSectionPostsFromStore() {
	return {
		type: CLEAR_SECTION_POSTS_FROM_STORE,
		payload: true,
	}
};

export const clearPostsFromStore = () => ({
	type: CLEAR_POSTS_FROM_STORE,
	payload: true,
});

export const clearPostReadList = () => ({
	type: CLEAR_POST_READ_LIST,
	payload: true,
});

export const deletePostFile = (postId, fileId) => ({
	type: DELETE_POST_FILE,
	payload: new Promise((resolve) => {
		api.delete(`posts/${postId}/files/${fileId}`).then(() => {
			resolve(fileId);
		});
	}),
});