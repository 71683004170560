import {
	GET_ASSESSMENT,
	GET_ASSESSMENTS,
	GET_ASSESSMENTS_COURSES,
	GET_STUDENT_ASSESSMENT_OVERVIEW,
	GET_MATRIX_ASSESSMENT_STATISTIC,
	RESET_MATRIX_ASSESSMENT_STATISTICS,
	GET_STUDENT_ASSESSMENTS,
	CLEAR_MATRIX_ASSESSMENT_OVERVIEW,
	RESET_USER_ASESSMENT_STATISTIC,
	GET_SECTIONS_FROM_ASSESSMENTS,
	GET_USER_ASSESSMENTS_STATISTIC_PROGRESS,
	GET_ASSESSMENTS_BY_STUDENT_AND_SECTION,
	GET_ASSESSMENTS_BY_STUDENT,
	CLEAR_ASSESSMENTS,
	GET_STUDENT_COURSES_GOALS,
	GET_STUDENT_ASSESSMENT_STATISTICS,
} from 'actions/assessments';

import {
	GET_STUDENT_ASSESSOR_ASSESSMENT,
	GET_STUDENT_ASSESSOR_ASSESSMENTS
} from 'actions/studentassessments';

const INITIAL_STATE = {
	courses: [],
	assessments: [],
	assessment: null,
	studentAssessment: null,
	studentAssessments: [],
	studentStatistics: null,
	studentOverview: null,
	matrixAssessments: [],
	userAssessments: [],
	progressSections: [],
	progressAssessmentStats: [],
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_ASSESSMENT:
			return { ...state, assessment: action.payload };

		case GET_ASSESSMENTS:
			return { ...state, assessments: action.payload };
		case GET_ASSESSMENTS_COURSES:
			return { ...state, courses: action.payload };

		case GET_STUDENT_ASSESSOR_ASSESSMENT:
			return { ...state, studentAssessment: action.payload };

		case GET_STUDENT_COURSES_GOALS:
			return { ...state, studentOverview: action.payload };

		case GET_STUDENT_ASSESSMENT_STATISTICS:
			return { ...state, studentStatistics: action.payload };

		case GET_STUDENT_ASSESSOR_ASSESSMENTS:
			return { ...state, studentAssessments: action.payload };

		case GET_SECTIONS_FROM_ASSESSMENTS:
			return { ...state, progressSections: action.payload };

		case GET_USER_ASSESSMENTS_STATISTIC_PROGRESS:
			return { ...state, progressAssessmentStats: action.payload };

		case CLEAR_MATRIX_ASSESSMENT_OVERVIEW:
			return { ...state, matrixAssessments: INITIAL_STATE.matrixAssessments, assessments: [] };

		case GET_MATRIX_ASSESSMENT_STATISTIC:
			return { ...state, matrixAssessments: [...state.matrixAssessments, action.payload] };

		case RESET_MATRIX_ASSESSMENT_STATISTICS:
			return { ...state, matrixAssessments: [] };

		case GET_STUDENT_ASSESSMENTS:
			return { ...state, studentAssessments: action.payload };

		case RESET_USER_ASESSMENT_STATISTIC:
			return { ...state, userAssessments: INITIAL_STATE.userAssessments };

		case GET_ASSESSMENTS_BY_STUDENT_AND_SECTION:
			return { ...state, assessments: action.payload };

		case GET_ASSESSMENTS_BY_STUDENT:
			return { ...state, assessments: action.payload };

		case CLEAR_ASSESSMENTS:
			return { ...state, assessments: [] };

		default:
			return state;
	}
}
