import React, { Component } from 'react';
import { translate, Flex, Checkbox, Button, Icon } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Input from 'components/Inputs/input';
import { getConsentInfo, updateTenantServiceConsent, saveSelectedProductsAsNotes } from '../../actions/services';
import Card, { CardsContainer, CardTitle, CardFooter } from 'components/Cards/Card';
import { Spinner } from 'UI';
import Redirect from 'components/Redirect/Redirect';
import './_onboarding.scss';
import Modal from 'containers/Modals/Modal';
import { appInsights } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";
import getEnvironment from 'lib/env';

class TermsManagement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: 'Haldor',
			consentInfos: [],
			modal: false,
			showConsentRequireMessage: false,
			consentRequiredMessage: this.props.translate('If you need any help or have any questions about the approval of permissions for Haldor Education, please contact our support at support@haldor.se'),
			loading: true
		};

		window.onPopupDataReceived = this.getResults.bind(this);
	}

	getResults(response) {
		appInsights.trackTrace({ message: "Onboarding | O365 Admin page has given admin consent" }, {
			response: response,
			consentInformation: this.state.consentInfos
		});

		var itemIndex = this.state.consentInfos.findIndex(element => { return element.state == response.state });

		if (itemIndex > -1) {
			this.state.consentInfos[itemIndex].status = response.consent;

			var tenantService = {
				serviceId: this.state.consentInfos[itemIndex].serviceId,
				consentActive: response.consent
			}

			appInsights.trackTrace({ message: "Onboarding | Update Admin consent on tenantService" }, {
				response: response,
				consentInformation: this.state.consentInfos,
				tenantService: tenantService
			});

			this.props.updateTenantServiceConsent(tenantService);
			this.setState({ consentInfos: this.state.consentInfos });
		}
	}

	componentDidMount() {
		appInsights.trackTrace({ message: "Onboarding | O365 Admin page was initiated" });

		this.setState({ isLoading: false });

		this.props.getConsentInfo().then(() => {
			if (this.props.consentInfo) {
				this.setState({ consentInfos: this.props.consentInfo, loading: false });
			}
		});
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	getAdminConsentOnService(consentItem) {
		var itemIndex = this.state.consentInfos.findIndex(element => { return element.state == consentItem.state });

		if (itemIndex > -1) {
			this.state.consentInfos[itemIndex].loading = true;
			this.setState({ consentInfos: this.state.consentInfos });
		}

		if (this.inIframe()) {
			localStorage.setItem('adminConsentTeams', '1');
		}

		this.getAdminConsent(consentItem.uri, '/teams/he/authentication');
	}

	renderRedirect = () => {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} />
		}
	}

	onSave() {
		this.props.saveSelectedProductsAsNotes();

		if (this.props.onSubmit) {
			this.props.onSubmit(3);
		}
	}

	getAdminConsent(uri, redirectUri) {
		this.setState({ showConsentRequireMessage: false });

		if (this.inIframe()) {
			microsoftTeams.authentication.authenticate({
				url: '/teams/he/authentication?consent_url=' + uri + "&redirect_uri=" + window.location.origin + redirectUri,
			})
				.then((result) => {
					let data = localStorage.getItem(result);
					localStorage.removeItem(result);
					let tokenResult = JSON.parse(data);
					this.getResults(tokenResult);
				})
				.catch((error) => {
					console.log(error);
					this.setState({ showConsentRequireMessage: true });
				});
		} else {
			var consentTab = window.open(uri + "&redirect_uri=" + window.location.origin + redirectUri, "_blank", "width=600,height=1200");

			//this method will catch if the browser has blocked popups
			setTimeout(function () {
				if (consentTab.closed) {
					this.setState({ showConsentRequireMessage: true });
				}
			}.bind(this), 200);
		}
		return;
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(1)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	showButtonGroup() {
		return (<div>
			<Button disabled={this.state.consentInfos.findIndex(element => { return element.status == false }) > -1} type="primary" style={{ marginRight: '0.5rem' }} onClick={this.onSave.bind(this)}>
				{this.props.translate('Continue')}
			</Button>
			<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.toggleModal() }}>
				{this.props.translate('View permissions')}
			</Button>
			<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.goBack() }}>
				{this.props.translate('Back')}
			</Button>
		</div >)
	}

	showConsentedCard(element) {
		return (
			<div className="onboarding-consent-card" key={element.state}>
				<div>
					<img style={{ width: '2rem', height: '2rem' }} src="/dist/svg/check_green_ico.svg" />
				</div>

				<div>
					<div style={{ height: '2rem', marginLeft: '0.5rem', marginTop: '0.35rem' }}>
						{element.name + " " + this.props.translate('is approved')}
					</div>
				</div>
			</div>)
	}

	getConsentCard(element) {
		return (
			<div className="onboarding-consent-card active" key={element.state} onClick={() => { this.getAdminConsentOnService(element) }}>
				<div>
					<img style={{ width: '2rem', height: '2rem' }} src="/dist/svg/check_grey_ico.svg" />
				</div>

				<div>
					<div style={{ height: '2rem', marginLeft: '0.5rem', marginTop: '0.35rem' }}>
						{this.props.translate('Click here to approve') + " " + element.name}
					</div>
				</div>
			</div>)
	}

	render() {
		if (this.state.redirect) {
			return this.renderRedirect()
		}

		if (this.state.loading) {
			return (<Spinner center />)
		}
		let product = getEnvironment().id;
		var productName = 'Haldor Education';
		if (product === 'haldor_planning') {
			productName = "Haldor Planning";
		}

		return <div style={{ background: '#FFF' }}>
			<Modal type="small news" overridePrompt isOpen={this.state.modal} onClose={this.toggleModal}>
				<div className="form">
					<div className="form-row">
						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Sign in users, read directory data and profiles')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to let the signed-in user have basic information about other users in the organization, such as name and e-mail address. Used by all services in Haldor.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Manage education app settings')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Makes it possible for Haldor to handle data from Microsoft School Data Sync. Used to synchronize data with Haldor.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Read and write class assignments with grades')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to handle assignments in Teams. Users can read and create Teams-assignments. Parents can access information about assignments and grading. Used by the Haldor Assignments, Planning, Assessment and also Parent/Guardian services.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Read and write the organization\'s roster')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to get information about what classes exist, information about these classes and who the teacher and students in these classes are. Used by all services in Haldor.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Have full access to all files user can access')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to let the user access files in their personal OneDrive and other SharePoint sites the user has access to. Used by Haldor Assignments, Planning and Posts.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Read and write all groups')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to get information about what groups the signed-in user is part of and information about users memberships in groups that are not classes. Used by all services in Haldor and sync-services.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Read and create user\'s online meetings')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to create and get information about online meetings in Microsoft 365. Used by Haldor Planning and Parent-Teacher meetings.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Create, edit and delete items and lists in all site collections')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor creates document libraries for assignments, copies files for assignments and plans, and sets permissions based on the status of the assignments. Used by Haldor Assignments and Planning.')}</p>

						<h3 style={{ 'marginTop': '1rem' }}>{this.props.translate('Have full control of all site collections')}</h3>
						<p style={{ 'marginTop': '0.5rem' }}>{this.props.translate('Haldor uses this permission to set unique permissions on document libraries in SharePoint sites that are used for assignments. Used by a backend service for creating document libraries and setting permissions for Haldor Assignments.')}</p>
					</div>
				</div>
			</Modal>
			<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '98%', paddingTop: '3%', paddingBlockStart: '3%' }}>
				<div style={{ display: 'flex' }}>
					<div style={{ flexGrow: 2 }}>
						<h2 style={{ textAlign: 'left' }}> {this.props.translate('Approve permissions for {0}').replace('{0}', productName)} </h2>
						<p style={{ textAlign: 'left', marginTop: '1.0rem', marginBottom: '1.0rem' }}>{this.props.translate('Haldor Education is a collection of educational services that expands the functionality in Teams and facilitates for school staff. In order for you to use the services, you need to gain permission to certain parts of the organisation’s Microsoft Office 365 tenant to enable Haldor to access data via Microsoft Graph APIs.')}</p>
						<p style={{ textAlign: 'left', marginTop: '0.5rem' }}>
							{this.props.translate('Haldor is a Microsoft partner that complies with GDPR legislation and processes its data based on the highest information and security standards. Haldor only uses data when it is necessary.')}
						</p>
					</div>
				</div>

				<div style={{ marginTop: '2rem' }} />
				{this.state.consentInfos != null && this.state.consentInfos.map(element => {
					return (element.status ? this.showConsentedCard(element) : this.getConsentCard(element))
				})}

				<div style={{ color: 'red', marginBottom: '0,5rem' }}>
					{this.state.showConsentRequireMessage ? this.state.consentRequiredMessage : null}
				</div>
				<div style={{ display: "flex", alignItems: "left", justifyContent: "left", marginTop: '1.5rem' }}>
					{this.showButtonGroup()}
				</div>
			</div>
		</div>

	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		selectedServices: state.Services.selectedServices,
		currentUser: state.user.currentUser,
		consentInfo: state.Services.consentInfo
	};
}


export default connect(mapStateToProps, {
	getConsentInfo,
	updateTenantServiceConsent,
	saveSelectedProductsAsNotes
})(TermsManagement);


