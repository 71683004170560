import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert2';

import DateTime from '_class/DateTime';
import User from '_class/User';
import {
	getScheduleItem,
	deleteCalendarEvent,
	updateCalendarEvent,
	clearScheduleItem,
} from 'actions/schedule';
import { setPageTitle } from 'actions/header';

import Modal from 'containers/Modals/Modal';
import ConflictingEventForm from 'containers/Forms/ConflictingEventForm';

import PostContent from 'components/Presentation/PostContent';

import { Block, Button, Icon, getActiveLanguage } from '@haldor/ui';
import { Expandable, Spinner } from 'UI';

class SingleCalendarEvent extends Component {
	constructor(props) {
		super(props);

		let descriptionOpen = true;

		if (localStorage.getItem('haldor-calendarEvent-description') != null) {
			descriptionOpen = JSON.parse(localStorage.getItem('haldor-calendarEvent-description'));
		}

		const locale = getActiveLanguage();
		Moment.locale(locale);

		this.state = {
			descriptionOpen,
			loadingSchedule: false,
			saving: false,
			editForm: false,
		};

		this._isMounted = true;
	}

	componentDidMount() {
		this.setState({ loadingSchedule: true });

		this.props.getScheduleItem(this.props.match.params.id).then(async () => {

			// Redirect to page for Lessons if schedule is a Lesson
			if (this.props?.schedule?.type === 'SCHEDULE_EVENT') {
				this.props.history.push('/lesson/' + this.props.match.params.id);
			}

			this.props.setPageTitle(this.props?.schedule?.title);
			this.setState({ loadingSchedule: false });
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		this.props.clearScheduleItem();
	}

	redirect = () => {
		this.props.history.push('/schedule');
	};

	toggleEdit = (reload = false) => {
		this.setState({ editForm: !this.state.editForm });
		if (reload) {
			this.props.getScheduleItem(this.props.match.params.id).then(() => {
				this.props.setPageTitle(this.props.schedule?.title);
			});
		}
	};

	onDescriptionTogle = (open) => {
		this.setState({ descriptionOpen: open });
		localStorage.setItem('haldor-calendarEvent-description', JSON.stringify(open));
	};

	onRemove = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.props.deleteCalendarEvent(this.props.match.params.id).then(() => {
					this.redirect();
				});
			}
		});
	};

	isLoading = () => {
		return (
			<div className='container single-task'>
				<Spinner center />
			</div>
		);
	};

	noDataFound = () => {
		return (
			<div className='container single-task'>
				<h1>{this.props.translate('no-results')}</h1>
			</div>
		);
	};

	render() {
		if (this.state.loadingSchedule) {
			return this.isLoading();
		} else {
			if (this.props.schedule == null) {
				return this.noDataFound();
			}
		}

		const { schedule, translate } = this.props;
		const user = new User(this.props.currentUser);
		const entireSchool = schedule?.activityMemberships?.find(m => m.referenceType === 'SCHOOL') != null;

		return (
			<div className='single-task lesson'>
				<div className='single-section form left-side' style={{ paddingTop: 0 }}>
					<Block>
						<Expandable
							open={this.state.descriptionOpen}
							onChange={this.onDescriptionTogle}
							title={this.props.translate('Description')}
						>
							{schedule.description != null ? (
								<div style={{ marginTop: '1rem' }}>
									<PostContent>{schedule.description}</PostContent>
								</div>
							) : null}

							<div style={{ marginTop: '1rem' }}>
								<span className='title'>{translate('time')}</span>
								{new DateTime(schedule.startTime).getLongDateWithTime()
									+ ' - ' +
									new DateTime(Moment.utc(schedule.startTime).add(schedule.length, 'm').format()).getTime()}
							</div>

							<div style={{ marginTop: '1rem' }}>
								<span className="title">{this.props.translate('column-created-by')}</span>
								{schedule.author ?? '-'}
							</div>

							<div style={{ marginTop: '1rem' }}>
								<span className='title'>{translate('Applies to')}</span>
								{entireSchool ?
									translate('Entire school')
									: (
										schedule?.sections?.map((section) => {
											return <div>{section.title}</div>
										})
									)}
							</div>

						</Expandable>
					</Block>
				</div>

				<div className='single-actions right-side' style={{ paddingTop: 0 }}>
					{(user.isTeacher() || user.isAdministrator() || user.isPedagogue() || user.isSchoolLeader() || user.isOperationManager() || user.isStudent()) ? (
						<div className='action-section'>
							<h3>{this.props.translate('tools')}</h3>

							{schedule.editable ? (
								<Button onClick={this.toggleEdit} type='secondary'>
									<Icon name='Pen_Small' /> {this.props.translate('Edit')}
								</Button>
							) : null}

							{schedule.deletable ? (
								<div>
									<Button
										onClick={this.onRemove}
										type='secondary'
										style={{ marginTop: 5 }}
									>
										{this.props.translate('Delete')}
									</Button>
								</div>
							) : null}

						</div>
					) : null}
				</div>

				<div style={{ clear: 'both' }} />

				<Modal
					isOpen={this.state.editForm}
					onClose={this.toggleEdit}
					title={this.props.translate('Edit')}
				>
					<ConflictingEventForm
						onAbort={this.toggleEdit}
						onSubmit={this.toggleEdit}
					//hasDate={false}
					/>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		user: state.user.currentUser,
		translate: translate(state.Languages.translations),
		schedule: state.schedule.activeScheduleItem,
		currentUser: state.user.currentUser,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getScheduleItem,
		deleteCalendarEvent,
		setPageTitle,
		updateCalendarEvent,
		clearScheduleItem
	})(SingleCalendarEvent)
);
