import api from 'lib/api';

export const GET_SINGLE_SCHOOL_DETAILS = 'SCHOOLS_GET_SINGLE_SCHOOL_DETAILS';
export const GET_SCHOOL_ABSENCE = 'SCHOOLS_GET_SINGLE_SCHOOL_ABSENCE';
export const SET_ACTIVE_SCHOOL = 'SCHOOLS_SET_ACTIVE_SCHOOL';
export const GET_SINGLE_SCHOOL_STUDENTS = 'SCHOOLS_GET_SINGLE_SCHOOL_STUDENTS';
export const GET_SCHOOL_GUARDIANS = 'GET_SCHOOL_GUARDIANS';
export const UPDATE_SCHOOL_TYPE = 'UPDATE_SCHOOL_TYPE';
export const GET_SCHOOL_SUBJECTTEACHERS = 'GET_SCHOOL_SUBJECTTEACHERS';
export const GET_SCHOOL_SERVICES = 'SCHOOLS_GET_SCHOOL_SERVICES';
export const GET_SCHOOLS_DATA_FOR_SECTIONS = 'GET_SCHOOLS_DATA_FOR_SECTIONS';
export const GET_SCHOOL_DATA_FOR_SECTIONS = 'GET_SCHOOL_DATA_FOR_SECTIONS';
export const GET_SINGLE_SCHOOL_BY_EXTERNALID = 'GET_SINGLE_SCHOOL_BY_EXTERNALID';


export const getSchoolByExternalId = (id) => ({
	type: GET_SINGLE_SCHOOL_BY_EXTERNALID,
	payload: new Promise(resolve => {
		api.get(`schools/externalId/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});


export const getSchoolDetails = (id) => ({
	type: GET_SINGLE_SCHOOL_DETAILS,
	payload: new Promise(resolve => {
		api.get(`schools/${id}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSchoolDataForSections = (id) => ({
	type: GET_SCHOOL_DATA_FOR_SECTIONS,
	payload: new Promise(resolve => {
		api.get(`schools/${id}/sectionsettings`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSchoolsDataForSections = () => ({
	type: GET_SCHOOLS_DATA_FOR_SECTIONS,
	payload: new Promise(resolve => {
		api.get(`schools/sectionsettings`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSchoolAbsence = (id, date) => ({
	type: GET_SCHOOL_ABSENCE,
	payload: new Promise(resolve => {
		api.get(`schools/${id}/absence?date=${date}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSchoolStudents = (id, skip) => ({
	type: GET_SINGLE_SCHOOL_STUDENTS,
	payload: new Promise(resolve => {
		if (skip != null) {
			api.get(`schools/${id}/students/${skip}`).then((response) => {
				resolve(response.data);
			});

			return true;
		}

		api.get(`schools/${id}/students`).then((response) => {
			resolve(response.data);
		});
	}),

});

export const getSchoolGuardians = (id) => ({
	type: GET_SCHOOL_GUARDIANS,
	payload: new Promise(resolve => {
		api.get(`schools/${id}/guardians`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getSchoolSubjectTeachers = (id) => ({
	type: GET_SCHOOL_SUBJECTTEACHERS,
	payload: new Promise(resolve => {
		api.get(`schools/${id}/subjectteachers`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateSchoolType = (Id, Type) => {
	return {
		type: UPDATE_SCHOOL_TYPE,
		payload: new Promise(resolve => {
			api.put(`schools/${Id}/type/${Type}`, null).then((response) => {
				resolve(response.data);
			});
		})
	};
}

export const getSchoolServices = (id) => ({
	type: GET_SCHOOL_SERVICES,
	payload: new Promise((resolve) => {
		api.get(`schools/${id}/services`).then((response) => {
			resolve(response.data);
		});
	}),
});