import Moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from 'date-fns/locale/da';
import uk from 'date-fns/locale/uk';
import { translate, getActiveLanguage } from '@haldor/ui';
import { connect } from 'react-redux';

class DatePickerEvent extends Component {
	constructor(props) {
		super(props);

		Moment.locale(getActiveLanguage());
		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		this.state = {
			timeStart: props.values?.eventDate != null ? Moment.utc(props.values.eventDate).local() : null,
			dtPickerStartDate: props.values?.eventDate != null ? Moment.utc(props.values.eventDate).local() : null,
			Published: props.values?.published != null && props.values?.status == 'POST_PUBLISHED' ? Moment.utc(props.values.published).local() :
				props.values?.published != null && Moment.utc(props.values.published).local() > Moment() ? Moment.utc(props.values.published).local() : Moment(),
			dtPickerPublished: props.values?.published != null && props.values?.status == 'POST_PUBLISHED' ? Moment.utc(props.values.published).local() :
				props.values?.published != null && Moment.utc(props.values.published).local() > Moment() ? Moment.utc(props.values.published).local() : Moment(),
			dtPickerDueDate: props.values?.validUntil != null ? Moment.utc(props.values.validUntil).local() : null,
			timeEnd: props.values?.validUntil != null ? Moment.utc(props.values.validUntil).local() : null,
			updated: false,
		};
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.values != null && this.state.updated == false) {
			this.setState({
				dtPickerStartDate: nextProps.values.eventDate != null ? Moment.utc(nextProps.values.eventDate).local() : this.state.dtPickerStartDate,
				Published: nextProps.values.published != null ? Moment.utc(nextProps.values.published).local() : this.state.Published,
				dtPickerPublished: nextProps.values.published != null && nextProps.values?.status == 'POST_PUBLISHED' ? Moment.utc(nextProps.values.published).local() : this.state.dtPickerPublished,
				dtPickerDueDate: nextProps.values.validUntil != null ? Moment.utc(nextProps.values.validUntil).local() : this.state.dtPickerDueDate,
				timeEnd: nextProps.values.validUntil != null ? Moment.utc(nextProps.values.validUntil).local() : this.state.timeEnd,
				updated: true
			});
		}
	}

	componentDidMount = () => {
		this.onChange();
	}

	onStartChange = (date) => {
		date = date != null ? Moment(date) : null;
		let { dtPickerStartDate, timeStart, dtPickerDueDate, timeEnd } = this.state;
		dtPickerStartDate = date != null ? Moment(date).set({ hours: 23, minutes: 59, seconds: 59 }) : null;
		timeStart = date != null ? Moment(date).set({ hours: 23, minutes: 59, seconds: 59 }) : null;

		if (date != null) {
			var dueDate = Moment(date).add(2, 'days');
			if (dtPickerDueDate != null && date.diff(dtPickerDueDate, 'days') > 0) {
				dtPickerDueDate = dueDate;
				timeEnd = dueDate;
			}
		}

		this.setState({ dtPickerDueDate, timeEnd, dtPickerStartDate, timeStart }, () => {
			this.onChange();
		});
	}

	onEndChange = (date) => {
		date = date != null ? Moment(date) : null;
		let { dtPickerDueDate, timeEnd, dtPickerPublished, Published } = this.state;
		dtPickerDueDate = date != null ? Moment(date).set({ hours: 23, minutes: 59, seconds: 59 }) : null;
		timeEnd = date != null ? Moment(date).set({ hours: 23, minutes: 59, seconds: 59 }) : null;

		if (date != null && timeEnd) {
			var publishedDate = timeEnd;
			if (timeEnd.diff(dtPickerPublished, 'days') < 0) {
				dtPickerPublished = publishedDate;
				Published = publishedDate;
			}
		}

		this.setState({ dtPickerDueDate, timeEnd, dtPickerPublished, Published }, () => {
			this.onChange();
		});
	}

	onPublishedChange = (date) => {
		date = date != null ? Moment(date) : null;
		let { dtPickerPublished, Published, dtPickerDueDate, timeEnd, dtPickerStartDate, timeStart } = this.state;
		dtPickerPublished = date != null ? Moment(date).set({ hours: 0, minutes: 0, seconds: 0 }) : null;
		Published = date != null ? Moment(date).set({ hours: 0, minutes: 0, seconds: 0 }) : null;

		if (date != null) {
			var dueDate = date.add(2, 'days');
			var startDate = date;
			if (dtPickerDueDate != null && date.diff(dtPickerDueDate) > 0) {
				dtPickerDueDate = dueDate;
				timeEnd = dueDate;
			}

			if (dtPickerStartDate != null && date.diff(dtPickerStartDate) > 0) {
				dtPickerStartDate = startDate;
				timeStart = startDate;
			}
		}

		this.setState({ dtPickerPublished, dtPickerDueDate, timeEnd, timeStart, dtPickerStartDate, Published }, () => {
			this.onChange();
		});
	}

	onChange = () => {
		if (this.props.onChange) {
			let start = this.state.timeStart != null ?
				Moment(this.state.timeStart).format() : null;

			let end = this.state.timeEnd != null ?
				Moment(this.state.timeEnd).format() : null;

			let published = this.state.Published != null ?
				Moment(this.state.Published).format() : null;

			const dates = {
				start,
				end,
				published,
			};

			this.props.onChange(dates);
		}
	}

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK"){
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	render() {
		const { showEnd } = this.props;

		return (
			<div>
				<div className="form-row-half">
					<label htmlFor="date-start">{this.props.translate('publishing-date')}</label>

					<DatePicker
						selected={this.state.dtPickerPublished && this.state.dtPickerPublished._isAMomentObject ? this.state.dtPickerPublished.toDate() : this.state.dtPickerPublished}
						startDate={this.state.dtPickerPublished && this.state.dtPickerPublished._isAMomentObject ? this.state.dtPickerPublished.toDate() : this.state.dtPickerPublished}
						dateFormat={this.getDateFormat()}
						selectsPublished
						popperPlacement="bottom-start"
						positionFixed={true}
						popperModifiers={{
							preventOverflow: {
								enabled: true,
							},
							positionFixed: false,
						}}
						showWeekNumbers
						onChange={this.onPublishedChange}
						previousMonthButtonLabel=""
						nextMonthButtonLabel=""
						locale={this.normalizeLocale(getActiveLanguage())}
						{...this.state.dtPickerPublished}
					/>
				</div>

				<div className="form-row-half last">
					<label htmlFor="date-end">{this.props.translate('valid-until')}</label>

					<DatePicker
						selected={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
						startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
						endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
						minDate={this.state.dtPickerPublished && this.state.dtPickerPublished._isAMomentObject ? this.state.dtPickerPublished.toDate() : this.state.dtPickerPublished}
						dateFormat={this.getDateFormat()}
						onChange={this.onEndChange}
						popperPlacement="bottom-start"
						positionFixed={true}
						popperModifiers={{
							preventOverflow: {
								enabled: true,
							},
							positionFixed: false,
						}}
						selectsEnd
						showWeekNumbers
						fixedHeight
						previousMonthButtonLabel=""
						nextMonthButtonLabel=""
						locale={this.normalizeLocale(getActiveLanguage())}
						{...this.state.dtPickerDueDate}
					/>
				</div>

				{showEnd ?
					<div>
						<div className="form-row-half">
							<label htmlFor="date-start">{this.props.translate('date-for-event')} *</label>

							<DatePicker
								selected={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
								startDate={this.state.dtPickerStartDate && this.state.dtPickerStartDate._isAMomentObject ? this.state.dtPickerStartDate.toDate() : this.state.dtPickerStartDate}
								endDate={this.state.dtPickerDueDate && this.state.dtPickerDueDate._isAMomentObject ? this.state.dtPickerDueDate.toDate() : this.state.dtPickerDueDate}
								onChange={this.onStartChange}
								minDate={this.state.dtPickerPublished && this.state.dtPickerPublished._isAMomentObject ? this.state.dtPickerPublished.toDate() : this.state.dtPickerPublished}
								dateFormat={this.getDateFormat()}
								required={showEnd}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								popperPlacement="bottom-start"
								selectsStart
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=""
								nextMonthButtonLabel=""
								locale={this.normalizeLocale(getActiveLanguage())}
								{...this.state.dtPickerStartDate}
							/>
						</div>
						<div className="form-row-half last">
							<label></label>
							<div>
								<div className="important-visible">
									{this.props.translate('important-visible-seven-days')}
								</div>
							</div>
						</div>
					</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages
	};
}

export default connect(mapStateToProps)(DatePickerEvent);
