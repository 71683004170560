import {
	GET_FLAGGED_MESSAGES,
} from 'actions/conversation_admin';

const INITIAL_STATE = {
	flagged: []
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_FLAGGED_MESSAGES:
			return { ...state, flagged: action.payload };
		default:
			return state;
	}
}
