import api from 'lib/api';

export const ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS = 'ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS';
export const ACTIVE_TASK = 'ACTIVE_TASK';
export const ASSIGNMENT_CREATE = 'ASSIGNMENTS_ASSIGNMENT_CREATE';
export const ASSIGNMENT_UPDATE = 'ASSIGNMENTS_ASSIGNMENT_UPDATE';
export const CLEAR_ASSIGNMENT_SECTION = 'ASSIGNMENTS_CLEAR_ASSIGNMENT_SECTION';
export const CLEAR_ASSIGNMENTS_FROM_STORE = 'CLEAR_ASSIGNMENTS_FROM_STORE';
export const CLEAR_LATEST_ASSIGNMENTS = 'CLEAR_LATEST_ASSIGNMENTS';
export const CREATE_ASSIGNMENT_TASK_WORKSPACE = 'CREATE_ASSIGNMENT_TASK_WORKSPACE';
export const CREATE_BULK_TASKS = 'ASSIGNMENTS_CREATE_BULK_TASKS';
export const DELETE_ASSIGNMENT_FROM_STORE = 'DELETE_ASSIGNMENT_FROM_STORE';
export const DELETE_USER_ASSIGNMENT_DOCUMENT = 'DELETE_USER_ASSIGNMENT_DOCUMENT';
export const GET_ASSIGNMENT_EVENTS_PAGINATION = 'GET_ASSIGNMENT_EVENTS_PAGINATION';
export const GET_ASSIGNMENT_SECTION = 'ASSIGNMENTS_GET_ASSIGNMENT_SECTION';
export const GET_ASSIGNMENT_STATUS = 'ASSIGNMENTS_GET_ASSIGNMENT_STATUS';
export const GET_ASSIGNMENT_STUDENT_ADJUSTMENTS = 'ASSIGNMENTS_GET_ASSIGNMENT_STUDENT_ADJUSTMENTS';
export const GET_FOLDER_FILES = 'GET_FOLDER_FILES';
export const GET_HALDOR_ASSIGNMENTS = 'GET_HALDOR_ASSIGNMENTS';
export const GET_LATEST_HALDOR_ASSIGNMENTS = 'GET_LATEST_HALDOR_ASSIGNMENTS';
export const GET_LATEST_MICROSOFT_ASSIGNMENTS = 'GET_LATEST_MICROSOFT_ASSIGNMENTS';
export const GET_MICROSOFT_EDUCATION_ASSIGNMENTS = 'GET_MICROSOFT_EDUCATION_ASSIGNMENTS';
export const GET_TEAMS_ASSIGNMENT_DETAILS = 'ASSIGNMENT_SGET_TEAMS_ASSIGNMENT_DETAILS';
export const GET_TEAMS_ASSIGNMENT_SUBMISSION = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT_SUBMISSION';
export const GET_TEAMS_ASSIGNMENTS = 'ASSIGNMENTS_GET_TEAMS_ASSIGNMENT';
export const SEARCH_ASSIGNMENT = 'SEARCH_ASSIGNMENT';
export const SET_ACTIVE_ASSIGNMENT = 'SET_ACTIVE_ASSIGNMENT';
export const SET_ASSIGNMENT_SERVICES = 'SET_ASSIGNMENT_SERVICES';
export const SET_MY_ASSIGNMENTS_ACTIVE_GROUP = 'ASSIGNMENTS_SET_ACTIVE_GROUP_ON_MY_ASSIGNMENTS';
export const SET_TEACHER_DONE = 'SET_TEACHER_DONE';
export const UPDATE_ACTIVE_ASSIGNMENT = 'UPDATE_ACTIVE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT_LIST = 'UPDATE_ASSIGNMENT_LIST';
export const UPDATE_ASSIGNMENT_TASK_STATUS = 'UPDATE_ASSIGNMENT_TASK_STATUS';
export const UPDATE_ASSIGNMENT_TASK_SUBMITTED_DATE = 'UPDATE_ASSIGNMENT_TASK_SUBMITTED_DATE';
export const UPLOAD_USER_ONE_DRIVE_DOCUMENT = 'UPLOAD_USER_ONE_DRIVE_DOCUMENT';
export const SET_CURRENT_ASSIGNMENT_EVENT_PAGE = 'SET_CURRENT_ASSIGNMENT_EVENT_PAGE';
export const CLEAR_ASSIGNMENT_EVENT_PAGINATION_FROM_STORE = 'CLEAR_ASSIGNMENT_EVENT_PAGINATION';
export const SET_ASSIGNMENT_EVENT_PAGE_FILTER = 'SET_ASSIGNMENT_EVENT_PAGE_FILTER';
export const SET_ASSIGNMENT_EVENT_PAGE_SORT = 'SET_ASSIGNMENT_EVENT_PAGE_SORT';

export const getMicrosoftEducationAssignments = (graphId) => ({
	type: GET_MICROSOFT_EDUCATION_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		let endpoint = 'assignments?origin=MS_ASSIGNMENTS';
		if (graphId != null) {
			endpoint += '&graphId=' + graphId;
		}

		api.get(endpoint).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getHaldorAssignments = (graphId, startDate, endDate, expand = null) => ({
	type: GET_HALDOR_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		let endpoint = 'assignments?origin=HALDOR_ASSIGNMENTS';
		if (graphId != null) {
			endpoint += '&graphId=' + graphId;
		}

		if (startDate != null) {
			endpoint += '&startDate=' + startDate;
		}

		if (endDate != null) {
			endpoint += '&endDate=' + endDate;
		}

		if (expand != null) {
			endpoint += '&expand=' + expand;
		}

		api.get(endpoint).then((response) => {
			resolve(response.data);
		});
	}),
});
export const clearAssignmentEventPaginationFromStore = () => ({
	type: CLEAR_ASSIGNMENT_EVENT_PAGINATION_FROM_STORE,
	payload: new Promise((resolve) => {
		resolve();
	}),
});


export const setCurrentAssignmentEventPage = (page) => ({
	type: SET_CURRENT_ASSIGNMENT_EVENT_PAGE,
	payload: new Promise((resolve) => {
		resolve(page);
	}),
});

export const setAssignmentEventPageFilter = (filterField, filterValues) => ({
	type: SET_ASSIGNMENT_EVENT_PAGE_FILTER,
	payload: { filterField, filterValues }
});

export const setAssignmentEventPageSort = (sortField, sortOrder) => ({
	type: SET_ASSIGNMENT_EVENT_PAGE_SORT,
	payload: { sortField, sortOrder }
});

export const getAssignmentEvents = (page, pageSize = 10, params) => {
	return async (dispatch) => {
		try {
			const response = await api.post(`assignments/events?page=${page}&pageSize=${pageSize}`, params);

			var data = {
				page: page,
				total: response.data?.total,
				events: response.data?.data,
			}

			dispatch({
				type: GET_ASSIGNMENT_EVENTS_PAGINATION,
				payload: data
			});
			return data;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};
};


export const createAssignment = (values) => ({
	type: ASSIGNMENT_CREATE,
	payload: new Promise((resolve) => {
		const json = JSON.stringify(values);

		api.post('assignments', json).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateAssignment = (values) => ({
	type: ASSIGNMENT_UPDATE,
	payload: new Promise((resolve) => {
		api.put('assignments', values).then(() => {
			api.get(`assignments/${values.id}`).then((response) => {
				resolve(response.data);
			});
		});
	}),
});
export const uploadUserOneDrive = (assignmentId, files) => ({
	type: UPLOAD_USER_ONE_DRIVE_DOCUMENT,
	payload: new Promise((resolve, reject) => {
		api.post(`assignments/${assignmentId}/uploadOneDriveDocuments`, files)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	}),
});

export const updateAssignmentInList = (updatedAssignment) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_ASSIGNMENT_LIST,
				payload: updatedAssignment,
			});
			return updatedAssignment;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};

}

export const updateAssignmentTaskStatus = (assignmentId, assignmentTaskId, status) => {
	return async (dispatch) => {
		try {
			const response = await api.put('assignments/Student/Status?assignmentTaskId=' + assignmentTaskId + '&status=' + status)
			dispatch({
				type: UPDATE_ASSIGNMENT_TASK_STATUS,
				payload: response.data,
			});
			return response.data;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};
}

export const updateAssignmentTaskSubmittedDate = (task) => {
	return async (dispatch) => {
		try {
			const response = await api.put(`assignments/${task.assignmentId}/tasks/${task.id}/submittedDate`, task.submittedDate);
			dispatch({
				type: UPDATE_ASSIGNMENT_TASK_SUBMITTED_DATE,
				payload: response.data,
			});
			return response.data;
		} catch (error) {
			// Handle the error appropriately
			throw error;
		}
	};
};

export const createBulkAssignments = (values) => ({
	type: CREATE_BULK_TASKS,
	payload: new Promise((resolve) => {
		api.post('assignments/bulk', values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createAssignmentTaskWorkspace = (assignmentId, assignmentTaskId) => ({
	type: CREATE_ASSIGNMENT_TASK_WORKSPACE,
	payload: new Promise((resolve) => {
		api.put(`assignments/${assignmentId}/tasks/${assignmentTaskId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateBulkAssignmentObject = (id, origin = false) => ({
	type: CREATE_BULK_TASKS,
	payload: new Promise((resolve) => {
		let endpoint = `assignments/${id}`;
		if (origin) {
			endpoint = endpoint + "?origin=HALDOR_ASSIGNMENTS"
		}

		api.get(endpoint).then((response) => {
			resolve([response.data]);
		});
	}),
});

export const getTaskDetails = (id, team) => ({
	type: ACTIVE_TASK,
	payload: new Promise((resolve, reject) => {
		let url = `assignments/${id}`;
		if (team != null) {
			url += `?groupId=${team}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssignmentKnowledgeCollections = (sectionId, assignmentId) => ({
	type: ACTIVE_ASSIGNMENT_KNOWLEDGECOLLECTIONS,
	payload: new Promise((resolve) => {
		api.get(`knowledge/section/${sectionId}/assignment/${assignmentId}/collections`).then(
			(response) => {
				resolve(response.data);
			}
		);
	}),
});

export const getFolderFiles = (assignmentId, taskId) => ({
	type: GET_FOLDER_FILES,
	payload: new Promise((resolve) => {
		api.get(`assignments/${assignmentId}/task/${taskId}/files`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteAssignmentUserDocument = (assignmentId, fileName) => ({
	type: DELETE_USER_ASSIGNMENT_DOCUMENT,
	payload: new Promise((resolve) => {
		const values = {
			assignmentId: assignmentId,
			fileName: fileName,
		};

		api.delete(`assignments/${assignmentId}/deleteDocument/${fileName}`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateActiveAssignment = (assignment) => ({
	type: UPDATE_ACTIVE_ASSIGNMENT,
	payload: assignment,
});

export const setActiveAssignment = (id) => ({
	type: SET_ACTIVE_ASSIGNMENT,
	payload: id,
});

export const deleteAssignmentFromStore = (id) => ({
	type: DELETE_ASSIGNMENT_FROM_STORE,
	payload: new Promise((resolve) => {
		resolve(id);
	}),
});

export const clearAssignmentsFromStore = () => ({
	type: CLEAR_ASSIGNMENTS_FROM_STORE,
	payload: true,
});

export const getLatestMicrosoftEducationAssignments = (team) => ({
	type: GET_LATEST_MICROSOFT_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/?graphId=${team}&top=10&origin=MS_ASSIGNMENTS`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getLatestHaldorAssignments = (team) => ({
	type: GET_LATEST_HALDOR_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/?graphId=${team}&top=10&origin=HALDOR_ASSIGNMENTS`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getMicrosoftAssignments = (team) => ({
	type: GET_TEAMS_ASSIGNMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/?graphId=${team}&origin=MS_ASSIGNMENTS`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getMicrosoftAssignmentDetails = (id) => ({
	type: GET_TEAMS_ASSIGNMENT_DETAILS,
	payload: new Promise((resolve) => {
		api.get(`assignments/${id}/details`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssignmentSubmission = (assignment, submission) => ({
	type: GET_TEAMS_ASSIGNMENT_SUBMISSION,
	payload: new Promise((resolve) => {
		api.get(`assignments/${assignment}/submissions/${submission}/files`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setTeacherDone = (assignmentId, done) => ({
	type: SET_TEACHER_DONE,
	payload: new Promise((resolve) => {
		api.post(`assignments/${assignmentId}/teacherdone`, done).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearLatestAssignments = () => ({
	type: CLEAR_LATEST_ASSIGNMENTS,
	payload: [],
});

export const setAssignmentServices = (services) => ({
	type: SET_ASSIGNMENT_SERVICES,
	payload: services,
});

export const searchAssignment = (value) => ({
	type: SEARCH_ASSIGNMENT,
	payload: new Promise((resolve) => {
		api.get(`assignments/search?term=${value}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssignmentStatus = (assignmentId) => ({
	type: GET_ASSIGNMENT_STATUS,
	payload: new Promise((resolve) => {
		api.get(`assignments/${assignmentId}/status`).then((response) => {
			var reponseObject = { assigmentId: assignmentId, created: response.data };
			resolve(reponseObject);
		});
	}),
});

export const getStudentAdditionalAdjustmentsForAssignment = (assignmentId) => ({
	type: GET_ASSIGNMENT_STUDENT_ADJUSTMENTS,
	payload: new Promise((resolve) => {
		api.get(`assignments/${assignmentId}/studentAdditionalAdjustments`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const setMyAssignmentsActiveGroup = (groupId) => ({
	type: SET_MY_ASSIGNMENTS_ACTIVE_GROUP,
	payload: groupId,
});

export const getAssignmentSection = (id, callback) => ({
	type: GET_ASSIGNMENT_SECTION,
	payload: new Promise((resolve) => {
		api.get(`sections/${id}`).then((response) => {
			var data = response.data;

			if (data && data.courses != null && data.courses.length > 0) {
				data.courses.forEach((element) => {
					var sortedList = [];
					if (element.details != null && element.details.length > 0) {
						var activeDetailIndex = element.details.findIndex(
							(element) => element.endDate == null
						);

						if (activeDetailIndex > -1) {
							var activeDetail = element.details[activeDetailIndex];
							element.details.splice(activeDetailIndex, 1);
							element.details.unshift(activeDetail);
						}
					}
				});
			}

			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const clearAssignmentSection = () => ({
	type: CLEAR_ASSIGNMENT_SECTION,
	payload: true,
});
