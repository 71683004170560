import React, { Component } from "react";
import { connect } from "react-redux";
import * as microsoftTeams from "@microsoft/teams-js";

import {
	getTaskDetails,
	setActiveAssignment,
} from "actions/assignments";

import { setActiveSection } from "actions/sections";

import { clearAssessments } from "actions/assessments";
import { clearBlocksOnReference } from 'actions/blocks';

import { Block, translate } from '@haldor/ui';
import { Spinner } from "UI";

import HaldorAssignment from "./Assignment/Haldor";
import MicrosoftAssignment from "./Assignment/Microsoft";
import { isMicrosoftTeams } from "helpers/teams";

class SingleAssignmentV1 extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	componentWillUnmount = () => {
		this.props.setActiveAssignment();
		this.props.clearAssessments();
		this.props.clearBlocksOnReference();

		/**
		 * We need to set activeSection to null when leaving the assignment, ActiveSection will be set when GetSection is called
		 */
		this.props.setActiveSection(null);
	};

	UNSAFE_componentWillMount = () => {
		const { assignmentId } = this.props.match.params;

		if (isMicrosoftTeams()) {
			microsoftTeams.app.getContext().then((context) => {
				if (context.team) {
					this.props.getTaskDetails(assignmentId, context.team.groupId)
						.then(() => {
							this.setState({ loading: false });
						});
				}
				else {
					this.props.getTaskDetails(assignmentId)
						.then(() => {
							this.setState({ loading: false });
						});
				}
			});
		} else {
			this.props.getTaskDetails(assignmentId)
				.then(() => {
					this.setState({ loading: false });
				});
		}
	};

	render() {
		const { loading } = this.state;
		const { assignment } = this.props;

		if (loading || this.props.groups == null) {
			return (
				<div key="single-assignment-spinner">
					<Spinner center />
				</div>
			);
		}

		// Check if assignment is null
		if (this.props.assignment == null) {
			return <div className="single-task">
				<div className="single-section left-side">
					<Block>
						<div style={{ padding: '1rem 0' }} className="text--center color--meta weight--bold">
							{this.props.translate('assignment-has-been-deleted')}
						</div>
					</Block>
				</div>

				<div className="single-actions right-side"></div>
			</div>
		}

		if (assignment != null) {
			let assignmentId = assignment.externalID ?? this.props.match.params.assignmentId;

			if (assignmentId == parseInt(assignmentId, 10) && assignment.origin !== "TEAMS") {
				return <HaldorAssignment assignment={assignment} />;
			}

			return <MicrosoftAssignment assignment={assignment} />;
		} else {
			return <div>{this.props.translate("assignment-not-found")}</div>;
		}
	}
}

function mapStateToProps(state) {
	return {
		assignment: state.assignments.active_assignment,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
		tenant: state.user.tenant
	};
}

export default connect(mapStateToProps, {
	getTaskDetails,
	clearAssessments,
	setActiveAssignment,
	clearBlocksOnReference,
	setActiveSection
})(SingleAssignmentV1);