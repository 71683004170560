import React, { Component } from 'react';
import Block, { BlockContent, BlockFooter } from 'UI/Elements/Block';

import './Style.scss';

class List extends Component {
	render() {
		const { children, title, footerContent } = this.props;

		return (
			<div className="list-container">
				<Block style={ this.props.style }>
					{ title ?
						<BlockContent>
							<h2>{ title }</h2>
						</BlockContent>
					 : null }

					{ children ? children : null }

					<BlockFooter>
						{ footerContent ? footerContent : null }
					</BlockFooter>
				</Block>
			</div>
		);
	}
}

export default List;
