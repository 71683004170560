import api from 'lib/api';

export const TOGGLE_ADVANCED_CALENDAR = 'TOGGLE_ADVANCED_CALENDAR';

//activity filtering
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const TOGGLE_MY_SCHEDULE = 'TOGGLE_MY_SCHEDULE';
export const SET_TOTAL_AMOUNT = 'SET_TOTAL_AMOUNT';

//Group filtering
export const SET_ALL_GROUPS = 'SET_ALL_GROUPS';
export const SET_ALL_SEARCHED = 'SET_ALL_SEARCHED';
export const TOGGLE_ALL_USERS = 'TOGGLE_ALL_USERS';
export const SET_CUSTOM_GROUP = 'SET_CUSTOM_GROUP';

export const SET_SEARCH_GROUP = 'SET_SEARCH_GROUP';
export const REMOVE_SEARCHED_GROUP = 'REMOVE_SEARCHED_GROUP';
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_SECTION_SEARCH = 'ADD_SECTION_SEARCH';

export const CHANGE_SELECTED_PEOPLE = 'CHANGE_SELECTED_PEOPLE';
export const CHANGE_SEARCHED_PEOPLE = 'CHANGE_SEARCHED_PEOPLE';
export const CHANGE_SEARCH_OWNER = 'CHANGE_SEARCH_OWNER';
export const CHANGE_SELECTED_OWNER = 'CHANGE_SELECTED_OWNER';

export const SET_TOTAL_GROUPS = 'SET_TOTAL_GROUPS';

export const REMOVE_SELECTED_PEOPLE = 'REMOVE_SELECTED_PEOPLE';
export const REMOVE_SELECTED_OWNER = 'REMOVE_SELECTED_OWNER';
export const REMOVE_SEARCHED_PEOPLE = 'REMOVE_SEARCHED_PEOPLE';
export const REMOVE_SEARCHED_OWNER = 'REMOVE_SEARCHED_OWNER';

export const CLEAR_ALL = 'CLEAR_ALL';

export const SELECT_ALL_USER = 'SELECT_ALL_USER';
export const REMOVE_ALL_USER = 'REMOVE_ALL_USER';
export const REMOVE_ALL = 'REMOVE_ALL';

export const clearScheduleFilter = (data) => ({
	type: CLEAR_FILTER,
	payload: { filterData: data },
});

export const toggleAdvanceCalendar = () => ({
	type: TOGGLE_ADVANCED_CALENDAR,
});

export const UpdateFilter = (data, obj) => ({
	type: UPDATE_FILTER,
	payload: {
		allData: data,
		obj: obj,
	},
});
export const ClearAllFilter = () => ({
	type: CLEAR_ALL,
});

export const toggleMySchedule = () => ({
	type: TOGGLE_MY_SCHEDULE,
});

export const updateTotalUsers = (total) => ({
	type: SET_TOTAL_AMOUNT,
	payload: { countTotal: total },
});

export const setAllGroups = (sections, count, groups, owners, checkedObj) => ({
	type: SET_ALL_GROUPS,
	payload: {
		data: sections,
		total: count,
		totalGroups: groups,
		totalOwners: owners,
		groupsChecked: checkedObj,
	},
});

export const setAllSearched = (searchedArr, checkedObj) => ({
	type: SET_ALL_SEARCHED,
	payload: {
		data: searchedArr,
		groupsChecked: checkedObj,
	},
});

export const toggleAllUsers = () => ({
	type: TOGGLE_ALL_USERS,
});

export const selectCustomGroup = (data) => ({
	type: SET_CUSTOM_GROUP,
	payload: { group: data },
});

export const selectSearchGroup = (data) => ({
	type: SET_SEARCH_GROUP,
	payload: { group: data },
});

export const removeCustomGroup = (group, fullObj) => ({
	type: REMOVE_SEARCHED_GROUP,
	payload: { group: group, obj: fullObj },
});

export const getSections = (id, isCheckbox, userId, isSearch, callback) => ({
	type: ADD_SECTION,
	payload: new Promise((resolve, reject) => {
		api.get(`sections/${id}?expand=owners;members`)
			.then((response) => {
				if (response.data.owners === null) {
					response.data.owners = [];
				}

				resolve({
					data: response.data,
					checkBox: isCheckbox,
					id: id,
					currentUser: userId,
					search: isSearch,
				});

				if (callback != null) {
					callback(response.data);
				}
			})
			.catch((e) => {
				reject(e);
			});
	}),
});

export const addAll = (id) => {
	return new Promise((resolve, reject) => {
		api.get(`sections/${id}?expand=owners;members`)
			.then((response) => {
				resolve({ data: response.data });
			})
			.catch((e) => {
				reject(e);
			});
	});
};

export const getSectionSearch = (id, isCheckbox, callback) => ({
	type: ADD_SECTION_SEARCH,
	payload: new Promise((resolve, reject) => {
		api.get(`sections/${id}?expand=owners;members`)
			.then((response) => {
				if (response.data.owners === null) {
					response.data.owners = [];
				}

				resolve({ data: response.data, checkBox: isCheckbox, id: id });

				if (callback != null) {
					callback(response.data);
				}
			})
			.catch((e) => {
				reject(e);
			});
	}),
});

export const changeSelected = (data) => ({
	type: CHANGE_SELECTED_PEOPLE,
	payload: { selectedUser: data },
});
export const changeSelectedOwner = (data) => ({
	type: CHANGE_SELECTED_OWNER,
	payload: { selectedUser: data },
});

export const removeSelectedPeople = (data) => ({
	type: REMOVE_SELECTED_PEOPLE,
	payload: { selectedUser: data },
});

export const removeSelectedOwner = (data) => ({
	type: REMOVE_SELECTED_OWNER,
	payload: { selectedUser: data },
});

export const setAllActive = (id, ownerArr, findArr, searched) => ({
	type: SELECT_ALL_USER,
	payload: {
		id: id,
		owners: ownerArr,
		newArr: findArr,
		isSearched: searched,
	},
});

export const removeAllUser = (id, obj, ownerArr, afterRemoved, searchedData = false) => ({
	type: REMOVE_ALL_USER,
	payload: {
		id: id,
		data: obj,
		owners: ownerArr,
		removed: afterRemoved,
		isSearchedData: searchedData,
	},
});

export const removeAll = () => ({
	type: REMOVE_ALL,
});
