import { clearPlansFromStore, getPlans, getPlansBySectionId, clearSectionPlansFromStore } from "actions/plans";
import { getSectionByGraphId, getEducationGroups } from "actions/sections";
import LoadingCards from "components/Cards/LoadingCard";
import SectionTitle from "components/Presentation/SectionTitle";
import { PRESCHOOL } from "constants/schoolTypes";
import MultiplePlanForm from "containers/Forms/PlanningForm/MultiplePlanForm";
import MultiplePreschoolPlanForm from "containers/Forms/PlanningForm/MultiplePreschoolPlanForm";
import SinglePlanForm from "containers/Forms/PlanningForm/SinglePlanForm";
import SectionForm from "containers/Forms/SectionForm";
import ReactGantt from "containers/Gantt/ReactGantt";
import Modal from "containers/Modals/Modal";
import { getRootUrl } from "helpers/url";
import { isUserTeacher } from "helpers/user";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import { Icon, Button, getActiveLanguage } from "@haldor/ui";
import { setPageTitle, setPageActions } from "actions/header";
import User from "_class/User";
import { isMicrosoftTeams } from 'helpers/teams';
import { saveWithExpiration, getWithExpiration } from 'helpers/localstorage';
import * as microsoftTeams from "@microsoft/teams-js";

class MyPlans extends Component {
	constructor(props) {
		super(props);

		moment.locale(getActiveLanguage());
		const momentDate = moment();

		let leftBound = momentDate.startOf('week').toDate();
		let rightBound = momentDate.endOf('week').add(3, 'weeks').toDate();
		if (getWithExpiration('gantt-leftbound') != null) {
			leftBound = moment(getWithExpiration('gantt-leftbound')).toDate();
		}

		if (getWithExpiration('gantt-rightbound') != null) {
			rightBound = moment(getWithExpiration('gantt-rightbound')).toDate();
		}

		this.state = {
			modalIsOpen: false,
			rows: null,
			groups: [],
			sectionId: null,
			noSectionFound: false,
			loading: true,
			teamsContext: null,
			multiplePlanFormModal: false,
			options: {
				labelWidth: "20%",
				labelBorderColor: "#E4E4EB",
				labelAppendBeforeFormat: this.props.translate("week"),
				intervalFormat: "W",
				showBorders: false,
				responsive: true,
				bootstrapped: true,
				leftBound: leftBound,
				rightBound: rightBound,
				placeholder: "",
				beforeClimaxColor: "white",
				afterClimaxColor: "white",
				hideFirstColumn: true,
				barStyle: {
					height: "105px",
					border: "1px solid #E4E4EB",
					showTitle: true,
					shadow: false,
					borderRadius: "4px",
					hoverPopup: false,
					showGroupTitle: true
				}
			},
			groupForm: false,
		};
	}

	UNSAFE_componentWillMount = () => {
		if (this.props.overview) {
			return true;
		}
		this.props.clearSectionPlansFromStore();
		this.props.setPageTitle(this.props.translate("planning-header-overview"));
	};

	componentDidMount = () => {
		this.initComponent();
	};

	initComponent = () => {
		let actions = [];
		const user = new User(this.props.currentUser);
		const isFrame = isMicrosoftTeams();

		if (isFrame) {
			if (getRootUrl() == "/planning-tab/" || getRootUrl() == "/haldor-tab/") {
				microsoftTeams.app.getContext().then((context) => {
					let section = this.props.sections.find(section => section.graphId == context.team.groupId);

					if (section != null) {
						this.setState({
							sectionId: section.id,
							noSectionFound: false,
							loading: false
						});

						if (this.props.sectionPlans != null && this.props.sectionPlans.length > 0) {
							this.parseRows();

							this.props.getPlansBySectionId(section.id).then(() => {
								this.parseRows();
							});
						} else {
							this.props.getPlansBySectionId(section.id).then(() => {
								this.parseRows();
							});
						}
					} else {
						this.setState({
							noSectionFound: true,
							teamsContext: context,
							loading: false
						});
					}
				});
			} else {
				if (this.props.planning != null && this.props.planning.length > 0) {
					this.parseRows();

					this.props.getPlans().then(() => {
						this.parseRows();
					});
				} else {
					this.props.getPlans().then(() => {
						this.parseRows();
					});
				}
			}
		} else {
			if (this.props.planning != null && this.props.planning.length > 0) {
				this.parseRows();

				this.props.getPlans().then(() => {
					this.parseRows();
				});
			} else {
				this.props.getPlans().then(() => {
					this.parseRows();
				});
			}
		}

		if (!this.props.disableCreate && !user.isStudent()) {
			actions.push({
				type: "button",
				value: this.props.translate("Create"),
				onClick: this.toggleMultiplePlansForm,
				disabled: this.props.archived || this.props.locked,
				buttonType: "primary",
				icon: "plus"
			});

			if (getRootUrl() == '/planning-tab/') {
				actions.push({
					type: 'icon',
					onClick: this.toggleGroupSettings,
					icon: 'cog_bw',
				});
			}
		}

		if (this.props.overview) {
			return true;
		}

		this.props.setPageActions(actions);
	}

	parseRows = () => {
		let { groups, rows } = this.state;
		let groupId = this.props.groupid;

		let plans = [];

		if (this.state.sectionId != null || groupId != null) {
			plans = this.props.sectionPlans;
		} else {
			plans = this.props.planning;
		}

		if (plans != null) {
			rows = [];
			plans.map(planning => {
				// One planning object
				const isInGroups = groups.find(
					g_o => g_o.id == planning.sectionId
				);

				// Filter by groupid
				if (groupId && planning.sectionId != parseInt(groupId)) {
					return false;
				}

				let open = false;
				let showGroupTitle = true;
				if (window.location.pathname == "/planning-tab") {
					open = true;
					showGroupTitle = false;
				}

				if (!isInGroups) {
					const newGroup = {
						id: planning.sectionId,
						title: planning.section.title,
						open,
						showGroupTitle
					};

					groups.push(newGroup);
				}

				let url = `${getRootUrl()}plan/${planning.id}`;

				const newRow = {
					title: planning.title,
					id: planning.id,
					borderLeft: `3px solid #997AE8`,
					startDate: moment.utc(planning.timeStart).toDate(),
					climaxDate: moment(planning.timeStart)
						.add("6", "hours")
						.toDate(),
					planning: planning,
					groupColor: "grey",
					endDate: moment.utc(planning.timeEnd).toDate(),
					group: planning.sectionId,
					action: () => {
						this.props.history.push(url);
					}
				};

				if (this.props.dateRestriction && planning.status != "PLANNING_CLOSED") {
					rows.push(newRow);
				} else if (this.props.dateRestriction == false) {
					rows.push(newRow);
				}
			});

			this.setState({
				groups,
				rows,
				loading: false
			});
		}
	};

	toggleIcon = flip => (
		<i className="cl-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				className={flip ? "i-90" : "a-90"}
				style={{ height: "7px", width: "15px" }}
			>
				<path
					id="Path_50"
					data-name="Path 50"
					className="cls-1"
					d="M11.361,1.4,6.38,5.9,1.4,1.4"
				/>
			</svg>
		</i>
	);

	toggleMultiplePlansForm = skipModal => {
		if (this.state.multiplePlanFormModal) {
			if (skipModal) {
				this.setState({ multiplePlanFormModal: false, rows: null }, () => {
					this.props.clearPlansFromStore();
					this.initComponent();
				});
			} else {
				this.setState({ multiplePlanFormModal: false });
			}
		} else {
			this.setState({ multiplePlanFormModal: true });
		}
	};

	toggleIcon = flip => (
		<i className="cl-container">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				className={flip ? 'i-90' : 'a-90'}
				style={{ height: '12px', width: '15px', margin: 0 }}
			>
				<path
					id="Path_50"
					data-name="Path 50"
					className="cls-1"
					d="M11.361,1.4,6.38,5.9,1.4,1.4"
				/>
			</svg>
		</i>
	)

	goToday = (event) => {
		event.preventDefault();
		const { options } = this.state;

		const momentDate = moment();

		options.leftBound = momentDate.startOf('week').toDate();
		options.rightBound = momentDate.endOf('week').add(3, 'weeks').toDate();

		localStorage.removeItem('gantt-leftbound');
		localStorage.removeItem('gantt-rightbound');

		this.setState({ options });
	}

	goBackwards = event => {
		event.preventDefault();
		const { options } = this.state;

		const leftBound = moment(options.leftBound).subtract("1", "weeks").toDate();
		const maxLeftBound = moment().startOf("week").subtract("20", "weeks").toDate();

		if (leftBound > maxLeftBound || this.props.dateRestriction == false) {
			options.leftBound = leftBound;
			options.rightBound = moment(options.rightBound).subtract("1", "weeks").toDate();

			saveWithExpiration('gantt-leftbound', options.leftBound);
			saveWithExpiration('gantt-rightbound', options.rightBound);

			this.setState({ options });
		}
	};

	goForwards = event => {
		event.preventDefault();
		const { options } = this.state;

		const rightBound = moment(options.rightBound)
			.add("1", "weeks")
			.toDate();
		const maxRightBound = moment()
			.endOf("week")
			.add("4", "weeks")
			.add("20", "weeks")
			.toDate();

		if (rightBound < maxRightBound || this.props.dateRestriction == false) {
			options.leftBound = moment(options.leftBound)
				.add("1", "weeks")
				.toDate();
			options.rightBound = rightBound;

			saveWithExpiration('gantt-leftbound', options.leftBound);
			saveWithExpiration('gantt-rightbound', options.rightBound);

			this.setState({ options });
		}
	};

	toggleGroupSettings = (shouldReload) => {
		this.setState({ groupForm: !this.state.groupForm });

		if (shouldReload == true) {
			microsoftTeams.app.getContext().then((context) => {
				this.props.getSectionByGraphId(context.team.groupId);
			});
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	closeModal = () => {
		this.setState({ modalIsOpen: false });
	};

	outputForms = () => {
		const { sectionId } = this.state;
		const isTeacher = isUserTeacher(this.props.currentUser);
		var schoolType = this.props.currentUser.schools.getActiveSchool(
			this.props.currentUser.userId
		).type;

		if (sectionId != null && schoolType != PRESCHOOL) {
			return (
				<SinglePlanForm
					onAbort={this.toggleMultiplePlansForm}
					sectionId={sectionId}
					cloneView={false}
					editView={false}
				/>
			);
		}

		if (schoolType == PRESCHOOL) {
			return (
				<MultiplePreschoolPlanForm
					onAbort={this.toggleMultiplePlansForm}
				/>
			);
		}

		return <MultiplePlanForm onAbort={this.toggleMultiplePlansForm} />;
	};

	teamsTabCloseForm = () => {
		this.setState({ noSectionFound: false }, () => {
			window.location = window.location.href;
		});
	};

	render() {
		const { rows, groups, modalIsOpen, options } = this.state;
		const { planning, currentUser } = this.props;

		const isTeacher = isUserTeacher(this.props.currentUser);
		var schoolType = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type;

		return (
			<div className="my_planning planning">
				<Modal
					isOpen={this.state.multiplePlanFormModal}
					onClose={() => {
						this.toggleMultiplePlansForm(false);
					}}
					title={this.props.translate("plan-create-button")}
				>
					{this.outputForms()}
				</Modal>

				{this.props.group != null ?
					<Modal isOpen={this.state.groupForm} onClose={this.toggleGroupSettings} title={this.props.group.title}>
						<SectionForm
							onAbort={this.toggleGroupSettings}
							editView
							section={this.props.group}
						/>
					</Modal>
					: null}

				<div
					className="section"
					style={{ margin: 0, padding: 0, border: 0 }}
				>
					<SectionTitle>
						{!this.state.loading && !this.state.noSectionFound ? (
							<div style={{ float: 'right' }}>
								<Button type="secondary" style={{ marginLeft: 0, marginRight: '0.5em' }} onClick={this.goBackwards}>
									{this.toggleIcon(false)}&nbsp;
								</Button>

								<Button type="secondary" style={{ marginLeft: 0, marginRight: '0.5em' }} onClick={this.goToday}>
									{this.props.translate('today')}
								</Button>

								<Button type="secondary" style={{ marginLeft: 0 }} onClick={this.goForwards}>
									{this.toggleIcon(true)}&nbsp;
								</Button>
							</div>
						) : null}
					</SectionTitle>
				</div>

				<div style={{ clear: "both" }} />

				{!this.state.noSectionFound &&
					!this.state.loading &&
					planning != null &&
					rows != null &&
					rows.length == 0 ? (
					<div style={{ width: "100%", textAlign: "center" }}>
						{this.props.planning != null ? (
							this.props.planning.length == 0 ? (
								<div className="color--meta text--center weight--bold" style={{ textAlign: 'center' }}>
									{this.props.translate('No planning was found')}
									<br />
								</div>
							) : null
						) : null}
					</div>
				) : rows != null &&
					!this.state.noSectionFound &&
					!this.state.loading ? (
					<div className="gantt-container">
						<ReactGantt
							options={options}
							groups={groups}
							sections={this.props.sections}
							rows={rows}
							weekTranslation={this.props.translate("week-short")}
						/>
					</div>
				) : this.state.noSectionFound == false ? (
					<LoadingCards count={1} />
				) : null}

				{this.state.noSectionFound &&
					!this.state.loading &&
					isTeacher ? (
					<SectionForm
						editView={false}
						teamsContext={this.state.teamsContext}
						teamsTabCloseForm={this.teamsTabCloseForm}
						tab="planning"
						onboarding={true}
					/>
				) : null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		planning: state.planning.plans,
		sectionPlans: state.planning.sectionPlans,
		sections: state.sections.educationGroups,
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
		currentUser: state.user.currentUser,
		group: state.sections.graphSection,
	};
}

export default withRouter(connect(mapStateToProps, {
	getPlans,
	clearPlansFromStore,
	setPageTitle,
	getPlansBySectionId,
	clearSectionPlansFromStore,
	setPageActions,
	getSectionByGraphId,
	getEducationGroups
})(MyPlans));