import {
	GET_BASE_GROUP,
	GET_BASE_GROUPS,
	CREATE_BASE_GROUP,
	UPDATE_BASE_GROUP,
	DELETE_BASE_GROUP,
	CLEAR_ACTIVE_BASE_GROUP,
} from 'actions/basegroups';

const INITIAL_STATE = {
	baseGroups: [],
	baseGroup: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_BASE_GROUP:
			return { ...state, baseGroup: action.payload };

		case GET_BASE_GROUPS:
			return { ...state, baseGroups: action.payload };

		case CREATE_BASE_GROUP:
			return {
				...state,
				baseGroup: action.payload,
				baseGroups: [...state.baseGroups, action.payload],
			};

		case UPDATE_BASE_GROUP:
			return { ...state, baseGroup: action.payload };

		case DELETE_BASE_GROUP:
			return {
				...state,
				baseGroups: [...state.baseGroups.filter(t => t.id != action.payload)],
				baseGroup: INITIAL_STATE.baseGroup,
			};

		case CLEAR_ACTIVE_BASE_GROUP:
			return { ...state, baseGroup: INITIAL_STATE.baseGroup };

		default:
			return state;
	}
}
