import * as assignmentActions from 'actions/assignments';
import { addError } from 'actions/index';
import * as plagiarismActions from 'actions/plagiarism';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import DateTime from '_class/DateTime';
import { Checkbox, Button } from '@haldor/ui';

class FileList extends Component {
	UNSAFE_componentWillMount() {
		this.initState();
	}

	componentDidUpdate(prevProps) {
		const { students } = this.props;
		if (prevProps.students !== students) {
			this.initState();
		}
	}

	onCheckSelectedClick() {
		const { allFiles } = this.state;
		const selectedFileIds = this.selectedFileIds();
		const selectedPlagiarismObjs = allFiles.filter(f => selectedFileIds.includes(f.id))
			.map(f => this.plagiarismObjectFromFile(f));
		this.dispatchCheckAction(selectedPlagiarismObjs);
	}

	onCheckNowClicked(file) {
		const plagiarismObj = this.plagiarismObjectFromFile(file);

		this.dispatchCheckAction([plagiarismObj]);
	}

	getSubmitters(assignmentTaskId) {
		const { students } = this.props;
		const assignment = students.find(x => x.id === assignmentTaskId);
		var assignedUsers = assignment.assignedTo.split(";");
		var submitterEmail = '';

		assignedUsers.forEach((user) => {
			var student = this.props.activeSection.students.find(t => t.id == user);

			if (student != null) {
				submitterEmail = student.email;
				return;
			}
		})

		return submitterEmail;
	}

	isAllSelected() {
		const { fileSelectStatus } = this.state;
		return this.selectedFileIds().length > 0
			&& this.selectedFileIds().length === Object.keys(fileSelectStatus).length;
	}

	selectedFileIds() {
		const { fileSelectStatus } = this.state;
		return Object.keys(fileSelectStatus).filter(x => fileSelectStatus[x]);
	}

	hasSelectedFiles() {
		return this.selectedFileIds().length > 0;
	}

	initState() {
		const { students } = this.props;
		const allFiles = students.reduce((prev, curr) => [...prev, ...curr.files], []);
		const fileSelectStatus = allFiles.filter(f => !f.status) // Only files without status (null) should be selectable
			.reduce((prev, curr) => ({ ...prev, [curr.id]: false }), {});
		this.setState({ allFiles, fileSelectStatus });
	}

	plagiarismObjectFromFile(file) {
		const submitters = this.getSubmitters(file.assignmentTaskId);
		var timeSent = new DateTime();
		return {
			DocumentId: file.id,
			GroupId: this.props.activeSection.graphId,
			TimeSent: timeSent.data,
			TimeLastChecked: null,
			TimeLastUpdated: null,
			NextCheck: null,
			SystemSubmissionId: null,
			ContentType: 'docx',
			Status: null,
			Submitter: submitters,
			Identifier: '',
			Significance: null,
			ReportUrl: null,
			ErrorInfo: null,
			TenantId: '',
			BlobFilename: null,
			Id: 0,
			MinutesAdded: null,
			Receiver: null,
			OptOutUrl: null,
			PlagSystemMessage: null,
			TimeAdded: null,
		};
	}

	dispatchCheckAction(data) {
		const {
			activeTaskId,
			checkPlagiarism,
			getTaskDetails,
			toast,
			translate,
		} = this.props;

		// TODO: loading indicator?
		checkPlagiarism(data).then(() => {
			const sentFiles = data.length;
			const toastMessage = sentFiles === 1
				? `1 ${translate('toast-one-plagiarism-check-sent')}`
				: `${sentFiles} ${translate('toast-multi-plagiarism-check-sent')}`;
			toast(toastMessage, 'info');
			getTaskDetails(activeTaskId);
			this.checkAll(false);

			if (this.props.reloadParent) {
				this.props.reloadParent();
			}
		});
	}

	checkRow(id, checked) {
		const { fileSelectStatus } = this.state;
		const newState = { ...fileSelectStatus, [id]: checked };
		this.setState({ fileSelectStatus: newState });
	}

	checkAll(checked) {
		const { fileSelectStatus } = this.state;
		const newState = Object.keys(fileSelectStatus).reduce((prev, curr) => ({ ...prev, [curr]: checked }), {});
		this.setState({ fileSelectStatus: newState });
	}

	getStatus = (status) => {
		if (!status) {
			return '-';
		}

		if (status == 'Not Submitted' || status == 'Submitted') {
			return this.props.translate('Submitted');
		}

		if (status == 'Analyzed') {
			return this.props.translate('analyzed');
		}

		if (status == 'Error') {
			return this.props.translate('something-went-wrong');
		}

		if (status == 'NotCustomer') {
			return this.props.translate('not-a-customer-plagiarism');
		}

		if (status == 'NoUnitFound') {
			return this.props.translate('no-unit-found');
		}

		if (status == 'TimedOut') {
			return this.props.translate('timed-out')
		}

		return status;
	}

	render() {
		const { students, translate } = this.props;
		const { fileSelectStatus } = this.state;

		if (students == null && students.length == 0) {
			return null;
		}

		const rows = students.reduce((prev, curr) => {
			if (curr.files != null) {
				return [...prev, curr.groupName, ...curr.files];
			} else {
				return [...prev, curr.groupName];
			}
		}, []);

		const isAllSelected = this.isAllSelected();
		const nameStyle = {
			textAlign: 'left',
		};

		return (
			<div className="form">
				<table className="status-list">
					<thead>
						<tr>
							<th><Checkbox value={isAllSelected} onChange={checked => this.checkAll(checked)} /></th>
							<th>{translate('filename')}</th>
							<th>{translate('Status')}</th>
							<th>{translate('significance')}</th>
							<th>{translate('report')}</th>
							<th />
						</tr>
					</thead>

					<tbody>
						{rows.map(row => (
							typeof row === 'string' ? ( // Just a string, it's the name of the student
								<tr key={row}>
									<td colSpan="6" style={nameStyle} className="name">{row}</td>
								</tr>
							)
								: ( // Object with file information
									<tr key={row.id}>
										<td>
											{row.status ?
												null
												:
												<Checkbox
													disabled={row.status}
													value={fileSelectStatus[row.id]}
													onChange={checked => this.checkRow(row.id, checked)}
												/>
											}
										</td>

										<td><a target="_blank" href={row.wopiUrl}>{row.filename}</a></td>
										<td>{this.getStatus(row.status)}</td>
										<td>{row.significance || '-'}</td>
										<td>{row.reportUrl ? <a target="_blank" href={row.reportUrl}>{translate('report')}</a> : '-'}</td>

										<td>
											<Button type="secondary" disabled={row.status} onClick={() => this.onCheckNowClicked(row)}>
												{translate('check-plagiarism')}
											</Button>
										</td>
									</tr>
								)
						))}
					</tbody>
				</table>

				<div style={{ marginTop: '1rem', padding: '0 1.5em' }}>
					<Button disabled={!this.hasSelectedFiles()} type="secondary" onClick={() => this.onCheckSelectedClick()}>
						{this.props.translate('plagiarism-check-selected')}
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
	activeTaskId: state.assignments.active_assignment.id,
	activeSection: state.assignments.section,
});

const mapDispatchToProps = {
	...plagiarismActions,
	...assignmentActions,
	toast: addError,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileList);