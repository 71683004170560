import React, { Component } from 'react';
import User from '_class/User';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';
import { Field, reduxForm } from 'redux-form';

import getEnvironment from 'lib/env';
import { getToken, loginRequest, http } from 'lib/msal';

import DropzoneComponent from 'react-dropzone-component';

import { Tags, Button, Icon } from '@haldor/ui';

import './_fileUpload.scss';

class FileUpload extends Component {

	constructor(props) {
		super(props);

		this.state = {
			files: [],
			uploadedFiles: [],
		};

		this.dropzone = null;
		const user = new User(props.currentUser);

		this.componentConfig = {
			iconFiletypes: ['.jpg', '.png', '.gif'],
			showFiletypeIcon: false,
			postUrl: `${http.defaults.baseURL}users/${props.userId}/files?schoolId=${user.getActiveSchool().id}`,
			dropzoneSelector: '.dropzone-trigger',
		};

		this.djsConfig = {
			autoProcessQueue: false,
			parallelUploads: 2,
			maxFilesize: 3,
			headers: {},
		};

		loginRequest.scopes = getEnvironment().scopes;
		getToken(loginRequest).then((response) => {
			this.djsConfig.headers['Authorization'] = 'Bearer ' + response.accessToken;
		});

		this.dropzoneEvents = {
			init: this.dropzoneInit,
			addedfile: this.onAddFile,
			success: this.uploadedFile,
		};
	}

	dropzoneInit = (dropzone) => {
		this.dropzone = dropzone;
	}

	triggerDropzone = () => {
		if (this.dropzone != null) {
			this.dropzone.hiddenFileInput.click();
		}
	}

	onAddFile = (file) => {
		let { files } = this.state;

		files.push(file);

		setTimeout(() => {
			this.setState({ files });
		}, 40);
	}

	onRemoveFile = (file) => {
		let { files } = this.state;

		const fileIndex = files.findIndex(function (f) {
			return f.upload.filename === file.upload.filename;
		});

		files.splice(fileIndex, 1);
		this.setState({ files }, () => {
			if (this.dropzone) {
				this.dropzone.removeFile(file);
			}
		});
	}

	uploadedFile = (djsFile, response) => {
		// Gets called every time a file is uploaded
		let files = this.state.uploadedFiles;

		response.map((file) => {
			files.push(file);
		});

		this.setState({ uploadedFiles: files });
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	submit = (values) => {
		return new Promise(resolve => {
			if (this.dropzone && this.dropzone.getQueuedFiles().length > 0) {
				this.dropzone.processQueue();

				this.dropzone.on("queuecomplete", () => {
					// Do something after all files are uploaded
					values.files = this.state.uploadedFiles;

					if (this.props.onSubmit != null) {
						this.props.onSubmit(values).then(() => {
							resolve(1);
						});
					} else {
						resolve(1);
					}
				});
			} else {
				resolve(1);
			}
		});
	}

	render() {
		const { submitting, handleSubmit, translate } = this.props;

		return (
			<div className="form-container">
				{submitting ?
					<div className="is_sending"><p><span className="loading-spinner" /></p></div>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component file-upload">
					<div className="form-row">
						<label style={{ marginTop: 0 }}>
							{translate('open-sharepoint-onedrive')}
						</label>

						<div className="dropzone-trigger icon-container" style={{ height: 'auto', width: '100%' }} onClick={this.triggerDropzone}>
							<div className="icon">
								<Icon name="File" />
							</div>

							<div className="text">
								{this.props.translate('drop-files-or-click-here')}
							</div>

							<DropzoneComponent
								djsConfig={this.djsConfig}
								eventHandlers={this.dropzoneEvents}
								config={this.componentConfig}
							/>
						</div>
					</div>

					{this.state.files.length > 0 ?
						<div className="form-row file-list">
							<div>
								<label>{translate('files-on-queue')}</label>
							</div>

							{this.state.files.map((file, index) => {
								return (
									<div key={'file-' + index} className={`file-preview ${file.upload.progress > 0 ? ' upload-started' : ' not-started'}`}>
										<div style={{ cursor: 'pointer', marginRight: '0.6rem' }} onClick={() => this.onRemoveFile(file)}>
											<Icon name="Bin" />
										</div>

										<div className="file-name">
											{file.name}
										</div>

										<div className="file-progress">
											<div style={{ width: file.upload.progress + '%' }} className="file-progress-bar" />
										</div>
									</div>
								);
							})}
						</div>
						: null}

					<div className="form-row">
						<label>
							{translate('Add tag')}
						</label>

						<Field
							component={Tags}
							name="tags"
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting} type="primary">
							{translate('upload')}
						</Button>

						<div className="align-right">
							<Button onClick={this.onCancel} disabled={submitting} type="secondary">
								{this.props.translate('Cancel')}
							</Button>
						</div>
					</div>

				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(reduxForm({
	form: 'FileUpload',
	destroyOnUnmount: true,
})(FileUpload));