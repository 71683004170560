import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import sniffer from 'sniffer';
import { Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';
import { Spinner } from 'UI';
import { Flex, Button } from '@haldor/ui';
import { FlexibleTextArea } from 'UI/Inputs';

class MessageForm extends Component {

	constructor(props) {
		super(props);

		this.input = null;

		this.state = {
			hideInput: false,
		}
	}

	/* Utils */
	_setInput = (element) => {
		this.input = element;
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		if (this.input != null && !sniffer.isDevice) {
			ReactDOM.findDOMNode(this.input).focus();
		}
	}

	/* Events */
	submit = (values) => {
		return new Promise(async (resolve, reject) => {
			await this.setState({ hideInput: true });

			const { conversation } = this.props;
			let data = { ...values };

			data.text = values.text + '';

			if (conversation != null) {
				data.conversationID = conversation.id;
			}

			data.parentId = null;
			data.text = data.text.replace(/\n/g, "<br />");

			await this.setState({ hideInput: false });

			if (this.props.onSubmit) {
				this.props.onSubmit(data).then(() => {
					resolve(1);
					this.props.reset();
				});
			} else {
				setTimeout(() => {
					this.setState({ hideInput: false });
					resolve(1);
					this.props.reset();
				}, 1000);
			}
		});
	}

	required = (value) => {
		if (typeof (value) !== 'undefined' && value !== '') {
			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('write-message');
	}

	render() {
		const { handleSubmit, submitting, valid } = this.props;
		let placeHolderText = this.props.translate('write-message');
		if (this.props.conversation != null) {
			if (this.props.conversation.status.toUpperCase() == "ARCHIVED") {
				placeHolderText = this.props.translate('conversation-archived');
			}
		}

		return (
			<div className="conversation message-form">
				<Form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<Flex center>
						<div className="input-container">
							{!this.state.hideInput ?
								<Field
									component={FlexibleTextArea}
									type="text"
									name="text"
									placeholder={placeHolderText}
									validate={this.required}
									rows={1}
									disabled={this.props.disabled}
									ref={this._setInput}
									withRef
									forwardRef
								/>
								: null}
						</div>

						<Button disabled={submitting || !valid || this.props.disabled}>
							{!submitting ?
								<Icon name="Send" />
								: null}

							{submitting ?
								<Spinner center small />
								: null}
						</Button>
					</Flex>
				</Form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(reduxForm({
	form: 'MessageForm',
	destroyOnUnmount: true,
})(MessageForm));
