import {
	deleteDocument,
	deleteDocumentFromStore,
	getPreschoolDetails,
	setActivePreschoolDocument,
	getDocumentReadList,
	clearDocumentReadList
} from 'actions/documents';
import { fetch_preschoolgoals } from 'actions/preschoolform';
import { getReaderParameters } from 'actions/user';
import { setPageTitle } from 'actions/header';
import { CardSubtitle } from 'components/Cards/Card';
import Date from 'components/Presentation/Date/Date';
import PostContent from 'components/Presentation/PostContent';
import Documentation from 'containers/Forms/Documentation';
import Modal from 'containers/Modals/Modal';
import { trackImmersiveReaderError } from 'helpers/insights';
import { getRootUrl } from 'helpers/url';
import { isUserTeacher } from 'helpers/user';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert2';
import { Spinner } from 'UI';
import Block, { BlockContent, BlockWrapper } from 'UI/Elements/Block';
import { Immersive_Reader } from 'UI/Icons';
import { getDescription } from 'helpers/content';
import { Button, Block as ContentBlock, Icon } from '@haldor/ui';
import FileItem from 'components/FileItem';
import ProgressBar from 'UI/Elements/ProgressBar';
import DisplayName from 'components/Presentation/DisplayName';

const ImmersiveReader = require('@microsoft/immersive-reader-sdk');

class SinglePreschoolDocument extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			files: [],
			relationshipList: false,
			loadingReadlist: true,
		}
	}

	componentWillUnmount = () => {
		this.props.setActivePreschoolDocument();
		ImmersiveReader.close();
	}

	componentDidMount = () => {
		const { match, currentUser } = this.props;

		this.props.getPreschoolDetails(match.params.id).then(() => {
			this.props.setPageTitle(this.props.activeDocument.title);

			if (this.props.activeDocument.creator == currentUser.id || this.props.activeDocument.editable) {
				this.props.getDocumentReadList(match.params.id).then(() => {
					this.setState({ loadingReadlist: false });
				});
			} else {
				this.props.clearDocumentReadList();
			}
		}).catch((error) => {
			if (error.status == 204) {
				this.props.addError(this.props.translate("Document has been deleted"), 'error');
				this.props.history.push('/');
			}
		});

		if (this.props.preschoolGoals.length < 1) {
			this.props.fetch_preschoolgoals();
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = (proxy, skipPrompt) => {
		const { match } = this.props;

		this.setState({ modalIsOpen: false });

		if (skipPrompt) {
			this.props.getPreschoolDetails(match.params.id).then(() => {
				this.props.setPageTitle(this.props.activeDocument.title);
				this.props.getDocumentReadList(match.params.id).then(() => {
					this.setState({ loadingReadlist: false });
				});
			});
		}
	}

	editButton = () => {
		if (this.props.activeDocument == null || this.props.activeDocument.title == null) {
			return null;
		}

		return (
			<div>
				<Button type="secondary" onClick={this.openModal}>
					{this.props.translate('Edit')}
				</Button>
			</div>
		);
	}

	deleteModal = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('delete-post-prompt'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('Cancel'),
			confirmButtonText: this.props.translate('Delete'),
		}).then((result) => {
			if (result.value != null) {
				this.props.deleteDocument(this.props.match.params.id)
					.then(() => {
						this.props.deleteDocumentFromStore(this.props.match.params.id);
						this.props.history.push(getRootUrl());
					});
			}
		});
	}

	deleteButton = () => {
		return (
			<div>
				<Button type="secondary" style={{ marginTop: 5 }} onClick={this.deleteModal}>
					{this.props.translate('Delete')}
				</Button>
			</div>
		);
	}

	runImmersiveReader = async () => {
		const { activeDocument, currentUser } = this.props;
		const localLanguage = localStorage.getItem('language');

		console.log('run Microsoft Immersive Reader', localLanguage, currentUser.id);

		const data = {
			title: activeDocument.title,
			chunks: [
				{
					content: '<h1>' + activeDocument.title + '</h1>',
					mimeType: 'text/html',
				},
				{
					content: getDescription(activeDocument.description),
					mimeType: 'text/html',
				}
			],
		};

		await this.props.getReaderParameters();
		const { reader } = this.props;

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error('error', error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	}

	toggleRelationshipList = () => {
		const { readList } = this.props;

		if (readList != null && readList.filter(read => read.userType === 'GUARDIAN').length > 200) {
			const { translate } = this.props;
			swal.fire({
				title: '',
				text: translate('Since the post was shared with more than 200 people, you can not see who read it'),
				showCancelButton: false,
				confirmButtonText: translate('Ok'),
			})

			return true;
		}

		this.setState({ relationshipList: !this.state.relationshipList });
	}

	renderReadList = () => {
		const { translate, readList } = this.props;
		const { relationshipList } = this.state;

		if (readList != null && readList.filter(read => read.userType === 'GUARDIAN').length > 200) {
			return null;
		}

		return <Modal type="small" isOpen={relationshipList} overridePrompt onClose={this.toggleRelationshipList} title={translate('Shared with guardians only')}>
			<div className="form single-post-read-list">
				{readList != null ?
					<div style={{ marginLeft: "1.5rem" }}>
						<div className="info-item">
							<Icon bw name="Alert" />
							{translate('Recipients who have read the documentation are marked with')}
							<Icon name="Eye" title={this.props.translate('have-read')} />
						</div>
						{readList.map((read) => {
							return <div className="user-item" key={read.userId} style={{ display: "flex" }}>
								{read.userType != 'GUARDIAN' && read.user.guardians != null && read.user.guardians.length > 0 ?
									<div style={{ paddingBottom: ".7rem" }}>
										<span>
											<DisplayName
												firstName={read.user.firstName}
												lastName={read.user.lastName}
											/>
										</span>
										{read.user.guardians.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
											.map((guardian) => {
												let foundGuardian = readList.find(read => read.userId == guardian.id && read.userType == 'GUARDIAN');
												const hasRead = foundGuardian && foundGuardian.hasRead;
												return <div style={{ display: "flex", marginLeft: "2rem", fontWeight: hasRead ? "bold" : "normal" }}>
													<span style={{ display: "inline-flex" }}>
														<DisplayName
															firstName={guardian.firstName}
															lastName={guardian.lastName}
														/>
														{hasRead ?
															<Icon name="Eye" title={this.props.translate('have-read')} />
															: ""}
													</span>
												</div>
											})}
									</div>
									: ""}
							</div>
						})}
					</div>
					:
					<div className="color--meta text--center size-12">
						{this.props.translate('not-read-post')}
					</div>
				}
			</div>
		</Modal>
	}

	render() {
		const { activeDocument, currentUser, preschoolGoals } = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);
		const isCreator = currentUser.id == activeDocument.creator;

		if (activeDocument == null) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		let readCount = 0;
		let percentage = 0;
		let sharedWithPeopleCount = 0;

		if (this.props.readList != null) {
			readCount = [...this.props.readList].filter(read =>
				read.hasRead
			).length;

			sharedWithPeopleCount = [...this.props.readList].filter(read =>
				read.userType == "GUARDIAN"
			).length;

			percentage = Math.round(100 / sharedWithPeopleCount * readCount);
		}

		return (
			<div>
				{isTeacher ? this.renderReadList() : null}

				{isTeacher ?
					<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={this.props.translate('edit-document')}>
						<Documentation onModalClose={this.closeModal} />
					</Modal>
					: null}

				<div className="single-task single-preschool_document">
					<div className="single-section form left-side" style={{ paddingTop: '0' }}>
						<ContentBlock>
							{activeDocument.status == 'POST_DRAFT' ?
								<div style={{ marginBottom: '.35rem' }}>
									{this.props.translate('Draft')}
								</div>
								: null}

							<div className="single-task-description">
								<span className="title">{this.props.translate('description')}</span>

								{this.props.services.immersiveReaderPosts ?
									<Link to="#" onClick={this.runImmersiveReader}>
										<div className="reader trigger">
											<Immersive_Reader />
											{this.props.translate('immersive-reader')}
										</div>
									</Link>
									: null}

								<PostContent>{activeDocument.description}</PostContent>
							</div>

							<div style={{ marginTop: '1rem' }}>
								<span className="title">{this.props.translate('date')}</span>
								<CardSubtitle><Date>{activeDocument.created}</Date></CardSubtitle>
							</div>

							<div style={{ marginTop: '1rem' }}>
								<span className="title">{this.props.translate('column-created-by')}</span>
								{activeDocument.author != null ?
									<CardSubtitle>
										{activeDocument.author}
									</CardSubtitle>
									: null}
							</div>
							{isTeacher ?
								<div style={{ marginTop: '1rem' }}>
									<span className="title">{this.props.translate('shared-with')}</span>
									{activeDocument.relationships != null ?
										activeDocument.relationships.map((relationship) => {
											return <div key={relationship.referenceId}>
												<DisplayName
													firstName={relationship.referenceTitle}
													email={relationship.referenceEmail}
													data={activeDocument.relationships}
												/>
											</div>
										})
										: null}
								</div>
								: null}
						</ContentBlock>

						<BlockWrapper cols="2">
							{activeDocument.associatedGoals &&
								activeDocument.associatedGoals.length > 0 &&
								preschoolGoals && preschoolGoals.length > 0 ?
								<Block>
									<BlockContent>
										{activeDocument.associatedGoals.map(goal => {
											let goalObject = preschoolGoals.find(function (gb) {
												return gb.id == goal.goalId;
											});

											if (goalObject) {
												return (
													<div key={goalObject.id}
														style={{ backgroundColor: goalObject.colorCode }}
														data-tooltip={goalObject.text}
														className="goal-preview">
														{goalObject.title}
													</div>
												)
											}

											return null;
										})}
									</BlockContent>
								</Block>
								: null}
						</BlockWrapper>

						{activeDocument.associatedFiles != null && activeDocument.associatedFiles.length > 0 ?
							<div className="preschool-gallery">
								<BlockWrapper cols="2">
									{activeDocument.associatedFiles.map((file, index) => {
										if (file.type != null && file.type.indexOf('image') > -1) {
											return <Block key={`file-${file.id}-${index}`}>
												<FileItem
													file={file}
													style={{
														width: '100%',
														height: '100%',
														display: 'block',
														borderRadius: 'inherit',
													}}
												/>
											</Block>
										}

										if (file.type != null) {
											return <Block key={`file-${file.id}-${index}`}>
												<FileItem file={file}>
													<div style={{ padding: '0.755rem' }}>
														<div style={{
															display: 'inline-block',
															position: 'relative',
															top: 3,
															marginRight: '0.25rem'
														}}>
															<Icon name="File" />
														</div>

														{file.name}
													</div>
												</FileItem>
											</Block>
										}

										return null;
									})}
								</BlockWrapper>
							</div>
							: null}
					</div>

					<div className="single-actions right-side">
						{activeDocument.planId != null ?
							<div className="action-section">
								<h3>{this.props.translate('tools')}</h3>

								<Link to={`/plan/${activeDocument.planId}`}>
									<Button type="secondary">
										{this.props.translate('plan')}
									</Button>
								</Link>
							</div>
							: null}

						<div className="action-section">
							{activeDocument.planId != null || !activeDocument.editable ? null :
								<h3>
									{this.props.translate('tools')}
								</h3>
							}

							{isCreator || activeDocument.editable ? this.editButton() : null}
							{isCreator || activeDocument.deletable ? this.deleteButton() : null}

							{this.props.readList != null && !this.state.loadingReadlist ?
								<div className="single-post-read-list-trigger" onClick={this.toggleRelationshipList}>
									<Icon name="Eye" />
									{readCount}/{sharedWithPeopleCount} {this.props.translate('have-read')}
								</div>
								: null}

							{this.props.readList != null && this.props.readList.length > 0 && !this.state.loadingReadlist ?
								<div style={{ width: 230 }}>
									<ProgressBar
										percentage={percentage}
										fillColor="var(--color--meta)"
									/>
								</div>
								: null}
						</div>
					</div>

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		activeDocument: state.Documents.activeDocument,
		preschoolDocuments: state.Documents.documents,
		readList: state.Documents.readList,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
		reader: state.user.reader,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setActivePreschoolDocument,
		fetch_preschoolgoals,
		getPreschoolDetails,
		clearDocumentReadList,
		deleteDocument,
		deleteDocumentFromStore,
		getReaderParameters,
		getDocumentReadList,
		setPageTitle,
	}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SinglePreschoolDocument));
