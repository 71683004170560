import React, { Fragment, useEffect } from 'react';
import { Checkbox, Icon } from '@haldor/ui';
import { useDispatch, useSelector } from 'react-redux';
import { Collapsible, Spinner } from 'UI';
import Plus from 'UI/IconsSrc/plus';
import { translate as getTranslate } from '@haldor/ui';
import Minus from 'UI/IconsSrc/minus';
import SearchGroups from './SearchGroups';
import {
	removeAll,
} from 'actions/ScheduleFilter';
import DisplayName from 'components/Presentation/DisplayName';

const ListSelectorDropDown = (props) => {
	const {
		groupsSelectedCount,
		handleAll,
		fetchData,
		handlePlus,
		getActiveSearch,
		handlePeopleSelection,
		removeSearchedItem,
		searchGroup,
		isIopen,
		isLoading,
		disabled
	} = props;
	const ScheduleFilter = useSelector((state) => state.ScheduleFilter);
	const sections = useSelector((state) => state.sections.educationGroups);
	const translate = getTranslate(useSelector((state) => state.Languages.translations));
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.user.currentUser);


	//increase zIndex to allow this component to temporary overlapp the c--sider menu
	useEffect(() => {
		let element = document.querySelector('.c--content');
		if (element) {
			element.style.zIndex = 2;
		}
		return () => {
			let element = document.querySelector('.c--content');
			if (element) {
				element.style.zIndex = 1;
			}
		}
	}, []);

	return (
		<div>
			<div style={{ marginBottom: '2rem' }} onAbort={props.myAction ? props.myAction() : undefined}>
				{sections === null ? (
					<div className='listLoader'>
						<Spinner small center />
					</div>
				) : (
					<Fragment>
						<div className='toolTip_groups_header'>
							<div>
								<span>
									{`${translate('Students')}: ${ScheduleFilter?.selectedPeople}`}
								</span>
							</div>

							<div>
								<span>
									{`${translate('Owner')}: ${ScheduleFilter?.ownersSelected}`}
								</span>
							</div>
						</div>

						<Checkbox
							value={groupsSelectedCount == sections.length}
							onChange={handleAll}
							label={translate('select-all')}
							disabled={disabled}
						/>

						<div className="expendable-Header">
							<span style={{ fontSize: 'var(--size-14)', fontWeight: 500 }}>
								{translate('My groups')}
							</span>
						</div>

						{sections.map((items) => {
							items.owners = [];
							items.students = [];

							const groupData = ScheduleFilter.customGroup.find((x) => x.id == items.id);
							const filterData = ScheduleFilter.userFilter.find((x) => x.id == items.id);
							let owners = [];
							let groupIsIndeterminate = false;
							let groupIsActive = false;

							if (groupData?.owners) {
								owners = groupData.owners.filter((x) => x.id !== currentUser?.id);
							}

							if (groupData != null && filterData != null) {
								const selectedCount = filterData.students.length + filterData.owners.filter((x) =>
									x.id !== currentUser?.id
								).length;
								const totalCount = groupData.students.length + owners.length;

								if (selectedCount > 0) {
									groupIsIndeterminate = totalCount != selectedCount;
									groupIsActive = totalCount == selectedCount;
								}
							}

							return (
								<div className='expendable-Header' key={items.id}>
									<div className='title'>
										<Checkbox
											value={groupIsActive}
											indeterminate={groupIsIndeterminate}
											label={items.title}
											onChange={() => {
												if (groupIsActive) {
													fetchData(false, items.id, true, groupData, owners);
												} else {
													if (groupIsIndeterminate) {
														fetchData(true, items.id, true, groupData, owners);
													} else {
														fetchData(true, items.id, true, groupData, []);
													}
												}
											}}
											disabled={disabled}
										/>

										<span className='groupOwner'>
											{translate('Owner')}
										</span>
									</div>

									<button
										className='trigger-button'
										onClick={() => handlePlus(isIopen[items.id], items.id)}
									>
										<span className='trigger'>
											{isIopen[items.id] ? <Minus /> : <Plus />}
										</span>
									</button>

									<div className='subSection'>
										{isIopen[items.id] ? (
											<Fragment>
												{isLoading[items.id] && (
													<div className='listLoader'>
														<Spinner small center />
													</div>
												)}

												<div>
													{owners.map((owner) => (
														<div key={owner.id}>
															<div className='title'>
																<Checkbox
																	value={getActiveSearch(items.id, owner.id, true)}
																	label={(
																		<span>
																			{owner.firstName}{' '}
																			{owner.lastName}

																			<span className='groupOwner'>
																				{translate('Owner')}
																			</span>
																		</span>
																	)}
																	onChange={(checked) =>
																		handlePeopleSelection(
																			checked,
																			items,
																			owner,
																			true
																		)
																	}
																	disabled={disabled}
																/>
															</div>
														</div>
													))}

													{groupData?.students.map((student) => (
														
														<div key={student.id}>
															<div className='title'>
																<Checkbox
																	value={getActiveSearch(items.id, student.id)}
																	label={
																		<DisplayName
																		firstName={student.firstName}
																		lastName={student.lastName}
																		email={student.email}
																		data={groupData?.students}
																		/>
																	}
																	onChange={(checked) =>
																		handlePeopleSelection(checked, items, student)
																	}
																	disabled={disabled}
																/>
															</div>
														</div>
													))}
												</div>
											</Fragment>
										) : null}
									</div>
								</div>
							);
						})}

						{/* Section for nonOwned groups  */}
						<div className='searchStudents'>
							{ScheduleFilter?.storedSearch.length > 0 ?
								<div className="expendable-Header">
									<span style={{ fontSize: 'var(--size-14)', fontWeight: 500 }}>
										{translate('Other groups')}
									</span>
								</div>
								: null}

							{ScheduleFilter?.storedSearch.map((items) => {
								const groupData = ScheduleFilter.storedSearch.find((x) => x.id === items.id);
								const filterData = ScheduleFilter.userFilter.find((x) => x.id == items.id);

								let owners = [];
								let groupIsIndeterminate = false;
								let groupIsActive = false;
								if (groupData?.owners) {
									owners = groupData.owners.filter((x) => x.id !== currentUser?.id);
								}

								if (groupData != null && filterData != null) {
									const selectedCount = filterData.students.length + filterData.owners.filter((x) =>
										x.id !== currentUser?.id
									).length;
									const totalCount = groupData.students.length + owners.length;

									if (selectedCount > 0) {
										groupIsIndeterminate = totalCount != selectedCount;
										groupIsActive = totalCount == selectedCount;
									}
								}

								return (
									<div className='expendable-Header' key={items.id}>
										<div className='title'>
											<Checkbox
												value={groupIsActive}
												indeterminate={groupIsIndeterminate}
												label={items.title}
												onChange={() => {
													if (groupIsActive) {
														fetchData(false, items.id, true, groupData, owners, true);
													} else {
														if (groupIsIndeterminate) {
															fetchData(true, items.id, true, groupData, owners, true);
														} else {
															fetchData(true, items.id, true, groupData, [], true);
														}
													}
												}}
												disabled={disabled}
											/>{' '}

											<button
												className='removeStudentSearch'
												onClick={() => removeSearchedItem(items.id, groupData)}
											>
												<Icon name='Close' />
											</button>
										</div>

										<button
											className='trigger-button'
											onClick={() => handlePlus(isIopen[items.id], items.id, true)}
										>
											<span className='trigger'>
												{isIopen[items.id] ? <Minus /> : <Plus />}
											</span>
										</button>

										<div className='subSection'>
											{isIopen[items.id] ? (
												<Fragment>
													{isLoading[items.id] && (
														<div className='listLoader'>
															<Spinner small center />
														</div>
													)}

													<div>
														{owners.map((owners) => (
															<div key={owners.id}>
																<div className='title'>
																	<Checkbox
																		value={getActiveSearch(
																			items.id,
																			owners.id,
																			true,
																			true
																		)}
																		label={
																			<span>
																				{owners.firstName}{' '}
																				{owners.lastName}
																				<span className='groupOwner'> {translate('Owner')}
																				</span>
																			</span>
																		}
																		onChange={(checked) =>
																			handlePeopleSelection(
																				checked,
																				items,
																				owners,
																				true,
																				true
																			)
																		}
																		disabled={disabled}
																	/>
																</div>
															</div>
														))}

														{groupData?.students.map((student) => (
															<div key={student.id}>
																<div className='title'>
																	<Checkbox
																		value={getActiveSearch(
																			items.id,
																			student.id,
																			false,
																			true
																		)}
																		label={
																			<div>
																				{student.firstName}{' '}
																				{student.lastName}
																			</div>
																		}
																		onChange={(checked) =>
																			handlePeopleSelection(
																				checked,
																				items,
																				student,
																				false,
																				true
																			)
																		}
																		disabled={disabled}
																	/>
																</div>
															</div>
														))}
													</div>
												</Fragment>
											) : null}
										</div>
									</div>
								);
							})}
						</div>

						<Collapsible trigger={translate('Add group')}>
							<SearchGroups onSubmit={searchGroup} disabled={disabled} />
						</Collapsible>
					</Fragment>
				)}
			</div>

			{ScheduleFilter.selectedPeople > 0 || ScheduleFilter.ownersSelected > 0 ?
				<div className="clear-filter-container" onClick={() => dispatch(removeAll())}>
					<Icon name="Close" /> {translate('clear-filter')}
				</div> : null}

		</div>
	);
};

export default ListSelectorDropDown;