/* Import Icons */
import Plus from './IconsSrc/plus';
import Minus from './IconsSrc/minus';
import Trash from './IconsSrc/trash';
import Pen from './IconsSrc/pen';
import Document from './IconsSrc/document';
import MS_OneDrive from './IconsSrc/ms-onedrive';
import Close from './IconsSrc/close';
import Arrow_Down from './IconsSrc/arrow_down';
import Image from './IconsSrc/image';
import Chevron from './IconsSrc/chevron';
import Immersive_Reader from './IconsSrc/immersive_reader';
import AssignmentTaskStatusStarted from './IconsSrc/assignment_task_status_started';
import AssignmentTaskStatusNotStarted from './IconsSrc/assignment_task_status_not_started';
import AssignmentTaskStatusSubmitted from './IconsSrc/assignment_task_status_submitted';
import AssignmentTaskStatusNotComplete from './IconsSrc/assignment_task_status_not_complete';
import AssignmentTaskStatusCanDevelop from './IconsSrc/assignment_task_status_can_develop';
import AssignmentTaskStatusCompleted from './IconsSrc/assignment_task_status_completed';

export {
	Plus,
	Minus,
	Trash,
	Pen,
	Document,
	MS_OneDrive,
	Close,
	Arrow_Down,
	Image,
	Chevron,
	Immersive_Reader,
	AssignmentTaskStatusStarted,
	AssignmentTaskStatusNotStarted,
	AssignmentTaskStatusSubmitted,
	AssignmentTaskStatusNotComplete,
	AssignmentTaskStatusCanDevelop,
	AssignmentTaskStatusCompleted
};