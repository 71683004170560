import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as microsoftTeams from "@microsoft/teams-js";

import { addError } from 'actions';
import { getTypes } from 'actions/status';
import { getTranslations, registerLanguages } from 'actions/languages';
import { setLanguage } from 'actions/user';
import { onLanguageChange, setActiveLanguage } from '@haldor/ui';
import { Spinner } from 'UI';
import { appInsights } from 'lib/appInsights';
import { translate } from '@haldor/ui';
import { isMicrosoftTeams } from 'helpers/teams';

class Localization extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			showSession: false,
		};

		this.timer = null;
		this.retryInterval = null;
	}

	componentDidMount = () => {
		this.timer = setTimeout(() => {
			this.setState({ showSession: true });
		}, 1000 * 10);
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.translations == null && this.props.translations != null) {
			// Got translations
			if (this.timer != null) {
				clearTimeout(this.timer);
			}
		}
	}

	retryFetch = () => {
		if (this.props.translations == null || this.props.translations.length == 0) {
			const lcid = localStorage.getItem('language');
			this.props.getTranslations(lcid);
		} else {
			clearInterval(this.retryInterval);
		}
	}

	UNSAFE_componentWillMount = () => {
		this.props.registerLanguages();

		onLanguageChange((lcid) => {
			if (this.props.translations == null) {
				localStorage.setItem('language', lcid);
				this.props.getTranslations(lcid).then(() => {
					this.setState({ loading: false });

					if (this.props.translations == null || this.props.translations.length == 0) {
						this.props.addError(this.props.translate("You seem to have lost connection to the server. You can keep working as usual while we're trying to reconnect you."), 'info')
						this.retryInterval = setInterval(this.retryFetch, 1000 * 15)
					}
				})
			} else {
				// Only fetch new translations if lcid isnt the same as current language in localStorage
				if (localStorage.getItem('language') != lcid) {
					localStorage.setItem('language', lcid);
					this.props.getTranslations(lcid);

					this.props.setLanguage(lcid);
					this.props.getTypes(lcid);
				}

				this.setState({ loading: false });
			}
		});

		let localLanguage = localStorage.getItem('language');

		if (localLanguage != null) {
			localLanguage = localLanguage.toLowerCase();

			if (localLanguage == 'sv') {
				localLanguage = 'sv-se';
			} else if (localLanguage == 'en') {
				localLanguage = 'en-us';
			} else if (localLanguage == 'nb') {
				localLanguage = 'nb-no';
			} else if (localLanguage == 'nn') {
				localLanguage = 'nn-no';
			}
		}

		if (isMicrosoftTeams()) {
			microsoftTeams.app.getContext().then((context) => {
				if (context.app.locale == 'sv-se') {
					if (localLanguage != "sv-se") {
						localLanguage = 'sv-se';
					}
				} else if (context.app.locale == 'sv-fi') {
					if (localLanguage != "sv-fi") {
						localLanguage = 'sv-fi';
					}
				} else if (context.app.locale == 'da-dk') {
					if (localLanguage != "da-dk") {
						localLanguage = 'da-dk';
					}
				} else if (context.app.locale == 'nb-no') {
					if (localLanguage != "nb-no") {
						localLanguage = 'nb-no';
					}
				} else if (context.app.locale == 'nn-no') {
					// select bokmål for nynorsk users
					if (localLanguage != "nn-no") {
						localLanguage = 'nn-no';
					}
				} else if (context.app.locale == 'uk') {
					if (localLanguage != "uk") {
						localLanguage = 'uk';
					}
				} else {
					if (localLanguage != "en-us") {
						localLanguage = 'en-us';
					}
				}

				setActiveLanguage(localLanguage);
			});
		} else {
			if (localLanguage == null) {
				let validLanguages = ['sv-se', 'en-us', 'nn-no', 'nb-no', 'uk'];
				localLanguage = validLanguages.find(lang => {
					return lang.indexOf(navigator.language.toLowerCase() || (navigator.userLanguage != null ? navigator.userLanguage.toLowerCase() : "")) > -1;
				});

				localLanguage = localLanguage ?? 'en-us';
			}

			setActiveLanguage(localLanguage);
		}
	}

	render() {
		if (this.state.loading && window.location.pathname != '/lti') {
			return <div style={{ textAlign: 'center' }}>
				<Spinner center />

				{this.state.showSession ?
					<span style={{ color: 'var(--color--meta)' }}>
						Session ID: {localStorage.getItem('hsid')}
					</span>
					: null}
			</div>
		}

		return this.props.children;
	}

}

function mapStateToProps(state) {
	return {
		translations: state.Languages.translations,
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps, {
	getTranslations,
	setLanguage,
	registerLanguages,
	getTypes,
	addError,
})(Localization);