import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { clearVerdictScale } from 'actions/verdicts';

import { Spinner } from 'UI';

class LandingPage extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.clearVerdictScale();

		setTimeout(() => {
			this.props.history.goBack();
		}, 250)
	}

	render() {
		return (
			<div>
				<div className="app-comp-content content">
					<Spinner center />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
	};
}

export default withRouter(connect(mapStateToProps, {
	clearVerdictScale
})(LandingPage));
