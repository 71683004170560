import api from 'lib/api';

export const CHECK_PLAGIARISM = 'CHECK_PLAGIARISM';
export const VALIDATE_PLAGIARISM_LICENSE = 'VALIDATE_PLAGIARISM_LICENSE';

export const checkPlagiarism = (data, callback) => ({
	type: CHECK_PLAGIARISM,
	payload: new Promise((resolve) => {
		const endpoint = 'Plagiarism/multi';
		api.post(endpoint, data).then((response) => {
			resolve(response.data);

			if (callback != null) {
				callback(response.data);
			}
		});
	}),
});

export const validatePlagiarismLicense = (schoolId) => ({
	type: VALIDATE_PLAGIARISM_LICENSE,
	payload: new Promise((resolve) => {
		const endpoint = 'Plagiarism/' + schoolId + '/checkForValidPlagiarismLicense';

		api.get(endpoint).then((response) => {
			resolve(response.data);
		});
	}),
});