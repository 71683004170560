import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import MatrixAssessmentBlock from "./MatrixAssessmentBlock";

class MatrixAssessmentParagraph extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	onMatrixAssessmentUpdate = (block, detail) => {
		this.props.onMatrixAssessmentUpdate(block, detail);
	};

	render() {
		return (
			<div style={{ marginTop: "1rem" }} key={`r-paragraph-${this.props.paragraph.paragraphNr}`}>
				{this.props.paragraph.title != null && this.props.paragraph.title != "" ?
					<div className="single-section matrix-paragraph-title" style={{ paddingTop: "0px", marginBottom: "5px" }}>
						<strong>{this.props.paragraph.title}</strong>
					</div>
					: null}

				{this.props.paragraph.subtitle != null && this.props.paragraph.subtitle != "" ?
					<div
						className="single-section matrix-paragraph-subtitle"
						style={{
							paddingTop: "0px",
							marginBottom: "5px",
						}}
					>
						{this.props.paragraph.subtitle}
					</div>
					: null}

				{this.props.paragraph.rows.map((block) => {
					let associatedBlock = this.props.associatedParagraph.rows.find(
						(b) => b.id == block.id
					);

					if (associatedBlock != null) {
						let blockIndex = this.props.paragraph.rows.indexOf(block) + 1;

						if (!this.props.disableNotAchieved && block.cells.find((t) => t.id == "F") == null) {
							block.cells.unshift({
								id: "F",
								text: this.props.translate("not-achieved"),
							});
						}

						return (
							<MatrixAssessmentBlock
								key={block.id}
								sentenceNr={block.sentenceNr}
								assessments={this.props.assessments}
								items={this.props.items}
								block={block}
								type="multiple"
								details={this.props.details}
								groupAssignment={this.props.groupAssignment}
								referenceType={this.props.referenceType}
								studentAssessment={this.props.studentAssessment}
								onMatrixAssessmentUpdate={this.onMatrixAssessmentUpdate}
								index={this.props.paragraphIndex != null ?
									this.props.paragraphIndex + "." + blockIndex
									: null}
							/>
						);
					}
				})}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {})(MatrixAssessmentParagraph);
