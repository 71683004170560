import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

import { addError } from "actions";

import { getRootUrl } from 'helpers/url';

import CourseForm from 'containers/Forms/CourseForm';

import AssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';

import SinglePlanForm from 'containers/Forms/PlanningForm/SinglePlanForm';
import PreschoolPlanningForm from 'containers/Forms/PlanningForm/PreschoolPlanningForm';

import Modal from 'containers/Modals/Modal';
import { Button, TooltipMenu } from '@haldor/ui';

class CloneButton extends Component {

	constructor(props) {
		super(props);

		this.state = {
			prompt: false,
			multipleAssignment: false,
			enableOverlayWarning: false,
			overlayWarningPrompt: false,
		};
	}

	openMultipleAssignmentModal = () => {
		this.setState({ multipleAssignment: true });
	}

	openModal = () => {
		this.setState({ prompt: true });
	}

	closeModal = () => {
		if (this.state.enableOverlayWarning) {
			this.setState({ overlayWarningPrompt: true });
		} else {
			this.setState({ prompt: false });
		}

		if (this.props.onClose != null && typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	}

	onClick = () => {
		this.openModal();
	}

	enableOverlayWarningClose = (enable) => {
		this.setState({ enableOverlayWarning: enable });
	}

	overlayWarningPromptAgree = () => {
		this.setState({ prompt: false, overlayWarningPrompt: false, enableOverlayWarning: false });
	}

	overlayWarningPromptClose = () => {
		this.setState({ overlayWarningPrompt: false });
	}

	closeBulkAssignments = (reload) => {
		if (!reload) {
			this.setState({ multipleAssignment: false });

			if (this.props.onClose != null && typeof this.props.onClose === 'function') {
				this.props.onClose();
			}

			return true;
		}

		if (this.props.bulkAssignments != null) {
			if (this.props.bulkAssignments.length > 1) {
				this.props.addError('Kopior sparade', 'info');
			} else {
				let assignment = this.props.bulkAssignments[0];
				if (assignment != null && assignment.id != null) {
					window.location = `${getRootUrl()}assignment/${assignment.id}`;
				} else {
					this.setState({ multipleAssignment: false });

					if (this.props.onClose != null && typeof this.props.onClose === 'function') {
						this.props.onClose();
					}
				}

				return true;
			}
		}

		this.setState({ multipleAssignment: false });
		if (this.props.onClose != null && typeof this.props.onClose === 'function') {
			this.props.onClose();
		}
	}

	render = () => {
		const { translate } = this.props;

		return (
			<div>
				{this.props.type == 'assignment' ?
					<Modal isOpen={this.state.prompt} onClose={this.closeModal} title={translate('copy-assignment')}>
						<AssignmentForm
							onAbort={this.closeModal}
							enableOverlayWarningClose={this.enableOverlayWarningClose}
							overlayWarningPromptAgree={this.overlayWarningPromptAgree}
							overlayWarningPromptClose={this.overlayWarningPromptClose}
							overlayWarningPrompt={this.state.overlayWarningPrompt}
							enableOverlayWarning={this.state.enableOverlayWarning}
							task={this.props.object}
							editView={false}
							cloneView={true}
							groupid={this.props.object.sectionId}
							clearOnUnmount={this.props.clearOnUnmount}
						/>
					</Modal>
					: null}

				{this.props.type == 'assignment' ?
					<Modal isOpen={this.state.multipleAssignment} onClose={this.closeBulkAssignments} title={translate('copy-assignment')}>
						<MultipleAssignmentForm
							onAbort={this.closeBulkAssignments}
							enableOverlayWarningClose={this.enableOverlayWarningClose}
							overlayWarningPromptAgree={this.overlayWarningPromptAgree}
							overlayWarningPromptClose={this.overlayWarningPromptClose}
							overlayWarningPrompt={this.state.overlayWarningPrompt}
							enableOverlayWarning={this.state.enableOverlayWarning}
							initialValues={this.props.object}
							editView={false}
							cloneView={true}
							groupid={this.props.object.sectionId}
							clearOnUnmount={this.props.clearOnUnmount}
						/>
					</Modal>
					: null}

				{this.props.type == 'planning' ?
					<Modal isOpen={this.state.prompt} onClose={this.closeModal} title={this.props.translate('plan-create-button')}>
						<SinglePlanForm
							onAbort={this.closeModal}
							plan={this.props.object}
							enableOverlayWarningClose={this.enableOverlayWarningClose}
							overlayWarningPromptAgree={this.overlayWarningPromptAgree}
							overlayWarningPromptClose={this.overlayWarningPromptClose}
							overlayWarningPrompt={this.state.overlayWarningPrompt}
							enableOverlayWarning={this.state.enableOverlayWarning}
							editView={false}
							cloneView={true}
							sectionId={this.props.object.sectionId}
						/>
					</Modal>
					: null}

				{this.props.type == 'preschoolplanning' ?
					<Modal isOpen={this.state.prompt} onClose={this.closeModal} title={this.props.translate('plan-create-button')}>
						<PreschoolPlanningForm
							onAbort={this.closeModal}
							plan={this.props.object}
							enableOverlayWarningClose={this.enableOverlayWarningClose}
							overlayWarningPromptAgree={this.overlayWarningPromptAgree}
							overlayWarningPromptClose={this.overlayWarningPromptClose}
							overlayWarningPrompt={this.state.overlayWarningPrompt}
							enableOverlayWarning={this.state.enableOverlayWarning}
							editView={false}
							cloneView={true}
							sectionId={this.props.object.sectionId}
						/>
					</Modal>
					: null}

				{this.props.type == 'course' ?
					<Modal isOpen={this.state.prompt} onClose={this.closeModal} title={this.props.translate('edit-course')}>
						<CourseForm
							onClose={this.closeModal}
							editView={true}
							course={this.props.object}
						/>
					</Modal>
					: null}

				{this.props.type == 'assignment' ?
					<TooltipMenu trigger={(
						<Button type="secondary">
							{this.props.translate('column-copy')}
						</Button>
					)}>
						<TooltipMenu.Item onClick={this.openMultipleAssignmentModal}>
							{this.props.translate('copy-assignment')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={this.onClick}>
							{this.props.translate('copy-group-assignment')}
						</TooltipMenu.Item>
					</TooltipMenu>
					:
					<Button type="secondary" onClick={this.onClick} disabled={false}>
						{this.props.children}
					</Button>
				}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		bulkAssignments: state.assignments.newAssignments,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	addError,
})(CloneButton);