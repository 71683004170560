import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Close } from "UI/Icons";
import { FlexibleTextArea } from "UI/Inputs";

class MatrixGradeStep extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: props.blockStep.text
		};
	}
	onStepChange(text) {
		this.setState({ text }, () => {
			this.props.onStepUpdate({
				id: this.props.blockStep.id,
				keyId: this.props.blockStep.keyId,
				text: this.state.text
			});
		});
	}
	onStepDelete() {
		this.props.onStepDelete({ id: this.props.blockStep.id });
	}
	render() {
		if (this.props.blockStep.gradeF == null) {
			return (
				<div
					className="form-row"
					key={this.props.key}
					style={{
						flex: "1",
						padding: "0",
						marginRight: "1em",
						marginTop: "1em",
						position: "relative"
					}}
				>
					<FlexibleTextArea
						value={this.state.text != null ? this.state.text : ""}
						placeholder={this.props.translate("text")}
						onChange={event =>
							this.onStepChange(event.target.value)
						}
					></FlexibleTextArea>

					<div
						onClick={event => this.onStepDelete()}
						className="tooltip"
						data-tooltip={this.props.translate("delete-step")}
						style={{
							position: "absolute",
							top: "17px",
							right: "15px",
							cursor: "pointer",
							height: "1em"
						}}
					>
						<div style={{ width: "15px" }}>
							<Close />
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div
					style={{
						flex: "1",
						flexGrow: "0",
						padding: "0",
						position: "relative",
						marginTop: "1em",
						marginRight: "2em"
					}}
					className="form-row"
				>
					<FlexibleTextArea
						value={this.state.text != null ? this.state.text : ""}
						style={{
							width: "130px",
							height: "50px",
							fontSize: "1em",
							overflow: "hidden"
						}}
						disabled={true}
					></FlexibleTextArea>
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations)
	};
}

export default connect(mapStateToProps, {})(MatrixGradeStep);
