import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from '@haldor/ui';

import {
	createConversation,
	getConversation,
	getConversationsByReference,
	submitMessage,
} from 'actions/conversation';

import ConversationMessages from 'containers/Conversation/Display/ConversationMessages';
import MessageForm from 'containers/Conversation/Form/MessageForm';
import Modal from 'containers/Modals/Modal';

import { Spinner } from 'UI';

class Message extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			open: '',
		};
	}

	/* Lifecycle methods */
	componentDidMount = () => {
		// Get conversations
		if (this.props.assignment != null) {
			const { assignment } = this.props;
			this.props.getConversationsByReference(assignment.id, 'ASSIGNMENT').then(() => {
				this.setState({ loading: false });
			});
		}

		if (this.props.plan != null) {
			const { plan } = this.props;
			this.props.getConversationsByReference(plan.id, 'PLAN').then(() => {
				this.setState({ loading: false });
			});
		}

		if (this.props.meetingInstance != null) {
			const { meetingInstance } = this.props;
			this.props.getConversationsByReference(meetingInstance.id, 'MEETING_INSTANCE').then(() => {
				this.setState({ loading: false });
			});
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.activeUser == null) {
			return false;
		}

		if (nextProps.activeUser != this.props.activeUser) {
			let user = nextProps.activeUser;

			if (user.id == null) {
				user.id = user.userId;
			}

			let conversation = this.getConversationForUser(user);

			if (conversation != null && conversation.id != 0) {
				this.setState({ loading: true });
				this.props.getConversation(conversation.id).then(() => {
					this.setState({ loading: false });
				});
			}
		}
	}

	/* Helper methods */
	getCreatorId = () => {
		let creator = null;

		if (this.props.assignment != null) {
			if (this.props.assignment.id == parseInt(this.props.assignment.id, 10)) {
				creator = this.props.assignment.creator;
			} else {
				creator = this.props.assignment.createdBy.user.id;
			}
		}

		if (this.props.plan != null) {
			creator = this.props.plan.creator;
		}

		if (this.props.meetingInstance != null) {
			creator = this.props.meetingInstance.creator;
		}

		return creator;
	}

	buildReference = () => {
		let references = [];
		let reference = {};

		if (this.props.assignment != null) {
			reference.referenceId = this.props.assignment.id;
			reference.referenceType = 'ASSIGNMENT';
		}

		if (this.props.plan != null) {
			reference.referenceId = this.props.plan.id;
			reference.referenceType = 'PLAN';
		}

		if (this.props.meetingInstance != null) {
			reference.referenceId = this.props.meetingInstance.id;
			reference.referenceType = 'MEETING_INSTANCE';
		}

		if (this.props.studentId != null && this.props.activeUser.userType == "GUARDIAN") {
			let studentReference = {};
			studentReference.referenceId = this.props.studentId;
			studentReference.referenceType = 'STUDENT';
			references.push(studentReference);
		}

		references.push(reference);
		return references;
	}

	buildMemberships = (user) => {
		let memberships = [];

		memberships.push({
			membershipID: user.id,
			membershipType: user.userType == "GUARDIAN" ? 'GUARDIAN' : 'USER',
			permission: 'W',
		});

		memberships.push({
			membershipID: this.props.user.id,
			membershipType: 'USER',
			permission: 'O',
			user: this.props.user,
		});

		return memberships;
	}

	getConversationForUser = (user) => {
		const { conversations } = this.props;

		if (conversations != null) {
			let found = conversations.find(conversation => {
				let userType = 'USER'
				if (user.userType == "GUARDIAN") {
					userType = 'GUARDIAN';
				}
				let membership = conversation.memberships.find(member => {
					return member.membershipType == userType && member.membershipID == user.id;
				});

				return membership != null;
			});

			return found;
		}

		return null;
	}

	getMembership = (conversation, userid) => {
		let membership = conversation.memberships.find(member => {
			return member.membershipType == 'USER' && member.membershipID == userid;
		});

		if (membership != null) {
			return membership;
		}

		return null;
	}

	/* Events */
	onCreate = (values, student) => {
		return new Promise(resolve => {
			values.creator = this.props.user.id;
			values.senderId = values.creator;
			values.senderType = 'USER';
			delete values.conversationID;

			let conversation = {
				messages: [values],
				memberships: this.buildMemberships(student),
				relationships: this.buildReference(),
				title: '',
				status: 'ACTIVE',
			};

			this.props.createConversation(conversation)
				.then(() => {
					this.props.getConversation(this.props.new);
					this.componentDidMount();
					resolve(1);
				});
		})
	}

	submitMessage = (values) => {
		return new Promise(resolve => {
			const { user } = this.props;

			values.creator = user.id;
			values.senderId = values.creator;
			values.senderType = 'USER';

			this.props.submitMessage(values).then(() => {
				this.setState({});
				resolve(1);
			});
		});
	}

	onModalClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	}

	/* Render methods */
	renderConversation = () => {
		let conversation = null;
		let created = true;
		let user = this.props.activeUser;
		let trigger = this.props.activeUser.firstName + ' ' + this.props.activeUser.lastName;
		let disabled = false;

		if (user.id == null) {
			user.id = user.userId;
		}

		if (user != null) {
			conversation = this.getConversationForUser(user);
			if (conversation != null && conversation.status.toUpperCase() == 'ARCHIVED') {
				disabled = true;
			}
		}

		if (conversation != null && conversation.status.toUpperCase() == 'ARCHIVED') {
			disabled = true;
		}

		if (conversation == null) {
			trigger = this.props.translate('send-a-message');
			created = false;
			conversation = { id: 0 };
		}

		return <div className="conversation conversation--wrapper" style={{ boxShadow: 'none', borderRadius: 0, height: '70vh' }}>
			{created ?
				<ConversationMessages
					conversation={this.props.active}
				/>
				:
				<div className="conversation-begin" />
			}

			{created ?
				<MessageForm
					conversation={conversation}
					onSubmit={this.submitMessage}
					disabled={disabled}
				/>
				:
				<MessageForm
					conversation={null}
					onSubmit={values => this.onCreate(values, user)}
				/>
			}
		</div>
	}

	renderContent = () => {
		if (this.state.loading) {
			return <Spinner center />
		}

		return this.renderConversation();
	}

	render() {
		if (this.props.activeUser == null) {
			return null;
		}

		let title = null;
		if (this.props.plan != null) {
			title = this.props.activeUser.firstname + ' ' + this.props.activeUser.lastname;
		} else {
			title = this.props.activeUser.firstName + ' ' + this.props.activeUser.lastName;
		}

		return (
			<Modal type="small" overridePrompt onClose={this.onModalClose} isOpen={true} title={title}>
				<div className="single-assignment-message">
					{this.renderContent()}
				</div>
			</Modal>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		conversations: state.Conversation.referenceConversations,
		active: state.Conversation.conversation,
		new: state.Conversation.new,
	};
}

export default connect(mapStateToProps, {
	getConversationsByReference,
	createConversation,
	submitMessage,
	getConversation,
})(Message);
