import { GET_TYPES } from '../actions/status';

const INITIAL_STATE = {
  status: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TYPES:
		action.payload?.assignmentTaskStatus?.sort((a,b) => a.index - b.index);
      	return { ...state, status: action.payload };
    default:
      return state;
  }
}
