import api from 'lib/api';
import { loginRequest, getToken, http } from 'lib/msal';

export const getFile = (id, callback) => ({
	type: 'get_file',
	payload: new Promise((resolve) => {
		api.get(`fileItems/${id}`).then((response) => {
			callback(response.data);
			resolve(response.data);
		});
	}),
});

export const getFileUrl = (id, callback) => ({
	type: 'get_file_url',
	payload: new Promise((resolve) => {
		// TODO: Test this
		let url = http.defaults.baseURL + `fileItems/${id}`;

		getToken(loginRequest).then((response) => {
			url += `?token=${response.accessToken}`;

			resolve(url);
			callback(url);
		});
	}),
});

export const getPreschoolDocumentImage = (id, callback) => ({
	type: 'get_preschool_document_image',
	payload: new Promise((resolve) => {
		api.get(`fileItems/${id}`).then((response) => {
			callback(response.data);
			resolve({ id: id, src: response.data });
		});
	}),
});

export const deleteFile = (file) => ({
	type: 'delete_file_item',
	payload: new Promise((resolve) => {
		api.delete(`fileitems/${file.id}`).then((response) => {
			resolve(response.data);
		});
	})
});
