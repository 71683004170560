import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const defaultoptions = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export default function DropdownButtons({ options }) {
	let buttonOptions = options.map(item => item.title) || defaultoptions;
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const arrowRef = React.useRef(null);
	const [selectedIndex, setSelectedIndex] = React.useState(-1);

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);

		if (index < options.length && options[index] && options[index].onClick) {
			options[index].onClick();
		} else {
			const validFormat = JSON.stringify([{ title: 'test', onClick: this.handleClickEvent1 }, { title: 'test2', onClick: this.handleClickEvent2 }]);
			throw new Error(`Invalid options format. Please provide options in the format: ${validFormat}`);
		}
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	return (
		<React.Fragment>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="Button group with a nested menu"
			>
				<Button
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					variant="outlined"
					onClick={handleToggle}
					sx={{ color: 'black', border: '0.2px solid black' }}
				>
					<MoreHorizIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 9999,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				modifiers={[
					{
						name: 'arrow',
						enabled: true,
						options: {
							element: arrowRef.current,
						},
					},
				]}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<span className="arrow" ref={arrowRef} style={{ position: 'absolute', fontSize: 7, width: '3em', height: '3em' }} />
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{buttonOptions.map((option, index) => (
										<MenuItem
											key={option}
											disabled={index === 2}
											selected={index === selectedIndex}
											onClick={(event) => handleMenuItemClick(event, index)}
										>
											{option}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
}