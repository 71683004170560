import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { Close } from 'UI/Icons';


const customStyles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.35)',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: '2147483',
	},

	content: {
		backgroundColor: 'transparent',
		border: '0',
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		padding: '0',
		minHeight: '100%',
		overflow: 'auto',
		zIndex: '2147483',
		WebkitOverflowScrolling: 'touch',
	},
};

Modal.setAppElement('#root')

class ModalObject extends Component {
	onModalClick = (e) => {
		e.stopPropagation();
	};

	shouldClose = () => {
		if (this.props.isOpen && !this.props.overridePrompt) {
			swal.fire({
				title: this.props.translate('are-you-sure'),
				text: this.props.translate('close-form-prompt'),
				showCancelButton: true,
				cancelButtonText: this.props.translate('No'),
				confirmButtonText: this.props.translate('Yes'),
			}).then(result => {
				if (result.value != null) {
					if (this.props.onClose != null && typeof this.props.onClose == 'function') {
						this.props.onClose();
					}
				}
			});
		} else {
			if (this.props.onClose != null && typeof this.props.onClose == 'function') {
				this.props.onClose();
			}
		}
	}

	render() {
		return (
			<Modal
				parentSelector={() => document.querySelector('#modals')}
				isOpen={this.props.isOpen}
				onRequestClose={this.shouldClose}
				contentLabel=""
				style={{ ...customStyles, ...this.props.style }}
			>
				<div className={this.props.type ? `modal-overlay ${this.props.type}` : 'modal-overlay'} onClick={this.shouldClose}>
					<div className={this.props.type ? `modal-container ${this.props.type}` : 'modal-container'} onClick={this.onModalClick}>
						<div className="modal-header">
							<div className="title">
								<h2>{this.props.title}</h2>
							</div>

							{this.props.onClose != null ?
								<div className="close" onClick={this.props.onClose}>
									<Close />
								</div>
								: null}

							<div style={{ clear: 'both' }} />
						</div>

						<div className="modal-content">{this.props.children}</div>
					</div>
				</div>
			</Modal>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(ModalObject);
