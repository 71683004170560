import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Moment from "moment";

import { getRootUrl } from "helpers/url";
import { isUserStudent } from "helpers/user";

import { getBlocksByReference } from 'actions/blocks';
import { getAssignmentAssessment } from "actions/assessments";
import { getTaskDetails, getAssignmentSection } from "actions/assignments";

import UserAssignment from "components/List/UserAssignment";
import PostContent from "components/Presentation/PostContent";
import Status from "components/Presentation/Status";
import UserGroupAssignment from "components/List/UserGroupAssignment";
import AssessmentsConsumer from 'components/BlockAssessments/Consumer';

import { Icon, Button, getActiveLanguage, translate } from '@haldor/ui';
import { List, Spinner } from "UI";

class PrintAssignment extends Component {
	constructor(props) {
		super(props);

		this.state = {};

		Moment.locale(getActiveLanguage());
	}

	componentDidMount() {
		const { params } = this.props.match;

		if (params.assignmentId > 0) {
			this.props.getTaskDetails(params.assignmentId).then(() => {
				if (this.props.section == null) {
					this.props.getAssignmentSection(this.props.assignment.sectionId);
				}
			});

			this.props.getBlocksByReference(params.assignmentId, 'ASSIGNMENT');
			this.props.getAssignmentAssessment(params.assignmentId);
		}
	}

	goBack = (event) => {
		event.preventDefault();
		window.history.go(-1);

		setTimeout(function () {
			if (this != null && this.props != null) {
				const { match } = this.props;
				window.location.href = `${getRootUrl()}assignment/${match.params.assignmentId}`;
			}
		}, 200);

		return false;
	};

	backButton = () => {
		const { match } = this.props;

		if (match.params.assignmentId) {
			return (
				<Link
					onClick={this.goBack}
					style={{
						cursor: "pointer",
						color: "black",
						textDecoration: "none",
					}}
					className="teams-icon-go-back"
					to="#"
				>
					<span className="chevron" style={{ paddingLeft: "0.5em" }}>
						{this.props.translate("back")}
					</span>
				</Link>
			);
		} else {
			return null;
		}
	};

	pupilStatusIndicator = (status) => {
		if (status === "ASSIGNMENT_TASK_NOT_STARTED") {
			return (
				<span className="single-dot dot-gray">
					{this.props.translate("not-started")}
				</span>
			);
		} else if (status === "ASSIGNMENT_TASK_STARTED") {
			return (
				<span className="single-dot dot-gray">
					{this.props.translate("started")}
				</span>
			);
		} else if (
			status === "ASSIGNMENT_TASK_SUBMITTED" ||
			status === "ASSESSMENT_COMPLETED" ||
			status === "ASSESSMENT_STARTED"
		) {
			return (
				<span className="single-dot dot-yellow">
					{this.props.translate("submitted")}
				</span>
			);
		} else if (status === "ASSIGNMENT_TASK_NOT_COMPLETE") {
			return (
				<span className="single-dot dot-red">
					{this.props.translate("not-complete")}
				</span>
			);
		} else if (status === "ASSIGNMENT_TASK_CAN_DEVELOP") {
			return (
				<span className="single-dot dot-orange">
					{this.props.translate("can-develop")}
				</span>
			);
		} else if (status === "ASSESSMENT_PUBLISHED") {
			return (
				<span className="single-dot dot-green">
					{this.props.translate("assessed")}
				</span>
			);
		}
	};

	assignmentIndicator = (status) => {
		if (status === "ASSIGNMENT_OPEN") {
			return (
				<span className="single-dot dot-green">
					{this.props.translate("Open")}
				</span>
			);
		} else if (status === "ASSIGNMENT_CLOSED") {
			return (
				<span className="single-dot dot-red">
					{this.props.translate("Hidden")}
				</span>
			);
		} else if (status === "ASSIGNMENT_DRAFT") {
			return (
				<span className="single-dot dot-gray">
					{this.props.translate("Draft")}
				</span>
			);
		} else if (status === "ASSIGNMENT_DELETED") {
			return (
				<span className="single-dot dot-red">
					{this.props.translate("Deleted")}
				</span>
			);
		}
	};

	renderMembersForStudent() {
		if (this.props.activeTask != null && this.props.activeTask.tasks[0] != null) {
			return this.props.activeTask.tasks[0].assignedUsers.map((student) => (
				<div>{`${student.firstName} ${student.lastName}`}</div>
			));
		}
	}

	printDialog = () => {
		window.print();
	};

	taskContent = () => {
		Moment.locale(getActiveLanguage());
		const isStudent = isUserStudent(this.props.currentUser);
		const teacherOwnsAssignment = this.props.sections.find((t) =>
			t.id == this.props.assignment.sectionId && t.userRole == 'OWNER'
		) == null ? false : true;
		let assessmentBlocks = [];
		let assessment = null;

		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);

			if (isStudent && this.props.assessments.length > 0) {
				assessment = this.props.assessments[0];
			}
		}

		return (
			<div className="single-task" key={this.props.assignment.id}>
				<div className="single-section no-print">
					<Button type="secondary" onClick={this.printDialog}>
						<Icon name="Printer" />
						{this.props.translate("print")}
					</Button>
				</div>

				<div className="single-section df jcb fw">
					<div style={{ marginRight: '.65rem' }}>
						<h4 style={{ marginTop: 0 }}>{this.props.translate("Status")}</h4>

						{this.props.assignment.created == "0001-01-01T00:00:00" ?
							<div className="badge-primary">
								{this.props.translate("task-is-creating")}
							</div>
							:
							<div>
								{isStudent ?
									this.pupilStatusIndicator(this.props.assignment.tasks[0].status)
									: this.assignmentIndicator(this.props.assignment.status)
								}
							</div>
						}
					</div>

					{isStudent ?
						<div>
							<h4 style={{ marginTop: 0 }}>{this.props.translate("members")}</h4>

							{this.renderMembersForStudent()}
						</div>
						: null}

					<div style={{ marginRight: '.65rem' }}>
						<h4 style={{ marginTop: 0 }}>{this.props.translate("information")}</h4>

						<div style={{ display: "inline-block" }}>
							<Status
								type="assignmentTypes"
								id={this.props.assignment.assignmentType}
							/>
						</div>
					</div>

					<div>
						<h4 style={{ marginTop: 0 }}>{this.props.translate("start")}</h4>

						<div>
							{Moment.utc(this.props.assignment.startDate).local().format("D MMM H:mm")}
						</div>
					</div>

					<div>
						<h4 style={{ marginTop: 0 }}>{this.props.translate("end")}</h4>

						<div>
							{Moment.utc(this.props.assignment.dueDate).local().format("D MMM H:mm")}
						</div>
					</div>
				</div>

				<div className="single-section form">
					<div className="single-assignment-meta">
						<span>{this.props.section.title}</span>
					</div>

					<div>
						<h1 style={{ display: "inline-block", fontWeight: "600", fontSize: "2.2em" }}>
							<span style={{ color: "#3A3A3A" }}>
								{this.props.assignment.title}
							</span>
						</h1>
					</div>
				</div>

				<div className="single-section form">
					<PostContent>
						{this.props.assignment.description}
					</PostContent>
				</div>

				<div style={{ clear: "both" }} />
				<div
					className="single-section-divider"
					style={{ marginBottom: "3rem" }}
				/>

				{isStudent == false && teacherOwnsAssignment ?
					this.props.assignment.groupEnabled ?
						<List title={this.props.translate("students")}>
							<UserGroupAssignment
								assignment={this.props.assignment}
								reload={null}
								printable={true}
							/>
						</List>
						:
						<List title={this.props.translate("students")}>
							<UserAssignment
								assignment={this.props.assignment}
								reload={null}
								printable={true}
							/>
						</List>
					: null}

				<div className="single-section print-core-contents">
					<AssessmentsConsumer
						fields={assessmentBlocks}
						assessment={assessment}
						isStudent={isStudent}
					/>
				</div>
			</div>
		);
	};

	render() {
		if (this.props.assignment != null && this.props.section != null) {
			return (
				<div>
					<div className="back-button single-section no-print">
						{this.backButton()}
					</div>

					<div className="container">
						{this.taskContent()}
					</div>
				</div>
			);
		} else {
			return (
				<div className="container">
					<Spinner center />
				</div>
			);
		}
	}
}

function mapStateToProps(state) {
	return {
		sections: state.sections.educationGroups,
		assignment: state.assignments.active_assignment,
		assignmentTypes: state.status.assignmentTypes,
		blocks: state.Blocks.reference,
		currentUser: state.user.currentUser,
		assessments: state.assessments.assessments,
		section: state.assignments.section,
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
	};
}

export default connect(mapStateToProps, {
	getTaskDetails,
	getAssignmentAssessment,
	getAssignmentSection,
	getBlocksByReference,
})(PrintAssignment);
