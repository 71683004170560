import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ColorScale from '_class/ColorScale';
import moment from 'moment';

import User from '_class/User';

import { getVerdictScale } from 'actions/verdicts';
import { getGroupAbsenceForToday, getSectionAssessmentStatistics } from 'actions/sections';
import { getVerdictsForStudentsInSection } from 'actions/verdicts';
import DateTime from '_class/DateTime';
import { getSectionSubjects } from 'actions/subjects';

import VerdictWidget from 'containers/Verdict/Display/VerdictWidget';
import { Collapsible } from 'UI';
import { Checkbox, Expandable, translate } from '@haldor/ui';
import { Bar, Progress } from 'UI/Graphs';
import { Skeleton, Spinner } from 'UI';
import { Table, Block, Flex, Tabs, Tab, Icon, MultiSelect } from '@haldor/ui';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import './UserMentorSection.scss';
import DisplayName from 'components/Presentation/DisplayName';
import InfoButton from 'components/Presentation/InfoButton';

class UserMentorSection extends Component {

	constructor(props) {
		super(props);

		this.state = {
			subjects: [],
			filters: ['ASSESSMENTGOAL', 'MATRIX', 'ASSESSED-ASSIGNED'],
			loadingVerdicts: false,
			loadingAssessments: false,
			smallLoader: false,
			width: 0,
			openStudents: [],
			showMenu: true,
			absences: null,
		}

		this.container = null;
		this.barColumn = null;
	}

	_setContainer = (element) => this.container = element;
	_setBarColumn = (element) => this.barColumn = element;

	componentDidMount = () => {
		const { section, start, end, mentorGroups } = this.props;

		if (section != null) {
			let students = [];
			section.students.forEach(student => {
				students.push(student.userId);
			});

			this.setState({ loadingVerdicts: true });
			this.props.getVerdictsForStudentsInSection(students, section.id, start, end).then(() => {
				this.setState({ loadingVerdicts: false });
			});
		}

		this.fetchAbsences();

		if (this.props.scale == null) {
			this.props.getVerdictScale();
		}

		this.setState({ loadingAssessments: true });
		this.props.getSectionSubjects(section.id).then(() => {
			this.setState({ loadingAssessments: false, subjects: this.props.subjects });
		});

		window.addEventListener('hashchange', this.onHashChange);
		window.addEventListener('resize', this.resize);
		this.setState({ openStudents: this.props.section.students.map(student => student.userId) }, () => {
			this.resize();
		});
	}

	fetchAbsences = () => {
		const { mentorGroups, section } = this.props;

		if (mentorGroups != null) {
			const listSection = mentorGroups.find((g) => g.id == section.id);
			if (listSection != null) {
				if (listSection.absences != null) {
					// Use absences from list instead
					this.setState({ absences: listSection.absences });
					return true;
				}
			}
		}

		if (section.absences == null) {
			this.props.getGroupAbsenceForToday(section.id, encodeURIComponent(moment().format()), encodeURIComponent(moment().add(1, 'd').format()))
				.then(() => {
					this.setState({ absences: section.absences });
				});
		}
	}

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('hashchange', this.onHashChange);
	}

	onHashChange = () => {
		if (window.location.hash == '#assessments') {
			this.setState({ showMenu: true })
		} else {
			this.setState({ showMenu: false })
		}
	}

	resize = () => {
		if (this.barColumn != null && this.container != null) {
			this.setState({ width: this.container.scrollWidth - this.barColumn.scrollWidth })
		}
	}

	onSubjectsChange = (subjects) => {
		this.setState({ subjects });
		const { section, start, end } = this.props;

		this.setState({ smallLoader: true });
		this.props.getSectionAssessmentStatistics(section.id, start, end, subjects).then(() => {
			this.resize();
			this.setState({ smallLoader: false });
		});
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.start == null || nextProps.end == null) {
			return false;
		}

		const { section } = this.props;

		if (nextProps.start != this.props.start || nextProps.end != this.props.end) {
			const { start, end } = nextProps;
			let students = [];
			section.students.forEach(student => {
				students.push(student.userId);
			});

			this.setState({ loadingVerdicts: true });
			this.props.getVerdictsForStudentsInSection(students, section.id, start, end).then(() => {
				this.setState({ loadingVerdicts: false });
			});

			this.setState({ loadingAssessments: true });

			this.props.getSectionAssessmentStatistics(section.id, start, end, this.props.subjects.map((subject) => subject.id)).then(() => {
				this.resize();
				this.setState({ loadingAssessments: false, subjects: this.props.subjects });
			});
		}
	}

	calculateAttendance = (attendance, student) => {
		if (attendance != null) {
			const { translate } = this.props;
			let foundAttendance = null;
			if (attendance != null) {
				foundAttendance = this.props.attendance.find((user_attendance) => {
					if (user_attendance != null) {
						return user_attendance.userId == student.id;
					}

					return false;
				});
			}

			if (foundAttendance != null) {
				const { invalidAbsence, invalidLate, present, validAbsence, validLate } = foundAttendance;

				const total = invalidAbsence + invalidLate + present + validAbsence + validLate;

				let attendance = {
					'percentage': {
						'attendance': 0,
						'validAbsence': 0,
						'invalidAbsence': 0,
					},
					'minutes': {
						'present': present,
						'validAbsence': validAbsence + validLate,
						'invalidAbsence': invalidAbsence + invalidLate,
					},
					'display': {
						'present': '0 ' + translate('minutes').toLowerCase(),
						'validAbsence': '0 ' + translate('minutes').toLowerCase(),
						'invalidAbsence': '0 ' + translate('minutes').toLowerCase(),
					},
				};

				if (total > 0) {
					attendance.percentage = {
						attendance: Math.round(((present / total) * 100) * 10) / 10,
						validAbsence: Math.round((((validAbsence + validLate) / total) * 100) * 10) / 10,
						invalidAbsence: Math.round((((invalidAbsence + invalidLate) / total) * 100) * 10) / 10,
					};

					if (attendance.minutes.present > 600) {
						attendance.display.present = Math.floor(attendance.minutes.present / 60) + ' ' + translate('hours').toLowerCase();
					} else {
						attendance.display.present = attendance.minutes.present + ' ' + translate('minutes');
					}

					if (attendance.minutes.validAbsence > 600) {
						attendance.display.validAbsence = Math.floor(attendance.minutes.validAbsence / 60) + ' ' + translate('hours').toLowerCase();
					} else {
						attendance.display.validAbsence = attendance.minutes.validAbsence + ' ' + translate('minutes').toLowerCase();
					}

					if (attendance.minutes.invalidAbsence > 600) {
						attendance.display.invalidAbsence = Math.floor(attendance.minutes.invalidAbsence / 60) + ' ' + translate('hours').toLowerCase();
					} else {
						attendance.display.invalidAbsence = attendance.minutes.invalidAbsence + ' ' + translate('minutes').toLowerCase();
					}
				}

				return attendance;
			}
		}

		return null;
	}

	getPercentageFromStatistics = (items) => {
		if (items == null) {
			return 0;
		}

		let percentages = [];

		items.forEach((item) => {
			let item_total = 0;
			let indexed = [];

			item.statistics.forEach((stat) => {
				item_total += stat.count;
				indexed.push({ count: stat.count, index: stat.index, weight: stat.weight });
			});

			for (let i = 0; i <= item_total; i++) {
				indexed.map((entry) => {
					if (entry.count > 0) {
						percentages.push(entry.weight);
						entry.count = entry.count - 1;
					}

					return entry;
				});
			}
		});

		return Math.round(percentages.reduce((a, b) => a + b, 0) / percentages.length);
	}

	renderAssessedAndAssignedInfo = (item) => {
		if (item == null) return;

		if (!this.state.filters.includes('ASSESSED-ASSIGNED')) {
			return null;
		}

		var title = `${item.totalAssessedAssignments + item.totalAssessedPlannings} ${this.props.translate('of')} ${item.totalAssignedAssignments + item.totalAssignedPlannings} ${this.props.translate('plans and assignments in all subjects')}`


		return (
			<div className="bar-outer-container">
				<div>
					{this.props.translate("Assessed-Assigned")}
				</div>

				<div style={{ paddingLeft: '.55rem' }}>
					<Progress labelPostion='start' currentValue={item.totalAssessedAssignments + item.totalAssessedPlannings} maxValue={item.totalAssignedAssignments + item.totalAssignedPlannings} label={title} />
				</div>
			</div>
		);
	}

	renderAssessmentBar = (item, index) => {
		if (!this.state.filters.includes(item.type)) {
			return null;
		}

		if (item.statistics != null) {
			var data = [];
			const isEmpty = item.statistics.find((stat) => stat.count > 0) == null;

			if (isEmpty) {
				return null;
			}

			const colors = new ColorScale(item.statistics.length).get();
			item.statistics.map((stat) => {
				data.push({
					amount: stat.count,
					label: stat.count,
					color: colors[stat.index],
				});
			});

			let line = null;
			if (data.length > 0) {
				line = (
					<Bar data={data} showAmount showAllSteps />
				);
			}

			return (
				<div key={index} className="bar-outer-container">
					<div>
						{this.props.translate(item.type)}
					</div>
					{line}
				</div>
			);
		}

		return <div />;
	}

	renderGoalBar = (percentage = 0) => {
		if (!this.state.filters.includes('ASSESSMENTGOAL')) {
			return null;
		}

		if (percentage == 0) {
			return null;
		}

		return (
			<div className="bar-outer-container">
				<div>
					{this.props.translate('ASSESSMENTGOAL')}
				</div>

				<div style={{ paddingLeft: '.55rem' }}>
					<Progress percentage={percentage} label={`${percentage}%`} />
				</div>
			</div>
		);
	}


	getCourses = () => {
		let courses = [];
		if (this.props.sectionVerdicts != null) {
			this.props.sectionVerdicts.forEach(verdict => {
				verdict.courses.forEach(course => {
					let inCourses = courses.find(c => {
						return c.courseID == course.courseID;
					});

					if (inCourses == null) {
						courses.push(course);
					}
				})
			})
		}

		if (courses.length > 0) {
			courses = courses.sort((a, b) =>
				a.courseID > b.courseID ? 1 : 0
			);
		}

		return courses;
	}

	toggleStudentOpen = (student) => {
		const index = this.state.openStudents.indexOf(student.userId);

		if (index > -1) {
			this.setState({ openStudents: this.state.openStudents.filter(id => id != student.userId) });
		} else {
			this.setState({ openStudents: [...this.state.openStudents, student.userId] });
		}
	}

	toggleStudentsOpen = () => {
		const active = this.props.section.students.length == this.state.openStudents.length;

		if (active) {
			this.setState({ openStudents: [] });
		} else {
			this.setState({ openStudents: this.props.section.students.map(student => student.userId) });
		}
	}

	toggleFilter = (filter) => {
		const index = this.state.filters.indexOf(filter);

		if (index > -1) {
			this.setState({ filters: this.state.filters.filter(id => id != filter) });
		} else {
			this.setState({ filters: [...this.state.filters, filter] });
		}
	}

	getChildHistoricMembers = (historicMember) => {
		const children = this.props.section.historicMembers.filter(el =>
			el.userId == historicMember.userId && el.relationship == 'child'
		);

		return children.map((member) => {
			return (
				<div className="history-member-row child">
					<div style={{ flex: 1 }} className="name">
					</div>

					<div className="connected-duration">
						<span>
							{`${new DateTime(member.startDate).getDateStamp()} - ${new DateTime(member.endDate).getDateStamp()}`}
						</span>

						{member.userRoles.some(el => el == 'OWNER') ?
							<div className="card-meta" style={{ marginLeft: '1.65rem', fontWeight: 500 }}>
								{this.props.translate('Owner')}
							</div>
							: null}
					</div>
				</div>
			);
		});
	}

	checkIfStudentHasSameName = (student, data) => {
		if (student && data) {
			return data.filter(x =>
				x.firstName === student.firstName && x.lastName === student.lastName
			).length > 1;
		}
	}

	renderStudentRoleIndicator = (student) => {
		if (student == null) {
			return null;
		}

		if (student.userRoles != null && student.userRoles.find(role => { return role.toUpperCase() == "STUDENT" }) == null) {
			const { translate } = this.props;

			return (
				<Icon name="Alert_Red" title={translate('Missing student role in school')} />
			);
		}

		return null;
	};

	renderStudentRow = (student) => {
		const { absences } = this.state;
		const user = new User(student);
		let attendance = this.calculateAttendance(this.props.attendance, student);

		let foundValid = null;
		let foundInvalid = null;

		if (absences != null) {
			foundValid = absences.find(absence => {
				return absence.user.userId == student.id && absence.type == "VALID_ABSENCE";
			});

			foundInvalid = absences.find(absence => {
				return absence.user.userId == student.id && absence.type == "INVALID_ABSENCE";
			});
		}

		return <tr key={student.id}>
			<td className="name">
				{this.checkIfStudentHasSameName(student, this.props.section.students) ?
					<InfoButton email={student.email} />
					: ""}
				<Flex center>
					{foundInvalid != null ?
						<span
							style={{
								width: 11,
								height: 11,
								borderRadius: 10,
								display: 'inline-block',
								marginRight: '0.3rem',
								backgroundColor: "#f25252",
							}}
						></span>
						: null}

					{foundInvalid == null && foundValid != null ?
						<span
							style={{
								width: 11,
								height: 11,
								borderRadius: 10,
								display: 'inline-block',
								marginRight: '0.3rem',
								backgroundColor: '#FF883E',
							}}
						></span>
						: null}
					<Link to={`/user/${student.id}/progress/#attendance`}>
						{this.renderStudentRoleIndicator(user)}
						{user.getName()}
					</Link>
				</Flex>
			</td>

			<td>
				{attendance != null ?
					<Flex center>
						<span
							style={{
								width: 11,
								height: 11,
								borderRadius: 10,
								display: 'inline-block',
								marginRight: '0.3rem',
								backgroundColor: '#19DB6C'
							}}
						></span>
						{attendance.percentage.attendance}%
						({attendance.display.present})
					</Flex>
					: <Skeleton />}
			</td>

			<td>
				{attendance != null ?
					<Flex center>
						<span
							style={{
								width: 11,
								height: 11,
								borderRadius: 10,
								display: 'inline-block',
								marginRight: '0.3rem',
								backgroundColor: '#FF883E'
							}}
						></span>
						{attendance.percentage.validAbsence}%
						({attendance.display.validAbsence})
					</Flex>
					: <Skeleton />}
			</td>

			<td>
				{attendance != null ?
					<Flex center>
						<span
							style={{
								width: 11,
								height: 11,
								borderRadius: 10,
								display: 'inline-block',
								marginRight: '0.3rem',
								backgroundColor: '#F25252'
							}}
						></span>
						{attendance.percentage.invalidAbsence}%
						({attendance.display.invalidAbsence})
					</Flex>
					: <Skeleton />}
			</td>
		</tr>
	}

	renderVerdictTable = () => {
		const courses = this.getCourses();
		const sortedStudents = this.props.section.students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));
		if (courses.length < 1) {
			return (
				<div style={{ margin: '4rem 0 3rem 0' }} className="text--center color--meta">
					{this.props.translate('no-results')}
				</div>
			)
		}

		return (
			<Table>
				<thead>
					<tr>
						<th>{this.props.translate('name')}</th>

						{courses.map((course, i) => {
							return <th key={i}>
								{course.title + (course.year != null ? " " + course.year : '')}
							</th>
						})}
					</tr>
				</thead>

				<tbody>
					{sortedStudents.map((student) => {
						return <tr key={student.userId}>
							<td className="name">
								{this.checkIfStudentHasSameName(student, sortedStudents) ?
									<InfoButton email={student.email} />
									: ""}
								<Link to={`/user/${student.id}/progress/#verdicts`}>
									{this.renderStudentRoleIndicator(student)}
									{student.firstName} {student.lastName}
								</Link>
							</td>

							{courses.map((course, i) => {
								let verdict = this.props.sectionVerdicts.find(verdict => {
									if (verdict.userId != student.userId) {
										return false;
									}

									let inCourses = verdict.courses.findIndex(co =>
										co.courseID == course.courseID
									);

									if (inCourses > -1) {
										return true;
									}
									return false;
								});

								if (verdict == null) {
									return <td key={i}>
										<div style={{ height: 10 }} />
									</td>
								}

								return <td key={i} style={{ textAlign: 'center' }}>
									<VerdictWidget
										displayLabel={false}
										verdict={verdict}
									/>
								</td>
							})}
						</tr>
					})}
				</tbody>
			</Table>
		)
	}

	renderAttendanceTable = () => {
		const sortedStudents = this.props.section.students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

		return (
			<div>
				<div style={{ position: 'relative', margin: '-1.4rem -1.7rem' }}>
					<Table>
						<thead>
							<tr>
								<th>{this.props.translate('name')}</th>
								<th>{this.props.translate('attendance')}</th>
								<th>{this.props.translate('valid_absence')}</th>
								<th>{this.props.translate('invalid_absence')}</th>
							</tr>
						</thead>

						<tbody>
							{sortedStudents.map(this.renderStudentRow)}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}

	renderAssessmentsTable = () => {
		let width = this.state.width > 0 ? this.state.width + 'px' : '100%';
		const sortedStudents = this.props.section.students.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""));

		return (
			<div>
				<div style={{ position: 'relative', margin: '-1.4rem -1.7rem' }}>
					<Table>
						<thead>
							<tr>
								<th ref={this._setBarColumn}>
									{this.props.translate('name')}
								</th>

								<th style={{ width }}>
									{this.props.translate('assessment-overview')}
								</th>
							</tr>
						</thead>

						<tbody>
							{sortedStudents.map((student) => {
								let assessments = null;
								let percentage = 0;
								const open = this.state.openStudents.includes(student.userId);
								if (this.props.assessments != null) {
									assessments = this.props.assessments.find(assessment => {
										return assessment.studentId == student.userId;
									});

									const goalStatistics = assessments?.studentStatistics?.filter(stat => stat.type == 'ASSESSMENTGOAL');
									percentage = this.getPercentageFromStatistics(goalStatistics);
								}

								return <tr key={student.id}>
									<td className="name" title={`${this.props.translate('Connected to group')} ${new DateTime(student.connectedToSectionDate).getDateStamp()}`}>
										{this.checkIfStudentHasSameName(student, sortedStudents) ?
											<InfoButton email={student.email} />
											: ""}
										<Link to={`/user/${student.id}/progress/#assessments`}>
											{this.renderStudentRoleIndicator(student)}
											{student.firstName} {student.lastName}
										</Link>
									</td>

									<td>
										{this.renderAssessedAndAssignedInfo(assessments)}


										{percentage > 0 ?
											this.renderGoalBar(percentage)
											: null}

										{assessments != null ?
											assessments.studentStatistics.map((item, index) => {
												if (item.type == 'MATRIX') {
													return this.renderAssessmentBar(item, index);
												}

												return null;
											})
											: <div style={{ height: 23 }} />}

									</td>
								</tr>
							})}
						</tbody>
					</Table>
				</div>
			</div>
		);
	}

	render() {
		this.props.section.historicMembers?.forEach((member, index) => {
			var laterEntries = this.props.section.historicMembers.some((el, someIndex) => el.userId == member.userId && someIndex > index);
			var earlierEntries = this.props.section.historicMembers.some((el, someIndex) => el.userId == member.userId && someIndex < index);
			if (laterEntries && !earlierEntries) {
				member.relationship = 'parent';
			} else if ((laterEntries && earlierEntries) || (!laterEntries && earlierEntries)) {
				member.relationship = 'child';
			} else {
				member.relationship = null;
			}
		});

		let subjects = this.props.subjects;

		if (subjects != null && subjects.find((subject) => subject.id == 0) == null) {
			subjects.push({ id: 0, title: this.props.translate('Assessments without subjects') })
		}

		let multipleDropDown = null;

		if (this.state.subjects != null && subjects != null && subjects.length > 0) {
			multipleDropDown = <div style={{ display: 'flex', alignItems: 'center' }}>{this.props.translate('Showing') + ' ' + this.state.subjects.length + ' ' + this.props.translate('of') + ' ' + subjects.length + ' ' + this.props.translate('subjects')} <ArrowDropDownOutlinedIcon style={{ marginLeft: '.3rem' }}></ArrowDropDownOutlinedIcon></div>
		}
		return (
			<div ref={this._setContainer} className="list user-mentor">
				{this.props.renderActions != null ? this.props.renderActions() : null}

				<div className="clearfix"></div>

				<Block>
					<div className="title-container">
						<span className="title" style={{ marginBottom: 0 }}>
							{this.props.translate('Students')}
						</span>
						{this.state.showMenu ?

							<div className="filter-container">
								{this.state.smallLoader ?
									<Spinner small />
									: null}

								<Checkbox
									label={this.props.translate('Show the number of Assessed-Assigned')}
									onChange={() => this.toggleFilter('ASSESSED-ASSIGNED')}
									value={this.state.filters.includes('ASSESSED-ASSIGNED')}
								/>

								<Checkbox
									label={this.props.translate('MATRIX')}
									onChange={() => this.toggleFilter('MATRIX')}
									value={this.state.filters.includes('MATRIX')}
								/>

								<Checkbox
									label={this.props.translate('ASSESSMENTGOAL')}
									onChange={() => this.toggleFilter('ASSESSMENTGOAL')}
									value={this.state.filters.includes('ASSESSMENTGOAL')}
								/>

								{multipleDropDown != null && this.props.subjects != null ?
									<MultiSelect
										displaySelectedCount={false}
										trigger={multipleDropDown}
										options={subjects.map((subject) => ({
											label: subject.title,
											value: subject.id,
										}))}
										onChange={this.onSubjectsChange}
										value={this.props.subjects.map((subject) => subject.id)}
										selectAllLabel={this.props.translate('Select all')}
									/>
									: null}
							</div>
							: null}
					</div>

					<Tabs>
						<Tab title={this.props.translate('assessments-header-overview')} route="assessments">
							{this.state.loadingAssessments ?
								<Spinner center />
								: this.renderAssessmentsTable()}
						</Tab>

						<Tab title={this.props.translate('verdicts')} route="verdicts">
							{this.state.loadingVerdicts ?
								<Spinner center />
								:
								<div style={{ position: 'relative', margin: '-1.4rem -1.7rem' }}>
									{this.renderVerdictTable()}
								</div>
							}
						</Tab>

						<Tab title={this.props.translate('attendance')} route="attendance">
							{this.renderAttendanceTable()}
						</Tab>
					</Tabs>

					{this.props.section.historicMembers?.length > 0 &&
						<div className="previous-members-card">
							<Collapsible trigger={(
								<div className="previous-members-title">
									{this.props.translate('Previous members')}
								</div>
							)}>
								<div className="previous-member-list">
									{this.props.section.historicMembers?.map((student) => {

										const hasSameName = this.props.section.historicMembers.filter(x =>
											x.displayName === student.displayName).length > 1;
										return (
											<div>
												{student.relationship == 'parent' ?
													<Collapsible trigger={(
														<div className="history-member-row">
															<div className="name" style={{ marginRight: "1rem" }}>
																<DisplayName
																	firstName={student.displayName}
																	email={student.userName}
																	sameName={hasSameName}
																/>
															</div>
															<div className="connected-duration">
																<span>{`${new DateTime(student.startDate).getDateStamp()} - ${new DateTime(student.endDate).getDateStamp()}`}</span>
																{student.userRoles.some(el => el == 'OWNER') ?
																	<div className="card-meta" style={{ marginLeft: '1.65rem', fontWeight: 500 }}>
																		{this.props.translate('Owner')}
																	</div>
																	: null
																}
															</div>
														</div>
													)}>
														{this.getChildHistoricMembers(student)}
													</Collapsible>
													:
													student.relationship == null ?
														<div className="history-member-row child">
															<div style={{ flex: 1, marginRight: "1rem" }} className="name">
																<DisplayName
																	firstName={student.displayName}
																	email={student.userName}
																	sameName={hasSameName}
																/>
															</div>
															<div className="connected-duration">
																{`${new DateTime(student.startDate).getDateStamp()} - ${new DateTime(student.endDate).getDateStamp()}`}
															</div>
														</div>
														: null
												}
											</div>
										);
									})}
								</div>
							</Collapsible>
						</div>
					}
				</Block>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		mentorGroups: state.sections.mentorGroups,
		sectionVerdicts: state.Verdicts.sectionStudents,
		scale: state.Verdicts.scale,
		assessments: state.sections.assessmentStatistics,
		subjects: state.Subjects.section,
	};
}

export default connect(mapStateToProps, {
	getGroupAbsenceForToday,
	getVerdictsForStudentsInSection,
	getVerdictScale,
	getSectionAssessmentStatistics,
	getSectionSubjects,
})(UserMentorSection);