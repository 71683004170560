import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import User from '_class/User';

import { getUserInfo, getUserRoles } from "actions/user";
import { setPageTitle } from "actions/header";

import UserRoles from "./Partials/UserRoles";
import Subjects from "./Partials/Subjects";

import { Spinner } from 'UI';
import { Flex, Skeleton, getActiveLanguage, Block, Tabs, Tab, translate } from '@haldor/ui';
import SectionList from "components/List/SectionList";

class Staff extends Component {

	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			loadingGroups: true,
			loadingRoles: true,
			userSchoolAdmin: false,
			staffIsMentor: false,
		}
	}

	componentDidMount = () => {
		let userId = null;
		if (this.props.match != null && this.props.match.params.userId != null) {
			userId = this.props.match.params.userId;
		} else if (this.props.userId != null) {
			userId = this.props.userId;
		} else {
			userId = this.props.currentStaff.id;
		}

		if (this.props.currentStaff == null) {
			let userSchoolAdmin = this.isCurrentUserSchoolAdmin();
			this.props.getUserInfo(userId, "default").then(() => {
				let staffIsMentor = this.isCurrentStaffMentor();
				this.setState({ loading: false, userSchoolAdmin: userSchoolAdmin, staffIsMentor: staffIsMentor });
				this.props.setPageTitle(
					this.props.currentStaff.firstName + " " + this.props.currentStaff.lastName
				);
			});
		} else {
			let userSchoolAdmin = this.isCurrentUserSchoolAdmin();
			let staffIsMentor = this.isCurrentStaffMentor();
			this.setState({ loading: false, userSchoolAdmin: userSchoolAdmin, staffIsMentor: staffIsMentor });
			this.props.setPageTitle(
				this.props.currentStaff.firstName + " " + this.props.currentStaff.lastName
			);
		}

		this.props.getUserRoles(userId).then(() => {
			this.setState({ loadingRoles: false });
		})
	}

	isCurrentUserSchoolAdmin = () => {
		if (this.props.currentUser.userRoles != null && this.props.currentUser.userRoles.length > 0) {
			var schoolAdministrator = this.props.currentUser.userRoles.find(role =>
				role.toUpperCase() == "SCHOOL_ADMINISTRATOR" || "SYSADMIN"
			);

			if (schoolAdministrator != null) {
				return true;
			}
		}

		return false;
	}

	isCurrentStaffMentor = () => {
		if (this.props.currentStaff == null) {
			return false;
		}

		if (this.props.currentStaff.userRoles != null && this.props.currentStaff.userRoles.length > 0) {
			var mentor = this.props.currentStaff.userRoles.find(role => role.toUpperCase() == "MENTOR");
			if (mentor != null) {
				return true;
			}
		}

		return false;
	}

	render() {
		const user = new User(this.props.currentUser);
		if (this.state.loading || this.props.currentStaff == null) {
			return (
				<div className="staff-card">
					<Block>
						<Flex>
							<div style={{ flex: 1 }}>
								<div style={{ width: 225 }}>
									<Skeleton />
								</div>
							</div>
						</Flex>
					</Block>
				</div>
			)
		}

		return (
			<div className="staff-card">
				<Block>
					<span className="title">
						{this.props.translate('e-mail')}
					</span>

					{this.props.currentStaff.email != null ?
						this.props.currentStaff.email
						: null}
				</Block>

				<Tabs>
					{this.state.userSchoolAdmin || this.state.staffIsMentor ?
						<Tab title={this.props.translate('Groups')} route="mentor">

							{this.state.userSchoolAdmin ?
								<SectionList userId={this.props.currentStaff.userId}></SectionList>
								: null}
						</Tab>
						: null}

					<Tab title={this.props.translate('roles')} route="roles">
						{this.state.loadingRoles ?
							<Spinner center />
							:
							<UserRoles currentStaff={this.props.currentStaff} />
						}
					</Tab>

					{user.isAdministrator() ?
						<Tab title={this.props.translate('subjects')} route="subjects">
							<Subjects userId={this.props.currentStaff.userId} />
						</Tab>
						: null}
				</Tabs>
			</div>
		);
	}

}

function mapStateToProps(state, ownProps) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		currentStaff: state.user.info.find(u => {
			return u.userId == ownProps.match.params.userId;
		})
	};
}

export default connect(mapStateToProps, {
	getUserInfo,
	setPageTitle,
	getUserRoles,
})(Staff);