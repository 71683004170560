import { searchCourseByTitle, searchSectionByTitle } from 'actions/search';
import { getSchoolByGroupGraphId } from 'actions/sections';
import { debounce } from 'helpers/content';
import schoolHandling from 'helpers/schoolHandling';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { reduxForm } from 'redux-form';
import { Spinner } from 'UI';
import { Button } from '@haldor/ui';
import { informAboutTabData } from 'actions/sections';
import api from 'lib/api';
import * as microsoftTeams from "@microsoft/teams-js";

import Onboarding from 'containers/Onboarding/Onboarding';

class SchoolSettingsForm extends Component {

	constructor(props) {
		super(props);

		let onboarding = false;
		if (props.onboarding) {
			onboarding = props.onboarding;
		}

		this.state = {
			courses: [],
			group: null,
			school: null,
			disableGroupInput: false,
			hideSchoolInput: false,
			groupType: 'EDUCATION_GROUP',
			onboarding,
			steps: [
				{
					target: '#school-input',
					content: props.translate('groupconfig-explain-school'),
					disableBeacon: true,
				},
				{
					target: '#course-input',
					content: props.translate('groupconfig-explain-courses'),
					disableBeacon: true,
				},
				{
					target: '#teams-header-actions',
					content: props.translate('groupconfig-explain-cog'),
					disableBeacon: true,
				},
			],
		};
	}

	componentDidMount = () => {
		microsoftTeams.app.getContext().then((context) => {
			this.props.getSchoolByGroupGraphId(context.team.groupId).then(() => {
				if (this.props.graphGroupSchool != null) {
					this.setState({ school: this.props.graphGroupSchool.id });
				}
			});

			const group = {
				title: context.team.displayName,
				graphId: context.team.groupId,
			};

			this.setState({ group, disableGroupInput: true });
		});

		if (this.props.currentUser != null) {
			const { currentUser } = this.props;
			if (currentUser.schools != null && currentUser.schools.length == 1) {
				this.selectSchool(currentUser.schools[0]);
				this.setState({ hideSchoolInput: true });
			}
		}
	}

	submit = (values) => {
		return new Promise((resolve) => {
			const courses = [];
			if (this.state.courses != null && this.state.courses.length > 0) {
				this.state.courses.forEach((extraCourse) => {
					const duplicated = courses.find(dupe => dupe.id == extraCourse.value);

					if (duplicated == null) {
						courses.push({
							id: extraCourse.value,
						});
					}
				});
			}

			values.title = this.state.group.label;
			values.graphId = this.state.group.value;
			values.type = this.state.groupType;
			values.courses = courses;
			values.schoolId = this.state.school.value;
			values.description = '';

			api.post('sections', values).then((response) => {
				if (values.schoolId != 0) {
					schoolHandling.setSchoolOnUser(this.props.currentUser, values.schoolId);
				}

				this.props.informAboutTabData(this.state.group.value, 'HALDOR_PLANNING');

				if (this.props.onSubmit != null) {
					this.props.onSubmit(response.data)
						.then(() => {
							resolve(1);
						});
				} else {
					resolve(1);
				}
			});
		});
	}

	onSearchExtraCourse = (title, callback) => {
		if (!title) {
			callback([]);
		} else {
			var searchValue = title.trim();
			searchValue = searchValue.replace(/[^A-Za-z0-9åäöÅÄÖ ]/g, "");
			searchValue = encodeURIComponent(searchValue);

			if (searchValue.length < 3) {
				callback([]);
				return false;
			}

			if (searchValue != null && searchValue != "") {
				this.props.searchCourseByTitle(searchValue, (data) => {
					let options = [];

					data.forEach((course) => {
						var title = "";
						if (course.typeOfSchooling != null) {
							var typeOfSchool = this.props.translate(course.typeOfSchooling);

							if (typeOfSchool.indexOf("Missing") == -1) {
								title = `${course.title} ${course.year != null ? course.year : ''} (${this.props.translate(course.typeOfSchooling)}) (${course.courseCode})`;
							} else {
								title = `${course.title} ${course.year != null ? course.year : ''} (${course.courseCode})`
							}
						} else {
							title = `${course.title} ${course.year != null ? course.year : ''} (${course.courseCode})`
						}

						options.push({ value: course.id, label: title });
					});

					callback(options);
				});
			} else {
				callback([]);
			}
		}
	}

	onSearchSection = (title, callback) => {
		if (!title) {
			callback([]);
		} else {
			var searchValue = title.trim();
			searchValue = searchValue.replace(/[^A-Za-z0-9åäöÅÄÖ ]/g, "");
			searchValue = encodeURIComponent(searchValue);

			if (searchValue.length < 3) {
				callback([]);
				return false;
			}

			if (searchValue != null && searchValue != "") {
				this.props.searchSectionByTitle(searchValue, true, (data) => {
					let options = [];

					data.forEach((section) => {
						options.push({
							value: section.graphId,
							label: section.title
						});
					});

					callback(options);
				});
			} else {
				callback([]);
			}
		}
	}

	exitOnboarding = () => this.setState({ onboarding: false });

	onSectionChange = (option) => {
		this.setState({ group: option });
	}

	onExtraCourseChange = (option) => {
		this.setState({ courses: option });
	}

	selectSchool = (option) => {
		this.setState({ school: option });
	}

	render() {
		const { handleSubmit, submitting } = this.props;

		return (
			<div className="form-container">
				{this.state.onboarding ?
					<Onboarding
						steps={this.state.steps}
						onExit={this.exitOnboarding}
						onboarding={this.state.onboarding}
					/>
					: null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<label>
							{this.props.translate('section')}
						</label>

						<div className="select">
							<Select
								isDisabled={this.state.disableGroupInput}
								isSearchable
								name="section"
								value={this.state.group}
								loadOptions={debounce(this.onSearchSection, 700)}
								onChange={value => this.onSectionChange(value)}
								placeholder={this.props.translate('search-for-a-group')}
								className="simple-select"
								multi={false}
								ignoreAccents={false}
								ignoreCase
								noOptionsMessage={() => this.props.translate('no-results')}
								loadingMessage={() => this.props.translate('loading') + '...'}
							/>
						</div>
					</div>

					{!this.state.hideSchoolInput ?
						<div className="form-row">
							<label>
								{this.props.translate('school')}
							</label>

							<div id="school-input">
								<Select
									isDisabled={this.props.graphGroupSchool != null}
									type="schools"
									placeholder={this.props.translate('pick-a-school')}
									labelKey="title"
									valueKey="id"
									selectedValue={this.state.school}
									value={this.state.school}
									options={this.props.currentUser.schools}
									onChange={this.selectSchool}
								/>
							</div>
						</div>
						: null}

					<div className="form-row">
						<label>{this.props.translate('courses-header-overview')}</label>

						<div id="course-input" className="select">
							<AsyncSelect
								isSearchable
								isMulti
								name="extra-courses"
								value={this.state.courses}
								loadOptions={debounce(this.onSearchExtraCourse, 700)}
								onChange={value => this.onExtraCourseChange(value)}
								placeholder={this.props.translate('search-for-courses')}
								className="simple-select"
								valueKey="id"
								labelKey="title"
								ignoreAccents={false}
								isLoading={false}
								ignoreCase
								trimFilter={true}
								noOptionsMessage={() => this.props.translate('no-results')}
								loadingMessage={() => this.props.translate('loading') + '...'}
							/>
						</div>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button style={{ marginBottom: '25px' }} disabled={submitting || this.state.school == null}>
							{this.props.translate('save')}

							{submitting ?
								<Spinner center small />
								: null}
						</Button>
					</div>

					<div className="clearfix" />
				</form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		graphGroupSchool: state.sections.graphGroupSchool,
	}
}

export default connect(mapStateToProps, {
	searchCourseByTitle,
	getSchoolByGroupGraphId,
	searchSectionByTitle,
	informAboutTabData,
})(reduxForm({
	form: 'SchoolSettings',
	destroyOnUnmount: true,
})(SchoolSettingsForm));
