import React, { useEffect, useState } from 'react';
import { Flex, Icon, Expandable, Block } from '@haldor/ui';
import { useTranslate } from 'lib/translate';

import GoalMatrixRowForm from './GoalMatrixRowForm';
import { capitalize } from 'helpers/content';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import { Collapsible, ColorLabel, ColorLabelContainer } from 'UI';

import './_OnlyFeedbackForm.scss';
import { utlGetLocalStorage } from '@microsoft/applicationinsights-common';

const DisplayOnlyFeedback = (props) => {
	const translate = useTranslate();
	const { part, block } = props;
	const assessment = block.api.getEditor().getProp('assessment');
	const isStudent = block.api.getEditor().getProp('isStudent');

	const renderAssessmentFeedbackPermission = () => {
		const active = props.part.permissions?.find(element =>
			element.target == 'GUARDIAN' && element.referenceType == 'ASSESSMENTFEEDBACK'
		) != null;

		return <div className="permission active">
			<>
				<Icon name={active ? "Eye" : "Eye_Off"} />
				{translate(capitalize('Show feedback for guardians'))}
			</>
		</div>
	}

	const renderTitle = () => {
		return (<div className="df aic jcb">
			{translate('Feedback')}

			{!isStudent ?
				<div className="trigger-container">
					{renderAssessmentFeedbackPermission()}
				</div>
				: null}
		</div>)
	}

	let feedbacks = [];

	if (assessment != null) {
		feedbacks = assessment.assessmentFeedbacks?.filter((feedback) =>
			feedback.assessmentBlockPartId == part.id
		);
	}

	if (!isStudent) {
		return (<div className="only-feedback-container">
			<Block>
				<Flex space>
					<span className="title" style={{ '--color': '#777' }}>
						{translate('Feedback')}
					</span>

					<div className="trigger-container">
						{renderAssessmentFeedbackPermission()}
					</div>
				</Flex>
			</Block>
		</div>)
	}

	if (isStudent && feedbacks != null && feedbacks.length > 0) {
		return (
			<div className="only-feedback-container">
				<Expandable open
					title={renderTitle()}
					color="#777"
					ignorePropsUpdate>
					{feedbacks != null && feedbacks.length > 0 ?
						<div className="feedbacks">
							{feedbacks.map((feedback) => {
								return <Collapsible
									key={feedback.id}
									trigger={translate('Feedback')}
									defaultOpen
								>
									<div
										dangerouslySetInnerHTML={{ __html: feedback.text }}
									/>
								</Collapsible>
							})}
						</div>
						: null}
				</Expandable>
			</div>
		);
	}

	return null;
}

DisplayOnlyFeedback.defaultProps = {
	assessments: [],
	disabled: false,
};

export default DisplayOnlyFeedback;