import React from 'react';

const Input = props => (
	<div className="form-row input">
		{props.label ? <label htmlFor={props.name}>{props.label}</label> : ''}

		{
			props.maxLength != null ?
				<input
					type={props.type}
					id={props.name}
					onChange={props.onChange}
					placeholder={props.placeholder}
					className={props.class}
					value={props.value}
					name={props.name}
					onKeyDown={props.onKeyDown}
					disabled={props.disabled}
					maxLength={props.maxLength}
				/>
				:
				<input
					type={props.type}
					id={props.name}
					onChange={props.onChange}
					placeholder={props.placeholder}
					className={props.class}
					value={props.value}
					name={props.name}
					onKeyDown={props.onKeyDown}
					disabled={props.disabled}
				/>
		}
	</div>
);

export default Input;
