import React, { Component } from 'react';

import { Document } from '../Icons';
import { Checkbox } from '@haldor/ui';

import './Style.scss';

class FileList extends Component {
	onFolderClick = ( folder ) => {
		if ( this.props.onFolderClick ) {
			this.props.onFolderClick( folder );
		}
	}

	onFileClick = ( file ) => {
		if ( this.props.onFileClick ) {
			this.props.onFileClick( file );
		}
	}

	render() {
		const { files, selectedFiles } = this.props;

		return (
			<div className="file-list">
				{ this.props.files.map( file => {
					if ( file.folder ) {
						return <div className="list-directory" key={ file.id } onClick={ e => { this.onFolderClick( file ) } }>
							<strong>{ file.name }</strong>
							<div className="clearfix"></div>
						</div>;
					} else {
						const isSelected = this.props.selectedFiles.find( function( selF ) {
							return selF.id == file.id;
						});

						const selected = isSelected != null;

						return (
							<div className={ selected ? "list-file active" : "list-file" }
								key={ file.id }
								onClick={ e => { e.preventDefault(); this.onFileClick(file); }}>

								<span className="icon-container">
									<Document />
								</span>

								<span className="checkbox-container">
									<Checkbox
										id={ 'checkbox-' + file.id }
										value={selected}
									/>
								</span>

								<div className="info">
									{ file.name }
								</div>

								<div className="clearfix"></div>
							</div>
						);
					}
				}) }
			</div>
		);
	}
}

export default FileList;
