import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { FlexibleTextArea } from 'UI/Inputs';
import '../../Verdict.scss';

class VerdictFeedbackForm extends Component {

	constructor(props) {
		super(props);

		let value = '';

		if (props.value != null) {
			value = props.value;
		}

		this.state = {
			value,
		}
	}

	onChange = (event) => {
		const { value } = event.target;
		this.setState({ value }, () => {
			if (this.props.onChange != null) {
				this.props.onChange(value);
			}
		});
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.value != this.state.value) {
			this.setState({ value: nextProps.value });
		}
	}

	render() {
		const { translate } = this.props;

		return (
			<div className="verdict verdict-feedback-form">
				<div className="input">
					<div className="form">
						<div className="form-row" style={{ padding: 0 }}>
							<FlexibleTextArea
								value={this.state.value != null ? this.state.value : ''}
								placeholder={translate('write-feedback')}
								onChange={this.onChange}
								autoGrow={true}
								maximumHeight={250}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(VerdictFeedbackForm);
