import React from 'react';
import Card, { CardDescription, CardsContainer, CardSubtitle, CardTitle } from './Card';

function renderCards(count) {
	const cards = [];
	for (let i = 0; i < count; i++) {
		cards.push(<Card key={i} type="placeholder">
			<CardSubtitle />
			<CardTitle />
			<CardDescription />
		</Card>);
	}

	return <CardsContainer>{cards}</CardsContainer>;
}

const loadingCards = ({ count = 3 }) => <div>{renderCards(count)}</div>;

export default loadingCards;
