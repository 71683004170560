import api from 'lib/api';

export const GET_FORECAST = 'ABSENCE_GET_FORECAST';
export const GET_CALENDAR_ATTENDENCE = 'ABSENCE_GET_CALENDAR_ATTENDENCE';
export const GET_SCHOOL_STUDENTS_PAGE = 'ABSENCE_GET_SCHOOL_STUDENTS_PAGE';
export const GET_USER_ATTENDANCE = 'ABSENCE_GET_USER_ATTENDANCE';
export const CLEAR_USER_ATTENDANCE = 'ABSENCE_CLEAR_USER_ATTENDANCE';
export const CLEAR_FORECAST = 'ABSENCE_CLEAR_FORECAST';

export const SUBMIT_ATTENDANCE = 'ABSENCE_SUBMIT_ATTENDANCE';
export const UPDATE_ATTENDANCE = 'ABSENCE_UPDATE_ATTENDANCE';

export const SUBMIT_ABSENCE = 'ABSENCE_SUBMIT_ABSENCE';
export const UPDATE_ABSENCE = 'ABSENCE_UPDATE_ABSENCE';
export const DELETE_ABSENCE = 'DELETE_ABSENCE';
export const DELETE_REPORTED_ATTENDANCE = 'ATTENDANCE_DELETE_REPORTED_ATTENDANCE';

export const GET_USER_ATTENDANCE_STATS = 'ABSENCE_GET_USER_ATTENDANCE_STATS';

// Get forecast before reporting attendence
export const getForecast = (calendarId) => ({
	type: GET_FORECAST,
	payload: new Promise(resolve => {
		api.get(`attendance/Forecast/?calendarEventId=${calendarId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearForecast = () => ({
	type: CLEAR_FORECAST,
	payload: true,
});

// Get reported attendence?
export const getAttendence = (calendarId) => ({
	type: GET_CALENDAR_ATTENDENCE,
	payload: new Promise(resolve => {
		api.get(`attendance/ForCalendarEvent?calendarEventId=${calendarId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const submitAttendence = (values, lessonId) => ({
	type: SUBMIT_ATTENDANCE,
	payload: new Promise((resolve, reject) => {
		api.post(`attendance/?calendarEventId=${lessonId}`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateAttendance = (values, lessonId) => ({
	type: UPDATE_ATTENDANCE,
	payload: new Promise((resolve, reject) => {
		api.put(`attendance/?calendarEventId=${lessonId}`, values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const submitAbsence = (values) => ({
	type: SUBMIT_ABSENCE,
	payload: new Promise((resolve, reject) => {
		api.post('absence', values).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const updateAbsence = (values) => ({
	type: UPDATE_ABSENCE,
	payload: new Promise((resolve, reject) => {
		api.put('absence', values).then((response) => {
			resolve(response.data);
		}).catch((error) => {
			reject(error);
		});
	}),
});

export const getUserAttendanceStatistics = (userId, start, end) => ({
	type: GET_USER_ATTENDANCE_STATS,
	payload: new Promise(resolve => {
		api.get(`attendance/Statistics?studentId=${userId}&startDate=${start}&endDate=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserAttendance = (userId, start, end, sectionId) => ({
	type: GET_USER_ATTENDANCE,
	payload: new Promise(resolve => {
		if (sectionId != 0 && sectionId != null) {
			api.get(`attendance/AttendanceDetails?studentId=${userId}&startTime=${start}&endTime=${end}&sectionId=${sectionId}`).then((response) => {
				resolve(response.data);
			});

			return true;
		}

		api.get(`attendance/AttendanceDetails?studentId=${userId}&startTime=${start}&endTime=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearUserAttendance = () => ({
	type: CLEAR_USER_ATTENDANCE,
	payload: true,
});

export const deleteAbsence = (absenceId) => ({
	type: DELETE_ABSENCE,
	payload: new Promise((resolve) => {
		api.delete(`absence/${absenceId}`).then((response) => {
			resolve(absenceId);
		});
	}),
});

export const deleteReportedAttendance = (calendarEventId) => ({
	type: DELETE_REPORTED_ATTENDANCE,
	payload: new Promise((resolve) => {
		api.delete(`attendance/${calendarEventId}`).then((response) => {
			resolve(calendarEventId);
		});
	}),
})
