import React from 'react';

const AssignmentTaskStatusCompleted = React.memo(() => {
	return (
		<svg
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<circle
				cx={21.32}
				cy={21.43}
				r={17.86}
				style={{
					fill: "#fff",
					strokeMiterlimit: 10,
					strokeWidth: "3.61px",
					stroke: "#164cc4",
				}}
			/>
			<path
				d="M21.32 14.25v14.36m-7.18-7.18H28.5"
				style={{
					stroke: "#164cc4",
					fill: "none",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: 3,
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusCompleted;