import React, { Component } from 'react';

import NotificationSection from 'containers/Overview/Sections/Notifications';

class Notifications extends Component {

	render() {
		return (
			<div className="overview">
				<div className="section" id="section-notifications">
					<NotificationSection />
				</div>
			</div>
		);
	}
}

export default Notifications;
