import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { Button, translate } from '@haldor/ui';

class AddLinkForm extends Component {

	submit = (values) => {
		return new Promise(resolve => {
			if (this.props.onSubmit != null) {
				this.props.onSubmit(values).then(() => {
					resolve(1);
				})
			}
		});
	}

	onCancel = (event) => {
		event.preventDefault();

		if (this.props.onCancel != null) {
			this.props.onCancel();
		}
	}

	renderInput = ({
		input,
		label,
		type,
		placeholder,
		meta: { touched, error, warning }
	}) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: "1px solid red"
							}
							: {}
					}
				/>

				{touched &&
					((error && (
						<span style={{ marginTop: "1rem", color: "red" }}>
							{error}
						</span>
					)) ||
						(warning && (
							<span style={{ marginTop: "1rem", color: "red" }}>
								{warning}
							</span>
						)))}
			</div>
		);
	};

	render() {
		const { handleSubmit, submitting, translate } = this.props;

		return <div className="form-container">
			{submitting ? (
				<div className="is_sending">
					<p>
						<span className="loading-spinner" />
					</p>
				</div>
			) : null}

			<form className="form form-component" onSubmit={handleSubmit(this.submit)}>
				<div className="form-row">
					<label style={{ marginTop: 0 }}>
						{this.props.translate('Web link')}*
					</label>

					<Field
						component={this.renderInput}
						type="text"
						name="url"
					/>
				</div>

				<div className="form-row">
					<label>
						{this.props.translate('Link name')}
					</label>

					<Field
						component={this.renderInput}
						type="text"
						name="title"
					/>
				</div>

				<div className="form-divider" />

				<div className="form-row spacing submit">
					<Button disabled={submitting} onClick={handleSubmit(this.submit)} type="primary">
						{this.props.initialValues != null ?
							translate('Save')
							: translate('Add')}
					</Button>

					<div className="align-right">
						<Button onClick={this.onCancel} disabled={submitting} type="secondary">
							{translate('Cancel')}
						</Button>
					</div>
				</div>
			</form>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps)(reduxForm({
	form: 'AddLinkForm',
	destroyOnUnmount: true,
})(AddLinkForm));