import React from 'react';
import PropTypes from 'prop-types';
import DateTime from '_class/DateTime';
import { useTranslate } from 'lib/translate';
import { capitalize } from 'helpers/content';


import { Collapsible } from 'UI';
import { Icon } from '@haldor/ui';

import './_displayFeedbacks.scss';
import User from '_class/User';
import { useSelector } from 'react-redux';

const DisplayFeedbacks = ({ feedbacks, resource, resources, isStudentOverView }) => {
	const translate = useTranslate();
	const currentUser = useSelector(state => state.user.currentUser);
	const user = new User(currentUser);
	/**
	 * @returns {array} feedbacks
	 */
	const getPublicFeedbacks = () => {
		if (feedbacks == null) {
			return [];
		}

		return feedbacks.filter((feedback) => !feedback.isPrivate);
	}

	/**
	 * @returns {array} feedbacks
	 */
	const getPrivateFeedbacks = () => {
		if (feedbacks == null || currentUser == null) {
			return [];
		}

		return feedbacks.filter((feedback) => feedback.isPrivate && feedback.editorUser != null && feedback.editorUser.userId == currentUser.id);
	}

	const getResource = (feedback) => {
		if (resource != null) {
			return resource;
		}

		if (resources.length > 0) {
			return resources.find((resource) => feedback.assessmentBlockPartId == resource.id);
		}

		return null;
	}

	/**
	 * @param {string} permission
	 * @param {array} permissions
	 */
	const renderPermission = (permission, permissions) => {
		const active = permissions.find((perm) =>
			perm.target == permission && perm.referenceType == 'ASSESSMENTFEEDBACK'
		) != null;

		return <div className="permission">
			<Icon name={active ? "Eye" : "Eye_Off"} />
			{translate(capitalize(permission))}
		</div>
	}

	const renderFeedback = (feedback, index) => {
		const resource = getResource(feedback);
		let permissions = [];
		if (resource != null && !feedback.isPrivate) {
			permissions = resource.permissions;
		}

		return <div className="feedback" key={feedback.id}>
			<div className="size-12 feedback-item-header" style={{ marginBottom: '.4rem' }}>
				<div>
					{feedback.navigationObject != null ?
						feedback.navigationObject.referenceType == 'PLAN' ?
							translate('Plan')
							: translate('Assignment')
						: null}

					{feedback.navigationObject != null ?
						' • ' + feedback.navigationObject.title
						: null}
				</div>

				<div>
					{feedback.editorUser != null ?
						feedback.editorUser.firstName + ' ' + feedback.editorUser.lastName
						: null}

					{' • ' + new DateTime(feedback.published ? feedback.published : feedback.created).getLongDate()}
				</div>
			</div>

			<div className="feedback-item">
				<div
					dangerouslySetInnerHTML={{ __html: feedback.text }}
				/>

				{resource != null && !user.isStudent() ?
					<div className="permissions">
						{renderPermission('STUDENT', permissions)}
						{renderPermission('GUARDIAN', permissions)}
					</div>
					: null}
			</div>
		</div>
	}

	return (
		<div className="display-feedbacks">
			{getPublicFeedbacks().length > 0 ?
				<div className="collapsible-container">
					<Collapsible ignorePropsUpdate open trigger={translate('Feedback')}>
						{getPublicFeedbacks().map(renderFeedback)}
					</Collapsible>
				</div>
				: null}

			{getPrivateFeedbacks().length > 0 ?
				<div className="collapsible-container">
					<Collapsible trigger={translate('Personal notes')}>
						{getPrivateFeedbacks().map(renderFeedback)}
					</Collapsible>
				</div>
				: null}
		</div>
	)

}

DisplayFeedbacks.propTypes = {
	feedbacks: PropTypes.array,
	resource: PropTypes.object,
	resources: PropTypes.array,
};

DisplayFeedbacks.defaultProps = {
	resource: null,
	resources: [],
};

export default DisplayFeedbacks;