import api from 'lib/api';

export const GET_ADDITIONAL_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_ADJUSTMENTS';
export const GET_STUDENT_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_STUDENT_ADJUSTMENTS';
export const CREATE_STUDENT_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_CREATE_STUDENT_ADJUSTMENT';
export const UPDATE_STUDENT_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_UPDATE_STUDENT_ADJUSTMENT';
export const ADD_ADJUSTMENT_COMMENT = 'ADDITIONAL_ADJUSTMENTS_ADD_ADJUSTMENT_COMMENT';
export const GET_SCHOOL_ADJUSTMENTS = 'GET_SCHOOL_ADJUSTMENTS';
export const GET_GROUP_ADJUSTMENTS = 'ADDITIONAL_ADJUSTMENTS_GET_GROUP_ADJUSTMENTS';
export const GET_GROUP_ADJUSTMENTS_MULTIPLE = 'ADDITIONAL_ADJUSTMENTS_GET_GROUP_ADJUSTMENTS_MULTIPLE';
export const GET_STUDENT_ADJUSTMENTS_BY_COURSE = 'GET_STUDENT_ADJUSTMENTS_BY_COURSE';
export const GET_STUDENT_ADJUSTMENTS_BY_SUBJECT = 'GET_STUDENT_ADJUSTMENTS_BY_SUBJECT';

export const CREATE_ADDITIONAL_ADJUSTMENT = 'CREATE_ADDITIONAL_ADJUSTMENT';
export const SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT = 'SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT';
export const UPDATE_ADDITIONAL_ADJUSTMENT = 'UPDATE_ADDITIONAL_ADJUSTMENT';
export const UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT = 'UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT';

export const DELETE_ADDITIONAL_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_DELETE_ADDITIONAL_ADJUSTMENT';
export const DELETE_STUDENT_ADDITIONAL_ADJUSTMENT = 'ADDITIONAL_ADJUSTMENTS_DELETE_STUDENT_ADDITIONAL_ADJUSTMENT';
export const DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT = 'DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT';


export const getAdditionalAdjustments = () => ({
	type: GET_ADDITIONAL_ADJUSTMENTS,
	payload: new Promise(resolve => {
		api.get('AdditionalAdjustments').then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAdjustments = (student, start, end) => ({
	type: GET_STUDENT_ADJUSTMENTS,
	payload: new Promise(resolve => {
		api.get(`StudentAdditionalAdjustments?studentId=${student}&start=${start}&end=${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAdjustmentsByCourse = (student, courseId) => ({
	type: GET_STUDENT_ADJUSTMENTS_BY_COURSE,
	payload: new Promise(resolve => {
		api.get(`StudentAdditionalAdjustments?studentId=${student}&courseId=${courseId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAdjustmentsBySubject = (groupId, subjectId, studentId) => ({
	type: GET_STUDENT_ADJUSTMENTS_BY_SUBJECT,
	payload: new Promise(resolve => {
		api.get(`sections/${groupId}/subjects/${subjectId}/studentAdditionalAdjustments?userId=${studentId}`).then((response) => {
			resolve(response.data);
		});
	})
});

export const createStudentAdjustment = (values) => ({
	type: CREATE_STUDENT_ADJUSTMENT,
	payload: new Promise(resolve => {
		api.post('StudentAdditionalAdjustments', values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateStudentAdjustment = (values) => ({
	type: UPDATE_STUDENT_ADJUSTMENT,
	payload: new Promise(resolve => {
		api.put('StudentAdditionalAdjustments', values).then((response) => {
			resolve({
				oldId: values.id,
				...response.data
			});
		});
	}),
});

export const addComment = (values, studentAdjustment) => ({
	type: ADD_ADJUSTMENT_COMMENT,
	payload: new Promise(resolve => {
		api.post(`StudentAdditionalAdjustments/${studentAdjustment}/comments`, values).then((response) => {
			resolve(response.data);
		})
	}),
});

export const getSchoolAdjustments = () => ({
	type: GET_SCHOOL_ADJUSTMENTS,
	payload: new Promise(resolve => {
		api.get('StudentAdditionalAdjustments').then((response) => {
			resolve(response.data);
		});
	}),
});

export const createAdditionalAdjustment = (values) => ({
	type: CREATE_ADDITIONAL_ADJUSTMENT,
	payload: new Promise(resolve => {
		api.post('AdditionalAdjustments', values).then((response) => {
			resolve(response.data);
		})
	}),
});

export const switchActiveAdjustment = (values) => ({
	type: SWITCH_ACTIVE_ADDITIONAL_ADJUSTMENT,
	payload: new Promise(resolve => {
		values.items = [];

		api.put('AdditionalAdjustments/Disable', values).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateAdditionalAdjustment = (values) => ({
	type: UPDATE_ADDITIONAL_ADJUSTMENT,
	payload: new Promise(resolve => {
		values.items = [];

		api.put('AdditionalAdjustments', values).then((response) => {
			resolve({ oldId: values.id, ...response.data });
		})
	}),
});

export const deleteStudentAdjustment = (adjustment) => ({
	type: DELETE_STUDENT_ADDITIONAL_ADJUSTMENT,
	payload: new Promise((resolve) => {
		api.delete(`StudentAdditionalAdjustments/${adjustment.id}`).then((response) => {
			resolve(adjustment.id);
		})
	})
});

export const deleteStudentAdditionalAdjustmentComment = (comment) => ({
	type: DELETE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
	payload: new Promise(resolve => {
		api.delete(`StudentAdditionalAdjustments/${comment.studentAdjustmentID}/comments/${comment.id}`).then((response) => {
			if (response.data == true) {
				resolve(comment);
			}
		})
	}),
});

export const updateStudentAdditionalAdjustmentComment = (values) => ({
	type: UPDATE_STUDENT_ADDITIONAL_ADJUSTMENT_COMMENT,
	payload: new Promise(resolve => {
		api.put(`StudentAdditionalAdjustments/${values.studentAdjustmentID}/comments`, values).then((response) => {
			resolve(values);
		})
	})
})

export const deleteAdditionalAdjustment = (adjustment) => ({
	type: DELETE_ADDITIONAL_ADJUSTMENT,
	payload: new Promise((resolve) => {
		api.delete(`AdditionalAdjustments/${adjustment.id}`).then((response) => {
			resolve(adjustment.id);
		})
	})
})