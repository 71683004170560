import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getRemainingSMS, getSMSStatistics } from 'actions/sms';

import { DataList, Spinner } from 'UI';
import Block, { BlockHeader, BlockContent } from "UI/Elements/Block";
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';

import { translate } from '@haldor/ui';

class SMSAdmin extends Component {

	componentDidMount = () => {
		this.props.getRemainingSMS();
		this.props.getSMSStatistics();
	}

	renderSchoolRow = (school) => {
		let statistic = null;
		if (this.props.statistics != null) {
			statistic = this.props.statistics.schools.find(_school => {
				return _school.schoolId == school.id
			});
		}

		return <RowItem key={school.id}>
			<RowCell title={this.props.translate('Name')}>
				{school.title}
			</RowCell>

			<RowCell title={this.props.translate('Sms-balance')}>
				{statistic != null ?
					statistic.totalBoughtAmount - statistic.totalSMSSent
					: null}
			</RowCell>

			<RowCell title={this.props.translate('Sent sms')}>
				{statistic != null ?
					statistic.totalSMSSent
					: null}
			</RowCell>
		</RowItem>
	}

	render() {
		const schools = this.props.currentUser.schools;

		if (schools != null) {
			schools.sort((a, b) => a.title.localeCompare(b.title));
		}

		return <div>
			<DataList
				data={schools}
				renderRow={this.renderSchoolRow}
				emptyData={() => (
					<div>
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					</div>
				)}
			/>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		remaining: state.SMS.remaining,
		statistics: state.SMS.statistics,
	}
}

export default connect(mapStateToProps, {
	getRemainingSMS,
	getSMSStatistics,
})(SMSAdmin);