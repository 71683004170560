import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, translate } from '@haldor/ui';
import * as microsoftTeams from "@microsoft/teams-js";

import msal from 'lib/msal';
import api from 'lib/api';
import getEnvironment from 'lib/env';
import { appInsights } from 'lib/appInsights';

import schoolHandling from 'helpers/schoolHandling';

import { setOnboardingExpireTokenIn, setOnboardingToken } from 'lib/msal';

import { postData } from 'actions';
import { getTenant, getMe, setUser, addUser } from 'actions/user';
import { getSectionByGraphId, informAboutTabData } from 'actions/sections';
import { getServices, isConsentPresent, createTenant, createTenantServices, getAdminInvitations, getTenantProducts, updateTenant, updateTenantProducts, setSelectedServices } from 'actions/services';
import { getSchoolsDataForSections, getSchoolByExternalId } from 'actions/schools';

import Input from 'components/Inputs/input';
import { Spinner } from 'UI';
import User from '_class/User';

class TabConfigure extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tab: 'HALDOR_PLAN_COMBO',
			title: 'Plans',
			isLoading: true,
			loadingGroupData: true,
			showErrorLogin: false,
			hideSchoolInput: true,
			groupId: null,
			schoolId: 0,
			groupType: 'EDUCATION_GROUP',
			user: {
				email: null,
				firstName: null,
				lastName: null,
				userRoles: [],
			},
			fieldValidation: {
				valid: null,
				text: '',
				hideForms: false
			},
		};

		this.onTitleChange.bind(this);
	}

	UNSAFE_componentWillMount() {
		document.body.style.overflow = 'hidden';
		document.body.style.backgroundColor = 'white';

		this.initTabConfigure();
	}

	initTabConfigure() {

		/**
		* 
		* 1. Check for consent
		* 2. Check for tenant, if not there, create
		* 	- When tenant is created, default schools is created. But backend returns ID = 0 on default school
		* 3. Check for user, if not there create user and roles (default roles according to onboarding)
		* 4. Check if group is alreade connected, if it is, get school ID and set locally
		* 5. If group is not connected, Get userinfo, including roles and set current school in frontend based on teacher role
		*/

		let TenantHasAdminConsent = false;
		this.setState({ showErrorLogin: false });

		microsoftTeams.app.getContext().then((context) => {
			if (context != null) {
				this.props.isConsentPresent(context.user.tenant.id).then(() => {
					TenantHasAdminConsent = this.props.isAppConsented;
					api.post('log/Apps/CoursePlan/Context/Configure', context);
					this.setState({ groupId: context.team.groupId });

					microsoftTeams.pages.getConfig().then(settings => {
						if (settings != null && settings.suggestedDisplayName != null) {
							this.setState({ title: settings.suggestedDisplayName }, () => {
								this.onValidate();
							});
						}
					});

					microsoftTeams.pages.config.registerOnSaveHandler((saveEvent) => {
						microsoftTeams.app.getContext().then(async (context) => {
							if (this.props.group == null || this.props.group.id == 0) {
								if (this.state.schoolId != 0) {
									let group = {
										title: context.team.displayName,
										graphId: context.team.groupId,
										schoolId: this.state.schoolId,
										type: this.state.groupType,
									};

									await this.props.postData(group, 'sections');

									if (group.schoolId != 0) {
										schoolHandling.setSchoolOnUser(this.props.currentUser, group.schoolId);
									}
								}
							}

							await this.props.informAboutTabData(context.team.groupId, 'HALDOR_PLANNING');
							saveEvent.notifySuccess();
						});
					});
					microsoftTeams.authentication.authenticate({
						url: window.location.origin + (TenantHasAdminConsent ? '/Auth?teams=yes' : '/teams/he/authentication?haldor_login=true&teams=yes'),
					})
						.then((result) => {
							console.log('success popup result', result);
							let data = localStorage.getItem(result);
							localStorage.removeItem(result);
							let tokenResult = JSON.parse(data);

							if (!TenantHasAdminConsent) {
								setOnboardingExpireTokenIn(result.expires_in);
								setOnboardingToken(result.access_token);
							}
							if (msal.getAllAccounts().length > 0 || !TenantHasAdminConsent) {
								this.validateTenant(context).then((returnedTenant) => {
									this.validateUser().then(() => {
										this.validateCreatedTenant(context, TenantHasAdminConsent);
									})
								})
							}
						})
						.catch((error) => {
							this.setState({ showErrorLogin: true });
							console.warn('fail popup error LandingPage', error);
						});
				});
			}
		});
	}

	validateTenant(context) {
		return new Promise((resolve) => {
			this.props.getTenant().then(() => {
				if (this.props.tenant == null || this.props.tenant == "") {
					this.retrieveUser(context);
					const { userPrincipalName } = context;
					let tenantDomain = null;

					if (userPrincipalName != null && userPrincipalName.indexOf("@") > -1) {
						tenantDomain = userPrincipalName.substring(userPrincipalName.indexOf("@") + 1).toLowerCase();
					}
					if (tenantDomain == null && context.user.loginHint != null && context.user.loginHint.indexOf("@") > -1) {
						tenantDomain = context.user.loginHint.substring(context.user.loginHint.indexOf("@") + 1).toLowerCase();
					}

					let tenant = {
						title: "",
						tenantId: context.user.tenant.id,
						tenantDomain: tenantDomain,
						tenantSPOUrl: "https://" + context.sharePointSite.teamSiteDomain,
					};

					this.props.createTenant(tenant).then(() => {
						this.props.getTenant().then(() => {
							resolve(this.props.tenant);
						})
					})
				}
				else {
					resolve(this.props.tenant);
				}
			})
		})
	}

	validateUser() {
		return new Promise((resolve) => {
			this.props.addUser(this.state.user).then(() => {
				// No meaning in getting the user since we cannot be sure it is created for the current tenant and call to get tenant is not returning any schools so we cannot compare
				// If user exists, it will not be created again, roles will also not be created again, but there will be duplicate logs
				// School-array is not returned from API either, so we need to also check userSchools
				this.getUserData().then(() => {
					const { currentUser } = this.props;
					if (currentUser != null) {
						if (currentUser.schools != null && currentUser.schools.length > 0 && currentUser.schools[0].id > 0) {
							this.setState({ schoolId: currentUser.schools[0].id });
							schoolHandling.setSchoolOnUser(currentUser, currentUser.schools[0].id);
						}
						else if (currentUser.userSchools != null && currentUser.userSchools.length > 0) {
							this.setState({ schoolId: parseInt(currentUser.userSchools[0]) });
							schoolHandling.setSchoolOnUser(currentUser, parseInt(currentUser.userSchools[0]));
						}
					}
					resolve(1);
				})
			})
		})
	}
	validateCreatedTenant(context, TenantHasAdminConsent) {
		return new Promise((resolve) => {
			this.getTabConfigInformation(context).then(() => {
				let promises = [];
				if (TenantHasAdminConsent) {
					promises.push(this.props.getSectionByGraphId(context.team.groupId));
				}
				promises.push(this.props.getTenantProducts());
				promises.push(this.props.getServices());
				Promise.all(promises).then(async () => {
					await this.CheckIfPlanningAndSetServices(context.user.tenant.id);
					if (this.props.group != null) {
						if (this.props.group.schoolID != null && this.props.group.schoolID > 0) {
							this.setState({ schoolId: this.props.group.schoolID, loadingGroupData: false });

							// return false;
						}
					}
					this.setState({ loadingGroupData: false });

					this.props.getTenant().then(() => {
						this.onValidate();
						this.setState({ isLoading: false });
						resolve();
					});
				});
			});
		});
	}
	retrieveUser(context) {
		let user = this.state.user;

		if (context.user.userPrincipalName) {
			user.email = context.user.userPrincipalName;
			user.firstName = context.user.userPrincipalName.split('@')[0];

			if (user.firstName.indexOf(".") > -1) {
				let firstName = user.firstName.split('.')[0];
				let lastName = user.firstName.split('.')[1];

				user.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
				user.lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
			}
		}

		if (context.user.licenseType != null) {
			user.userRoles.push(context.user.licenseType);
		}

		this.setState({ user: user });
	}

	/**
	 * Method to add Products and Services for Haldor Planning
	 */
	CheckIfPlanningAndSetServices(tenantId) {
		return new Promise((resolve) => {
			let id = getEnvironment().id;
			if (id == 'haldor_planning') {

				var tenantServices = [];
				var selectedProducts = [];

				var productDashboard = this.props.products.find((product) => {
					return product.name === "Haldor Dashboard";
				});
				selectedProducts.push(productDashboard);
				productDashboard.services.forEach((service) => {
					if (!tenantServices.find(service => { return service.ServiceId == service.id })) {
						tenantServices.push({ ServiceId: service.id });
					}
				})
				var productPlanning = this.props.products.find((product) => {
					return product.name === "Haldor Planning";
				});
				selectedProducts.push(productPlanning);
				productPlanning.services.forEach((service) => {
					if (!tenantServices.find(service => { return service.ServiceId == service.id })) {
						tenantServices.push({ ServiceId: service.id });
					}
				})

				this.props.updateTenantProducts(selectedProducts).then(() => {
					this.props.getTenantProducts().then(() => {
						appInsights.trackTrace({ message: "Onboarding H.P. | trying update tenantServices" }, {
							services: tenantServices,
							tenant: this.props.tenant
						});

						// This is important since our system is depended on services
						this.props.createTenantServices(tenantServices).then(() => {
							//this.props.saveSelectedProductsAsNotes(false);
							resolve(tenantId);
						});
					});
				});
			}
		});
	}

	setFieldValidation = (message, hideForms = false) => {
		this.state.fieldValidation.valid = false;
		this.state.fieldValidation.text = message;
		this.state.fieldValidation.hideForms = hideForms;

		this.setState({ fieldValidation: this.state.fieldValidation, loadingGroupData: false });
	}

	removeFieldValidation = () => {
		this.state.fieldValidation.valid = true;
		this.state.fieldValidation.hideForms = false;
		this.state.fieldValidation.text = '';

		this.setState({ fieldValidation: this.state.fieldValidation });
	}

	getUserData = () => {
		return new Promise((resolve, reject) => {
			this.props.getMe().then(() => {
				const { currentUser } = this.props;

				if (currentUser != null && currentUser.schools != null) {
					this.props.getSchoolsDataForSections().then(() => {
						if (currentUser.schools.length == 1 && currentUser.schools[0].id > 0) {
							this.setState({ schoolId: currentUser.schools[0].id, disableSchoolSelector: true });
							schoolHandling.setSchoolOnUser(currentUser, currentUser.schools[0].id);
						}
					});
				}

				resolve();
			})
		});
	}

	selectSchool = (event) => {
		this.setState({ schoolId: event.target.value }, () => {
			this.onValidate();
		});
	}

	getTabUrl() {
		const tabId = this.state.tab;
		let url = '';

		if (this.props.tenant == null) {
			return '';
		}
		let selectedTab = this.props.tenant.tabs.find(tab => {
			return tab.id == tabId;
		});
		if (selectedTab != null) {
			url = window.location.origin + selectedTab.contentUrl;
		}
		return url;
	}

	async getTabConfigInformation(context) {
		const { currentUser } = this.props;

		api.post('log/Apps/HaldorEdu/Context/Configure', context)

		this.setState({ groupId: context.team.groupId });

		await this.props.getSectionByGraphId(context.team.groupId, 'GRAPH_OBJECT;');

		let graphSection = this.props.group;

		if (graphSection == null || currentUser == null) {
			this.setFieldValidation(this.props.translate("Whoops, something went wrong!"), true);
			return false;
		}

		if (graphSection.type == "MENTOR_GROUP") {
			this.setFieldValidation(this.props.translate("You can't add a tab because the group is connected as mentor group"), true);
			return false;
		}

		if (graphSection.externalSchoolId != null) {
			const school = currentUser.schools?.find((school) => {
				return school.externalId == graphSection.externalSchoolId
			});

			if (school != null && school.Id > 0) {
				this.setState({ schoolId: school.id, disableSchoolSelector: true }, () => {
					schoolHandling.setSchoolOnUser(this.props.currentUser, school.id);
					this.onValidate();
				});
			} else {
				if (graphSection.externalSchoolId != null) {
					await this.props.getSchoolByExternalId(graphSection.externalSchoolId);

					if (this.props.school != null) {
						this.setFieldValidation(this.props.translate("You can't add a tab because you don't have permission to the group {{1}} in the school {{2}}.", graphSection.title, this.props.school.title), true);
						return false;
					} else {
						this.setFieldValidation(this.props.translate("You can't add a tab because you don't have permission to the group {{1}}", graphSection.title), true);
						return false;
					}
				}
			}
		}

		if (graphSection.schoolID != null && graphSection.schoolID > 0) {
			this.setState({
				schoolId: graphSection.schoolID,
				loadingGroupData: false,
				disableSchoolSelector: true
			}, () => {
				schoolHandling.setSchoolOnUser(this.props.currentUser, graphSection.schoolID);
				this.onValidate();
			});

			return false;
		}

		this.setState({ loadingGroupData: false });
	}
	onTitleChange(event) {
		this.setState({ title: event.target.value }, () => {
			this.onValidate();
		});
	}

	onValidate = () => {
		const name = this.state.title;
		const schoolId = this.state.schoolId;
		const tab = this.getTabUrl();

		if (name != '' && name != null && tab != '' && schoolId > 0) {
			microsoftTeams.pages.config.setConfig({
				contentUrl: tab,
				suggestedDisplayName: name,
				websiteUrl: 'https://planning.haldoredu.com',
			});

			microsoftTeams.pages.config.setValidityState(true);
		}
	}

	renderForm() {
		return (
			<div className="form-container form-create_task">
				<form className="form form-component">
					<h1>{this.props.translate('planning')}</h1>
					<div>{this.props.translate('configure-welcome-to-planning')}</div>

					<div style={{ clear: 'both' }} />

					<Input
						name="title"
						type="text"
						placeholder={this.props.translate('title')}
						maxLength="199"
						label={this.props.translate('title')}
						value={this.state.title}
						onChange={this.onTitleChange.bind(this)}
					/>

					{this.props.group == null && !this.state.hideSchoolInput ?
						<div className="form-row">
							<label>
								{this.props.translate('school')}
							</label>

							<div className="select">
								<select
									value={this.state.schoolId}
									onChange={this.selectSchool}
								>
									<option value="0">
										{this.props.translate('pick-a-school')}
									</option>

									{this.props.currentUser?.schools.map(school => {
										return <option key={school.id} value={school.id}>
											{school.title}
										</option>
									})}
								</select>
							</div>
						</div>
						: null}
				</form>
			</div>
		);
	}

	renderNotACustomer() {
		return (
			<div>
				{this.props.translate('not-a-customer')}
				<a href="https://portal.haldor.se">https://portal.haldor.se</a>
			</div>
		);
	}

	render = () => {
		return (
			<div>
				{this.state.isLoading || this.state.loadingGroupData ?
					<div>
						{this.state.showErrorLogin ? <div style={{ backgroundColor: '#FFF', boxShadow: '0 5px 15px 0 rgb(0 0 0 / 5%)', borderRadius: '3px', padding: '1.4rem 1.7rem', marginBottom: '3rem', textAlign: 'center' }}>
							{this.props.translate('Remember to allow popups in order to complete the login, you may need to click the Login button twice.')}
							<Button style={{ display: 'block', margin: 'auto', marginTop: '0.5rem' }} type="secondary" onClick={this.initTabConfigure.bind(this)}>
								{this.props.translate('log-in')}
							</Button>
						</div> : <Spinner center />}
					</div>
					:
					this.props.services.haldorCoursePlanning ?
						this.renderForm()
						:
						this.renderNotACustomer()
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		services: state.Services.availableServices,
		group: state.sections.graphSection,
		currentUser: state.user.currentUser,
		schoolsData: state.School.schoolsDataForSection,
		school: state.School.school,
		isAppConsented: state.Services.isAppConsented?.data,
		products: state.Services.availableProducts,
	};
}

export default connect(mapStateToProps, {
	getServices,
	getTenant,
	getSectionByGraphId,
	getTenantProducts,
	getSchoolsDataForSections,
	getSchoolByExternalId,
	setSelectedServices,
	updateTenant,
	updateTenantProducts,
	createTenantServices,
	postData,
	informAboutTabData,
	getMe,
	setUser,
	isConsentPresent,
	addUser,
	createTenant
})(TabConfigure);
