import React from 'react';
import { Icon } from '@haldor/ui';
import Moment from 'moment';

const TimeLineButton = (props) => {
	const handleClick = () => {
		props.toggleTimeButton();
	};

	return (
		<div className='timeLine-button'>
			<div className='add-placeholder-button'>
				<div className='dots'>
					<div></div>
				</div>
				<button className='content-container' onClick={handleClick}>
					<span className='icon-container'>
						{props.open ? <Icon name='minus' /> : <Icon name='plus' />}
					</span>
					<span className='title'>{Moment.utc(props.time).local().format('LT')}</span>
				</button>
			</div>
		</div>
	);
};

export default TimeLineButton;
