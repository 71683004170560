import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import User from '_class/User';

import FromNow from 'components/Presentation/Date/FromNow';
import Status from 'components/Presentation/Status';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { ColorLabel, ColorLabelContainer } from 'UI';
import { Icon, Flex, translate } from '@haldor/ui';
import { Link } from 'react-router-dom';
import { getRootUrl } from 'helpers/url';
import Tooltip from '@mui/material/Tooltip';

class DisplayAssignment extends Component {
	renderTeamsAssignment = () => {
		const { assignment } = this.props;

		return (
			<Flex>
				<div style={{ flex: 1 }}>
					<span className='title'>{assignment.displayName}</span>
				</div>

				<div className='assignment-meta'>
					<div>
						<FromNow>{assignment.dueDateTime}</FromNow>

						<div className='card-meta' style={{ marginRight: 5 }}>
							<span>Teams</span>
						</div>


						{this.props.remove != null ? (
							<span className='delete-resource' onClick={() => this.props.remove()}>
								<Icon name='Bin' />
							</span>
						) : null}
					</div>
				</div>
			</Flex>
		);
	};

	renderHaldorAssignment = () => {
		const { translate, assignment } = this.props;
		const user = new User(this.props.currentUser);
		let addedColorDots = [];

		return (
			<Flex>
				{this.props.useLink ?
					<Link to={`${getRootUrl()}assignment/${assignment?.externalID ?? assignment.id}`} className='cardLink'>
						<div style={{ flex: 1 }}>
							{assignment.courses != null && assignment.courses.length > 0 ? (
								<ColorLabelContainer>
									{assignment.courses.map((course) => {
										if (addedColorDots.find((t) => t == course.id) == null) {
											let text = course.title;
											text = course.year != null ? text + ' ' + course.year : text;
											addedColorDots.push(course.id);

											return (
												<ColorLabel
													key={course.id}
													tooltip={text}
													color={course.colorCode}
												/>
											);
										}
									})}
								</ColorLabelContainer>
							) : null}

							<span className='title'>{assignment.title}</span>
							<div style={{ fontSize: 'var(--size-14)' }}>
								{assignment.section != null ? assignment.section.title : ''}
							</div>
						</div>
					</Link>
					:
					<div style={{ flex: 1 }}>
						{assignment.courses != null && assignment.courses.length > 0 ? (
							<ColorLabelContainer>
								{assignment.courses.map((course) => {
									if (addedColorDots.find((t) => t == course.id) == null) {
										let text = course.title;
										text = course.year != null ? text + ' ' + course.year : text;
										addedColorDots.push(course.id);

										return (
											<ColorLabel
												key={course.id}
												tooltip={text}
												color={course.colorCode}
											/>
										);
									}
								})}
							</ColorLabelContainer>
						) : null}

						<span className='title'>{assignment.title}</span>
						<div style={{ fontSize: 'var(--size-14)' }}>
							{assignment.section != null ? assignment.section.title : ''}
						</div>
					</div>
				}

				<div className='assignment-meta'>
					<div>
						{assignment.autoAssignStudents ? <Tooltip placement="top" arrow title="[missing translate] Kommer automatiskt att tilldelas elever som tillkommer i gruppen"><div className="card-meta"><PersonAddIcon sx={{ fontSize: '15px' }} />[missing translate] Automatisk tilldelning</div></Tooltip> : null}


						{assignment.status === 'ASSIGNMENT_LOCKED' ? (
							<div className='card-meta' style={{ marginRight: 5 }}>
								<Icon name='Lock' />
								{this.props.translate('Locked')}
								{user.isTeacher() ?
									<Fragment>
										{' '}{assignment?.lockedWorkspaces === 0 ? 0 : assignment?.lockedWorkspaces} /{' '}
										{assignment?.totalTasks}
									</Fragment>
									: null}
							</div>
						) : null}

						{assignment.published == '0001-01-01T00:00:00' ? (
							<div className='card-meta'>
								{this.props.translate('Draft')}
							</div>
						) : null}

						{assignment.created != '0001-01-01T00:00:00' ? (
							<FromNow>{assignment.dueDate}</FromNow>
						) : null}

						{assignment.assessmentId != null ? (
							<div className='card-meta'>{this.props.translate('assessed')}</div>
						) : null}

						{assignment.externalID != null ? (
							<div className='card-meta' style={{ marginRight: 5 }}>
								<span>Teams</span>
							</div>
						) : assignment.created == '0001-01-01T00:00:00' ? (
							<span className='badge-primary'>
								{this.props.translate('task-is-creating')}
							</span>
						) : (
							<div className='card-meta'>
								<Status type='assignmentTypes' id={assignment.assignmentType} />
							</div>
						)}

						{this.props.customPermission ?
							this.props.remove != null && this.props.permission ? (
								<span className='delete-resource' onClick={() => this.props.remove()}>
									<Icon name='Bin' />
								</span>
							) : null
							:
							this.props.remove != null ? (
								<span className='delete-resource' onClick={() => this.props.remove()}>
									<Icon name='Bin' />
								</span>
							) : null}
					</div>

					<div className='submission-info'>
						{assignment.submittedTasks != null && !user.isStudent() && assignment.totalTasks > 0 ? (
							<div
								data-tooltip={
									translate('submitted-list-title') + ' ' + translate('tasks')
								}
								className='card-meta'
							>
								<div style={{ display: 'inline-block', width: 11, height: 19 }}>
									<Icon name='File' />
								</div>

								<div style={{ display: 'inline-block', marginLeft: 7 }}>
									{translate('submitted-list-title')} {assignment.submittedTasks} /{' '}
									{assignment.totalTasks}
								</div>
							</div>
						) : null}

						{assignment.publishedAssessments != null && !user.isStudent() && assignment.totalTasks > 0 ? (
							<div data-tooltip={translate('published-assessments')} className='card-meta'>
								{translate('assessed-tasks')} {assignment.publishedAssessments}
							</div>
						) : null}
					</div>
				</div>
			</Flex>
		);
	};

	render() {
		if (this.props.assignment['@odata.type'] == 'haldor.assignment.block.teamsassignment') {
			return this.renderTeamsAssignment();
		}

		return this.renderHaldorAssignment();
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(DisplayAssignment);
