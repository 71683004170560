import React from 'react';
import { Button, Haldor__Block__Plugin, Flex } from '@haldor/ui';

import { ASSESSMENT_TYPES } from '../AssessmentTypes';

class AddAssessmentBlockButton_Plugin extends Haldor__Block__Plugin {

	constructor(props) {
		super(props);

		this.name = 'AddAssessmentBlockButton_Plugin';
	}

	onButtonPress = async (type, event) => {
		event.preventDefault();

		switch (type) {
			case ASSESSMENT_TYPES.TEACHER:
				await this.editor.addBlock('Haldor.Blocks.AssessmentBlock');
				break;
			case ASSESSMENT_TYPES.PEER:
				await this.editor.addBlock('Haldor.Blocks.AssessmentBlock.Peer');
				break;
			case ASSESSMENT_TYPES.SELF:
				await this.editor.addBlock('Haldor.Blocks.AssessmentBlock.Self');
				break;
			default:
				break;
		}

		var arrayLength = this.editor.getBlocks().length;
		this.editor.getBlocks()[arrayLength - 1].resources.push({ '@odata.type': 'assessmenttype', 'value': type })
	}

	init = () => {
		const { editor } = this;
		const translate = editor.getProp('translate');

		editor.renderer.registerPosition('after_blocks', () => {
			return (
				<div className='add-assessment-button'>
					<Flex center>
						<Button onClick={(e) => { this.onButtonPress(ASSESSMENT_TYPES.TEACHER, e) }} type="secondary" style={{ marginRight: '.55rem' }}>
							{translate('Add teacher assessment')}
						</Button>

						<Button onClick={(e) => { this.onButtonPress(ASSESSMENT_TYPES.PEER, e) }} type="secondary" style={{ marginRight: '.55rem' }}>
							{translate('Add peer review')}
						</Button>

						<Button onClick={(e) => { this.onButtonPress(ASSESSMENT_TYPES.SELF, e) }} type="secondary" >
							{translate('Add self assessment')}
						</Button>
					</Flex>
				</div>
			)
		})
	}

}

export default AddAssessmentBlockButton_Plugin;