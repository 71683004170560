import {
  GROUP_INFO,
  USER_INFO_BY_ID,
} from '../actions/progress';


const INITIAL_STATE = {
  group: null,
  user: null,
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GROUP_INFO:
      return { ...state, group: action.payload };
    case USER_INFO_BY_ID:
      return { ...state, user: action.payload };
    default:
      return state;
  }
}
