import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as microsoftTeams from "@microsoft/teams-js";

import msal, { loginRequest, getUser, setUser } from 'lib/msal';
import api from 'lib/api';
import localstoragefunc from 'helpers/localstorage';
import getEnvironment from 'lib/env';

import { Spinner } from 'UI';

export default class Authentication extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logged: false,
		};
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	runTeamsLogin() {
		microsoftTeams.app.getContext().then((context) => {
			let authorizeEndpoint = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + getEnvironment().apiClientId + "&response_type=token&scope=user.read&redirect_uri=" + window.location.origin + "/teams/he/authentication/api"
			window.location.assign(authorizeEndpoint);
		});
	}

	runTeamsAdminConsent(url) {
		microsoftTeams.app.getContext().then((context) => {
			let authorizeEndpoint = url;
			window.location.assign(authorizeEndpoint);
		});
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	componentDidMount = () => {
		microsoftTeams.app.initialize();

		if (window.location.hash.indexOf('access_token') > -1 && window.location.search.indexOf('admin_consent') == -1) {
			window.opener.onPopupDataReceived(true);
			window.close();
		}

		if (window.location.search.indexOf('code') > -1 && window.location.search.indexOf('error') == -1) {
			msal.handleRedirectPromise().then((response) => {
				window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" + getEnvironment().clientId + "&response_type=id_token&scope=user.read%20mail.send&redirect_uri=" + window.location.origin + "/teams/he/auth";
			})
				.catch((reason) => {
					microsoftTeams.authentication.notifyFailure(reason);
				});

			window.opener.onPopupDataReceived(true);
			window.close();
			return true;
		}

		if (window.location.search.indexOf('admin_consent') > -1) {
			var adminConsentResult = this.getParameterByName('admin_consent');
			var state = this.getParameterByName('state');

			var info = {
				consent: adminConsentResult.toLowerCase() == 'true',
				state: state
			}

			var usingTeams = localStorage.getItem('adminConsentTeams')

			if (usingTeams != null) {
				microsoftTeams.app.getContext().then((context) => {
					microsoftTeams.authentication.notifySuccess(info);
				});

				localStorage.removeItem('adminConsentTeams');
			} else {
				window.opener.onPopupDataReceived(info);
				window.close();
			}
		}

		if (window.location.search.indexOf('error') > -1) {
			var adminConsentResult = this.getParameterByName('error_subcode');
			var state = this.getParameterByName('state');

			var info = {
				consent: false,
				state: state
			}

			if (usingTeams != null) {
				microsoftTeams.app.getContext().then((context) => {
					microsoftTeams.authentication.notifyFailure(info);
				});

				localStorage.removeItem('adminConsentTeams');
			} else {
				window.opener.onPopupDataReceived(info);
				window.close();
			}
		}

		if (window.location.search.indexOf('consent_url') > -1) {
			var url = this.getParameterByName('consent_url');
			var state = this.getParameterByName('state');
			var redirect_uri = this.getParameterByName('redirect_uri');
			this.runTeamsAdminConsent(url + "&state=" + state + "&redirect_uri=" + redirect_uri);
		}

		if (window.location.search.indexOf('haldor_login') > -1) {
			this.runTeamsLogin();
		}
	}

	render() {
		return (
			<div>
				<Spinner center />
			</div>
		);
	}
}
