import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import User from '_class/User';

import { PRESCHOOL } from 'constants/schoolTypes';
import { isUserTeacher } from 'helpers/user';

import { getDocumentsByPlanId } from 'actions/documents';
import { getFileUrl } from 'actions/files';
import { fetch_preschoolgoals } from 'actions/preschoolform';

import Documentation from 'containers/Forms/Documentation';
import Modal from 'containers/Modals/Modal';

import LoadingCards from 'components/Cards/LoadingCard';
import Date from 'components/Presentation/Date/Date';
import SectionTitle from 'components/Presentation/SectionTitle';

import Block, { BlockWrapper } from 'UI/Elements/Block';
import { Button } from '@haldor/ui';
import FromNow from 'components/Presentation/Date/FromNow';

class PlanPosts extends Component {

	constructor(props) {
		super(props);

		this.state = {
			documentModal: false,
			loading: true,
		};
	}

	componentDidMount = () => {
		const user = new User(this.props.currentUser);
		const isPreschool = user.getActiveSchool() != null && user.getActiveSchool().type == PRESCHOOL;

		this.setState({ loading: true });
		this.props.getDocumentsByPlanId(this.props.planId).then(() => {
			this.setState({ loading: false });
		});

		if (this.props.preschoolGoals.length < 1 && isPreschool) {
			this.props.fetch_preschoolgoals();
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.planId != this.props.planId) {
			this.setState({ loading: true });
			this.props.getDocumentsByPlanId(nextProps.planId).then(() => {
				this.setState({ loading: false });
			});
		}
	}

	toggleDocumentModal = () => {
		this.setState({ documentModal: !this.state.documentModal });
	}

	renderCreateButton = () => {
		const isTeacher = isUserTeacher(this.props.currentUser);

		if (isTeacher) {
			return (
				<div className="create-container" style={{ display: 'inline-block' }}>
					<Button onClick={this.toggleDocumentModal} type="secondary">
						{this.props.translate('create-new')}
					</Button>
				</div>
			);
		} else {
			return null;
		}
	}

	content = (document) => {
		let strippedDescription = '';

		if (document.description != null) {
			strippedDescription = document.description.replace(/(<([^>]+)>)/ig, '');
			if (strippedDescription.length > 99) {
				strippedDescription = strippedDescription.slice(0, 100) + '…';
			}
		}

		return (
			<div>
				<h3 style={{ fontSize: '1.25em' }}>{document.title}</h3>

				<p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
					dangerouslySetInnerHTML={{ __html: strippedDescription }}
				/>
			</div>
		);
	}

	headerContent = (document) => {
		return (
			<div>
				<div style={{ float: 'left' }}>
					{document.author != null ?
						typeof document.author == 'string' ?
							document.author
							:
							`${document.author.firstName != null ? document.author.firstName : ''} ${document.author.lastName != null ? document.author.lastName : ''}`
						: ' '}
				</div>

				<div style={{ float: 'right' }}>
					{document.published != null ?
						Moment.utc(document.published).isAfter(Moment()) ?
							<FromNow>{Moment.utc(document.published).local().format()}</FromNow>
							:
							<Date>{Moment.utc(document.published).local()}</Date>
						: null}
				</div>

				<div className="clearfix" />
			</div>
		);
	}

	footerContent = (document) => {
		const { preschoolGoals } = this.props;

		if (document.associatedGoals && document.associatedGoals.length > 0) {
			let groups = [];

			if (preschoolGoals && preschoolGoals.length > 0) {
				preschoolGoals.map(goal => {
					const inDocument = document.associatedGoals.find(function (goalOb) {
						return goalOb.goalId == goal.id;
					});

					if (typeof (inDocument) != 'undefined') {
						let inArray = groups.findIndex(i => i.name == goal.group);

						if (inArray > -1) {
							return groups[inArray].count++;
						} else {
							let group = {
								'name': goal.group,
								'colorCode': goal.colorCode,
								'count': 1,
							};

							return groups.push(group);
						}
					}
				});
			}

			return (
				<div>
					<div>{this.props.translate('goals')}</div>

					{groups.length > 0 ?
						<div className="goal-preview-container">
							{groups.map(group => {
								return (
									<div key={`goal-${group.name}`} style={{ backgroundColor: group.colorCode }} className="goal-preview">
										{group.name} ({group.count})
									</div>
								)
							})}
						</div>
						: null}
				</div>
			);
		} else {
			return null;
		}
	}

	renderDocument = (document) => {
		let link = '';
		let file = null;

		link = '/preschooldocument/' + document.id;

		if (document.associatedFiles != null && document.associatedFiles.length > 0) {
			file = document.associatedFiles.find(file => {
				return file.type.indexOf('image') > -1;
			})
		}

		return (
			<Block
				headerContent={this.headerContent(document)}
				image={file}
				content={this.content(document)}
				footerContent={this.footerContent(document)}
				link={link}
			/>
		);
	}

	renderSectionContent = () => {
		const { documents } = this.props;

		if (documents.length > 0) {
			return (
				<BlockWrapper cols={3}>
					{documents ? documents.map((document, index) => {
						return this.renderDocument(document)
					}) : null}
				</BlockWrapper>
			);
		} else {
			return (
				<h4 style={{ textAlign: 'center' }}>
					{this.props.translate('no-items-found')}
				</h4>
			);
		}
	}

	render() {
		const { loading } = this.state;

		const isTeacher = isUserTeacher(this.props.currentUser);
		const relations = {
			relationships: [
				{
					referenceType: 'SECTION',
					referenceId: this.props.sectionId,
				},
			],
		};

		return (
			<div className="single-section section preschooldocuments">
				{isTeacher ?
					<Modal
						isOpen={this.state.documentModal}
						onClose={this.toggleDocumentModal}
						title={this.props.translate('create-new-documentation')}
					>
						<Documentation
							planId={this.props.planId}
							values={relations}
							onModalClose={this.toggleDocumentModal}
							section={this.props.section}
						/>
					</Modal>
					: null}

				<SectionTitle>
					{this.props.translate('preschooldocument-posts')}

					{this.renderCreateButton()}

					<Link to="/posts" className="see-more">
						{this.props.translate('show-all-posts')}
					</Link>
				</SectionTitle>

				<div className="posts-section-container">
					<div>
						{loading ?
							<LoadingCards count={3} />
							:
							this.renderSectionContent()
						}
					</div>

					<div style={{ clear: 'both' }} />
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		documents: state.Documents.planDocuments,
		preschoolGoals: state.PreschoolGoals.preschoolGoals,
		groups: state.sections.educationGroups,
	};
}

export default connect(mapStateToProps, {
	getDocumentsByPlanId,
	getFileUrl,
	fetch_preschoolgoals,
})(PlanPosts);
