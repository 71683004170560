import {
	GET_FORECAST,
	GET_CALENDAR_ATTENDENCE,
	SUBMIT_ABSENCE,
	UPDATE_ABSENCE,
	GET_USER_ATTENDANCE_STATS,
	GET_USER_ATTENDANCE,
	CLEAR_USER_ATTENDANCE,
	CLEAR_FORECAST,
	DELETE_REPORTED_ATTENDANCE,
} from 'actions/absence';

import Moment from 'moment';

const INITIAL_STATE = {
	forecast: null,
	calendarAttendence: null,
	submitResult: false,
	userStatistics: null,
	userAttendance: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_FORECAST:
			return { ...state, forecast: action.payload };

		case CLEAR_FORECAST:
			return { ...state, forecast: null };

		case SUBMIT_ABSENCE:
			return { ...state, submitResult: action.payload };

		case UPDATE_ABSENCE:
			return { ...state, submitResult: action.payload };

		case GET_CALENDAR_ATTENDENCE:
			return { ...state, calendarAttendence: action.payload };

		case GET_USER_ATTENDANCE:
			if (action.payload != null) {
				const attendance = action.payload;

				attendance.sort((a, b) => {
					return new Date(b.startTime) - new Date(a.startTime);
				});

				return { ...state, userAttendance: attendance };
			}

			return state;

		case CLEAR_USER_ATTENDANCE:
			return { ...state, userAttendance: null };

		case GET_USER_ATTENDANCE_STATS:
			let data = {
				labels: [],
				datasets: [],
			};

			let absent = {
				borderColor: "rgba(242, 82, 82, 1)",
				pointBackgroundColor: "rgba(242, 82, 82, 1)",
				backgroundColor: "rgba(242, 82, 82, 1)",
				label: 'minutes-absence',
				data: [],
			};

			let attending = {
				borderColor: "rgba(25, 219, 108, 1)",
				pointBackgroundColor: "rgba(25, 219, 108, 1)",
				backgroundColor: "rgba(25, 219, 108, 1)",
				label: 'minutes-attending',
				data: [],
			};

			let notReported = {
				borderColor: "rgba(126, 195, 221, 1)",
				pointBackgroundColor: "rgba(126, 195, 221, 1)",
				backgroundColor: "rgba(126, 195, 221, 1)",
				label: 'minutes-not-reported',
				data: [],
			};

			action.payload.map(date => {
				data.labels.push(Moment(date.date).format('YYYY-MM-DD'));

				absent.data.push(date.absent);
				attending.data.push(date.attending);
				notReported.data.push(date.notReported);
			});

			data.datasets.push(absent);
			data.datasets.push(attending);
			data.datasets.push(notReported);

			return { ...state, userStatistics: data };

		case DELETE_REPORTED_ATTENDANCE:
			return { ...state, calendarAttendance: null };

		default:
			return state;
	}
}
