import React, { Component } from 'react';
import Card, { CardFooter, CardTitle } from '../../Cards/Card';

export default class Course extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: this.props.course.hasOwnProperty('colorCode') ? this.props.course.colorCode : '',
		};
	}

	render() {
		return (
			<Card
				type="course"
				id={this.props.course.id}
				color={this.state.color}
				target={`/Course/${this.props.course.id}`}
			>
				<CardTitle>{this.props.course.title}</CardTitle>
				<CardFooter />
			</Card>
		);
	}
}
