import PostContent from 'components/Presentation/PostContent';
import { getSchedule } from 'actions/schedule';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import DateTime from '_class/DateTime';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import User from '_class/User';
import { Block } from '@haldor/ui';
import { getActiveSchool } from 'helpers/localstorage';
import { ColorLabel, ColorLabelContainer, Collapsible } from 'UI';

import TeacherAdjustments from 'containers/AdditionalAdjustments/Display/TeacherAdjustments';

const styles = {
	background: 'white',
	color: 'black',
	borderRadius: '6px',
	marginBottom: '0.55rem',
	padding: '0.65rem',
	cursor: 'pointer',
	boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.09)',
	textDecoration: 'none',
	display: 'block',
};

class SectionInformation extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.group.type != 'MENTOR_GROUP') {
			this.props.getSchedule(
				encodeURIComponent(Moment({ hour: 0 }).format('YYYY-MM-DD HH:mm:ss')),
				encodeURIComponent(Moment({ hour: 23, minute: 59 }).format('YYYY-MM-DD HH:mm:ss')),
			);
		}
	}

	isUserOwner = () => {
		const { currentUser, group } = this.props;

		if (currentUser == null || group == null) {
			return false;
		}

		let foundOwner = group.owners.find(owner => {
			return currentUser.id == owner.id;
		});

		if (foundOwner != null) {
			return true;
		}

		return false;
	}

	render() {
		let subjects = [];
		const user = new User(this.props.currentUser);
		const groupId = this.props.group.id;
		const scheduleItems = this.props.schedule.filter(function (scheduleItem) {
			if (scheduleItem.section != null) {
				return scheduleItem.section.id == groupId;
			}
			else {
				return null;
			}
		});

		if (this.props.currentUser.subjects != null && this.props.currentUser.subjects.length > 0) {
			this.props.currentUser.subjects.forEach(subject => {
				if (getActiveSchool(this.props.currentUser.id) == subject.schoolId) {
					subjects.push(subject);
				}
			});
		}

		return (
			<div className="single-section form left-side">
				<Block>
					{this.props.group.description ?
						<div>
							<span className="title">{this.props.translate('group-information')}</span>

							<PostContent>
								{this.props.group.description}
							</PostContent>
						</div>
						: null}

					{this.props.group.owners != null && this.props.group.owners.length > 0 ?
						<div style={{ marginTop: '1rem' }}>
							<span className="title">{this.props.translate('owners')}</span>

							{this.props.group.owners.map(owner => (
								<div className="card-meta" style={{ marginRight: '0.45rem' }} key={'owner' + owner.id}>
									<span data-tooltip={`${this.props.translate('Connected to group')} ${new DateTime(owner.connectedToSectionDate).getDateStamp()}`}>
										<a href={`mailto:${owner.email}`} style={{ margin: 0, color: '#363636', textDecoration: 'none' }}>
											{owner.firstName} {owner.lastName}
										</a>
									</span>
								</div>
							))}
						</div>
						: null}

					{!user.isStudent() && subjects.length > 0 && this.props.group.type != 'MENTOR_GROUP' && this.props.services.verdicts ?
						<div style={{ marginTop: '1rem' }}>
							<span className="title">{this.props.translate('verdicts')}</span>

							{subjects.map(userSubject => {
								const course = this.props.group.courses.find(course => {
									return userSubject.subjectCode == course.subjectCode;
								});

								if (course == null) {
									return null;
								}

								if (course.status == 'COURSE_DRAFT') {
									return null;
								}

								const schoolType = this.props.translate(course.typeOfSchooling);
								const title = `${course.title} ${course.year != null ? course.year : ''} (${schoolType})`;

								return <Link key={'course-section' + course.id} to={`/groups/${this.props.group.id}/course/${course.id}`}>
									<ColorLabelContainer>
										<ColorLabel
											content={title}
											color={course.colorCode}
											style={{ color: '#363636' }}
										/>
									</ColorLabelContainer>
								</Link>
							})}
						</div>
						: null}

					{this.props.group.type != 'MENTOR_GROUP' && this.props.services.schedule ?
						<div style={{ marginTop: '1rem' }}>
							<span className="title">{this.props.translate('todays-lessons')}</span>

							{scheduleItems != null ? scheduleItems.map(item => {
								return <Link to={`/lesson/${item.id}`} style={styles} key={`schedule-${item.id}`}>
									<div style={{ fontSize: '0.8rem' }}>
										{new DateTime(item.startTime).getTime() + ' - ' + new DateTime(item.startTime).add(item.length, 'minutes').getTime()}
										{item.room != null ?
											` - ${item.room}`
											: null}
									</div>

									<strong style={{ fontWeight: '500' }}>{item.title}</strong>
								</Link>
							}) : null}
						</div>
						: null}

					{this.props.group.type == 'MENTOR_GROUP' && !user.isStudent() && this.props.services.additionalAdjustments ?
						<div style={{ marginTop: '1rem' }}>
							<Collapsible trigger={this.props.translate('additional-adjustments')}>
								<TeacherAdjustments groupId={groupId} />
							</Collapsible>
						</div>
						: null}
				</Block>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		currentUser: state.user.currentUser,
		schedule: state.schedule.scheduleToday,
	};
}

export default connect(mapStateToProps, {
	getSchedule,
})(SectionInformation);
