import {
	createStudentAssessments,
	deletStudentAssessment,
	getStudentAssessment,
	updateStudentAssessment,
} from "actions/studentassessments";
import { isUserTeacher } from "helpers/user";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { Expandable } from "UI";
import { QuillEditor } from "UI/Inputs";
import { Button } from '@haldor/ui';
import MatrixAssessmentParagraph from "../Matrix/MatrixAssessment/MatrixAssessmentParagraph";

class StudentAssessmentFormV1 extends Component {
	constructor(props) {
		super(props);

		this.state = {
			assessments: [],
			deleteAssessment: null,
			feedback: "",
			editMode: false,
		};
	}

	componentDidMount() {
		this.props
			.getStudentAssessment(
				this.props.assignment.id,
				this.props.task.assignedTo
			)
			.then(() => {
				if (this.props.assessment) {
					let assessment = this.props.assessment;
					assessment.assessmentDetails =
						assessment.studentAssessmentDetails;
					if (assessment != null) {
						let assessments = [];
						assessments.push(assessment);
						var deleteAssessment = Object.assign({}, assessment);
						deleteAssessment.assessmentDetails = [];

						this.setState({
							editMode: true,
							assessments,
							feedback: assessment.feedback,
							deleteAssessment,
						});
					}
				} else {
					this.createAssessments();
				}
			});
	}

	createAssessments = () => {
		let assessments = [];
		var assessmentObj = {
			assessmentDetails: [],
			feedback: "",
			referenceId: this.props.task.id,
			referenceType: "AssignmentTask",
			status: "",
			studentId: this.props.task.assignedTo,
		};

		assessments.push(assessmentObj);

		this.setState({ assessments });
		return;
	};

	submit = (values) => {
		const isTeacher = isUserTeacher(this.props.currentUser);
		if (isTeacher == false) {
			return new Promise((resolve) => {
				let assessments = this.state.assessments;
				assessments.forEach((assessment) => {
					assessment.feedback = this.state.feedback;
					if (assessment.status != "ASSESSMENT_PUBLISHED") {
						assessment.status = values.status;
					}
				});

				if (this.state.editMode) {
					this.props
						.updateStudentAssessment(
							this.props.assignment.id,
							assessments[0]
						)
						.then(() => {
							if (
								this.state.deleteAssessment.assessmentDetails
									.length > 0
							) {
								this.props
									.deletStudentAssessment(
										this.state.deleteAssessment,
										this.props.assignment.id
									)
									.then(() => {
										resolve();
										this.props.onAbort(true, true);
									});
							} else {
								resolve();
								this.props.onAbort(true, true);
							}
						});
				} else {
					this.props
						.createStudentAssessments(
							this.props.assignment.id,
							assessments[0]
						)
						.then(() => {
							resolve();
							this.props.onAbort(true, true);
						});
				}
			});
		}
	};

	onMatrixAssessmentUpdate = (block, course) => {
		let assessments = this.state.assessments;
		let deleteAssessment = this.state.deleteAssessment;

		assessments.forEach((assessment) => {
			var find = assessment.assessmentDetails.findIndex(
				(t) => t.rowId == block.id
			);
			if (find == -1) {
				var cellIndex = block.cells
					.map((cell) => cell.selected)
					.lastIndexOf(true);
				if (cellIndex > -1) {
					var blockObj = {
						cellId: block.cells[cellIndex].id,
						rowId: block.id,
						matrixId: course.matrixID,
					};
					assessment.assessmentDetails.push(blockObj);

					if (this.state.editMode) {
						var cellIndex = deleteAssessment.assessmentDetails.findIndex(
							(t) => t.rowId == block.id
						);
						deleteAssessment.assessmentDetails.splice(cellIndex, 1);
					}
				}
			} else {
				var cellIndex = block.cells
					.map((cell) => cell.selected)
					.lastIndexOf(true);
				if (cellIndex == -1) {
					if (this.state.editMode) {
						deleteAssessment.assessmentDetails.push(
							assessment.assessmentDetails[find]
						);
					}
					assessment.assessmentDetails.splice(find, 1);
				} else {
					var detail = assessment.assessmentDetails.find(
						(t) => t.rowId == block.id
					);
					detail.cellId = block.cells[cellIndex].id;
				}
			}
		});

		this.setState({ assessments, deleteAssessment });
	};

	onFeedbackChange = (content) => {
		this.setState({ feedback: content });
	};

	renderUserList() {
		const isTeacher = isUserTeacher(this.props.currentUser);
		let studentAssessorTask = null;
		if (
			this.props.assignment != null &&
			this.props.assignment.tasks != null &&
			this.props.task != null &&
			isTeacher
		) {
			studentAssessorTask = this.props.assignment.tasks.find(
				(t) => t.assignedTo == this.props.task.studentAssessorId
			);
		}

		return (
			<div>
				<table className="item-list">
					<tbody>
						<tr key={this.props.task.assignedTo}>
							<td
								className="name"
								style={{
									paddingLeft: "1.25em",
									fontWeight: "500",
								}}
							>
								{this.props.task.groupName}
							</td>
						</tr>

						{studentAssessorTask != null ? (
							<tr key={studentAssessorTask.assignedTo}>
								<td
									className="name"
									style={{
										paddingLeft: "1.25em",
										fontWeight: "500",
									}}
								>
									{studentAssessorTask.groupName +
										" (" +
										this.props.translate("buddy-assessor") +
										")"}
								</td>
							</tr>
						) : null}
					</tbody>
				</table>
			</div>
		);
	}

	renderMatrix = () => {
		if (this.props.assignment && this.props.activeSection) {
			const { associatedMatrices } = this.props.assignment;
			const { courses } = this.props.activeSection;
			if (associatedMatrices?.length > 0) {
				return courses.map((course) => {
					let selectedRows = false;
					course.details.forEach((detail) => {
						var matrix = associatedMatrices.find(
							(matrix) =>
								matrix.courseId == course.id &&
								detail.matrixID == matrix.matrixId
						);
						if (matrix != null) {
							selectedRows = true;
						}
					});

					if (selectedRows) {
						return (
							<div key={course.id}>
								{course.details.map((detail) => {
									var matrix = associatedMatrices.find((matrix) =>
										matrix.courseId == course.id && matrix.matrixId == detail.matrixID
									);

									if (matrix != null) {
										var title = course.title + " (" + detail.matrix.title;
										if (detail.endDate != null) {
											title += " (" + this.props.translate("Expired") + "))";
										} else {
											title += ")";
										}

										return (
											<Expandable
												title={title}
												key={detail.id}
												open={true}
												color={course.colorCode}
												ignorePropsUpdate
											>
												{matrix._ParagraphSet.map(
													(associatedParagraph) => {
														let paragraph = detail.matrix._ParagraphSet.find((p) =>
															p.id == associatedParagraph.id
														);

														if (paragraph != null) {
															return (
																<MatrixAssessmentParagraph
																	key={paragraph.id}
																	assessments={this.state.assessments}
																	items={[this.props.task,]}
																	paragraph={paragraph}
																	associatedParagraph={associatedParagraph}
																	type="multiple"
																	details={detail}
																	groupAssignment={this.props.groupAssignment}
																	studentAssessment={true}
																	referenceType={this.props.referenceType}
																	onMatrixAssessmentUpdate={this.onMatrixAssessmentUpdate.bind(this)}
																	disableNotAchieved={detail.matrix.disableNotAchieved}
																/>
															);
														}
													}
												)}
											</Expandable>
										);
									}
								})}
							</div>
						);
					}
				});
			}
		}

		return;
	};

	render() {
		const {
			handleSubmit,
			change,
			pristine,
			reset,
			submitting,
			valid,
			assessments,
		} = this.props;
		const isTeacher = isUserTeacher(this.props.currentUser);
		return (
			<div className="form-container form-assessment">
				{this.props.submitting ? (
					<div className="is_sending">
						<p>
							<span className="loading-spinner" />
						</p>
					</div>
				) : null}

				<form className="form form-component">
					<Expandable title={this.props.translate("students")} open>
						{this.renderUserList()}
					</Expandable>

					{this.renderMatrix()}

					<div className="form-row">
						<QuillEditor
							placeholder={this.props.translate("feedback")}
							label={this.props.translate("feedback")}
							onChange={this.onFeedbackChange}
							text={this.state.feedback}
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button
							onClick={handleSubmit((values) =>
								this.submit({
									...values,
									status: "ASSESSMENT_PUBLISHED",
								})
							)}
							disabled={submitting || !valid || isTeacher}
						>
							{this.props.translate("save-assessment")}
						</Button>

						{this.props.assignment.assessmentType == "BUDDY" ? (
							<Button
								onClick={handleSubmit((values) =>
									this.submit({
										...values,
										status: "ASSESSMENT_STARTED",
									})
								)}
								type="secondary"
								disabled={submitting || !valid || isTeacher}
							>
								{this.props.translate("save-draft")}
							</Button>
						) : null}

						<div className="align-right">
							<Button
								type="secondary"
								onClick={(event) => {
									event.preventDefault();
									this.props.onAbort(false);
								}}
							>
								{this.props.translate("Cancel")}
							</Button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let section = state.sections.activeSection;
	if (ownProps.section != null) {
		section = ownProps.section;
	}

	return {
		currentUser: state.user.currentUser,
		activeSection: section,
		assessment: state.assessments.studentAssessment,
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {
	createStudentAssessments,
	updateStudentAssessment,
	getStudentAssessment,
	deletStudentAssessment,
})(
	reduxForm(
		{
			form: "StudentAssessmentForm",
			destroyOnUnmount: true,
		},
		mapStateToProps
	)(StudentAssessmentFormV1)
);
