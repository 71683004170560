import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 20;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 350,
		},
	},
};

function getItemStyles(id, selectedGroups) {
	let isSelected = selectedGroups.findIndex((item) => item === id) > -1;
	return {
		fontWeight: isSelected ? 'bold' : null,
		backgroundColor: isSelected ? 'rgba(25, 118, 210, 0.08)' : null,
	};
}

function areArraysEqual(arr1, arr2) {
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}
	return true;
}


const SelectMultiple = ({ sx, options, label, onChange, initialSelectedItems }) => {
	const [selectedItems, setSelectedItems] = useState([]);

	useEffect(() => {
		if (initialSelectedItems && !areArraysEqual(selectedItems, initialSelectedItems)) {
			const initialSelectedItemsTitles = initialSelectedItems.map((item) => item.title);
			setSelectedItems(initialSelectedItemsTitles);
		}
	}, [initialSelectedItems]);

	const handleSelectionChange = (event) => {
		const { target: { value } } = event;
		setSelectedItems(value);
		const selectedItemObjects = options.filter((item) => value.includes(item.title));
		if (onChange) {
			onChange(selectedItemObjects);
		}
	};

	const removeSelectedItem = (optionTitle) => {
		const updatedItems = selectedItems.filter(item => item !== optionTitle);
		setSelectedItems(updatedItems);
		const selectedItemObjects = options.filter((item) => updatedItems.includes(item.title));
		if (onChange) {
			onChange(selectedItemObjects);
		}
	};

	return (
		<Box>
			<FormControl size="small" sx={sx}>
				<InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
				<Select
					labelId="groups-multiple-chip-label"
					id="groups-multiple-chip"
					multiple
					value={selectedItems}
					onChange={handleSelectionChange}
					autoWidth
					input={<OutlinedInput id="select-multiple-chip" label={label} />}
					renderValue={(selected) => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected.map((value) => (
								<Chip onMouseDown={(event) => { event.preventDefault(); event.stopPropagation(); }} onDelete={() => removeSelectedItem(value)} key={value} label={value} />
							))}
						</Box>
					)}
					MenuProps={MenuProps}
				>
					{options.map((option) => (
						<MenuItem
							key={option.id}
							value={option.title}
							style={getItemStyles(option.id, selectedItems)}
						>
							<Checkbox checked={selectedItems.indexOf(option.title) > -1} />
							{option.title}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

SelectMultiple.propTypes = {
	sx: PropTypes.object,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			title: PropTypes.string.isRequired,
		})
	),
	initialSelectedItems: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			title: PropTypes.string.isRequired,
		})
	),
	onChange: PropTypes.func,
};

export default SelectMultiple;