import React from 'react';
import { Button, Haldor__Block__Plugin, Flex } from '@haldor/ui';

import { ASSESSMENT_TYPES } from '../AssessmentTypes';

class AddTeacherAssessmentBlockButton_Plugin extends Haldor__Block__Plugin {

	constructor(props) {
		super(props);

		this.name = 'AddAssessmentBlockButton_Plugin';
	}

	onButtonPress = async (type, event) => {
		event.preventDefault();
		await this.editor.addBlock('Haldor.Blocks.AssessmentBlock');
	}

	init = () => {
		const { editor } = this;
		const translate = editor.getProp('translate');

		editor.renderer.registerPosition('after_blocks', () => {
			return (
				<div className='add-assessment-button'>
					<Flex center>
						<Button onClick={(e) => { this.onButtonPress(ASSESSMENT_TYPES.TEACHER, e) }} type="secondary" style={{ marginRight: '.55rem' }}>
							{translate('Add teacher assessment')}
						</Button>
					</Flex>
				</div>
			)
		})
	}

}

export default AddTeacherAssessmentBlockButton_Plugin;