import React, { Component } from 'react';
import msal from 'lib/msal';

import { clearLocalStorage } from 'helpers/localstorage';

export default class Logout extends Component {

	constructor(props) {
		super(props);

		const foundAdalinStorage = this.allStorage().findIndex(item => {
			return item.indexOf('adal') > -1;
		});

		if (foundAdalinStorage > -1) {
			clearLocalStorage();
			msal.logoutRedirect();
		} else {
			msal.logoutRedirect();
		}
	}

	allStorage = () => {
		var archive = [],
			keys = Object.keys(localStorage),
			i = 0, key;

		for (; key = keys[i]; i++) {
			archive.push(key + '=' + localStorage.getItem(key));
		}

		return archive;
	}

	render() {
		return null;
	}

}
