import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { TooltipMenu, Icon, Button, translate } from '@haldor/ui';
import { Skeleton } from 'UI';
import { Search as SearchInput } from 'UI/Inputs';
import { onRouteBack } from 'actions/header';
import { isMicrosoftTeams } from 'helpers/teams';

class Header extends Component {
	goBack = (event) => {
		event.preventDefault();

		if (this.props.settings.history.length > 1) {
			const newRoute = this.props.settings.history[1];
			this.props.onRouteBack();
			this.props.history.push(newRoute);
		} else {
			window.history.go(-1);
		}
	}

	onSearch = (search) => {
		this.props.history.push('/search?q=' + search.query);
	}

	renderAction = (action, index) => {
		switch (action.type) {
			case 'button':
				return (
					<Button key={'action-' + index} type={action.buttonType} disabled={action.disabled} onClick={action.onClick}>
						{action.value}
					</Button>
				);

			case 'dropDown':
				return (
					<TooltipMenu
						key={'action-' + index}
						trigger={
							<Button disabled={action.disabled}>
								{action.icon != null ? <Icon name={action.icon} /> : null}

								{action.value}
							</Button>
						}
					>
						{action.subButtons ?
							action.subButtons.map((subAction, index) => (
								<TooltipMenu.Item
									onClick={() => subAction.onClick(true)}
									key={'subAction-' + index}
								>
									{subAction.value}
								</TooltipMenu.Item>
							))
							: null}
					</TooltipMenu>
				);

			case 'icon':
				return (
					<Link to="#" key={'action-' + index} className="header-action" data-tooltip={action.tooltip} onClick={action.onClick}>
						<Icon name="Cog" />
					</Link>
				);

			default:
				return null;
		}
	}

	render() {
		const { translate } = this.props;

		return (
			<div>
				<div className="teams-header">
					<div className="content">
						{this.props.settings.backButton ?
							<Link to="#" onClick={this.goBack} className="c--button back">
								<i className="cl-container">
									<svg xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(90deg)' }} className="a-90">
										<path
											id="Path_50"
											data-name="Path 50"
											className="cls-1"
											d="M11.361,1.4,6.38,5.9,1.4,1.4"
										/>
									</svg>
								</i>
							</Link>
							: null}

						{this.props.title != null ?
							<div className="page-title" title={this.props.title}>
								{this.props.title}
							</div>
							:
							<div className="page-title" style={{ width: '50%' }}>
								<Skeleton style={{ width: '75%', height: '1.25rem' }} />
							</div>
						}
					</div>

					{isMicrosoftTeams() ?
						<div className="content right">
							<div className="actions">
								<SearchInput
									placeholder={translate('search') + '...'}
									onSubmit={this.onSearch}
									clearOnSubmit
									lengthLimit={3}
								/>

								{this.props.settings.actions != null ?
									this.props.settings.actions.map(this.renderAction)
									: null}
							</div>
						</div>
						: null}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		title: state.Header.title,
		settings: state.Header,
		translate: translate(state.Languages.translations),
	};
}

export default withRouter(connect(mapStateToProps, {
	onRouteBack,
})(Header));
