import { lang } from 'moment';
import React, { useEffect } from 'react';

function ZendeskWidget({ language }) {
	let widgetLang = "en-gb";

	if (language == 'sv-SE' || language == 'sv-FI')
		widgetLang = 'sv';
	if (language == 'nb-NO' || language == 'nn-NO')
		widgetLang = 'no';

	zE('webWidget', 'setLocale', widgetLang);

	return null;
}

export default ZendeskWidget;

