import {
	GET_FORM_TEMPLATES,
	GET_FORM_TEMPLATE,
	GET_MY_FORMS,
	UPDATE_FORM_TEMPLATE,
	CLEAR_FORM_TEMPLATE,
	GET_FORM,
	GET_FORMS,
	GET_FORM_INSTANCE,
	CLEAR_FORM,
	CREATE_FORM_INSTANCE
} from 'actions/templates';

const INITIAL_STATE = {
	templates: null,
	formTemplate: null,
	form: null,
	forms: null,
	instance: null,
	createdForm: 0
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_FORM_TEMPLATES:
			return { ...state, templates: action.payload };

		case GET_FORM_TEMPLATE:
			return { ...state, formTemplate: action.payload };

		case UPDATE_FORM_TEMPLATE:
			return { ...state, formTemplate: action.payload };

		case CLEAR_FORM_TEMPLATE:
			return { ...state, formTemplate: INITIAL_STATE.formTemplate };

		case GET_FORM:
			return { ...state, form: action.payload };

		case CLEAR_FORM:
			return { ...state, form: INITIAL_STATE.form, formTemplate: INITIAL_STATE.formTemplate };

		case GET_FORMS:
			return { ...state, forms: action.payload };

		case GET_FORM_INSTANCE:
			return { ...state, instance: action.payload };

		case GET_MY_FORMS:
			return { ...state, myForms: action.payload };

		case CREATE_FORM_INSTANCE:
			return { ...state, createdForm: action.payload.id, instance: action.payload };

		default:
			return state;
	}
}

