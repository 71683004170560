import {
	GET_SCHOOL_TYPES,
} from 'actions/tenants';

const INITIAL_STATE = {
	schoolTypes: []
}

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_SCHOOL_TYPES:
			return { ...state, schoolTypes: action.payload ?? [] };

		default:
			return state;
	}
};
