import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import Moment from "moment";
import Modal from 'containers/Modals/Modal';
import { getActiveSchool } from 'helpers/localstorage';
import { Flex, Skeleton, Menu, Icon, TooltipMenu, Button, getActiveLanguage } from '@haldor/ui';
import { DataList, Spinner } from 'UI';
import { Trash } from 'UI/Icons';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { getTeacherRoles, deleteTeacherRole, addTeacherRole } from 'actions/user';
import TeacherRoleForm from 'containers/Forms/TeacherRole';
import User from '_class/User';

import './Subjects.scss';

class Subjects extends Component {
	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			loading: true,
			removing: null,
			modal: false,
			teacherRoles: [],
		}
	}

	componentDidMount = () => {
		this.props.getTeacherRoles(this.props.userId).then(() => {
			this.setState({ loading: false });
		});
	}

	toggleModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	renderSubjectRoleRow = (teacherRole) => {
		let removing = false;
		if (this.state.removing != null && this.state.removing == teacherRole.subjectCode) {
			removing = true;
		}

		const user = new User(this.props.currentUser);

		return (
			<RowItem key={teacherRole.subjectCode} >
				<RowCell title={this.props.translate('name')}>
					{removing ?
						<Skeleton />
						: teacherRole.title}
				</RowCell>

				<RowCell title={this.props.translate('school')}>
					{removing ?
						<Skeleton />
						: this.props.translate(teacherRole.typeOfSchooling)}
				</RowCell>

				{user.isAdministrator() ?
					<RowCell shrink title=" ">
						{!removing ?
							<div className="icon" >
								<span className="action" onClick={() => { this.onStudentTeacherDelete(teacherRole.subjectId); }}>
									<Trash heigth={'22px'} />
								</span>
							</div>
							: null}
					</RowCell>
					: null}
			</RowItem>
		);
	}

	onTeacherRoleSubmit = (subjects) => {
		let promises = [];
		const activeSchool = getActiveSchool(this.props.currentUser.id);
		subjects.forEach(subject => {
			let subjectTeacher = {
				UserId: this.props.currentStaff.id,
				SubjectId: subject.id,
				SubjectCode: subject.subjectCode,
				typeOfSchooling: subject.typeOfSchooling,
				Title: subject.title,
				SchoolId: activeSchool
			}

			promises.push(this.props.addTeacherRole(this.props.userId, subjectTeacher));
		});

		Promise.all(promises).then(() => {
			this.toggleModal();
		});
	}

	onStudentTeacherDelete = async (subjectId) => {
		this.setState({ removing: subjectId });
		const activeSchool = getActiveSchool(this.props.currentUser.id);
		await this.props.deleteTeacherRole(this.props.userId, subjectId, activeSchool);
		await this.props.getTeacherRoles(this.props.currentStaff.id, true);
		this.setState({ removing: null });
	}

	render() {
		const user = new User(this.props.currentUser);

		if (this.state.loading) {
			return (
				<div className="temp-subject-administration">
					<div className="single-section">
						<Spinner center />
					</div>
				</div>
			)
		}

		return (
			<div className="single-section">
				<Modal onClose={this.toggleModal} title={this.props.translate('add-subject')} isOpen={this.state.modal} type="small">
					<TeacherRoleForm
						previousTeacherRoles={this.props.teacherRoles}
						onTeacherRoleSubmit={this.onTeacherRoleSubmit}
					/>
				</Modal>

				<DataList
					data={this.props.teacherRoles}
					renderRow={this.renderSubjectRoleRow}
					emptyData={() => (
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					)}
				/>

				{user.isAdministrator() ?
					<Button style={{ marginTop: '20px', float: 'right' }} type="secondary" onClick={() => this.toggleModal()}>
						<Icon name="Plus" /> {this.props.translate("Add")}
					</Button>
					: null}
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		teacherRoles: state.user.teacherRoles,
		currentStaff: state.user.info.find(u => {
			return u.userId == ownProps.userId;
		})
	};
}

export default connect(mapStateToProps, {
	getTeacherRoles,
	deleteTeacherRole,
	addTeacherRole
})(Subjects);