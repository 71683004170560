import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBlockTypes } from 'actions/blocks';

import { HaldorBlockEditor, SkolonBlock, translate } from '@haldor/ui';

import AssignmentBlock from './Blocks/AssignmentBlock';
import FileBlock from './Blocks/FileBlock';
import LinksBlock from './Blocks/LinksBlock';
import NDLABlock from './Blocks/NdlaBlock';

class BlockEditor extends Component {

	componentDidMount = () => {
		if (this.props.types == null) {
			this.props.getBlockTypes();
		}
	}

	render() {
		if (this.props.types == null) {
			return null;
		}

		let plugins = [];

		if (this.props.types.indexOf('Haldor.Blocks.Link') > -1) {
			plugins.push(LinksBlock);
		}

		if (this.props.types.indexOf('Haldor.Blocks.Assignment') > -1) {
			plugins.push(AssignmentBlock);
		}

		if (this.props.types.indexOf('Haldor.Blocks.File') > -1) {
			plugins.push(FileBlock);
		}

		if (this.props.types.indexOf('Haldor.Blocks.Skolon') > -1) {
			plugins.push(SkolonBlock);
		}

		if (this.props.types.indexOf('Haldor.Blocks.NDLA') > -1) {
			plugins.push(NDLABlock);
		}

		return <HaldorBlockEditor
			plugins={plugins}
			disabledBlocks={this.props.disabled}
			translate={this.props.translate}
			{...this.props}
		/>
	}

}

function mapStateToProps(state) {
	return {
		types: state.Blocks.types,
		translate: translate(state.Languages.translations),
	}
}

export default connect(mapStateToProps, {
	getBlockTypes,
})(BlockEditor);