import { FIELD_DESCRIPTION, FIELD_TITLE } from 'constants/fields';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import './Styles.scss';

class PreviewForm extends Component {

	renderTitle = (field) => {
		return <div className="input-label">
			{field.value}
		</div>
	}

	renderDescription = (field) => {
		return <div>{field.value}</div>
	}

	renderInput = (field) => {
		const type = field.type.toLowerCase();

		return <div className={`placeholder-input ${type}`}>
			{this.props.translate(type)}
		</div>
	}

	renderField = (field, index) => {
		if (field.type == FIELD_TITLE) {
			return <div key={'field-' + index} className="form-row field">
				{this.renderTitle(field, index)}
			</div>
		}

		if (field.type == FIELD_DESCRIPTION) {
			return <div key={'field-' + index} className="form-row field">
				{this.renderDescription(field, index)}
			</div>
		}

		return <div key={'field-' + index} className="form-row">
			{this.renderInput(field, index)}
		</div>
	}

	render() {
		if (this.props.fields == null) {
			return null;
		}

		let fields = [...this.props.fields];
		fields.sort((a, b) => {
			return a.sortOrder > b.sortOrder ? 1 : -1;
		});

		return (
			<div className="dynamic-form">
				<div className="form form-component">
					{fields.map(this.renderField)}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, null, null, {
	pure: false,
})(PreviewForm);
