import React from 'react';
import DataObject from './index.js';

class Post extends DataObject {

	getName = () => {
		return this.data.title;
	}

	getDescription = () => {
		return this.getHTML(this.data.body);
	}

	getAuthor = () => {
		return this.data.author.firstName + ' ' + this.data.author.lastName;
	}

}

export default Post;
