import { deleteFlaggedMessage, getFlaggedMessages, updateFlaggedMessage } from 'actions/conversation_admin';
import Date from 'components/Presentation/Date/Date';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import { Button } from '@haldor/ui';
import Block, { BlockWrapper } from 'UI/Elements/Block';
import { setPageTitle } from 'actions/header';

class FlaggedConversations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('nav_flagged-messages'));
	}

	componentDidMount() {
		this.getData();
	}

	getData() {
		this.setState({ loading: true });
		this.props.getFlaggedMessages().then(() => {
			this.setState({ loading: false });
		})
	}

	softDeleteMessage(flag) {
		this.props.deleteFlaggedMessage(flag.id).then(() => {
			this.getData();
		});
	}

	removeFlag(flag) {
		flag.status = 'CLOSED'
		this.props.updateFlaggedMessage(flag).then(() => {
			this.getData();
		});
	}

	headerContent(flag) {
		return (
			<div>
				<div style={{ float: 'left', color: '#535353', fontSize: '0.9em' }}>
					{this.props.translate('reported-by')}: {flag.reporter.firstName + " " + flag.reporter.lastName}
				</div>

				<div style={{ float: 'right' }}>
					<Date>{Moment(flag.created)}</Date>
				</div>

				<div className="clearfix" />
			</div>
		)
	}

	content(flag) {
		return (
			<div>
				<div>
					<h3>{this.props.translate('reason')}</h3>
					<p>{flag.reason}</p>

					<span style={{ float: 'right', color: '#535353', fontSize: '0.9em', marginTop: '1em' }}>
						{this.props.translate('written-by')}: {flag.reporter.firstName + " " + flag.reporter.lastName}
					</span>
				</div>

				<div className="clearfix" />

				<div style={{ marginTop: '1em' }}>
					<h3>{this.props.translate('message')}</h3>

					<p>{flag.message.text}</p>

					<span style={{ float: 'right', color: '#535353', fontSize: '0.9em', marginTop: '1em' }}>
						{this.props.translate('written-by')}: {flag.sender.firstName + " " + flag.sender.lastName}
					</span>
				</div>

				<div className="clearfix" />
			</div>
		)
	}

	footerContent(flag) {
		return (
			<div className="spacing">
				<Button style={{ margin: '5px' }} onClick={() => { this.softDeleteMessage(flag); }}>
					{this.props.translate('remove-message')}
				</Button>

				<Button style={{ margin: '5px' }} type="secondary" onClick={() => { this.removeFlag(flag); }}>
					{this.props.translate('remove-flagged-message')}
				</Button>
			</div>
		)
	}

	render() {
		if (this.state.loading) {
			return <Spinner center />
		}

		let flagged = [];

		if (this.props.flagged != null && this.props.flagged.length > 0) {
			flagged = [...this.props.flagged];
			flagged.sort((a, b) => {
				return a.id < b.id ? 1 : -1;
			});
		}

		return (
			<div>
				<BlockWrapper cols={3}>
					{flagged.map((flag) => {
						return (
							<Block
								key={flag.id}
								headerContent={this.headerContent(flag)}
								content={this.content(flag)}
								footerContent={this.footerContent(flag)}
							/>
						)
					})}
				</BlockWrapper>

				{this.props.flagged.length == 0 ?
					<div>{this.props.translate('no-flagged-messages-found')}</div>
					: null}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		flagged: state.ConversationAdmin.flagged,
	}
}

export default connect(mapStateToProps, {
	getFlaggedMessages,
	updateFlaggedMessage,
	deleteFlaggedMessage,
	setPageTitle,
})(FlaggedConversations);

