import React from 'react';
import { Haldor__Block__Plugin } from '@haldor/ui';
import { ASSESSMENT_TYPES } from '../AssessmentTypes';

class PushAssessmentBlock_Plugin extends Haldor__Block__Plugin {

	constructor(props) {
		super(props);

		this.name = 'PushAssessmentBlock_Plugin';
	}

	editorMount = async () => {
		const { editor } = this;

		if (editor.getBlocks().length == 0) {
			await editor.addBlock('Haldor.Blocks.AssessmentBlock');
		}
	}

}

export default PushAssessmentBlock_Plugin;