import { http } from 'lib/msal';
import getEnvironment from 'lib/env';

let api = {
	get: function (route, useBigAzure) {
		if (useBigAzure) {
			return http.get(route, { baseURL: getEnvironment()?.baseURL });
		}
		return http.get(route);
	},
	put: function (route, data) {
		return http.put(route, data);
	},
	post: function (route, data) {
		return http.post(route, data, {
			headers: {
				'content-type': 'application/json'
			},
		});
	},
	delete: function (route, data) {
		return http.delete(route, {
			data: data != null ? data : null,
		});
	},
	patch: function (route, data, operations) {
		return new Promise((resolve) => {
			http.patch(route, operations).then((response) => {
				resolve(response);
			});
		});
	},
	getFile: function (id) {
		return http.get(`fileItems/${id}`, {
			responseType: 'blob',
		});
	},
	getOneDriveFile: function (route, token) {
		return http.get(route, {
			headers: {
				'Authorization': `Bearer ${token}`
			}
		});
	}
};

export default api;