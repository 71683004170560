
import {
	SET_PAGE_ACTIONS,
	SET_PAGE_TITLE,
	ON_ROUTE_CHANGE,
	ON_ROUTE_BACK,
} from 'actions/header';

import { isSupported } from 'helpers/localstorage';

import { backButtonEnable } from 'helpers/teams';

let hydrateHistory = null;
if (isSupported()) {
	hydrateHistory = localStorage.getItem('history') != null ? JSON.parse(localStorage.getItem('history')) : null;
}

let pathname = window.location.pathname;
if (pathname.indexOf('/landingpage') > -1) {
	pathname = pathname.replace('/landingpage', '');
}

// Init history with current route as first item
let history = [pathname];

if (backButtonEnable() && hydrateHistory != null && hydrateHistory.length > 0) {
	// Only load history from local storage if on page that should show back button
	if (hydrateHistory[0] == pathname) {
		// If first item in hydrated history matches current route, set hydrated history as history
		history = hydrateHistory;
	} else {
		// If it doesnt match we clear the item since we dont need the data
		localStorage.removeItem('history');
	}
}

if (!backButtonEnable() && isSupported()) {
	// If user loads on a page that shouldnt display the back button we clear history item in LS to avoid big items
	localStorage.removeItem('history');
}

const INITIAL_STATE = {
	actions: null,
	title: null,
	backButton: backButtonEnable() && history.length > 1,
	pathname: pathname,
	history,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_PAGE_ACTIONS:
			return { ...state, actions: action.payload };

		case SET_PAGE_TITLE:
			return { ...state, title: action.payload };

		case ON_ROUTE_CHANGE:
			// Dispatched from routes/index.js
			if (window.location.pathname == state.pathname) {
				return state;
			}

			let history = JSON.parse(localStorage.getItem('history'));
			if (history == null) {
				history = [pathname];
			}

			if (history[0] != window.location.pathname) {
				history.unshift(window.location.pathname);
			}

			if (history.length > 0) {
				localStorage.setItem('history', JSON.stringify(history));
			}

			return {
				...state,
				title: null,
				actions: null,
				history: history,
				pathname: window.location.pathname,
				backButton: backButtonEnable() && history.length > 1,
			};

		case ON_ROUTE_BACK:
			let newHistory = JSON.parse(localStorage.getItem('history'));
			if (newHistory == null) {
				newHistory = [pathname];
			}

			newHistory.shift();

			if (newHistory.length > 0) {
				localStorage.setItem('history', JSON.stringify(newHistory));
			}

			return { ...state, history: newHistory };

		default:
			return state;
	}
}
