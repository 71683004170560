import React from 'react';
import DataObject from './index.js';
import PermissionManager from './PermissionManager.js';

import schoolHelper from 'helpers/schoolHandling';

import {
	isUserTeacher,
	isUserAdministrator,
	isUserCourseAdministrator,
	isUserMentor,
	isUserStudent,
	isUserCurriculumAdmin,
	isUserAuthoritative,
	isUserPedagogue,
	havingPermission
} from 'helpers/user';

class User extends DataObject {
	constructor(props) {
		super(props);
		this.permissionManager = new PermissionManager();
		this.permissionTypes = this.permissionManager.getPermissionTypes();
	}

	getUserId = () => {
		if (this.data != null) {
			return this.data.userId;
		}

		return null;
	}

	getName = () => {
		if (this.data != null) {
			return this.data.firstName + ' ' + this.data.lastName;
		}

		return '';
	}

	isTeacher = () => {
		return isUserTeacher(this.data);
	}

	isAdministrator = () => {
		return isUserAdministrator(this.data);
	}

	isCourseAdministrator = () => {
		return isUserCourseAdministrator(this.data);
	}

	isUserCurriculumAdmin = () => {
		return isUserCurriculumAdmin(this.data);
	}

	isMentor = () => {
		return isUserMentor(this.data);
	}

	isStudent = () => {
		return isUserStudent(this.data);
	}

	isAuthoritative = () => {
		return isUserAuthoritative(this.data);
	}

	isPedagogue = () => {
		return isUserPedagogue(this.data);
	}

	havingPermission = (permissionType) => {
		if (!this.permissionTypes[permissionType]) {
			return false;
		}

		return havingPermission(this.data, permissionType);
	}

	isSchoolLeader = () => {
		if (this.data == null) {
			return false;
		}

		const school = schoolHelper.getSchoolForUser(this.data);
		if (this.data.roles != null && this.data.roles.length > 0) {
			const role = this.data.roles.find(role =>
				role.roleId.toUpperCase() == 'SCHOOL_LEADER' &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (role != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	isOperationManager = () => {
		if (this.data == null) {
			return false;
		}

		if (this.data.userRoles != null && this.data.userRoles.length > 0) {
			const role = this.data.userRoles.find(role =>
				role.toUpperCase() == 'OPERATION_MANAGER'
			);

			if (role != null) {
				return true;
			}
		}

		return false;
	}

	isSMSAdmin = () => {
		if (this.data == null) {
			return false;
		}

		const school = schoolHelper.getSchoolForUser(this.data);
		if (this.data.roles != null && this.data.roles.length > 0) {
			const role = this.data.roles.find(role =>
				role.roleId.toUpperCase() == 'SMS_ADMIN' &&
				role.referenceType.toUpperCase() == 'SCHOOL' &&
				role.referenceId == school
			);

			if (role != null) {
				return true;
			}

			return false;
		}

		return false;
	}

	getActiveSchool = () => {
		if (this.data == null) {
			return null;
		}

		const schoolId = schoolHelper.getSchoolForUser(this.data);

		if (schoolId != null) {
			const school = this.data.schools.find(school =>
				school.id == schoolId
			);

			if (school != null) {
				return school;
			}
		}

		return null;
	}
}

export default User;
