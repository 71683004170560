import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Close, Minus } from 'UI/Icons';
import { FlexibleTextArea } from 'UI/Inputs';

class CustomCoreContent extends Component {

	constructor(props) {
		super(props);

		let init = {
			coreContents: [],
			contentRowCount: 0,
			aspectCount: 0,
			aspectRowCount: 0,
		};

		if (props.coreContents != null) {
			init.coreContents = props.coreContents;
			init.contentRowCount = props.coreContents.length;
			props.coreContents.forEach(coreContent => {
				coreContent.key = coreContent.id;
				if (coreContent.aspects != null && coreContent.aspects.length > 0) {
					init.aspectCount += coreContent.aspects.length;

					coreContent.aspects.map(aspect => {
						aspect.key = aspect.id;

						if (aspect.aspectRows != null && aspect.aspectRows.length > 0) {
							init.aspectRowCount = init.aspectRowCount + aspect.aspectRows.length;

							aspect.aspectRows.map(aspectRow => {
								aspectRow.key = aspectRow.id;

								return aspectRow;
							});
						}

						return aspect;
					});
				}
			});
		}

		this.state = init;
	}

	onChange = () => {
		if (this.props.onChange) {
			const coreContents = this.state.coreContents;

			/*
			let data = {
				title: coreContents.title,
				id: coreContents.id,
				aspects: [],
			};

			coreContents.aspects.map(aspect => {
				if (aspect.title != '') {
					let newAspect = {
						title: aspect.title,
						aspectRows: [],
						id: aspect.id,
					};

					aspect.aspectRows.map(aspectRow => {
						if (aspectRow.text != '') {
							newAspect.aspectRows.push(aspectRow);
						}
					});

					data.aspects.push(newAspect);

					return aspect;
				}
			});*/
			let dataArray = [];
			coreContents.map(coreContent => {
				let data = {
					title: coreContent.title,
					id: coreContent.id,
					aspects: [],
				};
				coreContent.aspects.map(aspect => {
					if (aspect.title != '') {
						let newAspect = {
							title: aspect.title,
							aspectRows: [],
							id: aspect.id,
						};

						aspect.aspectRows.map(aspectRow => {
							if (aspectRow.text != '') {
								newAspect.aspectRows.push(aspectRow);
							}
						});

						data.aspects.push(newAspect);

						return aspect;
					}
				});
				dataArray.push(data);
			});

			this.props.onChange(dataArray);
		}
	}

	UNSAFE_componentWillMount = () => {
		if (this.state.coreContents.length < 1) {
			this.addCoreContent();
		}
	}

	/* Inset area */
	addCoreContent = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { coreContents, contentRowCount } = this.state;

		let newObject = {
			key: 'core-content-' + this.state.coreContents.length + ':' + contentRowCount,
			title: '',
			aspects: [],
		};

		coreContents.push(newObject);
		contentRowCount++;
		this.setState({ coreContents, contentRowCount }, () => {
			this.addAspect(newObject);
		});
	}

	addAspect = (contentRow) => {
		let { coreContents, aspectCount } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let newAspect = {
			key: contentRow.key + '-aspect-' + stateObject.aspects.length + ':' + aspectCount,
			title: '',
			aspectRows: [],
		};

		stateObject.aspects.push(newAspect);
		aspectCount++;
		this.setState({ coreContents, aspectCount }, () => {
			this.addAspectRow(newAspect, contentRow);
			this.onChange();
		});
	}

	addAspectRow = (aspect, contentRow) => {
		let { coreContents, aspectRowCount } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let aspectObject = stateObject.aspects.find(as => {
			return as.key == aspect.key;
		});

		let newAspectRow = {
			key: aspect.key + '-aspectRow-' + aspect.aspectRows.length + ':' + aspectRowCount,
			text: '',
		};

		aspectObject.aspectRows.push(newAspectRow);
		aspectRowCount++;
		this.setState({ coreContents, aspectRowCount }, () => {
			this.onChange();
		});
	}

	/* Text change area */
	onAspectTitleChange = (text, aspect, contentRow) => {
		let { coreContents } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let aspectObject = stateObject.aspects.find(as => {
			return as.key == aspect.key;
		});

		aspectObject.title = text;
		this.setState({ coreContents }, () => {
			this.onChange();
		});
	}

	onAspectRowTextChange = (text, aspectRow, aspect, contentRow) => {
		let { coreContents } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let aspectObject = stateObject.aspects.find(as => {
			return as.key == aspect.key;
		});

		let aspectRowObject = aspectObject.aspectRows.find(ar => {
			return ar.key == aspectRow.key;
		});

		aspectRowObject.text = text;
		this.setState({ coreContents }, () => {
			let aspectRowIndex = aspectObject.aspectRows.findIndex(ar => {
				return ar.key == aspectRow.key;
			});

			if (aspectRowIndex + 1 == aspectObject.aspectRows.length) {
				if (aspectRowObject.text != '') {
					this.addAspectRow(aspect, contentRow);
				}
			}

			this.onChange();
		});
	}

	/* Delete area */
	onContentRowDelete = (contentRow) => {
		let { coreContents } = this.state;

		let contentRowIndex = coreContents.findIndex(cc => {
			return cc.key == contentRow.key;
		});

		coreContents.splice(contentRowIndex, 1);
		this.setState({ coreContents }, () => {
			this.onChange();
		});
	}

	onAspectDelete = (aspect, contentRow) => {
		let { coreContents } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let aspectIndex = stateObject.aspects.findIndex(as => {
			return as.key == aspect.key;
		});

		stateObject.aspects.splice(aspectIndex, 1);
		this.setState({ coreContents }, () => {
			this.onChange();
		});
	}

	onAspectRowDelete = (aspectRow, aspect, contentRow) => {
		let { coreContents } = this.state;

		let stateObject = coreContents.find(cc => {
			return cc.key == contentRow.key;
		});

		let aspectObject = stateObject.aspects.find(as => {
			return as.key == aspect.key;
		});

		let aspectRowIndex = aspectObject.aspectRows.findIndex(ar => {
			return ar.key == aspectRow.key;
		});

		aspectObject.aspectRows.splice(aspectRowIndex, 1);
		this.setState({ coreContents }, () => {
			this.onChange();
		});
	}

	/*
		<div onClick={ e => this.onContentRowDelete( contentRow ) } className="remove-button-container">
			<div className="remove-icon-container"><Minus /></div>
			Ta bort sektion
		</div>
	*/

	render() {
		const { coreContents } = this.state;

		return (
			<div className="core-content">
				{coreContents.map(contentRow => {
					return <div className="form-row custom-row" key={contentRow.key}>
						<div className="aspects">
							{contentRow.aspects.map((aspect, index) => {
								return <div className="aspect-row" key={aspect.key}>
									<span className="subtitle">
										{this.props.translate('core-section')} {index + 1}
									</span>

									<div onClick={event => this.onAspectDelete(aspect, contentRow)} className="remove-button-container">
										<div className="remove-icon-container"><Minus /></div>
										{this.props.translate('remove-core-section')}
									</div>

									<input
										type="text"
										value={aspect.title}
										onChange={event => {
											const { value } = event.target;
											this.onAspectTitleChange(value, aspect, contentRow)
										}}
									/>

									<div className="aspects">
										<span className="subtitle" style={{ marginTop: '1.25em' }}>
											{this.props.translate('content')}
										</span>

										<div className="clearfix" />

										{aspect.aspectRows.map(aspectRow => {
											return <div className="aspect-add" key={aspectRow.key}>
												<FlexibleTextArea
													value={aspectRow.text != null ? aspectRow.text : ''}
													onChange={event => {
														const { value } = event.target;
														this.onAspectRowTextChange(value, aspectRow, aspect, contentRow);
													}}>
												</FlexibleTextArea>

												<div onClick={event => {
													event.preventDefault();
													this.onAspectRowDelete(aspectRow, aspect, contentRow);
												}}
													className="tooltip"
													data-tooltip={this.props.translate('remove-content')}
													className="aspects-remove">
													<div style={{ width: '15px' }}><Close /></div>
												</div>
											</div>
										})}

										<div className="core-content-add-btn" style={{ marginTop: '0.75em' }} onClick={e => {
											e.preventDefault();
											this.addAspectRow(aspect, contentRow);
										}}>
											+ {this.props.translate('add-content')}
										</div>
									</div>
								</div>
							})}

							<div className="core-content-add-btn" onClick={e => {
								e.preventDefault();
								this.addAspect(contentRow);
							}}>
								+ {this.props.translate('add-core-section')}
							</div>
						</div>
					</div>
				})}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(CustomCoreContent);
