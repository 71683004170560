import packageJson from './../../package.json';

import { isSupported } from 'helpers/localstorage';
import getEnviroment from 'lib/env';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const shorthash = require('../../app-config/shorthash.js');
let s_shortterm_id = shorthash.unique(new Date().toUTCString());

if (isSupported()) {
	if (window.location.pathname === '/Auth' && localStorage.getItem('hsid') != null) {
		s_shortterm_id = localStorage.getItem('hsid');
	} else {
		localStorage.setItem('hsid', s_shortterm_id);
	}
}

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: getEnviroment().applicationInsightKey,
		disableExceptionTracking: false,
	}
});


appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope) => {
	envelope.data.hsid = s_shortterm_id;
});
appInsights.context.application.ver = packageJson.version;

const getEnhancedLogLevel = () => {
	if (localStorage.getItem('enhanced-log-level') != null) {
		return parseInt(localStorage.getItem('enhanced-log-level'));
	}
	return 0;
}

const setEnhancedLogLevel = (level) => {
	localStorage.setItem('enhanced-log-level', level);
	return level;
}

export { appInsights, getEnhancedLogLevel, setEnhancedLogLevel };