import DisplayName from 'components/Presentation/DisplayName';
import React, { Component } from 'react';

class UserSectionPreschool extends Component {

	render() {
		return (
			<div>
				<table className="status-list">
					<tbody>
						{this.props.section.students
							.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
							.map((student) => {
								return (
									<tr key={student.id}>
										<td className="name">
											<DisplayName
											firstName={student.firstName}
											lastName={student.lastName}
											email={student.email}
											data={this.props.section.students}
											/>
										</td>

										<td className="assessment-bar">
										</td>

									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		);
	}

}

export default UserSectionPreschool;