import React, { Component } from 'react';
import ColorScale from '_class/ColorScale';

import '../../Verdict.scss';

class VerdictLevelForm extends Component {

	constructor(props) {
		super(props);

		let active = null;
		if (props.value != null) {
			active = props.value;
		}

		this.state = {
			active,
		};
	}

	onCellClick = (cell) => {
		this.setState({ active: cell.id });

		if (this.props.onChange) {
			this.props.onChange(cell.id);
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (nextProps.value != this.state.value) {
			this.setState({ active: nextProps.value });
		}
	}

	render() {
		if (this.props.levels == null) {
			return null;
		}

		const colors = new ColorScale(this.props.levels.length).get();

		return (
			<div className="verdict verdict-level-form">
				<div className="verdict-cells">
					{this.props.levels.map(cell => {
						let styles = {
							'--level-color': cell.colorCode != null ? cell.colorCode : colors[cell.level],
						};

						let className = "cell";

						if (cell.id == this.state.active) {
							className += ' active';
						}

						return <div key={cell.id} style={styles} className={className} onClick={e => this.onCellClick(cell)}>
							{cell.localizationText != null ? cell.localizationText : cell.level}
						</div>
					})}
				</div>
			</div>
		);
	}

}

export default VerdictLevelForm
