import { FIELD_DESCRIPTION, FIELD_TEXT, FIELD_TEXTAREA, FIELD_TITLE } from 'constants/fields';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Icon, TooltipMenu } from '@haldor/ui';
import './Styles.scss';

class CreateFormTemplate extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		let fields = [];

		if (props.fields != null && props.fields.length > 0) {
			fields = [...props.fields];
		}

		this.state = {
			fields,
		};
	}

	getHighestName = () => {
		if (this.state.fields.length > 0) {
			let max = 0;
			this.state.fields.forEach(function (field) {
				const fieldIndex = parseInt(field.name.replace('field-', ''));
				if (fieldIndex > max) {
					max = fieldIndex;
				}
			});

			return max;
		}

		return 0;
	}

	/* Events */
	onChange = () => {
		if (this.props.onChange) {
			this.props.onChange(this.state.fields);
		}
	}

	onValueChange = (value, index) => {
		let fields = [...this.state.fields];

		let field = { ...fields[index], value: value };
		fields[index] = field;

		fields.forEach((field, index) => {
			field.sortOrder = index;
		});

		this.setState({ fields }, () => {
			this.onChange();
		});
	}

	onMoveUp = (index) => {
		let { fields } = this.state;

		if (index > 0) {
			const field = fields[index];
			fields.splice(index, 1);
			fields.splice(index - 1, 0, field);
		}

		fields.forEach((field, index) => {
			field.sortOrder = index;
		});

		this.setState({ fields }, () => {
			this.onChange();
		});
	}

	onRemove = (index) => {
		let { fields } = this.state;
		fields.splice(index, 1);

		fields.forEach((field, index) => {
			field.sortOrder = index;
		});

		this.setState({ fields }, () => {
			this.onChange();
		});
	}

	onMoveDown = (index) => {
		let { fields } = this.state;

		if (index < fields.length) {
			const field = fields[index];
			fields.splice(index, 1);
			fields.splice(index + 1, 0, field);
		}

		fields.forEach((field, index) => {
			field.sortOrder = index;
		});

		this.setState({ fields }, () => {
			this.onChange();
		});
	}

	addField = (type) => {
		let { fields } = this.state;

		const fieldIndex = this.getHighestName() + 1;

		switch (type) {
			case FIELD_TEXT:
				fields.push({
					type: FIELD_TEXT,
					placeholder: '',
					name: 'field-' + fieldIndex,
				});

				break;

			case FIELD_TEXTAREA:
				fields.push({
					type: FIELD_TEXTAREA,
					placeholder: '',
					name: 'field-' + fieldIndex,
				});

				break;

			case FIELD_TITLE:
				fields.push({
					type: FIELD_TITLE,
					name: 'field-' + fieldIndex,
					value: '',
				});

				break;

			case FIELD_DESCRIPTION:
				fields.push({
					type: FIELD_DESCRIPTION,
					name: 'field-' + fieldIndex,
					value: '',
				});

				break;

			default:
				break;
		}

		fields.forEach((field, index) => {
			field.sortOrder = index;
		});

		this.setState({ fields }, () => {
			this.onChange();
		});
	}

	/* Render methods */
	fieldControls = (field, index) => {
		return (
			<div>
				<span className="field-tooltip-trigger-icon up" onClick={() => this.onMoveUp(index)}>
					<Icon name="Chevron" />
				</span>

				<span className="field-tooltip-trigger-icon down" onClick={() => this.onMoveDown(index)}>
					<Icon name="Chevron" />
				</span>

				<span className="field-tooltip-trigger-icon trash" onClick={() => this.onRemove(index)}>
					<Icon name="Bin" />
				</span>
			</div>
		)
	}

	renderField = (field, index) => {
		if (field.type == FIELD_TITLE) {
			return <div key={index} style={{ marginBottom: '0.85rem' }} className="form-row">
				<div style={{ marginBottom: '.2rem' }}>
					{this.props.translate('heading')}

					<div style={{ float: 'right' }}>
						{this.fieldControls(field, index)}
					</div>

					<div className="clearfix" />
				</div>

				<input
					type="text"
					value={field.value}
					onChange={e => this.onValueChange(e.target.value, index)}
				/>
			</div>
		}

		if (field.type == FIELD_DESCRIPTION) {
			return <div key={index} style={{ marginBottom: '0.85rem' }} className="form-row">
				<div style={{ marginBottom: '.2rem' }}>
					{this.props.translate('description')}

					<div style={{ float: 'right' }}>
						{this.fieldControls(field, index)}
					</div>

					<div className="clearfix" />
				</div>

				<textarea
					type="text"
					value={field.value}
					rows={2}
					onChange={e => this.onValueChange(e.target.value, index)}
				/>
			</div>
		}

		return <div key={index} style={{ marginBottom: '0.75rem' }} className="form-row">
			<div style={{ marginBottom: '.2rem' }}>
				<div style={{ float: 'right' }}>
					{this.fieldControls(field, index)}
				</div>

				<div className="clearfix"></div>
			</div>

			<div style={field.type == FIELD_TEXT ? {
				width: '100%',
				backgroundColor: '#f9f9f9',
				border: '1px solid #d9d9d9',
				color: '#7b7b7b',
				borderRadius: '3px',
				padding: '15px',
			} : {
					width: '100%',
					backgroundColor: '#f9f9f9',
					border: '1px solid #d9d9d9',
					color: '#7b7b7b',
					borderRadius: '3px',
					padding: '15px',
					height: '150px'
				}}>
				{field.type == FIELD_TEXTAREA ?
					this.props.translate('fields_input_textarea')
					:
					this.props.translate('fields_input_text')
				}
			</div>
		</div>
	}

	render() {
		const trigger = (
			<div style={{
				margin: '1.1rem 0',
				width: '100%',
				textAlign: 'center',
				cursor: 'pointer',
			}}>+ {this.props.translate('add-content')}</div>
		);

		return (
			<div className="dynamic-form create">
				<div className="form form-component">
					{this.state.fields.map(this.renderField)}
				</div>

				<div className="add-field">
					<TooltipMenu trigger={trigger} id="dynamic-form-add-field-tooltip">
						<TooltipMenu.Item onClick={(e) => this.addField(FIELD_TITLE)}>
							{this.props.translate('heading')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={(e) => this.addField(FIELD_DESCRIPTION)}>
							{this.props.translate('description')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={(e) => this.addField(FIELD_TEXT)}>
							{this.props.translate('fields_input_text')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={(e) => this.addField(FIELD_TEXTAREA)}>
							{this.props.translate('fields_input_textarea')}
						</TooltipMenu.Item>
					</TooltipMenu>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps)(CreateFormTemplate);
