import React, { Component } from 'react';

class Bar extends Component {
	static defaultProps = {
		showAmount: false,
		showLabel: false,
	};

	renderBar = ( data ) => {
		const {
			showAmount,
			showLabel,
			showAllSteps,
			showPercentage,
		} = this.props;

		let steps = [];
		let total = data.map( item => {
			return parseInt( item.amount, 10 );
		}).reduce( ( prev, next ) => {
			return prev + next;
		});

		data.map( step => {
			let stepWidth = 0;
			if ( total > 0 ) {
				stepWidth = ( parseInt( step.amount, 10 ) / total * 100 ) + '%';
			} else {
				stepWidth = ( 100 / data.length ) + '%';
			}

			steps.push({
				width: stepWidth,
				label: step.label ? step.label : '',
				color: step.color ? step.color : '#9E82EB',
				amount: step.amount,
			});
		});

		return (
			<div className="bar-container">
				{ steps.map( (step, i) => {
					let style = showAllSteps && step.width == '0%' ?
						{
							padding: '0.2em 0.3em !important',
							backgroundColor: step.color,
						}
					:
						{
							width: step.width,
							backgroundColor: step.color
						};

					return <span key={i} className="step" style={ style }>
						{ showLabel ? step.label : null }
						{ showAmount ? ` ${ step.amount }` : null }
						{ showPercentage ? ` ${ step.width }%` : null }
					</span>;
				}) }
			</div>
		);
	}

	render() {
		const { data, datasets } = this.props;

		if ( datasets ) {
			return(
				<div className="graph bar">
					{ datasets.map( data => {
						return this.renderBar( data )
					}) }
				</div>
			);
		}

		if ( data ) {
			return(
				<div className="graph bar">
					{ this.renderBar( data ) }
				</div>
			);
		}

		return null;
	}
}

export default Bar;
