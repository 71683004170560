import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getServices, createTenant, createTenantServices, getAdminInvitations, getTenantProducts, updateTenant, updateTenantProducts, setSelectedServices } from '../../actions/services';
import { getTenant, addUser, getBaseMe, userAdminCheck } from 'actions/user';
import { Spinner } from 'UI';
import ServiceSelector from './Step1/ServiceSelector';
import Wizard from 'containers/Wizard/Wizard';
import WizardStep from 'containers/Wizard/WizardStep';
import ConsentManager from './ConsentManager';
import TermsManagemant from './TermsManagemant';
import { setUser } from 'lib/msal';
import getEnvironment from 'lib/env';
import ConsentConfirm from './Step4/ConsentConfirm';
import { appInsights } from 'lib/appInsights';
import * as microsoftTeams from "@microsoft/teams-js";

class IndexWrapper extends Component {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);

		this.state = {
			currentPage: 0,
			loading: true,
			user: { firstName: '', lastName: '', email: '', userRoles: [] }
		};

		this.token = null;
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	/**
	 * Method to add Products and Services for Haldor Planning
	 */
	CheckIfPlanningAndSetServices(tenantId) {
		let id = getEnvironment().id;

		if (id == 'haldor_planning') {

			var tenantServices = [];
			var selectedProducts = [];

			var productDashboard = this.props.products.find((product) => {
				return product.name === "Haldor Dashboard";
			});
			selectedProducts.push(productDashboard);
			productDashboard.services.forEach((service) => {
				if (!tenantServices.find(service => { return service.ServiceId == service.id })) {
					tenantServices.push({ ServiceId: service.id });
				}
			})
			var productPlanning = this.props.products.find((product) => {
				return product.name === "Haldor Planning";
			});
			selectedProducts.push(productPlanning);
			productPlanning.services.forEach((service) => {
				if (!tenantServices.find(service => { return service.ServiceId == service.id })) {
					tenantServices.push({ ServiceId: service.id });
				}
			})

			this.props.updateTenantProducts(selectedProducts).then(() => {
				this.props.getTenantProducts();

				appInsights.trackTrace({ message: "Onboarding H.P. | trying update tenantServices" }, {
					services: tenantServices,
					tenant: this.props.tenant
				});

				// This is important since our system is depended on services
				this.props.createTenantServices(tenantServices).then(() => {
					//this.props.saveSelectedProductsAsNotes(false);
					this.props.getTenant();
				});
			});

		}
	}

	componentDidMount() {
		appInsights.trackTrace({ message: "Onboarding | Initiating onboarding proccess" });

		// Onboarding is depanded on following information from Database
		let promises = [
			this.props.userAdminCheck(),
			this.props.getTenant(),
			this.props.getBaseMe(),
			this.props.getAdminInvitations(),
			this.props.getTenantProducts(),
			this.props.getServices(),
		];

		if (this.inIframe()) {
			appInsights.trackTrace({ message: "Onboarding | User is using Teams and preparing to get user consent" });

			microsoftTeams.app.getContext().then((context) => {
				this.retrieveUser(context);

				Promise.all(promises).then(() => {
					appInsights.trackTrace({ message: "Onboarding | Checking if tenant information is present in our database" }, {
						tenant: this.props.tenant
					});

					this.CheckIfPlanningAndSetServices(context.user.tenant.id);

					if (this.props.tenant == null || this.props.tenant == "") {
						var tenantDomain = null;

						if (context.user.userPrincipalName != null && context.user.userPrincipalName.indexOf("@") > -1) {
							tenantDomain = context.user.userPrincipalName.substring(context.user.userPrincipalName.indexOf("@") + 1).toLowerCase();
						}

						if (tenantDomain == null && context.user.userPrincipalName != null && context.user.userPrincipalName.indexOf("@") > -1) {
							tenantDomain = context.user.loginHint.substring(context.user.userPrincipalName.indexOf("@") + 1).toLowerCase();
						}

						var tenant = {
							title: "",
							tenantId: context.user.tenant.id,
							tenantDomain: tenantDomain,
							tenantSPOUrl: "https://" + context.sharePointSite.teamSiteDomain,
							lcid: context.app.locale
						}

						appInsights.trackTrace({ message: "Onboarding | Trying to save tenant information in our database" }, {
							tenant: tenant
						});

						this.props.createTenant(tenant).then(() => {
							appInsights.trackTrace({ message: "Onboarding | Trying to save user information in our database" });

							this.props.addUser(this.state.user).then(() => {
								this.setState({ loading: false })
							});
						});
					} else {
						if (!this.props.currentUser) {
							appInsights.trackTrace({ message: "Onboarding | New user in tenant are using onboarding, saving user information in our database" });

							this.props.addUser(this.state.user).then(() => {
								this.setState({ loading: false })
							});
						}

						if (this.props.tenant.contactEmail == null) {
							appInsights.trackTrace({ message: "Onboarding | ConactEmail is missing in tenant, trying to update tenant" });

							this.props.updateTenant(this.props.tenant);
						}
					}

					this.navigateToSpecificPage();
				});
			});
		} else {
			appInsights.trackTrace({ message: "Onboarding | User is not using Teams and preparing to get user consent" });

			Promise.all(promises).then(() => {
				appInsights.trackTrace({ message: "Onboarding | Checking if tenant information is present in our database" }, {
					tenant: this.props.tenant
				});

				if (this.props.tenant == null || this.props.tenant == "") {
					var tenantDomain = null;

					if (tenantDomain == null && this.props.onboardingUser.email.indexOf("@") > -1) {
						tenantDomain = this.props.onboardingUser.email.substring(this.props.onboardingUser.email.indexOf("@") + 1).toLowerCase();
					}

					var tenant = {
						title: "",
						tenantId: this.props.onboardingUser.tenantId,
						tenantDomain: tenantDomain,
						tenantSPOUrl: "",
						lcid: ""
					}

					appInsights.trackTrace({ message: "Onboarding | Trying to save tenant information in our database" }, {
						tenant: tenant
					});

					this.props.createTenant(tenant).then(() => {
						appInsights.trackTrace({ message: "Onboarding | Trying to save user information in our database" });

						this.props.addUser(this.props.onboardingUser).then(() => {
							this.setState({ loading: false });
							this.navigateToSpecificPage();
						});
					});
				} else {
					if (!this.props.currentUser) {
						appInsights.trackTrace({ message: "Onboarding | New user in tenant are using onboarding, saving user information in our database" });

						this.props.addUser(this.props.onboardingUser).then(() => {
							this.setState({ loading: false });
							this.navigateToSpecificPage();
						});
					} else {
						this.navigateToSpecificPage();
					}
				}
			});
		}
	}

	/**
	 * @description User will be navigated to the proper page based on the url
	 */
	navigateToSpecificPage() {
		// if (this.props.adminInvitations != null) {
		// 	if (this.props.adminInvitations.sentByMe && window.location.search.indexOf('admin') == -1) {
		// 		this.goToPage(1);
		// 	}
		// }

		this.setState({ loading: false });

		if (window.location.search.indexOf('admin') > -1) {
			this.goToPage(2);
		}
	}

	/**
	 * @description This method is used to retrieve user information based on Teams Context
	 * @param {Object} context 
	 */
	retrieveUser(context) {
		if (context.user.userPrincipalName) {
			var user = this.state.user;
			user.email = context.user.userPrincipalName;
			user.firstName = context.user.userPrincipalName.split('@')[0];

			if (user.firstName.indexOf(".") > -1) {
				var firstName = user.firstName.split('.')[0];
				var lastName = user.firstName.split('.')[1];

				user.firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
				user.lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
			}
		}

		if (context.user.licenseType != null) {
			user.userRoles.push(context.user.licenseType);
		}

		this.setState({ user: user });
	}

	submit(event) {
		this.setState({ loading: false });
		this.goToPage(2);
		return;
	}

	/**
	 * @description Take user to particular page within the onboarding process
	 * @param {*} page 
	 * @returns 
	 */
	goToPage = (page) => {
		return new Promise(async resolve => {
			let product = getEnvironment().id;
			if (product == 'haldor_planning') {
				page = --page;
			}
			resolve(1);
			this.setState({ loading: false });
			this.setState({ currentPage: page });
		});
	}

	render() {
		let product = getEnvironment().id;
		return <div style={{ background: '#FFF', marginLeft: 'auto', marginRight: 'auto', width: '80%', paddingTop: '3%', paddingBlockStart: '3%', height: '-webkit-fill-available' }}>
			{this.state.loading ? <Spinner center></Spinner> :
				product == 'haldor_planning' ?
					<div>
						<Wizard disableWizardStepNavigation={true} currentPage={this.state.currentPage} goToPage={(i) => { this.goToPage(i) }}>
							<WizardStep name="1">
								<ConsentManager onSubmit={this.goToPage}></ConsentManager>
							</WizardStep>

							<WizardStep name="2">
								<TermsManagemant onSubmit={this.goToPage}></TermsManagemant>
							</WizardStep>

							<WizardStep name="3">
								<ConsentConfirm onSubmit={this.goToPage}></ConsentConfirm>
							</WizardStep>
						</Wizard>
					</div> :
					<div>
						<Wizard disableWizardStepNavigation={true} currentPage={this.state.currentPage} goToPage={(i) => { this.goToPage(i) }}>
							<WizardStep name="1">
								<ServiceSelector onSubmit={this.goToPage}></ServiceSelector>
							</WizardStep>

							<WizardStep name="2">
								<ConsentManager onSubmit={this.goToPage}></ConsentManager>
							</WizardStep>

							<WizardStep name="3">
								<TermsManagemant onSubmit={this.goToPage}></TermsManagemant>
							</WizardStep>

							<WizardStep name="4">
								<ConsentConfirm onSubmit={this.goToPage}></ConsentConfirm>
							</WizardStep>
						</Wizard>
					</div>}
		</div>
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentBaseUser,
		onboardingUser: state.user.onboardingUser,
		tenant: state.user.tenant,
		products: state.Services.availableProducts,
		adminInvitations: state.Services.adminInvitations,
	};
}

export default connect(mapStateToProps, {
	getServices,
	setSelectedServices,
	createTenant,
	createTenantServices,
	getTenant,
	addUser,
	getBaseMe,
	getTenantProducts,
	userAdminCheck,
	updateTenant,
	updateTenantProducts,
	getAdminInvitations
})(reduxForm({
	form: 'AddService',
	destroyOnUnmount: true,
})(IndexWrapper));
