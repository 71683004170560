import * as actions from 'actions/ScheduleFilter';

const INITIAL_STATE = {
	allFilter: false,
	activitiesFilter: {},
	userFilter: [],
	displayMySchedule: true,
	selectedPeople: 0,
	totalPeople: 0,
	totalOwners: 0,
	totalGroups: 0,
	allPeopleSelected: false,
	ownersSelected: 0,
	groupsSelected: 0,
	selectedGroups: {},
	customGroup: [],
	storedSearch: [],
	advancedCalendarActive: false,
};

const isGroupActive = (group, id) => {
	if (Object.keys(group).length === 0) {
		return true;
	}

	return group[id];
}

const getUniqueStudents = (userFilter) => {
	let selectedStudents = [];
	Object.values(userFilter).forEach(group => {
		Object.values(group.students).forEach(student => {
			if (!selectedStudents.some(s => s === student.id)) {
				selectedStudents.push(student.id);
			}
		});
	});
	return selectedStudents.length;
}
const getUniqueOwners = (userFilter) => {
	let selectedOwners = [];
	Object.values(userFilter).forEach(group => {
		Object.values(group.owners).forEach(owner => {
			if (!selectedOwners.some(o => o === owner.id)) {
				selectedOwners.push(owner.id);
			}
		});
	});
	return selectedOwners.length;
}

export default function (state = INITIAL_STATE, action) {
	let newUserFilter = null;
	switch (action.type) {
		case actions.UPDATE_FILTER:
			if (action.payload.allData) {
				return { ...state, activitiesFilter: action.payload.obj, allFilter: true };
			}

			return {
				...state,
				activitiesFilter: {
					...state.activitiesFilter,
					...action.payload.obj,
				},
				allFilter: false,
			};

		case actions.TOGGLE_ADVANCED_CALENDAR:
			return { ...state, advancedCalendarActive: !state.advancedCalendarActive };

		case actions.CLEAR_FILTER:
			return {
				...state,
				activitiesFilter: action.payload.filterData,
				allFilter: false,
			};

		case actions.TOGGLE_MY_SCHEDULE:
			return {
				...state,
				displayMySchedule: !state.displayMySchedule,
			};

		/* handle group search function section */
		case actions.SET_TOTAL_AMOUNT:
			return {
				...state,
				totalPeople:
					state.totalPeople > action.payload.countTotal
						? state.totalPeople
						: action.payload.countTotal,
			};

		case actions.REMOVE_SEARCHED_GROUP:
			let oSearch = action.payload.obj;
			newUserFilter = state.userFilter.filter((x) => x.id !== action.payload.group);
			return {
				...state,
				selectedPeople: getUniqueStudents(newUserFilter),
				ownersSelected: getUniqueOwners(newUserFilter),
				groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
				userFilter: newUserFilter,
				storedSearch: state.storedSearch.filter((x) => x.id !== action.payload.group),
				totalPeople:
					state.totalPeople - oSearch.students?.length <= 0
						? 0
						: state.totalPeople - oSearch.students?.length,
				totalOwners:
					state.totalOwners - oSearch.owners?.length <= 0
						? 0
						: state.totalOwners - oSearch.owners?.length,
				totalGroups: state.totalGroups - 1 <= 0 ? 0 : state.totalGroups - 1,
			};

		case actions.TOGGLE_ALL_USERS:
			if (state.allPeopleSelected) {
				return {
					...state,
					selectedPeople: 0,
					userFilter: [],
					allPeopleSelected: false,
				};
			}

			return {
				...state,
				allPeopleSelected: !state.allPeopleSelected,
			};

		case actions.SET_CUSTOM_GROUP:
			return {
				...state,
				customGroup: action.payload.group,
			};

		case actions.SET_SEARCH_GROUP:
			return {
				...state,
				storedSearch: [...state.storedSearch, action.payload.group.userId],
			};

		case actions.SET_ALL_SEARCHED:
			newUserFilter = [...state.userFilter, ...action.payload.data];
			return {
				...state,
				userFilter: newUserFilter,
				selectedPeople: getUniqueStudents(newUserFilter),
				ownersSelected: getUniqueOwners(newUserFilter),
				groupsSelected: state.groupsSelected + action.payload.data.length,
				selectedGroups: { ...state.selectedGroups, ...action.payload.groupsChecked },
			};

		case actions.SET_ALL_GROUPS:
			const items = JSON.parse(JSON.stringify(action.payload.data)).map((section) => {
				section.owners = [];
				return section;
			});

			newUserFilter = [...state.userFilter, ...items];
			if (action.payload.total > state.totalPeople) {
				return {
					...state,
					userFilter: newUserFilter,
					customGroup: action.payload.data,
					totalPeople: state.totalPeople + action.payload.total ?? 0,
					groupsSelected: state.groupsSelected + action.payload.totalGroups ?? 0,
					selectedPeople: getUniqueStudents(newUserFilter),
					ownersSelected: getUniqueOwners(newUserFilter),
					totalGroups: state.totalGroups + action.payload.totalGroups ?? 0,
					totalOwners: state.totalOwners + action.payload.totalOwners ?? 0,
					selectedGroups: { ...state.selectedGroups, ...action.payload.groupsChecked },
				};
			}

			if (!action.payload.totalPeople) {
				return {
					...state,
					userFilter: newUserFilter,
					customGroup: action.payload.data,
					selectedPeople: getUniqueStudents(newUserFilter),
					ownersSelected: getUniqueOwners(newUserFilter),
					groupsSelected: state.totalGroups,
					selectedGroups: { ...state.selectedGroups, ...action.payload.groupsChecked },
				};
			}

			return {
				...state,
				userFilter: newUserFilter,
				customGroup: action.payload.data,
				selectedPeople: getUniqueStudents(newUserFilter),
				ownersSelected: getUniqueOwners(newUserFilter),
				selectedGroups: { ...state.selectedGroups, ...action.payload.groupsChecked },
			};

		case actions.ADD_SECTION:
			if (action.payload.data?.id) {
				// Sort students and owners
				action.payload.data?.students?.sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName));
				action.payload.data?.owners?.sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName));

				let item = JSON.parse(JSON.stringify(action.payload.data));
				item.owners = [];

				newUserFilter = action.payload.checkBox ?
					state.userFilter.filter((x) =>
						x.id !== action.payload.data.id
					).concat(item)
					: state.userFilter;

				return {
					...state,
					selectedGroups: action.payload.checkBox ? {
						...state.selectedGroups,
						[action?.payload?.data?.id]: true
					}
						: state.selectedGroups,
					totalGroups: state.totalGroups + 1,
					groupsSelected: state.groupsSelected + 1,
					totalOwners: state.totalOwners + item.owners.filter((x) =>
						x.id !== action.payload.currentUser
					).length,
					selectedPeople: getUniqueStudents(newUserFilter),
					totalPeople: state.totalPeople + item.students.length,
					customGroup: action.payload.search
						? state.customGroup
						: state.customGroup
							.filter((x) => x.id !== action.payload.data.id)
							.concat(action.payload.data),
					userFilter: newUserFilter,
					storedSearch: !action.payload.search ?
						state.storedSearch
						: state.storedSearch
							.filter((x) => x.id !== action.payload.data.id)
							.concat(action.payload.data),
				};
			}

			return state;

		case actions.SELECT_ALL_USER:
			newUserFilter = state.userFilter
				.filter((x) => x.id !== action.payload.id)
				.concat(action.payload.newArr);

			return {
				...state,
				ownersSelected: getUniqueOwners(newUserFilter),
				selectedPeople: getUniqueStudents(newUserFilter),
				userFilter: newUserFilter,
				selectedGroups: { ...state.selectedGroups, [action.payload.id]: true },
				groupsSelected: state.groupsSelected + 1,
			};

		case actions.REMOVE_ALL_USER:
			newUserFilter = [...action.payload.removed];
			if (action.payload.data?.students?.length) {
				return {
					...state,
					selectedGroups: { ...state.selectedGroups, [action?.payload?.data?.id]: false },
					ownersSelected: getUniqueOwners(newUserFilter),
					selectedPeople: getUniqueStudents(newUserFilter),
					allPeopleSelected: false,
					userFilter: newUserFilter,
					groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
				};
			}

			return {
				...state,
				groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
				selectedGroups: { ...state.selectedGroups, [action?.payload?.data?.id]: false },
				userFilter: newUserFilter,
			};

		case actions.CHANGE_SELECTED_PEOPLE:
			newUserFilter = state.userFilter
				.filter((x) => x.id !== action.payload.selectedUser.id)
				.concat(action.payload.selectedUser);
			return {
				...state,
				selectedPeople: getUniqueStudents(newUserFilter),
				allPeopleSelected: false,
				selectedGroups: { ...state.selectedGroups, [action?.payload?.selectedUser?.id]: true },
				userFilter: newUserFilter,
				groupsSelected: isGroupActive(state.selectedGroups, action?.payload?.selectedUser?.id) ?
					state.groupsSelected + 1 : state.groupsSelected,
			};

		case actions.CHANGE_SELECTED_OWNER:
			newUserFilter = state.userFilter
				.filter((x) => x.id !== action.payload.selectedUser.id)
				.concat(action.payload.selectedUser);
			return {
				...state,
				ownersSelected: getUniqueOwners(newUserFilter),
				allPeopleSelected: false,
				selectedGroups: { ...state.selectedGroups, [action?.payload?.selectedUser?.id]: true },
				userFilter: newUserFilter,
			};

		case actions.REMOVE_SELECTED_PEOPLE:
			if (
				action.payload.selectedUser.students.length === 0 &&
				action.payload.selectedUser.owners.length === 0
			) {
				newUserFilter = state.userFilter.filter((x) => x.id !== action.payload.selectedUser.id);
				return {
					...state,
					allPeopleSelected: false,
					groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
					selectedGroups: { ...state.selectedGroups, [action.payload.selectedUser.id]: false },
					userFilter: newUserFilter,
					selectedPeople: getUniqueStudents(newUserFilter),
				};
			}

			newUserFilter = state.userFilter
				.filter((x) => x.id !== action.payload.selectedUser.id)
				.concat(action.payload.selectedUser);
			return {
				...state,
				allPeopleSelected: false,
				groupsSelected: state.groupsSelected,
				selectedGroups: { ...state.selectedGroups, [action.payload.selectedUser.id]: true },
				userFilter: newUserFilter,
				selectedPeople: getUniqueStudents(newUserFilter),
			};

		case actions.REMOVE_SELECTED_OWNER:
			if (
				action.payload.selectedUser.students.length === 0 &&
				action.payload.selectedUser.owners.length === 0
			) {
				newUserFilter = state.userFilter.filter((x) => x.id !== action.payload.selectedUser.id);
				return {
					allPeopleSelected: false,
					...state,
					groupsSelected: state.groupsSelected - 1 <= 0 ? 0 : state.groupsSelected - 1,
					selectedGroups: { ...state.selectedGroups, [action.payload.selectedUser.id]: false },
					userFilter: newUserFilter,
					ownersSelected: getUniqueOwners(newUserFilter),
				};
			}

			newUserFilter = state.userFilter
				.filter((x) => x.id !== action.payload.selectedUser.id)
				.concat(action.payload.selectedUser);
			return {
				...state,
				allPeopleSelected: false,
				groupsSelected: state.groupsSelected,
				selectedGroups: { ...state.selectedGroups, [action.payload.selectedUser.id]: true },
				userFilter: newUserFilter,
				ownersSelected: getUniqueOwners(newUserFilter),
			};

		case actions.REMOVE_ALL:
			return {
				...state,
				userFilter: [],
				ownersSelected: 0,
				selectedPeople: 0,
				groupsSelected: 0,
				allPeopleSelected: false,
				selectedGroups: {},
			};

		// Clear everything, used for when changing school
		case actions.CLEAR_ALL:
			return {
				...state,
				userFilter: [],
				ownersSelected: 0,
				selectedPeople: 0,
				groupsSelected: 0,
				allPeopleSelected: false,
				selectedGroups: {},
				customGroup: [],
				totalPeople: 0,
				totalOwners: 0,
				totalGroups: 0,
				storedSearch: [],
				advancedCalendarActive: false,
			};

		default:
			return state;
	}
}
