import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Moment from "moment";
import { getSchoolDetails, updateSchoolType } from "actions/schools";
import { getActiveSchool } from 'helpers/localstorage';
import SectionTitle from 'components/Presentation/SectionTitle';
import { DataList, Expandable } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Flex, Skeleton, Menu, Icon, TooltipMenu, Button, getActiveLanguage } from '@haldor/ui';
import { setActiveSchool, getMe } from 'actions/user';
import User from '_class/User';

class SchoolAdministration extends Component {
	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			school: [],
			schools: [],
			typeOfSchools: [
				{ id: 1, type: "PRESCHOOL" },
				{ id: 2, type: "COMPULSORY_SCHOOL" },
				{ id: 3, type: "UPPER_SECONDARY_EDUCATION" },
				{ id: 4, type: "ADULT_EDUCATION" }
			]
		}
	}

	static contextTypes = {
		router: PropTypes.object,
	};

	changeSchoolType = (school, schoolType) => {
		this.props.updateSchoolType(school.id, schoolType);
	}

	renderSchoolRow = (school) => {
		const dropdownTrigger = (<div className="dropdown--trigger">
			{this.props.translate(school.type)} <Icon name="Chevron" />
		</div>);

		return (
			<RowItem key={school.id} >
				<RowCell title={this.props.translate('name')}>
					{school.title}
				</RowCell>

				<RowCell title={this.props.translate('type')}>
					<TooltipMenu trigger={dropdownTrigger}>
						{this.state.typeOfSchools.map(type => {
							if (type.type != school.type) {
								return <TooltipMenu.Item key={'type-' + school.id + "-" + type.id} onClick={() => this.changeSchoolType(school, type.type)}>
									{this.props.translate(type.type)}
								</TooltipMenu.Item>
							}
						})}
					</TooltipMenu>
				</RowCell>
			</RowItem>
		);
	}

	render() {
		const user = new User(this.props.currentUser);
		const activeSchool = getActiveSchool(this.props.currentUser.id);

		if (this.props.school == null) {
			return (
				<div className="single-section">
					<SectionTitle>
						<Skeleton />
					</SectionTitle>

					<Flex>
						<div style={{ flex: 1 }}>
							<div>
								<Skeleton />
							</div>
						</div>
					</Flex>
				</div>
			)
		}

		let schools = [];
		this.props.currentUser.schools.forEach((school) => {
			if (school.id == this.props.school.id) {
				schools.push(this.props.school);
			} else {
				schools.push(school);
			}
		});

		return (
			<div className="single-section">
				<DataList
					data={schools}
					renderRow={this.renderSchoolRow}
					emptyData={() => (
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					)}
				/>
			</div>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		translate: translate(state.Languages.translations),
		tenant: state.user.tenant,
		currentUser: state.user.currentUser,
		school: state.School.active
	};
}

export default connect(mapStateToProps, {
	setActiveSchool,
	getSchoolDetails,
	updateSchoolType,
})(SchoolAdministration);