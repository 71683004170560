import api from 'lib/api';
import { get_operations } from 'lib/patch';

export const GET_ASSESSMENTS_COURSES = 'GET_ASSESSMENTS_COURSES';
export const GET_ASSESSMENT = 'GET_ASSESSMENT';
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const CREATE_ASSESSMENTS = 'CREATE_ASSESSMENTS';
export const DELETE_ASSESSMENT = 'ASSESSMENTS_DELETE_ASSESSMENTS';
export const DELETE_ASSESSMENTS_DETAIL = 'ASSESSMENTS_DELETE_ASSESSMENTS_DETAIL';
export const GET_STUDENT_ASSESSMENTS = 'GET_STUDENT_ASSESSMENTS';
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT';
export const UPDATE_ASSESSMENT_STATUS = 'UPDATE_ASSESSMENT_STATUS';
export const GET_STUDENT_ASSESSMENT_OVERVIEW = 'GET_STUDENT_ASSESSMENT_OVERVIEW';
export const GET_MATRIX_ASSESSMENT_STATISTIC = 'GET_MATRIX_ASSESSMENT_STATISTIC';
export const GET_STUDENT_COURSES_GOALS = 'ASSESSMENTS_GET_STUDENT_COURSES_AND_GOALS';
export const GET_STUDENT_ASSESSMENT_STATISTICS = 'ASSESSMENTS_GET_STUDENT_ASSESSMENT_STATISTICS';

export const RESET_MATRIX_ASSESSMENT_STATISTICS = 'RESET_MATRIX_ASSESSMENT_STATISTICS';
export const CLEAR_MATRIX_ASSESSMENT_OVERVIEW = 'CLEAR_MATRIX_ASSESSMENT_OVERVIEW';
export const RESET_USER_ASESSMENT_STATISTIC = 'RESET_USER_ASSESSMENT_STATISTIC';
export const GET_SECTIONS_FROM_ASSESSMENTS = 'GET_SECTIONS_FROM_ASSESSMENTS';
export const GET_USER_ASSESSMENTS_STATISTIC_PROGRESS = 'GET_USER_ASSESSMENTS_STATISTIC_PROGRESS';

export const GET_ASSESSMENTS_BY_STUDENT = 'GET_ASSESSMENTS_BY_STUDENT';
export const GET_ASSESSMENTS_BY_STUDENT_AND_SECTION = 'GET_ASSESSMENTS_BY_STUDENT_AND_SECTION';
export const CLEAR_ASSESSMENTS = 'CLEAR_ASSESSMENTS';
export const EXPORT_ASSESSMENT = 'ASSESSMENTS_EXPORT_ASSESSMENT';
export const EXPORT_ASSESSMENT_FOR_SECTION = 'EXPORT_ASSESSMENT_FOR_SECTION';
export const EXPORT_ASSESSMENT_FOR_SCHOOLID = 'EXPORT_ASSESSMENT_FOR_SCHOOLID';
export const CREATE_ASSESSMENTS_STUDENT = 'CREATE_ASSESSMENTS_STUDENT';


export const getCoursesUsedWithinMySections = (from, to) => ({
	type: GET_ASSESSMENTS_COURSES,
	payload: new Promise((resolve) => {
		api.get(`assessments/v2/courses?fromDate=${from}&toDate=${to}`).then((response) => {
			resolve(response.data);
		});
	}),
});


export const getAssessment = (type, id, userId) => ({
	type: GET_ASSESSMENT,
	payload: new Promise((resolve) => {
		api.get(`${type}/${id}/assessments/${userId}/`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const createExportAssessment = (studentId) => ({
	type: EXPORT_ASSESSMENT,
	payload: new Promise(resolve => {
		api.post(`assessments/export?fromStudentId=${studentId}`, "").then((response) => {
			resolve(response.data);
		})
	}),
});

export const createExportAssessmentForSectionId = (sectionId) => ({
	type: EXPORT_ASSESSMENT_FOR_SECTION,
	payload: new Promise(resolve => {
		api.post(`assessments/export?fromSectionId=${sectionId}`, "").then((response) => {
			resolve(response.data);
		})
	}),
});

export const createExportAssessmentForSchoolId = (schoolId) => ({
	type: EXPORT_ASSESSMENT_FOR_SCHOOLID,
	payload: new Promise(resolve => {
		api.post(`assessments/export?fromSchoolId=${schoolId}`, "").then((response) => {
			resolve(response.data);
		})
	}),
});

export const getPlanAssessments = (id) => ({
	type: GET_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/v2/plans/${id}`).then((response) => {
			if (response.status > 300) {
				resolve([]);
				return false;
			}

			resolve(response.data);
		});
	}),
});

export const createAssessments = (values) => ({
	type: CREATE_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.post('assessments/v2', values).then(async (response) => {
			resolve(response.data);
		});
	}),
});

export const createStudentAssessment = (values) => ({
	type: CREATE_ASSESSMENTS_STUDENT,
	payload: new Promise((resolve) => {
		api.post('assessments/v2/student', values).then(async (response) => {
			resolve(response.data);
		})
	}),
});

export const updateAssessment = (values, assessment) => ({
	type: UPDATE_ASSESSMENT,
	payload: new Promise((resolve) => {
		const operations = get_operations(assessment, values);

		if (operations.length > 0) {
			api.patch(`assessments/v2/${values.id}`, assessment, operations).then((response) => {
				resolve(response.data);
			});
		} else {
			resolve(values);
		}
	}),
});

export const updateAssessmentStatus = (assessmentId, status) => ({
	type: UPDATE_ASSESSMENT_STATUS,
	payload: new Promise((resolve) => {
		api.put(`assessments/status?assessmentId=${assessmentId}&status=${status}`, null).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteAssessment = (assessmentId) => ({
	type: DELETE_ASSESSMENT,
	payload: new Promise((resolve) => {
		api.delete("assessments/" + assessmentId).then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteAssessmentDetails = (assessmentId) => ({
	type: DELETE_ASSESSMENTS_DETAIL,
	payload: new Promise((resolve) => {
		api.delete("assessments/" + assessmentId + "/details").then((response) => {
			resolve(response.data);
		});
	}),
});

export const deleteAssessmentDetail = (assessmentId, detailId) => ({
	type: DELETE_ASSESSMENTS_DETAIL,
	payload: new Promise((resolve) => {
		api.delete(`assessments/${assessmentId}/details/${detailId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAssesmentByGroup = (userid, groupid) => ({
	type: GET_STUDENT_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/section/${groupid}/student/${userid}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssignmentAssessment = (id) => ({
	type: GET_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/v2/assignments/${id}`).then((response) => {
			if (response.status != 200) {
				resolve(null);
			} else {
				resolve(response.data);
			}
		});
	}),
});

export const getStudentAssessmentOverview = (sectionId, studentId) => ({
	type: GET_STUDENT_ASSESSMENT_OVERVIEW,
	payload: new Promise((resolve) => {
		api.get(`assessments/section/${sectionId}/student/${studentId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStudentAssessmentOverviewByDate = (sectionId, studentId, start, end) => ({
	type: GET_STUDENT_ASSESSMENT_OVERVIEW,
	payload: new Promise((resolve) => {
		api.get(`assessments/section/${sectionId}/student/${studentId}/start/${start}/end/${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getMatrixAssessmentStatistic = (sectionId, studentId, matrixId) => ({
	type: GET_MATRIX_ASSESSMENT_STATISTIC,
	payload: new Promise(resolve => {
		api.get(`assessments/section/${sectionId}/student/${studentId}/matrix/${matrixId}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const resetMatrixAssessmentStatistics = () => ({
	type: RESET_MATRIX_ASSESSMENT_STATISTICS,
	payload: []
});

export const clearAssessments = () => ({
	type: CLEAR_ASSESSMENTS,
	payload: []
});

export const resetUserAssessmentStatistic = () => ({
	type: RESET_USER_ASESSMENT_STATISTIC,
	payload: true,
});

export const getMatrixAssessmentOverviewByDate = (sectionId, studentId, matrixId, start, end) => ({
	type: GET_MATRIX_ASSESSMENT_STATISTIC,
	payload: new Promise(resolve => {
		api.get(`assessments/section/${sectionId}/student/${studentId}/matrix/${matrixId}/start/${start}/end/${end}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearMatrixAssessmentOverview = () => {
	return {
		type: CLEAR_MATRIX_ASSESSMENT_OVERVIEW,
		payload: true,
	};
};

export const getUserAssessmentStatistics = (studentId, start, end) => {
	return {
		type: GET_STUDENT_ASSESSMENT_OVERVIEW,
		payload: new Promise(resolve => {
			api.get(`assessments/student/${studentId}/start/${start}/end/${end}`).then((response) => {
				resolve(response.data);
			});
		}),
	};
}

export const getSectionsFromAssessments = (userId, startDate, endDate) => ({
	type: GET_SECTIONS_FROM_ASSESSMENTS,
	payload: new Promise((resolve) => {
		api.get(`assessments/${userId}/sections/start/${startDate}/end/${endDate}`).then((response) => {
			if (response.status == 204) {
				resolve(null);
				return true;
			}

			resolve(response.data);
		});
	}),
});

export const getUserAssessmentStatisticsForProgress = (userId, start, end) => ({
	type: GET_USER_ASSESSMENTS_STATISTIC_PROGRESS,
	payload: new Promise((resolve) => {
		api.get(`assessments/${userId}/start/${start}/end/${end}/assessmentStatistics`).then((response, err) => {
			resolve(response.data);
		});
	}),
});

export const getAssessmentsByStudentAndSection = (sectionId, studentId, startDate, endDate) => ({
	type: GET_ASSESSMENTS_BY_STUDENT_AND_SECTION,
	payload: new Promise((resolve) => {
		api.get(`assessments/section/${sectionId}/student/${studentId}/start/${startDate}/end/${endDate}/assessments`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getAssessmentsByStudent = (studentId, startDate, endDate) => ({
	type: GET_ASSESSMENTS_BY_STUDENT,
	payload: new Promise((resolve) => {
		api.get(`assessments/student/${studentId}/start/${startDate}/end/${endDate}/assessments`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserSummary = (studentId, startDate, endDate, params = {}) => ({
	type: GET_STUDENT_COURSES_GOALS,
	payload: new Promise((resolve) => {
		let url = `assessments/v2/users/${studentId}/summary/data?startDate=${startDate}&endDate=${endDate}`;

		if (params.courseId != null) {
			url += `&courseId=${params.courseId}`;
		}

		if (params.sectionId != null) {
			url += `&sectionId=${params.sectionId}`;
		}

		if (params.type != null) {
			url += `&type=${params.type}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getUserStatisticsDetailed = (studentId, startDate, endDate, params = {}) => ({
	type: GET_STUDENT_ASSESSMENT_STATISTICS,
	payload: new Promise((resolve) => {
		let url = `assessments/v2/users/${studentId}/statistics/detailed?startDate=${startDate}&endDate=${endDate}`;

		if (params.sectionId != null) {
			url += `&sectionId=${params.sectionId}`;
		}

		if (params.courseId != null) {
			url += `&courseId=${params.courseId}`;
		}

		if (params.type != null) {
			url += `&type=${params.type}`;
		}

		api.get(url).then((response) => {
			resolve(response.data);
		});
	}),
});