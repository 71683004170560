import React from 'react';
import ColorScale from '_class/ColorScale';
import { useTranslate } from 'lib/translate';

import DisplayCell from './DisplayCell';

const DisplayMatrix = ({ matrix, statistic }) => {
	const translate = useTranslate();

	if (matrix == null) {
		return null;
	}

	delete matrix.paragraphSet;

	/**
	 * @param {object} row
	 */
	const getColors = (row) => {
		let showRed = true;

		if (matrix.disableNotAchieved) {
			showRed = false;
		}

		return new ColorScale(getRowCells(row).length).get(showRed);
	}

	/**
	 * @param {object} row
	 * @returns {array} cells
	 */
	const getRowCells = (row) => {
		let cells = JSON.parse(JSON.stringify(row.cells));

		if (!matrix.disableNotAchieved) {
			cells.unshift({
				text: translate('Not achieved'),
				id: 'F',
			});
		}

		return cells;
	}

	/**
	 * @param {object} row
	 * @param {number} index
	 * @returns {object} assessmentDetail
	 */
	const getCellAssessmentDetails = (row, index) => {
		if (statistic == null) {
			return [];
		}

		let foundEntry = statistic.statistics.find((stat) => stat.index == index);
		if (foundEntry != null) {
			return foundEntry.assessmentDetails.filter((detail) =>
				detail.assessmentBlockPartRow.referenceId == row.id
			);
		}

		return [];
	}

	return (
		<div className="block--assessment-block consume">
			<div className="display-matrix">
				{matrix._ParagraphSet.map((paragraph) => {
					return <div className="paragraph" key={paragraph.paragraphNr}>
						{paragraph.title != null ? <div className="matrix-paragraph-title">{paragraph.title}</div> : null}
						{paragraph.subtitle != null ? <div className="matrix-paragraph-subtitle">{paragraph.subtitle}</div> : null}
						{paragraph.rows.map((row) => {
							const colors = getColors(row);

							return <div className="df row" key={row.id}>
								{paragraph != null ?
									<div>
										{paragraph.paragraphNr}.{row.sentenceNr}
									</div>
									: null}

								<div className="column-text">
									{row.text}
								</div>

								<div className="cells df">
									{getRowCells(row).map((cell, index) => {
										const assessmentDetails = getCellAssessmentDetails(row, index);
										return <DisplayCell
											key={cell.id}
											cell={cell}
											color={colors[index]}
											assessmentDetails={assessmentDetails}
										/>
									})}
								</div>
							</div>
						})}
					</div>
				})}
			</div>
		</div>
	);
}

export default DisplayMatrix;