import React, { Component } from 'react';

class NotFound extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="container">
				<h1 style={{ textAlign: 'center', width: '100%', marginBottom: '1em' }}>Sidan hittades inte!</h1>
				<h3 style={{ textAlign: 'center', width: '100%' }}>404 - Sidan kanske inte finns?</h3>
			</div>
		);
	}
}

export default (NotFound);
