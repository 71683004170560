import { getStandardCourses } from 'actions/courses';
import { getSections } from 'actions/sections';
import { CardsContainer } from 'components/Cards/Card';
import LoadingCards from 'components/Cards/LoadingCard';
import Course from 'components/Presentation/Cards/Course';
import SectionTitle from 'components/Presentation/SectionTitle';
import CourseForm from 'containers/Forms/CourseForm';
import Modal from 'containers/Modals/Modal';
import { isUserCurriculumAdmin } from 'helpers/user';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setPageTitle, setPageActions } from 'actions/header';


class StandardCourses extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			isLoading:false,
		};
	}

	UNSAFE_componentWillMount = () => {
		this.props.setPageTitle(this.props.translate('courses-header-overview'));

		if (isUserCurriculumAdmin(this.props.currentUser)) {
			let actions = [];
			actions.push({
				type: 'button',
				value: this.props.translate('Create'),
				buttonType: 'primary',
				onClick: this.openModal,
			});

			this.props.setPageActions(actions);
		}
	}

	componentDidMount = () => {
		if (isUserCurriculumAdmin(this.props.currentUser)) {
			this.setState({isLoading:true})
			this.props.getStandardCourses("PREDEFINED_COURSE").then(() => {
				this.setState({isLoading:false})
			})
		}
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = (reload, skipPrompt) => {
		if (skipPrompt) {
			this.setState({ modalIsOpen: false });
		} else {
			this.setState({ modalIsOpen: false });
		}
	}

	render() {
		if (!isUserCurriculumAdmin(this.props.currentUser)) {
			return <Redirect to="/" />
		}

		return (
			<div className="my_groups">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={this.props.translate('create-course')}>
					<CourseForm
						onClose={this.closeModal}
						editView={false}
					/>
				</Modal>

				<SectionTitle>
					<Link to="search/courses" className="see-more">
						{this.props.translate('search-courses')}
					</Link>
				</SectionTitle>

               {this.state.isLoading ?
			   <LoadingCards count={3} />
			   :
				<CardsContainer key="myCourses-1">
					{this.props.courses.map((course) => {
						return (
							<Course key={course.id} course={course} />
						);
					})}
				</CardsContainer>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		courses: state.Courses.standardCourses,
		translate: translate(state.Languages.translations),
		activeSchool: state.user.activeSchool,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps, {
	getSections,
	getStandardCourses,
	setPageTitle,
	setPageActions,
})(StandardCourses);
