import React, { useEffect, useState, useMemo, useRef } from 'react';
import { DataGrid, GridToolbarContainer, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, Stack, TablePagination } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignmentEvents, setCurrentAssignmentEventPage, setAssignmentEventPageSort, setAssignmentEventPageFilter, clearAssignmentEventPaginationFromStore } from 'actions/assignments';
import NoAssginmentsFoundLayout from './NoItemsFoundLayout';
import SelectMultiple from 'containers/Overview/TableFilterComponents/SelectMultiple';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import { Icon, translate, translate as translateConstructor } from '@haldor/ui';
import { getRootUrl } from 'helpers/url';


const MyAssignmentsLogs = ({ groupId }) => {
	const translations = useSelector(state => state.Languages.translations);
	const currentPage = useSelector(state => state.AssignmentEventsPagination.currentPage);
	const sortField = useSelector(state => state.AssignmentEventsPagination.sortField);
	const sortOrder = useSelector(state => state.AssignmentEventsPagination.sortOrder);
	const filterField = useSelector(state => state.AssignmentEventsPagination.filterField);
	const filterValues = useSelector(state => state.AssignmentEventsPagination.filterValues);
	const lastFetched = useSelector(state => state.AssignmentEventsPagination.lastFetched);

	const dispatch = useDispatch();

	let translate = translateConstructor(translations);
	const history = useHistory();
	const apiRef = useGridApiRef();
	const currentTableFilter = useRef(null);

	const [paginationModel, setPaginationModel] = useState({
		page: currentPage > 1 ? currentPage - 1 : 0,
		pageSize: 15,
		sortField: sortField,
		sortOrder: sortOrder,
		filterField: groupId ? 'sectionIds' : filterField,
		filterValues: groupId ? [groupId] : filterValues,
	});

	/**
	 * This will be triggered when the lastFetched is updated.
	 */
	useEffect(() => {
		/**
		 * if lastFetched is becoming null, then we need to reset the page in paginationModel to the initial state.
		 */
		if (lastFetched == null) {
			setPaginationModel({ ...paginationModel, page: 0 });
		}
	}, [lastFetched]);

	/**
	 * This useEffect will be triggered when the component is mounted and set the sort and filter values in the table.
	 */
	useEffect(() => {
		if (paginationModel.sortField != null && paginationModel.sortOrder != null && paginationModel.filterValues.length > 0) {
			apiRef.current.setSortModel([{ field: paginationModel.sortField, sort: paginationModel.sortOrder }]);
		}

		if (paginationModel.filterField != null && paginationModel.filterValues != null) {
			currentTableFilter.current = {
				[paginationModel.filterField]: paginationModel.filterValues
			};

			let sectionIds = null;

			if (groupId != null) {
				sectionIds = [groupId];
			} else {
				sectionIds = paginationModel.filterValues != null && paginationModel.filterValues.length > 0 ? paginationModel.filterValues.map(item => item.id) : null;
			}

			setPaginationModel({ ...paginationModel, filterField: paginationModel.filterField, filterValues: sectionIds });
		}

		// Check if lastFetched is 5 min old, then clear the pagination from the store
		if (lastFetched != null && moment().diff(lastFetched, 'minutes') > 5) {

			/**
			 * This will set lastFetched to null and clear the pagination from the store.
			 */
			dispatch(clearAssignmentEventPaginationFromStore());
		}
	}, []);

	/**
	 * FetchData will be triggered every time the paginationModel is updated.
	 */
	const {
		isLoading,
		rows,
		info: { hasNextPage, totalRowCount },
	} = fetchData(paginationModel);

	const onPaginationModelChange = (updatedPageInfo) => {
		if (updatedPageInfo != null) {
			setPaginationModel({ ...paginationModel, page: updatedPageInfo.page, pageSize: updatedPageInfo.pageSize })
		}
	}

	const onSortModelChange = (details) => {
		if (details && details.length > 0) {
			const sortField = details[0].field;
			const sortOrder = details[0].sort;

			dispatch(setAssignmentEventPageSort(sortField, sortOrder));
			setPaginationModel({ ...paginationModel, sortField, sortOrder });
		} else {
			dispatch(setAssignmentEventPageSort(null, null));
			setPaginationModel({ ...paginationModel, sortField: null, sortOrder: null });
		}
	}

	/**
	 * This function is called when the user selects a filter in the table filter component.
	 * @param {*} filterType 
	 * @param {*} selectedOptions 
	 */
	const onChangeFilter = (filterType, selectedOptions) => {
		const filterObjects = {
			...currentTableFilter.current,
			[filterType]: selectedOptions
		};

		currentTableFilter.current = filterObjects;

		if (selectedOptions == null || selectedOptions.length === 0) {
			setPaginationModel({ ...paginationModel, filterField: null, filterValues: null });
			dispatch(setAssignmentEventPageFilter(null, null));
			return;
		}

		switch (filterType) {
			case 'sectionIds':
				let sectionIds = selectedOptions != null && selectedOptions.length > 0 ? selectedOptions.map(item => item.id) : null;
				dispatch(setAssignmentEventPageFilter(filterType, selectedOptions));
				setPaginationModel({ ...paginationModel, filterField: filterType, filterValues: sectionIds });
				break;
			default:
				break;
		}
	};

	const resetTableFilter = () => {
		currentTableFilter.current = null;
		setPaginationModel({ ...paginationModel, filterField: null, filterValues: null });
		dispatch(setAssignmentEventPageFilter(null, null));
	}

	const handleRowClick = (params) => {
		let row = params.row;

		switch (row.event) {
			case "ASSIGNMENT_DEADLINE_PASSED":
				// Navigate to the assignment page
				var url = `${getRootUrl()}assignment/${row.assignmentId}`;
				history.push(url);
				break;
			case "ASSIGNMENT_TASK_STATUS":
				// Navigate to the assignment task page
				var url = `${getRootUrl()}assignment/${row.assignmentId}/task/${row.assignmentTaskId}/documents`;
				history.push(url);
				break;
			default:
				break;
		}
	}

	const columns = [
		{
			field: 'created',
			headerName: 'Created',
			renderHeader: (params) => <div style={{ marginLeft: '25px' }}>{translate('Created')}</div>,
			flex: 0,
			width: 160,
			sortable: true,
			renderCell: (params) => {
				var eventType = params?.row?.eventType;
				var icon = null;

				if (eventType != null) {
					switch (eventType) {
						case 'DEADLINE_PASSED':
							icon = <RunningWithErrorsOutlinedIcon sx={{ width: '18px', color: '#df2d2e' }} />;
							break;
						case 'SUBMITTED_LATE':
							icon = <Icon name='Alert_Red' />
							break;
					}
				}

				return (
					<Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
						{icon && <div>{icon}</div>}
						<div className={!icon ? "ml-4" : ""}>
							{moment.utc(params?.row?.created).local().format('YYYY-MM-DD HH:mm')}
						</div>
					</Stack>
				)
			}
		},
		{ field: 'eventText', headerName: translate('Description'), flex: 1, minWidth: 420, disableColumnMenu: true, sortable: false },
		{ field: 'assignmentTitle', headerName: translate('Title'), flex: 1, minWidth: 250, sortable: true },
		{ field: 'sectionTitle', headerName: translate('Group'), flex: 1, minWidth: 150, sortable: true },
		{
			field: 'assignmentDueDate',
			headerName: translate('Assignment due date'),
			flex: 1,
			minWidth: 250,
			sortable: true,
			renderCell: (params) => moment(params?.row?.assignmentDueDate).format('YYYY-MM-DD HH:mm')
		},
	];

	return (

		<Box sx={{ width: '100%', backgroundColor: '#FFFFFF' }}>
			<DataGrid
				apiRef={apiRef}
				rows={rows}
				columns={columns}
				autoHeight
				initialState={{ ...rows, pagination: { rowCount: -1 } }}
				loading={isLoading}
				paginationMode="server"
				rowCount={totalRowCount}
				onRowClick={handleRowClick}
				disableColumnSelector={true}
				disableColumnFilter={true}
				paginationModel={paginationModel}
				onPaginationModelChange={onPaginationModelChange}
				onSortModelChange={onSortModelChange}
				slots={{
					toolbar: () => { return <CustomToolbarForLogs onChangeFilter={onChangeFilter} groupId={groupId} resetFilter={resetTableFilter} intialFilterValues={currentTableFilter.current} /> },
					noRowsOverlay: () => (<NoAssginmentsFoundLayout title={'No events found'} />),
				}}
				pageSizeOptions={[10]}
				sx={{ width: '100%', '--DataGrid-overlayHeight': '300px', '& .MuiDataGrid-overlay': { backgroundColor: '#FFFFFF' } }}
			/>
		</Box>

	);
};

/**
 * This function is used to create a custom toolbar for the table.
 * @param {*} param0 
 * @returns 
 */
const CustomToolbarForLogs = ({ groupId, onChangeFilter, resetFilter, intialFilterValues }) => {
	const mySections = useSelector(state => state.sections.educationGroups);
	const sectionOptions = useMemo(() => mySections?.filter(item => item?.id > 0)?.map(item => ({ id: item.id, title: item.title })) || [], [mySections]);
	const translations = useSelector(state => state.Languages.translations);
	let translate = translateConstructor(translations);

	const noFilterApplied = useMemo(() => {
		return (!intialFilterValues || Object.values(intialFilterValues).every(arr => arr.length === 0 || arr == groupId));
	}, [intialFilterValues]);

	const initialSelectedSections = useMemo(() => { return intialFilterValues?.sectionIds ?? [] }, [intialFilterValues, intialFilterValues?.sectionIds]);
	return (
		<GridToolbarContainer sx={{
			paddingLeft: '1.5rem',
			paddingTop: '1rem',
			marginBottom: '1rem',
		}}>
			{groupId == null ? (
				<SelectMultiple
					onChange={(selectedOptions) => onChangeFilter('sectionIds', selectedOptions)}
					options={sectionOptions}
					initialSelectedItems={initialSelectedSections}
					label={translate('Group')}
					sx={{ minWidth: 300, maxWidth: 350 }}
				/>
			) : null}
			{noFilterApplied ? null : (
				<Button
					onClick={resetFilter}
					sx={{ padding: '7.5px 14px;', color: 'rgba(0, 0, 0, 0.6)' }}
					variant="outlined"
					startIcon={<DeleteIcon />}
				>
					Rensa Filter
				</Button>
			)}
		</GridToolbarContainer>
	);
};

/**
 * This function fetches the data for the table.
 * @param {*} paginationModel 
 * @returns 
 */
const fetchData = (paginationModel) => {
	const translations = useSelector(state => state.Languages.translations);
	const assignmentStatus = useSelector(state => state.status.status.assignmentTaskStatus);

	let translate = translateConstructor(translations);
	const events = useSelector(state => state.AssignmentEventsPagination.events);
	const totalEvents = useSelector(state => state.AssignmentEventsPagination.total);
	const eventsHistory = useSelector(state => state.AssignmentEventsPagination.history);
	const currentPage = useSelector(state => state.AssignmentEventsPagination.currentPage);
	const lastFetched = useSelector(state => state.AssignmentEventsPagination.lastFetched);

	const [state, setState] = useState({
		isLoading: false,
		rows: [],
		info: { totalRowCount: 0 },
	});

	const dispatch = useDispatch();

	/**
	 * Every time the events are updated, we need to update the state and set custom eventType for the events and set text
	 */
	useEffect(() => {
		/**
		 * If totalEvents are 0, we need to set the state to empty and isLoading to false, because there are no events to show.
		 */
		if (totalEvents == 0) {
			setState({
				isLoading: false,
				rows: [],
				info: { totalRowCount: 0 },
			});
			return;
		}

		if (events == null) return;

		events.forEach((data) => {
			switch (data.event) {
				case "ASSIGNMENT_DEADLINE_PASSED":
					data.eventText = translate('Due time for the assignment has passed');
					data.eventType = 'DEADLINE_PASSED';
					break;
				case "ASSIGNMENT_TASK_STATUS":
					var statusText = assignmentStatus.find(item => item.id === data.value)?.value;
					var assignmentDueDate = moment.utc(data.assignmentDueDate).local().format('YYYY-MM-DD hh:mm');
					var statusCreated = moment.utc(data.created).local().format('YYYY-MM-DD hh:mm');
					var status = data.value;

					if (status == 'ASSIGNMENT_TASK_SUBMITTED') {
						if (statusCreated > assignmentDueDate) {
							data.eventText = data.assignmentTaskName + " " + translate('has submitted late');
							data.eventType = 'SUBMITTED_LATE';
						} else {
							data.eventText = data.assignmentTaskName + " " + translate('have submitted the assignment');
							data.eventType = 'SUBMITTED';
						}
					} else {

						data.eventText = `${translate('The work status of the')} ${data.assignmentTaskName} ${translate('has been changed to')} ${statusText} `;
					}

					break;
				default:
					data.eventText = data.event;
					break;
			}
		});

		setState({
			isLoading: false,
			rows: events,
			info: { totalRowCount: totalEvents },
		});
	}, [currentPage, totalEvents, lastFetched]);



	useEffect(() => {
		setState({ ...state, isLoading: true });
		var existingInHistory = eventsHistory.find(item => item.page == paginationModel.page + 1);

		if (existingInHistory != null) {
			setState({
				isLoading: false,
				rows: existingInHistory.events,
				info: { totalRowCount: totalEvents },
			});

			dispatch(setCurrentAssignmentEventPage(existingInHistory.page));

			return;
		}

		/**
		 * If the page is not loaded, we need to fetch the data from the server
		 */
		dispatch(getAssignmentEvents(paginationModel.page + 1, paginationModel.pageSize, paginationModel));
	}, [dispatch, paginationModel]);
	return state;
};


export default MyAssignmentsLogs;