import React, { Component } from 'react';
import { translate, Button } from '@haldor/ui';
import { connect } from 'react-redux';
import '../_onboarding.scss';

class SelectConsentWay extends Component {
	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
		if (this.props.adminInvitations != null && this.props.adminInvitations.length > 0 && this.props.onboardingUser != null) {
			if (this.props.adminInvitations.sentByMe) {
				this.props.showPage('waitingForResponse');
			}
		}
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(0)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	showConsentHeader() {
		return (<div>
			<h2 style={{ textAlign: 'left' }}>{this.props.translate('Are you the first one?')}</h2>
			<p style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>{this.props.translate('You appear to be the first in your organisation to use Haldor’s services. If so, your IT support or Office 365 administrator must approve that you add the services. For your convenience, we have prepared an e-mail that you can send to them.')}</p>

			<div style={{ width: '20rem', marginBottom: '2rem' }}>
				<img src="/dist/svg/illustration_steg02.svg" />
			</div>
		</div>)
	}

	showConsentButtonGroup() {
		return (<div>
			<Button type="primary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('showEmailInfo') }}>
				{this.props.translate('Open e-mail suggestion')} </Button>
			<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('navigateToAdminConsent') }}>
				{this.props.translate('I am an Office 365-admin')} </Button>
			<Button type="secondary" onClick={this.props.goBack}>
				{this.props.translate('Back')} </Button>
		</div>)
	}

	render() {
		return (
			<div>
				<div>
					{this.showConsentHeader()}
					<div style={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
						{this.showConsentButtonGroup()}
					</div>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		adminInvitations: state.Services.adminInvitations,
		onboardingUser: state.user.onboardingUser,
	};
}

export default connect(mapStateToProps, {
})(SelectConsentWay);
