import {
	REGISTER_LANGUAGES,
	GET_TRANSLATIONS_FOR_LANGUAGE,
	LOAD_TRANSLATIONS_FILE,
} from 'actions/languages';

const INITIAL_STATE = {
	translations: null,
	languages: null,
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case REGISTER_LANGUAGES:
			return { ...state, languages: action.payload };

		case GET_TRANSLATIONS_FOR_LANGUAGE:
			return { ...state, translations: action.payload };

		case LOAD_TRANSLATIONS_FILE:
			return { ...state, translations: [...state.translations, ...action.payload] };

		default:
			return state;
	}
}