
var store = require('../');
var ROLE_STUDENT = require('../constants/roles').ROLE_STUDENT;
var ROLE_CONVERSATION_ADMIN = require('../constants/roles').ROLE_CONVERSATION_ADMIN;

var fetchRole = function (activeRole, currentUser, overrideRole) {
	if (overrideRole == null) {
		var localStorageRole = localStorage.getItem("hal.role" + currentUser.userId);

		if (localStorageRole == null) {
			if (currentUser.userRoles != null && currentUser.userRoles.length > 0) {
				var currentRole = currentUser.userRoles[0].toUpperCase();
				if (currentRole == ROLE_CONVERSATION_ADMIN) {
					currentUser.userRoles.forEach((role) => {
						if (role != ROLE_CONVERSATION_ADMIN && currentRole == ROLE_CONVERSATION_ADMIN) {
							currentRole = role;
						}
					});
				}

				localStorage.setItem("hal.role" + currentUser.userId, currentRole.toUpperCase());

				const newAction = { type: "SET_ROLE", payload: currentRole.toUpperCase() };
				store.default.dispatch(newAction);
			} else {
				localStorage.setItem("hal.role" + currentUser.userId, ROLE_STUDENT);

				const newAction = { type: "SET_ROLE", payload: ROLE_STUDENT };
				store.default.dispatch(newAction);
			}
		} else {
			if (localStorageRole == ROLE_CONVERSATION_ADMIN) {
				if (currentUser.userRoles.length > 0) {
					currentUser.userRoles.forEach((role) => {
						if (role != ROLE_CONVERSATION_ADMIN && localStorageRole == ROLE_CONVERSATION_ADMIN) {
							localStorageRole = role;
						}
					})
				}
			}

			localStorage.setItem("hal.role" + currentUser.userId, localStorageRole.toUpperCase() );
			const newAction = { type: "SET_ROLE", payload: localStorageRole.toUpperCase() };
			store.default.dispatch(newAction);
		}
	} else {
		localStorage.setItem("hal.role" + currentUser.userId, overrideRole.toUpperCase());

		const newAction = { type: "SET_ROLE", payload: overrideRole.toUpperCase() };
		store.default.dispatch(newAction);
	}
}

module.exports = {
	fetchRole,
}
