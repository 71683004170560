import React, { useMemo, useState } from 'react';
import moment from 'moment';
import User from '_class/User';
import DateTime from '_class/DateTime';

import { useTranslate } from 'lib/translate';

import { Collapsible } from 'UI';
import { Button, ButtonGroup, Checkbox, Flex, Table } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

const ReportHistory = ({ attendance }) => {
	const [reportHistory, setReportHistory] = useState([]);
	const translate = useTranslate();

	const getReportedBy = (item) => {
		if (item.reportedBy != null && item.reportedBy.firstName != null) {
			const reportedBy = new User(item.reportedBy);
			return reportedBy.getName()
		}

		if (item.reportedBy != null) {
			if (item.reportedBy.userId == 'TrackAttendance') {
				return translate('system');
			}

			if (item.reportedBy != '') {
				return translate('Guardian')
			}
		}

		return null;
	}

	const getRemovedBy = (item) => {
		if (item.editor != null && item.editor.firstName != null) {
			const editor = new User(item.editor);
			return editor.getName()
		}

		return null;
	}

	useMemo(() => {
		if (attendance == null) {
			return false;
		}

		let attendanceList = [];
		attendance.forEach((item) => {
			let reducedItem = { ...item };
			if (reducedItem.history != null && reducedItem.history.length > 0) {
				item.history.forEach((historyItem) => {
					attendanceList.push(historyItem);
				});

				delete reducedItem.history;
			}

			attendanceList.push(reducedItem);
		});

		let attendanceHistory = attendanceList.filter(attendance => attendance.status == 'Deleted');
		let reportHistory = [];
		attendanceHistory.forEach((item) => {
			let historyFound = reportHistory.findIndex((history) =>
				moment(history.edited).isSame(moment(item.edited), 'second')
			);

			if (historyFound > -1) {
				reportHistory[historyFound].items.push(item);
			} else {
				let reportHistoryItem = {
					reported: item.reported,
					reportedBy: item.reportedBy,
					edited: item.edited,
					editor: item.editor,
					items: [item],
				};

				reportHistory.push(reportHistoryItem);
			}
		});

		setReportHistory(reportHistory);
	}, [attendance]);

	if (reportHistory.length == 0) {
		return null;
	}

	return <div style={{ margin: '1.5rem 1.7rem', marginBottom: 0 }}>
		<span className="title">
			{translate('Removed reports')}
		</span>

		{reportHistory.map((history, index) => {
			let trigger = (
				<Flex center space>
					<div className="size-16" style={{ fontWeight: 400 }}>
						{new DateTime(history.edited).getLongDateWithTime()}
					</div>

					<div className="size-16" style={{ fontWeight: 400 }}>
						{translate('reported-by')} {getReportedBy(history)}
					</div>

					<div className="size-16" style={{ fontWeight: 400 }}>
						{getRemovedBy(history) != null ?
							translate('Removed by') + ' ' + getRemovedBy(history)
							: null}
					</div>
				</Flex>
			);

			let style = {};
			if (index + 1 < reportHistory.length) {
				style = { marginBottom: '.75rem' };
			}

			return <div key={history.reported} style={style}>
				<Collapsible trigger={trigger}>
					<div style={{ marginTop: '.55rem' }}></div>

					<Table>
						<thead>
							<tr>
								<th>{translate('name')}</th>
								<th>{translate('absent')}</th>
								<th>{translate('minutes')}</th>
								<th>{translate('mark-for-valid-absence')}</th>
								<th>{translate('Absence length')}</th>
								<th>{translate('reported-by')}</th>
								<th>{translate('reported')}</th>
							</tr>
						</thead>

						<tbody>
							{history.items.map((item) => {
								const user = new User(item.user);

								return <tr key={item.id}>
									{/* Name */}
									<td title={item.user.email}>
										<DisplayName
										firstName={item.user.firstName}
										lastName={item.user.lastName}
										email={item.user.email}
										data={history.items ? history.items.flatMap(item => item.user) : null}
										/>
									</td>

									{/* Buttons */}
									<td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
										<ButtonGroup>
											<Button type="secondary" style={item.type == 'PRESENT' || item.type == 'VALID_PRESENT' ?
												{ backgroundColor: '#4baf4d', borderColor: '#4baf4d', color: 'white' }
												: {}} disabled>
												{translate('present')}
											</Button>

											<Button type="secondary" style={item.type == 'INVALID_ABSENCE' || item.type == 'VALID_ABSENCE' ?
												{ backgroundColor: '#df2d2e', borderColor: '#df2d2e', color: 'white' }
												: {}} disabled>
												{translate('absent-whole')}
											</Button>

											<Button type="secondary" style={item.type == 'INVALID_LATE' || item.type == 'VALID_LATE' ?
												{ backgroundColor: '#f38234', borderColor: '#f38234', color: 'white' }
												: {}} disabled>
												{translate('late-partly')}
											</Button>
										</ButtonGroup>
									</td>

									{/* Minutes */}
									<td style={{ textAlign: 'left', width: 60, verticalAlign: 'top' }}>
										<div className="form-row">
											{item.type.indexOf('LATE') > -1 ?
												<input
													style={{ width: 60 }}
													type="number"
													min="0"
													max="500"
													value={item.lateDuration}
													disabled
												/>
												:
												<div style={{ width: 60, height: 23, marginBottom: 3 }} />
											}
										</div>
									</td>

									{/* Valid absence */}
									<td>
										<div>
											{item.type != 'PRESENT' && item.type != 'VALID_PRESENT' ?
												<Checkbox
													value={item.type.indexOf('VALID') == 0}
													style={{ float: 'right' }}
													disabled
												/>
												: null}
										</div>
									</td>

									{/* Absence length */}
									<td>
										<div>
											{translate('From')}: {new DateTime(item.startTime).getHourStamp()}<br />
											{translate('To')}: {new DateTime(item.endTime).getHourStamp()}
										</div>
									</td>

									{/* Reported by */}
									<td>
										{getReportedBy(item)}
									</td>

									{/* Reported */}
									<td>
										{new DateTime(item.reported).getHourStamp()}
									</td>
								</tr>
							})}
						</tbody>
					</Table>
				</Collapsible>
			</div>
		})}
	</div>

}

export default ReportHistory;