import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import User from '_class/User';

import { getUserInfo } from 'actions/user';
import { getVerdictScale, updateVerdict, deleteVerdict } from 'actions/verdicts';

import VerdictForm from 'containers/Verdict/Form/VerdictForm';

import VerdictDisplayFeedback from './Partials/VerdictDisplayFeedback';
import VerdictDisplayLevel from './Partials/VerdictDisplayLevel';
import { Button } from '@haldor/ui';

class VerdictDisplay extends Component {
	constructor(props) {
		super(props);

		this.state = {
			edit: false
		}
	}

	componentDidMount = () => {
		if (this.props.scale == null) {
			this.props.getVerdictScale();
		}

		if (this.getUser() == null) {
			this.props.getUserInfo(this.props.verdict.creator, 'base');
		}
	}

	getUser = () => {
		let user = this.props.users.find(user => {
			return user.userId == this.props.verdict.creator;
		});

		return user;
	}

	deleteVerdictPublished = (verdict) => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(result => {
			if (result.value != null) {
				if (this.props.setLoading) {
					this.props.setLoading(true);
				}

				this.props.deleteVerdict(verdict.id).then(() => {
					if (this.props.reload != null) {
						this.props.reload();
					}
				});
			}
		});
	}

	deleteVerdictForm = (verdict) => {
		this.setState({ loading: true });

		if (this.props.setLoading) {
			this.props.setLoading(true);
		}

		this.props.deleteVerdict(verdict.id).then(() => {
			if (this.props.reload != null) {
				this.props.reload();
			}
		});
	}

	onVerdictFormSubmit = (values) => {
		return new Promise((resolve) => {
			values.courses = [{
				"courseCode": this.props.course.courseCode,
				"subjectCode": this.props.course.subjectCode,
				"courseID": this.props.course.id,
			}];

			this.props.updateVerdict(values).then(() => {
				this.setState({ edit: false });
				if (this.props.reload != null) {
					this.props.reload();
				}

				resolve(1);
			})
		});
	}

	render() {
		if (this.props.verdict == null) {
			return null;
		}

		const user = new User(this.props.currentUser);

		return (
			<div className="verdict verdict-display-container">
				{this.props.scale != null && !this.state.edit ?
					<VerdictDisplayLevel
						verdict={this.props.verdict}
						scale={this.props.scale}
					/>
					: null}

				<div className="display-feedback-container">
					{!this.state.edit ?
						<VerdictDisplayFeedback
							verdict={this.props.verdict}
							user={this.getUser()}
						/>
						: null}

					{this.state.edit ?
						<VerdictForm
							verdict={this.props.verdict}
							deleteVerdict={this.deleteVerdictForm}
							onSubmit={this.onVerdictFormSubmit}
							edit={this.state.edit}
						/>
						: null}
				</div>

				{!this.props.isStudent && (!this.props.group || !this.props.group.archived) && user.isTeacher() ?
					<Button
						style={{ float: 'right', marginTop: '1em' }}
						type="secondary"
						onClick={(e) => { e.preventDefault(); this.setState({ edit: this.state.edit ? false : true }) }}
					>
						{this.state.edit ? this.props.translate('close') : this.props.translate('Edit')}
					</Button>
					: null}

				{!this.props.isStudent && (!this.props.group || !this.props.group.archived) && (user.isAdministrator() || user.data.id == this.props.verdict.creator) ?
					<Button
						style={{ float: 'right', marginTop: '1em', marginRight: '0.5rem' }}
						type="secondary"
						onClick={(e) => { e.preventDefault(); this.deleteVerdictPublished(this.props.verdict) }}
					>
						{this.props.translate('Delete')}
					</Button>
					: null}


				<div style={{ clear: 'both' }}></div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		scale: state.Verdicts.scale,
		currentUser: state.user.currentUser,
		users: state.user.info,
	};
}

export default connect(mapStateToProps, {
	getVerdictScale,
	getUserInfo,
	updateVerdict,
	deleteVerdict
})(VerdictDisplay);
