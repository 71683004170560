import {
	GET_WELCOME_INFORMATION_TEXT,
	HIDE_WELCOME_MODAL,
} from 'actions/information_texts';

const INITIAL_STATE = {
	welcome: null,
	hideWelcomeModal:false,

};

export default function informationTexts(state = INITIAL_STATE, action) {
	switch (action.type) {
		case GET_WELCOME_INFORMATION_TEXT:
			return { ...state, welcome: action.payload };
	    case HIDE_WELCOME_MODAL:
			return {...state, hideWelcomeModal:true}		

		default:
			return state;
	}
}