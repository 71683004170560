import React, { Component, Fragment } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getRootUrl } from 'helpers/url';

import FromNow from '../Date/FromNow';
import Status from '../Status';
import { isUserTeacher } from 'helpers/user';
import { ColorLabel, ColorLabelContainer } from 'UI';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Tooltip from '@mui/material/Tooltip';
import { Icon, Card } from '@haldor/ui';

import './_AssignmentCard.scss';

class AssignmentCard extends React.PureComponent {
	render() {
		const { translate } = this.props;
		const isHaldorAssignment = this.props.assignment.id == parseInt(this.props.assignment.id, 10);

		if (this.props.assignment.status == null) {
			// Wait for status requests
			return null;
		}

		let addedColorDots = [];
		let assignmentId = this.props.assignment.externalID ?? this.props.assignment.id;

		const isTeacher = isUserTeacher(this.props.currentUser);

		return (
			<Card
				element={Link}
				to={`${getRootUrl()}assignment/${assignmentId}`}
				className='assignment-card'
			>
				<div style={{ flex: 1 }}>
					{isHaldorAssignment &&
						this.props.assignment.courses != null &&
						this.props.assignment.courses.length > 0 ? (
						<ColorLabelContainer>
							{this.props.assignment.courses.map((course) => {
								if (addedColorDots.find((t) => t == course.id) == null) {
									let text = course.title;
									text = course.year != null ? text + ' ' + course.year : text;
									addedColorDots.push(course.id);

									return (
										<ColorLabel
											key={course.id}
											tooltip={text}
											color={course.colorCode}
										/>
									);
								}
							})}
						</ColorLabelContainer>
					) : null}

					{isHaldorAssignment ? (
						<span className='title'>{this.props.assignment.title}</span>
					) : (
						<span className='title'>{this.props.assignment.displayName}</span>
					)}

					{isHaldorAssignment ? (
						<div style={{ fontSize: 'var(--size-14)' }}>
							{this.props.assignment.section != null
								? this.props.assignment.section.title
								: ''}
						</div>
					) : null}
				</div>

				<div className='meta-container'>
					<div>
						{this.props.assignment.autoAssignStudents ? <Tooltip placement="top" arrow title="[missing translate] Kommer automatiskt att tilldelas elever som tillkommer i gruppen"><div className="card-meta"><PersonAddIcon sx={{ fontSize: '15px' }} />[missing translate] Automatisk tilldelning</div></Tooltip> : null}

						{this.props.assignment.status === 'ASSIGNMENT_LOCKED' ? (
							<div className='card-meta' style={{ marginRight: 5 }}>
								<Icon name='Lock' />
								{this.props.translate('Locked')}{' '}
								{isTeacher ?
									<Fragment>

										{this.props.assignment?.lockedWorkspaces === 0 ? 0 : this.props.assignment?.lockedWorkspaces} /
										{' '}{this.props.assignment?.totalTasks}
									</Fragment>
									: null}
							</div>
						) : null}
						{isHaldorAssignment ? (
							this.props.assignment.created != '0001-01-01T00:00:00' ? (
								<FromNow>{this.props.assignment.dueDate}</FromNow>
							) : null
						) : (
							<FromNow>{this.props.assignment.dueDateTime}</FromNow>
						)}

						{this.props.assignment.assessmentId != null ? (
							<div className='card-meta'>{this.props.translate('assessed')}</div>
						) : null}

						{!isHaldorAssignment || this.props.assignment.externalID != null ? (
							<div className='card-meta' style={{ marginRight: 5 }}>
								<span>Teams</span>
							</div>
						) : this.props.assignment.created == '0001-01-01T00:00:00' ? (
							<span className='badge-primary'>
								{this.props.translate('task-is-creating')}
							</span>
						) : (
							<div className='card-meta'>
								<Status
									type='assignmentTypes'
									id={this.props.assignment.assignmentType}
								/>
							</div>
						)}
					</div>

					<div className='submission-info'>
						{this.props.assignment.submittedTasks != null && this.props.isTeacher ? (
							<div
								data-tooltip={
									translate('submitted-list-title') + ' ' + translate('tasks')
								}
								className='card-meta'
							>
								<div style={{ display: 'inline-block', width: 11, height: 19 }}>
									<Icon name='File' />
								</div>

								<div style={{ display: 'inline-block', marginLeft: 7 }}>
									{translate('submitted-list-title')}{' '}
									{this.props.assignment.submittedTasks} /{' '}
									{this.props.assignment.totalTasks}
								</div>
							</div>
						) : null}

						{this.props.assignment.publishedAssessments != null && this.props.isTeacher ? (
							<div data-tooltip={translate('published-assessments')} className='card-meta'>
								{translate('assessed-tasks')}{' '}
								{this.props.assignment.publishedAssessments}
							</div>
						) : null}
					</div>
				</div>
			</Card>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		status: state.status.status,
		currentUser: state.user.currentUser,
	};
}

export default connect(mapStateToProps)(AssignmentCard);
