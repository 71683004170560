import React, { useState } from 'react';
import "./_infoButton.scss";


const InfoButton = ({ email }) => {
	
	const [showEmail, setShowEmail] = useState(false);

	const InfoButtonClicked = () => {
		setShowEmail(true);

		setTimeout(() => {
			setShowEmail(false);
		}, 3000);
	}
	
	return (
		<>
		  {email ?
			<div id='show-info'>
			  <button type="button" title={email} onClick={()=> InfoButtonClicked()}>
				<img src='/dist/svg/info-outline.svg' />
			  </button>
			  {showEmail ?
  			    <span id='show-info-text'>{email}</span>			  
				: ""}
			</div>
		  : null}
		</>
	  );
	}

export default InfoButton;