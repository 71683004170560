import React, { Component } from "react";
import Moment from 'moment';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import DateTime from "_class/DateTime";
import User from "_class/User";

import { getUserAssessmentDates } from "helpers/user";

import { setPageTitle } from "actions/header";
import { getSection } from "actions/sections";

import DatePickers from "./Partials/DatePickers";
import UserAssessments from './Partials/UserAssessments';

import { Spinner } from "UI";
import { Button, translate } from '@haldor/ui';

class SectionProgress extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			student: null,
			end: getUserAssessmentDates().end,
			start: getUserAssessmentDates().start,
			currentEnd: getUserAssessmentDates().end,
			currentStart: getUserAssessmentDates().start
		};
	}

	componentDidMount = () => {
		let groupId = null;

		if (this.props.match != null) {
			if (this.props.match.params.groupId != null) {
				groupId = this.props.match.params.groupId;
			}
		}

		if (groupId == null) {
			groupId = this.props.groupId;
		}

		if (this.props.activeSection != null) {
			if (this.props.activeSection.id == groupId) {
				this.setTitleAndStudent();
				return true;
			}
		}

		this.props.getSection(groupId).then(() => {
			this.setTitleAndStudent();
		});
	};

	setTitleAndStudent = () => {
		const { activeSection } = this.props;
		let studentId = null;
		let student = null;

		if (this.props.match != null) {
			if (this.props.match.params.userId != null) {
				studentId = this.props.match.params.userId;
			}
		}

		if (studentId == null) {
			studentId = this.props.userId;
		}

		const currentUser = new User(this.props.currentUser);
		if (currentUser.isStudent()) {
			student = this.props.currentUser;
		} else {
			const foundStudent = activeSection.students.find(t => t.id == studentId);

			if (foundStudent != null) {
				student = foundStudent;

				this.props.setPageTitle(
					activeSection.title + ' / ' + foundStudent.firstName + " " + foundStudent.lastName
				);
			}
		}

		if (student != null) {
			this.setState({ loading: false, student });
		}
	}

	updateDates = () => {
		const { start, end } = this.state;

		localStorage.setItem("assessment-start-date", new DateTime(start).getDateStamp());
		localStorage.setItem("assessment-end-date", new DateTime(end).getDateStamp());

		this.setState({ currentStart: start, currentEnd: end });
	}

	onDateChange = ({ start, end }) => {
		if (start == "Invalid date") {
			start = Moment().subtract(1, "years");
		}
		if (end == "Invalid date") {
			end = Moment().add(1, "years");
		}

		this.setState({ start: new DateTime(start).getDateStamp(), end: new DateTime(end).getDateStamp() });
	};

	render() {
		const { loading, student } = this.state;
		let groupId = null;

		if (this.props.match != null) {
			if (this.props.match.params.groupId != null) {
				groupId = this.props.match.params.groupId;
			}
		}

		if (groupId == null) {
			groupId = this.props.groupId;
		}

		if (this.props.activeSection == null && student == null || loading) {
			return (
				<div className="progress">
					<Spinner center />
				</div>
			)
		}

		const user = new User(this.props.currentUser);
		const isStudent = user.isStudent();

		if (isStudent) {
			let studentId;
			if (this.props.match?.params != null && this.props.match?.params?.userId != null) {
				studentId = this.props.match.params.userId;
			} else {
				studentId = this.props.userId;
			}

			if (studentId == null || studentId != this.props.currentUser.id) {
				return <Redirect to="/404" />;
			}
		}

		return (
			<div className="progress single-task">
				<div className="single-section">
					<div className="form">
						<div className="form-row">
							<div className="form-row-half static">
								<DatePickers
									onChange={this.onDateChange}
									startDate={this.state.start}
									endDate={this.state.end}
								/>
							</div>
						</div>
					</div>

					<div className="clearfix" />

					<div style={{ marginTop: "1em" }}>
						<Button type="secondary" onClick={this.updateDates}>
							{this.props.translate("update")}
						</Button>
					</div>
				</div>

				<div className="clearfix" />

				<div className="single-section-divider" style={{ marginBottom: '3rem' }} />

				<UserAssessments
					studentId={student.id}
					sectionId={groupId}
					start={this.state.currentStart}
					end={this.state.currentEnd}
				/>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		activeSection: state.sections.activeSection,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations)
	};
}

export default connect(mapStateToProps, {
	getSection,
	setPageTitle
})(SectionProgress);