import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import Moment from "moment";
import { getSchoolDetails } from "actions/schools";
import { setActiveSchool } from 'actions/user';
import { getActiveSchool } from 'helpers/localstorage';
import SectionTitle from 'components/Presentation/SectionTitle';
import { setPageTitle } from "actions/header";
import User from '_class/User';

import SchoolAdministration from './Partial/School';
import SubjectsAdministration from './Partial/Subjects';
import ExportAssessmentForSchool from './Partial/ExportAssessmentForSchool';
import UsersWithoutMentorGroups from './Partial/UsersWithoutMentorGroups';
import SMSAdmin from './Partial/SMS';

import { TooltipMenu, Tabs, Tab, getActiveLanguage } from '@haldor/ui';

class Admin extends Component {

	constructor(props) {
		super(props)

		Moment.locale(getActiveLanguage());

		this.state = {
			school: [],
			schools: [],
		}
	}

	componentDidMount = () => {
		let schools = [];
		this.props.currentUser.schools.forEach(school => {
			if (this.userSchoolAdmin(school.id)) {
				schools.push({
					title: school.title,
					id: school.id,
					type: school.type,
					typeLocalizes: this.props.translate(school.type),
					typeOfSchools: [
						{ id: 1, type: "PRESCHOOL" },
						{ id: 2, type: "COMPULSORY_SCHOOL" },
						{ id: 3, type: "UPPER_SECONDARY_EDUCATION" },
						{ id: 4, type: "ADULT_EDUCATION" }
					]
				});
			}
		});

		const schoolId = getActiveSchool(this.props.currentUser.id);
		this.props.getSchoolDetails(schoolId).then(() => {
			let school = this.props.school;
			this.setState({ loading: false, schools, school });
		})

		this.props.setPageTitle(this.props.translate('administration'));
	}

	userSchoolAdmin = (schoolId) => {
		if (this.props.currentUser.roles != null && this.props.currentUser.roles.length > 0) {
			var schoolAdministrator = this.props.currentUser.roles.find(role => (role.roleId.toUpperCase() == "SCHOOL_ADMINISTRATOR" && role.referenceId == schoolId) || (role.roleId.toUpperCase() == "SYSADMIN"));

			if (schoolAdministrator != null) {
				return true;
			}
		}

		return false;
	}

	renderActiveSchoolAction = (school) => {
		return <TooltipMenu.Item key={'school-id-' + school.id} onClick={() => this.switchSchool(school.id, this.props.currentUser.id)}>
			{school.title}
		</TooltipMenu.Item>
	}

	switchSchool = (school, userId) => {
		localStorage.setItem("hal.school" + userId, school);
		this.props.setActiveSchool(school);

		this.props.history.push("/administration");
	}

	render() {
		const user = new User(this.props.currentUser);

		if (!user.isAdministrator()) {
			this.props.history.push('/');
		}

		return (
			<div className="general-administration">
				<SectionTitle>
					<TooltipMenu trigger={this.state.school.title} id={'school-menu'}>
						{this.state.schools.map(this.renderActiveSchoolAction)}
					</TooltipMenu>
				</SectionTitle>

				<Tabs>
					<Tab title={this.props.translate('school')} route="school">
						<SchoolAdministration />
					</Tab>

					<Tab title={this.props.translate('subjects')} route="subjects">
						<SubjectsAdministration />
					</Tab>

					<Tab title={this.props.translate('Students without mentor groups')} route="withoutMentorGroups">
						<UsersWithoutMentorGroups />
					</Tab>

					<Tab title={this.props.translate('Sms')} route="sms">
						<SMSAdmin />
					</Tab>
				</Tabs>
			</div>
		)
	}

}

function mapStateToProps(state, ownProps) {
	return {
		languages: state.Languages.languages,
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		currentUser: state.user.currentUser,
		school: state.School.active
	};
}

export default withRouter(connect(mapStateToProps, {
	setPageTitle,
	setActiveSchool,
	getSchoolDetails,
})(Admin));