import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Moment from 'moment';
import swal from 'sweetalert2';

import { setPageTitle } from 'actions/header';
import { getGoalsStatisticsForUsers } from 'actions/preschoolform';
import {
	getArchivedEducationGroups,
	getArchievedMentorGroups,
	getEducationGroups,
	getMentorGroups,
	getSection,
	updateArchived,
} from 'actions/sections';
import { getUserAssessmentDates } from "helpers/user";

import User from '_class/User';
import DateTime from '_class/DateTime';

import MentorSectionForm from 'containers/Forms/MentorSectionForm';
import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';
import TeacherSectionProgress from 'containers/Progress/TeacherSection';
import SectionInformation from '../Functionality/SectionInformation';
import SectionPlans from '../Functionality/SectionPlans';
import SectionPosts from '../Functionality/SectionPosts';
import SectionTools from '../Functionality/SectionTools';
import DatePickers from "containers/Progress/Partials/DatePickers";

import { Spinner } from 'UI';
import { Table, Block, Button, Tabs, Tab, translate } from '@haldor/ui';

import './_sectionStats.scss';
import InfoButton from 'components/Presentation/InfoButton';

class PreSchoolSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modalIsOpen: false,
			loading: false,
			end: getUserAssessmentDates().end,
			start: getUserAssessmentDates().start,
		};
	}

	componentDidMount() {
		const { groupId } = this.props.match.params;
		if (groupId != null && groupId != undefined && groupId != "") {
			this.setState({ loading: true });
			this.props.getSection(groupId).then(() => {
				this.props.setPageTitle(this.props.group.title);
				this.setState({ loading: false });

				if (this.props.group.type == "MENTOR_GROUP") {
					this.getStatistics();
				}
			});
		}
	}

	getStatistics = () => {
		const { group } = this.props;
		const { start, end } = this.state;

		this.props.getGoalsStatisticsForUsers(group.id, start, end);
	}

	updateData = () => {
		this.getStatistics();
	}

	onDateChange = ({ start, end }) => {
		if (start == "Invalid date") {
			start = Moment().subtract(1, "years");
		}
		if (end == "Invalid date") {
			end = Moment().add(1, "years");
		}

		localStorage.setItem("assessment-start-date", new DateTime(start).getDateStamp());
		localStorage.setItem("assessment-end-date", new DateTime(end).getDateStamp());

		this.setState({
			start: new DateTime(start).getDateStamp(),
			end: new DateTime(end).getDateStamp()
		});
	};

	EditButton = () => {
		const user = new User(this.props.currentUser);
		let isOwner = this.props.group.owners.find(owner => {
			return owner.userId == this.props.currentUser.id;
		});

		if (!user.isStudent() && isOwner != null) {
			return (
				<div>
					<Button type="secondary" onClick={this.openModal} disabled={this.props.group.archived}>
						{this.props.translate('Edit')}
					</Button>
				</div>
			);
		}

		return null;
	}

	ArchiveButton = () => {
		const user = new User(this.props.currentUser);

		if (!user.isStudent()) {
			return (
				<div>
					<Button type="secondary" onClick={this.makeArchive}>
						{this.props.translate(this.props.group.archived ? 'Restore' : 'Archive')}
					</Button>
				</div>
			);
		}

		return null;
	}

	isUserOwner = () => {
		const { currentUser, group } = this.props;

		if (currentUser == null || group == null) {
			return false;
		}

		let foundOwner = group.owners.find(owner => {
			return currentUser.id == owner.id;
		});

		if (foundOwner != null) {
			return true;
		}

		return false;
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	makeArchive = async () => {
		const { groupId } = this.props.match.params;
		const { group, translate } = this.props;

		let result = null;
		if (group.archived) {
			result = await swal.fire({
				title: translate('are-you-sure'),
				text: translate('Are you sure you want to reset this group?'),
				showCancelButton: true,
				cancelButtonText: translate('No'),
				confirmButtonText: translate('Yes'),
			});
		} else {
			result = await swal.fire({
				title: translate('are-you-sure'),
				html: this.props.translate('Both the group and the Microsoft Team will be archived.<br /><br />Do you want to archive this group?'),
				showCancelButton: true,
				cancelButtonText: translate('No'),
				confirmButtonText: translate('Yes'),
			});
		}

		if (result != null && result.value != null) {
			this.props.updateArchived(group).then(() => {
				this.setState({ loading: true });
				this.props.getSection(groupId).then(() => {
					this.setState({ loading: false });
				});

				if (group.type == 'EDUCATION_GROUP') {
					this.props.getEducationGroups();
				}

				if (group.type == 'MENTOR_GROUP') {
					this.props.getMentorGroups();
				}
			});
		}
	}

	getCategories = () => {
		let categories = [];
		if (this.props.goals != null) {
			this.props.goals.forEach(goal => {
				if (!categories.find(category => category.title === goal.group)) {
					categories.push({ title: goal.group, color: goal.colorCode });
				}
			});
		}

		return categories;
	}

	getGoalsByCategoryName = (category) => {
		let goals = [];
		if (this.props.goals != null) {
			goals = [...this.props.goals].filter(goal => goal.group == category);
		}

		return goals;
	}

	closeModal = (reRender, skipPrompt) => {
		if (skipPrompt) {
			const { groupId } = this.props.match.params;
			if (reRender) {
				this.setState({ loading: true });
				this.props.getSection(groupId).then(() => {
					this.setState({ loading: false });
				});
			}

			this.setState({ modalIsOpen: false });
		} else {
			this.setState({ modalIsOpen: false });
		}
	}

	renderCategoryGoals = (category) => {
		const { group } = this.props;
		const goals = this.getGoalsByCategoryName(category);

		return (
			<div style={{ position: 'relative', margin: '-1.4rem -1.7rem' }}>
				<Table>
					<thead>
						<tr>
							<th>
								{this.props.translate('name')}
							</th>

							{goals.map(goal => {
								return <th
									style={{ maxWidth: 340, whiteSpace: 'normal', minWidth: 150 }}
									key={goal.id}
									title={goal.text}
								>
									{goal.title}
								</th>
							})}
						</tr>
					</thead>

					<tbody>
						{group.students
							.sort((a, b) => a.lastName.localeCompare(b.lastName))
							.map((student) => {
								return this.renderStudentRow(student, goals)
							})}
					</tbody>
				</Table>
			</div>
		);
	}

	checkIfStudentHasSameName = (student, data) =>{
		if(student && data){
			return data.filter(x =>
				x.firstName === student.firstName && x.lastName === student.lastName
				).length > 1;
		}
	}

	renderStudentRow = (student, goals) => {
		let userStats = null;
		if (this.props.goalStatistics != null) {
			userStats = this.props.goalStatistics.find(statistic => {
				return statistic.studentId == student.userId;
			});
		}
		return <tr key={student.userId}>
			<td className='name'>
				{this.checkIfStudentHasSameName(student, this.props.group.students) ? 
				<InfoButton email={student.email}/>
				: ""}
				<Link to={`/user/${student.userId}/progress`}>
					{student.firstName} {student.lastName}
				</Link>
			</td>

			{goals.map(goal => {
				let goalStats = null;
				if (userStats != null) {
					goalStats = userStats.statistics.find(stat => {
						return stat.goalId == goal.id;
					});
				}

				return <td style={{ textAlign: 'center' }} key={goal.id}>
					{goalStats != null ?
						<span className="number">
							{goalStats.total}
						</span>
						:
						<span className="empty-dot"></span>
					}
				</td>
			})}
		</tr>
	}

	renderGroupContent = () => {
		const { group } = this.props;
		const user = new User(this.props.currentUser);

		return (
			<div className="single-group single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<SectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionTools EditButton={this.EditButton} ArchiveButton={this.ArchiveButton} group={group} />

				<SectionInformation group={group} />

				<div style={{ clear: 'both' }} />

				{this.props.services.news ?
					<div id="section-posts" className="section" style={{ border: 0, marginTop: 0 }}>
						<SectionPosts
							groupid={group.id}
							archived={this.props.group.archived}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{this.props.services.plans ?
					<div id="section-planning" style={{ marginTop: '4rem' }}>
						<SectionPlans
							groupid={group.id}
							archived={this.props.group.archived}
							dateRestriction={false}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{group.students && group.students.length > 0 && !user.isStudent() ?
					<div id="section-pupils" className="single-section form" style={{ marginTop: '4rem' }}>
						<TeacherSectionProgress
							groupId={group.id}
							schoolType="PRESCHOOL"
						/>
					</div>
					: null}
			</div>
		);
	}

	renderMentorGroup() {
		const { group } = this.props;
		const user = new User(this.props.currentUser);
		const categories = this.getCategories();

		return (
			<div className="single-group single-task">
				<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={group.title}>
					<MentorSectionForm onAbort={this.closeModal} editView section={group} />
				</Modal>

				<SectionTools EditButton={this.EditButton} ArchiveButton={this.ArchiveButton} group={group} />

				<SectionInformation group={group} />

				<div style={{ clear: 'both' }} />

				{this.props.services.news ?
					<div id="section-posts" className="section single-section" style={{ border: 0, marginTop: 0 }}>
						<SectionPosts
							groupid={group.id}
							disableCreate={!this.isUserOwner()}
						/>
					</div>
					: null}

				{group.students && group.students.length > 0 && !user.isStudent() ?
					<div style={{ marginTop: '3rem' }} className="section-stats">
						<div className="form">
							<div className="form-row">
								<DatePickers
									startDate={this.state.start}
									endDate={this.state.end}
									onChange={this.onDateChange}
								/>
							</div>
						</div>

						<div className="clearfix" />

						<div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
							<Button type="secondary" onClick={this.updateData}>
								{this.props.translate("update")}
							</Button>
						</div>

						<Block>
							<span className="title">
								{this.props.translate('goals')}
							</span>

							{categories.length > 0 ?
								<Tabs>
									{categories.map((category, index) => {
										return <Tab title={category.title} key={'category' + index} route={'category' + index}>
											{this.renderCategoryGoals(category.title)}
										</Tab>
									})}
								</Tabs>
								: null}
						</Block>
					</div>
					: null}
			</div>
		);
	}

	render() {
		const { group } = this.props;

		if (!group || this.state.loading) {
			return (
				<Spinner center />
			);
		}

		switch (group.type) {
			case "EDUCATION_GROUP":
				return this.renderGroupContent();

			case "MENTOR_GROUP":
				return this.renderMentorGroup();

			default:
				return this.renderGroupContent();
		}
	}

}

function mapStateToProps(state) {
	return {
		group: state.sections.activeSection,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		services: state.Services.availableServices,
		goals: state.PreschoolGoals.preschoolGoals,
		goalStatistics: state.PreschoolGoals.statistics,
	};
}

export default withRouter(connect(mapStateToProps, {
	getSection,
	updateArchived,
	getEducationGroups,
	getArchivedEducationGroups,
	setPageTitle,
	getGoalsStatisticsForUsers,
	getArchievedMentorGroups,
	getMentorGroups,
})(PreSchoolSection));