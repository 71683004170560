import React, { Component } from 'react';
import { connect } from 'react-redux';

import Overview from 'containers/Overview';

class IndexWrapper extends Component {
	render() {
		if (this.props.currentUser == null) {
			return <div></div>
		} else {
			var typeRaw = this.props.currentUser.schools.getActiveSchool(this.props.currentUser.userId).type;
			var type = typeRaw ? typeRaw.toUpperCase() : "";

			return <Overview />
		}
	}
}

function mapStateToProps(state) {
	return {
		currentUser: state.user.currentUser
	};
}

export default connect(mapStateToProps)(IndexWrapper);
