import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { addError } from 'actions/index';
import { Button, translate, Checkbox } from '@haldor/ui';

import { getSchoolDataForSections } from 'actions/schools';

import api from 'lib/api';
import { getActiveSchool } from 'helpers/localstorage';

class SectionOptionForm extends Component {

	onSubmit = (values) => new Promise((resolve) => {
		const { translate } = this.props;
		const activeSchool = getActiveSchool(this.props.currentUser.id);

		let data = [];
		Object.keys(values).forEach((key) => {
			if (key == 'schoolId') {
				return false;
			}

			data.push({
				'Key': key,
				'Value': values[key],
			});
		});

		api.put('schools/schooldata', data).then(() => {
			resolve(1);
			this.props.getSchoolDataForSections(activeSchool).then(() => {
				this.props.onAbort(true);
				this.props.addError(translate('changes-saved'), 'info');
			});
		}).catch(() => {
			resolve(1);
			this.props.addError(translate('Something went wrong while saving the options'), 'error');
		});
	});

	render() {
		return (
			<div className="form-container">
				<div style={{ padding: '0 1.75rem' }}>
					<Form
						initialValues={this.props.allowManualConnection}
						onSubmit={this.onSubmit}
						render={({ submitting, handleSubmit, pristine }) => (
							<form className="form form-component" onSubmit={handleSubmit}>
								{submitting ?
									<div className="is_sending">
										<p><span className="loading-spinner" /></p>
									</div>
									: null}

								<div className="modal-content">
									<label>{this.props.translate('Allow manual connection of Groups')}</label>

									<div style={{ marginTop: '1rem' }}>
										<div>
											<Field
												component={Checkbox}
												name="allowManualMentorGroupConnection"
												label={this.props.translate("Mentor groups")}
											/>
										</div>

										<div style={{ marginTop: '.35rem' }}>
											<Field
												component={Checkbox}
												name="allowManualEducationGroupConnection"
												label={this.props.translate("Education groups")}
											/>
										</div>
									</div>
								</div>

								<div style={{ marginTop: '3rem' }} className="df aic jcb">
									<Button disabled={submitting || pristine}>
										{this.props.translate('save')}
									</Button>

									<div className="align-right">
										<Button onClick={(event) => {
											event.preventDefault();
											this.props.onAbort(false);
										}} type="secondary">
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
	currentUser: state.user.currentUser,
});

export default withRouter(connect(mapStateToProps, {
	addError,
	getSchoolDataForSections
})(SectionOptionForm));
