import { Stack } from '@mui/material';
import { AssignmentTaskStatusSubmitted, AssignmentTaskStatusNotStarted, AssignmentTaskStatusStarted, AssignmentTaskStatusCanDevelop, AssignmentTaskStatusNotComplete } from 'UI/Icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import './_simpleSelect.scss';

/**
 * @param {string} props name - Input name
 * @param {string} props id - Input id
 * @param {Array<value, label, disabled, color>} props options
 * @param {function} props onChange
 *
 * @returns {JSX Component}
 */
class SimpleSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.selectedValue != null ? this.props.selectedValue : null,
			disabled: this.props.disabled != null ? this.props.disabled : false,
			options: [],
		};
	}

	componentDidMount() {
		this.parseOptions();
	}

	parseOptions() {
		let initOptions = [];
		let assignmentTaskStatusIcons = [
			{ id: 'ASSIGNMENT_TASK_NOT_STARTED', icon: <AssignmentTaskStatusNotStarted /> },
			{ id: 'ASSIGNMENT_TASK_STARTED', icon: <AssignmentTaskStatusStarted /> },
			{ id: 'ASSIGNMENT_TASK_SUBMITTED', icon: <AssignmentTaskStatusSubmitted /> },
			{ id: 'ASSIGNMENT_TASK_NOT_COMPLETE', icon: <AssignmentTaskStatusNotComplete /> },
			{ id: 'ASSIGNMENT_TASK_CAN_DEVELOP', icon: <AssignmentTaskStatusCanDevelop /> },
		]

		if (this.props.status != null) {
			if (this.props.type == 'assignment') {
				initOptions = this.props.status.assignmentTaskStatus.filter(t => t.selectable);

				initOptions.forEach(option => {
					const icon = assignmentTaskStatusIcons.find(item => item.id === option.id)?.icon;
					option.icon = icon || null;
				});
			} else if (this.props.type == 'planning') {
				initOptions = this.props.status.planningStatus.filter(t => t.selectable);
			} else if (this.props.type == 'assessment') {
				initOptions = this.props.status.assessmentStatus.filter(t => t.selectable);
				// Remove icons and colors from options for assessments
				initOptions.forEach(option => { option.icon = null; option.color = null; });
			}
		}

		const options = initOptions.map((option) => {
			if (option != undefined) {
				let leftInfo = null;

				if (option.color) {
					switch (option.id) {
						case 'ASSESSMENT_PUBLISHED':
							leftInfo = <div className="simple-select__color" style={{ backgroundColor: option.color }}></div>;
							break;
						case 'ASSESSMENT_STARTED':
							leftInfo = <div className="simple-select__color__outline" style={{ borderColor: option.color }}></div>;
							break;
						case 'ASSESSMENT_COMPLETED':
							leftInfo = <div className="simple-select__color__outline" style={{ borderColor: option.color }}></div>;
							break;
						default:
							leftInfo = <div className="simple-select__color" style={{ backgroundColor: option.color }}></div>;
							break;
					}
				}

				leftInfo = option.icon ? <div style={{ width: '15px', marginTop: '5px' }}>{option.icon}</div> : leftInfo;

				const obj = {
					value: option.id,
					color: null,
					label: (
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={1}
						>
							{leftInfo}
							<div>{option.value}</div>
						</Stack>
					),
				};

				return obj;
			}
		});

		this.setState({ options });
	}

	UNSAFE_componentWillReceiveProps = (newProps) => {
		if (newProps.selectedValue !== this.props.selectedValue) {
			this.setState({ value: newProps.selectedValue });
		}

		this.setState({ disabled: newProps.disabled });

		this.parseOptions();
	}

	_onChange = (option) => {
		this.setState({ value: option.value }, () => {
			this.props.onChange(this.props.item, option);
		});
	}

	render() {
		let selectValue = null;

		if (this.state.options != null) {
			let selected = this.state.options.find(option =>
				option.value == this.state.value
			);
			selectValue = selected;
		}

		return (
			<Select
				menuPortalTarget={this.props.menuPortalTarget}
				menuPosition={this.props.menuPosition}
				menuPlacement={'bottom'}
				isDisabled={this.state.disabled}
				isSearchable={false}
				isClearable={false}
				name={this.props.name}
				value={selectValue}
				options={this.state.options}
				onChange={value => this._onChange(value)}
				className="simple-select"
				placeholder={this.props.placeholder != null ? this.props.placeholder : ''}
				components={{ IndicatorSeparator: () => null }}
			/>
		);
	}
}

function mapStateToProps(state) {
	return {
		status: state.status.status,
	};
}

export default connect(mapStateToProps, {})(SimpleSelect);
