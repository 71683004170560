import { getRootUrl } from 'helpers/url';

export const isMicrosoftTeams = () => {
	const userAgent = navigator.userAgent;
	return userAgent.includes("Teams") || window.self !== window.top || getRootUrl().indexOf('-tab') > -1;
}

export const isIframe = () => {
	try {
		return window.self !== window.top || getRootUrl().indexOf('-tab') > -1;
	} catch (e) {
		return true;
	}
}

export const backButtonEnable = () => {
	const isIframe = isMicrosoftTeams();

	let current = window.location.pathname;
	if (current[current.length - 1] == '/') {
		// Remove trailing slash
		current = current.slice(0, -1);
	}

	// Remove trailing slash on getRootUrl since it always contains one
	if (current == getRootUrl().slice(0, -1)) {
		return false;
	} else {
		if (current == '/' && isIframe) {
			return false;
		}

		if (current == '/menu') {
			return false;
		}

		if (current == '/search' && !isIframe) {
			return false;
		}

		if (current == '/assignments') {
			return false;
		}

		if (current == '/evaluations') {
			return false;
		}

		if (current == '/posts') {
			return false;
		}

		if (current == '/plans') {
			return false;
		}

		if (current == '/groups') {
			return false;
		}

		if (current == '/schedule') {
			return false;
		}

		if (current == '/notifications') {
			return false;
		}

		if (current == '/conversations') {
			return false;
		}

		if (current == '/report/absence') {
			return false;
		}

		if (current == '/report/attendance') {
			return false;
		}

		if (current == '/courses') {
			return false;
		}

		if (current == '/courses/standard') {
			return false;
		}

		if (current == '/conversations/admin/flagged') {
			return false;
		}

		if (current == '/forms/templates') {
			return false;
		}

		if (current == '/meetings') {
			return false;
		}

		if (current == '/progress') {
			return false;
		}

		if (current == '/additionaladjustments') {
			return false;
		}

		if (current == '/overview/mentor') {
			return false;
		}

		if (current == '/bookmarks') {
			return false;
		}

		if (current == '/administration') {
			return false;
		}

		if (current == '/sms') {
			return false;
		}

		return true;
	}
};

export const generateMicrosoftAssignmentDeepLink = (assignment, group, channel) => {
	const base = 'https://teams.microsoft.com/l/entity';
	const entityId = '66aeee93-507d-479a-a3ef-8f494af43945/classroom';

	let context = `{"subEntityId":"{\\"version\\":\\"1.0\\",\\"config\\":{\\"classes\\":[{\\"id\\":\\"${group}\\",\\"assignmentIds\\":[\\"${assignment}\\"]}]},\\"action\\":\\"navigate\\",\\"view\\":\\"assignment-viewer\\"}","channelId":"${channel}"}`;
	context = encodeURIComponent(context);

	return `${base}/${entityId}?context=${context}`;
}
