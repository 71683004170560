import api from 'lib/api';

export const LOG_ACTIVITY = 'LOG_ACTIVITY';
export const GET_LOGS_PAGE_BY_SCHOOL = 'GET_LOGS_PAGE_BY_SCHOOL';

export function logActivity(values) {
	return {
		type: LOG_ACTIVITY,
		payload: new Promise(resolve => {
			api.post('log', values).then((response) => {
				resolve(response.data);
			});
		})
	}
}

export function getLogsPage(startingTicks, pageSize) {
	return {
		type: GET_LOGS_PAGE_BY_SCHOOL,
		payload: new Promise(resolve => {
			api.get(`log?startingTicks=${startingTicks}&pageSize=${pageSize}`).then((response) => {
				resolve(response.data);
			});
		})
	}
}