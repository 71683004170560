import Moment from 'moment';

import {
	SUBMIT_POST,
	UPDATE_POST,
	GET_POSTS_NEXT_PAGE,
	SET_ACTIVE_POST_DETAILS,
	GET_SECTION_POSTS,
	DELETE_POST_FROM_STORE,
	CLEAR_POSTS_FROM_STORE,
	CLEAR_SECTION_POSTS_FROM_STORE,
	DELETE_POST,
} from 'actions/posts';

import {
	SUBMIT_PRESCHOOL_DOCUMENT,
	SET_ACTIVE_PRESCHOOL_DETAILS,
	UPDATE_PRESCHOOL_DOCUMENT,
	GET_PRESCHOOL_DOCUMENTS,
	GET_DOCUMENTS_PAGE,
	GET_SECTION_DOCUMENTS,
	CLEAR_DOCUMENTS_FROM_STORE,
	CLEAR_SECTION_DOCUMENTS_FROM_STORE,
	DELETE_DOCUMENT_FROM_STORE,
	DELETE_DOCUMENT,
} from 'actions/documents';

import { updateObjectInArray } from 'helpers/array';

const initialState = {
	combined: [],
	sectionCombined: [],
	posts: [],
	documentations: [],
	sectionDocumentations: [],
	sectionPosts: [],
};

export default function CombinedEvents(state, action) {
	const { type, value } = action;

	if (typeof (state) === 'undefined') {
		return initialState;
	}

	switch (type) {
		case GET_POSTS_NEXT_PAGE:
			let currentPostCombined = state.combined;
			action.payload.map(d => {
				const checkExisting = currentPostCombined.find(cb => {
					return cb.id == d.id && cb.ReferenceType == 'post';
				});

				if (typeof (checkExisting) == 'undefined') {
					currentPostCombined.push({ "ReferenceType": "post", ...d });
				}
			});

			if (currentPostCombined.length > 0) {
				currentPostCombined.sort(function (a, b) {
					return Moment(b.published).unix() - Moment(a.published).unix();
				});
			}

			return { ...state, combined: currentPostCombined, posts: action.payload };

		case GET_SECTION_POSTS:
			let currentSectionCombinedPosts = state.sectionCombined;

			if (action.payload != null && typeof (action.payload) == 'object') {
				action.payload.map(d => {
					const checkExisting = currentSectionCombinedPosts.find(cb => {
						return cb.id == d.id && cb.ReferenceType == 'post';
					});

					if (checkExisting == null) {
						currentSectionCombinedPosts.push({ "ReferenceType": "post", ...d });
					}
				});

				if (currentSectionCombinedPosts.length > 0) {
					currentSectionCombinedPosts.sort(function (a, b) {
						return Moment(b.published).unix() - Moment(a.published).unix();
					});
				}
			}

			return { ...state, sectionCombined: currentSectionCombinedPosts, sectionPosts: action.payload };

		case SUBMIT_POST:
			action.payload.ReferenceType = 'post';

			let addPosts = null
			if (state.combined != null) {
				addPosts = updateObjectInArray(state.combined, action.payload);
			} else {
				addPosts = updateObjectInArray([], action.payload);
			}

			return { ...state, combined: addPosts };

		case UPDATE_POST:
			action.payload.ReferenceType = 'post';
			let updatePosts = updateObjectInArray(state.combined, action.payload);

			return { ...state, combined: updatePosts };

		case SET_ACTIVE_POST_DETAILS:
			if (action.payload != null && !action.error) {
				action.payload.ReferenceType = 'post';
				let listposts = null;

				if (state.combined != null) {
					listposts = updateObjectInArray(state.combined, action.payload);
				} else {
					listposts = updateObjectInArray([], action.payload);
				}

				return { ...state, combined: listposts };
			}
			return state;

		case SUBMIT_PRESCHOOL_DOCUMENT:
			action.payload.ReferenceType = 'document';

			let addDocuments = null
			if (state.combined != null) {
				addDocuments = updateObjectInArray(state.combined, action.payload);
			} else {
				addDocuments = updateObjectInArray([], action.payload);
			}

			return { ...state, combined: addDocuments };

		case CLEAR_SECTION_POSTS_FROM_STORE:
			// Add ReferenceType==Post here
			return { ...state, sectionCombined: [] };

		case DELETE_POST_FROM_STORE:
			let currentPostsToDelete = state.combined;
			// Add ReferenceType==Post here
			currentPostsToDelete = currentPostsToDelete.filter(t => t.id != action.payload);
			return { ...state, combined: currentPostsToDelete };

		case CLEAR_POSTS_FROM_STORE:
			// Add ReferenceType==Post here
			return { ...state, combined: [], sectionCombined: [] };

		case GET_DOCUMENTS_PAGE:
			let currentDocumentsCombined = state.combined;
			action.payload.map(d => {
				// Add ReferenceType==Document here
				const checkExisting = currentDocumentsCombined.find(cb => {
					return cb.id == d.id && cb.ReferenceType == 'document';
				});

				if (typeof (checkExisting) == 'undefined') {
					currentDocumentsCombined.push({ "ReferenceType": "document", ...d });
				}
			});

			if (currentDocumentsCombined.length > 0) {
				currentDocumentsCombined.sort(function (a, b) {
					return Moment(b.published).unix() - Moment(a.published).unix();
				});
			}

			return { ...state, combined: currentDocumentsCombined, documentations: action.payload };

		case SET_ACTIVE_PRESCHOOL_DETAILS:
			if (action.payload != null) {
				action.payload.ReferenceType = 'document';
				let listdocuments = null;

				if (state.combined != null) {
					listdocuments = updateObjectInArray(state.combined, action.payload);
				} else {
					listdocuments = updateObjectInArray([], action.payload);
				}

				return { ...state, combined: listdocuments };
			}

			return state;

		case DELETE_DOCUMENT_FROM_STORE:
			// Add ReferenceType==Document here
			let currentDocumentsToDelete = state.combined.filter(t => t.id != action.payload);

			return { ...state, documents: currentDocumentsToDelete };

		case GET_SECTION_DOCUMENTS:
			let currentSectionCombinedDocuments = state.sectionCombined;
			action.payload.map(d => {
				const checkExisting = currentSectionCombinedDocuments.find(cb => {
					return cb.id == d.id && cb.ReferenceType == 'document';
				});

				if (typeof (checkExisting) == 'undefined') {
					currentSectionCombinedDocuments.push({ "ReferenceType": "document", ...d });
				}
			});

			if (currentSectionCombinedDocuments.length > 0) {
				currentSectionCombinedDocuments.sort(function (a, b) {
					return Moment(b.published).unix() - Moment(a.published).unix();
				});
			}

			return { ...state, sectionCombined: currentSectionCombinedDocuments, sectionDocumentations: action.payload };

		case CLEAR_DOCUMENTS_FROM_STORE:
			// Add ReferenceType==Document here
			return { ...state, combined: [], sectionCombined: [] };

		case CLEAR_SECTION_DOCUMENTS_FROM_STORE:
			// Add ReferenceType==Document here
			return { ...state, sectionCombined: [] };

		case DELETE_POST:
			let newCombinedItems = state.combined.filter(t => {
				if (action.payload == t.id && t.ReferenceType == 'post') {
					return false;
				}

				return true;
			});

			return { ...state, combined: newCombinedItems };

		case DELETE_DOCUMENT:
			let newCombinedDocuments = state.combined.filter(t => {
				if (action.payload == t.id && t.ReferenceType == 'document') {
					return false;
				}

				return true;
			});

			return { ...state, combined: newCombinedDocuments };

		default:
			return state;

			break;

	}
}
