import api from 'lib/api';

export const GET_COURSE_DETAILS = 'GET_COURSE_DETAILS';
export const SET_ACTIVE_COURSE = 'SET_ACTIVE_COURSE';
export const GET_COURSES = 'GET_COURSES';
export const GET_COURSE_BY_COURSECODE = 'GET_COURSE_BY_COURSECODE';
export const ADD_COURSE = 'ADD_COURSES';
export const PUT_COURSE = 'PUT_COURSES';
export const CLEAR_COURSES_FROM_STORE = 'CLEAR_COURSES_FROM_STORE';
export const GET_STANDARD_COURSES = 'GET_STANDARD_COURSES';

export const addCourse = (data, callback) => ({
	type: ADD_COURSE,
	payload: new Promise((resolve, reject) => {
		api.post('courses', data).then((response) => {
			if (response.status != 200) {
				reject(response);
			} else {
				resolve(response.data);

				if (callback != null) {
					callback(response);
				}
			}
		});
	}),
});

export const updateCourse = (data, callback) => ({
	type: PUT_COURSE,
	payload: new Promise((resolve, reject) => {
		api.put('courses', data).then((response) => {
			if (response.status > 300) {
				reject(response);
			} else {
				resolve(response.data);

				if (callback != null) {
					callback(response);
				}
			}
		});
	}),
});

export const getCourse = (id) => ({
	type: GET_COURSE_DETAILS,
	payload: new Promise((resolve, reject) => {
		api.get(`courses/${id}`).then((response) => {
			if (response.status != 200) {
				reject(response);
			} else {
				resolve(response.data);
			}
		});
	})
})

export const getCourseByCourseCode = (courseCode) => ({
	type: GET_COURSE_BY_COURSECODE,
	payload: new Promise((resolve, reject) => {
		api.get(`courses/courseCode/${courseCode}`).then((response) => {
			if (response.status != 200) {
				reject(response);
			} else {
				resolve(response.data);
			}
		});
	})
})

export const setActiveCourse = (id, callback) => {
	return [
		{
			type: SET_ACTIVE_COURSE,
			id: id,
		}
	];
};

export const getCourses = (type) => ({
	type: GET_COURSES,
	payload: new Promise((resolve, reject) => {
		api.get(`courses?type=${type}`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const getStandardCourses = () => ({
	type: GET_STANDARD_COURSES,
	payload: new Promise((resolve, reject) => {
		api.get(`courses?type=PREDEFINED_COURSE`).then((response) => {
			resolve(response.data);
		});
	}),
});

export const clearCoursesFromStore = () => ({
	type: CLEAR_COURSES_FROM_STORE,
	payload: []
})