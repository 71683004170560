import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
	getHaldorAssignments,
	getMicrosoftEducationAssignments,
	clearLatestAssignments,
	getLatestHaldorAssignments,
	getLatestMicrosoftEducationAssignments,
} from 'actions/assignments';

import {
	clearSearchResults,
	searchHaldorAssignmentsInGroup,
	searchMicrosoftAssignmentsInGroup,
	searchAssignment,
} from 'actions/search';
import {
	getSection
} from 'actions/sections';

import { ColorLabel, ColorLabelContainer, Spinner } from 'UI';
import { Search } from 'UI/Inputs';
import { Tabs, Tab, translate } from '@haldor/ui';
import FromNow from 'components/Presentation/Date/FromNow';
import GroupSelector from '../Partials/GroupSelector';

import './AssignmentSelect.scss';

class AssignmentSelect extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			loadingLatest: false,
			sectionLoadedAtSetup: props.section != null,
		};
	}

	componentDidMount = () => {
		this.setState({ loadingLatest: true });
		let promises = [];

		if (this.props.section != null) {
			if (this.props.services.haldor) {
				promises.push(this.props.getLatestHaldorAssignments(this.props.section.graphId));
			}

			if (this.props.services.microsoft) {
				promises.push(this.props.getLatestMicrosoftEducationAssignments(this.props.section.graphId));
			}
		} else {
			if (this.props.services.haldor) {
				promises.push(this.props.getHaldorAssignments());
			}

			if (this.props.services.microsoft) {
				promises.push(this.props.getMicrosoftEducationAssignments());
			}
		}

		Promise.all(promises).then(() => {
			this.setState({ loadingLatest: false });
		});
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		if (this.props.section == null && nextProps.section != null && this.state.loadingLatest == false) {
			this.setState({ loadingLatest: true });
			let promises = [];

			if (this.props.services.microsoft) {
				promises.push(this.props.getLatestHaldorAssignments(nextProps.section.graphId));
			}

			if (this.props.services.haldor) {
				promises.push(this.props.getLatestMicrosoftEducationAssignments(nextProps.section.graphId));
			}

			Promise.all(promises).then(() => {
				this.setState({ loadingLatest: false });
			});
		}
	}

	componentWillUnmount = () => {
		this.props.clearSearchResults();
		this.props.clearLatestAssignments();
	}

	assignmentFilter = (assignment) => {
		const { filterResults } = this.props;

		if (assignment.status == 'ASSIGNMENT_DELETED') {
			return true;
		}

		if (filterResults != null && filterResults.length > 0) {
			const found = filterResults.find(id => id == assignment.id);
			return found != null;
		}

		return false;
	}

	onGroupChange = (groupId, group) => {
		if (groupId != 0) {
			this.props.getSection(groupId);
		}
	};
	onSearch = (value) => {
		this.setState({ loading: true });
		let promises = [];

		if (this.props.section != null) {
			if (this.props.services.microsoft) {
				promises.push(this.props.searchMicrosoftAssignmentsInGroup(value.query, this.props.section.graphId));
			}

			if (this.props.services.haldor) {
				promises.push(this.props.searchHaldorAssignmentsInGroup(value.query, this.props.section.graphId));
			}
		} else {
			if (this.props.services.haldor) {
				promises.push(this.props.searchAssignment(value.query));
			}
		}

		Promise.all(promises).then(() => {
			this.setState({ loading: false });
		});
	}

	renderAssignment = (assignment) => {
		if (assignment.title == '')
			return null;

		if (this.assignmentFilter(assignment))
			return null;

		let courses = [];

		if (assignment.selectedCourses != null && this.props.section != null) {
			let courseIds = assignment.selectedCourses.split(";");
			courseIds.forEach((id) => {
				var findCourse = this.props.section.courses.find(t => t.id == id);

				if (findCourse != null) {
					courses.push(findCourse);
				}
			});
		}

		return (
			<div className="assignment" key={'selected-assignment-haldor-' + assignment.id} onClick={e => this.onSelect(assignment)}>
				{courses.length > 0 ?
					<ColorLabelContainer collapse inline style={{ position: 'relative', top: 2, marginRight: '0.5rem' }}>
						{courses.map(course => (
							<ColorLabel
								key={course.id}
								color={course.colorCode}
								tooltip={course.title}
							/>
						))}
					</ColorLabelContainer>
					: null}

				{assignment.title}

				{assignment.section != null ?
					<div className="card-meta">
						{assignment.section.title}
					</div>
					: null}

				{assignment.dueDate != null ?
					<FromNow>
						{assignment.dueDate}
					</FromNow>
					: null}
			</div>
		);
	}

	renderTeamsAssignment = (assignment) => {
		if (assignment.displayName == '')
			return null;

		if (this.assignmentFilter(assignment))
			return null;

		return (
			<div className="assignment" key={'selected-assignment-' + assignment.id} onClick={e => this.onSelect(assignment)}>
				{assignment.displayName}

				{assignment.reference != null && assignment.reference.section != null ?
					<div className="card-meta">
						{assignment.reference.section.title}
					</div>
					: null}

				{assignment.dueDateTime != null ?
					<FromNow>
						{assignment.dueDateTime}
					</FromNow>
					: null}
			</div>
		);
	}

	onSelect = (assignment) => {
		if (this.props.onSelect) {
			this.props.onSelect(assignment);
		}
	}

	render() {
		const {
			latestMicrosoftAssignments,
			latestHaldorAssignments,
			includeAssignmentsWithPlans,
			teacherAssignments,
			teacherTeamsAssignments,
		} = this.props;

		let latest = [];

		if (this.props.section != null) {
			if (includeAssignmentsWithPlans != null && includeAssignmentsWithPlans == false) {
				if (latestHaldorAssignments != null && latestHaldorAssignments != '') {
					latest.push(...latestHaldorAssignments.filter(t => t.plans.length == 0));
				}
				if (latestMicrosoftAssignments != null) {
					latest.push(...latestMicrosoftAssignments);
				}
			} else {
				if (latestMicrosoftAssignments != null) {
					latest.push(...latestMicrosoftAssignments);
				}

				if (latestHaldorAssignments != null) {
					latest.push(...latestHaldorAssignments);
				}
			}
		} else {
			if (teacherAssignments != null) {
				latest.push(...teacherAssignments);
			}
			if (teacherTeamsAssignments != null) {
				latest.push(...teacherTeamsAssignments);
			}
		}

		latest.sort(function (a, b) {
			let aDate, bDate = null;
			if (a.created != null) {
				aDate = a.created;
			} else {
				aDate = a.createdDateTime
			}

			if (b.created != null) {
				bDate = b.created;
			} else {
				bDate = b.createdDateTime
			}

			return moment.utc(bDate).local().unix() - moment.utc(aDate).local().unix();
		});

		return (
			<div className="container assignment-selector" style={{ marginTop: 0 }}>
				{this.state.sectionLoadedAtSetup ? null :
					<div style={{ marginBottom: 5 }}>
						<GroupSelector
							disabled={this.state.sectionLoadedAtSetup}
							onChange={this.onGroupChange}
							sectionId={this.props.section?.id}
						/>
					</div>
				}
				{this.props.section == null ? null : <div>
					<Search
						onSubmit={this.onSearch}
						disabled={this.props.section == null}
						loading={this.state.loading}
						lengthLimit={3}
						placeholder={this.props.section ? this.props.section.title ?
							this.props.translate('Search for assignments created for') + " " +
							this.props.section.title
							: "" : this.props.translate('Choose group first')}
					/>

					<Tabs>
						<Tab title={this.props.translate('latest')} route="latest">
							{this.state.loadingLatest ?
								<Spinner center />
								: null}

							{latest.map((assignment) => {
								if (assignment.displayName != null)
									return this.renderTeamsAssignment(assignment);

								const inLatestMicrosoftAssignments = latestMicrosoftAssignments?.findIndex((_assignment) => {
									return _assignment.id == assignment.externalID;
								});

								const inTeacherTeamsAssignments = teacherTeamsAssignments?.find((_assignment) => {
									return _assignment.id == assignment.externalID;
								});

								if (inLatestMicrosoftAssignments != null && inLatestMicrosoftAssignments > -1)
									return null;

								if (inTeacherTeamsAssignments != null)
									return null;

								return this.renderAssignment(assignment);
							})}
						</Tab>

						{this.props.searchAssignmentsNoGroup != null && this.props.searchAssignmentsNoGroup.length > 0 ?
							<Tab title="Haldor Education" route="haldor">
								{this.props.searchAssignmentsNoGroup.map((assignment) => {
									let inMicrosoftTeamsResults = this.props.microsoftAssignments?.findIndex(_assignment => {
										return _assignment.id == assignment.externalID;
									});

									if (inMicrosoftTeamsResults != null && inMicrosoftTeamsResults > -1)
										return null;

									return this.renderAssignment(assignment);
								})}
							</Tab>
							: null}

						{this.props.microsoftAssignments != null && this.props.microsoftAssignments.length > 0 ?
							<Tab title="Microsoft Assignments" route="microsoft">
								{this.props.microsoftAssignments.map((assignment) => {
									return this.renderTeamsAssignment(assignment);
								})}
							</Tab>
							: null}

						{this.props.haldorAssignments != null && this.props.haldorAssignments.length > 0 ?
							<Tab title="Haldor Education" route="haldor">
								{this.props.haldorAssignments.map((assignment) => {
									let inMicrosoftTeamsResults = this.props.microsoftAssignments?.findIndex(_assignment => {
										return _assignment.id == assignment.externalID;
									});

									if (inMicrosoftTeamsResults != null && inMicrosoftTeamsResults > -1)
										return null;

									return this.renderAssignment(assignment);
								})}
							</Tab>
							: null}

						{this.props.assignments != null && this.props.assignments.length > 0 ?
							<Tab title="Haldor Education" route="assignments">
								{this.props.assignments.map((assignment) => {
									return this.renderAssignment(assignment);
								})}
							</Tab>
							: null}
					</Tabs></div>}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		assignments: state.Search.assignments,
		haldorAssignments: state.Search.haldorAssignments,
		microsoftAssignments: state.Search.microsoftAssignments,
		latestMicrosoftAssignments: state.assignments.latestMicrosoftAssignments,
		latestHaldorAssignments: state.assignments.latestHaldorAssignments,
		services: state.Services.availableServices,
		translate: translate(state.Languages.translations),
		teacherAssignments: state.assignments.assignments,
		teacherTeamsAssignments: state.assignments.teamsAssignments,
		searchAssignmentsNoGroup: state.assignments.search_results,
	};
}

export default connect(mapStateToProps, {
	getHaldorAssignments,
	getMicrosoftEducationAssignments,
	searchHaldorAssignmentsInGroup,
	searchMicrosoftAssignmentsInGroup,
	getLatestHaldorAssignments,
	getLatestMicrosoftEducationAssignments,
	clearSearchResults,
	clearLatestAssignments,
	searchAssignment,
	getSection,
})(AssignmentSelect);