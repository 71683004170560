import React, { Component } from 'react';
import { Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import swal from 'sweetalert2';

import { clearVerdicts, getVerdictScale } from 'actions/verdicts';

import VerdictFeedbackForm from 'containers/Verdict/Form/Partials/VerdictFeedbackForm';
import VerdictLevelForm from 'containers/Verdict/Form/Partials/VerdictLevelForm';

import { Button } from '@haldor/ui';
import '../Verdict.scss';

class VerdictForm extends Component {

	constructor(props) {
		super(props);

		let level = 0;
		let feedback = '';

		if (props.initialValues != null) {
			if (props.initialValues.scaleLevelId != null) {
				level = props.initialValues.scaleLevelId;
			}

			if (props.initialValues.feedback != null) {
				feedback = props.initialValues.feedback;
			}
		}

		this.state = {
			level,
			feedback,
			loading: true,
			id: null
		};
	}

	componentDidMount = () => {
		if (this.props.scale == null) {
			this.props.getVerdictScale()
				.then(() => {
					this.initForm();
				})
		} else {
			this.initForm();
		}
	}

	initForm = () => {
		this.setState({ level: null, feedback: null, loading: true, id: null }, () => {
			if (this.props.duplicate == true) {
				let verdict = this.props.sectionVerdicts.find(verdict => {
					return this.props.activeStudent == verdict.userId;
				});
				this.setState({ level: verdict.scaleLevelId, feedback: verdict.feedback, loading: false });
			} else if (this.props.userVerdicts != null && this.props.userVerdicts.length > 0) {
				let latestVerdict = this.props.userVerdicts[0];
				if (latestVerdict.status == 'VERDICT_DRAFT') {
					this.setState({ level: latestVerdict.scaleLevelId, feedback: latestVerdict.feedback, id: latestVerdict.id });
				}

				this.setState({ loading: false });
			} else if (this.props.verdict != null) {
				this.setState({ level: this.props.verdict.scaleLevelId, feedback: this.props.verdict.feedback, id: this.props.verdict.id, loading: false });
			} else {
				this.setState({ loading: false });
			}
		});
	}

	submit = (values) => {
		return new Promise(resolve => {
			values.scaleLevelId = this.state.level;
			values.feedback = this.state.feedback;
			values.scaleId = this.props.scale.id;

			if (this.state.id != null)
				values.id = this.state.id;

			if (values.status == null) {
				values.status = 'VERDICT_PUBLISHED';
			}

			if (this.props.onSubmit != null) {
				this.props.onSubmit(values)
					.then(() => {
						if (values.status == "VERDICT_PUBLISHED") {
							this.setState({ level: 0, feedback: '' });
							this.props.reset();
						}
						this.initForm();
						resolve(1);
					});
			}
		});
	}

	deleteVerdictDraft = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('are-you-sure-you-want-to-delete'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then(async (result) => {
			if (result.value != null) {
				this.setState({ loading: true });
				await this.props.deleteVerdict(this.props.userVerdicts[0].id);
				this.setState({
					loading: false,
					level: 0,
					feedback: '',
					id: null
				});
			}
		});
	}

	render() {
		const { submitting, handleSubmit } = this.props;
		const { feedback, level, loading } = this.state;

		if (loading) {
			return <div></div>
		}

		let saveText = this.props.translate('save-verdict');
		let status = null;
		if (this.props.userVerdicts != null && this.props.userVerdicts.length > 0) {
			if (this.props.userVerdicts[0].status == 'VERDICT_DRAFT')
				saveText = this.props.translate('publish');
			status = this.props.userVerdicts[0].status;
		}

		let invalid_group = this.props.section?.deleted || this.props.section?.archived;
		let invalid = level == 0 || level == null || invalid_group;

		return (
			<div>
				{submitting ?
					<div className="is_sending" style={{ height: '100%', top: 0 }}>
						<p><span className="loading-spinner" /></p>
					</div>
					: null}

				{invalid_group ? <div className='info-box'>
					<span className="title">
						<Icon name="Alert" bw /> {this.props.translate('No evaluations can be created because the group is archived or deleted')}
					</span>
				</div> : null}

				<div className="form-container">
					<form onSubmit={handleSubmit(this.submit)} className="form form-component">
						<div className="form-row">
							{this.props.scale != null ?
								<VerdictLevelForm
									levels={this.props.scale.levels}
									value={level}
									onChange={level => this.setState({ level })}
									key={this.props.scale.id}
								/>
								: null}
						</div>

						<div className="form-row">
							<VerdictFeedbackForm
								value={this.state.feedback}
								onChange={feedback => this.setState({ feedback })}
							/>
						</div>

						<div className="form-row submit" style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
							<Button onClick={handleSubmit(values => this.submit({ ...values, status: 'VERDICT_PUBLISHED' }))} disabled={invalid}>
								{saveText}
							</Button>

							{this.props.edit == false ?
								<Button onClick={handleSubmit(values => this.submit({ ...values, status: 'VERDICT_DRAFT' }))} disabled={invalid}>
									{this.props.translate('save-draft')}
								</Button>
								: null}

							{status == 'VERDICT_DRAFT' ?
								<Button onClick={(e) => { e.preventDefault(); this.deleteVerdictDraft() }} type="secondary">
									{this.props.translate('Delete')}
								</Button>
								: null}

							<div className="clearfix"></div>
						</div>
					</form>
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		scale: state.Verdicts.scale,
		sectionVerdicts: state.Verdicts.section,
		section: state.sections.activeSection
	};
}

export default connect(mapStateToProps, {
	getVerdictScale,
	clearVerdicts,
})(reduxForm({
	form: 'VerdictForm',
	destroyOnUnmount: true,
})(VerdictForm));
