import moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Search as SearchInput } from 'UI/Inputs';
import { searchSchoolUsers } from 'actions/search';
import { DataList } from 'UI';
import { Checkbox, Button } from '@haldor/ui';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';

class UpdateMeetingInstance extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedUsers: [],
			loading: false
		};
	}

	submit = (values) => {
		return new Promise(resolve => {
			values.staff = this.state.selectedUsers;

			if (this.props.onSubmit) {
				this.props.onSubmit(values).then(() => {
					resolve(1);
				});
			}
		});
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ margintop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ margintop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	runSearch = (term) => {
		this.setState({ loading: true });

		var studentIds = this.props.meetingInstance.participants.filter(x => x.userType === 'STUDENT').map(x => x.userID);

		if (term != null && term !== '') {
			this.setState({ term });

			Promise.all([
				this.props.searchSchoolUsers(term, 'staff,guardian', studentIds),
			]).then(() => {
				this.setState({ loading: false, selectStudent: null });
			});
		}
	}

	onSearch = (value) => {
		this.runSearch(value.query);
	}

	selectUser = (staff) => {
		if (this.state.selectedUsers.findIndex(ss => ss.userId == staff.userId) > -1) {
			this.setState({ selectedUsers: this.state.selectedUsers.filter(t => t.userId != staff.userId) });
		} else {
			this.setState({ selectedUsers: [...this.state.selectedUsers, staff] });
		}
	}

	renderUserName = (user) => {
		if (!user.displayName || user.displayName === " ")
			return user.email;

		return user.displayName;
	}

	renderResult = (user) => {
		if (this.props.meetingInstance.participants.find(t => t.userID == user.userId) != null)
			return null;

		return (
			<RowItem key={user.userId}>
				<RowCell title={this.props.translate('name')}>
					<Checkbox
						value={this.state.selectedUsers.find(t => t.userId == user.userId) != null}
						onChange={() => this.selectUser(user)}
						label={this.renderUserName(user)}
					//disabled={this.state.selectedStaff.find(t => t.userId == user.userId) != null}
					/>

					{user.userType == 'GUARDIAN' ?
						<span style={{ marginLeft: '.55rem' }} className="size-12 color--meta">
							{this.props.translate('Guardian')}
						</span>
						: null}
				</RowCell>
			</RowItem>
		);
	}

	render() {
		const { handleSubmit, submitting, valid, translate } = this.props;
		let searchResult = this.props.staff.concat(this.props.guardians);
		return (
			<div className="form-container">
				{this.state.loading ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row" style={{ marginTop: 0 }}>
						<label style={{ marginTop: 0 }}>
							{this.props.translate('invite-participants')}
						</label>

						<SearchInput
							placeholder={this.props.translate('search')}
							onSubmit={this.onSearch}
							autofocus
							lengthLimit={3}
						/>
					</div>

					<div className="form-row" style={{ marginTop: '2rem' }}>
						<DataList
							title={translate('participants')}
							data={searchResult}
							renderRow={this.renderResult}
							emptyData={() => (
								<div className="color--meta text--center weight--bold">
									{translate('no-results')}
								</div>
							)}
						/>
					</div>

					<div className="form-divider" />

					<div className="form-row spacing submit" style={{ marginTop: '2em' }}>
						<Button
							onClick={handleSubmit(values =>
								this.submit({
									...values
								})
							)}
							disabled={submitting || !valid || this.checkDates()}
						>{this.props.translate('save')}</Button>
					</div>
				</form>
			</div>
		);
	}

	checkDates = () => {
		if (this.state.date != null && this.state.endDate != null) {
			if (this.state.date > this.state.endDate) {
				return true;
			}
		}

		return false;
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		initialValues: state.Meetings.meetingInstance,
		staff: state.Search.staff,
		guardians: state.Search.guardians,

	}
}

export default connect(mapStateToProps, {
	searchSchoolUsers
})(reduxForm({
	form: 'UpdateMeetingInstance',
	destroyOnUnmount: true,
})(UpdateMeetingInstance));
