import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import api from 'lib/api';
import { addError } from 'actions';

import { Button, translate, Checkbox, Spinner } from '@haldor/ui';

import { getNotificationSettings } from 'actions/notifications';
import { getUserNotificationSettings } from 'actions/user';

class NotificationOptionForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			optionsModalVisible: false,
			uniqueNotificationSettingsObjects: null
		};
	}

	componentDidMount = () => {
		if (this.props.values != null && this.props.values.length > 0) {

			let uniqueNotificationSettingsObjects = this.props.values.reduce((accumulator, current) => {
				if (!accumulator.find(item => item.notificationSettings.id === current.notificationSettings.id)) {
					accumulator.push(current);
				}
				return accumulator;
			}, [])
				.map(item => item.notificationSettings)
				.sort((a, b) => a.id - b.id);;

			this.setState({ uniqueNotificationSettingsObjects: uniqueNotificationSettingsObjects });
		}
	}

	onSubmit = (values) => new Promise((resolve) => {
		let data = [];
		this.setState({ loading: true });

		this.state.uniqueNotificationSettingsObjects?.forEach((setting) => {

			const notificationSpaces = setting.notificationSpace.split(',');
			notificationSpaces?.forEach((space) => {
				const userSetting = values.settings?.find((s) =>
					s.notificationSettingId == setting.id && s.notificationSpace == space.trim()
				);

				if (userSetting != null) {
					data.push(userSetting);
				} else {
					const object = {
						notificationSettingId: setting.id,
						notificationSpace: space.trim(),
						enabled: true,
					};

					data.push(object);
				}
			});
		});

		api.post('notifications/userNotificationSettings', data).then(() => {
			this.props.getUserNotificationSettings().then(() => {
				this.props.addError(this.props.translate('changes-saved'), 'info');

				if (this.props.onSubmit != null) {
					this.setState({ loading: false });
					this.props.onSubmit();
				}

				if (this.props.onAbort != null) {
					this.props.onAbort();
				}
			});
		}).catch(() => {
			this.props.addError(this.props.translate('Something went wrong while saving the options'), 'error');
			this.setState({ loading: false });
		});
	});

	renderField = (field) => {
		let value = false;
		if (field.input.value != '') {
			value = field.input.value.find((s) =>
				s.notificationSettingId == field.setting.id && s.notificationSpace.includes(field.space) && s.enabled == true
			) != null;
		}

		return (
			<Checkbox
				value={value}
				onChange={(checked) => {
					let values = [];
					if (field.input.value != '') {
						values = field.input.value;
					}

					let userSetting = values?.find((s) =>
						s.notificationSettingId == field.setting.id && s.notificationSpace == field.space
					);

					if (userSetting) {
						userSetting.enabled = checked;
					} else {
						var object = {
							notificationSettingId: field.setting.id,
							notificationSpace: field.space,
							enabled: checked,
						};

						values.push(object);
					}

					field.input.onChange(values);
				}}
			/>
		);
	}

	render() {
		let notice = this.props.translate('notice-notificationsettings');
		if (notice == 'notice-notificationsettings') {
			notice = '';
		}

		let uniqueNotificationSettingsObjects = this.props.values.reduce((accumulator, current) => {
			if (!accumulator.find(item => item.notificationSettings.id === current.notificationSettings.id)) {
				accumulator.push(current);
			}
			return accumulator;
		}, []).map(item => item.notificationSettings);

		if (this.state.loading) {
			return (<div>
				<Spinner center />
			</div>)
		}

		return (
			<div className="form-container">

				<div style={{ padding: '0 1.75rem' }}>
					<Form
						initialValues={{
							settings: this.props.values
						}}
						onSubmit={this.onSubmit}
						render={({ submitting, handleSubmit }) => {
							return (
								<form className="form form-component" onSubmit={handleSubmit}>
									{submitting ?
										<div className="is_sending">
											<p><span className="loading-spinner" /></p>
										</div>
										: null}

									<table className='modal-content'>
										<thead>
											<tr>
												<th>
												</th>

												<th style={{ paddingLeft: '1rem' }}>
													{this.props.translate('Teams')}
												</th>
											</tr>
										</thead>

										<tbody>
											{uniqueNotificationSettingsObjects?.map((setting) => {
												return (
													<tr key={setting.id}>
														<td style={{ paddingRight: '2rem' }}>
															{this.props.translate(setting.text)}
														</td>

														<td style={{ paddingLeft: '1rem' }}>
															{setting.notificationSpace.includes('Teams') ?
																<div>
																	<Field
																		component={this.renderField}
																		name={'settings'}
																		setting={setting}
																		space="Teams"
																	/>
																</div>
																: null}
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>

									<div style={{ marginTop: '3rem' }} className="df aic jcb">
										<Button disabled={submitting} id="save-input">
											{this.props.translate('save')}
										</Button>

										<div className="align-right">
											<Button onClick={(event) => {
												event.preventDefault();
												this.props.onAbort(false);
											}} type="secondary">
												{this.props.translate('Cancel')}
											</Button>
										</div>
									</div>
								</form>
							);
						}}
					/>
				</div>

			</div>
		);
	}

}

const mapStateToProps = state => ({
	translate: translate(state.Languages.translations),
	notificationSettings: state.Notifications.notificationSettings,
});

export default withRouter(connect(mapStateToProps, {
	getNotificationSettings,
	getUserNotificationSettings,
	addError
})(NotificationOptionForm));
