import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import Moment from 'moment';
import * as microsoftTeams from '@microsoft/teams-js';

import { getRootUrl } from 'helpers/url';
import { isMicrosoftTeams } from 'helpers/teams';

import { isUserTeacher } from 'helpers/user';

import { getEducationGroups, getSectionByGraphId, getSection, setActiveSection } from 'actions/sections';
import { setPageTitle, setPageActions } from 'actions/header';
import {
	clearAssignmentsFromStore,
	getHaldorAssignments,
	getMicrosoftEducationAssignments,
	setAssignmentServices,
	getAssignmentStatus,
	setMyAssignmentsActiveGroup,
	updateBulkAssignmentObject
} from 'actions/assignments';

import AssignmentForm from 'containers/Forms/AssignmentForm/AssignmentForm';
import MultipleAssignmentForm from 'containers/Forms/AssignmentForm/MultipleAssignmentForm';

import Expandable from 'UI/Elements/Expandable';
import { CardsContainer } from 'components/Cards/Card';
import LoadingCards from 'components/Cards/LoadingCard';
import AssignmentCard from 'components/Presentation/Cards/AssignmentCard';
import SectionTitle from 'components/Presentation/SectionTitle';
import SectionForm from 'containers/Forms/SectionForm';
import Modal from 'containers/Modals/Modal';

import { Icon, TooltipMenu, Button } from '@haldor/ui';
import moment from 'moment';

class MyAssignments extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			loadingGroups: true,
			lists: [],
			modalIsOpen: false,
			tasks: null,
			graphId: null,
			enableOverlayWarning: false,
			overlayWarningPrompt: false,
			sectionId: null,
			noSectionFound: false,
			teamsContext: null,
			multipleAssignmentFormModal: false,
			singleAssignmentFormModal: false,
			missingWorkspace: false,
			draftOpen: true,
			currentOpen: true,
			overDueOpen: false,
			upcomingOpen: false,
			submittedOpen: false,
			closedOpen: false,
			groupForm: false,
			groupSelected: '',
		};

		this.services = {
			microsoft: props.services.microsoft,
			haldor: props.services.haldor
		};

		this.timer = null;
		this.toggleMultipleAssignmentForm = this.toggleMultipleAssignmentForm.bind(this);
	}

	componentDidMount = () => {
		this.props.setAssignmentServices(this.services);
		this.initComponent();
	};

	initComponent = async () => {
		let that = this;
		if (isMicrosoftTeams()) {
			microsoftTeams.app.getContext().then((context) => {
				var promises = [];
				if (context.team) {
					if (this.services.haldor) {
						promises.push(this.props.getHaldorAssignments(context.team.groupId));
					} else if (this.services.microsoft) {
						promises.push(this.props.getMicrosoftEducationAssignments(context.team.groupId));
					}
				} else {
					if (this.services.haldor) {
						promises.push(this.props.getHaldorAssignments());
					} else if (this.services.microsoft) {
						promises.push(this.props.getMicrosoftEducationAssignments());
					}
				}

				Promise.race(promises).then(() => {
					that.setState({ loading: false });
					this.getData(this.props.sections);
				});
			});
		} else {
			var promises = [];
			if (this.props.groupid != null) {
				this.props.clearAssignmentsFromStore();
				this.props.setMyAssignmentsActiveGroup(this.props.groupid);
				if (this.props.activeSection.id == this.props.groupid) {
					if (this.services.haldor) {
						promises.push(this.props.getHaldorAssignments(this.props.activeSection.graphId));
					} else if (this.services.microsoft) {
						promises.push(this.props.getMicrosoftEducationAssignments(this.props.activeSection.graphId));
					}
				}
			}

			if (this.props.groupid == null && this.props.activeGroupForSection != null) {
				this.props.setMyAssignmentsActiveGroup(null);
				await this.props.clearAssignmentsFromStore();
			}
			if (
				((this.props.assignments == null || this.props.assignments.length == 0)
					|| (this.props.haldorAssignmentsFetchedUTC != null && moment().subtract(5, 'minute').isSameOrAfter(moment(this.props.haldorAssignmentsFetchedUTC)))
					|| (this.props.teamsAssignmentsFetchedUTC != null && moment().subtract(5, 'minute').isSameOrAfter(moment(this.props.teamsAssignmentsFetchedUTC))))
				&&
				this.props.groupid == null
			) {
				if (this.services.haldor) {
					promises.push(this.props.getHaldorAssignments());
				} else if (this.services.microsoft) {
					promises.push(this.props.getMicrosoftEducationAssignments());
				}
			}

			if (promises.length > 0) {
				Promise.race(promises).then(() => {
					this.setState({ loading: false });
					this.getData(this.props.sections);
				});
			} else {
				this.setState({ loading: false });
				this.getData(this.props.sections);
			}
		}
	};

	componentWillUnmount = () => {
		if (this.timer != null) {
			clearTimeout(this.timer);
		}
	};
	handleGroupDisplay = (groupName) => {
		if (groupName?.length > 0) {
			this.setState({
				groupSelected: groupName,
			});
		} else {
			this.setState({
				groupSelected: '',
			});
		}
	};

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		let list = [...nextProps.current, ...nextProps.overdue];

		if (list.length > 0) {
			let creatingAssignment = list.find(
				(assignment) =>
					assignment.created == '0001-01-01T00:00:00' &&
					Moment.utc(assignment.edited).isSame(Moment.utc(), 'hour')
			);

			if (creatingAssignment != null && this.timer == null) {
				this.timer = setTimeout(this.getAssignmentStatuses, 10000);
			}
		}

		if (this.props.sections.length == 0 && nextProps.sections.length > 0) {
			this.getData(nextProps.sections);
		}
	};
	getAssignmentStatuses = () => {
		clearTimeout(this.timer);
		this.timer = null;

		let promises = [];
		let list = [...this.props.current, ...this.props.overdue];

		list.forEach((assignment) => {
			if (assignment.created != '0001-01-01T00:00:00' || assignment.created == null) {
				return false;
			}

			if (!Moment.utc(assignment.edited).isSame(Moment.utc(), 'hour')) {
				return false;
			}

			promises.push(this.props.getAssignmentStatus(assignment.id));
		});

		Promise.all(promises).then(() => {
			let list = [...this.props.current, ...this.props.overdue];

			let creatingAssignment = list.find(
				(assignment) =>
					assignment.created == '0001-01-01T00:00:00' &&
					Moment.utc(assignment.edited).isSame(Moment.utc(), 'hour')
			);

			if (creatingAssignment != null) {
				this.timer = setTimeout(this.getAssignmentStatuses, 10000);
			}
		});
	};

	getData = async (sections) => {
		let isFrame = isMicrosoftTeams();

		if (isFrame && getRootUrl() != '/') {
			if (this.props.groupid != null) {
				let section = sections.find((section) => section.id == this.props.groupid);
				await this.setState({
					loadingGroups: false,
					sectionId: this.props.groupid,
					graphId: section.graphId,
					missingWorkspace: section.classroomUrl == null || section.classroomUrl == '',
				});
			} else {
				const context = await microsoftTeams.app.getContext();
				if (context.team) {
					let section = sections.find((section) => section.graphId == context.team.groupId);
					if (section != null) {
						await this.props.getSection(section.id);
						await this.setState({
							sectionId: section.id,
							noSectionFound: false,
							graphId: context.team.groupId,
							loadingGroups: false,
							missingWorkspace:
								section.classroomUrl == null || section.classroomUrl == '',
						});
					} else {
						await this.setState({
							noSectionFound: true,
							teamsContext: context,
							loadingGroups: false,
						});
					}
				} else {
					await this.setState({ noSectionFound: false, loadingGroups: false });
				}
			}
		} else {
			if (this.props.groupid != null) {
				let section = sections.find((section) => section.id == this.props.groupid);
				if (section != null) {
					await this.setState({
						loadingGroups: false,
						sectionId: this.props.groupid,
						graphId: section.graphId,
						missingWorkspace: section.classroomUrl == null || section.classroomUrl == '',
					});
				} else {
					if (this.props.groupid == this.props.activeSection.id) {
						await this.setState({
							loadingGroups: false,
							sectionId: this.props.groupid,
							graphId: this.props.activeSection.graphId,
						});
					}
				}
			} else {
				await this.setState({ loadingGroups: false });
			}
		}

		if (!this.services.haldor) {
			return null;
		}

		if (this.props.disableCreate) {
			return null;
		}

		let actions = [];
		const isTeacher = isUserTeacher(this.props.currentUser);

		if (isTeacher) {
			if (getRootUrl() == '/' || getRootUrl() == '/assignments-tab/') {
				actions.push({
					type: 'button',
					value: this.props.translate('task-create-button'),
					onClick: this.toggleMultipleAssignmentForm,
					buttonType: 'primary',
					icon: 'plus',
				});
			}

			actions.push({
				type: 'button',
				value: this.props.translate('create-group-assignment'),
				onClick: this.toggleSingleAssignmentForm,
				buttonType: 'primary',
				icon: 'plus',
			});

			if (getRootUrl() == '/assignments-tab/') {
				actions.push({
					type: 'icon',
					onClick: this.toggleGroupSettings,
					icon: 'cog_bw',
				});
			}
		}
	};

	DisplayNoAssignments = () => {
		return (
			<div className='color--meta text--center weight--bold'>
				{this.props.translate('no-assignments-results')}
			</div>
		);
	};

	openModal = () => {
		this.setState({ modalIsOpen: true });
	};

	toggleGroupSettings = (shouldReload) => {
		this.setState({ groupForm: !this.state.groupForm });

		if (shouldReload == true) {
			microsoftTeams.app.getContext().then((context) => {
				this.props.getSectionByGraphId(context.team.groupId);
			});
		}
	};

	closeModal = () => {
		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('close-form-prompt'),
			showCancelButton: true,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('Yes'),
		}).then((result) => {
			if (result.value != null) {
				this.setState({ modalIsOpen: false });
			}
		});
	};

	enableOverlayWarningClose = (enable) => {
		this.setState({ enableOverlayWarning: enable });
	};

	overlayWarningPromptAgree = () => {
		this.setState({ modalIsOpen: false, overlayWarningPrompt: false, enableOverlayWarning: false });
	};

	overlayWarningPromptClose = () => {
		this.setState({ overlayWarningPrompt: false });
	};

	teamsTabCloseForm = () => {
		this.setState({ noSectionFound: false }, () => {
			window.location = window.location.href;
		});
	};

	CreateButton = () => {
		if (!this.services.haldor) {
			return null;
		}

		if (this.props.disableCreate) {
			return null;
		}

		// Do not show button until loading is complete
		if (this.state.loading) {
			return null;
		}

		const isTeacher = isUserTeacher(this.props.currentUser);

		if (isTeacher) {
			if (this.state.noSectionFound) {
				return null;
			}

			if (this.state.missingWorkspace) {
				return (
					<div className='create-container' style={{ display: 'inline-block' }}>
						<Button type='secondary' onClick={this.showMissingWorkspaceNotice}>
							<Icon name='Plus' /> {this.props.translate('Create')}
						</Button>
					</div>
				);
			}

			return (
				<div className='create-container' style={{ display: 'inline-block' }}>
					<TooltipMenu
						trigger={
							<Button type='secondary'>
								<Icon name='Plus' /> {this.props.translate('Create')}
							</Button>
						}
					>
						<TooltipMenu.Item onClick={this.toggleSingleAssignmentForm}>
							{this.props.translate('create-group-assignment')}
						</TooltipMenu.Item>

						<TooltipMenu.Item onClick={this.toggleMultipleAssignmentForm}>
							{this.props.translate('task-create-button')}
						</TooltipMenu.Item>
					</TooltipMenu>
				</div>
			);
		}
	};

	showMissingWorkspaceNotice = () => {
		let text = this.props.translate(
			'A configuration problem has occurred with one of your groups. This means that the group is missing a workspace in Sharepoint and no assignments or plans can be created.'
		);
		text +=
			' ' +
			this.props.translate(
				'Please wait and try again later. If the problem persists, please contact your school for support.'
			);

		swal.fire({
			title: this.props.translate('Workspace missing'),
			text: text,
			showCancelButton: false,
			confirmButtonText: this.props.translate('Ok'),
		}).then((result) => {
			if (result.value != null) {
				this.setState({ modalIsOpen: false });
			}
		});
	};

	toggleSingleAssignmentForm = () => {
		this.setState({ singleAssignmentFormModal: !this.state.singleAssignmentFormModal });
	};

	toggleMultipleAssignmentForm = (value) => {
		if (window.location.pathname.indexOf('groups') == -1 && !isMicrosoftTeams()) {
			this.props.setActiveSection(null);
		}

		if (value && this.props.createdAssignments != null && this.props.createdAssignments.length > 0) {
			this.props.createdAssignments.forEach(a => {
				this.props.updateBulkAssignmentObject(a.id);
			})
		}

		this.setState({
			multipleAssignmentFormModal: !this.state.multipleAssignmentFormModal,
			groupSelected: '',
		});
	};

	renderContent = () => {
		const isTeacher = isUserTeacher(this.props.currentUser);
		let isFrame = isMicrosoftTeams();

		if (this.state.noSectionFound && isTeacher) {
			return (
				<SectionForm
					editView={false}
					teamsContext={this.state.teamsContext}
					teamsTabCloseForm={this.teamsTabCloseForm}
					tab='assignments'
					onboarding={true}
				/>
			);
		}

		if (this.state.loading || this.state.loadingGroups) {
			return <LoadingCards count={3} />;
		}

		let count = 0;
		if (this.props.assignments != null) {
			count += this.props.assignments.length;
		}

		if (this.props.teamsAssignments != null) {
			count += this.props.teamsAssignments.length;
		}

		if (count == 0) {
			return this.DisplayNoAssignments();
		}

		let sectionId = null;
		if (this.props.groupid != null && !isFrame) {
			sectionId = this.props.groupid;
		} else if (isFrame && this.state.sectionId != null) {
			sectionId = this.state.sectionId;
		}

		return (
			<div>
				{this.renderDrafts(sectionId)}
				{this.renderOverdue(sectionId)}
				{this.renderCurrent(sectionId)}
				{this.renderUpcoming(sectionId)}
				{this.renderSubmitted(sectionId)}
				{this.renderClosed(sectionId)}
			</div>
		);
	};

	renderTask(assignment) {
		const isTeacher = isUserTeacher(this.props.currentUser);

		return <AssignmentCard key={assignment.id} assignment={assignment} isTeacher={isTeacher} />;
	}

	renderDrafts(sectionId) {
		const isTeacher = isUserTeacher(this.props.currentUser);
		if (!isTeacher) {
			return null;
		}

		let limit = 99999;
		if (this.props.limit != null) {
			limit = this.props.limit;
		}

		let list = this.props.drafts;

		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('draft-list-title')}
				open={this.state.draftOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment, index) => {
						if (index < limit) {
							return this.renderTask(assignment);
						}
					})}
				</CardsContainer>

				{this.showAllLink()}
			</Expandable>
		);
	}

	renderOverdue(sectionId) {
		let limit = 99999;
		if (this.props.limit != null) {
			limit = this.props.limit;
		}

		let list = this.props.overdue;
		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('overdue-list-title')}
				open={this.state.overDueOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment, index) => {
						if (index < limit) {
							return this.renderTask(assignment);
						}
					})}
				</CardsContainer>

				{this.showAllLink()}
			</Expandable>
		);
	}

	renderCurrent = (sectionId) => {
		let limit = 99999;
		if (this.props.limit != null) {
			limit = this.props.limit;
		}

		let list = [...this.props.current];
		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('current-list-title')}
				open={this.state.currentOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment, index) => {
						if (index < limit) {
							return this.renderTask(assignment);
						}
					})}
				</CardsContainer>
				{this.showAllLink()}
			</Expandable>
		);
	};

	renderUpcoming(sectionId) {
		let limit = 99999;
		if (this.props.limit != null) {
			limit = this.props.limit;
		}

		let list = this.props.upcoming;
		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('upcoming-list-title')}
				open={this.state.upcomingOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment, index) => {
						if (index < limit) {
							return this.renderTask(assignment);
						}
					})}
				</CardsContainer>
				{this.showAllLink()}
			</Expandable>
		);
	}

	renderSubmitted(sectionId) {
		const isTeacher = isUserTeacher(this.props.currentUser);
		if (isTeacher || this.props.limit != null) {
			return null;
		}

		let list = this.props.submitted;
		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('submitted-list-title')}
				open={this.state.submittedOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment) => {
						return this.renderTask(assignment);
					})}
				</CardsContainer>
				{this.showAllLink()}
			</Expandable>
		);
	}
	renderClosed(sectionId) {
		const isTeacher = isUserTeacher(this.props.currentUser);
		if (!isTeacher || this.props.limit != null) {
			return null;
		}
		let list = this.props.closed;
		if (sectionId != null) {
			list = list.filter((t) => {
				if (t.id == parseInt(t.id, 10)) {
					return t.sectionId == sectionId;
				} else {
					return t.classId == this.state.graphId;
				}
			});
		}

		if (list.length == 0) {
			return null;
		}

		return (
			<Expandable
				title={this.props.translate('Hidden')}
				open={this.state.closedOpen}
				overrideOpen={true}
				ignorePropsUpdate
			>
				<CardsContainer>
					{list.map((assignment) => {
						return this.renderTask(assignment);
					})}
				</CardsContainer>
				{this.showAllLink()}
			</Expandable>
		);
	}

	showAllLink() {
		if (this.props.limit == null) {
			return null;
		}
		return (
			<span style={{ width: '100%', textAlign: 'center', display: 'block' }}>
				<Link style={{ color: '#ccc', textDecoration: 'none' }} to='/assignments/'>
					{this.props.translate('show-all')}
				</Link>
			</span>
		);
	}

	render() {
		const { groupid, planid, translate } = this.props;
		const assignmentFormParams = {
			enableOverlayWarningClose: this.enableOverlayWarningClose,
			overlayWarningPromptAgree: this.overlayWarningPromptAgree,
			overlayWarningPromptClose: this.overlayWarningPromptClose,
			overlayWarningPrompt: this.state.overlayWarningPrompt,
			enableOverlayWarning: this.state.enableOverlayWarning,
			onAbort: this.toggleSingleAssignmentForm,
			editView: false,
			cloneView: false,
			groupid,
			planid,
		};

		return (
			<div className='my_tasks'>
				<Modal
					isOpen={this.state.singleAssignmentFormModal}
					onClose={this.toggleSingleAssignmentForm}
					title={
						<span>
							{translate('create-group-assignment')}{' '}
							{this.state.groupSelected.length > 0
								? ` ${translate('for')} ${this.state.groupSelected}`
								: null}
						</span>
					}
				>
					<AssignmentForm
						{...assignmentFormParams}
						groupid={
							this.state.sectionId != null ? this.state.sectionId : this.props.groupid
						}
						planid={this.props.planid}
						editView={false}
						displayTitle={this.handleGroupDisplay}
					/>
				</Modal>

				{!this.state.loadingGroups ? (
					<Modal
						isOpen={this.state.multipleAssignmentFormModal}
						onClose={() => {
							this.toggleMultipleAssignmentForm(false);
						}}
						title={
							<span>
								{translate('task-create-button')}{' '}
								{this.state.groupSelected.length > 0
									? ` ${translate('for')} ${this.state.groupSelected}`
									: null}
							</span>
						}
					>
						<MultipleAssignmentForm
							displayTitle={this.handleGroupDisplay}
							onAbort={this.toggleMultipleAssignmentForm}
							section={this.props.activeSection}
						/>
					</Modal>
				) : null}

				{this.props.group != null ? (
					<Modal
						isOpen={this.state.groupForm}
						onClose={this.toggleGroupSettings}
						title={this.props.group.title}
					>
						<SectionForm
							onAbort={this.toggleGroupSettings}
							editView
							section={this.props.group}
						/>
					</Modal>
				) : null}

				<div>{this.renderContent()}</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		submitted: state.assignments.submitted,
		closed: state.assignments.closed,
		drafts: state.assignments.drafts,
		locked: state.assignments.locked,
		overdue: state.assignments.overdue,
		upcoming: state.assignments.upcoming,
		current: state.assignments.current,
		assignments: state.assignments.assignments,
		teamsAssignments: state.assignments.teamsAssignments,
		services: state.Services.availableServices,
		haldorAssignmentsFetchedUTC: state.assignments.haldorAssignmentsFetchedUTC,
		teamsAssignmentsFetchedUTC: state.assignments.teamsAssignmentsFetchedUTC,
		sections: state.sections.educationGroups,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		group: state.sections.graphSection,
		activeSection: state.sections.activeSection,
		activeGroupForSection: state.assignments.activeGroup,
		createdAssignments: state.assignments.newAssignments,
	};
}

export default connect(mapStateToProps, {
	getHaldorAssignments,
	getSection,
	setActiveSection,
	getEducationGroups,
	clearAssignmentsFromStore,
	setPageTitle,
	setPageActions,
	getMicrosoftEducationAssignments,
	setAssignmentServices,
	getSectionByGraphId,
	getAssignmentStatus,
	setMyAssignmentsActiveGroup,
	updateBulkAssignmentObject
})(MyAssignments);
