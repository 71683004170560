import { getSection } from 'actions/sections';
import StudentGuardianSelector from 'containers/Forms/Partials/StudentGuardianSelector';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Expandable, List } from 'UI';
import { Button } from '@haldor/ui';

class AddMeetingInstance extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sectionId: 0,
			date: null,
			endDate: null,
			description: null,
			error: null,
			selectedUsers: [],
			currentSelected: [],
		};
	}

	componentDidMount = () => {
		let currentSelected = [];
		this.props.instances.forEach((instance) => {
			currentSelected.push(instance.studentId)
		});

		this.setState({ currentSelected });
	}

	submit = (values) => {
		return new Promise(resolve => {
			values.instances = [];
			this.state.selectedUsers.forEach(student => {
				let instance = {
					date: null,
					endDate: null,
					description: null,
					participants: [],
					status: 'ACTIVE',
				};

				if (student.selected) {
					let participant = {
						userID: student.studentId,
						userType: "STUDENT",
						sectionId: student.sectionId,
					};

					instance.participants.push(participant);
				}

				if (student.guardians != null) {
					student.guardians.forEach((guardian) => {
						let participant = {
							userID: guardian.id,
							userType: "GUARDIAN",
							sectionId: student.sectionId,
						};

						instance.participants.push(participant);
					});
				}

				let currentUser = {
					userID: this.props.currentUser.id,
					userType: 'USER',
					sectionId: student.sectionId,
				};

				instance.participants.push(currentUser);
				values.type = "EVALUATION";
				instance.studentId = student.studentId;
				values.instances.push(instance);
			});

			if (this.props.onSubmit) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					});
			}
		});
	}

	onUpdate = (selectedUsers) => {
		this.setState({ selectedUsers: selectedUsers });
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ margintop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ margintop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	findSection() {
		let sectionId = 0;
		this.props.instances.forEach((instance) => {
			instance.participants.forEach((participant) => {
				if (participant.sectionID != null) {
					sectionId = participant.sectionID;
				}
			})
		});

		return sectionId;
	}

	render() {
		const { handleSubmit, submitting } = this.props;
		let sectionId = this.findSection();

		return (
			<div className="form-container">
				{submitting ? <div className="is_sending"><p><span className="loading-spinner" /></p></div> : null}

				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<Expandable
						contentStyles={{ padding: '1em 0rem' }}
						contentStylesClosed={{ padding: '0' }}
						headerStyles={this.state.error != null ? { border: '1px solid red' } : {}}
						whiteBackground={true}
						open={true}
						title={this.props.translate('participants')}
					>
						<StudentGuardianSelector
							mentorGroups={true}
							onUpdate={this.onUpdate}
							sectionId={sectionId}
							currentSelected={this.state.currentSelected}
						/>
					</Expandable>

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button>
							{this.props.translate('save')}
						</Button>
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
	}
}

export default connect(mapStateToProps, {
	getSection,
})(reduxForm({
	form: 'AddMeetingInstance',
	destroyOnUnmount: true,
})(AddMeetingInstance));
