import { getRootUrl } from 'helpers/url';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Card extends Component {
	constructor(props) {
		super(props);

		this.state = {
			color: this.props.hasOwnProperty('color') ? this.props.color : '',
			type: this.props.hasOwnProperty('type') ? this.props.type : 'standard',
		};
	}

	output = () => {
		const { target, type, url } = this.props;

		const isExternal = !!(target && target.indexOf('http') > -1);
		const isInternal = !!(target && !isExternal);

		if (isExternal) {
			return (
				<a href={target} target="_blank" className={`card card-${this.state.type}`} style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</a>
			);
		} else if (isInternal) {
			return (
				<Link to={target} className={`card card-${this.state.type}`} style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>
			);
		}

		if (this.props.onClick != null && target == null) {
			return (
				<a
					onClick={this.props.onClick}
					className={`card card-${this.state.type}`}
					style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</a>
			);
		}

		// console.warn('Deprecation warning: Missing target attribute for card. Please use an internal or external link in prop target.');
		// TODO: Remove the type checks below and make sure all cards have a
		// target attribute. Target will work with both external and internal
		// links and figures out how to handle them (based on http or not in url).

		if (this.props.type === 'task') {
			return (
				<Link
					to={`${getRootUrl()}assignment/${this.props.id}`}
					className={`card card-${this.state.type}`}
					style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>
			);
		} else if (this.props.type === 'planning') {
			return (
				<Link
					to={`${getRootUrl()}plan/${this.props.id}`}
					className={`card card-${this.state.type}`}
					style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>);
		} else if (this.props.type === 'news') {
			return this.props.item.id != 0 ?
				<Link to={`news/${this.props.item.id}`} className={`card card-${this.state.type}`} style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>
				:
				<a href={this.props.item.section.classroomUrl} target="_blank" className={`card card-${this.state.type}`}>
					{this.props.children}
				</a>;
		} else if (this.props.type === 'preschooldocument') {
			return this.props.item.id != 0 ?
				<Link
					to={`preschooldocument/${this.props.item.id}`}
					className={`card card-${this.state.type}`}
					style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>
				:
				<a href={this.props.item.section.classroomUrl} target="_blank" className={`card card-${this.state.type}`}>
					{this.props.children}
				</a>;
		} else if (this.props.type === 'schedule') {
			return (
				<Link
					to={`${getRootUrl()}lesson/${this.props.id}`}
					className={`card card-${this.state.type} ${this.props.className}`}
					style={{ borderLeftColor: this.state.color, ...this.props.style }}>
					{this.props.children}
				</Link>
			);
		}
		return (
			<div className={`card card-${this.state.type}`} style={{ borderLeftColor: this.state.color, ...this.props.style }}>
				{this.props.children}
			</div>
		);
	}

	render() {
		return (
			<div className="card-outer" key={this.props.id}>
				{this.output()}
			</div>
		);
	}
}

/**
* Cards container
* Displays cards in multiple columns based on screen width.
* @export CardsContainer
*/
export const CardsContainer = ({ children }) => (
	<div className="cards-container">
		{children}
	</div>
);

/**
* Card title
*/
export const CardTitle = ({ children }) => (
	<div className="card_title">
		{children}
	</div>
);

/**
* Card subtitle
*/
export const CardSubtitle = ({ children }) => (
	<div className="card_subtitle">
		{children}
	</div>
);

/**
* Card description
*/
export const CardDescription = ({ children }) => (
	<div className="card_description">
		{children}
	</div>
);

/**
* Card footer
*/
export const CardFooter = ({ children }) => (
	<div className="card-footer">
		{children}
	</div>
);
