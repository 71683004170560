import { newGuid } from "helpers/guid";
import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";
import { Minus } from "UI/Icons";
import { FlexibleTextArea } from "UI/Inputs";
import MatrixGradeStep from "./MatrixGradeStep";

class MatrixBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.block.id != null ? props.block.id : newGuid(),
			text: props.block.text != null ? props.block.text : "",
			cells: props.block.cells != null ? props.block.cells : []
		};
	}
	onStepAdd = event => {
		event.preventDefault();
		let cells = this.state.cells;
		var id = newGuid();
		var keyId = newGuid();
		cells.push({ id, keyId, text: "" });
		this.setState({ cells }, () => {
			this.sendToParagraph();
		});
	};
	onStepUpdate = blockStep => {
		let cells = this.state.cells;
		let step = cells.find(t => t.id == blockStep.id);
		step.text = blockStep.text;
		this.setState({ cells }, () => {
			this.sendToParagraph();
		});
	};
	onStepDelete = blockStep => {
		let cells = this.state.cells;
		cells = cells.filter(t => t.id != blockStep.id);
		this.setState({ cells }, () => {
			this.sendToParagraph();
		});
	};
	onChangeBlockTitle = text => {
		this.setState({ text }, () => {
			this.sendToParagraph();
		});
	};
	onBlockDelete = () => {
		this.props.onBlockDelete({ id: this.props.block.id });
	};
	sendToParagraph = () => {
		this.props.onBlockUpdate({
			id: this.props.block.id,
			text: this.state.text,
			cells: this.state.cells
		});
	};
	render() {
		return (
			<div>
				<div
					className="form-row"
					style={{
						padding: "0",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<div style={{ flexGrow: "1" }}>
						<FlexibleTextArea
							value={
								this.state.text != null ? this.state.text : ""
							}
							placeholder={this.props.translate("text")}
							onChange={event =>
								this.onChangeBlockTitle(event.target.value)
							}
						></FlexibleTextArea>
					</div>

					<div
						onClick={this.onBlockDelete}
						style={{
							flexShrink: "0",
							cursor: "pointer",
							margin: "0 1em",
							marginLeft: "3em"
						}}
					>
						<div
							style={{
								width: "15px",
								display: "inline-block",
								position: "relative",
								top: "2px",
								marginRight: "0.5em"
							}}
						>
							<Minus />
						</div>
						{this.props.translate("delete-block")}
					</div>
				</div>
				<div>
					<div
						style={{
							width: "100%",
							display: "flex",
							alignItems: "flex-start"
						}}
					>
						{this.state.cells.map(blockStep => {
							var key = blockStep.keyId
								? blockStep.keyId
								: blockStep.id;

							return (
								<MatrixGradeStep
									key={key}
									blockStep={blockStep}
									onStepUpdate={this.onStepUpdate.bind(this)}
									onStepDelete={this.onStepDelete.bind(this)}
								/>
							);
						}, this)}

						<div
							className="form-row"
							style={{
								flex: "1 1 0%",
								padding: "0",
								position: "relative",
								marginTop: "1em",
								marginRight: "2em",
								fontSize: "1em"
							}}
						>
							<div
								className="courses-add-btn"
								onClick={this.onStepAdd}
							>
								{this.props.translate("add-step")}
							</div>
						</div>
					</div>

					<div className="clearfix" />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations)
	};
}

export default connect(mapStateToProps, {})(MatrixBlock);
