import React, { Component } from "react";
import { translate } from '@haldor/ui';
import { connect } from "react-redux";

import ColorScale from '_class/ColorScale';

/* Compontent for selecting objects from a Matrix */

class MatrixAssessmentBlock extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userClicked: false,
		};

		this.onStepSelect.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.block.cells.forEach((step) => {
			step.selected = false;
		});
	}

	onStepSelect(step) {
		if (this.props.disabled != null && this.props.disabled) {
		} else {
			if (this.props.type == "single") {
				step.selected = step.selected ? false : true;
				this.props.block.cells.forEach((blockStep) =>
					blockStep.selected == null
						? (blockStep.selected = false)
						: null
				);
			} else {
				let index = this.props.block.cells.findIndex(
					(t) => t.id == step.id
				);
				let nextStep = this.props.block.cells[index + 1];

				if (index != 0) {
					if (nextStep != null && nextStep.selected) {
						step.selected = step.selected ? true : false;
					} else {
						step.selected = step.selected ? false : true;
					}
				}

				this.props.block.cells.forEach((blockStep, i) => {
					if (index > 0 && i == 0) {
						/* Handle F Grade */
						blockStep.selected = false;
					} else if (index == 0 && i == 0) {
						blockStep.selected = step.selected ? false : true;
					}

					if (index > i && i > 0) {
						/* Handle rest of Grades */
						blockStep.selected = step.selected ? true : false;
					} else if (index == i) {
					} else if (i != 0) {
						blockStep.selected = false;
					}
				});
			}

			this.setState({ userClicked: true }, () => {
				if (this.props.type == "single") {
					this.props.onMatrixDescriptionAssessmentUpdate(
						this.props.block,
						step,
						this.props.details
					);
				} else {
					this.props.onMatrixAssessmentUpdate(
						this.props.block,
						this.props.details
					);
				}
			});
		}
	}

	getGrade(index) {
		var type = this.props.type;
		if (type == "single") {
			index = index + 1;
		}
		if (index == 0) {
			return "F";
		} else if (index == 1) {
			return "E";
		} else if (index == 2) {
			return "C";
		} else if (index == 3) {
			return "A";
		} else {
			return "A";
		}
	}

	descriptionStepSelected = (step) => {
		if (this.props.assessments != null) {
			var selectedUsers = null;
			if (this.props.studentAssessment != null) {
				selectedUsers = [this.props.items[0]];
			} else {
				if (this.props.groupAssignment) {
					this.props.items.forEach((task) => {
						task.students.forEach((student) => {
							if (student.selected) {
								if (selectedUsers == null) {
									selectedUsers = [];
								}
								selectedUsers.push(student);
							}
						});
					});
				} else {
					selectedUsers = this.props.items.filter((t) => t.selected);
				}
			}
			if (selectedUsers.length == 1) {
				let studentId = "";
				if (this.props.referenceType == "Plan") {
					studentId = selectedUsers[0].id;
				} else {
					if (this.props.groupAssignment) {
						studentId = selectedUsers[0].id;
					} else {
						studentId = selectedUsers[0].assignedTo;
					}
				}
				var assessment = this.props.assessments.find(
					(t) => t.studentId == studentId
				);
				if (assessment != null) {
					var detail = assessment.assessmentDetails.find(
						(t) =>
							t.rowId == this.props.block.id &&
							t.cellId == step.id
					);
					if (detail != null) {
						return true;
					}
				}
			}
		}

		return false;
	};

	render() {
		var highestGrade = "";
		if (this.props.assessments != null) {
			var selectedUsers = null;
			if (this.props.studentAssessment != null) {
				selectedUsers = [this.props.items[0]];
			} else {
				if (this.props.groupAssignment) {
					this.props.items.forEach((task) => {
						task.students.forEach((student) => {
							if (student.selected) {
								if (selectedUsers == null) {
									selectedUsers = [];
								}
								selectedUsers.push(student);
							}
						});
					});
				} else {
					selectedUsers = this.props.items.filter((t) => t.selected);
				}
			}

			if (selectedUsers.length == 1) {
				let studentId = "";
				if (this.props.referenceType == "Plan" || this.props.referenceType == "TeamsAssignment") {
					studentId = selectedUsers[0].id;
				} else {
					if (this.props.groupAssignment) {
						studentId = selectedUsers[0].id;
					} else {
						studentId = selectedUsers[0].assignedTo;
					}
				}
				var assessment = this.props.assessments.find((t) => t.studentId == studentId);
				if (assessment != null) {
					var detail = assessment.assessmentDetails.find((t) => t.rowId == this.props.block.id);
					if (detail != null) {
						highestGrade = detail.cellId;
					}
				}
			}
		}

		var foundGrade = false;
		const colors = new ColorScale(this.props.block.cells.length).get();

		return (
			<div
				className={
					this.props.sentenceNr != null
						? this.props.sentenceNr == 1
							? `matrix-block paragraph`
							: `matrix-block`
						: `matrix-block`
				}
				key={this.props.block.id}
			>
				<div className="matrix-block-container">
					<div className="matrix-block-title">
						{this.props.index ?
							<span>{this.props.index}&nbsp;</span>
							: null}
						<span
							dangerouslySetInnerHTML={{
								__html: this.props.block.text.replace(
									new RegExp("\r\n", "g"),
									"<br />"
								),
							}}
						/>
					</div>

					<div className="matrix-block-steps">
						{this.props.block.cells.map((step, index) => {
							var grade = this.getGrade(index);
							if (this.props.type == "multiple") {
								if (highestGrade != "" && this.state.userClicked == false) {
									if (grade == highestGrade && foundGrade == false) {
										foundGrade = true;
										step.selected = true;
									} else if (highestGrade == step.id && foundGrade == false) {
										foundGrade = true;
										step.selected = true;
									} else if (grade != highestGrade && foundGrade == false && grade != "F") {
										step.selected = true;
									}
								} else if (highestGrade == "" && this.state.userClicked == false) {
									step.selected = false;
								}
							} else {
								let selected = this.descriptionStepSelected(step);
								step.selected = selected;
							}

							return (
								<div
									className={
										step.selected
											? `matrix-block-step selected grade-${grade}`
											: `matrix-block-step grade-${grade}`
									}
									key={step.id}
									style={{ '--level-color': colors[index] }}
									onClick={this.onStepSelect.bind(this, step)}
								>
									<div className="matrix-block-step-description">
										{step.text}
									</div>
								</div>
							);
						}, this)}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	};
}

export default connect(mapStateToProps, {})(MatrixAssessmentBlock);
