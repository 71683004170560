import { flagMessage } from 'actions/conversation';
import { getReaderParameters } from 'actions/user';
import { trackImmersiveReaderError } from 'helpers/insights';
import moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { Spinner } from 'UI';
import DateTime from '_class/DateTime';
import Message from './Partials/Message';

const ImmersiveReader = require('@microsoft/immersive-reader-sdk');

class ConversationMessages extends Component {

	/* Class setup */
	constructor(props) {
		super(props);

		this.state = {
			loadingMore: false,
			loading: true,
		};

		this.container = null;
		this.event = null;
		this.scrollLimit = 35;
		this.timeout = null;

	}

	setContainer = (element) => {
		this.container = element;
	}

	/* Lifecycle methods */
	componentWillUnmount = () => {
		this.container.removeEventListener('scroll', this.onScroll);
		ImmersiveReader.close();
	}

	componentDidMount = () => {
		// Get messages (page 1)
		const { conversation } = this.props;

		if (this.container != null) {
			this.container.scrollTop = this.container.scrollHeight;
			this.container.addEventListener('scroll', this.onScroll);
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevState != this.state) {
			return;
		}

		this.container.scrollTop = this.container.scrollHeight;
	}

	/* Helper methods */
	throttle = (func, immediate) => {
		return (...args) => {
			var later = function () {
				this.timeout = null;
				if (!immediate) func.apply(this, args);
			};

			var callNow = immediate && !this.timeout;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(later, this.scrollLimit);
			if (callNow) func.apply(this, args);
		};
	}

	loadMore = () => {
		let onScrollComplete = this.throttle(() => {
			if (!this.state.loadingMore) {
				this.setState({ loadingMore: true });
				console.log('load next page');
			}
		});

		onScrollComplete();
	}

	getUserById = (id) => {
		if (this.props.conversation != null) {
			let membership = this.props.conversation.memberships.find(membership => {
				return membership.membershipID == id;
			});

			if (membership != null) {
				return {
					id: membership.membershipID,
					...membership.user,
				};
			}
		}

		return false;
	}

	runImmersiveReader = async (message) => {
		const { user, translate } = this.props;
		const localLanguage = localStorage.getItem('language');

		const creator = this.getUserById(message.creator);
		let name = '';
		const time = new DateTime(message.created).getLongDateWithTime();

		if (message.senderId != null && message.senderType == 'SECTION') {
			const owner_section = this.props.conversation.memberships.find(m => m.membershipID == message.senderId);
			if (owner_section != null) {
				name = owner_section.section?.title;
			}
		} else {
			name = creator.firstName + ' ' + creator.lastName;
		}

		const data = {
			title: name,
			chunks: [
				{
					content: name + ', ' + time + '<br /><br />',
					mimeType: 'text/html',
				},
				{
					content: message.text,
					mimeType: 'text/html',
				},
			],
		};

		await this.props.getReaderParameters();
		const { reader } = this.props;

		if (reader == null) {
			return;
		}

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
			uiZIndex: 9147483,
		}).catch((error) => {
			console.error('error', error);
			trackImmersiveReaderError(error, user.id);
		});
	}

	/* Events */
	getMessages = () => {

	}

	onScroll = (event) => {
		if (this.container.scrollTop < 10) {
			this.loadMore();
		}
	}

	reportMessage = (message) => {
		const { translate } = this.props;

		swal.fire({
			title: translate('Report message as inappropriate'),
			text: translate('Why do you want to report this message?'),
			input: 'text',
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('Report message'),
		}).then(response => {
			if (response.value != null) {
				this.props.flagMessage(message.id, response.value).then(() => {
					swal.fire({
						title: translate('The message has been reported'),
						text: translate('The message will be hidden while waiting for an administrator to manage the report.'),
						showCancelButton: false,
						confirmButtonText: 'Okay',
					});
				});
			}
		});
	}

	/* Render methods */
	renderMessage = (message, index) => {
		const { user } = this.props;
		let me = false;
		let narrow = false;
		let up = false;
		let down = false;
		let newMessage = false;
		let actions = [];
		let flagged = null;

		if (message.creator == user.id) {
			me = true;

			if (message.flagID != null) {
				flagged = this.props.translate('Message is reported as inappropriate.');
			}
		} else {
			if (message.flagID == null) {
				actions.push({
					label: this.props.translate('Report message'),
					onClick: () => this.reportMessage(message),
				});
			} else {
				flagged = this.props.translate('Message is reported as inappropriate.');
			}
		}

		if (this.props.services.immersiveReaderConversations) {
			actions.push({
				label: this.props.translate('immersive-reader'),
				onClick: () => this.runImmersiveReader(message),
			});
		}

		if (moment(message.created) > moment(this.props.conversation.lastRead) && !me) {
			newMessage = true;
		}

		let lastMessage = this.props.conversation.messages[index - 1];
		if (lastMessage != null && lastMessage.creator == message.creator) {
			narrow = true;
			up = true;
		}

		let sender = '';
		let creator = this.getUserById(message.creator);
		let nextMessage = this.props.conversation.messages[index + 1];
		if (nextMessage != null) {
			if (nextMessage.creator == message.creator) {
				down = true;
			}
		}

		if (message.senderId != null && message.senderType == 'SECTION') {
			const owner_section = this.props.conversation.memberships.find(m => m.membershipID == message.senderId);
			if (owner_section != null) {
				sender = owner_section.section?.title;
			}
		} else {
			sender = creator.firstName + ' ' + creator.lastName;
		}

		return <Message
			key={message.id}
			actions={actions}
			time={message.created}
			multipleUp={up}
			multipleDown={down}
			narrow={narrow}
			sender={sender}
			me={me}
			new={newMessage}
			children={message.text}
			flagged={flagged}
			messageId={message.id}
		/>
	}

	render() {
		return (
			<div className="conversation messages-container" ref={this.setContainer}>
				<div className="__inner">
					{this.props.conversation != null ?
						this.props.conversation.messages.map(this.renderMessage)
						:
						<Spinner center />
					}
				</div>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		services: state.Services.availableServices,
		messages: state.Conversation.messages,
		reader: state.user.reader,
	};
}

export default connect(mapStateToProps, {
	flagMessage,
	getReaderParameters,
})(ConversationMessages);
