import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Icon, Expandable, Checkbox } from '@haldor/ui';
import { useTranslate } from 'lib/translate';
import { capitalize } from 'helpers/content';
import { Collapsible } from 'UI';
import { ASSESSMENT_TYPES } from 'components/BlockAssessments/AssessmentTypes';
import DOMPurify from "dompurify";


import './_SelectMatricesForm.scss';

const SelectMatricesForm = (props) => {
	const { course, part } = props;
	const translate = useTranslate();
	const [selectedRows, setSelectedRows] = useState([]);

	useEffect(() => {
		if (part == null || part.assessmentBlockPartRows == null) {
			return null;
		}

		let rows = [];
		part.assessmentBlockPartRows.forEach((row) => {
			rows.push(row);
		});

		// Only update selectedRows if rows don't already exist in selectedRows
		if (rows.filter(row => selectedRows.some((selectedRow) => selectedRow.referenceId == row.referenceId)).length != rows.length) {
			setSelectedRows(rows);
		}

		return null;
	}, [part]);

	useEffect(() => {
		if (props.onChange != null) {
			const matrix = getMatrix();

			if (matrix == null) {
				// Dont add value for this course if matrix is null
				return null;
			}

			let relations = [
				{
					'courseId': course.id,
				}
			];

			if (part != null && part.assessmentBlockPartRelationships != null) {
				relations = part.assessmentBlockPartRelationships;
			}

			// Build assessmentPart object for course
			props.onChange({
				...props.part,
				'referenceId': matrix.id,
				'assessmentBlockPartRows': selectedRows,
				'assessmentBlockPartRelationships': relations,
			});
		}
	}, [selectedRows]);

	const getCourseDetail = () => {
		return course.details.find((detail) =>
			detail.matrixID == part.referenceId
		)
	}

	const getMatrix = () => {
		if (part == null || part.referenceId == null || part.referenceId == '' || part.referenceId == 0) {
			return course.details.filter((detail) =>
				detail.endDate == null || moment(detail.endDate).isAfter(moment())
			)[0]?.matrix;
		}

		return course.details.find((detail) =>
			detail.matrixID == part.referenceId
		)?.matrix;
	}

	/**
	 * @param {object} row
	 */
	const getRowClarifications = (row) => {
		if (props.clarifications == null) {
			return [];
		}

		const found = props.clarifications.find((clarification) => {
			if (clarification.value == null) {
				return false;
			}

			return clarification.value.title == row.id;
		});

		if (found == null) {
			return [];
		}

		return found.value.rows;
	}

	const removeCourse = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (props.onRemove != null) {
			props.onRemove();
		}
	}

	const onToggleRow = async (row) => {
		const selected = selectedRows.find((selectedRow) => selectedRow.referenceId == row.id);

		if (selected != null) {
			setSelectedRows(selectedRows.filter((selectedRow) => selectedRow.referenceId != row.id));
		} else {
			setSelectedRows([...selectedRows, {
				'referenceType': 'matrixRow',
				'referenceId': row.id,
			}]);
		}
	}

	const onToggleParagraph = async (active, paragraph) => {
		let values = [...selectedRows];
		if (active) {
			// Remove all previously selected rows for this paragraph
			values = values.filter((selectedRow) => {
				return paragraph.rows.find((r) => r.id == selectedRow.referenceId) == null;
			});

			// Add all rows as selected
			values.push(...paragraph.rows.map((row) => ({
				'referenceType': 'matrixRow',
				'referenceId': row.id,
			})));
		} else {
			// Remove selected rows for this paragraph
			values = values.filter((selectedRow) => {
				return paragraph.rows.find((r) => r.id == selectedRow.referenceId) == null;
			});
		}

		setSelectedRows(values);
	}

	/**
	 * @param {string} target
	 * @param {string} referenceType
	 * @description Toggles target permission on part data
	 */
	const togglePermission = (target, referenceType) => {
		let permissions = props.part.permissions;

		const indexOf = props.part.permissions?.findIndex(element =>
			element.target == target && element.referenceType == referenceType
		);

		if (indexOf > -1) {
			permissions.splice(indexOf, 1);
		} else {
			permissions.push({ target: target, referenceType: referenceType, level: 'READ' });
		}

		props.onChange({
			...props.part,
			permissions: permissions
		});
	}

	/**
	 * @param {string} target
	 */
	const renderPermission = (target) => {
		const active = props.part.permissions?.find(element =>
			element.target == target && element.referenceType == 'ASSESSMENTBLOCKPART'
		) != null;

		return <div className="permission active" onClick={(event) => {
			event.stopPropagation(); togglePermission(target, 'ASSESSMENTBLOCKPART');
		}}>
			<Icon name={active ? "Eye" : "Eye_Off"} />
			{translate(capitalize(target))}
		</div>
	}

	const renderAssessmentFeedbackPermission = () => {
		const active = props.part.permissions?.find(element =>
			element.target == 'GUARDIAN' && element.referenceType == 'ASSESSMENTFEEDBACK'
		) != null;

		return <div className="permission active" onClick={(event) => {
			event.stopPropagation(); togglePermission('GUARDIAN', 'ASSESSMENTFEEDBACK');
		}}>
			{props.assessmentType == ASSESSMENT_TYPES.TEACHER &&
				<>
					<Icon name={active ? "Eye" : "Eye_Off"} />
					{translate(capitalize('Show feedback for guardians'))}
				</>
			}
		</div>
	}

	const renderExpiredText = () => {
		const courseDetail = getCourseDetail();
		if (courseDetail != null && courseDetail.endDate != null && moment(courseDetail.endDate).isBefore(moment())) {
			return <div>
				<Icon style={{ marginRight: '0.5rem' }} name="Alert_Red" ></Icon>
				{translate('Expired')}
			</div>
		}

		return null;
	}

	const renderRowTitle = () => {
		const courseTitle = course.year != null ? `${course.title} ${course.year} (${course.courseCode})` :  `${course.title} (${course.courseCode})`;

		return <div className="matrix_header">
			<div className="matrix_title">
			{courseTitle} {renderExpiredText()}</div>

			{props.assessmentType == ASSESSMENT_TYPES.TEACHER &&
				<div className='permission_box'>
					<div className="row-tools">
						{renderPermission('STUDENT')}
					</div>

					<div className="row-tools">
						{renderPermission('GUARDIAN')}
					</div>
				</div>
			}

			{props.disableRows == null || props.disableRows.length == 0 ?
				<div className="controls row-tools">
					<div onClick={removeCourse}>
						<Icon name="Bin" />
					</div>
				</div>
				: null}
		</div>
	}

	const getDisabledRowsForParagraph = (paragraph) => {
		if (props.disableRows == null || props.disableRows.length == 0) {
			return [];
		}

		let disabled = [];
		paragraph.rows.forEach((row) => {
			const reference = props.part.assessmentBlockPartRows.find((partRow) => partRow.referenceId == row.id);
			if (reference == null) {
				return false;
			}

			if (props.disableRows.indexOf(reference.id) > -1) {
				disabled.push(row.id);
			}
		});

		return disabled;
	}

	const renderMatrix = () => {
		const matrix = getMatrix();
		const courseDetail = getCourseDetail();
		const isExpiredCourseDetail = courseDetail != null && courseDetail.endDate != null && moment(courseDetail.endDate).isBefore(moment());

		if (matrix == null || matrix._ParagraphSet == null) {
			return null;
		}

		return matrix._ParagraphSet.map((paragraph) => {
			let active = true;
			const disabledRows = getDisabledRowsForParagraph(paragraph);
			paragraph.rows.forEach((row) => {
				if (selectedRows.find((selectedRow) => selectedRow.referenceId == row.id) == null) {
					active = false;
				}
			});

			return <div className="paragraph" key={paragraph.id}>
				<div style={{ marginBottom: '1rem' }}>
					<Checkbox
						onChange={(active) => onToggleParagraph(active, paragraph)}
						value={active}
						disabled={disabledRows.length > 0 || isExpiredCourseDetail}
					/>
					<span className="matrix-paragraph-title">
						{paragraph.title}
					</span>
					{paragraph.subtitle != null ? <div className="matrix-paragraph-subtitle">{paragraph.subtitle}</div> : null}
				</div>

				{paragraph.rows.map((row, index) => {
					const selected = selectedRows.find((selectedRow) => selectedRow.referenceId == row.id) != null;
					const clarifications = getRowClarifications(row);

					return <Fragment key={row.id}>
						<div className="df row">
							<div className="df ais">
								<div className="df aic">
									<div>
										{paragraph.paragraphNr}.{row.sentenceNr}
									</div>

									<Checkbox
										onChange={() => onToggleRow(row)}
										value={selected}
										disabled={disabledRows.indexOf(row.id) > -1 || isExpiredCourseDetail}
									/>
								</div>
							</div>

							{row.text != null ? <div className="column-text" dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(
									row.text.replace(
										new RegExp("\r\n", "g"),
										"<br />"
									)
								)
							}}>
							</div> : null}

							<div className="cells df">
								{row.cells.map((cell) => {
									return <div className="cell" key={cell.id}>
										{cell.text}
									</div>
								})}
							</div>
						</div>

						{clarifications.length > 0 ?
							<div className="clarifications select-matrices-form">
								<Collapsible trigger={translate('Clarifications')}>
									{clarifications.map((clarification) => {
										return <div className="df row" key={clarification.id}>

											{clarification.description != null ? <div className="column-text" dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(
													clarification.description.replace(
														new RegExp("\r\n", "g"),
														"<br />"
													)
												)
											}}>
											</div> : null}

											<div className="cells df">
												{clarification.cells.map((cell) => {
													return <div className="cell" key={cell.id}>
														{cell.value}
													</div>
												})}
											</div>
										</div>
									})}

									<div className="color--meta">
										<Icon name="Alert" bw /> {translate('Existing clarifications will not be copied and cannot be edited. Move them to an assessment goal instead.')}
									</div>
								</Collapsible>
							</div>
							: null}
					</Fragment>
				})}
			</div>
		});
	}

	if (course == null) {
		return null;
	}

	let color = '#ccc';
	if (course.colorCode != null) {
		color = course.colorCode;
	}

	return <div className="select-matrices-form">
		<Expandable title={renderRowTitle()} color={color} open ignorePropsUpdate>
			<div className="guardian-feedback-permission">
				{renderAssessmentFeedbackPermission()}
			</div>

			{props.disabled ?
				<div className="disabled-notice">
					<Icon name="Alert" bw />
					{translate('To be able to edit, you must first delete the assessments made.')}
				</div>
				: null}

			{renderMatrix()}
		</Expandable>
	</div>
}

SelectMatricesForm.defaultProps = {
	disabled: false,
};

export default SelectMatricesForm;