import React from 'react';

const AssignmentTaskStatusNotComplete = React.memo(() => {
	return (
		<svg
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<path
				d="M8 11.87a16.93 16.93 0 1 1-2.95 17.81m.52-15.25 10.93 2.3M5.85 2.81l-.28 11.62"
				style={{
					fill: "none",
					stroke: "#e2c30b",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: "3.61px",
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusNotComplete;