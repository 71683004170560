import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Expandable } from 'UI';
import './Styles.scss';

class SelectedCoreContent extends Component {

	getContents = () => {
		const { associatedCoreContent, assessmentBlocks } = this.props;
		let coreContents = [];

		if (associatedCoreContent != null) {
			associatedCoreContent.map((reference) => {
				if (reference == null || reference.coreContents == null) {
					return false;
				}

				reference.coreContents.map((coreContent) => {
					var findCourse = null;
					assessmentBlocks.forEach((block) => {
						block.resources.forEach((resource) => {
							if (resource.referenceType == 'matrix') {
								let detail = resource.value.details.find((detail) =>
									detail.id == coreContent.courseDetailsId
								);

								if (detail != null) {
									findCourse = resource.value;
								}
							}
						})
					});

					let courseTitle = "";
					let courseId = "";

					if (findCourse != null) {
						courseTitle = findCourse.title;
						courseId = findCourse.id;
					}

					coreContents.push({
						...coreContent,
						courseTitle: courseTitle,
						courseId: courseId,
					});
				});
			});

		} else {
			if (this.props.coreContents != null && this.props.coreContents.length > 0) {
				coreContents = this.props.coreContents[0].coreContents;
			}
		}

		return coreContents;
	}

	render() {
		const { translate } = this.props;
		const coreContents = this.getContents();

		if (coreContents == null || coreContents.length == 0) {
			return null;
		}

		return (
			<div className="core-content selected">
				<h3 style={{ fontWeight: 500 }}>
					{this.props.translate('central-content')}
				</h3>

				<Expandable
					title={translate('plan-contains-core-content-title')}
					whiteBackground
					key={'core-content-container'}
				>
					{coreContents.map((coreContent, i) => {
						return <div style={i > 0 ? { paddingTop: "2rem" } : {}} key={coreContent.id}>
							{coreContent.courseTitle != null && coreContent.courseTitle != "" ?
								<h2 style={{ textDecorationLine: "underline" }}>
									{coreContent.courseTitle}
								</h2>
								: null}

							{coreContent.aspects.length > 0 ?
								<div style={{ paddingLeft: "2rem" }} className="core-content-row">
									{coreContent.aspects.map(aspect => {
										return <div className="content-row" key={'core-content-row-' + aspect.id}>
											<h4>
												{aspect.title != null ? aspect.title : ""}
											</h4>

											<ul>
												{aspect.aspectRows.map(row => {
													return <li key={'aspect-row-' + row.id}>
														{row.text}
													</li>
												})}
											</ul>
										</div>
									})}
								</div>
								: null}
						</div>
					})}
				</Expandable>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		activeSection: state.sections.activeSection,
	};
}

export default connect(mapStateToProps)(SelectedCoreContent);
