import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import promise from 'redux-promise';
import { applyMiddleware, compose, createStore } from 'redux';
import * as microsoftTeams from '@microsoft/teams-js';

import { BrowserRouter as Router } from 'react-router-dom';

import { isSupported } from 'helpers/localstorage';
import sniffer from 'sniffer';

import jsonMiddleware from './middleware/parseJson';

import reducers from './reducers';
import Routes from './routes/index';

import AuthenticationProvider from 'components/Providers/AuthenticationProvider';
import LocalizationProvider from 'components/Providers/LocalizationProvider';
import LandingPage from 'components/Login/LandingPage';
import CookiesNotSupportedError from 'containers/Errors/CookiesNotSupported';

import { thunk } from 'redux-thunk'

import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey('5133fb9fccd5df661758e14408d94e2eTz05OTM3NixFPTE3NTk2NTI3NjAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

import './helpers/polyfills';

// Import main stylesheet for app
import 'assets/css/main.scss';
import { isMicrosoftTeams } from 'helpers/teams';

function reduxMulti({ dispatch }) {
	return next => action =>
		Array.isArray(action)
			? action.filter(Boolean).map(dispatch)
			: next(action);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(
	applyMiddleware(
		promise,
		jsonMiddleware,
		reduxMulti,
		thunk,
	),
));

sniffer.addClasses(document.documentElement);

const rootElement = document.querySelector('#root');

function Index() {
	if (isMicrosoftTeams()) {
		microsoftTeams.app.initialize().then(() => {
			microsoftTeams.app.notifyAppLoaded();
		});
	}

	return (
		<Provider store={store}>
			<LocalizationProvider>
				<AuthenticationProvider loginComponent={LandingPage}>
					<Router>
						<Routes store={store} />
					</Router>
				</AuthenticationProvider>
			</LocalizationProvider>
		</Provider>
	);
}

if (isSupported()) {
	ReactDOM.render(<Index />, rootElement);
} else {
	ReactDOM.render(<CookiesNotSupportedError />, rootElement);
}

if (isMicrosoftTeams()) {
	document.querySelector('body').classList.add('is-frame');
}

export default store;
