import React, { Component } from 'react';
import { Spinner } from 'UI';
import { Button } from '@haldor/ui';

class WizardQuestion extends Component {

	render() {
		return (
			<div style={{ marginTop: '1.25rem' }}>
				<div style={{ marginBottom: '1.625rem' }}
					dangerouslySetInnerHTML={{ __html: this.props.text }}
				/>

				<div>
					{this.props.buttons.map((button, i) => {
						let props = { ...button };
						delete props.isLoading;
						delete props.value;

						return (
							<Button style={{ marginRight: '1.3rem' }} key={i} {...props}>
								{button.isLoading ?
									<Spinner size="small" />
									: null}

								{button.value}
							</Button>
						);
					})}
				</div>
			</div>
		);
	}

}

export default (WizardQuestion);
