import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';

class GroupSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
		};
	}

	onGroupSelect = (event, id) => {
		if (this.props.required) {
			if (event.target != null) {
				if (event.target.value === '' || event.target.value === '0') {
					this.setState({ error: this.props.translate('field-needs-input') });
					return;
				} else {
					this.setState({ error: '' });
				}
			}
		}

		if (event) {
			if (event.target != null) {
				if (this.props.onChange) {
					let group = this.props.groups.find((x) => x.id == event.target.value);
					if (group) {
				
						this.props.onChange(event.target.value, group);
					} else {
						this.props.onChange(event.target.value);
					}
				}
			}
		}

		if (id && id != '0') {
			if (this.props.onChange) {
				this.props.onChange(id);
			}
		}
	};

	groupOptions = (groups) => {
		return groups.map((group, i) => {
			if (
				group.classroomUrl == null ||
				(group.classroomUrl == '' && this.props.sectionId == null)
			) {
				return (
					<option key={`group-select-${i}`} disabled value={group.id}>
						{`${group.title} (${this.props.translate('Workspace missing')})`}
					</option>
				);
			}

			if (group.currentUserRole == 'OWNER') {
				if (this.props.sectionId == null || this.props.cloneView) {
					return (
						<option key={`group-select-${i}`} value={group.id}>
							{group.title}
						</option>
					);
				}

				if (this.props.sectionId == group.id) {
					return (
						<option key={`group-select-${i}`} value={group.id}>
							{group.title}
						</option>
					);
				}

				return (
					<option key={`group-select-${i}`} value={group.id}>
						{group.title}
					</option>
				);
			}
		});
	};

	componentDidMount = () => {
		const { sectionId } = this.props;

		if (sectionId != null && sectionId != 0) {
			var find = this.props.groups.find((t) => t.id == sectionId);

			if (find == null) {
				this.setState({ error: this.props.translate('field-needs-input') });
				this.props.onChange(0);
			}
		}
	};

	render() {
		const { groups, sectionId, disabled, required } = this.props;
		const { error } = this.state;

		return (
			<div>
				<label>
					{this.props.translate('section')}
					{required ? '*' : null}
				</label>
				<div className='select'>
					<select
						name='grup'
						id='groups-selector'
						value={sectionId ? sectionId : ''}
						onChange={this.onGroupSelect}
						disabled={disabled}
					>
						<option value='0'>{this.props.translate('select-an-alternative')}</option>
						{this.groupOptions(groups)}
					</select>

					{required && error !== '' ? (
						<div>
							<span style={{ marginTop: '1rem', color: 'red' }}>{error}</span>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		groups: state.sections.educationGroups,
	};
}

export default connect(mapStateToProps)(GroupSelector);
