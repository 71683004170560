import React, { useMemo } from "react";
import moment from 'moment';
import { useTranslate } from "lib/translate";
import { Expandable } from '@haldor/ui';

import './_blockPurpose.scss';

const BlockPurpose = (props) => {
	const translate = useTranslate();
	const purposeData = useMemo(() => {
		const value = props.assessmentBlocks;
		if (value == null || value.length == 0) {
			return [];
		}

		let data = [];
		let blocks = value.filter((b) => b.type === 'Haldor.Blocks.AssessmentBlock');

		blocks.forEach((items) => {
			let resources = items.resources.filter((s) =>
				s["@odata.type"] === 'Haldor.Blocks.AssessmentBlockPart' && s.referenceType === 'matrix'
			);

			if (resources) {
				resources.forEach((resource) => {
					let course = JSON.parse(JSON.stringify(resource));
					let inData = data.find((r) => r.value?.id == course.value?.id);
					if (inData == null) {
						let details = course?.value?.details.filter((x) =>
							x.endDate == null || moment(x.endDate).isAfter(moment())
						);

						course.value.details = details;
						data.push(course);
					}
				});
			}
		});

		return data;
	}, [props.assessmentBlocks])

	return (
		<div className="blockPurpose">
			{purposeData.length > 0 ?
				purposeData.map((items, index) => (
					<Expandable
						title={`${items.value?.title} ${items.value?.year ?? ''} (${items.value?.courseCode})`}
						color={items?.value?.colorCode ?? '#e4e4eb'}
						key={index}
						whiteBackground
					>
						{items.value.details.map((items, index) => (
							<div
								key={index}
								className="purposeContent"
								dangerouslySetInnerHTML={{ __html: items.purpose }}
							/>
						))}
					</Expandable>
				))
				: <p>{translate('No knowledge requirements selected')}</p>
			}
		</div>
	);
}

export default BlockPurpose;