import {
	LOG_ACTIVITY,
	GET_LOGS_PAGE_BY_SCHOOL,
} from 'actions/logs';

const initialState = {
	logs: [],
	startingTicks: '',
}

export default function Logs(state, action) {
	const { type, value } = action;

	if (typeof (state) === 'undefined') {
		return initialState;
	}

	switch (type) {
		case GET_LOGS_PAGE_BY_SCHOOL:
			return { ...state, logs: action.payload };

		case LOG_ACTIVITY:
			return state;

		default:
			return state;

			break;
	}
}
