export const getRootUrl = () => {
	if (window.location.pathname.indexOf('assignments-tab') > -1) {
		return `/assignments-tab/`;
	} else if (window.location.pathname.indexOf('planning-tab') > -1) {
		return `/planning-tab/`;
	} else if (window.location.pathname.indexOf('assessments-tab') > -1) {
		return `/assessments-tab/`;
	} else if (window.location.pathname.indexOf('attendance-tab') > -1) {
		return `/attendance-tab/`;
	} else if (window.location.pathname.indexOf('haldor-tab') > -1) {
		return `/haldor-tab/`;
	} else {
		return `/`;
	}
};

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';

	return decodeURIComponent(results[2].replace(/\+/g, ' '));
}