import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { Form } from "react-final-form";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from 'lib/translate';

import { deleteAssessment, updateAssessment } from 'actions/assessments';

import { Expandable } from 'UI';
import { Button, Checkbox, Icon } from '@haldor/ui';


import './ClearBlockAssessments.scss';

const ClearBlockAssessments = (props) => {
	const translate = useTranslate();
	const dispatch = useDispatch();
	const [selectedResources, setSelectedResources] = useState([]);
	const [feedback, setFeedback] = useState([]);
	const [notes, setNotes] = useState([]);
	const blocks = useSelector(state => state.Blocks.reference);
	const [totalAssessments, setTotalAssessments] = useState(0);

	const resources = useMemo(() => {
		if (blocks == null) {
			return [];
		}
		const assessmentBlocks = blocks.filter((block) =>
			block.type == "Haldor.Blocks.AssessmentBlock"
		);

		let blockParts = [];
		assessmentBlocks.forEach((block) => {
			if (block.resources == null) {
				return false;
			}
			let title = block.title;
			let assmentsObject = {};
			block.resources.forEach((resource) => {
				let inDetails = true;
				props.assessments.forEach((assessment) => {
					let inItems = props.items.find(item => item.assignedTo == assessment.studentId);
					if (inItems != null) {
						assessment.assessmentDetails.forEach((assessmentDetail) => {
							let inResource = resource.assessmentBlockPartRows.find((blockPartRow) =>
								blockPartRow.id == assessmentDetail.assessmentBlockPartRowId
							);
							if (inResource != null) {
								inDetails = true;
							}
						});
					}
				});

				if (inDetails) {
					assmentsObject = {
						id: block.id,
						title: title,
						items: [resource],
						sortOrder: block.sortorder,
					};
				}

				let findItem = blockParts.find(i => i.id === block.id);
				if (findItem && blockParts?.length > 0) {
					findItem?.items.push(resource);
					let item = blockParts.indexOf(x => x.id === block.id);
					if (item !== -1) {
						blockParts[item].items.push(resource);
					}
				} else {
					blockParts.push(assmentsObject);
				}
			});
		});

		let count = blockParts.length;
		//move asessmentsGoal type to first index
		blockParts.forEach((element) => {
			count += element.items.length
			element.items.sort((a, b) => {
				if (a.referenceType === 'assessmentGoal' && b.referenceType !== 'assessmentGoal') {
					return -1
				}
				if (a.referenceType !== 'assessmentGoal' && b.referenceType == 'assessmentGoal') {
					return 1;
				}
				return 0;
			})
		});

		setTotalAssessments(count);
		blockParts.sort((a, b) => a?.sortOrder - b?.sortOrder);
		return blockParts;
	}, [props.assessments, blocks]);

	//get child items and compare to resources to determine if all is selected
	const checkNumberOfItems = () => {
		let parentLength = 0;
		let findChilds = [];
		//get child items and compare to resources to determine if all is selected
		resources.forEach(element => {
			let findElement = element?.items.filter(x => {
				return selectedResources.find(s => {
					return s == x.id;
				})
			})
			parentLength += element?.items?.length;
			findChilds = [...findChilds, ...findElement];
		});
		return parentLength === findChilds.length;
	}

	const findAndRemoveItem = (idsToFind, newAssessment, isPrivate) => {
		let tempArr = [];
		resources.forEach(element => {
			element?.items?.forEach(assessment => {
				let existingFeedback = assessment.assessmentBlockPartRows.filter(x => {
					return idsToFind.find(t => {
						return x.assessmentBlockPartId == t;
					})
				}).map(x => x?.assessmentBlockPartId);
				if (existingFeedback.length > 0) {
					tempArr = [...tempArr, ...existingFeedback];
				}
			})
		});
		if (tempArr.length > 0) {
			let assessmentFeedbacksBlocks = [];
			let feedbackIds = tempArr.filter((item, index, self) =>
				self.findIndex((t) => t === item) === index);
			newAssessment.assessmentFeedbacks.forEach(element => {
				feedbackIds.find(x => {
					if (element.assessmentBlockPartId == x) {
						assessmentFeedbacksBlocks.push(element);
					}
				})
			})
			let removeData = [];
			if (isPrivate) {
				removeData = assessmentFeedbacksBlocks.filter((feedbacks) =>
					feedbacks.isPrivate
				);
			}
			else {
				removeData = assessmentFeedbacksBlocks.filter((feedbacks) =>
					!feedbacks.isPrivate
				);
			}
			newAssessment.assessmentFeedbacks = newAssessment.assessmentFeedbacks.filter((feedback) => {
				return !removeData.find(x => {
					return feedback.id == x.id
				})
			});
		}
	}

	const onSubmit = () => new Promise(async (resolve, reject) => {
		let promises = [];
		props.items.forEach((item) => {
			let assessment = props.assessments.find(assessment => {
				return assessment.studentId == item.assignedTo || assessment.id === item.assignedTo;
			});
			if (assessment == null) {
				return false;
			}
			let newAssessment = JSON.parse(JSON.stringify(assessment));

			let findObjects = resources.filter(x => {
				return selectedResources.find(s => {
					return s == x.id
				})
			})
			let isAllSelected = checkNumberOfItems()
			if (findObjects.length === resources.length || isAllSelected) {
				promises.push(dispatch(deleteAssessment(assessment.id)));
			} else {
				newAssessment.assessmentDetails = newAssessment.assessmentDetails.filter((assessmentDetail) => {
					const foundSelected = selectedResources.find((resourceId) => {
						let resource = null;
						blocks.forEach((block) => {
							let foundResource = block.resources.find((resource) =>
								resource.id == resourceId
							);
							if (foundResource != null)
								resource = foundResource;
						});
						if (resource == null) {
							return false;
						}

						return resource.assessmentBlockPartRows.find((blockPartRow) =>
							blockPartRow.id == assessmentDetail.assessmentBlockPartRowId
						)
					});

					return foundSelected == null;
				});
				//handle feedback  in an assessment
				if (feedback.length > 0) {
					findAndRemoveItem(feedback, newAssessment);
				}
				if (notes.length > 0) {
					findAndRemoveItem(notes, newAssessment, true);
				}
				newAssessment.assessmentFeedbacks = newAssessment.assessmentFeedbacks.filter((feedback) =>
					feedback.text != ''
				);
				promises.push(dispatch(updateAssessment(newAssessment, assessment)));
			}
		});

		Promise.all(promises).then(() => {
			props.onAbort(true);
			resolve(1);
		});
	});

	const handleNoteFeedback = (note, id) => {
		if (note) {
			if (notes.includes(id)) {
				setNotes(notes.filter(m => m != id));
			} else {
				setNotes([...notes, id]);
			}
			return;
		}

		if (feedback.includes(id)) {
			setFeedback(feedback.filter(m => m != id));
		} else {
			setFeedback([...feedback, id]);
		}
	}

	const selectNoteAndFeedback = (id, remove) => {
		if (remove) {
			setFeedback(feedback.filter(m => m != id));
			setNotes(notes.filter(m => m != id));
			return;
		}
		setFeedback([...feedback, id]);
		setNotes([...notes, id]);
	}

	const onSelectResource = (resource, parent, child = false, parentId) => {
		if (selectedResources.includes(resource.id)) {
			if (parent) {
				let test = resources.find(x => x.id === resource.id);
				let ids = [...test.items.map(row => row.id)];
				let removeFeedBack = feedback.filter(t => {
					return !ids.includes(t)
				})
				let removenote = notes.filter(t => {
					return !ids.includes(t)
				})
				setFeedback(removeFeedBack);
				setNotes(removenote);
				ids.push(resource.id);
				let removeItems = selectedResources.filter(t => {
					return !ids.includes(t)
				})

				setSelectedResources(removeItems);
				return;
			}
			// TODO should childs also be selected ?
			if (child) {
				selectNoteAndFeedback(resource.id, true);
				if (selectedResources.includes(parentId)) {
					setSelectedResources(selectedResources.filter(m => m != resource.id && m != parentId));
				} else {
					setSelectedResources(selectedResources.filter(m => m != resource.id));
				}
				return;
			}
			setSelectedResources(selectedResources.filter(m => m != resource.id));
		} else {
			if (parent) {
				let test = resources.find(x => x.id === resource.id);
				let id = test.id;
				let ids = test.items.map(row => row.id);
				if (ids.length > 0) {

					setFeedback([...feedback, ...ids]);
					setNotes([...notes, ...ids]);
				}
				let preSelected = [...selectedResources, id, ...ids]
				let findObjects = resources.filter(x => {
					return preSelected.find(s => {
						return s == x.id
					})
				})
				setSelectedResources([...selectedResources, id, ...ids]);
				return;
			}

			// TODO should childs also be selected ?
			if (child) {
				selectNoteAndFeedback(resource.id, false);
				let findParent = resources.find(x => x.id === parentId);
				if (findParent) {
					let arr = findParent?.items.filter(x => {
						return [...selectedResources, resource.id].find(s => {
							return s == x.id;
						})
					});
					if (arr.length === findParent?.items.length) {
						setSelectedResources([...selectedResources, resource.id, parentId]);
						return;
					}
				}
				setSelectedResources([...selectedResources, resource.id]);
				return;
			}
			setSelectedResources([...selectedResources, resource.id]);
		}
	}

	const toggleAll = (checked) => {
		if (checked) {
			setSelectedResources(resources.map(row => row.id));
			let id = resources.map(row => row.id);
			let data = [];
			let feed_notes = [];
			id.forEach(elementId => {
				let test = resources.find(x => x.id === elementId);
				let ids = [elementId, ...test?.items.map(row => row.id)]
				if (ids.length > 0) {
					ids.forEach(element => {
						feed_notes = [...feed_notes, element]
					});
				}
				data = [...data, ...ids];
			})
			setNotes(feed_notes);
			setFeedback(feed_notes)
			setSelectedResources(data);


		} else {
			setSelectedResources([]);
			setNotes([]);
			setFeedback([])
		}
	}

	return (
		<div className="form-container form">
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, form, valid, submitting, initialValues }) => {
					return <form onSubmit={handleSubmit} className="form form-component">
						{submitting ?
							<div className="is_sending">
								<p>
									<span className="loading-spinner" />
								</p>
							</div>
							: null}

						<Expandable title={translate('Students')}>
							<div>
								{props.items.map((item) => {
									return <div key={item.id} style={{ marginBottom: '.45rem' }}>
										<Checkbox
											disabled
											value={true}
											label={item.groupName}
										/>
									</div>
								})}
							</div>
						</Expandable>

						<div className="form-row">
							<label>{translate('Select the assessments to be deleted')}</label>

							<Checkbox
								label={translate('Select all')}
								onChange={toggleAll}
								value={totalAssessments == selectedResources.length}
							/>
						</div>

						<div style={{ paddingLeft: '2.5rem' }}>
							{resources != null && resources.length > 0 ?
								<div className="form-row" style={{ marginTop: '.45rem', marginBottom: '1rem' }}>
									{resources.map((resource) => {
										let title = resource.title;
										/* 	let title = translate('Goal'); */
										if (resource.referenceType == 'matrix') {
											title = `${resource.value.title} (${resource.value.courseCode})`;
										}
										let detail = null;
										if (detail != null) {
											if (detail.endDate != null && moment(detail.endDate).isBefore(moment())) {
												title = <span>
													{title}
													<span style={{ position: 'relative', top: 2, margin: '0 .25rem' }}>
														<Icon name="Alert_Red" />
													</span>
													{translate('Expired')}
												</span>
											}
										}

										return <div style={{ marginTop: '.75rem' }} key={resource.id}>
											<ul className='remove-assesments-list'>
												<li>
													<Checkbox
														label={title}
														value={selectedResources.includes(resource.id)}
														onChange={() => onSelectResource(resource, true, false, resource.id)}
													/>
												</li>

												<li>
													{resource.items?.length > 0 ?
														<ul>
															{resource.items.map((assessment, index) => (
																// Do not list clarifications that are returned from migrated assignments and plans
																assessment?.referenceType === 'matrix' || assessment?.referenceType === 'assessmentGoal' ?
																	<li key={index}>
																		<Checkbox
																			label={assessment?.referenceType === 'matrix' ? assessment?.value?.title : translate('Goal')}
																			value={selectedResources.includes(assessment.id)}
																			onChange={() => onSelectResource(assessment, false, true, resource.id)}
																		/>

																		<ul>
																			<li>
																				<Checkbox
																					label={translate('feedback')}
																					value={feedback.includes(assessment.id)}
																					onChange={() => handleNoteFeedback(false, assessment.id)}
																				/>
																			</li>
																			<li>
																				<Checkbox
																					label={translate('Personal note')}
																					value={notes.includes(assessment.id)}
																					onChange={() => handleNoteFeedback(true, assessment.id)}
																				/>
																			</li>
																		</ul>
																	</li>
																	: null
															))}
														</ul>
														: null}
												</li>
											</ul>
										</div>
									})}
								</div>
								: null}
						</div>

						<div className="form-divider" />

						<div className="form-row spacing submit">
							<Button disabled={submitting || !valid}>
								{translate("Remove assessments")}
							</Button>

							<div className="align-right">
								<Button
									type="secondary"
									onClick={e => {
										e.preventDefault();
										props.onAbort(false);
									}}
								>
									{translate("Cancel")}
								</Button>
							</div>
						</div>
					</form>
				}}
			/>
		</div>
	);
}

export default ClearBlockAssessments;