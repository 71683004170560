import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import api from 'lib/api';
import { debounce } from 'helpers/content';

import { getTenantSubjects, searchSubjects, createSubject } from 'actions/subjects';
import { addCourse, updateCourse, getCourseByCourseCode } from 'actions/courses';
import { getSectionByGraphId } from 'actions/sections';
import { getSchoolTypes } from 'actions/tenants'

import { ASSESSMENT_MATRIX } from 'constants/matrixTypes';
import { getRootUrl } from 'helpers/url';

import CustomCoreContent from 'containers/CoreContent/Custom';
import MatrixForm from 'containers/Matrix/Form/MatrixForm';

import Select from 'react-select/async';
import { Expandable, Spinner } from 'UI';
import { Editor } from 'UI/Inputs';
import { Icon, Button } from '@haldor/ui';

let lastValue = '';
const asyncValidate = async (courseCode, initialValues, meta) => {
	return new Promise((resolve) => {
		if (courseCode != null && courseCode != '' && lastValue != courseCode) {
			api.get(`courses/coursecode/${courseCode}`).then((payload) => {
				lastValue = courseCode;
				if (payload.data != null) {
					if (initialValues == null || initialValues.id != payload.data.id) {
						resolve('a-course-with-that-coursecode-already-exists');
						return true;
					}
				}

				resolve(undefined);
			});
		} else {
			// Dont change field validation if nothing has changed
			resolve(meta.error);
		}
	});
};

/**
 * @param {object} values
 * @param {function} translate
 * @returns {object} errors
 */
const validate = (values, translate) => {
	let errors = {};

	if (values.title == null || values.title == '') {
		errors.title = 'field-needs-input';
	}

	if (values.courseCode == null || values.courseCode == '') {
		errors.courseCode = 'field-needs-input';
	}

	let purposeText = '';
	if (typeof values.purpose == 'string') {
		purposeText = values.purpose.replace(/<(?:.|\n)*?>/gm, '');
	}

	if (purposeText == '' || purposeText == null) {
		errors.purpose = translate('field-needs-input');
	}

	return errors;
};

class CourseForm extends Component {
	constructor(props) {
		super(props);

		let coreContent = null;
		let matrix = null;
		let selectedSubject = null;
		let subjectTitle = null;
		let subjectId = null;
		if (props.initialValues != null && props.initialValues.details != null) {
			if (props.initialValues.details.length > 0) {
				coreContent = props.initialValues.details[0].coreContent;
			}
			if (props.initialValues.details[0]?.matrix != null) {
				matrix = props.initialValues.details[0].matrix;
			}

			selectedSubject = {
				value: props.initialValues.subjectId,
				label: props.initialValues.subjectTitle,
			};

			subjectTitle = props.initialValues.subjectTitle;
			subjectId = props.initialValues.subjectId;
		}

		this.state = {
			courseCode: '',
			matrix: matrix,
			coreContent: coreContent,
			selectedSubject: selectedSubject,
			subjectTitle: subjectTitle,
			subjectCode: '',
			subjectId: subjectId,
			subjectError: null,
			open: false,
		};

		this.onMatrixFormUpdate.bind(this);
	}

	submit = (values) => {
		return new Promise(async (resolve) => {
			const { coreContent } = this.state;
			const { currentUser, initialValues, translate } = this.props;

			values.typeOfSchooling = currentUser.schools.getActiveSchool(currentUser.userId).type;

			var detail = {};
			detail.purpose = values.purpose;
			detail.matrix = this.state.matrix;
			detail.coreContent = coreContent;

			if (values.details != null && values.details.length > 0) {
				detail.id = values.details[0].id;
			}

			if (detail.matrix != null && detail.matrix._ParagraphSet != null) {
				detail.matrix._ParagraphSet.forEach((paragraph) => {
					paragraph.rows.forEach((row) => {
						if (row.id != null && row.id.length != 36) {
							row.id = null;
						}
						row.cells = row.cells.filter((step) => {
							return step.gradeF == null;
						});
						row.cells.forEach((cell) => {
							if (cell.id != null && cell.id.length != 36) {
								cell.id = null;
							}
						});
					});
					if (paragraph.id != null && paragraph.id.length != 36) {
						paragraph.id = null;
					}
				});

				detail.matrix.paragraphSet = JSON.stringify(detail.matrix._ParagraphSet);

				var matrix = {
					Title: detail.matrix.title,
					DisableNotAchieved: detail.matrix.disableNotAchieved,
					Type: detail.matrix.type,
					ParagraphSet: detail.matrix.paragraphSet,
				};

				if (values.details != null && values.details.length > 0) {
					detail.matrixId = values.details[0].matrixID;
					matrix.id = values.details[0].matrixID;
				}

				if (detail.matrixId == null) {
					detail.matrixId = 0;
				}

				if (matrix.id == null) {
					matrix.id = 0;
				}

				detail.matrix = matrix;
			}

			values.details = [detail];

			let gotError = false;

			if (this.state.subjectId == 'new') {
				// Create new subject
				await this.props
					.createSubject({
						title: this.state.subjectTitle,
						subjectCode: this.state.subjectCode,
						typeOfSchooling: this.state.subjectSchoolType,
						shortSubjectCode: this.state.subjectCode,
					})
					.catch((error) => {
						gotError = true;
						this.setState({
							subjectError: translate(
								'A subject with that subject code already exists on your tenant'
							),
						});
					});

				if (this.props.createdSubject != null) {
					values.subjectId = this.props.createdSubject.id;
				}
			} else {
				values.subjectId = this.state.subjectId;
			}

			if (gotError) {
				resolve(0);
				return false;
			}

			if (this.props.editView == false) {
				this.props.addCourse(values, (response) => {

					// If a group Id is sent to this component, add the curriculum to the group
					if (this.props.group != null) {
						let group = this.props.group;
						group.courses.push({
							id: response.data
						})
						// Put is here since no action for this exists and I did not create one now either. Should be done!
						api.put('sections', group).then(() => {
							// Get data again to get fresh data in store
							if (this.props.group.graphId != null) {
								this.props.getSectionByGraphId(this.props.group.graphId, 'GRAPH_OBJECT;');
							}
							resolve(response.data);
						});
					}
					this.props.history.push(`${getRootUrl()}Course/${response.data}`);
					resolve(response.data);
				});
			} else {
				values.id = initialValues.id;
				this.props.updateCourse(values, (data) => {
					// Get data again to get fresh data in store
					if (this.props.group != null && this.props.group.graphId != null) {
						this.props.getSectionByGraphId(this.props.group.graphId, 'GRAPH_OBJECT;');
					}
					resolve(data);
					this.props.onClose(true, true);
				});
			}
		});
	};

	onSubjectSearch = (title, callback) => {
		if (title != '') {
			var searchValue = title.trim();
			searchValue = searchValue.replace(/[^A-Za-z0-9åäöÅÄÖ ]/g, '');
			searchValue = encodeURIComponent(searchValue);

			if (searchValue != null && searchValue != '') {
				if (searchValue.length < 3) {
					callback([]);
					return false;
				}

				this.props.searchSubjects(searchValue).then(() => {
					let options = [];
					if (this.props.subjectsSearchResults != null) {
						this.props.subjectsSearchResults.forEach((subject) => {
							options.push({
								label:
									subject.title +
									` - ${subject.subjectCode} (${this.props.translate(
										subject.typeOfSchooling
									)})`,
								value: subject.id,
							});
						});
					}

					callback(options);
				});

				return true;
			} else {
				callback([]);
			}
		}

		callback([]);
	};

	onSubjectChange = (option) => {
		if (option.value == 'new') {
			this.setState({
				subjectId: option.value,
				selectedSubject: option,
				subjectTitle: '',
				subjectCode: '',
				subjectSchoolType: 'PRESCHOOL',
				open: true,
			});
		} else {
			let subjects = [];
			if (this.props.subjectsSearchResults != null) {
				subjects.push(...this.props.subjectsSearchResults);
			}

			if (this.props.tenantSubjects != null) {
				subjects.push(...this.props.tenantSubjects);
			}

			let subject = subjects.find((subject) => subject.id == option.value);
			if (subject != null) {
				this.setState({
					subjectId: option.value,
					subjectTitle:
						subject.title +
						` - ${subject.subjectCode} (${this.props.translate(subject.typeOfSchooling)})`,
					subjectCode: subject.subjectCode,
					subjectSchoolType: subject.typeOfSchooling,
					selectedSubject: option,
				});
			}
		}
	};

	onMatrixFormUpdate = (form) => {
		let matrix = Object.assign({}, form);
		this.setState({ matrix });
	};

	onCoreContentChange = (content) => {
		this.setState({ coreContent: content });
	};

	componentDidMount = () => {
		if (this.props.initialValues !== null) {
			if (this.props.initialValues.details[0].matrix != null) {
				var matrix = JSON.parse(JSON.stringify(this.props.initialValues.details[0].matrix));
				this.setState({ matrix });
			}
		}
		this.props.getSchoolTypes();
		this.props.getTenantSubjects(this.props.tenant.tenantId);
	};

	renderCoreContent = () => {
		const { coreContent } = this.state;

		return (
			<Expandable title={this.props.translate('core-contents')}>
				<CustomCoreContent coreContents={coreContent} onChange={this.onCoreContentChange} />
			</Expandable>
		);
	};

	renderInput = ({
		input,
		label,
		type,
		placeholder,
		maxlength,
		meta: { touched, error, warning, validating, pristine },
	}) => {
		return (
			<div style={{ position: 'relative' }}>
				<input
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? { border: '1px solid red' } : {}}
					maxLength={maxlength}
					{...input}
				/>

				{pristine && validating ? (
					<div className='async-validating'>
						<Spinner center small />
					</div>
				) : null}

				{touched &&
					((error && (
						<span style={{ marginTop: '1rem', color: 'red' }}>
							{this.props.translate(error)}
						</span>
					)) ||
						(warning && (
							<span style={{ marginTop: '1rem', color: 'red' }}>
								{this.props.translate(warning)}
							</span>
						)))}
			</div>
		);
	};

	render() {
		const { initialValues, translate } = this.props;
		let purpose = '';

		if (initialValues != null && initialValues.details.length > 0) {
			purpose = initialValues != null ? initialValues.details[0].purpose : '';
		}

		let subjectValid = this.state.selectedSubject != null;
		if (this.state.subjectId == 'new') {
			if (this.state.subjectCode == '' || this.state.subjectTitle == '') {
				subjectValid = false;
			}
		}

		let options = [];
		if (initialValues == null) {
			options.push({
				label: this.props.translate('+ Create subject'),
				value: 'new',
			});
		}

		if (this.props.tenantSubjects != null) {
			this.props.tenantSubjects.forEach((subject) => {
				options.push({
					label:
						subject.title +
						` - ${subject.subjectCode} (${this.props.translate(subject.typeOfSchooling)})`,
					value: subject.id,
				});
			});
		}

		return (
			<div className='form-container course-form'>
				<Form
					initialValues={initialValues}
					initialValuesEqual={() => true}
					onSubmit={this.submit}
					validate={(values) => validate(values, this.props.translate)}
					render={({ handleSubmit, submitting, valid }) => {
						return (
							<form onSubmit={handleSubmit} className='form form-component'>
								{submitting ? (
									<div className='is_sending'>
										<p>
											<span className='loading-spinner' />
										</p>
									</div>
								) : null}

								<div className='form-row'>
									<label>{this.props.translate('title')}*</label>

									<Field
										name='title'
										type='text'
										component={this.renderInput}
										placeholder={this.props.translate('title')}
									/>
								</div>

								<div className='form-row'>
									<label>ID*</label>
									<Field
										name='courseCode'
										type='text'
										component={this.renderInput}
										placeholder={this.props.translate('course-code')}
										validate={(value, allValues, meta) => asyncValidate(value, initialValues, meta)}
										maxLength={50}
									/>
								</div>

								<div className='form-row'>
									<label>{this.props.translate('Subject*')}</label>

									<Select
										isSearchable
										isMulti={false}
										name='subject'
										value={this.state.selectedSubject}
										onChange={(value) => this.onSubjectChange(value)}
										className='simple-select'
										defaultOptions={options}
										loadOptions={debounce(this.onSubjectSearch, 500, false)}
										placeholder={this.props.translate('Search for a subject')}
										ignoreCase
										loadingPlaceholder={this.props.translate('Loading...')}
									/>
								</div>

								{initialValues == null ? (
									<div
										className='form-row'
										style={{ padding: 0, margin: 0, marginTop: '1rem' }}
									>
										<Expandable
											open={this.state.open}
											onChange={(open) => this.setState({ open })}
											title={this.props.translate('Subject description')}
										>
											<label style={{ marginTop: 0 }}>
												{this.props.translate('Subject title*')}
											</label>

											<input
												type='text'
												onChange={(e) =>
													this.setState({ subjectTitle: e.target.value })
												}
												value={this.state.subjectTitle || ''}
												disabled={this.state.subjectId != 'new'}
											/>

											<label>{this.props.translate('Area')}*</label>

											<div className='select'>
												<select
													name='subjectSchoolType'
													value={this.state.subjectSchoolType}
													onChange={(e) =>
														this.setState({
															subjectSchoolType: e.target.value,
														})
													}
													disabled={this.state.subjectId != 'new'}
												>
													{this.props.schoolTypes.sort((a, b) =>
														translate(a.typeId).localeCompare(translate(b.typeId))
													).map((type, index) => {
														return (
															<option key={index} value={type.typeId}>
																{this.props.translate(type.typeId)}
															</option>
														);
													})}
												</select>
											</div>

											<label>{this.props.translate('Subject code*')}</label>
											<input
												type='text'
												onChange={(e) =>
													this.setState({ subjectCode: e.target.value })
												}
												value={this.state.subjectCode}
												disabled={this.state.subjectId != 'new'}
												maxLength={50}
											/>
										</Expandable>

										{this.state.subjectError != null ? (
											<div style={{ marginTop: '.35rem', color: 'red' }}>
												<span
													style={{
														marginRight: '.35rem',
														position: 'relative',
														top: 2,
													}}
												>
													<Icon name='Alert' />
												</span>
												{this.state.subjectError}
											</div>
										) : null}
									</div>
								) : null}

								<div className='form-row'>
									<label>{this.props.translate('description')}</label>

									<Field
										name='description'
										component={Editor}
										placeholder={this.props.translate('description')}
										translate={this.props.translate}
									/>
								</div>

								<div className='form-row'>
									<label>{this.props.translate('purpose')}*</label>

									<Field
										name='purpose'
										component={Editor}
										placeholder={this.props.translate('purpose')}
										value={purpose}
									/>
								</div>

								<div className='clearfix' />

								<div style={{ marginTop: '4em' }}>
									{this.renderCoreContent()}
								</div>

								<div>
									<Expandable title={this.props.translate('matrix')}>
										<MatrixForm
											matrix={this.state.matrix}
											type={ASSESSMENT_MATRIX}
											onMatrixFormUpdate={this.onMatrixFormUpdate}
											editView={this.props.editView}
										/>
									</Expandable>
								</div>

								<div className='clearfix' />
								<div className='form-divider' />

								<div className='form-row spacing submit' style={{ marginTop: '30px' }}>
									<Button disabled={submitting || !valid || !subjectValid}>
										{this.props.editView
											? this.props.translate('save')
											: this.props.translate('publish')}
									</Button>

									<div className='align-right'>
										<Button
											onClick={(event) => {
												event.preventDefault();
												this.props.onClose(false, false);
											}}
											disabled={submitting}
											type='secondary'
										>
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						);
					}}
				/>
			</div>
		);
	}
}

function mapStateToProps(state) {
	let initialValues = null;
	if (state.Courses.course != null) {
		initialValues = { ...state.Courses.course };
		initialValues.purpose = state.Courses.course.details[0].purpose;
	}

	return {
		initialValues,
		courseByCourseCode: state.Courses.courseByCourseCode,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		tenant: state.user.tenant,
		subjectsSearchResults: state.Subjects.search,
		tenantSubjects: state.Subjects.tenant,
		createdSubject: state.Subjects.created,
		group: state.sections.graphSection,
		schoolTypes: state.Tenant.schoolTypes,
	};
}

export default withRouter(
	connect(mapStateToProps, {
		getSectionByGraphId,
		addCourse,
		updateCourse,
		getCourseByCourseCode,
		getTenantSubjects,
		searchSubjects,
		createSubject,
		getSchoolTypes,
	})(CourseForm)
);
