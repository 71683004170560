import React, { Component } from 'react';
import { translate, Button } from '@haldor/ui';
import { connect } from 'react-redux';
import { Spinner } from 'UI';
import '../_onboarding.scss';
import moment from 'moment';
import getEnvironment from 'lib/env';

class WaitingForResponse extends Component {
	constructor(props) {
		super(props);

		this.state = {
			recentSentMailDate: null
		};
	}

	componentDidMount() {
		if (this.props.adminInvitations != null) {
			var date = moment.utc(this.props.adminInvitations.latestSentDate);
			var stringDate = date.local().format('YYYY-MM-DD LTS');

			this.setState({ recentSentMailDate: stringDate });
		}
	}

	goBack = () => {
		return new Promise(resolve => {
			if (this.props.onSubmit) {
				this.props.onSubmit(0)
					.then(() => {
						resolve(1);
					});
			}
		})
	}

	render() {
		let product = getEnvironment().id;
		var productName = 'Haldor Education';
		if (product === 'haldor_planning') {
			productName = "Haldor Planning";
		}

		return (
			<div>
				<div>
					<h2 style={{ textAlign: 'left' }}>{this.props.translate('Great to see you!')}</h2>
					<p style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>{this.props.translate('We are waiting for an Office 365 administrator in your organisation to approve {0}. You will not be able to use the services until that is done. An e-mail requesting approval was sent to your administrator on').replace('{0}', productName)} {this.state.recentSentMailDate}. </p>

					<p style={{ textAlign: 'left', marginTop: '1rem', marginBottom: '1rem' }}>{this.props.translate('Would you like to send a new e-mail to remind your administrator?')}</p>
					<div style={{ width: '20rem', marginBottom: '2rem' }}>
						<img src="/dist/svg/illustration_vantar_pa_godkannande.svg" />
					</div>
				</div>

				<div>
					<Button type="primary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('showEmailInfo') }}>
						{this.props.translate('Open e-mail suggestion')} </Button>
					<Button type="secondary" style={{ marginRight: '0.5rem' }} onClick={() => { this.props.showPage('navigateToAdminConsent') }}>
						{this.props.translate('I am an Office 365-admin')} </Button>
					<Button type="secondary" onClick={this.props.goBack}>
						{this.props.translate('Back')} </Button>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		adminInvitations: state.Services.adminInvitations,
	};
}

export default connect(mapStateToProps, {
})(WaitingForResponse);
