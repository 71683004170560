import React, { Component } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";

import msal, { loginRequest, clearInteractionProgress } from 'lib/msal';
import { appInsights, getEnhancedLogLevel } from 'lib/appInsights';

import { Spinner } from 'UI';

export default class Auth extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showSession: false,
		};
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	runLogin = () => {
		let request = {
			redirectUri: window.location.origin + '/Auth',
			...loginRequest
		}

		if (this.getParameterByName('extraQueryParameters') != null) {
			request.extraQueryParameters = decodeURIComponent(this.getParameterByName('extraQueryParameters'));
		}

		if (this.getParameterByName('claims') != null) {
			request.claims = decodeURIComponent(this.getParameterByName('claims'));
		}

		if (this.getParameterByName('login_hint') != null) {
			request.loginHint = decodeURIComponent(this.getParameterByName('login_hint'));
		}

		clearInteractionProgress();
		msal.loginRedirect(request);
	}

	componentDidMount = () => {
		setTimeout(() => {
			this.setState({ showSession: true });
		}, 1000 * 5);

		if (window.location.hash.indexOf('code') > -1) {
			msal.handleRedirectPromise().then((response) => {
				if (response != null) {
					microsoftTeams.authentication.notifySuccess(response);
				} else {
					microsoftTeams.authentication.notifyFailure('');
				}
			}).catch((reason) => {
				microsoftTeams.authentication.notifyFailure(reason);
			});

			return true;
		}

		this.runLogin();
	}

	render() {
		return (
			<div style={{ textAlign: 'center' }}>
				<Spinner center />

				{this.state.showSession ?
					<span style={{ color: 'var(--color--meta)' }}>
						Session ID: {localStorage.getItem('hsid')}
					</span>
					: null}
			</div>
		);
	}
}
