import { getSection } from 'actions/sections';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Editor } from 'UI/Inputs';
import { Spinner } from 'UI';
import { Button, Checkbox } from '@haldor/ui';

class UpdateMeeting extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sectionId: 0,
			date: Moment(),
			error: null,
			selectedUsers: [],
			studentLed: false,
		};
	}

	componentDidMount = () => {
		if (this.props.initialValues != null && this.props.creating) {
			let foundStudentLed = this.props.initialValues.instances.findIndex(instance => {
				return instance.studentLed == true;
			})

			this.setState({ studentLed: foundStudentLed > -1 });
		}
	}

	submit = (values) => {
		return new Promise(resolve => {

			if (this.props.creating) {
				values.instances.forEach(instance => {
					instance.studentLed = this.state.studentLed;
					return instance;
				});
			} else {
				values.instances = [];
			}

			values.status = this.props.initialValues.status;

			if (this.props.onSubmit) {
				this.props.onSubmit(values)
					.then(() => {
						resolve(1);
					});
			}
		});
	}

	onUpdate = (selectedUsers) => {
		this.setState({ selectedUsers: selectedUsers });
	}

	renderInput = ({ input, label, type, placeholder, meta: { touched, error, warning } }) => {
		return (
			<div>
				<input
					{...input}
					placeholder={placeholder || label}
					type={type}
					style={touched && error ? {
						border: '1px solid red'
					} : {}}
				/>

				{touched && ((error &&
					<span style={{ margintop: '1rem', color: 'red', }}>{error}</span>) || (warning &&
						<span style={{ margintop: '1rem', color: 'red', }}>{warning}</span>))
				}
			</div>
		);
	}

	required = (value) => {
		if (typeof (value) != 'undefined' && value != '' && value != null) {
			if (value.length > 199) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-200-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;

		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	}

	render() {
		const { handleSubmit, submitting, valid } = this.props;

		if (submitting) {
			return <Spinner center />
		}

		return (
			<div className="form-container">
				<form onSubmit={handleSubmit(this.submit)} className="form form-component">
					<div className="form-row">
						<label>{this.props.translate('title')}*</label>
						<Field
							name="title"
							type="text"
							component={this.renderInput}
							placeholder={this.props.translate('title')}
							validate={this.required}
						/>
					</div>

					<div className="form-row input">
						<label>{this.props.translate('Information-to-participants')}</label>

						<Field
							component={Editor}
							name="description"
							placeholder={this.props.translate('describe-meeting')}
							style={{ margin: 0 }}
							translate={this.props.translate}
						/>
					</div>

					{this.props.services.teamsMeeting ?
						<div className="form-row">
							<label>{this.props.translate('create-teamsmeeting')}</label>

							<Field
								component={Checkbox}
								name="onlineMeeting"
								label={this.props.translate('create-teamsmeeting-for-this-meeting')}
							/>
						</div>
						: null}

					{this.props.creating ?
						<div className="form-row">
							<label>{this.props.translate('studentled')}</label>

							<Checkbox
								value={this.state.studentLed}
								onChange={value => this.setState({ studentLed: value })}
								label={this.props.translate('studentled-meeting')}
							/>
						</div>
						: null}

					<div className="form-divider" />

					<div className="form-row spacing submit">
						<Button disabled={submitting || !valid}>
							{this.props.translate('save')}
						</Button>
					</div>
				</form>
			</div>
		);
	}

}

function mapStateToProps(state) {
	let meeting = state.Meetings.meeting;
	if (meeting == null && state.Meetings.wizardMeeting != null) {
		meeting = state.Meetings.wizardMeeting;
	}

	return {
		translate: translate(state.Languages.translations),
		initialValues: meeting,
		services: state.Services.availableServices,
		currentUser: state.user.currentUser,
	}
}

export default connect(mapStateToProps, {
	getSection,
})(reduxForm({
	form: 'UpdateMeeting',
	destroyOnUnmount: true,
})(UpdateMeeting));
