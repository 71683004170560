import React from 'react';

import './Skeleton.scss';

const Skeleton = (props) => {
	return(
		<div className="skeleton" {...props}></div>
	);
}

export default Skeleton;
