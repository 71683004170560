import SectionTitle from 'components/Presentation/SectionTitle';
import SectionProgress from 'containers/Progress/Section';
import TeacherSectionProgress from 'containers/Progress/TeacherSection';
import React, { Component } from 'react';
import { Spinner } from 'UI';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Icon } from '@haldor/ui';
import User from '_class/User';

class Assessments extends Component {

	render() {
		const user = new User(this.props.currentUser);

		if (this.props.group == null) {
			return (
				<div className="section teams-assessment">
					<SectionTitle>
						<Icon name="People" />
						{this.props.translate('assessments-header-overview')}
					</SectionTitle>

					<Spinner center />
				</div>
			)
		}

		return (
			<div className="section teams-assessment">
				<SectionTitle>
					<Icon name="People" />
					{this.props.translate('assessments-header-overview')}
				</SectionTitle>

				{user.isStudent() ?
					<SectionProgress
						groupId={this.props.groupId}
						userId={this.props.currentUser.id}
					/>
					:
					<TeacherSectionProgress
						groupId={this.props.groupId}
					/>
				}
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		group: state.sections.activeSection,
	};
}

export default connect(mapStateToProps)(Assessments);
