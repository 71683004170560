import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import DateTime from '_class/DateTime';
import User from '_class/User';
import moment from 'moment';

import {
	getSMSList,
	createSMS,
	getRemainingSMS,
	getSMSEducationSections,
	getSMSMentorSections,
} from 'actions/sms';
import { setPageTitle, setPageActions } from 'actions/header';
import { getSchoolServices } from 'actions/schools';

import Modal from 'containers/Modals/Modal';
import SMSForm from 'containers/Forms/SMS';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';

import { Spinner } from 'UI';
import { Card, Grid, Radio, Icon, translate } from '@haldor/ui';

class SMSOverview extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loadingServices: true,
			form: false,
			groupFilter: 0,
			statusFilter: 'SMS_PUBLISHED',
			startDate: moment().subtract('3', 'months').format('YYYY-MM-DD'),
			endDate: moment().format('YYYY-MM-DD'),
		}
	}

	componentDidMount = () => {
		this.props.setPageTitle('Sms');
		const user = new User(this.props.user);

		let actions = [];
		actions.push({
			type: 'button',
			value: this.props.translate('Write sms'),
			onClick: this.onFormToggle,
			icon: 'plus',
		});

		this.props.setPageActions(actions);
		this.props.getRemainingSMS();

		const school = user.getActiveSchool();
		this.props.getSchoolServices(school.id).then(() => {
			const foundSMS = this.props.services.find(service => {
				return service.serviceId == 'HALDOR_SMS' && moment(service.serviceEnd).isSameOrAfter(moment(), 'day');
			});

			if (foundSMS != null) {
				this.setState({ loadingServices: false });
			} else {
				this.props.history.replace('/');
			}
		});

		this.props.getSMSEducationSections();
		this.props.getSMSMentorSections();

		this.fetchData();
	}

	fetchData = () => {
		const { statusFilter, groupFilter, startDate, endDate } = this.state;
		let group = null;
		if (groupFilter != 0) {
			group = groupFilter;
		}

		var utcStart = moment(moment(startDate).startOf('day'), 'YYYY-MM-DD  HH:mm:ss').utc().format();
		var utcEnd = moment(moment(endDate).endOf('day'), 'YYYY-MM-DD HH:mm:ss').utc().format();

		this.props.getSMSList(group, statusFilter, utcStart, utcEnd);
	}

	filterOnGroup = async (event) => {
		const groupId = parseInt(event.target.value, 10);

		if (groupId < 1) {
			await this.setState({ groupFilter: 0 });
		} else {
			await this.setState({ groupFilter: groupId });
		}

		this.fetchData();
	}

	clearGroupFilter = async (event) => {
		event.preventDefault();

		await this.setState({ groupFilter: 0 });
		this.fetchData();
	}

	onFilterDateChange = async (dates) => {
		const { startDate, endDate } = this.state;

		var start = moment(dates.start);
		var end = moment(dates.end);

		if (start.format('YYYY-MM-DD') == startDate && end.format('YYYY-MM-DD') == endDate) {
			return false;
		}

		await this.setState({ startDate: start.format('YYYY-MM-DD'), endDate: end.format('YYYY-MM-DD') });
		this.fetchData();
	}

	onStatusFilterChange = async (filter) => {
		if (filter == this.state.statusFilter) {
			return false;
		}

		await this.setState({ statusFilter: filter });
		this.fetchData();
	}

	onFormToggle = () => {
		this.setState({ form: !this.state.form });
	}

	onSubmit = (values) => {
		return new Promise((resolve, reject) => {
			this.props.createSMS(values).then(async () => {
				resolve(1);

				if (values.status != this.state.statusFilter) {
					await this.setState({ form: false, statusFilter: values.status });
					this.fetchData();
				} else {
					this.setState({ form: false });
				}

			}).catch(() => {
				reject(this.props.translate('Something went wrong, try again'));
			});
		})
	}

	render() {
		const user = new User(this.props.user);

		if (!user.isAdministrator() && !user.isSchoolLeader() && !user.isSMSAdmin()) {
			return <Redirect to="/" />
		}

		let data = null;
		if (this.props.list != null) {
			data = [...this.props.list].sort((a, b) => b.id - a.id)
		}

		if (this.state.loadingServices) {
			return <div className="preschooldocuments">
				<Spinner center />
			</div>
		}

		let sections = [];
		if (this.props.educationSections != null) {
			sections = sections.concat(this.props.educationSections);
		}

		if (this.props.mentorSections != null) {
			sections = sections.concat(this.props.mentorSections);
		}

		return (
			<div className="preschooldocuments">
				<Modal isOpen={this.state.form} onClose={this.onFormToggle} title="SMS">
					<SMSForm onSubmit={this.onSubmit} onCancel={this.onFormToggle} />
				</Modal>

				<div className="section-header form" style={{ marginBottom: '2rem' }}>
					<div className="option-container filter-container">
						<div className="form-row select">
							<label>{this.props.translate('Group')}</label>
							<select value={this.state.groupFilter} onChange={this.filterOnGroup}>
								<option value="0">{this.props.translate('select-an-alternative')}</option>

								{sections.map(group => {
									return <option value={group.id} key={group.id}>
										{group.title}
									</option>;
								})}
							</select>
						</div>

						{this.state.groupFilter > 0 ?
							<div className="clear-filter-container" onClick={this.clearGroupFilter}>
								<Icon name="Close" /> {this.props.translate('clear-filter')}
							</div>
							: null}
					</div>

					<div className="filter-container">
						<div className="form-row">
							<DatePickerFromTo
								values={{
									start: this.state.startDate,
									end: this.state.endDate,
								}}
								onChange={this.onFilterDateChange}
							/>
						</div>
					</div>

					<div className="filter-container">
						<div className="form-row">
							<label>{this.props.translate('Status')}</label>

							<div>
								<Radio
									input={{
										onChange: this.onStatusFilterChange,
										value: this.state.statusFilter,
									}}
									optionValue="SMS_PUBLISHED"
									label={this.props.translate('Published')}
								/>
							</div>

							<div style={{ marginTop: '.35rem' }}>
								<Radio
									input={{
										onChange: this.onStatusFilterChange,
										value: this.state.statusFilter,
									}}
									optionValue="SMS_DRAFT"
									label={this.props.translate('Draft')}
								/>
							</div>
						</div>
					</div>
				</div>

				<Grid>
					{data != null ?
						data.map(sms => {
							return <Card element={Link} to={`/sms/${sms.id}`} key={sms.id} className="fc ais">
								<div className="df jcb aic x size-14" style={{ marginBottom: '1rem' }}>
									<div>
										{sms.createdUser.firstName} {sms.createdUser.lastName}
									</div>

									<div className="size-12">
										{sms.status == 'SMS_DRAFT' ?
											<div className="card-meta" style={{ marginRight: '.45rem' }}>
												{this.props.translate('Draft')}
											</div>
											: null}

										{new DateTime(sms.created).getTimeStampHours()}
									</div>
								</div>

								{sms.title != null ?
									<div style={{ marginBottom: '0.45rem', fontSize: '1rem', fontWeight: 500 }}>
										{sms.title}
									</div>
									: null}

								<div className="size-14">
									{sms.message}
								</div>
							</Card>
						})
						: null}
				</Grid>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		user: state.user.currentUser,
		translate: translate(state.Languages.translations),
		list: state.SMS.list,
		services: state.School.schoolServices,
		educationSections: state.SMS.educationSections,
		mentorSections: state.SMS.mentorSections,
	}
}

export default connect(mapStateToProps, {
	setPageTitle,
	setPageActions,
	getSMSList,
	createSMS,
	getRemainingSMS,
	getSchoolServices,
	getSMSMentorSections,
	getSMSEducationSections,
})(SMSOverview);