export default function ({ dispatch }) {
    return next => action => {
        if (action.payload != null) {
            if (typeof action.payload == 'string') {
                try {
                    const newAction = { ...action, payload: JSON.parse(action.payload) };
                    dispatch(newAction);    
                } catch (error) {
                    next(action);
                }                
            }
            else {
                next(action);
            }
        } else {
            return next(action);
        }
    }
};