import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { TooltipMenu, Icon, Flex, Checkbox, Button } from '@haldor/ui';
import { Person } from 'UI';

import './MeetingUsers.scss';
import User from '_class/User';
import PermissionManager from '_class/PermissionManager';

class MeetingUsers extends Component {

	constructor(props) {
		super(props);

		this.state = {
			permissionTypes: new PermissionManager().getPermissionTypes(),
			callingUser: new User(this.props.user)
		}
	}

	selectParticipant = (user) => {
		this.props.onParticipantSelect(user);
	}

	markForConversation = (participant) => {
		if (this.props.onConversation != null) {
			this.props.onConversation(participant);
		}
	}

	getUserRoles = (roles) => {
		if (roles != null && roles.length > 0) {
			return roles.map((role, index) => {
				if (role.roleId.toLowerCase() == 'school_administrator') {
					return null;
				}

				if (role.roleId.toLowerCase() == 'standard_curriculum_admin') {
					return null;
				}

				if (role.roleId.toLowerCase() == 'course_administrator') {
					return null;
				}

				if (role.roleId.toLowerCase() == 'conversation_admin') {
					return null;
				}

				if (role.roleId.toLowerCase() == 'sysadmin') {
					return null;
				}

				if (role.roleId.toLowerCase() == 'special_pedagogue') {
					return null;
				}

				return <span key={role.roleId + index} style={{ marginLeft: '.55rem' }} className="size-12 color--meta">
					{this.props.translate(role.roleId.toLowerCase())}
				</span>
			})
		}

		return null;
	}

	renderUsers() {
		const { users, meeting, meetingInstance, user } = this.props;
		const { callingUser } = this.state;

		var havingReadPermission = callingUser.havingPermission(this.state.permissionTypes.MEETINGS_READ_SCHOOL);

		return users.map((participant) => {
			let user = participant.user;

			if (user == null)
				return null;

			let conversation = null;
			if (this.props.conversations != null) {
				conversation = this.props.conversations.find(conv => {
					return conv.memberships.find(member => {
						return member.membershipID == user.id;
					});
				});
			}

			let actions = [];

			if ((this.props.services.conversations &&
				this.props.onConversation != null &&
				this.props.user.id != user.userId &&
				!((this.props.isStudent && user.userType == 'GUARDIAN') || havingReadPermission))
			) {
				actions.push({
					label: (
						<span>
							{this.props.translate('manage-conversation')}
							{conversation != null && conversation.unreadMessages > 0 ?
								' (' + conversation.unreadMessages + ')'
								: null}
						</span>
					),
					onClick: () => this.markForConversation(user),
				});
			}

			if (this.props.onParticipantRemove != null && participant.userID != this.props.user.userId && meeting.creator === this.props.user.userId && !this.props.isStudent && meeting.status != 'COMPLETED') {
				actions.push({
					label: this.props.translate('Remove'),
					onClick: () => this.props.onParticipantRemove(participant),
				});
			}

			let roles = [];
			if (user.userType != 'GUARDIAN' && user.roles != null) {
				roles = user.roles;
				roles = roles.filter((thing, index, self) =>
					index === self.findIndex((t) => (
						t.roleId.toLowerCase() === thing.roleId.toLowerCase()
					))
				)
			}

			let name = (
				<span>
					{user.firstName} {user.lastName != null ? user.lastName : null}

					{this.getUserRoles(roles)}

					{user.userType == 'GUARDIAN' ?
						<span style={{ marginLeft: '.55rem' }} className="size-12 color--meta">
							{this.props.translate('Guardian')}
						</span>
						: null}
				</span>
			);

			return (
				<div style={{ margin: '0.55rem 0' }} key={'user-' + user.id}>
					<Flex center>
						<div className="size-14 weight--bold" style={{ flex: 1 }}>
							{!this.props.isStudent && meetingInstance.status != "COMPLETED" && meeting.status != 'COMPLETED' ?
								<Checkbox
									value={participant.present}
									onChange={() => { this.selectParticipant(participant) }}
									label={name}
								/>
								: !this.props.isStudent && (meeting.status == 'COMPLETED' || meetingInstance.status == 'COMPLETED') ?
									participant.present ? 
									<Person person={user} />
									: null
								: <Person person={user} />}
						</div>

						{!this.props.isStudent && (meeting.status == 'COMPLETED' || meetingInstance.status == 'COMPLETED') && !participant.present ?
							null 
							: actions.length > 1 ?
							<TooltipMenu trigger={(
								<div className="list-user-actions">
									{conversation != null && conversation.unreadMessages > 0 ?
										<span className="new-badge">
											{conversation.unreadMessages}
										</span>
										: null}

									<Icon name='Bullets' />
								</div>
							)}>
								{actions.map((action, index) => {
									return <TooltipMenu.Item key={index} onClick={action.onClick}>
										{action.label}
									</TooltipMenu.Item>
								})}
							</TooltipMenu>
							:
							<div>
								{actions.map((action, index) => {
									return <Link to="#" style={{ cursor: 'pointer' }} onClick={action.onClick}>
										<div style={{ width: '1.25rem', position: 'relative' }}>
											{conversation != null && conversation.unreadMessages > 0 ?
												<div>
													<div className="badge">
														<span>{conversation.unreadMessages}</span>
													</div>
													<Icon name='Message' />
												</div>
												: <Icon name='Message' bw />}
										</div>
									</Link>
								})}
							</div>
						}
					</Flex>
				</div>
			)
		});
	}

	render() {
		return (
			<div className="meeting-users">
				{this.renderUsers()}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		conversations: state.Conversation.referenceConversations,
		translate: translate(state.Languages.translations),
		user: state.user.currentUser,
		meeting: state.Meetings.meeting,
	};
}

export default connect(mapStateToProps)(MeetingUsers);
