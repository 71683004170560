import React from 'react';
import DOMPurify from 'dompurify';
import { Button, Checkbox, Icon, Expandable, Haldor__Block, addBlockTranslations } from '@haldor/ui';
import NDLAFrame from './NDLAFrame';

import './_NdlaBlock.scss';

class NDLABlock extends Haldor__Block {

	constructor(props) {
		super(props);

		this.name = 'Haldor.Blocks.NDLA';
		this.order = 48;
		this.icon = () => (
			<svg xmlns="http://www.w2.org/2000/svg" viewBox="0 0 50 50"
				dangerouslySetInnerHTML={{
					__html: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
						<style type="text/css">
							.st0{fill:#C8C9C8;}
						</style>
						<g>
							<path class="st0" d="M29.2,15.1c0,0.1,0,0.1,0,0.2c0,5.1,0,10.2,0,15.3v0.2H16.7V19.6H27v-4.5H29.2z M27,22h-8.2v6.5H27L27,22z"/>
							<path class="st0" d="M47.9,30.9c-0.1,0-0.1,0-0.1,0H35.5v-6.7h10.4V22H35.5v-2.4h10.4V22h2L47.9,30.9z M45.8,28.5v-2.6h-8.3v2.6
								H45.8z"/>
							<path class="st0" d="M2.1,19.6c0.1,0,0.1,0,0.1,0h12.3v11.2h-2.1V22H4.3v8.9H2.1V19.6z"/>
							<path class="st0" d="M33.3,15.1c0,0.1,0,0.1,0,0.2c0,5.1,0,10.2,0,15.3v0.2h-2.1v-0.2c0-5.1,0-10.2,0-15.3c0-0.1,0-0.1,0-0.2H33.3z"/>
						</g>
					</svg>`
				}}
			/>
		);

		addBlockTranslations('sv-se', [
			{
				output: 'NDLA',
				id: 'Haldor.Blocks.NDLA',
			}
		]);

		addBlockTranslations('en-us', [
			{
				output: 'NDLA',
				id: 'Haldor.Blocks.NDLA',
			}
		]);

		addBlockTranslations('nb-no', [
			{
				output: 'NDLA',
				id: 'Haldor.Blocks.NDLA',
			}
		]);

		addBlockTranslations('da-dk', [
			{
				output: 'NDLA',
				id: 'Haldor.Blocks.NDLA',
			}
		]);

		addBlockTranslations('uk', [
			{
				output: 'NDLA',
				id: 'Haldor.Blocks.NDLA',
			}
		]);

		this.registerResourceType('Haldor.Blocks.NDLA.article', {
			articleType: 'string',
			id: 'number',
			introduction: 'string',
			metaImageAlt: 'string',
			metaImageUrl: 'string',
			revision: 'number',
			revisionDate: 'string',
			title: 'string',
			updated: 'string',
			url: 'string',
		});

		this.registerResourceType('Haldor.Blocks.NDLA.displayType', {
			value: 'string',
		});

		this.registerResourceType('Haldor.Blocks.NDLA.ShowModal', {
			visible: 'boolean',
		});
	}

	toggleModal = (event) => {
		if (event != null && event.preventDefault != null) {
			event.stopPropagation();
			event.preventDefault();
		}

		const showModal = this.getResources().find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.NDLA.ShowModal'
		);

		if (showModal != null) {
			this.removeResource(showModal);
			return true;
		}

		this.addResource('Haldor.Blocks.NDLA.ShowModal', {
			visible: true,
		});
	}

	toggleDisplayType = () => {
		const containsIframeResource = this.getResources().find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.NDLA.displayType' && resource.value == 'iframe'
		);

		if (containsIframeResource != null) {
			this.removeResource(containsIframeResource);
		} else {
			this.addResource('Haldor.Blocks.NDLA.displayType', {
				value: 'iframe',
			});
		}
	};

	onResourceSubmit = (values) => {
		this.addResource('Haldor.Blocks.NDLA.article', values);
	};

	renderResourceCard = (resource, link = false) => {
		if (resource['@odata.type'] != 'Haldor.Blocks.NDLA.article') {
			return null;
		}

		const Elem = (props) => {
			return link ? <a {...props} /> : <div {...props} />;
		};

		return (
			<div className="ndla-resource-container" key={resource.id}>
				<Elem className="ndla-resource" href={resource.url} target="_blank">
					<div className="resource-content">
						<div
							className="image"
							style={{ backgroundImage: `url(${resource.metaImageUrl}?width=325)` }}
							title={resource.metaImageAlt}
						/>

						<div className="content">
							<div className="title df aic">
								<span
									dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.title) }}
									style={{ marginRight: '.35rem' }}
								/>

								<Icon name="External" />
							</div>

							<div className="description"
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(resource.introduction)
								}}
							/>
						</div>
					</div>

					<div className="resource-footer">
						<div className="url" />

						<span className="delete-resource" onClick={() => this.removeResource(resource)}>
							<Icon name="Bin" />
						</span>
					</div>
				</Elem>
			</div>
		);
	};

	edit = (props) => {
		const translate = this.api.getEditor().getProp('translate');
		const showModal = props.resources.find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.NDLA.ShowModal'
		);

		const containsIframeResource = props.resources.find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.NDLA.displayType' && resource.value == 'iframe'
		);

		return (
			<div className="block--ndla-block">
				{showModal != null ?
					<NDLAFrame
						toggleModal={this.toggleModal}
						onSubmit={this.onResourceSubmit}
					/>
					: null}

				<div className="ndla-resource-list">
					{props.resources.map((resource) => {
						return this.renderResourceCard(resource);
					})}
				</div>

				<div className="df aic jcb">
					<Button onClick={this.toggleModal}>
						<Icon name="Plus" />
						{translate('Add resource')}
					</Button>

					<Checkbox
						label={translate('Embed content')}
						value={containsIframeResource != null}
						onChange={this.toggleDisplayType}
					/>
				</div>
			</div>
		);
	};

	consume = (props) => {
		const containsIframeResource = props.resources.find((resource) =>
			resource['@odata.type'] == 'Haldor.Blocks.NDLA.displayType' && resource.value == 'iframe'
		);

		return (
			<div className="block--ndla-block">
				<div className="ndla-resource-list">
					{props.resources.map((resource) => {
						if (resource['@odata.type'] != 'Haldor.Blocks.NDLA.article') {
							return null;
						}

						if (containsIframeResource != null) {
							return (
								<Expandable title={resource.title}>
									<iframe key={resource.id} src={resource.url} height={1000} />
								</Expandable>
							);
						}

						return this.renderResourceCard(resource, true);
					})}
				</div>
			</div>
		);
	}

}

export default NDLABlock;