import api from 'lib/api';
import { get_operations } from 'lib/patch';
import { ASSESSMENT_TYPES } from '../components/BlockAssessments/AssessmentTypes';

export const GET_BLOCK_TYPES = 'BLOCKS_GET_BLOCK_TYPES';
export const CLEAR_BLOCK_TYPES = 'BLOCK_CLEAR_BLOCK_TYPES';
export const SAVE_BLOCKS_ON_REFERENCE = 'BLOCKS_SAVE_BLOCKS_ON_REFERENCE';
export const CREATE_ASSESSMENT_BLOCK = 'BLOCKS_CREATE_ASSESSMENT_BLOCK';
export const UPDATE_ASSESSMENT_BLOCK = 'BLOCKS_UPDATE_ASSESSMENT_BLOCK';
export const UPDATE_BLOCKS_ON_REFERENCE = 'BLOCKS_UPDATE_BLOCKS_ON_REFERENCE';
export const GET_BLOCKS_BY_REFERENCE = 'BLOCKS_GET_BLOCKS_BY_REFERENCE';
export const CLEAR_BLOCKS_ON_REFERENCE = 'BLOCKS_CLEAR_BLOCKS_ON_REFERENCE';
export const DELETE_BLOCK = 'BLOCKS_DELETE_BLOCK';

export const getBlockTypes = () => ({
	type: GET_BLOCK_TYPES,
	payload: new Promise((resolve, reject) => {
		return api.get(`blocks/types`)
			.then((response) => {
				if (response.status > 300) {
					reject(null);
					return true;
				}

				resolve(response.data);
			})
	})
});

export const clearBlockTypes = () => ({
	type: CLEAR_BLOCK_TYPES,
	payload: true,
});

export const saveBlocksOnReference = (blocks, referenceId, referenceType) => ({
	type: SAVE_BLOCKS_ON_REFERENCE,
	payload: new Promise((resolve, reject) => {
		const values = JSON.parse(JSON.stringify(blocks)).map(block => {
			block.referenceId = referenceId;
			block.referenceType = referenceType;
			return block;
		});

		api.post(`blocks`, values)
			.then((response) => {
				if (response.status > 300) {
					reject(null);
					return true;
				}

				resolve(response.data);
			})
	}),
});

export const updateBlocksOnReference = (blocks, referenceId, referenceType) => ({
	type: UPDATE_BLOCKS_ON_REFERENCE,
	payload: new Promise((resolve, reject) => {
		const values = JSON.parse(JSON.stringify(blocks)).map(block => {
			block.referenceId = referenceId;
			block.referenceType = referenceType;
			return block;
		});

		api.put(`blocks`, values)
			.then((response) => {
				if (response.status > 300) {
					reject(null);
					return true;
				}

				resolve(response.data);
			})
	}),
});

export const clearBlocksOnReference = () => ({
	type: CLEAR_BLOCKS_ON_REFERENCE,
	payload: true,
})

export const getBlocksByReference = (referenceId, referenceType) => ({
	type: GET_BLOCKS_BY_REFERENCE,
	payload: new Promise((resolve, reject) => {
		api.get(`blocks?referenceId=${referenceId}&referenceType=${referenceType}`)
			.then((response) => {
				if (response.status > 300) {
					reject(null);
					return true;
				}

				resolve(response.data);
			})
	})
});

export const deleteBlock = (blockId) => ({
	type: DELETE_BLOCK,
	payload: new Promise((resolve, reject) => {
		api.delete(`blocks/${blockId}`)
			.then((response) => {
				if (response.status > 300) {
					reject(response.data);
					return true;
				}

				resolve(response.data);
			})
	})
})

const createAssessmentPart = (resource, courses) => new Promise(async (resolve) => {
	let partValue = resource;
	if (resource.referenceType == 'assessmentGoal') {
		const goal = await api.post('assessmentGoals', resource.value);

		partValue = {
			referenceType: resource.referenceType,
			referenceId: goal.data.id,
			permissions: partValue.permissions,
			originId: resource.originId,
			assessmentBlockPartRows: goal.data.rows.map((row) => ({
				referenceType: 'assessmentGoalRow',
				referenceId: row.id,
			})),
			assessmentBlockPartRelationships: courses.map((courseId) => ({
				courseId: courseId,
			})),
			assessmentType: resource.assessmentType
		};
	}

	if (resource.referenceType == 'matrix') {
		// Delete stored course object from resource before sending to API
		delete partValue.value;
	}

	const part = await api.post('assessmentParts', partValue);
	resolve(part.data);
});

const updateAssessmentPart = (resource, oldResource, courses) => new Promise(async (resolve) => {
	if (resource.id == null || oldResource == null) {
		const part = createAssessmentPart(resource, courses);
		resolve(part);
		return true;
	}

	if (resource.referenceType == 'assessmentGoal') {
		let goal = resource.value;

		const oldGoal = oldResource.value;

		let operations = get_operations(oldGoal, goal);

		if (operations.length > 0) {
			await api.patch(`assessmentGoals/${resource.referenceId}`, oldResource, operations);
		}
	}

	delete resource.value;
	delete oldResource.value;

	resource.assessmentBlockPartRelationships = courses.map((courseId) => {
		let foundRelationship = oldResource.assessmentBlockPartRelationships.find((rel) => rel.courseId == courseId);
		if (foundRelationship != null) {
			return foundRelationship;
		}

		return {
			courseId: courseId,
		}
	});

	let operations = get_operations(oldResource, resource);

	if (operations != null && operations.length > 0) {
		/** We dont need to add any assessmentblockrelationships for matrices. For assessmentGoals the relationships is added above. */
		var filteredOperations = operations.filter(item => {
			return !(item.op == "add" && item.path == "/assessmentBlockPartRelationships")
		});

		if (filteredOperations.length > 0) {
			await api.patch(`assessmentParts/${resource.id}`, oldResource, filteredOperations)
		}
	}

	resolve(resource);
});

export const createAssessmentBlock = (block, referenceId, referenceType) => ({
	type: CREATE_ASSESSMENT_BLOCK,
	payload: new Promise(async (resolve) => {
		let values = JSON.parse(JSON.stringify(block));
		let resources = [];
		let courses = [];

		values.resources.forEach((resource) => {
			if (resource.referenceType == 'matrix') {
				courses.push(resource.value.id);
			}
		});

		let typeResource = values.resources.filter(resource => {
			return resource['@odata.type'] == 'assessmenttype';
		})[0]?.value;

		values.resources = values.resources.filter(resource => {
			return resource['@odata.type'] != 'assessmenttype';
		});

		values.resources.forEach(resource => {
			resource.assessmentType = typeResource == null ? ASSESSMENT_TYPES.TEACHER : typeResource;
		});

		values.assessmentType = typeResource == null ? ASSESSMENT_TYPES.TEACHER : typeResource;

		await Promise.all(
			values.resources.map((resource) => new Promise(async (resolve) => {
				if (resource['@odata.type'] == 'Haldor.Blocks.AssessmentBlockPart') {
					const part = await createAssessmentPart(resource, courses);
					resources.push({
						'@odata.type': 'Haldor.Blocks.AssessmentBlockPart',
						id: part.id,
						assessmentType: resource.assessmentType
					});

					resolve(resource);
					return true;
				}

				resources.push(resource);
				resolve(resource);
			}))
		);

		values.type = 'Haldor.Blocks.AssessmentBlock';
		values.resources = resources;
		values.referenceId = referenceId;
		values.referenceType = referenceType;

		api.post('blocks', [values]).then((response) => {
			resolve(response.data);
		});
	}),
});

export const updateAssessmentBlock = (block, oldBlock) => ({
	type: UPDATE_ASSESSMENT_BLOCK,
	payload: new Promise(async (resolve) => {
		let values = JSON.parse(JSON.stringify(block));
		let resources = [];
		let courses = [];

		values.resources.forEach((resource) => {
			if (resource.referenceType == 'matrix') {
				courses.push(resource.value.id);
			}
		});
		if (courses.length > 0) {
			await Promise.all(courses.map((course) => new Promise(async (resolve) => {
				var assessmentGoalResource = block.resources.filter(resource => { return resource.referenceType == 'assessmentGoal' && resource.id });
				if (assessmentGoalResource) {
					await Promise.all(assessmentGoalResource.map((resource) => new Promise(async (resolve) => {
						if (!resource.assessmentBlockPartRelationships.includes(course)) {
							var operations = [{
								op: 'add',
								path: '/assessmentBlockPartRelationships',
								value: {
									assessmentBlockPartId: resource.id,
									courseId: course
								},
							}];
							await api.patch(`assessmentParts/${resource.id}`, resource, operations);
							resolve(resource);
							return true;
						}
						resolve(resource);
					})));
				}
				resolve(course);
			})));
		}

		var deletedResources = oldBlock.resources.filter(item => { return !values.resources.find(currentItem => currentItem.id == item.id) });
		if (deletedResources != null && deletedResources.length > 0) {
			await Promise.all(deletedResources.map((resource) => new Promise(async (resolve) => {
				if (resource['@odata.type'] == 'Haldor.Blocks.AssessmentBlockPart') {
					delete resource.index;

					var operations = [
						{
							op: 'remove',
							path: '/',
							value: resource,
						}
					];

					resource.assessmentBlockPartRelationships.map(relationship => {
						operations.push({
							op: 'remove',
							path: '/assessmentBlockPartRelationships',
							value: relationship,
						})
					});

					await api.patch(`assessmentParts/${resource.id}`, resource, operations);

					resolve(resource);
					return true;
				}
				resolve(resource);
			})));
		}

		await Promise.all(
			values.resources.map((resource) => new Promise(async (resolve) => {
				if (resource['@odata.type'] == 'Haldor.Blocks.AssessmentBlockPart') {
					delete resource.index;

					const oldResource = oldBlock.resources.find((res) => res.id == resource.id);
					const part = await updateAssessmentPart(resource, oldResource, courses);

					resources.push({
						'@odata.type': 'Haldor.Blocks.AssessmentBlockPart',
						id: part.id,
						assessmentType: resource.assessmentType ?? ASSESSMENT_TYPES.TEACHER
					});

					resolve(resource);
					return true;
				}

				resources.push(resource);
				resolve(resource);
			}))
		);

		resources = resources.filter((resource) => resource.id != null);
		values.resources = resources;
		values.referenceId = oldBlock.referenceId;
		values.referenceType = oldBlock.referenceType;

		api.put('blocks', [values]).then((response) => {
			resolve(response.data);
		});
	}),
})