import {
	GET_PLANS,
	ACTIVE_PLAN,
	GET_PLANS_BY_SECTIONID,
	SET_ACTIVE_PLAN,
	CLEAR_PLANS_FROM_STORE,
	PLAN_UPDATE,
	PLAN_DELETE_PLAN,
	PLAN_DELETE_ASSIGNMENT_FROM_PLAN,
	SEARCH_PLANS,
	CLEAR_SECTION_PLANS,
	CREATE_PLAN,
	CREATE_BULK_PLANS,
	GET_PLAN_SECTION,
	CLEAR_PLAN_SECTION,
} from 'actions/plans';

import { ACTIVE_PLAN_KNOWLEDGECOLLECTIONS } from 'actions/knowledge';

import { updateObjectInArray } from 'helpers/array';

const INITIAL_STATE = {
	plans: null,
	active_plan: {},
	active_knowledgeCollection: [],
	sectionPlans: [],
	search_results: null,
	createdPlanId: null,
	createdPlans: null,
	section: null,
	version: '1',
};

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
		case ACTIVE_PLAN:
			let updateList = updateObjectInArray(state.plans, action.payload);
			return { ...state, active_plan: action.payload, plans: updateList };

		case ACTIVE_PLAN_KNOWLEDGECOLLECTIONS:
			return { ...state, active_knowledgeCollection: action.payload };

		case CREATE_PLAN:
			return { ...state, createdPlanId: action.payload };

		case GET_PLANS:
			return { ...state, plans: action.payload };

		case SEARCH_PLANS:
			return { ...state, search_results: action.payload };

		case GET_PLANS_BY_SECTIONID:
			return { ...state, sectionPlans: action.payload };

		case SET_ACTIVE_PLAN:
			if (action.id != null) {
				let selected = state.plans.find((plan) => { return plan.id == action.id });

				if (typeof (selected) != 'undefined') {
					return { ...state, active_plan: selected };
				} else {
					return { ...state, active_plan: INITIAL_STATE.active_plan };
				}
			}

			return { ...state, active_plan: INITIAL_STATE.active_plan };

		case PLAN_UPDATE:
			return { ...state, active_plan: action.payload };

		case CLEAR_PLANS_FROM_STORE:
			return { ...state, plans: INITIAL_STATE.plans, active_plan: INITIAL_STATE.active_plan };

		case CLEAR_SECTION_PLANS:
			return { ...state, sectionPlans: INITIAL_STATE.sectionPlans };

		case PLAN_DELETE_PLAN:
			if (action.payload != null) {
				let currentPlans = state.plans;
				if (currentPlans != null && currentPlans.length > 0) {
					// Try to find index of the now deleted plan
					let foundIndex = currentPlans.findIndex(function (plan) {
						return plan.id == action.payload;
					});

					if (foundIndex > -1) {
						// Remove the index from list
						currentPlans.splice(foundIndex, 1);
						return { ...state, plans: currentPlans };
					}
				} else {
					return state;
				}
			}

			return state;

		case PLAN_DELETE_ASSIGNMENT_FROM_PLAN:
			return state;

		case CREATE_BULK_PLANS:
			if (action.payload != null) {
				var currentPlans = state.plans;

				if (currentPlans == null)
					currentPlans = [];

				return { ...state, plans: [...currentPlans, ...action.payload], createdPlans: action.payload };
			}

			return state;

		case GET_PLAN_SECTION:
			return { ...state, section: action.payload };

		case CLEAR_PLAN_SECTION:
			return { ...state, section: null };

		default:
			return state;
	}
}
