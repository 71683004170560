import moment from 'moment';

class ServiceManager {
	constructor(tenantServices, schoolServices) {
		this.tenantServices = tenantServices;
		this.schoolServices = schoolServices;
	}

	getServices = () => {
		return {
			haldorEducation: this.haveService('HALDOR_EDUCATION'),
			haldorCoursePlanning: this.haveService('HALDOR_COURSEPLANNING'),
			microsoft: this.haveService('MS_ASSIGNMENTS'),
			haldor: this.haveService('HALDOR_ASSIGNMENTS'),
			news: this.haveService('HALDOR_NEWS'),
			schedule: this.haveService('HALDOR_SCHEDULE'),
			advancedSchedule: this.haveService('HALDOR_ADVANCED_CALENDAR'),
			plans: this.haveService('HALDOR_PLANS'),
			conversations: this.haveService('HALDOR_CONVERSATIONS'),
			attendance: this.haveService('HALDOR_ATTENDANCE'),
			forms: this.haveService('HALDOR_FORMS'),
			meetings: this.haveService('HALDOR_MEETINGS'),
			verdicts: this.haveService('HALDOR_VERDICTS'),
			additionalAdjustments: this.haveService('HALDOR_ADDITIONAL_ADJUSTMENTS'),
			teamsMeeting: this.haveService('HALDOR_TEAMSMEETINGS'),
			notes: this.haveService('HALDOR_NOTES'),
			exportAssessments: this.haveService('HALDOR_EXPORTASSESSMENTS'),
			plagiarism: this.haveService('HALDOR_EDUCATION_PLAGIARISM'),
			immersiveReaderPlans: this.haveService('IMMERSIVE_READER_PLANS'),
			immersiveReaderAssignments: this.haveService('IMMERSIVE_READER_ASSIGNMENTS'),
			immersiveReaderPosts: this.haveService('IMMERSIVE_READER_POSTS'),
			immersiveReaderConversations: this.haveService('IMMERSIVE_READER_CONVERSATIONS')
		};
	};

	haveService = (serviceId) => {
		return (this.tenantServices != null && this.tenantServices.find((service) => {
			return (
				service.serviceId == serviceId &&
				moment(service.serviceEnd).isSameOrAfter(moment(), 'day')
			);
		}) != null) ||
			(this.schoolServices != null && this.schoolServices.find(service => {
				return service.serviceId == serviceId &&
					moment(service.serviceEnd).isSameOrAfter(moment(), 'day');
			}) != null);
	};
}

export default ServiceManager;