import React, { Component } from 'react';
import swal from 'sweetalert2';
import { connect } from 'react-redux';

import {
	getSection,
	getSectionGuardians,
	setActiveSection,
	toggleSelectedSection,
	clearSelectedSections,
} from 'actions/sections';

import { Expandable, Spinner } from 'UI';
import { Checkbox, Icon, translate } from '@haldor/ui';

import './MultipleSectionSelector.scss';
import DisplayName from 'components/Presentation/DisplayName';

class MultipleSectionSelector extends Component {

	constructor(props) {
		super(props);

		var sections = JSON.parse(JSON.stringify(props.sections));
		if (props.mentorGroups) {
			sections = JSON.parse(JSON.stringify(props.mentorSections));
		}

		if (sections != null) {
			sections = sections.sort(function (a, b) {
				return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
			});

			sections = sections.filter(section => {
				return section.userRole == "OWNER"
			})

			sections.forEach(section => {
				if (this.props.value != null) {
					if (section.id == this.props.value) {
						section.checked = true;
						return section;
					}
				}
			});
		}

		this.state = {
			sections: sections
		};
	}

	componentWillUnmount = () => {
		this.props.setActiveSection();
		this.props.clearSelectedSections();
		this.setState({ sections: [] });
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.selectGuardians != this.props.selectGuardians) {
			this.onUpdateResult();
		}
	}

	componentDidMount = () => {
		if (this.state.sections != null) {
			this.state.sections.forEach(section => {
				if (this.props.value != null) {
					if (section.id == this.props.value) {
						section.checked = false;
						this.onSectionChange(section);
					}
				}
			});
		}
	}

	promptEmpty = () => {
		const { translate } = this.props;
		if (this.props.type == 'assignment') {
			swal.fire({
				title: this.props.translate('The group contains no students'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('Ok'),
				html: `
				${translate('The group you selected does not contain any students. You can`t create assignments for a group without students. The group may lack students due to the fact that:')}
				<br />
				<ul>
					<li>${translate('No students have been added to the group. Make sure your team/Microsoft 365 group includes students.')}</li>
					<li>${translate('Students have not been activated on the team. To do this, use Microsoft Teams.')}</li>
					<li>${translate('The group could not be loaded correctly. Close the form and reload the page, then try again.')}</li>
				</ul>
				<br />
				`,
			});
		}

		if (this.props.type == 'plan') {
			swal.fire({
				title: this.props.translate('The group contains no students'),
				showCancelButton: false,
				confirmButtonText: this.props.translate('Ok'),
				html: `
				${translate('create-planning-selected-group-no-students')}
				<br />
				<ul>
					<li>${translate('No students have been added to the group. Make sure your team/Microsoft 365 group includes students.')}</li>
					<li>${translate('Students have not been activated on the team. To do this, use Microsoft Teams.')}</li>
					<li>${translate('The group could not be loaded correctly. Close the form and reload the page, then try again.')}</li>
				</ul>
				<br />
				`,
			});
		}
	}

	onSectionChange = (section, checked) => {
		if (!section.checked) {
			section.checked = true;
		} else {
			section.checked = checked;
		}

		if (section.students == null || section.students.length == 0) {
			this.loadSectionStudents(section, section.checked);
		}

		if (section.students != null) {
			if (section.checked && section.students.length == 0) {
				if (this.props.type == 'assignment') {
					section.checked = false;
					this.setState({});
					return false;
				}
			}

			this.markSectionStudents(section, checked);
		}

		this.props.toggleSelectedSection(section);
	}

	onSectionOpenChange = (section) => {
		section.open = !section.open;
		this.setState({});

		if (section.students == null || section.students.length == 0) {
			this.loadSectionStudents(section);
		}
	}

	markSectionStudents = (section, checked) => {
		if (this.props.selectGuardians) {
			section.students.forEach((student) => {
				student.guardians.forEach(guardian => {
					guardian.checked = checked;
					student.checked = section.checked;
				});
			});
		} else {
			section.students.forEach((student) => {
				student.checked = checked;
			});
		}

		this.setState({}, () => {
			this.onUpdateResult();
		});
	}

	loadSectionStudents = (section, prompt = false) => {
		let sections = this.state.sections;
		let stateSection = sections.find(t => t.id == section.id);
		stateSection.loading = true;

		this.setState({ sections }, () => {
			if (this.props.selectGuardians) {
				this.props.getSectionGuardians(section.id)
					.then(() => {
						stateSection.students = JSON.parse(JSON.stringify(this.props.guardians));

						stateSection.students.forEach((student) => {
							student.guardians.forEach(guardian => {
								guardian.checked = section.checked;
							});

							student.checked = section.checked;
						});

						stateSection.loading = false;
						this.setState({ sections }, () => {
							this.onUpdateResult();
						});
					});
			} else {
				this.props.getSection(section.id)
					.then(() => {
						if (this.props.activeSection != null) {
							stateSection.students = JSON.parse(JSON.stringify(this.props.activeSection.students));
							stateSection.courses = JSON.parse(JSON.stringify(this.props.activeSection.courses));

							if (stateSection.students != null) {
								if (stateSection.students.length == 0 && prompt) {
									this.promptEmpty();

									if (this.props.type == 'assignment') {
										stateSection.checked = false;
									}
								}

								stateSection.students.forEach((student) => {
									student.checked = section.checked;
								});
							}

							stateSection.loading = false;
							this.setState({ sections }, () => {
								this.onUpdateResult();
							});
						}
					});
			}
		});
	}

	onGuardiansUpdate = (section) => {
		const checkedGuardians = section.students.find((student) => {
			return student.guardians.find(guardian => {
				return guardian.checked == true;
			});
		});

		if (checkedGuardians != null) {
			section.checked = true;
		} else {
			section.checked = false;
		}

		this.setState({}, () => {
			this.onUpdateResult();
		});
	}

	onStudentsUpdate = (section) => {
		const checkedStudents = section.students.find(function (student) {
			return student.checked == true;
		});

		if (checkedStudents != null) {
			if (!section.checked) {
				this.props.toggleSelectedSection(section);
			}

			section.checked = true;
		} else {
			// No students are no longer checked
			if (section.checked) {
				this.props.toggleSelectedSection(section);
			}

			section.checked = false;
		}

		this.setState({}, () => {
			this.onUpdateResult();
		});
	}

	onStudentChange = (student, section) => {
		student.checked = student.checked ? false : true;

		this.setState({}, () => {
			this.onStudentsUpdate(section);
		});
	}

	onGuardianChange = (guardian, section) => {
		guardian.checked = guardian.checked ? false : true;

		this.setState({}, () => {
			this.onGuardiansUpdate(section);
		});
	}

	onUpdateResult = () => {
		let filtered = this.state.sections.filter(t => t.checked || t.active);
		let sections = [];

		filtered.forEach((section) => {
			let sectionObject = {
				id: section.id,
				courses: section.courses,
				type: section.type,
				students: section.students != null ? section.students.filter(t => t.checked) : [],
				allStudentsSelected: section.students != null && section.students.length > 0 ? section.students.every(student => student.checked) : false,
				graphId: section.graphId,
				title: section.title,
			}

			if (this.props.selectGuardians) {
				sectionObject.guardians = [];
				section.students.forEach(student => {
					if (student.guardians != null) {
						student.guardians.forEach(guardian => {
							if (guardian.checked) {
								sectionObject.guardians.push(guardian);
							}
						});
					}
				});
			}

			sections.push(sectionObject);
		});
		this.props.onChange(sections);
	}

	onSectionOpen = (section, toggle) => {
		if (section.open != null) {
			section.open = !section.open;
		} else {
			section.open = true;
		}

		if (section.students == null || section.students.length == 0) {
			this.loadSectionStudents(section);
		}

		toggle();
	}

	renderStudent = (student, section) => {
		const displayName =
			<DisplayName
				firstName={student.firstName}
				lastName={student.lastName}
				email={student.email}
				data={section.students}
			/>
		if (this.props.selectGuardians) {
			return <div style={{ marginTop: '0.75rem' }} key={student.id}>
				{student.firstName} {student.lastName}
				<div style={{ marginTop: '0.45rem' }}>
					{student.guardians.map(guardian => {
						return <Checkbox
							key={student.id + '-' + guardian.id}
							onChange={() => this.onGuardianChange(guardian, section)}
							value={guardian.checked}
							label={`${guardian.firstName} ${guardian.lastName != null ? guardian.lastName : ''}`}
						/>
					})}
				</div>
			</div>
		}

		return <div key={student.id}>
			<Checkbox
				onChange={() => this.onStudentChange(student, section)}
				value={student.checked}
				label={displayName}
			/>
		</div>
	}

	renderSection = (section) => {
		const title = (
			<div className="df aic jcb">
				<div style={{ flex: 1 }}>
					<Checkbox
						value={section.checked}
						onChange={(checked) => this.onSectionChange(section, checked)}
						label={section.title}
						disabled={this.props.disabled || section.classroomUrl == null || section.classroomUrl == ''}
					/>
				</div>

				{section.students != null && section.students.length == 0 ?
					<span style={{ fontWeight: 400, fontSize: '1rem', marginRight: '1rem' }}>
						<span style={{ position: 'relative', top: 2, marginRight: '.3rem' }}>
							<Icon name="Alert_Red" />
						</span>
						{this.props.translate('The group contains no students')}
					</span>
					: null}

				{section.classroomUrl == null || section.classroomUrl == '' ?
					<span style={{ fontWeight: 400, fontSize: '1rem', marginRight: '1rem' }}>
						<span style={{ position: 'relative', top: 2, marginRight: '.3rem' }}>
							<Icon name="Alert_Red" />
						</span>
						{this.props.translate('Workspace missing')}
					</span>
					: null}
			</div>
		);

		if (section.open == null) {
			section.open = false;
		}

		return (
			<Expandable
				open={section.open}
				title={title}
				key={section.id}
				onPlus={toggle => { this.onSectionOpenChange(section) }}
				onClick={toggle => { return false; }}
			>
				{section.error ?
					<div className="section-error">
						{section.error}
					</div>
					: null}

				{section.loading ?
					<Spinner small center />
					: null}

				{section.students ?
					section.students
						.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
						.map(student => this.renderStudent(student, section))
					: null}
			</Expandable>
		);
	}

	render() {
		const { disabled } = this.props;

		if (this.props.sections != null) {
			return (
				<div className={disabled ? "multi-user-select disabled" : "multi-user-select"}>
					{this.state.sections.map(section => {
						return this.renderSection(section)
					})}
				</div>
			);
		}

		return (
			<div className="user-select">
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		sections: state.sections.educationGroups,
		mentorSections: state.sections.mentorGroups,
		activeSection: state.sections.activeSection,
		guardians: state.sections.guardians,
	};
}

export default connect(mapStateToProps, {
	getSection,
	getSectionGuardians,
	setActiveSection,
	toggleSelectedSection,
	clearSelectedSections,
})(MultipleSectionSelector);