import React, { useEffect, useRef } from 'react';
import api from 'lib/api';
import Modal from 'containers/Modals/Modal';
import { LTIComponent } from 'containers/LTI';

import { useTranslate } from 'lib/translate';

const NDLAFrame = (props) => {
	const translate = useTranslate();
	const iframeElement = useRef();

	const handleResize = () => {
		if (iframeElement.current == null) {
			return false;
		}

		iframeElement.current.style.height = window.outerHeight - 295 + 'px';
	};

	const addResource = (values) => {
		const id = values.url.replace(/^\D+/g, '');

		api.get(`https://api.ndla.no/article-api/v2/articles/${id}`)
			.then(({ data }) => {
				props.toggleModal();
				props.onSubmit({
					url: values.url,
					title: data.title.title,
					introduction: data.introduction.introduction,
					id: data.id,
					articleType: data.articleType,
					revision: data.revision,
					revisionDate: data.revisionDate,
					updated: data.updated,
					metaImageUrl: data.metaImage.url,
					metaImageAlt: data.metaImage.alt,
				});
			})
			.catch((reason) => {
				props.toggleModal();
				console.error('could not fetch article data because', reason);
			});
	};

	useEffect(() => {
		handleResize();

		window.addEventListener('resize', handleResize);

		var interval = setInterval(() => {
			if (iframeElement.current != null) {
				handleResize();
				clearInterval(interval);
			}
		}, 150);

		return () => {
			window.removeEventListener('resize', handleResize);
			clearInterval(interval);
		}
	}, []);

	handleResize();

	return (
		<Modal title={translate('NDLA')} isOpen={props.isOpen} onClose={props.toggleModal}>
			<div className="block--ndla-block iframe-container">
				<LTIComponent
					source="https://ndla.no/lti"
					ref={iframeElement}
					onCallback={addResource}
				/>
			</div>
		</Modal>
	);

};

NDLAFrame.defaultProps = {
	onSubmit: () => true,
	toggleModal: () => true,
	isOpen: true,
};

export default NDLAFrame;