import React from 'react';

const AssignmentTaskStatusSubmitted = React.memo(() => {
	return (
		<svg
			data-name="Lager 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 42.64 42.85"
		>
			<circle
				cx={21.32}
				cy={21.43}
				r={17.86}
				style={{
					fill: "#fff",
					stroke: "#164cc4",
					strokeMiterlimit: 10,
					strokeWidth: "3.61px",
				}}
			/>
		</svg>
	);
});

export default AssignmentTaskStatusSubmitted;