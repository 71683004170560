import React from 'react';

import { Icon, Haldor__Block, addBlockTranslations } from '@haldor/ui';
import DOMPurify from 'dompurify';

import Prompt from './Prompt';

import placeholderImage from './default_img_links.svg';

import './_LinksBlock.scss';

class LinksBlock extends Haldor__Block {

	constructor(props) {
		super(props);

		this.name = 'Haldor.Blocks.Link';
		this.order = 49;
		this.icon = () => (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
				dangerouslySetInnerHTML={{
					__html: `
					<defs><style>.block-icon-link{fill:#c8c9c8;}</style></defs>
					<path class="block-icon-link" d="M27.52,19.64l-7.89,7.9a2,2,0,0,0,0,2.79h0a2,2,0,0,0,2.79,0h0l7.9-7.89a2,2,0,0,0-2.81-2.81Z"/>
					<path class="block-icon-link" d="M25.53,35.65,23,38.16A8.28,8.28,0,0,1,12,39a7.9,7.9,0,0,1-1.08-11.12c.17-.2.35-.4.54-.59l2.81-2.82a2,2,0,0,0,0-2.79h0a2,2,0,0,0-2.8,0h0L9,24.14A12.15,12.15,0,0,0,7.62,40.08a12,12,0,0,0,17.91,1.18l2.8-2.8a2,2,0,0,0-2.8-2.81Z"/>
					<path class="block-icon-link" d="M40.1,7.66A12.2,12.2,0,0,0,24.05,9l-2.13,2.17a2.16,2.16,0,0,0-.48,3c0,.06.09.12.14.17a2,2,0,0,0,2.79,0h0L27,11.77A8.22,8.22,0,0,1,38,11a7.9,7.9,0,0,1,1.11,11.11,6.87,6.87,0,0,1-.58.63l-2.8,2.83a2,2,0,0,0,0,2.79h0a2,2,0,0,0,2.79,0h0l2.8-2.8A12,12,0,0,0,41.2,8.63,10.17,10.17,0,0,0,40.1,7.66Z"/>
					`
				}}
			/>
		)

		addBlockTranslations('sv-se', [
			{
				output: 'Länkar',
				id: 'Haldor.Blocks.Link',
			}
		]);

		addBlockTranslations('en-us', [
			{
				output: 'Links',
				id: 'Haldor.Blocks.Link',
			}
		]);

		addBlockTranslations('nb-no', [
			{
				output: 'Lenke',
				id: 'Haldor.Blocks.Link',
			}
		]);

		addBlockTranslations('da-dk', [
			{
				output: 'Links',
				id: 'Haldor.Blocks.Link',
			}
		]);

		addBlockTranslations('uk', [
			{
				output: 'Посилання',
				id: 'Haldor.Blocks.Link',
			}
		]);

		this.registerResourceType('haldor.blocks.links.link', {
			title: 'string',
			description: 'string',
			image: 'string',
			url: 'string',
		});

		this.editorBlockWasAdded = null;
	}

	onLinkAdd = (link) => {
		if (link.index != null) {
			let links = this.getResources();
			let foundExisting = links.find(resource => resource.index == link.index);

			if (foundExisting != null) {
				foundExisting.title = link.title;
                foundExisting.url = link.url;
                foundExisting.image = link.image;
                foundExisting.description = link.description;
				return true;
			}
		}

		this.addResource('haldor.blocks.links.link', link);
	}

	addhttp = (url) => {
		if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
			url = "http://" + url;
		}
		return url;
	}

	consume = (props) => {
		return <div className="block--links consume">
			{props.resources != null ?
				<div className="link-resource-list">
					{props.resources.map((resource, index) => {
						return <div className="link-resource-container" key={index}>
							<a className="link-resource" href={this.addhttp(resource.url)} target="_blank">
								<div className="link-content">
									{resource.image != null && resource.image != '' ?
										<div
											className="image"
											style={{ backgroundImage: `url(${resource.image})` }}
										/>
										:
										<div
											className="image placeholder"
											style={{ backgroundImage: `url(${placeholderImage})` }}
										/>
									}

									<div className="content">
										<div className="title"
											dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.title) }}
										/>

										<div className="description"
											dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resource.description) }}
										/>
									</div>
								</div>

								<div className="link-footer">
									<div className="url">
										<a href={this.addhttp(resource.url)} target="_blank">
											{resource.url}

											<Icon name="External" />
										</a>
									</div>
								</div>
							</a>
						</div>
					})}
				</div>
				: null}
		</div>
	}

	edit = (props) => {
		return <div className="block--links">
			<Prompt
				links={props.resources}
				onSubmit={this.onLinkAdd}
				block={this}
			/* openPicker={callback => this.editorBlockWasAdded = callback} */
			/>
		</div>
	}

}

export default LinksBlock;