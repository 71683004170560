import { getUserInfo, addUserRole, deleteUserRole, getUserRoles } from "actions/user";
import { getSchoolServices } from 'actions/schools';
import React, { Component } from 'react';
import { Button, Icon, translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { DataList } from 'UI';
import RowItem, { RowCell } from 'UI/Elements/List/RowItem';
import { Checkbox } from '@haldor/ui';
import { Spinner } from 'UI';
import { getActiveSchool } from 'helpers/localstorage';
import User from '_class/User';
import moment from 'moment';
import Modal from "containers/Modals/Modal";

import './UserRoles.scss';

class UserRoles extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			verifyDeleteModal: false,
			roles: [
				{ name: "TEACHER", staffInRole: false, synced: false, adminView: false },
				{ name: "MENTOR", staffInRole: false, synced: false, adminView: false },
				{ name: "SPECIAL_PEDAGOGUE", staffInRole: false, synced: false, adminView: false },
				{ name: "COURSE_ADMINISTRATOR", staffInRole: false, synced: false, adminView: false },
				{ name: "CONVERSATION_ADMIN", staffInRole: false, synced: false, adminView: true },
				{ name: "SCHOOL_LEADER", staffInRole: false, synced: false, adminView: true },
				{ name: "SCHOOL_OVERALL_READER", staffInRole: false, synced: false, adminView: true },
				{ name: "OTHER_STAFF", staffInRole: false, synced: false, adminView: true }
			]
		}
	}

	componentDidMount = () => {
		const user = new User(this.props.user);
		const school = user.getActiveSchool();

		this.props.getSchoolServices(school.id).then(() => {
			const foundSMS = this.props.schoolServices.find(service => {
				return service.serviceId == 'HALDOR_SMS' && moment(service.serviceEnd).isSameOrAfter(moment(), 'day');
			});

			if (foundSMS != null) {
				let roles = this.state.roles;
				roles.push({ name: "SMS_ADMIN", staffInRole: false, synced: false, adminView: true });
				this.setState({ roles });
			}

			this.loadRoleStatus();
		});
	}

	loadRoleStatus = () => {
		let roles = this.state.roles.map(role => {
			return {
				...role,
				staffInRole: this.staffInRole(role.name),
				synced: this.roleSynced(role.name)
			};
		});

		this.setState({ roles: roles });
	}

	deleteUserRoles = (roleId, isActive) => {
		this.props.deleteUserRole(this.props.currentStaff.userId, roleId).then(() => {
			this.props.getUserRoles(this.props.currentStaff.id).then(() => {
				let roles = this.state.roles;
				this.state.roles.forEach(role => {
					if (role.name == roleId) {
						role.staffInRole = isActive;
					}
				});
				this.setState({ roles: roles, loading: false });
			})
		});
	}

	onVerifyModalClose = (confirmDelete) => {
		this.setState({ verifyDeleteModal: false });

		if (confirmDelete) {
			const { roleId, isActive } = this.state.pendingDelete;
			this.deleteUserRoles(roleId, isActive);
		}

		this.setState({ pendingDelete: null });
	}

	onCheckboxClick = (roleId, isActive, synced) => {
		this.setState({ loading: true });
		if (isActive) {
			this.props.addUserRole(this.props.currentStaff.userId, roleId).then(() => {
				this.props.getUserRoles(this.props.currentStaff.id).then(() => {
					let roles = this.state.roles;
					this.state.roles.forEach(role => {
						if (role.name == roleId) {
							role.staffInRole = isActive;
						}
					});
					this.setState({ roles: roles, loading: false });
				})
			});
		} else {
			let totalActiveRoles = this.state.roles.reduce((count, staff) => staff.staffInRole ? count + 1 : count, 0);

			if (totalActiveRoles == 1) {
				this.setState({ loading: false, verifyDeleteModal: true, pendingDelete: { roleId, isActive } });
			} else {
				this.deleteUserRoles(roleId, isActive);
			}
		}
	}

	renderRolesRow = (role) => {
		const { translate } = this.props;
		return (
			((role.staffInRole && !role.adminView) || this.isCurrentUserAdmin() ?
				<RowItem key={role.name} >
					<RowCell style={{ margin: '0.75rem 1.25rem' }} className="flex justify-content-space-between" title={translate('name')}>
						<div>
							{this.isCurrentUserAdmin() ?
								<Checkbox
									onChange={(value) => this.onCheckboxClick(role.name, value, role.synced)}
									value={role.staffInRole}
									disabled={role.synced}
								/> : null}

							{translate(role.name.toUpperCase())}
						</div>

						{role.synced ? <span className='card-meta'>
							<Icon name="sync"></Icon>{this.props.translate('Synchronized role')}
						</span> : null}
					</RowCell>
				</RowItem>
				: null)
		);
	}

	staffInRole = (role) => {
		if (this.props.roles != null && this.props.roles.length > 0) {
			var foundRole = this.props.roles.find(r => r.roleId.toUpperCase() == role && r.referenceId == this.props.activeSchool);
			if (foundRole != null) {
				return true;
			}
		}

		return false;
	}

	roleSynced = (role) => {
		if (this.props.roles != null && this.props.roles.length > 0) {
			var foundRole = this.props.roles.find(r => r.roleId.toUpperCase() == role && r.referenceId == this.props.activeSchool);
			if (foundRole != null) {
				return foundRole.synced;
			}
		}

		return false;
	}

	isCurrentUserAdmin = () => {
		if (this.props.currentUser.roles != null && this.props.currentUser.roles.length > 0) {
			var schoolAdmin = this.props.currentUser.roles.find(role => (role.roleId.toUpperCase() == "SCHOOL_ADMINISTRATOR" && role.referenceId == this.props.activeSchool) || (role.roleId.toUpperCase() == "SYSADMIN" && role.referenceId == this.props.activeSchool));
			if (schoolAdmin != null) {
				return true;
			}
		}
		return false;
	}

	render() {
		const { currentUser } = this.props;

		if (this.state.loading) {
			return <Spinner center />;
		}

		const school = currentUser.schools.find(school =>
			school.id == getActiveSchool(currentUser.id)
		);

		const user = new User(currentUser);

		return (
			<div className="single-section">
				{this.props.translate('roles-for')}
				{' ' + school.title}

				<Modal
					type="small editor-block-modal"
					isOpen={this.state.verifyDeleteModal}
					onClose={() => { this.onVerifyModalClose(false) }}
					title={this.props.translate('Are you sure?')}
				>
					<div className="form-container modal-content">
						<p>
							{this.props.translate('If you remove all roles from a user, it will not be possible to search for the person in Haldor. Any reset can only be done by your organization\'s system administrator.')}
						</p>
						<div className="verify-modal-button-group">
							<Button onClick={() => { this.onVerifyModalClose(true) }}>{this.props.translate('Yes, remove the role')}</Button>
							<Button type="secondary" onClick={() => { this.onVerifyModalClose(false) }}>{this.props.translate('Cancel')}</Button>
						</div>
					</div>
				</Modal>

				<DataList
					data={this.state.roles.filter((role) => {
						if (user.isStudent()) {
							return role.name.indexOf('ADMIN') == -1;
						}
						return true;
					})}
					renderRow={this.renderRolesRow}
					emptyData={() => (
						<h3 style={{ textAlign: 'center' }}>
							{this.props.translate('no-results')}
						</h3>
					)}
				/>
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		schoolServices: state.School.schoolServices,
		user: state.user.currentUser,
		translate: translate(state.Languages.translations),
		currentUser: state.user.currentUser,
		activeSchool: state.user.activeSchool,
		roles: state.user.roles,
	};
}

export default connect(mapStateToProps, {
	getUserInfo,
	addUserRole,
	deleteUserRole,
	getUserRoles,
	getSchoolServices,
})(UserRoles);
